import { OnboardingQuestionEditionViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { OnboardingChoicesEdition } from './OnboardingChoicesEdition';
import { OnboardingTextsEdition } from './OnboardingTextsEdition';

export interface OnboardingQuestionEditionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: OnboardingQuestionEditionViewModel;
}

export const OnboardingQuestionEdition = observer((props: OnboardingQuestionEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, viewModel } = props;
  const { editableQuestion, dependableQuestionNamesInStep, otherDependableQuestionNames } = viewModel;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const hasChoices = editableQuestion.kind === 'single-choice' || editableQuestion.kind === 'multiple-choice';
  const hasDependantQuestion = editableQuestion.dependantQuestionName.length > 0;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <OnboardingTextsEdition
        texts={editableQuestion.editableDescription}
        rows={5}
        onFileUpload={(d, f) => viewModel.uploadFile(d, f)}
      />
      {hasChoices && <OnboardingChoicesEdition editableQuestion={editableQuestion} />}
      <MUI.FormControlLabel
        sx={{ mt: 1, mb: 2 }}
        label={strings.isAnswerRequiredLabel}
        control={
          <MUI.Checkbox
            checked={editableQuestion.isRequired}
            onChange={(e) => (editableQuestion.isRequired = e.target.checked)}
          />
        }
      />
      <MUI.Grid container spacing={1} alignItems="flex-end">
        <MUI.Grid item xs={12} sm={4}>
          <MUI.FormControl fullWidth>
            <MUI.InputLabel htmlFor="dependant-question-name-select" shrink>
              {strings.dependantQuestionNameLabel}
            </MUI.InputLabel>
            <MUI.Select
              sx={{ backgroundColor: '#eef' }}
              inputProps={{ id: 'dependant-question-name-select' }}
              value={editableQuestion.dependantQuestionName}
              onChange={(e) => (editableQuestion.dependantQuestionName = e.target.value)}
            >
              <MUI.MenuItem key="no-dependable-question-name" value="">
                {strings.noDependantQuestionLabel}
              </MUI.MenuItem>
              <MUI.ListSubheader sx={{ margin: 0, lineHeight: '2em', paddingTop: 1 }}>
                {strings.questionsInThisStepLabel}
              </MUI.ListSubheader>
              {dependableQuestionNamesInStep.map((name) => (
                <MUI.MenuItem key={`dependable-question-name-${name}`} value={name}>
                  {name}
                </MUI.MenuItem>
              ))}
              {otherDependableQuestionNames.map((others, index) => [
                <MUI.ListSubheader key={`other-questions${index}`} sx={{ margin: 0, lineHeight: '2em', paddingTop: 1 }}>
                  {strings.questionsInOtherStepLabel(others.stepName)}
                </MUI.ListSubheader>,
                others.questionNames.map((name) => (
                  <MUI.MenuItem key={`dependable-question-name-${name}`} value={name}>
                    {name}
                  </MUI.MenuItem>
                ))
              ])}
            </MUI.Select>
          </MUI.FormControl>
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={4}>
          <MUI.TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { backgroundColor: '#eef' } }}
            disabled={!hasDependantQuestion}
            label={strings.dependantQuestionAnswerLabel}
            value={editableQuestion.dependantQuestionAnswer}
            onChange={(e) => (editableQuestion.dependantQuestionAnswer = e.target.value)}
          />
        </MUI.Grid>
        <MUI.Grid item xs={12} sm={4}>
          <MUI.FormControlLabel
            label={strings.isHiddenWhenDependantLabel}
            disabled={!hasDependantQuestion}
            control={
              <MUI.Checkbox
                checked={editableQuestion.isHiddenWhenDependant}
                onChange={(e) => (editableQuestion.isHiddenWhenDependant = e.target.checked)}
              />
            }
          />
        </MUI.Grid>
      </MUI.Grid>
    </MUI.Box>
  );
});
