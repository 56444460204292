import { BehaviourSummaryItemSize } from '@insights/models';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { OQValue } from '@shared/models/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { BehaviourSummaryItem } from './BehaviourSummaryItem';

export interface BehaviourSummaryProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  size?: BehaviourSummaryItemSize;
  appOpenValue?: OQValue;
  taskCompletionValue?: OQValue;
  taskBreakdownValue?: OQValue;
  taskPlanningValue?: OQValue;
}

export const BehaviourSummary = observer((props: BehaviourSummaryProps) => {
  const { className, sx, style, size = 'medium', appOpenValue, taskCompletionValue } = props;

  const marginInfo = useMemo(() => {
    switch (size) {
      case 'small':
        return {
          margin: '-2px',
          padding: '2px'
        };

      default:
        return {
          margin: 0,
          padding: 0.5
        };
    }
  }, [size]);

  return (
    <MUI.Box
      sx={{ ...sx, border: `1px solid ${grey[300]}`, backgroundColor: 'white', minWidth: 'min-content' }}
      className={className}
      style={style}
      borderRadius={50}
      padding={0.5}
    >
      <MUI.Box margin={marginInfo.margin} display="flex">
        <MUI.Box padding={marginInfo.padding}>
          <BehaviourSummaryItem type="app-open" value={appOpenValue} size={size} />
        </MUI.Box>
        <MUI.Box padding={marginInfo.padding}>
          <BehaviourSummaryItem type="task-completion" value={taskCompletionValue} size={size} />
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
});
