import { MicrosoftTeamsMappingsViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowIcon from '@mui/icons-material/ForwardOutlined';
import * as MUI from '@mui/material';
import { styled, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { AllContentWorkloadLevels } from '@shared/models/types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { ContentWorkloadLevelSelector } from './ContentWorkloadLevelSelector';
import { MicrosoftTeamsAssignmentCategoryInput } from './MicrosoftTeamsAssignmentCategoryInput';
import { OptionalContentIconSelector } from './OptionalContentIconSelector';

export interface MicrosoftTeamsAssignmentCategoryMappingsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: MicrosoftTeamsMappingsViewModel;
}

export const MicrosoftTeamsAssignmentCategoryMappings = observer(
  (props: MicrosoftTeamsAssignmentCategoryMappingsProps) => {
    const { localizationService } = useInsightsServices();
    const { sx, className, style, viewModel } = props;
    const strings = localizationService.localizedStrings.insights.components.connectors;

    return (
      <Root sx={sx} className={className} style={style}>
        <MUI.TableHead>
          <MUI.TableRow>
            <MUI.TableCell className={classNames('cell', 'groupCell')}>
              <MUI.InputLabel shrink>{strings.microsoftTeamsAssignmentCategoryTitle}</MUI.InputLabel>
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'iconCell')}>
              <MUI.InputLabel shrink>{strings.iconTitle}</MUI.InputLabel>
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'workloadCell')}>
              <MUI.InputLabel shrink>{strings.workloadLevelTitle}</MUI.InputLabel>
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'actionCell')} />
          </MUI.TableRow>
        </MUI.TableHead>
        <MUI.TableBody>
          {viewModel.mappings.map((m, i) => (
            <MUI.TableRow key={`category-row-${i}-${m.categoryName}`}>
              <MUI.TableCell className={classNames('cell', 'groupCell')}>
                <MUI.Box display="flex" flexDirection="row" alignItems="center">
                  <MUI.Typography flex={1}>{m.categoryName}</MUI.Typography>
                  <ArrowIcon className="arrow" />
                </MUI.Box>
              </MUI.TableCell>
              <MUI.TableCell className={classNames('cell', 'iconCell')}>
                <OptionalContentIconSelector
                  selectedIcon={m.representation?.icon}
                  workloadLevel={m.representation?.workloadLevel}
                  onChange={(icon) => viewModel.setIcon(m, icon)}
                />
              </MUI.TableCell>
              <MUI.TableCell className={classNames('cell', 'workloadCell')}>
                {m.representation?.icon != null && (
                  <ContentWorkloadLevelSelector
                    selectedLevel={m.representation.workloadLevel}
                    availableLevels={AllContentWorkloadLevels}
                    onChange={(level) => viewModel.setWorkloadLevel(m, level)}
                  />
                )}
              </MUI.TableCell>
              <MUI.TableCell className={classNames('cell', 'actionCell')}>
                <MUI.IconButton onClick={() => m.markAsDeleted()}>
                  <DeleteIcon fontSize="small" />
                </MUI.IconButton>
              </MUI.TableCell>
            </MUI.TableRow>
          ))}
        </MUI.TableBody>
        <MUI.TableFooter>
          <MUI.TableRow className="nextRow">
            <MUI.TableCell className={classNames('cell', 'groupCell')}>
              {/* Until we get an API to fetch all assignment categories, we must go
                  with a text-based mapping. Keeping this for reference. */}
              <MicrosoftTeamsAssignmentCategoryInput
                categoryName=""
                onAdd={(name) => viewModel.addMappingByName(name)}
              />
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'gradingCell')} />
            <MUI.TableCell className={classNames('cell', 'iconCell')} />
            <MUI.TableCell className={classNames('cell', 'workloadCell')} />
            <MUI.TableCell className={classNames('cell', 'actionCell')} />
          </MUI.TableRow>
        </MUI.TableFooter>
      </Root>
    );
  }
);

const Root = styled(MUI.Table)(({ theme }) => ({
  '.cell': {
    padding: '2px 8px 2px 0px',
    border: 'none',
    verticalAlign: 'bottom'
  },
  '.groupCell': {
    width: '30%'
  },
  '.gradingCell': {
    width: '30%'
  },
  '.iconCell': {
    width: '15%'
  },
  '.workloadCell': {
    width: '15%'
  },
  '.actionCell': {
    width: '10%'
  },
  '.nextRow': {
    backgroundColor: grey[200]
  },
  '.arrow': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));
