import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { AutomatedImport } from '@shared/models/import';
import { AllDayOfWeek, DayOfWeek, Time } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface AutomatedImportCreateOrEditDialogProps extends DialogResult<AutomatedImport> {
  sx?: SxProps;
  configId: string;
  importSessionId: string;
  automatedImport?: AutomatedImport;
  className?: string;
}

export const AutomatedImportCreateOrEditDialog = observer((props: AutomatedImportCreateOrEditDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx, configId, importSessionId, automatedImport, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;
  const dowStrings = localizationService.localizedStrings.models.dayOfWeek;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createAutomatedImportCreateOrEdit(onSuccess!, onCancel!, configId, importSessionId, automatedImport)
  );

  const isCreating = viewModel.automatedImport.shouldBeCreated;

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        onClose={() => viewModel.cancel()}
        maxWidth="xs"
        fullWidth={true}
        className={className}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <MUI.DialogTitle>
            {isCreating ? strings.createAutomatedImportTitle : strings.editAutomatedImportTitle}
          </MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <MUI.Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
              <MUI.TextField
                sx={{ mb: 2 }}
                label={strings.automatedImportNameLabel}
                value={viewModel.automatedImport.name}
                fullWidth
                required
                autoFocus
                onChange={(e) => (viewModel.automatedImport.name = e.target.value)}
              />

              <MUI.FormControl sx={{ mb: 1 }}>
                <MUI.InputLabel id="days-selector">{strings.automatedImportDaysLabel}</MUI.InputLabel>
                <MUI.Select
                  value={viewModel.automatedImport.days}
                  multiple
                  labelId="days-selector"
                  onChange={(event) => (viewModel.automatedImport.days = event.target.value as unknown as DayOfWeek[])}
                  renderValue={() => (
                    <MUI.Typography>
                      {viewModel.automatedImport.days.map((dow) => dowStrings.localizedDayOfWeek(dow)).join(', ')}
                    </MUI.Typography>
                  )}
                >
                  {AllDayOfWeek.map((dow) => (
                    <MUI.MenuItem key={`dow-${dow}`} value={dow}>
                      <MUI.Checkbox size="small" checked={viewModel.automatedImport.days.includes(dow)} />
                      <MUI.Typography>{dowStrings.localizedDayOfWeek(dow)}</MUI.Typography>
                    </MUI.MenuItem>
                  ))}
                </MUI.Select>
              </MUI.FormControl>

              <MUI.TextField
                type="time"
                required
                label={strings.automatedImportTimeLabel}
                value={viewModel.automatedImport.time?.asString ?? null}
                onChange={(event) => (viewModel.automatedImport.time = Time.fromString(event.target.value))}
                inputProps={{
                  step: 300
                }}
              />
            </MUI.Box>
          </MUI.DialogContent>

          <MUI.DialogActions>
            {viewModel.hasError && (
              <MUI.Typography flex={1} variant="caption" color="error" style={{ textAlign: 'end' }}>
                {strings.unexpectedAutomatedImportError}
              </MUI.Typography>
            )}
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => viewModel.cancel()}>
              {strings.cancelAutomatedImportButtonLabel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={!viewModel.canApply}
              isExecuting={viewModel.isExecuting}
              onClick={() => void viewModel.apply()}
            >
              {isCreating ? strings.createAutomatedImportButtonLabel : strings.editAutomatedImportButtonLabel}
            </InsightsButton>
          </MUI.DialogActions>
        </form>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
