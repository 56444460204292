import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';

export interface PrimaryControlProps {
  onKeyDown: React.KeyboardEventHandler;
}

export interface SecondaryControlProps {
  autoFocus: boolean;
  onBlur: React.FocusEventHandler;
}

export interface FieldNavigationManagerProps {
  /**
   * The first field
   * @param props The props that should be added to the field in order to make the tab navigation functional.
   */
  primaryControl: (props: PrimaryControlProps) => React.ReactNode;
  /**
   * The conditionally rendered field.
   * @param props The props that should be added to the field in order to make the tab navigation functional.
   */
  secondaryControl: (props: SecondaryControlProps) => React.ReactNode;
}

/**
 * This component should be used when we want to use proper tab navigation
 * between fields that are conditionally rendered.
 */
export const FieldNavigationManager = observer((props: FieldNavigationManagerProps) => {
  const { primaryControl, secondaryControl } = props;
  const [isTabPressed, setIsTabPressed] = useState(false);

  return (
    <>
      {primaryControl({
        onKeyDown: (event) => {
          if (event.key === 'Tab') {
            runInAction(() => setIsTabPressed(true));
          }
        }
      })}
      {secondaryControl({
        autoFocus: isTabPressed,
        onBlur: () => runInAction(() => setIsTabPressed(false))
      })}
    </>
  );
});
