import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { QuestionChoice } from '@shared/models/onboarding/interfaces';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ReadOnlyMultipleChoiceQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  choices: QuestionChoice[];
  answers?: string[];
}

export const ReadOnlyMultipleChoiceQuestion = observer((props: ReadOnlyMultipleChoiceQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, choices, answers = [] } = props;
  const languageCode = localizationService.currentLocale;

  return (
    <MUI.FormGroup sx={sx} className={className} style={style}>
      <MUI.Stack>
        {choices.map((choice) => (
          <MUI.FormControlLabel
            key={`q-ro-c-${choice.value}`}
            control={<MUI.Checkbox sx={{ paddingY: '6px' }} disabled checked={answers.includes(choice.value)} />}
            label={
              <MUI.Typography fontWeight="400">
                {choice.label.find((l) => l.languageCode === languageCode)?.value ?? ''}
              </MUI.Typography>
            }
          />
        ))}
      </MUI.Stack>
    </MUI.FormGroup>
  );
});
