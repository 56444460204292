import { ExternalAssociationViewModel } from '@insights/viewmodels';
import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { Column } from '../layout';

export interface ExternalAssociationListItemLastUpdateProps {
  sx?: SxProps;
  className?: string;
  association: ExternalAssociationViewModel;
}

export const ExternalAssociationListItemLastUpdate = observer(
  ({ sx, className, association }: ExternalAssociationListItemLastUpdateProps) => {
    if (!association.hasAssociation) {
      return null;
    }

    const color =
      association.lastUpdateResult == null ? 'textSecondary' : association.lastUpdateResult ? 'textPrimary' : 'error';

    const tooltip = association.lastUpdateTooltip;

    return (
      <Tooltip
        title={
          tooltip && (
            <Column>
              <Typography whiteSpace="pre-line" variant="caption">
                {tooltip}
              </Typography>
            </Column>
          )
        }
      >
        <Box sx={sx} className={className} maxWidth={250}>
          <Column>
            <Typography color={color} noWrap variant="caption">
              {association.lastUpdateMessage}
            </Typography>
            <Typography color={color} noWrap variant="caption">
              {association.lastUpdateReadable}
            </Typography>
          </Column>
        </Box>
      </Tooltip>
    );
  }
);
