import { MaterialTableData } from '@insights/models';
import { Column } from '@material-table/core';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { Incident, SourceRow } from '@shared/models/import';
import { OrderedIncidentSeverities } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import _, { chain, compact } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { cleanDiacritics } from 'underscore.string';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsMaterialTable } from '../InsightsMaterialTable';
import { Scroll } from '../layout';
import { ImportDataIncident } from './ImportDataIncident';
import { ImportDataIncidentIcon, IncidentSize } from './ImportDataIncidentIcon';

const DefaultTableStateKey = 'ImportSessionSourceRowList';

type IncidentFilter = 'all-rows' | 'rows-with-incident' | 'rows-without-incident';

export interface ImportSessionSourceRowListProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  rows: SourceRow[];
  useNonPropFont?: boolean;
  globalIncidents?: Incident[];
  columnNames: string[];
  showIncidents?: boolean;
  pageSize?: number;
  tableStateKey?: string;
}

interface SourceRowData extends MaterialTableData {
  row: SourceRow;
}

export const ImportSessionSourceRowList = observer((props: ImportSessionSourceRowListProps) => {
  const {
    sx,
    className,
    style,
    title,
    rows,
    useNonPropFont = false,
    columnNames,
    showIncidents,
    pageSize,
    tableStateKey,
    globalIncidents = []
  } = props;
  const { localizationService } = useInsightsServices();

  const theme = useTheme();
  const strings = localizationService.localizedStrings.insights.components.import;
  const [incidentFilter, setIncidentFilter] = useState<IncidentFilter>('all-rows');

  const filledNames = columnNames.length > 0 ? columnNames : rows[0]?.columns.map(() => '') ?? [];

  const incidentsSummary: Incident[] = useMemo(() => {
    return chain(rows)
      .map('incidents')
      .flatten()
      .concat(globalIncidents)
      .uniqBy('message')
      .sortBy('code', 'message')
      .value();
  }, [rows, globalIncidents]);

  const hasIncidents = useMemo(() => incidentsSummary.length > 0, [incidentsSummary]);

  function toggleIncidentFilter() {
    if (incidentFilter === 'all-rows') {
      setIncidentFilter('rows-with-incident');
    } else if (incidentFilter === 'rows-with-incident') {
      setIncidentFilter('rows-without-incident');
    } else {
      setIncidentFilter('all-rows');
    }
  }

  const columns: Column<SourceRowData>[] = filledNames.map((name, index) =>
    name.startsWith('_')
      ? { hidden: true }
      : {
          title: name.length === 0 ? `${index}` : `${index} : ${name.replace('~', '')}`,
          searchable: true,
          render: ({ row }: SourceRowData) => {
            const column = row.columns[index] ?? '';

            const hasAppliedChanges = column.includes('►');
            const hasKeptChanges = column.includes('◄');

            return name.startsWith('~') ? (
              renderTooltipRow(column, localizationService)
            ) : hasAppliedChanges || hasKeptChanges ? (
              renderUpdatedRow(column, hasAppliedChanges, theme)
            ) : useNonPropFont ? (
              <pre>{column}</pre>
            ) : (
              column
            );
          },
          customFilterAndSearch: (filter: string, { row }: SourceRowData) => {
            const cleanedValue = cleanDiacritics(row.columns[index]);
            const cleanedFilter = cleanDiacritics(filter);

            return cleanedValue.toLowerCase().indexOf(cleanedFilter.toLowerCase()) !== -1;
          }
        }
  );

  if (showIncidents) {
    columns.unshift({
      width: '1%',
      render: renderError
    });
  }

  return (
    <Scroll sx={sx} className={className} style={style} direction="x">
      <MUI.Box>
        <InsightsMaterialTable
          stateKey={tableStateKey ?? DefaultTableStateKey}
          // This is to disable the card contour
          components={{
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>
          }}
          actions={
            showIncidents
              ? [
                  {
                    icon: 'filter_alt',
                    iconProps: { color: getIncidentFilterColor(incidentFilter) },
                    isFreeAction: true,
                    onClick: () => toggleIncidentFilter()
                  }
                ]
              : []
          }
          title={title ?? ''}
          columns={columns}
          data={filterRows(rows, incidentFilter)}
          options={{
            search: true,
            showTitle: (title?.length ?? 0) > 0,
            paging: true,
            maxColumnSort: 0,
            rowStyle: { backgroundColor: '#fff', verticalAlign: 'top', fontSize: theme.typography.body2.fontSize },
            headerStyle: { fontSize: theme.typography.body2.fontSize },
            draggable: false,
            emptyRowsWhenPaging: false,
            padding: 'dense',
            pageSize
          }}
          localization={localizationService.localizedStrings.insights.materialTable}
        />
      </MUI.Box>
      <MUI.Box>
        {showIncidents && hasIncidents && (
          <MUI.List
            subheader={
              <MUI.Typography variant={'subtitle2'} my={1}>
                {strings.incidentsSummary}
              </MUI.Typography>
            }
          >
            {renderIncidents(incidentsSummary, 'small')}
          </MUI.List>
        )}
      </MUI.Box>
    </Scroll>
  );
});

function renderIncidents(incidents: Incident[], size?: IncidentSize) {
  return incidents.map((incident, i) => (
    <ImportDataIncident key={`incident-${i}`} incident={incident} size={size} colorMode="icon-incident-color" />
  ));
}

function renderUpdatedRow(row: string, hasAppliedChanges: boolean, theme: MUI.Theme) {
  const symbol = hasAppliedChanges ? '►' : '◄';
  const oldValueEndIndex = hasAppliedChanges ? row.indexOf(symbol) : row.indexOf(symbol) + 1;

  const oldValue = row.substring(0, oldValueEndIndex);
  const newValue = row.substring(oldValueEndIndex);

  return (
    <MUI.Box>
      <MUI.Box>{oldValue}</MUI.Box>
      <MUI.Box sx={{ color: hasAppliedChanges ? theme.palette.success.main : undefined }}>{newValue}</MUI.Box>
    </MUI.Box>
  );
}

function renderTooltipRow(row: string, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.components.import;

  const values = compact(row.split(','));
  const addedValues = values.filter((v) => v.startsWith('+'));
  const removedValues = values.filter((v) => v.startsWith('-'));
  const otherValues = values.filter((v) => !v.startsWith('+') && !v.startsWith('-'));

  let tooltip = '';

  if (addedValues.length > 0) {
    tooltip += `${strings.addedValues}:\n` + addedValues.map((v) => `* ${v}`).join('\n');
  }

  if (removedValues.length > 0) {
    if (tooltip.length > 0) {
      tooltip += '\n\n';
    }

    tooltip += `${strings.removedRows}:\n` + removedValues.map((v) => `* ${v}`).join('\n');
  }

  if (otherValues.length > 0) {
    if (tooltip.length > 0) {
      tooltip += '\n\n';
    }

    tooltip += `${strings.other}:\n` + otherValues.map((v) => `* ${v}`).join('\n');
  }

  if (tooltip.length === 0) {
    tooltip += strings.noValues;
  }

  return (
    <MUI.Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltip}</span>}>
      <div>{strings.values(values.length)}</div>
    </MUI.Tooltip>
  );
}

function renderError({ row }: SourceRowData) {
  const highestSeverity = _.chain(row.incidents)
    .orderBy((i) => OrderedIncidentSeverities.indexOf(i.severity), 'desc')
    .first()
    .value()?.severity;

  if (highestSeverity != null) {
    return (
      <MUI.Tooltip title={renderIncidents(row.incidents)} placement="bottom-start">
        <MUI.Box>
          <ImportDataIncidentIcon severity={highestSeverity} size="small" />
        </MUI.Box>
      </MUI.Tooltip>
    );
  }
  return null;
}

function getIncidentFilterColor(filter: IncidentFilter): 'disabled' | 'error' | 'secondary' {
  switch (filter) {
    case 'all-rows':
      return 'disabled';
    case 'rows-with-incident':
      return 'error';
    case 'rows-without-incident':
      return 'secondary';
  }
}

function filterRows(rows: SourceRow[], filter: IncidentFilter): SourceRowData[] {
  // We don't care about ids as rows aren't sortable, but toggling filters
  // should not generate same ids for different rows.
  switch (filter) {
    case 'rows-with-incident':
      return rows.filter((r) => r.incidents.length > 0).map<SourceRowData>((row, i) => ({ row, id: `rwi-${i}` }));
    case 'rows-without-incident':
      return rows.filter((r) => r.incidents.length === 0).map<SourceRowData>((row, i) => ({ row, id: `rwoi-${i}` }));
    default:
      return rows.map<SourceRowData>((row, i) => ({ row, id: `r-${i}` }));
  }
}
