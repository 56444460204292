import DropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AllOnboardingStatuses, AllStepOnboardingStatuses, OnboardingStatus } from '@shared/models/types';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

export interface OnboardingStatusSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  selectedProcessStatuses: OnboardingStatus[];
  selectedStepStatuses: OnboardingStatus[];
  minimumDate: Date | undefined;
  setStatuses: (
    processStatuses: OnboardingStatus[],
    stepStatuses: OnboardingStatus[],
    minimumDate: Date | undefined
  ) => void;
}

export const OnboardingStatusSelector = observer((props: OnboardingStatusSelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, setStatuses, selectedProcessStatuses, selectedStepStatuses, minimumDate } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [processStatuses, setProcessStatuses] = useState<OnboardingStatus[]>(selectedProcessStatuses.slice());
  const [stepStatuses, setStepStatuses] = useState<OnboardingStatus[]>(selectedStepStatuses.slice());
  const [localMinimumDate, setMinimumDate] = useState<Date | null>(minimumDate ?? null);

  const readableProcessStatuses =
    processStatuses.map((s) => strings.getProcessStatusLabel(s, false)).join('+') || strings.anyStatus;
  const readableStepStatuses =
    stepStatuses.map((s) => strings.getStepStatusLabel(s, false)).join('+') || strings.anyStatus;
  const readableMinimumDate =
    localMinimumDate != null
      ? format(localMinimumDate, localizationService.localizedStrings.models.dateFormats.medium)
      : strings.noneLabel;

  function toggleProcessStatus(status: OnboardingStatus) {
    if (processStatuses.includes(status)) {
      setProcessStatuses(processStatuses.filter((s) => s !== status));
    } else {
      setProcessStatuses([...processStatuses, status]);
    }
  }

  function toggleStepStatus(status: OnboardingStatus) {
    if (stepStatuses.includes(status)) {
      setStepStatuses(stepStatuses.filter((s) => s !== status));
    } else {
      setStepStatuses([...stepStatuses, status]);
    }
  }

  function applyChanges() {
    // Also make copies here, as we're still there for the next "open".
    // Otherwise, the viewmodel now has our copy.
    setStatuses(processStatuses.slice(), stepStatuses.slice(), localMinimumDate ?? undefined);
    setIsOpen(false);
  }

  function cancelChanges() {
    // Make sure to use copies to not affect the original arrays on push.
    setProcessStatuses(selectedProcessStatuses.slice());
    setStepStatuses(selectedStepStatuses.slice());
    setIsOpen(false);
  }

  return (
    <>
      <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="row" alignItems="center">
        <MUI.Button ref={buttonRef} variant="text" style={{ textTransform: 'none' }} onClick={() => setIsOpen(!isOpen)}>
          <MUI.Stack alignItems="flex-start" mr={2}>
            <MUI.Typography variant="caption">{strings.processStatuses}</MUI.Typography>
            <MUI.Typography variant="body2">{readableProcessStatuses}</MUI.Typography>
          </MUI.Stack>
          <MUI.Stack alignItems="flex-start" mr={2}>
            <MUI.Typography variant="caption">{strings.stepStatuses}</MUI.Typography>
            <MUI.Typography variant="body2">{readableStepStatuses}</MUI.Typography>
          </MUI.Stack>
          <MUI.Stack alignItems="flex-start" mr={2}>
            <MUI.Typography variant="caption">{strings.minimumDate}</MUI.Typography>
            <MUI.Typography variant="body2">{readableMinimumDate}</MUI.Typography>
          </MUI.Stack>
          <DropDownIcon fontSize="small" />
        </MUI.Button>
      </MUI.Box>

      <MUI.Popover
        open={isOpen}
        onClose={() => cancelChanges()}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MUI.Grid container sx={{ minWidth: theme.breakpoints.values.sm }}>
          <MUI.Grid item xs={12} sm={6} md={4} sx={{ p: 1 }}>
            <MUI.Typography variant="subtitle1">{strings.processStatuses}</MUI.Typography>
            <MUI.List>
              {AllOnboardingStatuses.map((s) => (
                <MUI.ListItemButton
                  key={`process-status-${s}`}
                  selected={processStatuses.includes(s)}
                  onClick={() => toggleProcessStatus(s)}
                >
                  <MUI.ListItemText>{strings.getProcessStatusLabel(s, false)}</MUI.ListItemText>
                </MUI.ListItemButton>
              ))}
            </MUI.List>
          </MUI.Grid>
          <MUI.Grid item xs={12} sm={6} md={4} sx={{ p: 1 }}>
            <MUI.Typography variant="subtitle1">{strings.stepStatuses}</MUI.Typography>
            <MUI.List>
              {AllStepOnboardingStatuses.map((s) => (
                <MUI.ListItemButton
                  key={`step-status-${s}`}
                  selected={stepStatuses.includes(s)}
                  onClick={() => toggleStepStatus(s)}
                >
                  <MUI.ListItemText>{strings.getStepStatusLabel(s, false)}</MUI.ListItemText>
                </MUI.ListItemButton>
              ))}
            </MUI.List>
          </MUI.Grid>
          <MUI.Grid item xs={12} sm={6} md={4} sx={{ p: 1 }}>
            <MUI.Typography variant="subtitle1">{strings.minimumDate}</MUI.Typography>
            <DatePicker
              slotProps={{
                actionBar: {
                  actions: ['clear', 'cancel', 'accept']
                },
                textField: {
                  fullWidth: true
                }
              }}
              localeText={{
                okButtonLabel: strings.okLabel,
                cancelButtonLabel: strings.cancelLabel,
                clearButtonLabel: strings.clearLabel
              }}
              format={localizationService.localizedStrings.models.dateFormats.mediumUnabridged}
              value={localMinimumDate}
              onChange={(v) => setMinimumDate(v)}
            />
            <MUI.Typography variant="caption">{strings.minimumDateNotice}</MUI.Typography>
          </MUI.Grid>
        </MUI.Grid>
        <MUI.Grid item xs={12}>
          <MUI.Box display="flex" flexDirection="row-reverse" alignItems="center">
            <MUI.Button variant="contained" sx={{ m: 1 }} onClick={() => cancelChanges()}>
              {strings.cancelLabel}
            </MUI.Button>
            <MUI.Button variant="contained" sx={{ m: 1 }} color="primary" onClick={() => applyChanges()}>
              {strings.applyLabel}
            </MUI.Button>
          </MUI.Box>
        </MUI.Grid>
      </MUI.Popover>
    </>
  );
});
