import { MenuItem, Select, SxProps, Typography } from '@mui/material';
import { ContentWorkloadLevel } from '@shared/models/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Row } from '../layout';

export interface ContentWorkloadLevelSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  selectedLevel: ContentWorkloadLevel;
  availableLevels: ContentWorkloadLevel[];
  onChange: (level: ContentWorkloadLevel) => void;
}

export const ContentWorkloadLevelSelector = observer((props: ContentWorkloadLevelSelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, selectedLevel, availableLevels, onChange } = props;
  const contentsStrings = localizationService.localizedStrings.models.contents;

  return (
    <Row sx={sx} className={className} style={style}>
      <Select
        fullWidth
        value={selectedLevel}
        onChange={(event) => onChange(event.target.value as ContentWorkloadLevel)}
      >
        {availableLevels.map((level) => (
          <MenuItem key={level} value={level}>
            <Typography>{contentsStrings.workloadLevelString(level)}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Row>
  );
});
