import { css } from '@emotion/css';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Thresholds } from '../Constants';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

export interface Thresholds {
  dailyThreshold: number;
  weeklyThreshold: number;
}

export interface ThresholdsInformationProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  thresholds: Thresholds;
}

export const ThresholdsInformation = observer((props: ThresholdsInformationProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, thresholds } = props;
  const theme = useTheme();

  const dailyThresholdDisplayValue =
    thresholds.dailyThreshold === Thresholds.maxThreshold ? '–' : `${thresholds.dailyThreshold}`;
  const weeklyThresholdDisplayValue =
    thresholds.weeklyThreshold === Thresholds.maxThreshold ? '–' : `${thresholds.weeklyThreshold}`;

  const tooltipClassName = css({
    backgroundColor: theme.palette.common.white,
    color: theme.palette.getContrastText(theme.palette.common.white),
    boxShadow: theme.shadows[1],
    maxWidth: 'none'
  });

  const popperClassName = css({ opacity: 1 });

  return (
    <MUI.Tooltip
      sx={sx}
      className={className}
      style={style}
      classes={{ tooltip: tooltipClassName, popper: popperClassName }}
      title={renderTooltip(dailyThresholdDisplayValue, weeklyThresholdDisplayValue, localizationService)}
    >
      <MUI.Box borderRadius="25px" border={`1px solid ${grey[300]}`}>
        <MUI.Box height="fit-content" display="flex" flexDirection="row">
          <MUI.Typography mx={1} variant="body2" color="textSecondary" noWrap>
            {dailyThresholdDisplayValue}
          </MUI.Typography>

          <MUI.Box borderRight={`1px solid ${grey[300]}`} />

          <MUI.Typography mx={1} variant="body2" color="textSecondary" noWrap>
            {weeklyThresholdDisplayValue}
          </MUI.Typography>
        </MUI.Box>
      </MUI.Box>
    </MUI.Tooltip>
  );
});

function renderTooltip(
  dailyThresholdDisplayValue: string,
  weeklyThresholdDisplayValue: string,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.components.thresholds;

  return (
    <MUI.Box>
      {/* Daily threshold */}
      <MUI.Box display="flex" flexDirection="row">
        <MUI.Box marginRight={3}>
          <MUI.Typography>{strings.dailyThresholdTooltipLabel}</MUI.Typography>
        </MUI.Box>
        <MUI.Box flex={1} />
        <MUI.Typography fontWeight="400" align="right">
          {dailyThresholdDisplayValue}
        </MUI.Typography>
      </MUI.Box>

      {/* Weekly threshold */}
      <MUI.Box display="flex" flexDirection="row">
        <MUI.Box marginRight={3}>
          <MUI.Typography>{strings.weeklyThresholdTooltipLabel}</MUI.Typography>
        </MUI.Box>
        <MUI.Box flex={1} />
        <MUI.Typography fontWeight="400" align="right">
          {weeklyThresholdDisplayValue}
        </MUI.Typography>
      </MUI.Box>
    </MUI.Box>
  );
}
