import { AddOnboardingStepViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { Autocomplete, createFilterOptions, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

interface StepOption {
  readonly name: string;
  readonly displayName?: string;
}

function isStepOption(value: string | StepOption | null): value is StepOption {
  return value != null && (value as StepOption).name != null;
}

const filter = createFilterOptions<StepOption>();

export interface AddOnboardingStepProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: AddOnboardingStepViewModel;
}

export const AddOnboardingStep = observer((props: AddOnboardingStepProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <Autocomplete
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        value={viewModel.selectedStepName}
        onChange={(_, v) => {
          viewModel.selectedStepName = isStepOption(v) ? v.name : v ?? '';
        }}
        filterOptions={(o, p) => {
          const filtered = filter(o, p);
          const { inputValue } = p;

          if (inputValue.length > 0 && !viewModel.availableStepNames.includes(inputValue)) {
            filtered.push({ name: inputValue, displayName: strings.getCreateStepLabel(inputValue) });
          }

          return filtered;
        }}
        options={viewModel.availableStepNames.map<StepOption>((name) => ({ name }))}
        getOptionLabel={(o) => (isStepOption(o) ? o.displayName ?? o.name : o)}
        renderInput={(params) => <MUI.TextField {...params} fullWidth label={strings.selectStepTemplateLabel} />}
      />
    </MUI.Box>
  );
});
