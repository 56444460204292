import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { SectionUtils } from '@insights/utils';
import { SectionOptionsViewModel } from '@insights/viewmodels';
import { Card, CardContent, Checkbox, FormControlLabel, Stack, SxProps, Typography } from '@mui/material';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { CreateSchoolSection } from './CreateSchoolSection';
import { SectionName } from './SectionName';

export interface CreateSchoolSectionsProps {
  sx?: SxProps;
  className?: string;
  viewModel: SectionOptionsViewModel;
}

export const CreateSchoolSections = observer(({ sx, className, viewModel }: CreateSchoolSectionsProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.createSchool;

  return (
    <Card sx={sx} className={className}>
      <CardContent sx={{ width: '100%' }}>
        <Stack spacing={2}>
          <Typography variant="h5">{strings.sectionsSection}</Typography>
          {viewModel.canKeepSections && (
            <>
              <Typography variant="h6">{strings.lockedSectionsSubSection}</Typography>
              {viewModel.lockedSections.length === 0 ? (
                <Typography variant="body2">{strings.noLockedSections}</Typography>
              ) : (
                <Stack spacing="0px">
                  {viewModel.lockedSections.length > 1 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          checked={viewModel.keepAllLockedSections}
                          onChange={(e) => (viewModel.keepAllLockedSections = e.target.checked)}
                        />
                      }
                      label={<Typography fontStyle="italic">{strings.keepAllLockedSectionsLabel}</Typography>}
                      color="textSecondary"
                    />
                  )}
                  {viewModel.lockedSections.map((s) => (
                    <FormControlLabel
                      key={`locked-section-${s.id}`}
                      control={
                        <Checkbox
                          checked={viewModel.keptLockedSectionIds.indexOf(s.id) != -1}
                          onChange={(event) => {
                            if (event.target.checked) {
                              viewModel.keptLockedSectionIds = _.union(viewModel.keptLockedSectionIds, [s.id]);
                            } else {
                              viewModel.keptLockedSectionIds = viewModel.keptLockedSectionIds.filter(
                                (id) => id != s.id
                              );
                            }
                          }}
                        />
                      }
                      label={
                        <SectionName title={`${SectionUtils.formatTitle(s, '?')} [${s.importId}]`} color={s.color} />
                      }
                    />
                  ))}
                </Stack>
              )}
              <FormControlLabel
                disabled={viewModel.keptLockedSectionIds.length === 0}
                control={
                  <Checkbox
                    checked={viewModel.keepSectionSchedules}
                    onChange={(e) => (viewModel.keepSectionSchedules = e.target.checked)}
                  />
                }
                label={strings.keepSectionSchedulesLabel}
                color="textSecondary"
              />
            </>
          )}
          <CreateSchoolSection title={strings.trainingSectionSubSection} viewModel={viewModel.trainingSection} />
        </Stack>
      </CardContent>
    </Card>
  );
});
