import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface PageHeaderDetailBarProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const PageHeaderDetailBar = observer((props: PageHeaderDetailBarProps) => {
  const { className, sx, style, children } = props;

  return (
    <MUI.Box sx={{ ...sx, backgroundColor: 'white' }} className={className} style={style}>
      <MUI.Box paddingX={3} paddingY={2}>
        {children}
      </MUI.Box>
      <MUI.Divider />
    </MUI.Box>
  );
});
