import { AuthorizationRoleCondition } from '@insights/components';
import { UserMenuViewModel } from '@insights/viewmodels';
import ArchivedIcon from '@mui/icons-material/Archive';
import PendingIcon from '@mui/icons-material/HourglassEmpty';
import * as MUI from '@mui/material';
import { styled, SxProps } from '@mui/material';
import { EmployeeAuthorizationRoles } from '@shared/models/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface UserMenuProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: UserMenuViewModel;
}

export const UserMenu = observer((props: UserMenuProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views;
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleDemoMode = () => {
    viewModel.toggleDemoMode();
    setIsOpen(false);
  };

  const navigateToUserSchoolConfigurations = () => {
    void viewModel.navigateToUserSchoolConfigurations();
    setIsOpen(false);
  };

  const navigateToManageSchool = () => {
    if (viewModel.currentConfigId != null) {
      void viewModel.navigateToManageSchool(viewModel.currentConfigId, navigate);
    }

    setIsOpen(false);
  };

  const navigateToSchoolConfigurations = () => {
    void viewModel.navigateToSchoolConfigurations(navigate);

    setIsOpen(false);
  };

  const navigateToOnboardingDashboard = () => {
    void viewModel.navigateToOnboardingDashboard(navigate);

    setIsOpen(false);
  };

  const navigateToUsers = () => {
    void viewModel.navigateToUsers(navigate);

    setIsOpen(false);
  };

  const navigateToSettings = () => {
    void viewModel.navigateToSettings(navigate);

    setIsOpen(false);
  };

  const handleSwitchLanguage = () => {
    void viewModel.switchLanguage();
    setIsOpen(false);
  };

  const handleLogout = async () => {
    await viewModel.logout();
  };

  const showSupport = () => {
    setIsOpen(false);
    if (window.Intercom != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Intercom('show');
    }
  };

  return (
    <Root sx={sx} className={className} style={style} display="flex" flexDirection="row" alignItems="center">
      {viewModel.currentConfigState !== 'active' && (
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <MUI.Tooltip sx={{ ml: 1 }} title={strings.managedGeneral.getConfigStateLabel(viewModel.currentConfigState)}>
            <MUI.IconButton onClick={navigateToManageSchool}>
              {viewModel.currentConfigState === 'archived' ? (
                <ArchivedIcon className={'stateIcon'} />
              ) : (
                <PendingIcon className={'stateIcon'} />
              )}
            </MUI.IconButton>
          </MUI.Tooltip>
        </AuthorizationRoleCondition>
      )}

      <MUI.Button ref={anchorEl} onClick={handleToggle}>
        <MUI.Box display="flex" flexDirection="column" alignItems="flex-end">
          <MUI.Typography variant="body2" className={'userName'} gutterBottom={false} noWrap={true}>
            {viewModel.userInfo.username}
          </MUI.Typography>
          <MUI.Typography variant="body2" className={'schoolInformation'} gutterBottom={false} noWrap={true}>
            {viewModel.currentConfigTitle}
          </MUI.Typography>
        </MUI.Box>
        <MUI.Avatar sx={{ ml: 1 }} className={'avatar'}>
          {viewModel.userInfo.initials}
        </MUI.Avatar>
      </MUI.Button>

      <RaisedPopper open={isOpen} anchorEl={anchorEl.current} transition={true} placement="bottom-end">
        {({ TransitionProps }) => (
          <MUI.Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
            <MUI.Paper>
              <MUI.ClickAwayListener onClickAway={handleClose}>
                <MUI.MenuList>
                  {viewModel.userInfo.hasSchoolConfigurations && (
                    <MUI.MenuItem onClick={navigateToUserSchoolConfigurations}>
                      <MUI.ListItemText primary={strings.selectSchoolConfig} />
                    </MUI.MenuItem>
                  )}
                  {viewModel.currentConfigId && (
                    <AuthorizationRoleCondition allowedRoles={EmployeeAuthorizationRoles}>
                      <MUI.MenuItem onClick={navigateToManageSchool}>
                        <MUI.ListItemText primary={strings.manageThisSchool} />
                      </MUI.MenuItem>
                    </AuthorizationRoleCondition>
                  )}
                  <MUI.MenuItem onClick={handleSwitchLanguage}>
                    <React.Fragment>
                      {/* no need for localization */}
                      {viewModel.currentLocale === 'en' && <MUI.ListItemText primary="Basculer en français" />}
                      {viewModel.currentLocale === 'fr' && <MUI.ListItemText primary="Switch to English" />}
                    </React.Fragment>
                  </MUI.MenuItem>
                  <MUI.MenuItem onClick={showSupport}>
                    <MUI.ListItemText primary={strings.getSupport} />
                  </MUI.MenuItem>
                  <MUI.Divider />
                  <AuthorizationRoleCondition allowedRoles={['super-admin', 'studyo-staff']}>
                    <MUI.MenuItem onClick={navigateToOnboardingDashboard}>
                      <MUI.ListItemText primary={strings.onboardingDashboard} />
                    </MUI.MenuItem>
                    <MUI.Divider />
                  </AuthorizationRoleCondition>
                  {/* Studyo Staff users can toggle the demo mode, but only show that priviledge
                      when currently viewing a school. */}
                  {viewModel.currentConfigId ? (
                    <AuthorizationRoleCondition allowedRoles={['super-admin', 'studyo-staff']}>
                      <MUI.MenuItem onClick={toggleDemoMode}>
                        <MUI.Box flex={1} display="flex" flexDirection="row" alignItems="center">
                          <MUI.ListItemText primary={strings.demoMode} />
                          <MUI.Box flex={1} />
                          <MUI.Switch checked={viewModel.demoMode} />
                        </MUI.Box>
                      </MUI.MenuItem>
                    </AuthorizationRoleCondition>
                  ) : (
                    <AuthorizationRoleCondition allowedRoles={['super-admin']} allowRootAdmins>
                      <MUI.MenuItem onClick={toggleDemoMode}>
                        <MUI.Box flex={1} display="flex" flexDirection="row" alignItems="center">
                          <MUI.ListItemText primary={strings.demoMode} />
                          <MUI.Box flex={1} />
                          <MUI.Switch checked={viewModel.demoMode} />
                        </MUI.Box>
                      </MUI.MenuItem>
                    </AuthorizationRoleCondition>
                  )}
                  <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                    <MUI.MenuItem onClick={navigateToSchoolConfigurations}>
                      <MUI.ListItemText primary={strings.schoolConfigs} />
                    </MUI.MenuItem>
                    <MUI.MenuItem onClick={navigateToUsers}>
                      <MUI.ListItemText primary={strings.users} />
                    </MUI.MenuItem>
                    <MUI.MenuItem onClick={navigateToSettings}>
                      <MUI.ListItemText primary={strings.settingsMenu} />
                    </MUI.MenuItem>
                    <MUI.Divider />
                  </AuthorizationRoleCondition>
                  <MUI.MenuItem onClick={() => void handleLogout()}>
                    <MUI.ListItemText primary={strings.logout} />
                  </MUI.MenuItem>
                </MUI.MenuList>
              </MUI.ClickAwayListener>
            </MUI.Paper>
          </MUI.Grow>
        )}
      </RaisedPopper>
    </Root>
  );
});

const Root = styled(MUI.Box)(({ theme }) => ({
  '.userName': {
    color: theme.palette.primary.contrastText,
    fontWeight: 500
  },
  '.schoolInformation': {
    color: theme.palette.primary.contrastText
  },
  '.avatar': {
    backgroundColor: theme.palette.secondary.main
  },
  '.stateIcon': {
    color: theme.palette.primary.contrastText
  },
  '.menuSubHeader': {
    outline: 'none'
  },
  '.divider': {
    outline: 'none'
  }
}));

const RaisedPopper = styled(MUI.Popper)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1
}));
