import { MenuItem, Select, SxProps, Typography } from '@mui/material';
import { BlackbaudAssignmentKind } from '@shared/models/connectors';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface BlackbaudAssignmentKindSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  availableKinds: BlackbaudAssignmentKind[];
  selectedKindId: number;
  onChange: (kind: BlackbaudAssignmentKind | undefined) => void;
}

export const BlackbaudAssignmentKindSelector = observer((props: BlackbaudAssignmentKindSelectorProps) => {
  const { sx, className, style, availableKinds, selectedKindId, onChange } = props;

  return (
    <Select
      sx={sx}
      className={className}
      style={style}
      fullWidth
      value={selectedKindId}
      onChange={(event) => {
        const value = Number(event.target.value);
        if (!Number.isNaN(value)) {
          onChange(availableKinds.find((kind) => kind.id === BigInt(value)));
        }
      }}
    >
      {availableKinds.map((kind) => (
        <MenuItem key={Number(kind.id)} value={Number(kind.id)}>
          <Typography>{kind.name}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
});
