import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { ImportSession } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportSessionEditDialogProps extends DialogResult<void> {
  sx?: SxProps;
  session: ImportSession;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionEditDialog = observer((props: ImportSessionEditDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, style, className, session, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createImportSessionEdit(session, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="sm"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <MUI.DialogTitle>{strings.editSessionTitle}</MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <MUI.Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
              <MUI.TextField
                sx={{ mb: 2 }}
                label={strings.sessionNameLabel}
                value={viewModel.name}
                fullWidth
                required
                autoFocus
                onChange={(e) => (viewModel.name = e.target.value)}
              />

              <MUI.TextField
                sx={{ mb: 2 }}
                label={strings.sessionDescriptionLabel}
                value={viewModel.description}
                multiline
                fullWidth
                onChange={(e) => (viewModel.description = e.target.value)}
              />
            </MUI.Box>
          </MUI.DialogContent>

          <MUI.DialogActions>
            {viewModel.hasError && (
              <MUI.Typography flex={1} variant="caption" color="error" style={{ textAlign: 'end' }}>
                {strings.editSessionError}
              </MUI.Typography>
            )}
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => void viewModel.cancel()}>
              {strings.editSessionCancelButtonLabel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={!viewModel.canSave}
              isExecuting={viewModel.isExecuting}
              onClick={() => void viewModel.save()}
            >
              {strings.editSessionSaveButtonLabel}
            </InsightsButton>
          </MUI.DialogActions>
        </form>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
