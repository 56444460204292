import {
  InsightsButton,
  TransformationIndexedSources,
  TransformationSchema,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { ImportSession, Schema } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportSessionAddOrEditTransformationDialogProps extends DialogResult<ImportSession> {
  sx?: SxProps;
  configId: string;
  session: ImportSession;
  transformationLabel?: string;
  schemas?: Schema[];
}

export const ImportSessionAddOrEditTransformationDialog = observer(
  (props: ImportSessionAddOrEditTransformationDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { sx, configId, session, transformationLabel, schemas, onSuccess, onCancel } = props;
    const strings = localizationService.localizedStrings.insights.views.imports;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createImportSessionAddOrEditTransformation(
        configId,
        session,
        transformationLabel ?? '',
        schemas ?? [],
        onSuccess!,
        onCancel!
      )
    );

    return (
      <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
        <MUI.Dialog sx={sx} open={true} maxWidth="sm" fullWidth onClose={() => viewModel.cancel()}>
          <MUI.DialogTitle>{strings.addTransformationTitle}</MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <MUI.TextField
              sx={{ mb: 1 }}
              label={strings.labelLabel}
              inputProps={{ maxLength: 1 }}
              autoFocus
              value={viewModel.label}
              helperText={viewModel.labelError}
              error={viewModel.labelError.length > 0}
              onChange={(e) => (viewModel.label = e.target.value)}
            />
            <MUI.TextField
              sx={{ mb: 1 }}
              fullWidth
              label={strings.transformationNameLabel}
              inputProps={{ maxLength: 50 }}
              value={viewModel.name}
              onChange={(e) => (viewModel.name = e.target.value)}
            />
            <MUI.TextField
              sx={{ mb: 1 }}
              fullWidth
              label={strings.transformationDescriptionLabel}
              inputProps={{ maxLength: 250 }}
              value={viewModel.description}
              onChange={(e) => (viewModel.description = e.target.value)}
            />
            <MUI.TextField
              select
              fullWidth
              label={strings.sourceLabelLabel}
              value={viewModel.sourceLabel}
              onChange={(e) => (viewModel.sourceLabel = e.target.value)}
            >
              {viewModel.availableSources.map((source) => (
                <MUI.MenuItem key={`source-label-${source.label}`} value={source.label}>
                  {`${source.label} - ${source.name}`}
                </MUI.MenuItem>
              ))}
            </MUI.TextField>
            <TransformationIndexedSources sx={{ mb: 1 }} viewModel={viewModel.indexedSourcesViewModel} />
            <TransformationSchema sx={{ mt: 2, mb: 1 }} viewModel={viewModel.schemaViewModel} />
          </MUI.DialogContent>

          <MUI.DialogActions>
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => viewModel.cancel()}>
              {strings.cancel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isExecuting={viewModel.isExecuting}
              isDisabled={!viewModel.canSave}
              onClick={() => void viewModel.saveTransformation()}
            >
              {viewModel.isNew ? strings.addButton : strings.saveButton}
            </InsightsButton>
          </MUI.DialogActions>
        </MUI.Dialog>
      </MUI.ThemeProvider>
    );
  }
);
