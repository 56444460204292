import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { AccountOutlineViewModel } from '@insights/viewmodels';
import { Stack, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';

export interface CreateSchoolAccountProps {
  sx?: SxProps;
  className?: string;
  viewModel: AccountOutlineViewModel;
  title: string;
}

export const CreateSchoolAccount = observer(({ sx, className, viewModel, title }: CreateSchoolAccountProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.createSchool;

  return (
    <Stack sx={sx} className={className}>
      <Typography variant="h6">{title}</Typography>
      <Stack direction="row" spacing={2}>
        <TextField
          sx={{ flex: 1 }}
          required
          value={viewModel.firstName}
          label={strings.firstNameLabel}
          onChange={(event) => (viewModel.firstName = event.target.value)}
        />
        <TextField
          sx={{ flex: 1 }}
          required
          value={viewModel.lastName}
          label={strings.lastNameLabel}
          onChange={(event) => (viewModel.lastName = event.target.value)}
        />
        <TextField
          sx={{ flex: 1 }}
          required
          value={viewModel.email}
          label={strings.emailLabel}
          onChange={(event) => (viewModel.email = event.target.value)}
        />
      </Stack>
      {!viewModel.canSubmit && (
        <Typography variant="caption" color="error">
          {viewModel.isOptional ? strings.incompleteAccountWarning : strings.invalidAccountWarning}
        </Typography>
      )}
    </Stack>
  );
});
