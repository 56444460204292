import { MenuItem, Select, SxProps, Typography } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { AllContentIcons, ContentIcon, ContentWorkloadLevel } from '@shared/models/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Row } from '../layout';

export interface ContentIconSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  selectedIcon: ContentIcon;
  workloadLevel: ContentWorkloadLevel;
  onChange: (icon: ContentIcon) => void;
}

export const ContentIconSelector = observer((props: ContentIconSelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, selectedIcon, workloadLevel, onChange } = props;
  const contentsStrings = localizationService.localizedStrings.models.contents;

  return (
    <Row sx={sx} className={className} style={style}>
      <Select fullWidth value={selectedIcon} onChange={(event) => onChange(event.target.value as ContentIcon)}>
        {AllContentIcons.map((icon) => (
          <MenuItem key={icon} value={icon}>
            <Row verticalContentAlignment="center">
              <TaskIcon icon={icon} workloadLevel={workloadLevel} />
              <Typography sx={{ ml: 1 }}>{contentsStrings.defaultTitle(icon)}</Typography>
            </Row>
          </MenuItem>
        ))}
      </Select>
    </Row>
  );
});
