import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { UserProfile } from '@shared/models/user';
import { observer } from 'mobx-react';
import { IPromiseBasedObservable } from 'mobx-utils';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { LoadingIndicator } from '../LoadingIndicator';
import { UserInfo } from './UserInfo';

export interface LiveUserInfoProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  userId: string;
  userProfileData: IPromiseBasedObservable<UserProfile | undefined>;
  accountEmail?: string;
}

export const LiveUserInfo = observer(({ userId, userProfileData, accountEmail }: LiveUserInfoProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.components.users;
  const theme = useTheme();

  return userProfileData.case({
    pending: () => <LoadingIndicator size="small" />,
    rejected: () => (
      <MUI.Box display="flex" flexDirection="column">
        <MUI.Typography variant="body2">{strings.loadingUserProfileError}</MUI.Typography>
        <code style={{ color: theme.palette.grey[400] }}>{userId}</code>
      </MUI.Box>
    ),
    fulfilled: (userProfile) =>
      userProfile == null ? (
        <code style={{ color: theme.palette.grey[400] }}>{userId}</code>
      ) : (
        <UserInfo profile={userProfile} accountEmail={accountEmail} />
      )
  });
});
