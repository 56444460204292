import { AccountInfo, SectionInfo, accountInfoFromModel } from '@insights/models';
import { AccountService, AlertService, NavigationService, SettingsStore } from '@insights/services';
import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { CalendarStore, ContentStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppPaginatedViewModel, AppPaginationViewModel, PaginatedViewModel } from './PaginatedViewModel';
import { AppSectionsViewModel } from './SectionsViewModel';
import { AppTeacherExportsViewModel, TeacherExportsViewModel } from './TeacherExportsViewModel';

export interface TeacherDetailsInfo {
  config: SchoolYearConfigurationSummary;
  accountInfo: AccountInfo;
  displayedSectionIds: string[];
  filterCount: number;
}

export interface TeacherDetailsViewModel extends PaginatedViewModel {
  readonly configId: string;
  readonly teacherId: string;
  readonly data: IPromiseBasedObservable<TeacherDetailsInfo>;

  readonly exports: TeacherExportsViewModel;

  navigateToPlanner: () => Promise<void>;
  showFilters: () => Promise<void>;
}

export class AppTeacherDetailsViewModel extends AppPaginatedViewModel implements TeacherDetailsViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _settingsStore: SettingsStore,
    private readonly _contentStore: ContentStore,
    private readonly _calendarStore: CalendarStore,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _accountService: AccountService,
    private readonly _alertService: AlertService,
    public readonly configId: string,
    public readonly teacherId: string
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<TeacherDetailsInfo> {
    return fromPromise(this.loadData());
  }

  @computed
  get exports(): TeacherExportsViewModel {
    return new AppTeacherExportsViewModel(
      this._schoolYearConfigurationStore,
      this._contentStore,
      this._calendarStore,
      this._accountService,
      this._localizationService,
      this._alertService,
      this.configId,
      this.teacherId
    );
  }

  async showFilters(): Promise<void> {
    const configPreferences = this._settingsStore.getConfigPreferences(this.configId);
    const teacherDetailsFilters = await configPreferences.getTeacherDetailsFilters(this.teacherId);

    const result = await this._navigationService.navigateToTeacherDetailsFilters(
      this.configId,
      this.teacherId,
      teacherDetailsFilters
    );

    if (result !== 'cancelled' && !_.isEqual(result, teacherDetailsFilters)) {
      await configPreferences.saveTeacherDetailsFilters(this.teacherId, result);
    }
  }

  navigateToPlanner(): Promise<void> {
    return this._navigationService.navigateToPlannerExternal(this.configId, this.teacherId);
  }

  private async loadData(): Promise<TeacherDetailsInfo> {
    // IMPORTANT: In order to invalidate the data and have MOBX reevaluate the loadData,
    //            we must call this async method before the other ones. This is until we change
    //            approach and use sync stores.
    const configPreferences = this._settingsStore.getConfigPreferences(this.configId);
    const teacherDetailsFilters = await configPreferences.getTeacherDetailsFilters(this.teacherId);

    const [config, teacher] = await Promise.all([
      this._schoolYearConfigurationStore.getConfigSummary(this.configId),
      this._schoolYearConfigurationStore.getTeacher(this.configId, this.teacherId, false)
    ]);

    this.setPagination(new AppPaginationViewModel(config.startDay, config.endDay));

    const sections = await this._schoolYearConfigurationStore.getTaughtSectionsForTeacher(this.configId, teacher);
    const sectionInfos = await Promise.all(
      sections.map<Promise<SectionInfo>>(async (section) => {
        const [students, teachers] = await Promise.all([
          this._schoolYearConfigurationStore.getStudentsForSection(this.configId, section, false),
          this._schoolYearConfigurationStore.getTeachersForSection(this.configId, section, false)
        ]);

        return {
          id: section.id,
          section: section,
          students: students.map(accountInfoFromModel),
          teachers: teachers.map(accountInfoFromModel)
        };
      })
    );

    return {
      config,
      accountInfo: {
        id: teacher.id,
        account: teacher,
        sections: new AppSectionsViewModel(
          this._navigationService,
          this._localizationService,
          this.configId,
          sectionInfos
        )
      },
      displayedSectionIds: sections
        .filter((section) => teacherDetailsFilters.excludedSectionIds.indexOf(section.id) === -1)
        .map((section) => section.id),
      filterCount: teacherDetailsFilters.excludedSectionIds.length
    };
  }
}
