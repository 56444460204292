import {
  CurrentPageInfo,
  ObservablePresenter,
  PageFooter,
  PageHeaderBar,
  PageHeaderDetailBar,
  WeekPagingNavigation
} from '@insights/components';
import { TeacherDashboardViewModel } from '@insights/viewmodels';
import FilterIcon from '@mui/icons-material/FilterList';
import NotesIcon from '@mui/icons-material/NoteAlt';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { observer } from 'mobx-react';
import { CSSProperties, useEffect } from 'react';
import { useInsightsServices } from '../../../../UseInsightsServicesHook.ts';
import { PublishedTasksBySection } from '../PublishedTasksBySection';
import { SectionsCourseOccurrencesStatus } from '../SectionsCourseOccurrencesStatus';
import { WorkloadManagerBySection } from '../WorkloadManagerBySection';

export interface TeacherDashboardProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: TeacherDashboardViewModel;
}

export const TeacherDashboard = observer((props: TeacherDashboardProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const { dashboards: dashboardStrings, teachers: teachersStrings } =
    localizationService.localizedStrings.insights.views.metrics;

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Teacher Dashboard' });
  });

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
          data={viewModel.data}
          loadingMessage={dashboardStrings.loadingMessage}
          errorMessage={dashboardStrings.errorMessage}
          render={(data) => (
            <MUI.Box display="flex" flexDirection="column" sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
              {/* Teacher Info */}
              <PageHeaderDetailBar>
                <MUI.Box display="flex" flexDirection="row" alignItems="center">
                  {/* Teacher name and email */}
                  {data.accountInfo && (
                    <MUI.Box>
                      <MUI.Typography sx={{ fontSize: '2em', lineHeight: 'normal', fontWeight: 300 }}>
                        {AccountUtils.getDisplayLastFirstName(data.accountInfo.account, teachersStrings.noName)}
                      </MUI.Typography>
                      <MUI.Link variant="body1" target="_top" href={`mailto:${data.accountInfo.account.email}`}>
                        {data.accountInfo.account.email}
                      </MUI.Link>
                    </MUI.Box>
                  )}

                  <MUI.Box flex={1} />

                  {viewModel.exports?.canExportNotes === true && (
                    <MUI.Tooltip title={teachersStrings.exportNotesTooltip}>
                      <MUI.IconButton sx={{ marginRight: 2 }} onClick={() => void viewModel.exports!.exportNotes()}>
                        <NotesIcon />
                      </MUI.IconButton>
                    </MUI.Tooltip>
                  )}

                  <MUI.Tooltip title={teachersStrings.filterButtonTooltip}>
                    <MUI.IconButton onClick={() => void viewModel.showFilters()}>
                      <MUI.Badge badgeContent={data.filterCount} color="primary" showZero={false} variant="dot">
                        <FilterIcon />
                      </MUI.Badge>
                    </MUI.IconButton>
                  </MUI.Tooltip>
                </MUI.Box>
              </PageHeaderDetailBar>

              <MUI.Box flex={1} overflow="auto" padding={2}>
                <MUI.Grid container>
                  {/* Course Occurrences Status */}
                  <MUI.Grid item xs={12}>
                    <MUI.Card>
                      <SectionsCourseOccurrencesStatus
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createSectionsCourseOccurrencesStatus(
                          viewModel.configId,
                          data.displayedSectionIds,
                          viewModel.pagination
                        )}
                        displaySectionName={true}
                        displayWeekNavigation={false}
                      />
                    </MUI.Card>
                  </MUI.Grid>

                  {/* Published Tasks */}
                  <MUI.Grid item xs={12}>
                    <MUI.Card>
                      <PublishedTasksBySection
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createPublishedTasksBySection(
                          viewModel.configId,
                          data.displayedSectionIds,
                          viewModel.pagination
                        )}
                        displaySectionName={true}
                        displayWeekNavigation={false}
                      />
                    </MUI.Card>
                  </MUI.Grid>

                  {/* Workload Manager */}
                  <MUI.Grid item xs={12}>
                    <MUI.Card>
                      <WorkloadManagerBySection
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createWorkloadManagerBySection(
                          viewModel.configId,
                          data.displayedSectionIds,
                          viewModel.pagination
                        )}
                        displaySectionName={true}
                        displayWeekNavigation={false}
                      />
                    </MUI.Card>
                  </MUI.Grid>
                </MUI.Grid>
              </MUI.Box>

              <PageFooter dense>
                <MUI.Box display="flex" flexDirection="row" alignItems="center">
                  <CurrentPageInfo pagination={viewModel.pagination} />

                  <MUI.Box flex={1} />

                  <WeekPagingNavigation pagination={viewModel.pagination} />
                </MUI.Box>
              </PageFooter>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
