import { css } from '@emotion/css';
import { BlackbaudSkyAccountViewModel } from '@insights/viewmodels';
import { Link, Stack, SxProps, TextField, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsButton } from '../InsightsButton';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface BlackbaudSkyAccountProps {
  sx?: SxProps;
  className?: string;
  viewModel: BlackbaudSkyAccountViewModel;
}

export const BlackbaudSkyAccount = observer((props: BlackbaudSkyAccountProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx, className } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;
  const theme = useTheme();

  const blockClassName = css([{ margin: theme.spacing(1) }]);
  const buttonClassName = css([{ margin: theme.spacing(1) }]);

  return (
    <Column sx={sx} className={className}>
      {(viewModel.isNew || viewModel.tokens == null) && (
        <Stack sx={{ m: 1, mb: 2 }} direction="column">
          <Typography color={theme.palette.info.main}>{strings.blackbaudSkyBeforeNotice}</Typography>
          <Link
            variant="caption"
            color={theme.palette.info.dark}
            target="_blank"
            href="https://app.blackbaud.com/marketplace/applications/67bf049a-3f87-49cd-b1fa-58901631d3d4"
          >
            https://app.blackbaud.com/marketplace/applications/67bf049a-3f87-49cd-b1fa-58901631d3d4
          </Link>
        </Stack>
      )}

      <TextField
        className={blockClassName}
        label={strings.accountName}
        value={viewModel.accountName}
        onChange={(event) => (viewModel.accountName = event.target.value)}
      />

      {viewModel.isNew ? (
        <Typography className={blockClassName}>{strings.blackbaudSkyAuthorizationNotice}</Typography>
      ) : viewModel.tokens == null ? (
        <Column>
          <Typography className={blockClassName}>{strings.blackbaudSkyNotAuthorized}</Typography>
          <InsightsButton className={buttonClassName} onClick={() => void viewModel.repeatAuthorization()}>
            {strings.blackbaudSkyAuthorize}
          </InsightsButton>
        </Column>
      ) : (
        <Column>
          <Column className={blockClassName}>
            <Typography>{strings.blackbaudSkyAuthorized}</Typography>
            <Typography variant="caption">{viewModel.tokens.environmentName}</Typography>
            <Typography variant="caption">{viewModel.tokens.legalEntityName}</Typography>
            <Typography variant="caption">
              {viewModel.tokens.lastName}, {viewModel.tokens.firstName}
            </Typography>
            <Typography variant="caption">{viewModel.tokens.email}</Typography>
          </Column>
          <InsightsButton className={buttonClassName} onClick={() => void viewModel.repeatAuthorization()}>
            {strings.blackbaudSkyRepeatAuthorization}
          </InsightsButton>
        </Column>
      )}

      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
