import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface InsightsButtonProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  showSpinnerOnExecuting?: boolean;
  isDefault?: boolean;
  isSubmit?: boolean;
  isExecuting?: boolean;
  isDisabled?: boolean;
  percentage?: number;
  children?: React.ReactNode;
}

export const InsightsButton = observer(
  ({
    sx,
    className,
    style,
    children,
    onClick,
    showSpinnerOnExecuting = false,
    isDefault = false,
    isSubmit = false,
    isExecuting = false,
    isDisabled = false,
    percentage
  }: InsightsButtonProps) => {
    function handleButtonClick() {
      if (onClick) {
        requestAnimationFrame(() => onClick());
      }
    }

    const displayAsDefault = isDefault || isSubmit;
    const displaySpinner = showSpinnerOnExecuting && isExecuting;

    return (
      <MUI.Box sx={sx} className={className} style={style}>
        <MUI.Button
          sx={{ minWidth: 100, boxShadow: displayAsDefault ? 'none' : undefined }}
          variant={displayAsDefault ? 'contained' : 'outlined'}
          type={isSubmit ? 'submit' : undefined}
          color={displayAsDefault ? 'primary' : undefined}
          onClick={() => handleButtonClick()}
          disabled={isExecuting || isDisabled}
        >
          {!displaySpinner && children}

          {displaySpinner &&
            (percentage != null ? (
              <MUI.Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <MUI.CircularProgress size={24} color="inherit" variant="determinate" value={percentage} />
                <MUI.Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <MUI.Typography sx={{ fontSize: 10 }} component="div" color="text.secondary">{`${Math.round(
                    percentage
                  )}%`}</MUI.Typography>
                </MUI.Box>
              </MUI.Box>
            ) : (
              <MUI.CircularProgress size={24} color="inherit" variant="indeterminate" />
            ))}
        </MUI.Button>
      </MUI.Box>
    );
  }
);
