import { BehaviourHistoryChart, ObservablePresenter, RequiresFeatureCondition } from '@insights/components';
import { BehaviourSummaryItemType } from '@insights/models';
import { StudentBehaviourHistoryViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface StudentBehaviourHistoryProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: StudentBehaviourHistoryViewModel;
}

export const StudentBehaviourHistory = observer((props: StudentBehaviourHistoryProps) => {
  const { accountService, localizationService, navigationService } = useInsightsServices();
  const { className, sx, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.students;

  const handleBehaviourClicked = async (behaviourType: BehaviourSummaryItemType) => {
    if (!accountService.isAllowed(['super-admin', 'admin'])) {
      return;
    }

    switch (behaviourType) {
      case 'app-open':
        await navigationService.navigateToSessionsPerDay(viewModel.configId, viewModel.studentId, 'student');
        break;

      case 'task-completion':
        await navigationService.navigateToStudentPlannerStatus(viewModel.configId, viewModel.studentId);
        break;

      default:
        throw new Error('Unknown behaviour type');
    }
  };

  return (
    <RequiresFeatureCondition
      sx={{
        ...sx,
        height: 210, // This is based on the number of types we are showing. Adjust accordingly.
        minWidth: 600
      }}
      className={className}
      style={style}
      requiredFeature="organizational-quotient"
      featureNotAvailableContent={{
        fill: true,
        padding: 2,
        title: strings.behaviourHistoryCaption,
        titleVariant: 'h6'
      }}
    >
      <ObservablePresenter
        sx={{ height: '100%', width: '100%' }}
        data={viewModel.data}
        loadingMessage={strings.loadingStudentBehavioursMessage}
        errorMessage={strings.loadingStudentBehavioursErrorMessage}
        render={(data) => {
          if (data.length === 0) {
            return (
              <MUI.Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <MUI.Typography variant="h5">{strings.noOQDataMessage}</MUI.Typography>
              </MUI.Box>
            );
          }

          return (
            <BehaviourHistoryChart
              sx={{ height: '100%', width: '100%' }}
              caption={strings.behaviourHistoryCaption}
              subcaption={strings.behaviourHistorySubcaption}
              data={data}
              onBehaviourClicked={(type) => void handleBehaviourClicked(type)}
            />
          );
        }}
      />
    </RequiresFeatureCondition>
  );
});
