import { AccountInfo } from '@insights/models';
import { AccountInfoUtils, CustomFilterUtils } from '@insights/utils';
import { AccountSectionsEditionCopyViewModel } from '@insights/viewmodels';
import { Action, Column as TableColumn } from '@material-table/core';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { SxProps, Typography, styled, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { observer } from 'mobx-react';
import moize from 'moize';
import * as React from 'react';
import { DefaultTablePageSize, DefaultTablePageSizes } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsMaterialTable } from '../InsightsMaterialTable';
import { SectionsInlineList } from '../SectionsInlineList';
import { Container, Row } from '../layout';

const TableStateKey = 'AccountSectionCopy';

export interface AccountSectionsEditionCopyProps {
  sx?: SxProps;
  style?: React.CSSProperties;
  className?: string;
  viewModel: AccountSectionsEditionCopyViewModel;
}

export const AccountSectionsEditionCopy = observer((props: AccountSectionsEditionCopyProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, viewModel, style, className } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.insights.views.account;

  const actions: Action<AccountInfo>[] = [
    {
      icon: () => <FileCopyIcon color="action" />,
      isFreeAction: false,
      tooltip: strings.copySelectedSectionsTooltip,
      onClick: (_, data: AccountInfo | AccountInfo[]) => {
        const account = data as AccountInfo;

        if (account != null) {
          void viewModel.copy(account);
        }
      }
    }
  ];

  function renderColumns(): TableColumn<AccountInfo>[] {
    const strings = localizationService.localizedStrings.insights.components.accounts;

    const nameWidthPercent = 33;
    const sectionsWidthPercent = 67;

    const columns: TableColumn<AccountInfo>[] = [
      {
        title: strings.name,
        defaultSort: 'asc',
        headerStyle: {
          paddingLeft: theme.spacing(0.5),
          width: `${nameWidthPercent}%`
        },
        cellStyle: {
          paddingLeft: theme.spacing(0.5),
          width: `${nameWidthPercent}%`
        },
        customSort: (a: AccountInfo, b: AccountInfo) =>
          AccountInfoUtils.compareNames(a, b, localizationService.currentLocale),
        customFilterAndSearch: (filter: string, account: AccountInfo) =>
          CustomFilterUtils.customFilterAndSearch(filter, account, getSearchableFields),
        render: (rowData: AccountInfo) => {
          return (
            <Container sx={{ my: 0.5 }} className="item">
              <Row verticalContentAlignment="center">
                <Typography>{AccountUtils.getDisplayLastFirstName(rowData.account)}</Typography>
              </Row>
            </Container>
          );
        }
      },
      {
        title: strings.sections,
        sorting: false,
        cellStyle: {
          width: `${sectionsWidthPercent}%`
        },
        render: (rowData: AccountInfo) => {
          return (
            <SectionsInlineList
              sections={rowData.sections?.sections ?? []}
              configId={viewModel.configId}
              linkTo={false}
              noneLabel={strings.noneLabel}
            />
          );
        }
      }
    ];

    return columns;
  }

  const getSearchableFields = moize((a: AccountInfo) => [
    AccountUtils.getDisplayLastFirstName(a.account),
    a.account.email,
    a.account.managedIdentifier,
    ...(a.sections?.sections.map((s) => s.section?.title ?? '') ?? [])
  ]);

  return (
    <Root sx={sx} className={className} style={style}>
      <InsightsMaterialTable
        stateKey={TableStateKey}
        // This is to disable the card contour
        components={{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>
        }}
        title={strings.copySelectedSectionsTitle}
        columns={renderColumns()}
        data={viewModel.students}
        options={{
          pageSize: DefaultTablePageSize,
          pageSizeOptions: DefaultTablePageSizes,
          rowStyle: { backgroundColor: '#fff', verticalAlign: 'top' },
          headerStyle: { fontSize: theme.typography.body2.fontSize },
          emptyRowsWhenPaging: false,
          draggable: false,
          actionsColumnIndex: -1
        }}
        localization={{
          ...localizationService.localizedStrings.insights.materialTable,
          header: { actions: '' }
        }}
        actions={actions}
      />
    </Root>
  );
});

const Root = styled(Container)(() => ({
  '.item': {
    minHeight: 30
  }
}));
