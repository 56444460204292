import { PaginationViewModel } from '@insights/viewmodels';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Today from '@mui/icons-material/Today';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

export interface WeekPagingNavigationProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  pagination: PaginationViewModel | undefined;
}

export const WeekPagingNavigation = observer((props: WeekPagingNavigationProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, pagination } = props;
  const strings = localizationService.localizedStrings.insights.components;

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="row" alignItems="center">
      <MUI.IconButton
        size="large"
        disabled={!(pagination?.canGoToFirstPage ?? false)}
        onClick={() => pagination?.goToFirstPage()}
      >
        <MUI.Tooltip title={strings.firstWeek}>
          <FirstPage />
        </MUI.Tooltip>
      </MUI.IconButton>
      <MUI.IconButton
        size="large"
        disabled={!(pagination?.canGoToPreviousPage ?? false)}
        onClick={() => pagination?.goToPreviousPage()}
      >
        <MUI.Tooltip title={strings.previousWeek}>
          <ChevronLeft />
        </MUI.Tooltip>
      </MUI.IconButton>
      <MUI.IconButton size="large" onClick={() => pagination?.goToDefaultPage()}>
        <MUI.Tooltip title={strings.currentWeek}>
          <Today />
        </MUI.Tooltip>
      </MUI.IconButton>
      <MUI.IconButton
        size="large"
        disabled={!(pagination?.canGoToNextPage ?? false)}
        onClick={() => pagination?.goToNextPage()}
      >
        <MUI.Tooltip title={strings.nextWeek}>
          <ChevronRight />
        </MUI.Tooltip>
      </MUI.IconButton>
      <MUI.IconButton
        size="large"
        disabled={!(pagination?.canGoToLastPage ?? false)}
        onClick={() => pagination?.goToLastPage()}
      >
        <MUI.Tooltip title={strings.lastWeek}>
          <LastPage />
        </MUI.Tooltip>
      </MUI.IconButton>
    </MUI.Box>
  );
});
