// TODO MUI.Box

import { ManageableRole } from '@insights/enums';
import { EditableAccountInfo } from '@insights/models';
import { AccountInfoUtils, CustomFilterUtils } from '@insights/utils';
import { EditableAccountsViewModel } from '@insights/viewmodels';
import { Action, MTableAction, Column as TableColumn } from '@material-table/core';
import { mdiAccount, mdiAlphaXCircle } from '@mdi/js';
import Icon from '@mdi/react';
import LegendIcon from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import UnconfirmedIcon from '@mui/icons-material/QuestionMark';
import AdminIcon from '@mui/icons-material/VpnKey';
import {
  Box,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Snackbar,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AdminAuthorizationRoles, AdminOrTeacherAuthorizationRoles } from '@shared/models/types';
import { ScreenType } from '@shared/services';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import moize from 'moize';
import * as React from 'react';
import { useState } from 'react';
import { DefaultTablePageSize, DefaultTablePageSizes } from '../../Constants';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsMaterialTable } from '../InsightsMaterialTable';
import { MoreActionMenu } from '../MoreActionMenu';
import { OptionalRouterLink } from '../RouterLink';
import { SectionsInlineList } from '../SectionsInlineList';
import { Column, Container, Expanded, Row } from '../layout';

const TableStateKey = 'EditableAccountList';

export interface EditableAccountListProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: EditableAccountsViewModel;
  title?: string;
  configId: string;
  accountType: ManageableRole;
}

export const EditableAccountList = observer((props: EditableAccountListProps) => {
  const { accountService, localizationService, reactRouterRouteService, screenService } = useInsightsServices();
  const { sx, className, style, viewModel, title, configId, accountType } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.insights.components.accounts;

  const [showToast, setShowToast] = useState(false);
  const tableStateKey = `${TableStateKey}-${accountType}`;

  function renderColumns(): TableColumn<EditableAccountInfo>[] {
    const renderParents = accountType === 'student';
    const renderChildren = accountType === 'parent';
    const renderSections = accountType !== 'parent';
    const renderGradeLevel = accountType === 'student';

    return _.compact([
      renderStudyoUserColumn(),
      renderNameColumn(renderSections && (renderParents || renderChildren) ? 20 : 25),
      renderEmailColumn(renderSections && (renderParents || renderChildren) ? 20 : 25),
      renderGradeLevel ? renderGradeLevelColumn() : undefined,
      renderSections ? renderSectionsColumn(renderParents || renderChildren ? 40 : 50) : undefined,
      renderParents
        ? renderParentsColumn(renderSections ? 20 : 50, screenService.screenType < ScreenType.extraLarge)
        : undefined,
      renderChildren ? renderChildrenColumn(renderSections ? 20 : 50) : undefined
    ]);
  }

  function renderStudyoUserColumn(): TableColumn<EditableAccountInfo> {
    const strings = localizationService.localizedStrings.insights.components.accounts;

    return {
      sorting: false,
      grouping: false,
      searchable: false,
      width: '1%',
      cellStyle: {
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: 32
      },
      render: (rowData: EditableAccountInfo) => {
        let shouldDisplayUserColumn = false;
        let iconPath = '';
        let iconColor = '';
        let toolTip = '';

        if (rowData.account.isDeleted) {
          shouldDisplayUserColumn = true;
          iconPath = mdiAlphaXCircle;
          iconColor = theme.palette.error.main;
          toolTip = strings.deletedAccountTooltip;
        } else if (rowData.account.userId.length > 0) {
          shouldDisplayUserColumn = true;
          iconPath = mdiAccount;
          iconColor = theme.palette.secondary.main;
          toolTip = strings.connectedUserTooltip;
        }

        return (
          shouldDisplayUserColumn && (
            <Box
              marginY={0.5}
              minHeight={30}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Tooltip title={toolTip}>
                <Icon path={iconPath} size={1} color={iconColor} />
              </Tooltip>
            </Box>
          )
        );
      }
    };
  }

  function renderNameColumn(widthPercent: number): TableColumn<EditableAccountInfo> {
    const insightsStrings = localizationService.localizedStrings.insights;
    const accountStrings = insightsStrings.components.accounts;

    return {
      title: accountStrings.name,
      defaultSort: 'asc',
      width: `${widthPercent}%`,
      customSort: (a: EditableAccountInfo, b: EditableAccountInfo) =>
        AccountInfoUtils.compareNames(a, b, localizationService.currentLocale),
      customFilterAndSearch: (filter: string, account: EditableAccountInfo) =>
        CustomFilterUtils.customFilterAndSearch(filter, account, getSearchableFields, viewModel.namedFilters),
      render: (rowData: EditableAccountInfo) => {
        const { routeTemplate, accountIdParamName } = getRouteTemplateAndParamName();

        return (
          <Container sx={{ my: 0.5, minHeight: 30 }}>
            <Row verticalContentAlignment="center">
              <OptionalRouterLink
                variant="body1"
                to={reactRouterRouteService.resolveLocation(routeTemplate, [
                  { name: RouteParamNames.configId, value: configId },
                  { name: accountIdParamName, value: rowData.account.id }
                ])}
                disabled={routeTemplate.length === 0}
              >
                {AccountUtils.getDisplayLastFirstName(rowData.account, insightsStrings.noName)}
              </OptionalRouterLink>
              {rowData.account.isAdmin && <AdminIcon sx={{ ml: 1 }} color="secondary" />}
            </Row>
          </Container>
        );
      }
    };
  }

  function renderEmailColumn(widthPercent: number): TableColumn<EditableAccountInfo> {
    const strings = localizationService.localizedStrings.insights.components.accounts;

    return {
      title: strings.email,
      width: `${widthPercent}%`,
      customSort: (a: EditableAccountInfo, b: EditableAccountInfo) => AccountInfoUtils.compareEmails(a, b),
      render: (rowData: EditableAccountInfo) => {
        return (
          <Container sx={{ my: 0.5, minHeight: 30 }}>
            <Column>
              <Row verticalContentAlignment="center">
                <Link variant="body1" target="_top" href={`mailto:${rowData.account.email}`}>
                  {rowData.account.email}
                </Link>
              </Row>
              {rowData.account.profile.publicEmail.length > 0 && (
                <Row verticalContentAlignment="center">
                  <Link variant="body2" target="_top" href={`mailto:${rowData.account.profile.publicEmail}`}>
                    ✉️&nbsp;{rowData.account.profile.publicEmail}
                  </Link>
                </Row>
              )}
            </Column>
          </Container>
        );
      }
    };
  }

  function renderGradeLevelColumn(): TableColumn<EditableAccountInfo> {
    const strings = localizationService.localizedStrings.insights.components.accounts;

    return {
      title: strings.gradeTitle,
      width: '1%',
      cellStyle: {
        maxWidth: 'min-content'
      },
      customSort: (a: EditableAccountInfo, b: EditableAccountInfo) =>
        AccountInfoUtils.compareGrades(a, b, localizationService.currentLocale),
      render: (rowData: EditableAccountInfo) => {
        return (
          <Container sx={{ my: 0.5, minHeight: 30 }}>
            <Row verticalContentAlignment="center">
              <Typography variant="body1">{rowData.account.gradeLevel}</Typography>
            </Row>
          </Container>
        );
      }
    };
  }

  function renderSectionsColumn(widthPercent: number): TableColumn<EditableAccountInfo> {
    const strings = localizationService.localizedStrings.insights.components.accounts;

    return {
      title: strings.sections,
      width: `${widthPercent}%`,
      sorting: false,
      render: (rowData: EditableAccountInfo) => {
        return (
          <SectionsInlineList sections={rowData.sections.sections} configId={configId} noneLabel={strings.noneLabel} />
        );
      }
    };
  }

  function renderParentsColumn(widthPercent: number, hidden?: boolean): TableColumn<EditableAccountInfo> {
    const strings = localizationService.localizedStrings.insights.components.accounts;

    return {
      title: strings.parents,
      sorting: false,
      width: `${widthPercent}%`,
      render: (rowData: EditableAccountInfo) => {
        return (
          <Container sx={{ my: 0.5, minHeight: 30 }}>
            <Column>
              {rowData.parents.map((parent) => {
                const isPending = parent.childrenAccountPendingVerificationIds.indexOf(rowData.account.id) >= 0;
                return (
                  <Typography
                    key={`parent-account-${parent.id}`}
                    style={{ fontStyle: isPending ? 'italic' : 'normal' }}
                  >
                    {parent.email}
                  </Typography>
                );
              })}
            </Column>
          </Container>
        );
      },
      hidden
    };
  }

  function renderChildrenColumn(widthPercent: number): TableColumn<EditableAccountInfo> {
    const strings = localizationService.localizedStrings.insights.components.accounts;

    return {
      title: strings.children,
      sorting: false,
      width: `${widthPercent}%`,
      render: (rowData: EditableAccountInfo) => {
        return (
          <Container sx={{ my: 0.5, minHeight: 30 }}>
            <Column>
              {rowData.children.map((child) => {
                return (
                  <Typography key={`child-account-${child.id}`} style={{ fontStyle: 'normal' }}>
                    {`${AccountUtils.getDisplayFirstLastName(child)} (${child.email})`}
                  </Typography>
                );
              })}
              {rowData.pendingChildren.map((child) => {
                return (
                  <Tooltip
                    key={`child-account-${child.id}`}
                    title={strings.pendingChildTooltip}
                    placement="bottom-start"
                  >
                    <Stack direction="row" spacing={1}>
                      <UnconfirmedIcon fontSize="small" color="warning" />
                      <Typography style={{ fontStyle: 'italic' }}>
                        {`${AccountUtils.getDisplayFirstLastName(child)} (${child.email})`}
                      </Typography>
                    </Stack>
                  </Tooltip>
                );
              })}
            </Column>
          </Container>
        );
      }
    };
  }

  function getRouteTemplateAndParamName(): {
    routeTemplate: string;
    accountIdParamName: string;
  } {
    switch (accountType) {
      case 'student':
        return {
          routeTemplate: RouteTemplates.studentDetails,
          accountIdParamName: RouteParamNames.studentId
        };
      case 'teacher':
        return {
          routeTemplate: RouteTemplates.teacherDetails,
          accountIdParamName: RouteParamNames.teacherId
        };
      case 'parent':
      case 'staff':
        // No metric details
        return { routeTemplate: '', accountIdParamName: '' };
      default:
        throw new Error('Unexpected account type');
    }
  }

  const getSearchableFields = moize((a: EditableAccountInfo) => [
    AccountUtils.getDisplayLastFirstName(a.account),
    a.account.email,
    a.account.managedIdentifier,
    ...a.sections.sections.map((s) => s.section?.title ?? ''),
    ...a.sections.sections.map((s) => s.section?.importId ?? ''),
    ...a.children.map((c) => AccountUtils.getDisplayLastFirstName(c)),
    ...a.children.map((c) => c.email),
    ...a.pendingChildren.map((c) => AccountUtils.getDisplayLastFirstName(c)),
    ...a.pendingChildren.map((c) => c.email)
  ]);

  async function copyIdToClipboard(account: EditableAccountInfo): Promise<void> {
    await navigator.clipboard.writeText(account.account.id);
    runInAction(() => setShowToast(true));
  }

  // We can't use AuthorizationRoleCondition in a MoreActionMenu.
  const canViewTeacherPlanner = (accountInfo: EditableAccountInfo) =>
    !accountInfo.account.isDeleted &&
    accountType === 'teacher' &&
    accountService.isAllowed(AdminOrTeacherAuthorizationRoles);

  const canViewStudentPlanner = (accountInfo: EditableAccountInfo) =>
    !accountInfo.account.isDeleted &&
    accountType === 'student' &&
    accountService.isAllowed(AdminOrTeacherAuthorizationRoles);

  const canEditDetails = (accountInfo: EditableAccountInfo) =>
    !accountInfo.isReadOnly && accountService.isAllowed(AdminOrTeacherAuthorizationRoles);

  const canEditSections = (accountInfo: EditableAccountInfo) =>
    !accountInfo.account.isDeleted &&
    !accountInfo.isReadOnly &&
    (accountType === 'student' || accountType === 'teacher') &&
    accountService.isAllowed(AdminOrTeacherAuthorizationRoles);

  const canEditChildren = (accountInfo: EditableAccountInfo) =>
    !accountInfo.account.isDeleted &&
    !accountInfo.isReadOnly &&
    accountType === 'parent' &&
    accountService.isAllowed(AdminAuthorizationRoles);

  const canManageTeacherPlanning = (accountInfo: EditableAccountInfo) =>
    !accountInfo.account.isDeleted &&
    !accountInfo.isReadOnly &&
    accountType === 'teacher' &&
    accountService.isAllowed(AdminAuthorizationRoles);

  const canCopyIds = accountService.isAllowed(['super-admin']);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const actions: Action<EditableAccountInfo>[] = [{ icon: 'more_vert', onClick: () => {} }];

  if (accountService.isAllowed(AdminAuthorizationRoles)) {
    actions.push({
      icon: 'add',
      isFreeAction: true,
      tooltip: strings.getAddAccountTooltip(
        localizationService.localizedStrings.models.account.roleTitleForManageableRole(accountType, false)
      ),
      onClick: () => void viewModel.addAccount()
    });
  }

  if (accountService.isAllowed(['super-admin'])) {
    actions.push({
      icon: 'arrow_downward',
      isFreeAction: true,
      tooltip: strings.exportToCsv,
      onClick: () => viewModel.exportToCsv()
    });
  }

  return (
    <Box sx={sx} className={className} style={style}>
      <Container>
        <InsightsMaterialTable
          stateKey={tableStateKey}
          // This is to disable the card contour
          components={{
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>,
            Action: (p) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (!p.action.isFreeAction) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const data = p.data as EditableAccountInfo;

                return (
                  <Expanded>
                    <Row horizontalContentAlignment="right">
                      <MoreActionMenu>
                        {canViewTeacherPlanner(data) && (
                          <MenuItem onClick={() => void viewModel.navigateToPlanner(data.account.id)}>
                            <ListItemText primary={strings.viewPlanner} />
                            <Container sx={{ ml: 3 }}>
                              <ListItemIcon sx={{ m: 0 }}>
                                <OpenInNewIcon />
                              </ListItemIcon>
                            </Container>
                          </MenuItem>
                        )}
                        {canViewStudentPlanner(data) && (
                          <MenuItem onClick={() => void viewModel.navigateToPlanner(data.account.id)}>
                            <ListItemText primary={strings.viewPlanner} />
                            <Container sx={{ ml: 3 }}>
                              <ListItemIcon sx={{ m: 0 }}>
                                <OpenInNewIcon />
                              </ListItemIcon>
                            </Container>
                          </MenuItem>
                        )}
                        {canEditDetails(data) && (
                          <MenuItem onClick={() => void viewModel.editAccount(data)}>
                            <ListItemText primary={strings.editDetails} />
                          </MenuItem>
                        )}
                        {canEditSections(data) && (
                          <MenuItem onClick={() => void viewModel.editSelectedSections(data)}>
                            <ListItemText primary={strings.editSections} />
                          </MenuItem>
                        )}
                        {canEditChildren(data) && (
                          <MenuItem onClick={() => void viewModel.editChildren(data)}>
                            <ListItemText primary={strings.editChildren} />
                          </MenuItem>
                        )}
                        {canManageTeacherPlanning(data) && (
                          <MenuItem onClick={() => void viewModel.manageTeacherPlanning(data)}>
                            <ListItemText primary={strings.manageTeacherPlanning} />
                          </MenuItem>
                        )}
                        {canCopyIds && (
                          <MenuItem onClick={() => void copyIdToClipboard(data)}>
                            <ListItemText primary={strings.copyId} />
                          </MenuItem>
                        )}
                      </MoreActionMenu>
                    </Row>
                  </Expanded>
                );
              }

              return <MTableAction {...p} />;
            }
          }}
          title={title ?? ''}
          columns={renderColumns()}
          actions={actions}
          data={viewModel.accounts}
          options={{
            pageSize: DefaultTablePageSize,
            pageSizeOptions: DefaultTablePageSizes,
            rowStyle: { backgroundColor: '#fff', verticalAlign: 'top' },
            headerStyle: { fontSize: theme.typography.body2.fontSize },
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            draggable: false
          }}
          // Remove the "Actions" column title
          localization={{
            ...localizationService.localizedStrings.insights.materialTable,
            header: { actions: '' }
          }}
        />
      </Container>
      <Stack direction="row" mt={1}>
        <Tooltip
          title={strings.accountLegendTooltipLines.map((line, i) => (
            <Typography key={`tooltip-line-${i}`} variant="body2">
              {line}
            </Typography>
          ))}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <LegendIcon color="secondary" />
            <Typography variant="body2" color="textSecondary">
              {strings.accountLegendLabel}
            </Typography>
          </Stack>
        </Tooltip>
        <Box flex={1} />
      </Stack>
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => runInAction(() => setShowToast(false))}
        message={strings.copyIdNotification}
      />
    </Box>
  );
});
