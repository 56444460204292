import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ImportSessionFileOrderedHeadersDialogViewModel, OrderedHeaderViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import AddAllIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/Delete';
import ReorderIcon from '@mui/icons-material/Reorder';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { ReorderableList } from '@studyo/components';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface ImportSessionFileOrderedHeadersProps {
  viewModel: ImportSessionFileOrderedHeadersDialogViewModel;
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

interface SortableElementData {
  readonly header: OrderedHeaderViewModel;
}

interface SortableContainerData {
  readonly headers: OrderedHeaderViewModel[];
  readonly onOrderChanged: (oldIndex: number, newIndex: number) => void;
}

const SortableHeader = ({ header }: SortableElementData) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: header.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dragHandleStyle: CSSProperties = {
    cursor: 'move',
    touchAction: 'none'
  };

  return (
    <MUI.Box ref={setNodeRef} style={style}>
      <MUI.Box sx={{ mb: 1 }} display="flex" flexDirection="row" alignItems="center">
        <MUI.TextField fullWidth value={header.value} onChange={(e) => (header.value = e.target.value)} />
        <MUI.IconButton onClick={() => header.remove()}>
          <RemoveIcon fontSize="small" color={header.isExisting ? 'primary' : 'error'} />
        </MUI.IconButton>

        <ReorderIcon sx={{ ml: 1, mt: 1.5 }} {...attributes} {...listeners} style={dragHandleStyle} />
      </MUI.Box>
    </MUI.Box>
  );
};

const SortableHeaders = ({ headers, onOrderChanged }: SortableContainerData) => (
  <MUI.Box>
    <ReorderableList
      items={headers}
      renderItem={(header) => <SortableHeader header={header} />}
      onOrderChanged={onOrderChanged}
    />
  </MUI.Box>
);

export const ImportSessionFileOrderedHeaders = observer((props: ImportSessionFileOrderedHeadersProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <MUI.Typography mb={2}>{strings.editOrderedHeadersMessage}</MUI.Typography>

      <MUI.Typography variant="caption">{strings.availableHeadersLabel}</MUI.Typography>
      <MUI.Typography mb={2} variant="body2">
        {viewModel.currentHeaders ?? strings.noHeaders}
      </MUI.Typography>

      <MUI.Typography variant="caption">{strings.orderedHeadersLabel}</MUI.Typography>

      <SortableHeaders
        headers={viewModel.headers}
        onOrderChanged={(oldIndex, newIndex) => viewModel.moveHeader(oldIndex, newIndex)}
      />

      <MUI.Box display="flex" flexDirection="row-reverse">
        <MUI.Tooltip title={strings.addAllOrderedColumns}>
          <MUI.IconButton onClick={() => viewModel.addAllHeaders()}>
            <AddAllIcon />
          </MUI.IconButton>
        </MUI.Tooltip>
        <MUI.Tooltip title={strings.addNextOrderedColumn}>
          <MUI.IconButton onClick={() => viewModel.addHeader()}>
            <AddIcon />
          </MUI.IconButton>
        </MUI.Tooltip>
      </MUI.Box>
    </MUI.Box>
  );
});
