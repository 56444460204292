import { EditableAccountList, ObservablePresenter, PageHeaderBar } from '@insights/components';
import { EditableAccountsScreenViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface EditableAccountsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  kind: 'teachers' | 'students' | 'parents' | 'staff';
}

export const EditableAccounts = observer((props: EditableAccountsProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style, kind } = props;
  const strings = localizationService.localizedStrings.insights.views.account;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo<EditableAccountsScreenViewModel>(() => {
    switch (kind) {
      case 'parents':
        return viewModelFactory.createEditableParents(configId, true);
      case 'students':
        return viewModelFactory.createEditableStudents(configId, true);
      case 'teachers':
        return viewModelFactory.createEditableTeachers(configId, true);
      case 'staff':
        return viewModelFactory.createEditableStaff(configId, true);
    }
  }, [kind, configId]);

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 1, height: '100%', width: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingRecordsMessage}
          errorMessage={strings.loadingRecordsErrorMessage}
          render={(data) => (
            <MUI.Box display="flex" flexDirection="column">
              <MUI.Card sx={{ minWidth: MinLayoutWidth }}>
                <EditableAccountList
                  sx={{ width: '100%', height: '100%' }}
                  title={strings.accountListTitle(viewModel.manageableRole)}
                  viewModel={data}
                  accountType={viewModel.manageableRole}
                  configId={viewModel.configId}
                />
              </MUI.Card>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
