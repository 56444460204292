import { caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import { CustomizationData, SchoolCalendarViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloneIcon from '@mui/icons-material/FileCopy';
import UndeleteIcon from '@mui/icons-material/RestoreFromTrash';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { SpecialDaySymbolImage } from '@shared/components/special_day_symbols';

import { SectionColors } from '@shared/models/Colors';
import { SpecialDayModel } from '@shared/models/config';
import { Draggable } from '@shared/rxp/drag-drop';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Column } from '../layout';
import { DayConfigurationsList } from './DayConfigurationsList';
import { DisplayCycleDay } from './DisplayCycleDay';
import { DisplayCycleDayEffect } from './DisplayCycleDayEffect';
import { SpecialDayDialog } from './SpecialDayDialog';

export type SpecialDaysColumn = 'icon' | 'title' | 'cycleDay' | 'effect' | 'bellTimes' | 'actions';

export interface SpecialDaysProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarViewModel;
  allowEdit?: boolean;
  columnsToExclude?: SpecialDaysColumn[];
  specialDays?: SpecialDayModel[];
  renderRowActions?: (specialDay: SpecialDayModel, isDeleted: boolean) => React.ReactNode;
}

export const SpecialDays = observer((props: SpecialDaysProps) => {
  const [search, setSearch] = React.useState('');
  const { localizationService } = useInsightsServices();
  const { sx, className, viewModel, allowEdit = false, columnsToExclude = [], renderRowActions } = props;
  const editableConfig = viewModel.editableConfig;
  const strings = localizationService.localizedStrings.insights.components.calendar;
  const specialDays = (props.specialDays ?? editableConfig.allSpecialDays)
    .slice()
    .sort((a, b) => caseInsensitiveAccentInsensitiveCompare(a.title, b.title));
  const lowerSearch = search.toLocaleLowerCase();
  const filteredSpecialDays =
    search.length === 0 ? specialDays : specialDays.filter((sd) => sd.title.toLocaleLowerCase().includes(lowerSearch));

  function shouldHideColumn(column: SpecialDaysColumn): boolean {
    return columnsToExclude.includes(column);
  }

  return (
    <React.Fragment>
      <Stack direction="row-reverse">
        <FormControl sx={{ mr: 1 }}>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={strings.search}
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setSearch('')} edge="end">
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Stack>
      <Root sx={sx} className={className}>
        <TableHead>
          <TableRow className="normalRow">
            <TableCell className={getCellClassName('icon', shouldHideColumn, 'cell')} />
            <TableCell className={getCellClassName('title', shouldHideColumn, 'cell')}>{strings.title}</TableCell>
            <TableCell className={getCellClassName('cycleDay', shouldHideColumn, 'cell')}>{strings.cycleDay}</TableCell>
            <TableCell className={getCellClassName('effect', shouldHideColumn, 'cell')}>{strings.effect}</TableCell>
            <TableCell className={getCellClassName('bellTimes', shouldHideColumn, 'cell')}>
              {strings.bellTimes}
            </TableCell>
            <TableCell className={getCellClassName('actions', shouldHideColumn, 'cell')}>
              {allowEdit && (
                <IconButton aria-label="Add" onClick={() => viewModel.addSpecialDay()}>
                  <AddIcon />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allowEdit && (
            <TableRow className="normalRow">
              <TableCell className={'cell'}>
                <Draggable data={{ clearSpecialDays: true } as CustomizationData} type="customization">
                  <div style={{ cursor: 'grab' }}>
                    <DeleteIcon />
                  </div>
                </Draggable>
              </TableCell>
              <TableCell className={'cell'} colSpan={5}>
                <Typography variant="subtitle2">{strings.dragOnCalendarToClear}</Typography>
              </TableCell>
            </TableRow>
          )}
          {filteredSpecialDays.length === 0 && specialDays.length > 0 && (
            <TableRow>
              <TableCell />
              <TableCell colSpan={4}>
                <Typography>{strings.noSpecialDaysFound}</Typography>
              </TableCell>
            </TableRow>
          )}
          {filteredSpecialDays.map((specialDay) => {
            const isDeleted = editableConfig.getIsSpecialDayDeleted(specialDay);
            const isChanged = editableConfig.getIsSpecialDayChanged(specialDay);
            const rowClassName = isDeleted ? 'deletedRow' : isChanged ? 'changedRow' : 'normalRow';

            const dayConfigurations = viewModel.dayConfigurationsBySpecialDayId[specialDay.id] ?? [];
            const tooltipTitle =
              dayConfigurations.length === 0 ? (
                // An empty string tooltip prevents the tooltip from appearing.
                ''
              ) : (
                <DayConfigurationsList viewModel={viewModel} dayConfigurations={dayConfigurations} />
              );

            return (
              <Tooltip key={`special-day-${specialDay.id}`} title={tooltipTitle} placement={'left'}>
                <TableRow className={rowClassName}>
                  <TableCell className={getCellClassName('icon', shouldHideColumn, 'cell')}>
                    <Draggable data={{ specialDay } as CustomizationData} type="customization" isDraggable={allowEdit}>
                      <div style={{ cursor: allowEdit ? 'grab' : 'default' }}>
                        <SpecialDaySymbolImage
                          squareSize={24}
                          symbol={specialDay.symbol}
                          color={SectionColors.get(specialDay.symbolColor)}
                          alwaysDisplaySymbol={true}
                        />
                      </div>
                    </Draggable>
                  </TableCell>
                  <TableCell className={getCellClassName('title', shouldHideColumn, 'cell')}>
                    <Typography variant="body2">{specialDay.title}</Typography>
                  </TableCell>

                  <TableCell className={getCellClassName('cycleDay', shouldHideColumn, 'cell')}>
                    <DisplayCycleDay specialDay={specialDay} />
                  </TableCell>
                  <TableCell className={getCellClassName('effect', shouldHideColumn, 'cell')}>
                    <DisplayCycleDayEffect specialDay={specialDay} />
                  </TableCell>
                  <TableCell className={getCellClassName('bellTimes', shouldHideColumn, 'cell')}>
                    <Column>
                      {specialDay.scheduleIds
                        .map((id) => ({
                          schedule: viewModel.schedulesById[id],
                          id
                        }))
                        .map((pair) => (
                          <Typography
                            key={`special-day-schedule-${pair.id}`}
                            variant="body2"
                            color={pair.schedule == null ? 'error' : undefined}
                          >
                            {pair.schedule?.title ?? strings.invalidScheduleId}
                          </Typography>
                        ))}
                    </Column>
                  </TableCell>
                  <TableCell className={getCellClassName('actions', shouldHideColumn, 'cell')}>
                    {renderRowActions != null
                      ? renderRowActions(specialDay, isDeleted)
                      : renderDefaultRowActions(specialDay, isDeleted, allowEdit, viewModel)}
                  </TableCell>
                </TableRow>
              </Tooltip>
            );
          })}
        </TableBody>
      </Root>
      <SpecialDayDialog viewModel={viewModel} />
    </React.Fragment>
  );
});

function renderDefaultRowActions(
  specialDay: SpecialDayModel,
  isDeleted: boolean,
  allowEdit: boolean,
  viewModel: SchoolCalendarViewModel
) {
  if (allowEdit) {
    return isDeleted ? (
      <IconButton className="iconButton" onClick={() => void viewModel.undeleteSpecialDay(specialDay)}>
        <UndeleteIcon />
      </IconButton>
    ) : (
      <>
        <IconButton className="iconButton" onClick={() => void viewModel.deleteSpecialDay(specialDay)}>
          <DeleteIcon />
        </IconButton>
        <IconButton className="iconButton" onClick={() => viewModel.copySpecialDay(specialDay)}>
          <CloneIcon />
        </IconButton>
        <IconButton className="iconButton" onClick={() => viewModel.editSpecialDay(specialDay)}>
          <EditIcon />
        </IconButton>
      </>
    );
  }
  return null;
}

// No choice to use this function since the TableCell hidden property does not hide the cell
function getCellClassName(
  column: SpecialDaysColumn,
  shouldHideColumn: (column: SpecialDaysColumn) => boolean,
  ...classNames: string[]
): string {
  const resolvedClassNames = classNames;

  if (shouldHideColumn(column)) {
    resolvedClassNames.push('hiddenCell');
  }

  return clsx(resolvedClassNames);
}

const Root = styled(Table)(({ theme }) => ({
  '.normalRow': {
    height: 'auto'
  },
  '.deletedRow': {
    height: 'auto',
    backgroundColor: red[100]
  },
  '.changedRow': {
    height: 'auto',
    backgroundColor: green[100]
  },
  '.cell': {
    padding: '2px 10px'
  },
  '.hiddenCell': {
    display: 'none'
  },
  '.iconButton': {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  }
}));
