import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface PageFooterProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  dense?: boolean;
  children?: React.ReactNode;
}

export const PageFooter = observer((props: PageFooterProps) => {
  const { className, sx, style, children, dense = false } = props;

  return (
    <MUI.Box sx={{ ...sx, backgroundColor: 'white' }} className={className} style={style}>
      <MUI.Divider />
      <MUI.Box paddingY={dense ? 0.5 : 2} paddingX={3}>
        {children}
      </MUI.Box>
    </MUI.Box>
  );
});
