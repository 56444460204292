import { PageHeaderBar, PeriodDisplaySelector, Section } from '@insights/components';
import { AdminDashboardViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { CSSProperties, useEffect } from 'react';
import { MinLayoutWidth } from '../../../../Constants';
import { useInsightsServices } from '../../../../UseInsightsServicesHook.ts';
import { ActiveStudents } from '../ActiveStudents';
import { ActiveStudentsVariation } from '../ActiveStudentsVariation';
import { ActiveTeachers } from '../ActiveTeachers';
import { ActiveTeachersVariation } from '../ActiveTeachersVariation';
import { ParentAccounts } from '../ParentAccounts';
import { StudentAccounts } from '../StudentAccounts';
import { TeacherAccounts } from '../TeacherAccounts';

const TinyCardMinWidth = 200;

export interface AdminDashboardProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: AdminDashboardViewModel;
}

export const AdminDashboard = observer((props: AdminDashboardProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.dashboards;

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Admin Dashboard' });
  });

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar>
        <PeriodDisplaySelector
          selectedPeriod={viewModel.periodDisplay}
          onSelectedPeriodChanged={(period) => (viewModel.periodDisplay = period)}
        />
      </PageHeaderBar>

      <MUI.Box padding={2} flex={1} overflow="auto">
        <MUI.Box display="flex" flexDirection="column">
          {/* This is the 'Accounts' section */}

          <Section sx={{ mb: 3 }} header={strings.accountsSectionHeader} headerSx={{ mx: 1 }}>
            <MUI.Box display="flex" flexDirection="row" flexWrap="wrap">
              <MUI.Card sx={{ minWidth: MinLayoutWidth, flex: 1 }}>
                <StudentAccounts
                  sx={{ width: '100%', height: '100%' }}
                  viewModel={viewModelFactory.createStudentConfigGlobalStats(viewModel.configId)}
                />
              </MUI.Card>

              <MUI.Card sx={{ minWidth: MinLayoutWidth, flex: 1 }}>
                <TeacherAccounts
                  sx={{ width: '100%', height: '100%' }}
                  viewModel={viewModelFactory.createConfigGlobalStats(viewModel.configId)}
                />
              </MUI.Card>

              <MUI.Card sx={{ minWidth: MinLayoutWidth, flex: 1 }}>
                <ParentAccounts
                  sx={{ width: '100%', height: '100%' }}
                  viewModel={viewModelFactory.createConfigGlobalStats(viewModel.configId)}
                />
              </MUI.Card>
            </MUI.Box>
          </Section>

          {/* This is the 'Active Students' section */}
          <Section
            sx={{ mb: 3 }}
            header={strings.activeStudentsHeader}
            headerSx={{ mx: 1 }}
            footerNote={strings.activeStudentsFooter}
          >
            <MUI.Box display="flex" flexDirection="row">
              <MUI.Box display="flex" flexDirection="column">
                <MUI.Card sx={{ flex: 1, minWidth: TinyCardMinWidth }}>
                  <ActiveStudentsVariation
                    sx={{ width: '100%', height: '100%' }}
                    mode="past-week"
                    viewModel={viewModelFactory.createConfigGlobalStats(viewModel.configId)}
                  />
                </MUI.Card>

                <MUI.Card sx={{ flex: 1, minWidth: TinyCardMinWidth }}>
                  <ActiveStudentsVariation
                    sx={{ width: '100%', height: '100%' }}
                    mode="past-month"
                    viewModel={viewModelFactory.createConfigGlobalStats(viewModel.configId)}
                  />
                </MUI.Card>

                <MUI.Card sx={{ flex: 1, minWidth: TinyCardMinWidth }}>
                  <ActiveStudentsVariation
                    sx={{ width: '100%', height: '100%' }}
                    mode="this-year"
                    viewModel={viewModelFactory.createConfigGlobalStats(viewModel.configId)}
                  />
                </MUI.Card>
              </MUI.Box>

              <MUI.Card sx={{ flex: 1, minWidth: MinLayoutWidth }}>
                <ActiveStudents
                  sx={{ width: '100%', height: '100%' }}
                  viewModel={viewModelFactory.createActiveStudents(viewModel.configId)}
                />
              </MUI.Card>
            </MUI.Box>
          </Section>

          {/* This is the 'Active Teachers' section */}
          <Section header={strings.activeTeachersHeader} headerSx={{ mx: 1 }} footerNote={strings.activeTeachersFooter}>
            <MUI.Box display="flex" flexDirection="row">
              <MUI.Box display="flex" flexDirection="column">
                <MUI.Card sx={{ flex: 1, minWidth: TinyCardMinWidth }}>
                  <ActiveTeachersVariation
                    sx={{ width: '100%', height: '100%' }}
                    mode="past-week"
                    viewModel={viewModelFactory.createConfigGlobalStats(viewModel.configId)}
                  />
                </MUI.Card>

                <MUI.Card sx={{ flex: 1, minWidth: TinyCardMinWidth }}>
                  <ActiveTeachersVariation
                    sx={{ width: '100%', height: '100%' }}
                    mode="past-month"
                    viewModel={viewModelFactory.createConfigGlobalStats(viewModel.configId)}
                  />
                </MUI.Card>

                <MUI.Card sx={{ flex: 1, minWidth: TinyCardMinWidth }}>
                  <ActiveTeachersVariation
                    sx={{ width: '100%', height: '100%' }}
                    mode="this-year"
                    viewModel={viewModelFactory.createConfigGlobalStats(viewModel.configId)}
                  />
                </MUI.Card>
              </MUI.Box>

              <MUI.Card sx={{ flex: 1, minWidth: MinLayoutWidth }}>
                <ActiveTeachers
                  sx={{ width: '100%', height: '100%' }}
                  viewModel={viewModelFactory.createActiveTeachers(viewModel.configId)}
                />
              </MUI.Card>
            </MUI.Box>
          </Section>
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
});
