import {
  CourseOccurrenceInformationChip,
  ErrorIndicator,
  InformationText,
  LoadingIndicator,
  ObservablePresenter,
  RequiresFeatureCondition,
  SchoolDayHeader,
  SectionName,
  WeekPagingNavigation
} from '@insights/components';
import { SettingsStore } from '@insights/services';
import {
  SectionsCourseOccurrencesStatusInfo,
  SectionsCourseOccurrencesStatusPageInfo,
  SectionsCourseOccurrencesStatusViewModel
} from '@insights/viewmodels';
import ExportIcon from '@mui/icons-material/ArrowDownward';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SectionColors } from '@shared/models/Colors';
import { SchoolDay } from '@shared/models/calendar';
import { Color } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ReactRouterRouteService } from '@shared/web/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { MinLayoutWidth, MinTinyChartHeight } from '../../../Constants';
import { RouteParamNames, RouteTemplates } from '../../../Routes';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SectionsCourseOccurrencesStatusProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  displaySectionName: boolean;
  displayWeekNavigation?: boolean;
  allowCsvExport?: boolean;
  viewModel: SectionsCourseOccurrencesStatusViewModel;
}

export const SectionsCourseOccurrencesStatus = observer((props: SectionsCourseOccurrencesStatusProps) => {
  const { localizationService, reactRouterRouteService, settingsStore } = useInsightsServices();
  const {
    className,
    sx,
    style,
    viewModel,
    displayWeekNavigation = true,
    allowCsvExport = false,
    displaySectionName
  } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;
  const theme = useTheme();

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <RequiresFeatureCondition
        requiredFeature="student-entered-tasks-widget"
        featureNotAvailableContent={{
          fill: true,
          padding: 2,
          title: strings.tasks,
          titleVariant: 'h6'
        }}
      >
        <ObservablePresenter
          sx={{ minHeight: MinTinyChartHeight, minWidth: MinLayoutWidth }}
          data={viewModel.data}
          indicatorsSize="normal"
          loadingMessage={strings.loadingDataMessage}
          errorMessage={strings.loadingDataErrorMessage}
          render={(data) =>
            renderShell(
              data,
              allowCsvExport,
              displaySectionName,
              displayWeekNavigation,
              viewModel,
              localizationService,
              reactRouterRouteService,
              settingsStore,
              theme
            )
          }
        />
      </RequiresFeatureCondition>
    </MUI.Box>
  );
});

function renderShell(
  configData: SectionsCourseOccurrencesStatusInfo,
  allowCsvExport: boolean,
  displaySectionName: boolean,
  displayWeekNavigation: boolean,
  viewModel: SectionsCourseOccurrencesStatusViewModel,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  settingsStore: SettingsStore,
  theme: MUI.Theme
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  return (
    <MUI.Box display="flex" flexDirection="column">
      <MUI.Box marginLeft={3} marginBottom={2} display="flex" alignItems="center">
        <MUI.Typography sx={{ flexGrow: 1 }} variant="h6">
          {strings.tasks}
        </MUI.Typography>

        {allowCsvExport && (
          <MUI.Tooltip title={strings.csvExportTooltip}>
            <MUI.IconButton disabled={viewModel.isExporting} onClick={() => void viewModel.exportToCsv()}>
              {viewModel.isExporting && <LoadingIndicator size="tiny" />}
              {!viewModel.isExporting && <ExportIcon />}
            </MUI.IconButton>
          </MUI.Tooltip>
        )}
      </MUI.Box>

      <MUI.Box flex={1}>
        {viewModel.currentPageInfo.case({
          pending: () =>
            renderEmptyTable(
              configData,
              displaySectionName,
              viewModel,
              localizationService,
              reactRouterRouteService,
              settingsStore,
              theme
            ),
          rejected: () => <ErrorIndicator message={strings.loadingDataErrorMessage} />,
          fulfilled: (data) =>
            renderTable(
              configData,
              data,
              displaySectionName,
              viewModel,
              localizationService,
              reactRouterRouteService,
              settingsStore,
              theme
            )
        })}
      </MUI.Box>

      <MUI.Box
        sx={{ minHeight: 30 }}
        marginTop={2}
        marginLeft={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        {/* Legend */}
        <MUI.Tooltip
          slotProps={{
            popper: { sx: { opacity: 1 } },
            tooltip: {
              sx: {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.getContrastText(theme.palette.common.white),
                boxShadow: theme.shadows[1],
                maxWidth: 'none'
              }
            }
          }}
          title={renderLegendTooltip(localizationService)}
        >
          <span>
            <InformationText text={strings.showLegend} />
          </span>
        </MUI.Tooltip>

        <MUI.Box flex={1} />

        {/* Show a loading indicator when changing the current page */}
        {viewModel.currentPageInfo.state === 'pending' && <LoadingIndicator size="tiny" />}

        {displayWeekNavigation && <WeekPagingNavigation pagination={viewModel.pagination} />}
      </MUI.Box>
    </MUI.Box>
  );
}

function renderEmptyTable(
  configData: SectionsCourseOccurrencesStatusInfo,
  displaySectionName: boolean,
  viewModel: SectionsCourseOccurrencesStatusViewModel,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  settingsStore: SettingsStore,
  theme: MUI.Theme
) {
  const pageRange = viewModel.pagination?.currentPage;
  if (pageRange != null) {
    return renderTable(
      configData,
      {
        schoolDays: configData.schoolDays.filter((schoolDay) =>
          schoolDay.day.isWithin(pageRange.startDay, pageRange.endDay)
        ),
        dayInfos: []
      },
      displaySectionName,
      viewModel,
      localizationService,
      reactRouterRouteService,
      settingsStore,
      theme
    );
  }

  return null;
}

function renderTable(
  configData: SectionsCourseOccurrencesStatusInfo,
  pageData: SectionsCourseOccurrencesStatusPageInfo,
  displaySectionName: boolean,
  viewModel: SectionsCourseOccurrencesStatusViewModel,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  settingsStore: SettingsStore,
  theme: MUI.Theme
) {
  return (
    <MUI.Table sx={{ tableLayout: 'fixed' }}>
      {renderTableHeader(configData, pageData, displaySectionName, localizationService, theme)}
      {renderTableBody(
        configData,
        pageData,
        displaySectionName,
        viewModel,
        localizationService,
        reactRouterRouteService,
        settingsStore,
        theme
      )}
    </MUI.Table>
  );
}

function renderTableHeader(
  _configData: SectionsCourseOccurrencesStatusInfo,
  pageData: SectionsCourseOccurrencesStatusPageInfo,
  displaySectionName: boolean,
  localizationService: LocalizationService,
  theme: MUI.Theme
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  return (
    <MUI.TableHead>
      <MUI.TableRow sx={{ verticalAlign: 'center', height: '50px' }}>
        {displaySectionName && (
          <MUI.TableCell sx={{ width: '20%', cursor: 'default' }}>
            <MUI.Typography
              variant="subtitle2"
              sx={{ color: theme.palette.text.secondary, fontWeight: 500, lineHeight: 'normal' }}
            >
              {strings.section}
            </MUI.Typography>
          </MUI.TableCell>
        )}

        {pageData.schoolDays.map((schoolDay) => (
          <MUI.TableCell
            key={schoolDay.day.asDateString}
            sx={{ textAlign: 'center', paddingRight: theme.spacing(2), width: 'calc(80%/7)', cursor: 'default' }}
          >
            <SchoolDayHeader schoolDay={schoolDay} displayType="column-header" />
          </MUI.TableCell>
        ))}
      </MUI.TableRow>
    </MUI.TableHead>
  );
}

function renderTableBody(
  configData: SectionsCourseOccurrencesStatusInfo,
  pageData: SectionsCourseOccurrencesStatusPageInfo,
  displaySectionName: boolean,
  viewModel: SectionsCourseOccurrencesStatusViewModel,
  localizationService: LocalizationService,
  reactRouterRouteService: ReactRouterRouteService,
  settingsStore: SettingsStore,
  theme: MUI.Theme
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;
  const materialTableStrings = localizationService.localizedStrings.insights.materialTable;

  return (
    <MUI.TableBody>
      {configData.sections.length === 0 && (
        <MUI.TableRow>
          <MUI.TableCell colSpan={99} align="center">
            <MUI.Typography variant="body2">{materialTableStrings.body?.emptyDataSourceMessage ?? ''}</MUI.Typography>
          </MUI.TableCell>
        </MUI.TableRow>
      )}

      {configData.sections.length > 0 &&
        configData.sections.map((sectionInfo) => (
          <MUI.TableRow key={sectionInfo.section.id} sx={{ verticalAlign: 'center', height: '50px' }}>
            {displaySectionName && (
              <MUI.TableCell sx={{ width: '20%', cursor: 'default' }}>
                <SectionName
                  color={sectionInfo.section.color}
                  title={sectionInfo.section.title}
                  subInformation1={sectionInfo.section.sectionNumber}
                  subInformation2={strings.localizedNumberOfStudents(sectionInfo.numberOfStudents)}
                  linkTo={
                    settingsStore.isEmbedded
                      ? undefined
                      : reactRouterRouteService.resolveLocation(RouteTemplates.sectionDetails, [
                          {
                            name: RouteParamNames.configId,
                            value: viewModel.configId
                          },
                          {
                            name: RouteParamNames.sectionId,
                            value: sectionInfo.section.id
                          }
                        ])
                  }
                />
              </MUI.TableCell>
            )}

            {pageData.schoolDays.map((schoolDay) => {
              const dayInfo = pageData.dayInfos.find(
                (d) => d.schoolDay.day.isSame(schoolDay.day) && d.section.id === sectionInfo.section.id
              );
              const hasCourseOccurrence = dayInfo?.hasCourseOccurrence ?? false;
              const studentsWithOwnTasksCount = dayInfo?.studentsWithOwnTasksCount ?? 0;
              const publishedTasksCount = dayInfo?.publishedTasksCount ?? 0;

              const hasValue = studentsWithOwnTasksCount > 0 || publishedTasksCount > 0;

              return (
                <MUI.Tooltip
                  key={`${sectionInfo.section.id}${schoolDay.day.asDateString}`}
                  classes={{
                    tooltip: 'tooltip',
                    popper: 'tooltipPopper'
                  }}
                  title={renderValueTooltip(
                    studentsWithOwnTasksCount,
                    publishedTasksCount,
                    sectionInfo.numberOfStudents,
                    hasCourseOccurrence,
                    sectionInfo.section.color,
                    localizationService
                  )}
                >
                  <MUI.TableCell
                    sx={{
                      textAlign: 'center',
                      paddingRight: theme.spacing(2),
                      width: 'calc(80%/7)',
                      cursor: hasValue ? 'pointer' : 'default',

                      '&:hover': {
                        backgroundColor: hasValue ? grey[50] : undefined
                      }
                    }}
                    onClick={() => hasValue && void showDetail(viewModel, sectionInfo.section.id, dayInfo?.schoolDay)}
                  >
                    <CourseOccurrenceInformationChip
                      hasCourseOccurrence={hasCourseOccurrence}
                      hasPublishedTasks={publishedTasksCount > 0}
                      value={studentsWithOwnTasksCount}
                    />
                  </MUI.TableCell>
                </MUI.Tooltip>
              );
            })}
          </MUI.TableRow>
        ))}
    </MUI.TableBody>
  );
}

function renderValueTooltip(
  studentsWithOwnTasksCount: number,
  publishedTasksCount: number,
  sectionStudentCount: number,
  hasCourseOccurrence: boolean,
  sectionColor: Color,
  localizationService: LocalizationService
) {
  if (studentsWithOwnTasksCount === 0 && publishedTasksCount === 0 && !hasCourseOccurrence) {
    return '';
  }

  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  return (
    <MUI.Box display="flex" flexDirection="row">
      <MUI.Box mr="6px" borderLeft={`3px solid ${SectionColors.get(sectionColor)}`} />

      <MUI.Box display="flex" flexDirection="column">
        <MUI.Box display="flex" flexDirection="row">
          <MUI.Box marginRight={3}>
            <MUI.Typography>{strings.studentsWithOwnTasks}</MUI.Typography>
          </MUI.Box>
          <MUI.Box flex={1} />
          <MUI.Typography align="right" fontWeight={400}>
            {studentsWithOwnTasksCount}
          </MUI.Typography>
        </MUI.Box>

        <MUI.Box display="flex" flexDirection="row">
          <MUI.Box marginRight={3}>
            <MUI.Typography>{strings.studentsWithoutTasks}</MUI.Typography>
          </MUI.Box>
          <MUI.Box flex={1} />
          <MUI.Typography align="right" fontWeight={400}>
            {Math.max(0, sectionStudentCount - studentsWithOwnTasksCount)}
          </MUI.Typography>
        </MUI.Box>

        <MUI.Box display="flex" flexDirection="row">
          <MUI.Box marginRight={3}>
            <MUI.Typography>{strings.publishedTasks}</MUI.Typography>
          </MUI.Box>
          <MUI.Box flex={1} />
          <MUI.Typography align="right" fontWeight={400}>
            {publishedTasksCount}
          </MUI.Typography>
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
}

function renderLegendTooltip(localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  return (
    <MUI.Box display="flex" flexDirection="column">
      <MUI.Box margin={0.5} display="flex" flexDirection="row" alignItems="center">
        <CourseOccurrenceInformationChip hasCourseOccurrence={true} hasPublishedTasks={true} value={0} />
        <MUI.Box marginLeft={1}>
          <MUI.Typography noWrap={true} color="textSecondary">
            {strings.sectionOccursPublished}
          </MUI.Typography>
        </MUI.Box>
      </MUI.Box>

      <MUI.Box margin={0.5} display="flex" flexDirection="row" alignItems="center">
        <CourseOccurrenceInformationChip hasCourseOccurrence={true} hasPublishedTasks={false} value={0} />
        <MUI.Box marginLeft={1}>
          <MUI.Typography noWrap={true} color="textSecondary">
            {strings.sectionOccursNoPublished}
          </MUI.Typography>
        </MUI.Box>
      </MUI.Box>

      <MUI.Box margin={0.5} display="flex" flexDirection="row" alignItems="center">
        <CourseOccurrenceInformationChip hasCourseOccurrence={false} hasPublishedTasks={true} value={0} />
        <MUI.Box marginLeft={1}>
          <MUI.Typography noWrap={true} color="textSecondary">
            {strings.sectionNotOccurPublished}
          </MUI.Typography>
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
}

async function showDetail(
  viewModel: SectionsCourseOccurrencesStatusViewModel,
  sectionId: string,
  schoolDay: SchoolDay | undefined
) {
  if (schoolDay == null) {
    return;
  }

  await viewModel.showDetail(sectionId, schoolDay);
}
