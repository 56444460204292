// TODO MUI.Box

import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { SchoolDay } from '@shared/models/calendar';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { Column, Container } from './layout';

export interface SchoolWeekHeaderProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  schoolDay: SchoolDay;
  displayType: 'column-header' | 'page-header';
}

export const SchoolWeekHeader = observer((props: SchoolWeekHeaderProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, displayType, schoolDay } = props;
  const strings = localizationService.localizedStrings.insights.components;
  const theme = useTheme();

  return (
    <React.Fragment>
      {displayType === 'column-header' && (
        <Column sx={sx} className={className} style={style} horizontalContentAlignment="center">
          <MUI.Typography
            variant="subtitle2"
            sx={{ color: theme.palette.text.secondary, fontWeight: 500, lineHeight: 'normal' }}
          >
            {strings.week}
          </MUI.Typography>
        </Column>
      )}

      {displayType === 'page-header' && (
        <Column sx={sx} className={className} style={style} horizontalContentAlignment="right">
          <Container sx={{ ml: 1 }}>
            <MUI.Typography sx={{ color: theme.palette.text.secondary, lineHeight: 'normal' }} color="textSecondary">
              {strings.weekOf}
            </MUI.Typography>
          </Container>
          <MUI.Typography sx={{ fontSize: '1.25em', lineHeight: 'normal' }}>
            {schoolDay.day.formattedString(localizationService.localizedStrings.models.dateFormats.mediumUnabridged)}
          </MUI.Typography>
        </Column>
      )}
    </React.Fragment>
  );
});
