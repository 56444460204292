import { AuthorizationRoleCondition, ObservablePresenter, PageHeaderBar } from '@insights/components';
import EditIcon from '@mui/icons-material/Edit';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { AdminAuthorizationRoles } from '@shared/models/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';
import { SchoolYearConfigurationAssessmentPlanning } from './SchoolYearConfigurationAssessmentPlanning';
import { SchoolYearConfigurationDates } from './SchoolYearConfigurationDates';
import { SchoolYearConfigurationInformation } from './SchoolYearConfigurationInformation';
import { SchoolYearConfigurationLinks } from './SchoolYearConfigurationLinks';
import { SchoolYearConfigurationPowerTools } from './SchoolYearConfigurationPowerTools';
import { SchoolYearConfigurationWorkloadManagerThresholds } from './SchoolYearConfigurationWorkloadManagerThresholds';

export interface SchoolYearConfigurationScreenProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const SchoolYearConfigurationScreen = observer((props: SchoolYearConfigurationScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createSchoolYearConfigurationScreen(configId), [configId]);

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="column">
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingMessage}
          errorMessage={strings.errorMessage}
          render={(data) => (
            <MUI.Grid container alignItems="stretch">
              {/* Information */}
              <MUI.Grid item xs={12} md={6}>
                <MUI.Box height="100%" width="100%" display="flex">
                  <MUI.Card sx={{ flex: 1, display: 'block' }}>
                    <MUI.CardHeader
                      title={strings.informationCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                          <MUI.Tooltip title={strings.editInformationButtonTooltip}>
                            <MUI.IconButton
                              onClick={() => void viewModel.editInformation(data.schoolYearConfiguration)}
                            >
                              <EditIcon />
                            </MUI.IconButton>
                          </MUI.Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <MUI.Divider />
                    <MUI.CardContent>
                      <SchoolYearConfigurationInformation
                        data={data}
                        onEditIndividualAccount={(id) => viewModel.editIndividualAccount(id)}
                      />
                    </MUI.CardContent>
                  </MUI.Card>
                </MUI.Box>
              </MUI.Grid>

              {/* Dates */}
              <MUI.Grid item xs={12} md={6}>
                <MUI.Box height="100%" width="100%" display="flex">
                  <MUI.Card sx={{ flex: 1, display: 'block' }}>
                    <MUI.CardHeader
                      title={strings.datesCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                          <MUI.Tooltip title={strings.editDatesButtonTooltip}>
                            <MUI.IconButton onClick={() => void viewModel.editDates(data.schoolYearConfiguration)}>
                              <EditIcon />
                            </MUI.IconButton>
                          </MUI.Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <MUI.Divider />
                    <MUI.CardContent>
                      <SchoolYearConfigurationDates data={data} />
                    </MUI.CardContent>
                  </MUI.Card>
                </MUI.Box>
              </MUI.Grid>

              {/* Workload Manager Thresholds */}
              <MUI.Grid item xs={12} md={6}>
                <MUI.Box height="100%" width="100%" display="flex">
                  <MUI.Card sx={{ flex: 1, display: 'block' }}>
                    <MUI.CardHeader
                      title={strings.workloadManagerThresholdsCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                          <MUI.Tooltip title={strings.editWorkloadManagerThresholdsButtonTooltip}>
                            <MUI.IconButton onClick={() => void viewModel.editThresholds(data.schoolYearConfiguration)}>
                              <EditIcon />
                            </MUI.IconButton>
                          </MUI.Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <MUI.Divider />
                    <MUI.CardContent>
                      <SchoolYearConfigurationWorkloadManagerThresholds data={data} />
                    </MUI.CardContent>
                  </MUI.Card>
                </MUI.Box>
              </MUI.Grid>

              {/* Links */}
              <MUI.Grid item xs={12} md={6}>
                <MUI.Box height="100%" width="100%" display="flex">
                  <MUI.Card sx={{ flex: 1, display: 'block' }}>
                    <MUI.CardHeader
                      title={strings.linksCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                          <MUI.Tooltip title={strings.editLinksButtonTooltip}>
                            <MUI.IconButton onClick={() => void viewModel.editLinks(data.schoolYearConfiguration)}>
                              <EditIcon />
                            </MUI.IconButton>
                          </MUI.Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <MUI.Divider />
                    <MUI.CardContent>
                      <SchoolYearConfigurationLinks data={data} />
                    </MUI.CardContent>
                  </MUI.Card>
                </MUI.Box>
              </MUI.Grid>

              {/* Assessment Dates */}
              <MUI.Grid item xs={12} md={6}>
                <MUI.Box height="100%" width="100%" display="flex">
                  <MUI.Card sx={{ flex: 1, display: 'block' }}>
                    <MUI.CardHeader
                      title={strings.assessmentPlanningCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                          <MUI.Tooltip title={strings.editAssessmentPlanningButtonTooltip}>
                            <MUI.IconButton
                              onClick={() => {
                                void viewModel.editAssessmentPlanning(data.schoolYearConfiguration);
                              }}
                            >
                              <EditIcon />
                            </MUI.IconButton>
                          </MUI.Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <MUI.Divider />
                    <MUI.CardContent>
                      <SchoolYearConfigurationAssessmentPlanning data={data} />
                    </MUI.CardContent>
                  </MUI.Card>
                </MUI.Box>
              </MUI.Grid>

              {/* Power tools */}
              <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                <MUI.Grid item xs={12} md={6}>
                  <MUI.Box height="100%" width="100%" display="flex">
                    <MUI.Card sx={{ flex: 1, display: 'block' }}>
                      <MUI.CardHeader title={strings.powerToolsCardTitle} />
                      <MUI.Divider />
                      <MUI.CardContent>
                        <SchoolYearConfigurationPowerTools
                          viewModel={viewModel}
                          schoolYearConfiguration={data.schoolYearConfiguration}
                        />
                      </MUI.CardContent>
                    </MUI.Card>
                  </MUI.Box>
                </MUI.Grid>
              </AuthorizationRoleCondition>

              {/* Validation results */}
              <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                <MUI.Grid item xs={12} md={12}>
                  <MUI.Box height="100%" width="100%" display="flex">
                    <MUI.Card sx={{ flex: 1, display: 'block' }}>
                      <MUI.CardHeader title={strings.validationResultsCardTitle} />
                      <MUI.Divider />
                      <MUI.CardContent>
                        {data.validationResults == null && strings.validationResultsNotAvailable}
                        {(data.validationResults?.length ?? 0) === 0 && strings.validationResultsNoErrors}
                        {(data.validationResults?.length ?? 0) > 0 && (
                          <ul>
                            {data.validationResults!.map((validationMessage, i) => (
                              <li key={`validation-message-${i}`}>
                                <MUI.Typography variant="caption">{validationMessage}</MUI.Typography>
                              </li>
                            ))}
                          </ul>
                        )}
                      </MUI.CardContent>
                    </MUI.Card>
                  </MUI.Box>
                </MUI.Grid>
              </AuthorizationRoleCondition>
            </MUI.Grid>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
