import { RouterLink } from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface NotFoundProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const NotFound = observer((props: NotFoundProps) => {
  const { accountService, localizationService, reactRouterRouteService } = useInsightsServices();
  const { className, style, sx } = props;

  const navigate = useNavigate();
  const theme = useTheme();
  const strings = localizationService.localizedStrings.insights.views.notFound;

  const openIntercom = () => {
    if (window.Intercom != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Intercom('showNewMessage');
    }
  };

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="column" padding={3}>
      <MUI.Box marginBottom={3}>
        <MUI.Typography variant="h4">{strings.title}</MUI.Typography>
      </MUI.Box>

      <MUI.Box marginBottom={3}>
        <MUI.Typography variant="body1">
          {strings.errorOnOurPartText}
          <MUI.Link variant="body1" sx={{ color: theme.palette.secondary.dark }} onClick={openIntercom}>
            {strings.errorOnOurPartLetUsKnowLink}
          </MUI.Link>
          .
        </MUI.Typography>
      </MUI.Box>

      <MUI.Typography variant="body1">{strings.usefulLinks}</MUI.Typography>

      <RouterLink
        variant="body1"
        sx={{ color: theme.palette.secondary.dark }}
        to={reactRouterRouteService.resolveLocation(RouteTemplates.dashboard, [
          {
            name: RouteParamNames.configId,
            value: accountService.currentConfigId!
          }
        ])}
      >
        {strings.usefulLinksDashboard}
      </RouterLink>

      <MUI.Link variant="body1" sx={{ color: theme.palette.secondary.dark }} onClick={() => navigate(-1)}>
        {strings.usefulLinksGoBack}
      </MUI.Link>
    </MUI.Box>
  );
});
