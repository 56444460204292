import { css } from '@emotion/css';
import { BlackbaudSkyAccountSetupViewModel } from '@insights/viewmodels';
import { FormControl, InputLabel, MenuItem, Select, SxProps, TextField, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface BlackbaudSkyAccountSetupProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: BlackbaudSkyAccountSetupViewModel;
}

export const BlackbaudSkyAccountSetup = observer((props: BlackbaudSkyAccountSetupProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  const theme = useTheme();
  const fieldClassName = css([{ marginBottom: theme.spacing(2) }]);

  return (
    <Column sx={sx} className={className} style={style}>
      <FormControl className={fieldClassName}>
        <InputLabel htmlFor="year-select">{strings.yearLabel}</InputLabel>
        <Select
          value={viewModel.yearLabel}
          onChange={(e) => (viewModel.yearLabel = e.target.value)}
          inputProps={{ id: 'year-select' }}
        >
          {viewModel.availableYears.map((year) => (
            <MenuItem key={`year-${year.id}`} value={year.label}>
              <Typography fontWeight={year.isCurrent ? '500' : undefined}>{year.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={fieldClassName}>
        <InputLabel htmlFor="level-select">{strings.levelLabel}</InputLabel>
        <Select
          value={viewModel.levelId}
          onChange={(e) => (viewModel.levelId = BigInt(e.target.value))}
          inputProps={{ id: 'level-select' }}
        >
          <MenuItem key="level-none" value="0">
            <Typography>{strings.noLevel}</Typography>
          </MenuItem>
          {viewModel.availableLevels.map((level) => (
            <MenuItem key={`level-${level.id}`} value={Number(level.id)}>
              <Typography>
                {level.name} ({level.abbreviation})
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        className={fieldClassName}
        label={strings.blackbaudPortalAddress}
        fullWidth
        value={viewModel.portalAddress}
        onChange={(e) => (viewModel.portalAddress = e.target.value)}
      />

      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
