import { AutomatedImportListViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DetailsIcon from '@mui/icons-material/MoreHoriz';
import * as MUI from '@mui/material';
import { AutomatedImport } from '@shared/models/import';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { MoreActionMenu } from '../MoreActionMenu';

export interface AutomatedImportItemProps {
  viewModel: AutomatedImportListViewModel;
  automatedImport: AutomatedImport;
  sx?: MUI.SxProps;
  className?: string;
  showActions?: boolean;
  hasDivider?: boolean;
}

export const AutomatedImportItem = observer((props: AutomatedImportItemProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, viewModel, automatedImport, showActions = false, hasDivider = false } = props;
  const strings = localizationService.localizedStrings.insights.components.import;
  const navigate = useNavigate();

  return (
    <MUI.Box sx={sx} className={className} flexDirection="column">
      <MUI.Box my={1} flexDirection="row" display="flex" alignItems="flex-start">
        <MUI.Box flex={1} flexDirection="column" px={1}>
          <MUI.Button
            sx={{ textTransform: 'none', p: 0, minWidth: 0 }}
            variant="text"
            onClick={() => void viewModel.editAutomatedTransformations(automatedImport, navigate)}
          >
            <MUI.Typography variant="subtitle1">{automatedImport.name}</MUI.Typography>
          </MUI.Button>
          <MUI.Typography variant="body1" color="textSecondary">
            {strings.lastAutomatedImport(_.last(automatedImport.history))}
          </MUI.Typography>
          <MUI.Typography variant="subtitle2" color="textSecondary" style={{ fontStyle: 'italic' }}>
            {strings.automatedImportTimes(automatedImport.days, automatedImport.time)}
          </MUI.Typography>
        </MUI.Box>

        {showActions && (
          <MoreActionMenu>
            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
              <MUI.MenuItem onClick={() => void viewModel.editAutomatedImport(automatedImport)}>
                <MUI.ListItemIcon>
                  <EditIcon />
                </MUI.ListItemIcon>
                <MUI.ListItemText>{strings.editAutomatedImport}</MUI.ListItemText>
              </MUI.MenuItem>
            </AuthorizationRoleCondition>
            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
              <MUI.MenuItem onClick={() => void viewModel.editAutomatedTransformations(automatedImport, navigate)}>
                <MUI.ListItemIcon>
                  <DetailsIcon />
                </MUI.ListItemIcon>
                <MUI.ListItemText>{strings.editAutomatedTransformations}</MUI.ListItemText>
              </MUI.MenuItem>
            </AuthorizationRoleCondition>
            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
              <MUI.MenuItem onClick={() => void viewModel.deleteAutomatedImportRequest(automatedImport)}>
                <MUI.ListItemIcon>
                  <DeleteIcon />
                </MUI.ListItemIcon>
                <MUI.ListItemText>{strings.deleteAutomatedImport}</MUI.ListItemText>
              </MUI.MenuItem>
            </AuthorizationRoleCondition>
          </MoreActionMenu>
        )}
      </MUI.Box>

      {hasDivider && <MUI.Divider />}
    </MUI.Box>
  );
});
