import { css } from '@emotion/css';
import { DateTimePickerLocalizationProvider, InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { Day } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface EditSchoolYearConfigurationAssessmentPlanningDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  schoolYearConfiguration: SchoolYearConfigurationModel;
  style?: React.CSSProperties;
}

export const EditSchoolYearConfigurationAssessmentPlanningDialog = observer(
  (props: EditSchoolYearConfigurationAssessmentPlanningDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { className, sx, style, schoolYearConfiguration, onCancel, onSuccess } = props;
    const strings = localizationService.localizedStrings.insights.views.managedGeneral;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createEditSchoolYearConfigurationAssessmentPlanning(schoolYearConfiguration, onSuccess!, onCancel!)
    );

    const cellClassName = css({ padding: '2px 8px 2px 0px', border: 'none' });
    const gradeLevelCellClassName = css({ width: '33%' });
    const visibilityDayCellClassName = css({ width: '33%' });

    return (
      <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
        <DateTimePickerLocalizationProvider>
          <MUI.Dialog
            sx={sx}
            open={true}
            onClose={() => void viewModel.cancel()}
            maxWidth="sm"
            fullWidth={true}
            className={className}
            style={style}
          >
            <form
              noValidate
              onSubmit={(e) => {
                // This is to prevent the page from reloading on submit
                e.preventDefault();
                return false;
              }}
            >
              <MUI.DialogTitle>{strings.editAssessmentPlanningDialogTitle}</MUI.DialogTitle>
              <MUI.DialogContent dividers>
                <MUI.Grid container spacing={2}>
                  {/* Visibility Date by grade level */}
                  <MUI.Grid item xs={12}>
                    <MUI.Table>
                      <MUI.TableHead>
                        <MUI.TableRow>
                          <MUI.TableCell className={classNames(cellClassName, gradeLevelCellClassName)}>
                            <MUI.InputLabel shrink>{strings.assessmentPlanningGradeLevelColumnTitle}</MUI.InputLabel>
                          </MUI.TableCell>
                          <MUI.TableCell className={classNames(cellClassName, visibilityDayCellClassName)}>
                            <MUI.InputLabel shrink>
                              {strings.assessmentPlanningVisibilityDateColumnTitle}
                            </MUI.InputLabel>
                          </MUI.TableCell>
                        </MUI.TableRow>
                      </MUI.TableHead>
                      <MUI.TableBody>
                        {viewModel.gradeLevels.map((gradeLevel) => {
                          const day = viewModel.getVisibilityDate(gradeLevel);
                          const value = day != null ? day.asDate : null;

                          return (
                            <MUI.TableRow key={gradeLevel}>
                              <MUI.TableCell className={classNames(cellClassName, gradeLevelCellClassName)}>
                                <MUI.Typography variant="body1">{gradeLevel}</MUI.Typography>
                              </MUI.TableCell>
                              <MUI.TableCell className={classNames(cellClassName, visibilityDayCellClassName)}>
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      placeholder: viewModel.defaultDay.formattedString(
                                        localizationService.localizedStrings.models.dateFormats.mediumUnabridged
                                      )
                                    },
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept']
                                    }
                                  }}
                                  localeText={{
                                    okButtonLabel: strings.okButtonCaption,
                                    cancelButtonLabel: strings.cancelButtonCaption,
                                    clearButtonLabel: strings.clearButtonCaption
                                  }}
                                  format={localizationService.localizedStrings.models.dateFormats.mediumUnabridged}
                                  value={value}
                                  minDate={viewModel.minimumDay.asDate}
                                  maxDate={viewModel.maximumDay.asDate}
                                  onChange={(e) =>
                                    viewModel.setVisibilityDate(gradeLevel, Day.fromDate(e ?? undefined))
                                  }
                                />
                              </MUI.TableCell>
                            </MUI.TableRow>
                          );
                        })}
                      </MUI.TableBody>
                    </MUI.Table>
                  </MUI.Grid>
                </MUI.Grid>
              </MUI.DialogContent>
              <MUI.DialogActions>
                <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
                  {strings.cancelButtonCaption}
                </InsightsButton>
                <InsightsButton
                  isSubmit
                  showSpinnerOnExecuting
                  isExecuting={viewModel.isSubmitting}
                  onClick={() => void viewModel.save()}
                >
                  {strings.saveButtonCaption}
                </InsightsButton>
              </MUI.DialogActions>
            </form>
          </MUI.Dialog>
        </DateTimePickerLocalizationProvider>
      </MUI.ThemeProvider>
    );
  }
);
