import { AutomatedTransformationImportViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface ExpectedEntityListProps {
  sx?: MUI.SxProps;
  className?: string;
  viewModel: AutomatedTransformationImportViewModel;
}

export const ExpectedEntityList = observer(({ sx, className, viewModel }: ExpectedEntityListProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <MUI.Table sx={sx} className={className}>
      <MUI.TableHead>
        <MUI.TableRow>
          <MUI.TableCell>{strings.entityListKindColumn}</MUI.TableCell>
          <MUI.TableCell>{strings.entityMinCountColumn}</MUI.TableCell>
          <MUI.TableCell>{strings.entityMaxCountColumn}</MUI.TableCell>
        </MUI.TableRow>
      </MUI.TableHead>
      <MUI.TableBody>
        {viewModel.transformationImport.entityCountTolerances.map((t) => (
          <MUI.TableRow key={`entity-tolerance-${t.listKind}`}>
            <MUI.TableCell>{strings.entityListKind(t.listKind)}</MUI.TableCell>
            <MUI.TableCell>
              <MUI.TextField
                type="number"
                value={t.minimumCount}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (!Number.isNaN(value)) {
                    t.minimumCount = value;
                  }
                }}
              />
            </MUI.TableCell>
            <MUI.TableCell>
              <MUI.TextField
                type="number"
                value={t.maximumCount}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (!Number.isNaN(value)) {
                    t.maximumCount = value;
                  }
                }}
              />
            </MUI.TableCell>
          </MUI.TableRow>
        ))}
      </MUI.TableBody>
    </MUI.Table>
  );
});
