import { ApplicationName, LoadingIndicator } from '@insights/components';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import * as MUI from '@mui/material';
import { Stack, SxProps, useTheme } from '@mui/material';
import { Locale } from '@shared/resources/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

export interface LoginProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const Login = observer((props: LoginProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx } = props;
  const strings = localizationService.localizedStrings.insights;
  const theme = useTheme();
  const viewModel = useMemo(() => viewModelFactory.createLogin(), []);
  const { isLoggingIn, isSigningUp } = viewModel;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    void viewModel.onInit(location, navigate);
  }, []);

  const [hasAcceptedTerms, setHasAcceptedTerms] = React.useState(false);
  const [showPrivacyAlert, setShowPrivacyAlert] = React.useState(false);

  function onSignUpClick() {
    if (hasAcceptedTerms) {
      void viewModel.signUp(location, navigate);
    } else {
      setShowPrivacyAlert(true);
    }
  }

  return (
    <MUI.Box
      sx={{ ...sx, backgroundColor: 'transparent' }}
      className={className}
      style={style}
      display="flex"
      flexDirection="column"
      alignItems="center"
      overflow="auto"
    >
      <Stack alignItems="center" pb={2}>
        <ApplicationName
          sx={{ marginTop: '20vh', ['@media screen and (max-height: 650px)']: { marginTop: 3 } }}
          size="large"
          color="light"
        />

        <MUI.Box display="flex" flexDirection="column" maxWidth={325} sx={{ backgroundColor: 'transparent' }}>
          <MUI.Button
            sx={{ marginTop: 4, minWidth: 200 }}
            disabled={isSigningUp || isLoggingIn}
            variant="contained"
            color="primary"
            onClick={onSignUpClick}
          >
            {isSigningUp && <LoadingIndicator size="tiny" color="secondary" />}
            {!isSigningUp && strings.signUp}
          </MUI.Button>

          {!hasAcceptedTerms && showPrivacyAlert && (
            <MUI.Typography variant="caption" color="red" mt={1}>
              {strings.privacyPolicyAlertText}
            </MUI.Typography>
          )}

          <MUI.FormControlLabel
            sx={{ marginTop: 1 }}
            control={<MUI.Checkbox />}
            checked={hasAcceptedTerms}
            onChange={(_, checked) => setHasAcceptedTerms(checked)}
            label={
              <MUI.Typography variant="caption" color="GrayText">
                <div dangerouslySetInnerHTML={{ __html: strings.privacyPolicyConfirmMessage }} />
              </MUI.Typography>
            }
          />

          <MUI.Button
            sx={{ marginTop: 6, minWidth: 200 }}
            disabled={isLoggingIn || isSigningUp}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => void viewModel.login(location, navigate)}
          >
            {isLoggingIn && <LoadingIndicator size="tiny" color="secondary" />}
            {!isLoggingIn && strings.login}
          </MUI.Button>
        </MUI.Box>
        <MUI.Box marginTop={4}>
          <MUI.Link underline="none" onClick={() => void viewModel.switchLanguage()}>
            <MUI.Typography variant="body2" sx={{ fontWeight: 400, color: theme.palette.secondary.dark }}>
              {titleOfSwitchLocaleButton(viewModel.currentLocale)}
            </MUI.Typography>
          </MUI.Link>
        </MUI.Box>

        <MUI.Typography variant="caption" sx={{ marginTop: 4 }} color="GrayText" align="center">
          {viewModel.version}
        </MUI.Typography>
        <MUI.Typography variant="caption" sx={{ marginTop: 0.5 }} color="GrayText" align="center">
          {viewModel.copyright}
        </MUI.Typography>

        <MUI.Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={viewModel.hasError}
          onClose={(_, reason) => {
            if (reason !== 'clickaway') {
              viewModel.hasError = false;
            }
          }}
        >
          <MUI.SnackbarContent
            message={
              <MUI.Box display="flex" flexDirection="row" alignItems="center">
                <ErrorIcon sx={{ fontSize: 20 }} />

                <MUI.Box marginLeft={1} marginRight={1}>
                  {strings.loginError}
                </MUI.Box>

                <MUI.IconButton color="inherit" onClick={() => (viewModel.hasError = false)}>
                  <CloseIcon sx={{ fontSize: 20 }} />
                </MUI.IconButton>
              </MUI.Box>
            }
          />
        </MUI.Snackbar>
      </Stack>
    </MUI.Box>
  );
});

function titleOfSwitchLocaleButton(currentLocale: Locale): string {
  // No need for localization
  switch (currentLocale) {
    case 'en':
      return 'Français';
    case 'fr':
      return 'English';
  }
}
