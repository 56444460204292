import { css } from '@emotion/css';
import { SchoolCalendarViewModel } from '@insights/viewmodels';
import ErrorIcon from '@mui/icons-material/Error';
import TodayIcon from '@mui/icons-material/Today';
import { Box, Button, CircularProgress, IconButton, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { red } from '@mui/material/colors';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Row } from '../layout';

export interface SchoolCalendarHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarViewModel;
}

export const SchoolCalendarHeader = observer((props: SchoolCalendarHeaderProps) => {
  const { imageService, localizationService } = useInsightsServices();
  const { viewModel, sx, className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;
  const theme = useTheme();

  const navigationArrowClassName = css([
    {
      height: 16,
      width: 16,
      margin: theme.spacing(0.5)
    }
  ]);

  return (
    <Row sx={sx} className={className} verticalContentAlignment="center">
      <IconButton onClick={() => viewModel.goToPreviousMonth()} disabled={!viewModel.canGoToPreviousMonth}>
        <img
          className={navigationArrowClassName}
          src={imageService.studyoImages.generic.todayArrowLeft}
          alt="Left arrow"
        />
      </IconButton>
      <IconButton onClick={() => viewModel.goToCurrentMonth()}>
        <TodayIcon />
      </IconButton>
      <IconButton onClick={() => viewModel.goToNextMonth()} disabled={!viewModel.canGoToNextMonth}>
        <img
          className={navigationArrowClassName}
          src={imageService.studyoImages.generic.todayArrowRight}
          alt="Right arrow"
        />
      </IconButton>

      <Typography variant="body1" fontWeight="500" mx={1}>
        {viewModel.activeMonth.formattedString(
          localizationService.localizedStrings.models.dateFormats.monthYearUnabridged
        )}
      </Typography>

      <Box flex={1} />

      {viewModel.isUpdating && <CircularProgress sx={{ color: red[200] }} size="1rem" />}
      {viewModel.errorMessage != null && viewModel.errorMessage.length > 0 && (
        <Tooltip placement="top" title={viewModel.errorMessage}>
          <ErrorIcon />
        </Tooltip>
      )}
      {viewModel.hasChanges && (
        <Row sx={{ backgroundColor: red[200], ml: 2 }}>
          <Button onClick={() => void viewModel.saveChanges()}>{strings.save}</Button>
          <Button onClick={() => void viewModel.cancelChanges()}>{strings.cancel}</Button>
        </Row>
      )}
    </Row>
  );
});
