import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel } from '../../Model';
import { OnboardingQuestionKind } from '../../types';
import { onboardingQuestionKindFromProtobuf } from '../../types/EnumConversion';
import { OnboardingAnswer } from '../interfaces/OnboardingAnswer';

export class GrpcOnboardingAnswer extends BaseModel<PB.OnboardingAnswer> implements OnboardingAnswer {
  constructor(pb: PB.OnboardingAnswer) {
    super(pb);
  }

  get kind(): OnboardingQuestionKind {
    return onboardingQuestionKindFromProtobuf(this._pb.kind);
  }

  get simpleTextValue() {
    return this._pb.answer.case === 'simpleTextAnswer' ? this._pb.answer.value : '';
  }

  get largeTextValue() {
    return this._pb.answer.case === 'largeTextAnswer' ? this._pb.answer.value : '';
  }

  get singleChoiceValue() {
    return this._pb.answer.case === 'singleChoiceAnswer' ? this._pb.answer.value : '';
  }

  @LazyGetter()
  get multipleChoicesValue() {
    // Splitting an empty string returns a one item array.
    const value = this._pb.answer.case === 'multipleChoiceAnswer' ? this._pb.answer.value : '';
    return value.length > 0 ? value.split(',') : [];
  }

  @LazyGetter()
  get dateValue() {
    const value = this._pb.answer.case === 'dateAnswer' ? this._pb.answer.value : undefined;
    return value?.toDate();
  }

  @LazyGetter()
  get dateTimeValue() {
    const value = this._pb.answer.case === 'dateTimeAnswer' ? this._pb.answer.value : undefined;
    return value?.toDate();
  }

  get timeValue() {
    return this._pb.answer.case === 'timeAnswer' ? this._pb.answer.value : '';
  }

  get fileValue() {
    return this._pb.answer.case === 'fileAnswer' ? this._pb.answer.value : '';
  }

  get sensitiveSimpleTextValue() {
    return this._pb.answer.case === 'sensitiveSimpleTextAnswer' ? this._pb.answer.value : '';
  }
}
