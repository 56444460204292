import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { PremiumFeature } from '@shared/models/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { FeatureNotAvailableContent } from './FeatureNotAvailableContent.ts';

export interface RequiresFeatureConditionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  requiredFeature: PremiumFeature;
  featureNotAvailableContent?: FeatureNotAvailableContent;
  children?: React.ReactNode;
}

export const RequiresFeatureCondition = observer((props: RequiresFeatureConditionProps) => {
  const { accountService, localizationService } = useInsightsServices();
  const { className, sx, style, requiredFeature, children, featureNotAvailableContent } = props;

  const strings = localizationService.localizedStrings.insights.components.requiresFeatureCondition;
  const isAllowed = accountService.isFeatureAllowed(requiredFeature);

  if (isAllowed) {
    return (
      <MUI.Box sx={sx} className={className} style={style}>
        {children}
      </MUI.Box>
    );
  }

  if (featureNotAvailableContent == null) {
    return null;
  }

  const title = featureNotAvailableContent.title ?? strings.defaultTitle;
  const message = featureNotAvailableContent.message ?? strings.defaultMessage;

  return (
    <MUI.Box
      sx={sx}
      className={className}
      style={style}
      padding={featureNotAvailableContent.padding}
      width={featureNotAvailableContent.fill != null ? '100%' : undefined}
      height={featureNotAvailableContent.fill != null ? '100%' : undefined}
    >
      {title && (
        <MUI.Typography variant={featureNotAvailableContent.titleVariant ?? 'h4'} gutterBottom>
          {title}
        </MUI.Typography>
      )}
      <MUI.Typography>{message}</MUI.Typography>
    </MUI.Box>
  );
});
