import * as MUI from '@mui/material';
import { omit } from 'lodash';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface ExpansionPanelProps extends MUI.AccordionProps {
  /**
   * Key that will read and save the card expansion state in the settings store
   */
  stateKey: string;
}

export const ExpansionPanel = observer((props: ExpansionPanelProps) => {
  const { settingsStore } = useInsightsServices();
  const { stateKey, children } = props;
  const cardPreferences = settingsStore.cardPreferences;

  return (
    <MUI.Accordion
      {...omit(props, ['stateKey', 'settingsStore'])}
      expanded={cardPreferences.cardStateIsExpanded.get(stateKey) ?? false}
      onChange={(_, expanded) => cardPreferences.setIsExpanded(stateKey, expanded)}
    >
      {children}
    </MUI.Accordion>
  );
});
