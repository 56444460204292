import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { GeneralOptionsViewModel } from '@insights/viewmodels';
import {
  Autocomplete,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
  TextField,
  Typography,
  createFilterOptions
} from '@mui/material';
import { Day } from '@shared/models/types';
import { observer } from 'mobx-react';

const filter = createFilterOptions<string>();

export interface CreateSchoolGeneralProps {
  sx?: SxProps;
  className?: string;
  viewModel: GeneralOptionsViewModel;
}

export const CreateSchoolGeneral = observer(({ sx, className, viewModel }: CreateSchoolGeneralProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.createSchool;

  return (
    <Card sx={sx} className={className}>
      <CardContent sx={{ width: '100%' }}>
        <Stack spacing={2}>
          <Typography variant="h5">{strings.generalSection}</Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              sx={{ flex: 2 }}
              required
              autoFocus
              value={viewModel.schoolName}
              label={strings.schoolNameLabel}
              onChange={(event) => (viewModel.schoolName = event.target.value)}
              error={!viewModel.hasValidSchoolName}
              helperText={!viewModel.hasValidSchoolName && strings.invalidSchoolNameWarning}
            />
            <FormControl sx={{ flex: 1 }}>
              <InputLabel htmlFor="language-select">{strings.languageLabel}</InputLabel>
              <Select
                value={viewModel.language}
                inputProps={{ id: 'language-select' }}
                onChange={(event) => (viewModel.language = String(event.target.value))}
              >
                <MenuItem value="en">{strings.languageEnglish}</MenuItem>
                <MenuItem value="fr">{strings.languageFrench}</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              sx={{ flex: 2 }}
              value={viewModel.comments}
              label={strings.commentsLabel}
              onChange={(event) => (viewModel.comments = event.target.value)}
            />
            <Autocomplete
              sx={{ flex: 1 }}
              multiple
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={viewModel.suggestedTags}
              value={viewModel.tags}
              onChange={(_, v) => (viewModel.tags = v as string[])}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option);

                if (inputValue !== '' && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              renderInput={(p) => <TextField {...p} label={strings.tagsLabel} />}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              type="date"
              required
              label={strings.startDateLabel}
              value={viewModel.startDay.asDateString}
              onChange={(event) => {
                const day = Day.fromDateString(event.target.value);
                if (day != null) {
                  viewModel.startDay = day;
                }
              }}
            />
            <TextField
              fullWidth
              type="date"
              required
              label={strings.endDateLabel}
              value={viewModel.endDay.asDateString}
              onChange={(event) => {
                const day = Day.fromDateString(event.target.value);
                if (day != null) {
                  viewModel.endDay = day;
                }
              }}
            />
          </Stack>
          {!viewModel.hasValidDays && (
            <Typography variant="caption" color="error">
              {strings.invalidDaysWarning}
            </Typography>
          )}
          {viewModel.canBeDemo && (
            <FormControlLabel
              control={<Checkbox checked={viewModel.isDemo} onChange={(e) => (viewModel.isDemo = e.target.checked)} />}
              label={strings.demoLabel}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
});
