import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { EditableOnboardingProcess } from '@shared/models/onboarding/implementations';
import { observer } from 'mobx-react';
import * as React from 'react';
import { OnboardingTextsEdition } from './OnboardingTextsEdition';

export interface OnboardingProcessEditionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  editableProcess: EditableOnboardingProcess;
  onFileUpload?: (dataUrl: string, fileName?: string) => Promise<void>;
}

export const OnboardingProcessEdition = observer((props: OnboardingProcessEditionProps) => {
  const { className, style, sx, editableProcess, onFileUpload } = props;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <OnboardingTextsEdition texts={editableProcess.editableDescription} onFileUpload={onFileUpload} />
    </MUI.Box>
  );
});
