// TODO MUI.Box

import { SectionInfo } from '@insights/models';
import { SxProps, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { sortBy } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { cleanDiacritics } from 'underscore.string';
import { RouteParamNames, RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { SectionName } from './SectionName';
import { Container, Row } from './layout';

export interface SectionsInlineListProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  sections: SectionInfo[];
  noneLabel?: string;
  configId: string;
  forManagement?: boolean;
  linkTo?: boolean;
}

export const SectionsInlineList = observer((props: SectionsInlineListProps) => {
  const { reactRouterRouteService } = useInsightsServices();
  const { className, sx, style, sections, configId, forManagement = true, linkTo = true, noneLabel = '-' } = props;

  // TODO: @Jacques I don't like having to put that Container here, for the row to center vertically.
  return sections.length === 0 ? (
    <Container sx={{ ...sx, my: 0.5 }} className={className} style={style}>
      <Row verticalContentAlignment="center">
        <Typography variant="body1">{noneLabel}</Typography>
      </Row>
    </Container>
  ) : (
    <Row sx={sx} wrap="wrap" verticalContentAlignment="top" className={className} style={style}>
      {sortBy(sections, (sectionInfo) =>
        sectionInfo.section != null
          ? [
              sectionInfo.isAutoEnrolled,
              cleanDiacritics(sectionInfo.section.title).toLowerCase(),
              sectionInfo.section.sectionNumber
            ]
          : []
      ).map((sectionInfo) => (
        <Container
          key={sectionInfo.id}
          sx={{
            backgroundColor: sectionInfo.isAutoEnrolled
              ? grey[200]
              : sectionInfo.isLockedAsStudent
                ? blue[50]
                : undefined,
            my: 0.5,
            mr: 1
          }}
        >
          <SectionName
            title={sectionInfo.section?.title ?? '---'}
            color={sectionInfo.section?.color}
            subInformation1={forManagement ? sectionInfo.section?.importId : sectionInfo.section?.sectionNumber}
            size="small"
            sx={{ width: 150 }}
            linkTo={
              linkTo && sectionInfo.section != null
                ? reactRouterRouteService.resolveLocation(RouteTemplates.sectionDetails, [
                    { name: RouteParamNames.configId, value: configId },
                    {
                      name: RouteParamNames.sectionId,
                      value: sectionInfo.id
                    }
                  ])
                : undefined
            }
          />
        </Container>
      ))}
    </Row>
  );
});
