// TODO MUI.Box

import { css } from '@emotion/css';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { ContentDefinitionUtils } from '@shared/components/utils';
import { SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { TaskInformationOneLine } from './TaskInformationOneLine';
import { TaskInformationTwoLine } from './TaskInformationTwoLine';
import { Column } from './layout';

export interface TaskInformationProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  task: ContentDefinitionModel;
  type: 'one-line' | 'two-lines';
  size: 'normal' | 'small';
  showDueDayInTooltip?: boolean;
  disableTooltip?: boolean;
  sectionsById: Record<string, SectionModel>;
  schoolYearConfig: SchoolYearConfigurationModel;
}

export const TaskInformation = observer((props: TaskInformationProps) => {
  const { localizationService } = useInsightsServices();
  const {
    className,
    style,
    sx,
    task,
    showDueDayInTooltip,
    type,
    disableTooltip,
    size,
    sectionsById,
    schoolYearConfig
  } = props;

  const displayTitle = ContentDefinitionUtils.getDisplayTitleForContent(task, localizationService.localizedStrings);
  const notes = ContentDefinitionUtils.getDisplayedNotes(task);
  const strings = localizationService.localizedStrings.insights.components.tasks;

  const tooltipClassName = css({ maxWidth: '50vw', whiteSpace: 'pre-wrap' });

  const tooltip = !disableTooltip ? (
    <Column>
      <MUI.Typography sx={{ color: 'white', fontWeight: 400 }}>{displayTitle}</MUI.Typography>
      {showDueDayInTooltip && (
        <MUI.Typography sx={{ color: 'white', fontStyle: 'italic' }}>{`(${strings.dueOn} ${task.dueDay.formattedString(
          localizationService.localizedStrings.models.dateFormats.shortUnabridged
        )})`}</MUI.Typography>
      )}
      <MUI.Typography sx={{ color: 'white' }}>{notes}</MUI.Typography>
    </Column>
  ) : (
    ''
  );

  return (
    <MUI.Tooltip
      key={task.id}
      sx={sx}
      className={className}
      style={style}
      title={tooltip}
      classes={{ tooltip: tooltipClassName }}
    >
      <span style={{ overflow: 'hidden' }}>
        {type === 'one-line' && (
          <TaskInformationOneLine
            displayTitle={displayTitle}
            notes={notes}
            task={task}
            size={size}
            sectionsById={sectionsById}
            schoolYearConfig={schoolYearConfig}
          />
        )}

        {type === 'two-lines' && (
          <TaskInformationTwoLine
            displayTitle={displayTitle}
            notes={notes}
            task={task}
            size={size}
            sectionsById={sectionsById}
            schoolYearConfig={schoolYearConfig}
          />
        )}
      </span>
    </MUI.Tooltip>
  );
});
