import { ObservablePresenter, ValueVariationPresenter } from '@insights/components';
import { ConfigGlobalStatsViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { ConfigGlobalStats } from '@shared/models/metrics';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

type ActiveTeachersVariationMode = 'past-week' | 'past-month' | 'this-year';

export interface ActiveTeachersVariationProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  mode: ActiveTeachersVariationMode;
  viewModel: ConfigGlobalStatsViewModel;
}

export const ActiveTeachersVariation = observer((props: ActiveTeachersVariationProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel, mode } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.teachers;

  const caption = useMemo(() => {
    switch (mode) {
      case 'past-week':
        return strings.pastWeek;

      case 'past-month':
        return strings.pastMonth;

      case 'this-year':
        return strings.thisYear;

      default:
        throw new Error('Unknown mode');
    }
  }, [mode, localizationService.currentLocale]);

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <ObservablePresenter
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        data={viewModel.data}
        indicatorsSize="small"
        render={(data) => {
          const values = getValues(data, mode);
          return (
            <ValueVariationPresenter caption={caption} value={values.value} variationPercentage={values.variation} />
          );
        }}
      />
    </MUI.Box>
  );
});

function getValues(configGlobalStats: ConfigGlobalStats, mode: ActiveTeachersVariationMode) {
  switch (mode) {
    case 'past-week':
      return {
        value: configGlobalStats.activeTeachers.lastWeek,
        variation: configGlobalStats.activeTeachers.lastWeekVariation
      };

    case 'past-month':
      return {
        value: configGlobalStats.activeTeachers.lastMonth,
        variation: configGlobalStats.activeTeachers.lastMonthVariation
      };

    case 'this-year':
      return {
        value: configGlobalStats.activeTeachers.currentSchoolYear,
        variation: undefined
      };

    default:
      throw new Error('Unknown mode');
  }
}
