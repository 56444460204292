import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { EditableOnboardingAnswer, OnboardingAnswer } from '@shared/models/onboarding';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LastAnswerTooltip } from './LastAnswerTooltip';

export interface TextFieldQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  answer: EditableOnboardingAnswer;
  lastYearAnswer?: OnboardingAnswer;
  disabled?: boolean;
}

export const TextFieldQuestion = observer((props: TextFieldQuestionProps) => {
  const { className, style, sx, answer, lastYearAnswer, disabled } = props;

  // We only support these links.
  const isLink = answer.simpleTextValue.startsWith('https://');
  // We work with styles because we want the input to be read-only
  // but not disabled, to allow text selection.
  const inputStyle: React.CSSProperties = disabled
    ? {
        fontWeight: 400,
        color: grey[500]
      }
    : {
        fontWeight: 400
      };

  return isLink && disabled ? (
    <MUI.Box sx={sx} display="flex" flexDirection="row" alignItems="center">
      <MUI.Typography variant="body2" noWrap>
        <MUI.Link href={answer.simpleTextValue} target="_blank" rel="noreferrer">
          {answer.simpleTextValue}
        </MUI.Link>
      </MUI.Typography>
      <OpenInNewIcon fontSize="small" sx={{ ml: 1 }} />
    </MUI.Box>
  ) : (
    <MUI.TextField
      sx={sx}
      className={className}
      style={style}
      InputProps={{
        readOnly: disabled,
        style: inputStyle,
        endAdornment: (
          <MUI.InputAdornment position="end">
            {lastYearAnswer != null && lastYearAnswer.simpleTextValue.length > 0 && (
              <LastAnswerTooltip
                answer={lastYearAnswer.simpleTextValue}
                onCopy={() => (answer.simpleTextValue = lastYearAnswer.simpleTextValue)}
              />
            )}
          </MUI.InputAdornment>
        )
      }}
      fullWidth
      value={answer.simpleTextValue}
      onChange={(e) => (answer.simpleTextValue = e.target.value)}
    />
  );
});
