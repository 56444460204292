import { OnboardingQuestionViewModel } from '@insights/viewmodels';
import LastYearIcon from '@mui/icons-material/EventRepeat';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface MultipleChoiceQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: OnboardingQuestionViewModel;
}

export const MultipleChoiceQuestion = observer((props: MultipleChoiceQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, viewModel } = props;
  const languageCode = localizationService.currentLocale;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <MUI.FormGroup sx={sx} className={className} style={style}>
      <MUI.Stack>
        {viewModel.choices.map((choice) => (
          <MUI.FormControlLabel
            key={`q-${viewModel.templateName}-c-${choice.value}`}
            control={
              <MUI.Checkbox
                sx={{ paddingY: '6px' }}
                disabled={viewModel.isDisabled}
                checked={viewModel.answer.multipleChoicesValue.includes(choice.value)}
                onChange={(e) =>
                  e.target.checked
                    ? viewModel.answer.addMultipleChoicesValue(choice.value)
                    : viewModel.answer.removeMultipleChoicesValue(choice.value)
                }
              />
            }
            label={
              <MUI.Stack direction="row" spacing={2}>
                <MUI.Typography fontWeight="400">
                  {choice.label.find((l) => l.languageCode === languageCode)?.value ?? ''}
                </MUI.Typography>
                {viewModel.lastYearAnswer?.multipleChoicesValue?.includes(choice.value) && (
                  <MUI.Tooltip title={strings.lastYearAnswerTooltip}>
                    <LastYearIcon color="info" fontSize="small" />
                  </MUI.Tooltip>
                )}
              </MUI.Stack>
            }
          />
        ))}
      </MUI.Stack>
    </MUI.FormGroup>
  );
});
