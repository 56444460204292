import { css } from '@emotion/css';
import { ObservablePresenter, SectionName, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { SectionUtils } from '@insights/utils';
import { TeacherDetailsFilters } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps, createTheme, useTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface TeacherDetailsFilterDialogProps extends DialogResult<TeacherDetailsFilters> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  teacherId: string;
  initialFilters?: TeacherDetailsFilters;
}

export const TeacherDetailsFilterDialog = observer((props: TeacherDetailsFilterDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx, style, initialFilters, teacherId, onCancel, onSuccess, configId } = props;
  const strings = localizationService.localizedStrings.insights.views.account;
  const theme = useTheme();

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createTeacherDetailsFilter(configId, teacherId, onSuccess!, onCancel!, initialFilters)
  );

  const contentClassName = css({ minHeight: 100, paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) });

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        maxWidth="xs"
        fullWidth={true}
        scroll="paper"
        onClose={() => viewModel.close()}
        className={className}
        style={style}
      >
        <MUI.DialogTitle>{strings.teacherDetailsFilterDialogTitle}</MUI.DialogTitle>

        <MUI.DialogContent dividers classes={{ root: contentClassName }}>
          <ObservablePresenter
            className={contentClassName}
            data={viewModel.data}
            render={(data) => (
              <MUI.Box>
                <MUI.Box display="flex" flexDirection="row" alignItems="center">
                  <MUI.Typography variant="caption">
                    {strings.teacherDetailsFilterDisplayedSectionsFitlerTitle}
                  </MUI.Typography>
                  <MUI.Box flex={1} />
                  <MUI.Checkbox
                    onChange={(e, checked) => viewModel.toggleAllSections(checked)}
                    edge={false}
                    checked={viewModel.areAllSectionsSelected}
                    indeterminate={!viewModel.areAllSectionsSelected && !viewModel.areNoSectionsSelected}
                  />
                </MUI.Box>

                <MUI.List dense disablePadding>
                  {data.sections.map((section) => (
                    <MUI.ListItem
                      key={section.id}
                      disableGutters
                      dense
                      secondaryAction={
                        <MUI.Checkbox
                          checked={viewModel.isSectionSelected(section.id)}
                          onChange={(e, checked) => viewModel.toggleSection(section.id, checked)}
                        />
                      }
                    >
                      <MUI.ListItemText disableTypography>
                        <SectionName
                          title={SectionUtils.formatTitle(section)}
                          color={section.color}
                          subInformation1={section.sectionNumber}
                        />
                      </MUI.ListItemText>
                    </MUI.ListItem>
                  ))}
                </MUI.List>
              </MUI.Box>
            )}
          />
        </MUI.DialogContent>

        <MUI.DialogActions>
          <MUI.Button variant="outlined" onClick={() => viewModel.clear()}>
            {strings.resetFilter}
          </MUI.Button>

          <MUI.Box flex={1} />

          <MUI.Button variant="outlined" onClick={() => viewModel.close()}>
            {strings.close}
          </MUI.Button>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
