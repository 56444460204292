import { css } from '@emotion/css';
import { AuthorizationRoleCondition, Label } from '@insights/components';
import { SchoolYearConfigurationScreenInfo } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { orange } from '@mui/material/colors';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { Locale } from '@shared/resources/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SchoolYearConfigurationInformationProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  data: SchoolYearConfigurationScreenInfo;
  onEditIndividualAccount?: (accountId: string) => Promise<void>;
}

export const SchoolYearConfigurationInformation = observer((props: SchoolYearConfigurationInformationProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, style, data, onEditIndividualAccount } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;
  const theme = useTheme();

  const displayTitle = SchoolYearConfigurationUtils.displayTitle(data.schoolYearConfiguration);
  const stateLabel = strings.getConfigStateLabel(data.schoolYearConfiguration.state);
  // Only used when isDemo is true, no need to be empty otherwise.
  const demoLabel = strings.getDemoSchoolLabel(data.schoolYearConfiguration.demoDay);

  const tagClassname = css({
    '& .MuiChip-label': {
      fontSize: theme.typography.caption.fontSize
    }
  });

  return (
    <MUI.Grid sx={sx} className={className} style={style} container spacing={2}>
      {/* School name */}
      <MUI.Grid item xs={12}>
        <MUI.Box display="flex" flexDirection="row" alignItems="flex-end">
          <MUI.Box flex={1}>
            <Label>{strings.schoolNameFieldLabel}</Label>
            <MUI.Typography>{data.schoolYearConfiguration.schoolName}</MUI.Typography>
          </MUI.Box>

          {data.schoolYearConfiguration.isDemo && (
            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
              <MUI.Chip variant="outlined" color="info" label={demoLabel} />
            </AuthorizationRoleCondition>
          )}
        </MUI.Box>
      </MUI.Grid>

      {/* School title */}
      {Boolean(displayTitle) && (
        <MUI.Grid item xs={12}>
          <Label>{strings.schoolTitleFieldLabel}</Label>
          <MUI.Typography>{displayTitle}</MUI.Typography>
        </MUI.Grid>
      )}

      {/* State */}
      <MUI.Grid
        item
        xs={12}
        sx={data.schoolYearConfiguration.state === 'active' ? undefined : { backgroundColor: orange[100] }}
      >
        <Label>{strings.stateFieldLabel}</Label>
        <MUI.Typography>{stateLabel}</MUI.Typography>
      </MUI.Grid>

      {/* Comments */}
      {Boolean(data.schoolYearConfiguration.comments) && (
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <MUI.Grid item xs={12}>
            <Label>{strings.commentsFieldLabel}</Label>
            <MUI.Typography>{data.schoolYearConfiguration.comments}</MUI.Typography>
          </MUI.Grid>
        </AuthorizationRoleCondition>
      )}

      {/* Expected number of students */}
      {data.schoolYearConfiguration.expectedStudentCount > 0 && (
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <MUI.Grid item xs={12}>
            <Label>{strings.expectedStudentCountFieldLabel}</Label>
            <MUI.Typography>{data.schoolYearConfiguration.expectedStudentCount}</MUI.Typography>
          </MUI.Grid>
        </AuthorizationRoleCondition>
      )}

      {/* Onboarding code */}
      {Boolean(data.schoolYearConfiguration.managedOnboardCode) && (
        <MUI.Grid item xs={12}>
          <Label>{strings.onboardingCodeFieldLabel}</Label>
          <MUI.Typography>{data.schoolYearConfiguration.managedOnboardCode}</MUI.Typography>
        </MUI.Grid>
      )}

      {/* Timezone */}
      {Boolean(data.schoolYearConfiguration.timezone) && (
        <MUI.Grid item xs={12}>
          <Label>{strings.timezoneLabel}</Label>
          <MUI.Typography>{data.schoolYearConfiguration.timezone}</MUI.Typography>
        </MUI.Grid>
      )}

      {/* Language */}
      <MUI.Grid item xs={12}>
        <Label>{strings.languageLabel}</Label>
        <MUI.Typography>{strings.getLocaleDisplayName(data.schoolYearConfiguration.language as Locale)}</MUI.Typography>
      </MUI.Grid>

      {/* Tags */}
      <AuthorizationRoleCondition allowedRoles={['super-admin']}>
        {data.schoolYearConfiguration.tags.length > 0 && (
          <MUI.Grid item xs={12}>
            <MUI.Stack direction="row" spacing={1}>
              {data.schoolYearConfiguration.tags.map((tag, i) => (
                <MUI.Chip key={`tag-${i}`} className={tagClassname} variant="outlined" color="info" label={tag} />
              ))}
            </MUI.Stack>
          </MUI.Grid>
        )}
      </AuthorizationRoleCondition>

      {/* Individual account */}
      {data.individualAccount != null && (
        <MUI.Grid item xs={12}>
          <MUI.Button
            sx={{ mt: 2 }}
            variant="outlined"
            onClick={() => void onEditIndividualAccount?.(data.individualAccount!.id)}
          >
            {strings.editIndividualAccount}
          </MUI.Button>
        </MUI.Grid>
      )}
    </MUI.Grid>
  );
});
