import { css } from '@emotion/css';
import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { PremiumFeatureList } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface EditFeaturesDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  schoolYearConfiguration: SchoolYearConfigurationModel;
}

export const EditFeaturesDialog = observer((props: EditFeaturesDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx, style, schoolYearConfiguration, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.features;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createEditableFeatures(schoolYearConfiguration, onSuccess!, onCancel!)
  );

  const checkboxLabelClassName = css({ marginLeft: 0, flex: 1 });

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="xs"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <MUI.DialogTitle>{strings.editFeaturesDialogTitle}</MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <MUI.Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
              {PremiumFeatureList.map((feature) => (
                <MUI.FormControlLabel
                  key={`feature-${feature}`}
                  classes={{
                    root: checkboxLabelClassName,
                    label: checkboxLabelClassName
                  }}
                  control={<MUI.Checkbox />}
                  label={strings.getFeatureLabel(feature)}
                  labelPlacement="start"
                  checked={viewModel.getFieldValue(feature) as boolean | undefined}
                  onChange={(_, checked) => viewModel.setFieldValue(feature, checked)}
                />
              ))}
            </MUI.Box>
          </MUI.DialogContent>

          <MUI.DialogActions>
            <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
              {strings.cancelButtonCaption}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isExecuting={viewModel.isSubmitting}
              onClick={() => void viewModel.save()}
            >
              {strings.saveButtonCaption}
            </InsightsButton>
          </MUI.DialogActions>
        </form>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
