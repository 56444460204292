import { BlackbaudMappingsViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowIcon from '@mui/icons-material/ForwardOutlined';
import * as MUI from '@mui/material';
import { styled, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { BlackbaudAssignmentKind, EditableBlackbaudKindMapping } from '@shared/models/connectors';
import { AllContentWorkloadLevels, ContentIcon } from '@shared/models/types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { BlackbaudAssignmentKindSelector } from './BlackbaudAssignmentKindSelector';
import { ContentIconSelector } from './ContentIconSelector';
import { ContentWorkloadLevelSelector } from './ContentWorkloadLevelSelector';

export interface BlackbaudAssignmentKindMappingsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: BlackbaudMappingsViewModel;
}

export const BlackbaudAssignmentKindMappings = observer((props: BlackbaudAssignmentKindMappingsProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  function addKind(viewModel: BlackbaudMappingsViewModel, kind: BlackbaudAssignmentKind | undefined): void {
    // We actually add this new kind mapping
    if (kind != null) {
      viewModel.addKindMapping(kind);
    }
  }

  function updateKind(kindMapping: EditableBlackbaudKindMapping, kind: BlackbaudAssignmentKind | undefined): void {
    kindMapping.kindId = kind?.id ?? 0n;
    kindMapping.kindName = kind?.name ?? '';
  }

  function updateIcon(kindMapping: EditableBlackbaudKindMapping, icon: ContentIcon): void {
    kindMapping.icon = icon;
  }

  return (
    <Root className={className} style={style}>
      <MUI.TableHead>
        <MUI.TableRow>
          <MUI.TableCell className={classNames('cell', 'kindNameCell')}>
            <MUI.InputLabel shrink>{strings.kindNameTitle}</MUI.InputLabel>
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'iconCell')}>
            <MUI.InputLabel shrink>{strings.iconTitle}</MUI.InputLabel>
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'workloadCell')}>
            <MUI.InputLabel shrink>{strings.workloadLevelTitle}</MUI.InputLabel>
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'actionCell')} />
        </MUI.TableRow>
      </MUI.TableHead>
      <MUI.TableBody>
        {viewModel.kindMappings.map((m, index) => (
          <MUI.TableRow key={`kind-${index}`}>
            <MUI.TableCell className={classNames('cell', 'kindNameCell')}>
              <MUI.Box display="flex" flexDirection="row" alignItems="center">
                <BlackbaudAssignmentKindSelector
                  availableKinds={viewModel.availableKinds}
                  selectedKindId={Number(m.kindId)}
                  onChange={(kind) => updateKind(m, kind)}
                />
                <ArrowIcon className="arrow" />
              </MUI.Box>
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'iconCell')}>
              <ContentIconSelector
                selectedIcon={m.icon}
                workloadLevel={m.workloadLevel}
                onChange={(icon) => updateIcon(m, icon)}
              />
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'workloadCell')}>
              <ContentWorkloadLevelSelector
                selectedLevel={m.workloadLevel}
                availableLevels={AllContentWorkloadLevels}
                onChange={(level) => (m.workloadLevel = level)}
              />
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'actionCell')}>
              <MUI.IconButton onClick={() => m.markAsDeleted()}>
                <DeleteIcon fontSize="small" />
              </MUI.IconButton>
            </MUI.TableCell>
          </MUI.TableRow>
        ))}
      </MUI.TableBody>
      <MUI.TableFooter>
        <MUI.TableRow className="nextRow">
          <MUI.TableCell className={classNames('cell', 'kindNameCell')}>
            <BlackbaudAssignmentKindSelector
              availableKinds={viewModel.availableKinds}
              selectedKindId={(viewModel.nextAssignmentKind && Number(viewModel.nextAssignmentKind.id)) ?? 0}
              onChange={(kind) => addKind(viewModel, kind)}
            />
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'iconCell')} />
          <MUI.TableCell className={classNames('cell', 'workloadCell')} />
          <MUI.TableCell className={classNames('cell', 'actionCell')} />
        </MUI.TableRow>
      </MUI.TableFooter>
    </Root>
  );
});

const Root = styled(MUI.Table)(({ theme }) => ({
  '.cell': {
    padding: '2px 8px 2px 0px',
    border: 'none',
    verticalAlign: 'bottom'
  },
  '.kindNameCell': {
    width: '35%'
  },
  '.iconCell': {
    width: '35%'
  },
  '.workloadCell': {
    width: '15%'
  },
  '.actionCell': {
    width: '15%'
  },
  '.nextRow': {
    backgroundColor: grey[200]
  },
  '.arrow': {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));
