import { MetricsSideNavigation } from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface MetricsLayoutProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const MetricsLayout = observer((props: MetricsLayoutProps) => {
  const { viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createSchoolConfiguration(configId), [configId]);

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="row" className={className} style={style}>
      <MetricsSideNavigation demoMode={viewModel.demoMode} configId={viewModel.configId} />

      <MUI.Box flexGrow={1}>
        <Outlet />
      </MUI.Box>
    </MUI.Box>
  );
});
