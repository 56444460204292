import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import * as React from 'react';
import { InformationText } from './InformationText';

export interface SectionProps {
  sx?: SxProps;
  header: string;
  className?: string;
  headerClassName?: string;
  headerSx?: SxProps;
  footerNote?: string;
  children?: React.ReactNode;
}

export function Section({ sx, className, headerClassName, headerSx, header, footerNote, children }: SectionProps) {
  return (
    <MUI.Box sx={sx} className={className} display="flex" flexDirection="column">
      <MUI.Typography variant="h5" color="textSecondary" fontWeight="300" className={headerClassName} sx={headerSx}>
        {header}
      </MUI.Typography>

      {children}

      {footerNote && <InformationText className={headerClassName} text={footerNote} />}
    </MUI.Box>
  );
}
