import { DateTimePickerLocalizationProvider, InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SchoolYearConfigurationDemoCopyDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  schoolYearConfiguration: SchoolYearConfigurationModel;
}

export const SchoolYearConfigurationDemoCopyDialog = observer((props: SchoolYearConfigurationDemoCopyDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx, style, schoolYearConfiguration, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;
  const navigate = useNavigate();

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createSchoolYearConfigurationDemoCopy(schoolYearConfiguration, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <DateTimePickerLocalizationProvider>
        <MUI.Dialog
          sx={sx}
          open={true}
          onClose={() => viewModel.cancel()}
          maxWidth="sm"
          fullWidth={true}
          className={className}
          style={style}
        >
          <form
            noValidate
            onSubmit={(e) => {
              // This is to prevent the page from reloading on submit
              e.preventDefault();
              return false;
            }}
          >
            <MUI.DialogTitle>{strings.createDemoCopyTitle}</MUI.DialogTitle>
            <MUI.DialogContent dividers>
              <MUI.Grid container spacing={2}>
                <MUI.Grid item xs={12}>
                  <MUI.Typography>{strings.createDemoCopyMessage}</MUI.Typography>
                </MUI.Grid>

                <MUI.Grid item xs={12}>
                  <MUI.TextField
                    fullWidth
                    label={strings.newSchoolNameLabel}
                    value={viewModel.newSchoolName}
                    onChange={(e) => (viewModel.newSchoolName = e.target.value)}
                  />
                </MUI.Grid>

                <MUI.Grid item xs={12}>
                  <MUI.TextField
                    fullWidth
                    label={strings.baseAccountEmailLabel}
                    helperText={strings.baseAccountEmailHint}
                    value={viewModel.baseAccountEmail}
                    onChange={(e) => (viewModel.baseAccountEmail = e.target.value)}
                  />
                </MUI.Grid>

                <MUI.Grid item xs={12}>
                  <MUI.FormControl fullWidth>
                    <MUI.InputLabel shrink id="language-label">
                      {strings.newSchoolLanguageLabel}
                    </MUI.InputLabel>
                    <MUI.Select
                      labelId="language-label"
                      value={viewModel.languageCode}
                      onChange={(e) => (viewModel.languageCode = String(e.target.value))}
                    >
                      <MUI.MenuItem value="en">
                        {/* no localization needed */}
                        <MUI.Typography>English</MUI.Typography>
                      </MUI.MenuItem>
                      <MUI.MenuItem value="fr">
                        {/* no localization needed */}
                        <MUI.Typography>Français</MUI.Typography>
                      </MUI.MenuItem>
                    </MUI.Select>
                  </MUI.FormControl>
                </MUI.Grid>
              </MUI.Grid>
            </MUI.DialogContent>
            <MUI.DialogActions>
              <InsightsButton isExecuting={viewModel.isCreatingCopy} onClick={() => viewModel.cancel()}>
                {strings.cancelButtonCaption}
              </InsightsButton>
              <InsightsButton
                isSubmit
                showSpinnerOnExecuting
                isDisabled={!viewModel.canCreateCopy}
                isExecuting={viewModel.isCreatingCopy}
                onClick={() => void viewModel.create(navigate)}
              >
                {strings.createCopyButtonLabel}
              </InsightsButton>
            </MUI.DialogActions>
          </form>
        </MUI.Dialog>
      </DateTimePickerLocalizationProvider>
    </MUI.ThemeProvider>
  );
});
