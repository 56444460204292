import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AccountModel } from '@shared/models/config';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface SelectOnboardingMultiOwnershipProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  label: string;
  selectedAccounts: AccountModel[];
  availableAccounts: AccountModel[];
  onSelection: (accountIds: string[]) => void;
}

export const SelectOnboardingMultiOwnership = observer((props: SelectOnboardingMultiOwnershipProps) => {
  const { sx, className, style, label, selectedAccounts, availableAccounts, onSelection } = props;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <MUI.Typography variant="caption" color="textSecondary">
        {label}
      </MUI.Typography>
      <MUI.Autocomplete
        multiple
        limitTags={2}
        value={selectedAccounts.map((a) => ({
          id: a.id,
          label: AccountUtils.getDisplayLastFirstName(a)
        }))}
        options={availableAccounts.map((a) => ({
          id: a.id,
          label: AccountUtils.getDisplayLastFirstName(a)
        }))}
        isOptionEqualToValue={(option, value) => option.id == value.id}
        renderInput={(params) => <MUI.TextField {...params} />}
        onChange={(_, values) => onSelection(values.map((v) => v.id))}
        size="small"
        renderTags={(values, getTagProps) => (
          <MUI.Stack direction="row">
            {values.map((v, index) => (
              <MUI.Chip {...getTagProps({ index })} key={v.id} label={v.label} size="small" />
            ))}
          </MUI.Stack>
        )}
      />
    </MUI.Box>
  );
});
