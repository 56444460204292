import { css } from '@emotion/css';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { BehaviourSummaryItemType } from '../models';

export interface BehaviourSummaryItemTooltipProps {
  sx?: SxProps;
  className?: string;
  type: BehaviourSummaryItemType;
}

export const BehaviourSummaryItemTooltip = observer(({ sx, className, type }: BehaviourSummaryItemTooltipProps) => {
  const { localizationService, accountService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.components.behaviourSummary;

  let tooltip = '';
  let greyTooltip: string | undefined = undefined;
  let greenTooltip: string | undefined = undefined;
  let yellowTooltip: string | undefined = undefined;
  let redTooltip: string | undefined = undefined;

  switch (type) {
    case 'app-open':
      tooltip = strings.appOpenTooltip;
      greyTooltip = strings.appOpenGreyTooltip;
      greenTooltip = strings.appOpenGreenTooltip;
      yellowTooltip = strings.appOpenYellowTooltip;
      redTooltip = strings.appOpenRedTooltip;
      break;

    case 'task-completion':
      tooltip = strings.taskCompletionTooltip;
      greenTooltip = strings.taskCompletionGreenTooltip;
      yellowTooltip = strings.taskCompletionYellowTooltip;
      redTooltip = strings.taskCompletionRedTooltip;
      break;

    case 'parent-invitation':
      tooltip = strings.invitesParentTooltip;
      greyTooltip = strings.invitesParentGreyTooltip;
      greenTooltip = strings.invitesParentGreenTooltip;
      yellowTooltip = strings.invitesParentYellowTooltip;
      redTooltip = strings.invitesParentRedTooltip;
      break;

    default:
      throw new Error('Unknown type');
  }

  const theme = useTheme();
  const tooltipBoxClassName = css({ marginRight: theme.spacing(1), width: 12, height: 12 });

  return (
    <MUI.Box sx={sx} className={className}>
      <MUI.Typography fontWeight="500" variant="body2">
        {tooltip}
      </MUI.Typography>
      {greyTooltip != null && (
        <MUI.Box display="flex" alignItems="center">
          <MUI.Box className={tooltipBoxClassName} bgcolor={grey[300]} borderRadius={50}>
            &nbsp;
          </MUI.Box>
          <MUI.Typography variant="body2">{greyTooltip}</MUI.Typography>
        </MUI.Box>
      )}
      {greenTooltip != null && (
        <MUI.Box display="flex" alignItems="center">
          <MUI.Box className={tooltipBoxClassName} bgcolor={green[400]} borderRadius={50}>
            &nbsp;
          </MUI.Box>
          <MUI.Typography variant="body2">{greenTooltip}</MUI.Typography>
        </MUI.Box>
      )}
      {yellowTooltip != null && (
        <MUI.Box display="flex" alignItems="center">
          <MUI.Box className={tooltipBoxClassName} bgcolor={orange[400]} borderRadius={50}>
            &nbsp;
          </MUI.Box>
          <MUI.Typography variant="body2">{yellowTooltip}</MUI.Typography>
        </MUI.Box>
      )}
      {redTooltip != null && (
        <MUI.Box display="flex" alignItems="center">
          <MUI.Box className={tooltipBoxClassName} bgcolor={red[400]} borderRadius={50}>
            &nbsp;
          </MUI.Box>
          <MUI.Typography variant="body2">{redTooltip}</MUI.Typography>
        </MUI.Box>
      )}

      {!accountService.isFeatureAllowed('organizational-quotient') && (
        <MUI.Box marginTop={2}>
          <MUI.Typography variant="body2" fontWeight="500">
            {strings.featureNotAvailableTooltipNote}
          </MUI.Typography>
          <MUI.Typography variant="body2" fontWeight="500">
            {strings.featureNotAvailableTooltip}
          </MUI.Typography>
        </MUI.Box>
      )}
    </MUI.Box>
  );
});
