import { MinLayoutWidth } from '@insights/Constants';
import {
  AuthorizationRoleCondition,
  AutomatedImportHistory,
  AutomatedTransformationImportList,
  ObservablePresenter,
  PageHeaderBar
} from '@insights/components';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface AutomatedImportDetailsScreenProps {
  sx?: SxProps;
  className?: string;
}

export const AutomatedImportDetailsScreen = observer(({ sx, className }: AutomatedImportDetailsScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.imports;
  const compStrings = localizationService.localizedStrings.insights.components.import;
  const navigate = useNavigate();

  const params = useParams();
  const configId = params.configId ?? '';
  const sessionId = params.sessionId ?? '';
  const automatedImportId = params.automatedImportId ?? '';

  const viewModel = useMemo(
    () => viewModelFactory.createAutomatedImportDetails(configId, sessionId, automatedImportId),
    [configId, sessionId, automatedImportId]
  );

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className}>
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <MUI.Tooltip title={strings.deleteSessionTooltip}>
            <MUI.IconButton
              aria-label="Add"
              sx={{ m: 1 }}
              onClick={() => void viewModel.deleteAutomatedImport(navigate)}
            >
              <DeleteIcon fontSize="small" />
            </MUI.IconButton>
          </MUI.Tooltip>
        </AuthorizationRoleCondition>
      </PageHeaderBar>

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingAutomatedImportMessage}
          errorMessage={strings.loadingAutomatedImportError}
          render={(data) => (
            <MUI.Box>
              <MUI.Stack direction="row" ml={1} alignItems="center" spacing={2}>
                <MUI.Stack>
                  <MUI.Typography variant="h5">{data.automatedImport.name}</MUI.Typography>
                  <MUI.Typography variant="subtitle1">
                    {compStrings.automatedImportTimes(data.automatedImport.days, data.automatedImport.time)}
                  </MUI.Typography>
                </MUI.Stack>
                <MUI.IconButton onClick={() => void data.editAutomatedImport()}>
                  <EditIcon />
                </MUI.IconButton>
              </MUI.Stack>
              <MUI.Grid container>
                <MUI.Grid item xs={12} md={6}>
                  <MUI.Card sx={{ minWidth: MinLayoutWidth }}>
                    <AutomatedTransformationImportList
                      sx={{ width: '100%', height: '100%' }}
                      viewModel={data.transformations}
                    />
                  </MUI.Card>
                </MUI.Grid>

                <MUI.Grid item xs={12} md={6}>
                  <MUI.Card sx={{ minWidth: MinLayoutWidth }}>
                    <AutomatedImportHistory viewModel={data.history} />
                  </MUI.Card>
                </MUI.Grid>
              </MUI.Grid>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
