import * as MUI from '@mui/material';
import { Autocomplete, SxProps } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AccountModel } from '@shared/models/config';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface SelectOnboardingOwnershipProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  label: string;
  selectedAccount?: AccountModel;
  availableAccounts: AccountModel[];
  onSelection: (accountId?: string) => void;
}

export const SelectOnboardingOwnership = observer((props: SelectOnboardingOwnershipProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, label, selectedAccount, availableAccounts, onSelection } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <MUI.Box className={className} style={style}>
      <Autocomplete
        selectOnFocus
        options={availableAccounts}
        value={selectedAccount}
        onChange={(_, v: AccountModel | null) => onSelection(v?.id)}
        renderInput={(params) => (
          <MUI.TextField {...params} fullWidth label={label} InputLabelProps={{ shrink: true }} />
        )}
        getOptionLabel={(account) => AccountUtils.getDisplayLastFirstName(account)}
        noOptionsText={strings.accountNotFoundLabel}
      />
    </MUI.Box>
  );
});
