import { Label } from '@insights/components';
import { SchoolYearConfigurationScreenInfo } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SchoolYearConfigurationDatesProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  data: SchoolYearConfigurationScreenInfo;
}

export const SchoolYearConfigurationDates = observer((props: SchoolYearConfigurationDatesProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, style, data } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;

  return (
    <MUI.Grid sx={sx} className={className} style={style} container spacing={2}>
      {/* Start Date */}
      <MUI.Grid item xs={12}>
        <MUI.Box display="flex" flexDirection="row" alignItems="flex-start">
          <MUI.Box flex={1}>
            <Label>{strings.startDateFieldLabel}</Label>
            <MUI.Typography>
              {data.schoolYearConfiguration.startDay.formattedString(
                localizationService.localizedStrings.models.dateFormats.mediumUnabridged
              )}
            </MUI.Typography>
          </MUI.Box>
        </MUI.Box>
      </MUI.Grid>

      {/* End Date */}
      <MUI.Grid item xs={12}>
        <MUI.Box display="flex" flexDirection="row" alignItems="flex-start">
          <MUI.Box flex={1}>
            <Label>{strings.endDateFieldLabel}</Label>
            <MUI.Typography>
              {data.schoolYearConfiguration.endDay.formattedString(
                localizationService.localizedStrings.models.dateFormats.mediumUnabridged
              )}
            </MUI.Typography>
          </MUI.Box>
        </MUI.Box>
      </MUI.Grid>

      {/* Teacher Access Date */}
      {data.schoolYearConfiguration.teacherAvailabilityDate != null && (
        <MUI.Grid item xs={12}>
          <MUI.Box display="flex" flexDirection="row" alignItems="flex-start">
            <MUI.Box flex={1}>
              <Label>{strings.teachersAccessDate}</Label>
              <MUI.Typography>
                {format(
                  data.schoolYearConfiguration.teacherAvailabilityDate,
                  localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime
                )}
              </MUI.Typography>
            </MUI.Box>
          </MUI.Box>
        </MUI.Grid>
      )}

      {/* Student Access Date */}
      {data.schoolYearConfiguration.studentAvailabilityDate != null && (
        <MUI.Grid item xs={12}>
          <MUI.Box display="flex" flexDirection="row" alignItems="flex-start">
            <MUI.Box flex={1}>
              <Label>{strings.studentsAccessDate}</Label>
              <MUI.Typography>
                {format(
                  data.schoolYearConfiguration.studentAvailabilityDate,
                  localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime
                )}
              </MUI.Typography>
            </MUI.Box>
          </MUI.Box>
        </MUI.Grid>
      )}
    </MUI.Grid>
  );
});
