import { SectionModel } from '@shared/models/config';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, computedFn, fromPromise } from 'mobx-utils';

export interface TeacherDashboardFiltersInfo {
  sections: SectionModel[];
}

export interface TeacherDashboardFilters {
  excludedSectionIds: string[];
}

export interface TeacherDashboardFilterDialogViewModel {
  readonly data: IPromiseBasedObservable<TeacherDashboardFiltersInfo>;
  filters: TeacherDashboardFilters;

  readonly areAllSectionsSelected: boolean;
  readonly areNoSectionsSelected: boolean;

  isSectionSelected: (sectionId: string) => boolean;
  toggleSection: (sectionId: string, selected: boolean) => void;
  toggleAllSections: (selected: boolean) => void;

  clear: () => void;
  close: () => void;
}

export const EmptyTeacherDashboardFilters: TeacherDashboardFilters = {
  excludedSectionIds: []
};

export class AppTeacherDashboardFilterDialogViewModel implements TeacherDashboardFilterDialogViewModel {
  @observable private _filters: TeacherDashboardFilters;
  @observable private _allSectionIds: string[] = [];

  constructor(
    private readonly _configId: string,
    private readonly _teacherId: string,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _onSuccess: (filters: TeacherDashboardFilters) => void,
    private readonly _onCancel: () => void,
    initialFilters?: TeacherDashboardFilters
  ) {
    makeObservable(this);
    this._filters = initialFilters ?? EmptyTeacherDashboardFilters;
  }

  get data(): IPromiseBasedObservable<TeacherDashboardFiltersInfo> {
    return fromPromise(this.loadData());
  }

  @computed.struct
  get filters(): TeacherDashboardFilters {
    return this._filters;
  }

  set filters(value: TeacherDashboardFilters) {
    this._filters = value;
  }

  @computed
  get areAllSectionsSelected(): boolean {
    return this.filters.excludedSectionIds.length === 0;
  }

  @computed
  get areNoSectionsSelected(): boolean {
    return this.filters.excludedSectionIds.length === this._allSectionIds.length;
  }

  isSectionSelected = computedFn((sectionId: string): boolean => {
    return !this.filters.excludedSectionIds || this.filters.excludedSectionIds.indexOf(sectionId) === -1;
  });

  toggleSection(sectionId: string, selected: boolean) {
    let excludedSectionIds: string[] = [];

    if (selected) {
      excludedSectionIds = this.filters.excludedSectionIds?.filter((id) => id !== sectionId) || [];
    } else {
      excludedSectionIds = [...this.filters.excludedSectionIds, sectionId];
    }

    this.filters = {
      ...this.filters,
      excludedSectionIds: excludedSectionIds
    };
  }

  toggleAllSections(selected: boolean): void {
    const excludedSectionIds: string[] = selected ? [] : this._allSectionIds;

    this.filters = {
      ...this.filters,
      excludedSectionIds: excludedSectionIds
    };
  }

  @action
  clear() {
    this._filters = EmptyTeacherDashboardFilters;
  }

  close() {
    this._onSuccess(this.filters);
  }

  private async loadData(): Promise<TeacherDashboardFiltersInfo> {
    const sections = await this._schoolYearConfigurationStore.getTaughtSectionsForTeacherId(
      this._configId,
      this._teacherId
    );

    runInAction(() => (this._allSectionIds = sections.map((section) => section.id)));

    return {
      sections
    };
  }
}
