import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  SxProps,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material';
import { ExternalAccount } from '@shared/models/connectors';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ScheduledAutoMatchSettingsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  externalAccount: ExternalAccount;
}

export const ScheduledAutoMatchSettingsDialog = observer((props: ScheduledAutoMatchSettingsDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, externalAccount, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createScheduledAutoMatchSettings(externalAccount, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <Dialog sx={sx} open={true} onClose={() => void viewModel.cancel()} maxWidth="sm" fullWidth={true}>
        <DialogTitle>{strings.scheduledAutoMatchSettings}</DialogTitle>

        <DialogContent dividers>
          <Stack>
            <FormControlLabel
              disabled={!viewModel.canScheduleAutoMatch}
              label={strings.scheduledAutoMatchEnabledLabel}
              control={
                <Checkbox
                  color="primary"
                  checked={viewModel.isScheduledAutoMatchEnabled}
                  onChange={(e) => (viewModel.isScheduledAutoMatchEnabled = e.target.checked)}
                />
              }
            />
            <Typography variant="subtitle1" mt={2}>
              {strings.autoMatchEntriesTitle}
            </Typography>
            {viewModel.autoMatchHistory.map((h, i) => (
              <Stack key={`auto-match-${i}`} direction="row">
                <Typography textAlign="end">{h.studyoPattern}</Typography>
                <Typography color="textSecondary" mx={1}>
                  ↔️
                </Typography>
                <Typography>{h.externalPattern}</Typography>
              </Stack>
            ))}
          </Stack>
        </DialogContent>

        <DialogActions>
          {/* Note: Once we allow editing auto-match entries in this dialog, we should
                    disable this button while there are unsaved changes. Otherwise, that
                    manual run would use the original list. */}
          <InsightsButton
            isSubmit
            showSpinnerOnExecuting
            isExecuting={viewModel.isRunning}
            isDisabled={!viewModel.canScheduleAutoMatch || viewModel.isSaving}
            onClick={() => void viewModel.run()}
          >
            {strings.runButton}
          </InsightsButton>
          <Box flex={1} />
          <InsightsButton
            isDisabled={viewModel.isSaving || viewModel.isRunning}
            onClick={() => void viewModel.cancel()}
          >
            {strings.cancelButton}
          </InsightsButton>
          <InsightsButton
            isSubmit
            showSpinnerOnExecuting
            isExecuting={viewModel.isSaving}
            isDisabled={!viewModel.canSave || viewModel.isRunning}
            onClick={() => void viewModel.save()}
          >
            {strings.saveButton}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
