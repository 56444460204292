import MoreIcon from '@mui/icons-material/MoreVert';
import * as MUI from '@mui/material';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { PropsWithChildren, useRef, useState } from 'react';

export interface MoreActionMenuProps extends PropsWithChildren {
  sx?: SxProps;
  disabled?: boolean;
  badgeProps?: Partial<MUI.BadgeProps>;
}

export const MoreActionMenu = observer((props: MoreActionMenuProps) => {
  const { children, disabled, sx, badgeProps } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const filteredChildren = React.Children.map(children, (c) => c)?.filter((c) => c != null) ?? [];

  return (
    <React.Fragment>
      {filteredChildren.length > 0 && (
        <React.Fragment>
          <MUI.IconButton sx={sx} ref={buttonRef} disabled={disabled} onClick={() => setIsOpen(true)} size="large">
            <MUI.Badge {...badgeProps}>
              <MoreIcon />
            </MUI.Badge>
          </MUI.IconButton>
          <MUI.Menu anchorEl={buttonRef.current} open={isOpen} onClose={() => setIsOpen(false)}>
            {filteredChildren.map((c, i) => (
              <Box key={i} sx={{ outline: 'none' }} onClick={() => setIsOpen(false)}>
                {c}
              </Box>
            ))}
          </MUI.Menu>
        </React.Fragment>
      )}
    </React.Fragment>
  );
});
