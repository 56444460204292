import {
  IndicatorDot,
  ObservablePresenter,
  SchoolDayHeader,
  SectionName,
  TaskInformation,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { SectionCourseOccurrenceDetailInfo } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps, createTheme, styled } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { SchoolDay } from '@shared/models/calendar';
import { SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { LocalizationService } from '@shared/resources/services';
import { DialogResult } from '@shared/services';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { cleanDiacritics } from 'underscore.string';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SectionCourseOccurrenceDetailProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  sectionId: string;
  schoolDay: SchoolDay;
}

export const SectionCourseOccurrenceDetail = observer((props: SectionCourseOccurrenceDetailProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style, sectionId, schoolDay, onSuccess, onCancel, configId } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createSectionCourseOccurrenceDetail(configId, sectionId, schoolDay, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <Root
        sx={sx}
        open={true}
        maxWidth="md"
        fullWidth={true}
        onClose={() => void viewModel.close()}
        className={className}
        style={style}
      >
        <MUI.DialogTitle>
          {/* Ensure the overflow is visible so that the badge is displayed */}
          <MUI.Box display="flex" flexDirection="row" alignItems="center" overflow="visible">
            <ObservablePresenter
              data={viewModel.detailInfo}
              loadingIndicator={false}
              render={(data) => (
                <SectionName
                  title={data.section.title}
                  subInformation1={data.section.sectionNumber}
                  color={data.section.color}
                  subInformation2={strings.localizedNumberOfStudents(data.studentCount)}
                  size="medium"
                />
              )}
            />

            <MUI.Box flex={1} />

            <SchoolDayHeader schoolDay={viewModel.schoolDay} displayType="page-header" />
          </MUI.Box>
        </MUI.DialogTitle>

        <MUI.DialogContent dividers>
          <ObservablePresenter
            className="content"
            data={viewModel.detailInfo}
            render={(data) => renderTable(data, localizationService)}
          />
        </MUI.DialogContent>

        <MUI.DialogActions>
          {viewModel.detailInfo.case({
            fulfilled: (data) => {
              /* Only show the 'Show published tasks' check box if there are published tasks */
              if (data.publishedTaskCount === 0) {
                return null;
              }

              return (
                <MUI.FormControlLabel
                  classes={{ root: 'publishedTasksCheckbox' }}
                  control={
                    <MUI.Checkbox
                      checked={viewModel.showPublishedTasks}
                      onChange={(_, checked) => (viewModel.showPublishedTasks = checked)}
                    />
                  }
                  label={strings.showPublishedTasks}
                />
              );
            }
          })}
          <MUI.Box flex={1} />
          <MUI.Button variant="outlined" onClick={() => void viewModel.close()}>
            {strings.close}
          </MUI.Button>
        </MUI.DialogActions>
      </Root>
    </MUI.ThemeProvider>
  );
});

function renderTable(data: SectionCourseOccurrenceDetailInfo, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  return (
    // NOTE: The padding is for the card's shadow
    <MUI.Box height="100%" width="100%" pt="1px" pl="1px" pr="2px" pb="2px">
      <MUI.Table sx={{ width: '100%', height: '100%' }} className="table">
        <MUI.TableHead>
          <MUI.TableRow className="headerRow">
            <MUI.TableCell className={classNames('tableCell', 'headerCell', 'studentCell')}>
              {strings.student}
            </MUI.TableCell>
            <MUI.TableCell className={classNames('tableCell', 'headerCell', 'tasksCell')}>
              {strings.tasks}
            </MUI.TableCell>
            <MUI.TableCell className={classNames('tableCell', 'headerCell', 'bulletCell')}>
              <MUI.Tooltip title={strings.announcedTooltip}>
                <span>{strings.announced}</span>
              </MUI.Tooltip>
            </MUI.TableCell>
            <MUI.TableCell className={classNames('tableCell', 'headerCell', 'bulletCell')}>
              <MUI.Tooltip title={strings.plannedTooltip}>
                <span>{strings.planned}</span>
              </MUI.Tooltip>
            </MUI.TableCell>
            <MUI.TableCell className={classNames('tableCell', 'headerCell', 'bulletCell')}>
              <MUI.Tooltip title={strings.stepsTooltip}>
                <span>{strings.steps}</span>
              </MUI.Tooltip>
            </MUI.TableCell>
            <MUI.TableCell className={classNames('tableCell', 'headerCell', 'bulletCell')}>
              <MUI.Tooltip title={strings.doneTooltip}>
                <span>{strings.done}</span>
              </MUI.Tooltip>
            </MUI.TableCell>
          </MUI.TableRow>
        </MUI.TableHead>

        <MUI.TableBody>
          {orderBy(
            data.students,
            [(s) => s.getFilteredTasks().length > 0, (s) => cleanDiacritics(s.displayName.toLowerCase())],
            ['desc', 'asc']
          ).map((studentInfo) => {
            const orderedTasks = orderBy(studentInfo.getFilteredTasks(), [(t) => t.isSlave], ['desc']);

            return (
              <MUI.TableRow key={studentInfo.student.id} className={'dataRow'}>
                <MUI.TableCell className={classNames('tableCell', 'studentCell')}>
                  <MUI.Typography variant="body2" className={'studentName'}>
                    {studentInfo.displayName}
                  </MUI.Typography>
                </MUI.TableCell>
                <MUI.TableCell className={classNames('tableCell', 'tasksCell')}>
                  {renderTasksCell(orderedTasks, data.schoolYearConfig, data.sectionsById)}
                </MUI.TableCell>
                <MUI.TableCell className={classNames('tableCell', 'bulletCell')}>
                  {renderAnnouncedCell(orderedTasks)}
                </MUI.TableCell>
                <MUI.TableCell className={classNames('tableCell', 'bulletCell')}>
                  {renderPlannedCell(orderedTasks)}
                </MUI.TableCell>
                <MUI.TableCell className={classNames('tableCell', 'bulletCell')}>
                  {renderStepsCell(orderedTasks)}
                </MUI.TableCell>
                <MUI.TableCell className={classNames('tableCell', 'bulletCell')}>
                  {renderCompletedCell(orderedTasks)}
                </MUI.TableCell>
              </MUI.TableRow>
            );
          })}
        </MUI.TableBody>
      </MUI.Table>
    </MUI.Box>
  );
}

function renderTasksCell(
  tasks: ContentDefinitionModel[],
  schoolYearConfig: SchoolYearConfigurationModel,
  sectionsById: Record<string, SectionModel>
) {
  return (
    <MUI.Box display="flex" flexDirection="column">
      {tasks.map((task) => (
        <TaskInformation
          key={task.id}
          task={task}
          className="taskRow"
          type="one-line"
          size="small"
          schoolYearConfig={schoolYearConfig}
          sectionsById={sectionsById}
        />
      ))}
    </MUI.Box>
  );
}

function renderAnnouncedCell(tasks: ContentDefinitionModel[]) {
  return (
    <MUI.Box display="flex" flexDirection="column">
      {tasks.map((task) => {
        const isAnnounced = task.assignmentDay.dayCountUntil(task.dueDay) > 1;

        return (
          <MUI.Box
            key={task.id}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="centers"
            className="taskRow"
          >
            {isAnnounced && <IndicatorDot className="dot" color={green[500]} isFilled={true} />}
          </MUI.Box>
        );
      })}
    </MUI.Box>
  );
}

function renderPlannedCell(tasks: ContentDefinitionModel[]) {
  return (
    <MUI.Box display="flex" flexDirection="column">
      {tasks.map((task) => {
        const isPlanned = task.plannedDay.isAfter(task.assignmentDay);

        return (
          <MUI.Box
            key={task.id}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            className="taskRow"
          >
            {isPlanned && <IndicatorDot className="dot" color={green[500]} isFilled={true} />}
          </MUI.Box>
        );
      })}
    </MUI.Box>
  );
}

function renderStepsCell(tasks: ContentDefinitionModel[]) {
  return (
    <MUI.Box display="flex" flexDirection="column">
      {tasks.map((task) => {
        return (
          <MUI.Box
            key={task.id}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            className="taskRow"
          >
            {task.steps.length > 0 && <IndicatorDot className="dot" color={green[500]} isFilled={true} />}
          </MUI.Box>
        );
      })}
    </MUI.Box>
  );
}

function renderCompletedCell(tasks: ContentDefinitionModel[]) {
  return (
    <MUI.Box display="flex" flexDirection="column">
      {tasks.map((task) => {
        return (
          <MUI.Box
            key={task.id}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            className="taskRow"
          >
            {task.state === 'completed' && <IndicatorDot className="dot" color={green[500]} isFilled={true} />}
          </MUI.Box>
        );
      })}
    </MUI.Box>
  );
}

const Root = styled(MUI.Dialog)(({ theme }) => ({
  '.content': {
    minHeight: 100
  },
  '.dot': {
    width: 12,
    height: 12
  },
  '.table': {
    tableLayout: 'fixed'
  },
  '.tableCell': {
    padding: '1px 8px 1px 8px'
  },
  '.headerRow': {
    height: 'auto'
  },
  '.headerCell': {
    fontSize: 14,
    position: 'sticky',
    top: 0,
    backgroundColor: grey[100],
    color: theme.palette.getContrastText(grey[100]),
    zIndex: 1,
    paddingTop: 6,
    paddingBottom: 6
  },
  '.dataRow': {
    height: 'auto',
    verticalAlign: 'top'
  },
  '.taskRow': {
    height: '22px',
    paddingTop: 1,
    paddingBottom: 1
  },
  '.studentCell': {
    width: '45%'
  },
  '.studentName': {
    height: '22px',
    paddingTop: 1,
    paddingBottom: 1,
    fontWeight: 400
  },
  '.tasksCell': {
    width: '55%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  '.taskTitle': {
    fontWeight: 400,
    lineHeight: 'normal'
  },
  '.taskNotes': {
    lineHeight: 'normal'
  },
  '.bulletCell': {
    width: 80,
    textAlign: 'center'
  },
  '.tooltip': {
    maxWidth: 'none',
    whiteSpace: 'pre-wrap'
  },
  '.taskTooltipTitle': {
    color: 'white',
    fontWeight: 400
  },
  '.taskTooltipNotes': {
    color: 'white'
  },
  '.publishedTasksCheckbox': {
    marginLeft: 0
  }
}));
