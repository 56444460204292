import { MultipleValues } from '@insights/viewmodels';
import { FormControl, InputLabel, MenuItem, Select, SxProps, Typography } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AccountModel } from '@shared/models/config';
import { find } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { MultipleValuesControl } from './utils';

export interface EditableAccountSelectionProps {
  /**
   * The selected account id.
   */
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  selectedId: string | MultipleValues;
  /**
   * The accounts from which to select.
   */
  accounts: AccountModel[];
  /**
   * The field label.
   */
  label: string;
  /**
   * Indicates if the field value can be edited.
   */
  canEdit: boolean;
  sx?: SxProps;
  style?: React.CSSProperties;
  className?: string;

  /**
   * Callback when changing the selected value.
   * @param value The new account id.
   */
  onChange: (value: string) => void;
}

export const EditableAccountSelection = observer((props: EditableAccountSelectionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, selectedId, style, canEdit, onChange, accounts, label, className } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;

  return (
    <MultipleValuesControl
      sx={sx}
      value={selectedId}
      defaultValue={''}
      label={label}
      style={style}
      className={className}
      renderControl={(value) => (
        <FormControl style={style} className={className}>
          <InputLabel htmlFor="account-select" shrink={true}>
            {label}
          </InputLabel>
          <Select
            value={value}
            inputProps={{ id: 'account-select' }}
            disabled={!canEdit}
            onChange={(event) => onChange(String(event.target.value))}
            renderValue={() => {
              const account = find(accounts, { id: value });

              if (account == null) {
                return <Typography fontWeight="medium">{strings.none}</Typography>;
              } else {
                return (
                  <Typography fontWeight="500">
                    {AccountUtils.getDisplayLastFirstName(account, account.email)}
                  </Typography>
                );
              }
            }}
          >
            <MenuItem key="account-0" value="">
              <Typography fontWeight="500">{strings.none}</Typography>
            </MenuItem>
            {accounts.map((account) => (
              <MenuItem key={`account-${account.id}`} value={account.id}>
                <Typography fontWeight="500">{AccountUtils.getDisplayLastFirstName(account, account.email)}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
});
