import {
  AuthorizationRoleCondition,
  BehaviourSummary,
  BehaviourSupplement,
  ExpansionPanel,
  ObservablePresenter,
  PageHeaderBar,
  PageHeaderDetailBar,
  SectionList
} from '@insights/components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AdminOrTeacherAuthorizationRoles } from '@shared/models/types';
import { observer } from 'mobx-react';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';
import { StudentBehaviourHistory } from './StudentBehaviourHistory';

const SelectedSectionsCardStateKey = 'StudentDetailsSelectedSections';

export interface StudentDetailsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const StudentDetails = observer((props: StudentDetailsProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.students;

  const params = useParams();
  const configId = params.configId ?? '';
  const studentId = params.studentId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createStudentDetails(configId, studentId), [configId, studentId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Student Details' });
  });

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="column">
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={AdminOrTeacherAuthorizationRoles}>
          <MUI.Button onClick={() => void viewModel.navigateToPlanner()}>
            <MUI.Box display="flex" flexDirection="row" alignItems="center">
              <MUI.Typography>{strings.viewPlanner}</MUI.Typography>

              <OpenInNewIcon sx={{ ml: 0.5 }} color="action" />
            </MUI.Box>
          </MUI.Button>
        </AuthorizationRoleCondition>
      </PageHeaderBar>

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ height: '100%', width: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingStudentDetailsMessage}
          errorMessage={strings.loadingStudentDetailsErrorMessage}
          render={(data) => (
            <MUI.Box display="flex" flexDirection="column">
              {/* Student Info */}
              <PageHeaderDetailBar>
                <MUI.Box display="flex" flexDirection="row" alignItems="center">
                  <MUI.Box>
                    <MUI.Typography sx={{ fontSize: '2em', lineHeight: 'normal', fontWeight: 300 }}>
                      {AccountUtils.getDisplayLastFirstName(data.account, strings.noName)}
                    </MUI.Typography>
                    <MUI.Link variant="body1" target="_top" href={`mailto:${data.account.email}`}>
                      {data.account.email}
                    </MUI.Link>
                  </MUI.Box>

                  <MUI.Box flex={1} />

                  <BehaviourSummary
                    appOpenValue={data.oqProfile?.opensTheApp}
                    taskCompletionValue={data.oqProfile?.marksTasksAsDone}
                  />
                  {data.invitesParent != null && (
                    <BehaviourSupplement sx={{ ml: 2 }} invitesParent={data.invitesParent} />
                  )}
                </MUI.Box>
              </PageHeaderDetailBar>

              {/* Content */}
              <MUI.Box flex={1} overflow="auto" padding={2}>
                <MUI.Grid container alignItems="stretch">
                  {/* Behaviour History */}
                  <AuthorizationRoleCondition allowedRoles={AdminOrTeacherAuthorizationRoles}>
                    <MUI.Grid item xs={12}>
                      <MUI.Card>
                        <StudentBehaviourHistory
                          sx={{ height: '100%', width: '100%' }}
                          viewModel={viewModelFactory.createStudentBehaviourHistory(
                            viewModel.configId,
                            viewModel.studentId
                          )}
                        />
                      </MUI.Card>
                    </MUI.Grid>
                  </AuthorizationRoleCondition>

                  {/* Sections */}
                  {data.sections != null && data.sections.sections.length > 0 && (
                    <MUI.Grid item xs={12}>
                      <MUI.Card>
                        <ExpansionPanel
                          sx={{ height: '100%', width: '100%' }}
                          stateKey={SelectedSectionsCardStateKey}
                          elevation={0}
                        >
                          <MUI.AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <MUI.Typography variant="h6">{strings.selectedSections}</MUI.Typography>
                          </MUI.AccordionSummary>

                          <MUI.AccordionDetails sx={{ p: 0 }}>
                            <SectionList
                              sx={{ flex: 1 }}
                              sections={data.sections}
                              usePagination={false}
                              configId={viewModel.configId}
                            />
                          </MUI.AccordionDetails>
                        </ExpansionPanel>
                      </MUI.Card>
                    </MUI.Grid>
                  )}
                </MUI.Grid>
              </MUI.Box>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
