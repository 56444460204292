import { ManageBacMappingsViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowIcon from '@mui/icons-material/ForwardOutlined';
import * as MUI from '@mui/material';
import { styled, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { EditableManageBacAssignmentKindMapping } from '@shared/models/connectors';
import { EditableManageBacContentRepresentation } from '@shared/models/connectors/EditableManageBacContentRepresentation';
import { AllContentWorkloadLevels, ContentIcon } from '@shared/models/types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { ContentWorkloadLevelSelector } from './ContentWorkloadLevelSelector';
import { OptionalContentIconSelector } from './OptionalContentIconSelector';

export interface ManageBacAssignmentKindMappingsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: ManageBacMappingsViewModel;
}

export const ManageBacAssignmentKindMappings = observer((props: ManageBacAssignmentKindMappingsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  function updateIcon(mapping: EditableManageBacAssignmentKindMapping, icon: ContentIcon | undefined): void {
    if (icon == null) {
      mapping.representation = undefined;
    } else {
      if (mapping.representation == null) {
        mapping.representation = EditableManageBacContentRepresentation.createNew();
      }

      mapping.editableRepresentation.icon = icon!;
    }
  }

  return (
    <Root sx={sx} className={className} style={style}>
      <MUI.TableHead>
        <MUI.TableRow>
          <MUI.TableCell className={classNames('cell', 'kindCell')}>
            <MUI.InputLabel shrink>{strings.kindNameTitle}</MUI.InputLabel>
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'actionCell')} />
          <MUI.TableCell className={classNames('cell', 'iconCell')}>
            <MUI.InputLabel shrink>{strings.iconTitle}</MUI.InputLabel>
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'importanceCell')}>
            <MUI.InputLabel shrink>{strings.workloadLevelTitle}</MUI.InputLabel>
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'actionCell')} />
        </MUI.TableRow>
      </MUI.TableHead>
      <MUI.TableBody>
        {viewModel.assignmentKindMappings.map((m, index) => (
          <MUI.TableRow key={`mapping-${index}`}>
            <MUI.TableCell className={classNames('cell', 'kindCell')}>
              <MUI.Typography>{m.kind}</MUI.Typography>
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'actionCell')}>
              <ArrowIcon className={'arrow'} />
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'iconCell')}>
              <OptionalContentIconSelector
                selectedIcon={m.representation?.icon}
                workloadLevel={m.representation?.workloadLevel}
                onChange={(icon) => updateIcon(m, icon)}
              />
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'importanceCell')}>
              {m.representation?.icon != null && (
                <ContentWorkloadLevelSelector
                  selectedLevel={m.representation.workloadLevel}
                  availableLevels={AllContentWorkloadLevels}
                  onChange={(level) => (m.representation!.workloadLevel = level)}
                />
              )}
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'actionCell')}>
              <MUI.IconButton onClick={() => m.markAsDeleted()}>
                <DeleteIcon fontSize="small" />
              </MUI.IconButton>
            </MUI.TableCell>
          </MUI.TableRow>
        ))}
      </MUI.TableBody>
      <MUI.TableFooter>
        <MUI.TableRow className={'nextRow'}>
          <MUI.TableCell className={classNames('cell', 'kindCell')}>
            <MUI.Input
              fullWidth
              value={viewModel.addingKindName}
              onChange={(e) => (viewModel.addingKindName = e.currentTarget.value)}
              endAdornment={
                <MUI.InputAdornment position="end">
                  <MUI.IconButton onClick={() => viewModel.addAssignmentKindMapping()} disabled={!viewModel.canAddKind}>
                    <AddIcon fontSize="small" />
                  </MUI.IconButton>
                </MUI.InputAdornment>
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter' && viewModel.canAddKind) {
                  viewModel.addAssignmentKindMapping();
                }
              }}
            />
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'actionCell')} />
          <MUI.TableCell className={classNames('cell', 'iconCell')} />
          <MUI.TableCell className={classNames('cell', 'importanceCell')} />
          <MUI.TableCell className={classNames('cell', 'actionCell')} />
        </MUI.TableRow>
      </MUI.TableFooter>
    </Root>
  );
});

const Root = styled(MUI.Table)(() => ({
  '.cell': {
    padding: '2px 8px 2px 0px',
    border: 'none',
    verticalAlign: 'bottom'
  },
  '.kindCell': {
    width: '40%'
  },
  '.iconCell': {
    width: '20%'
  },
  '.importanceCell': {
    width: '20%'
  },
  '.actionCell': {
    width: '10%'
  },
  '.nextRow': {
    backgroundColor: grey[200]
  },
  '.arrow': {}
}));
