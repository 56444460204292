import { AlertService } from '@insights/services';
import { ExternalAccount } from '@shared/models/connectors';
import { Time } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface EditThrottleSettingsDialogViewModel {
  skippedSyncCycleCount: number;
  millisecondsBetweenSyncs: number;
  syncPauseTime: Time | undefined;
  syncResumeTime: Time | undefined;
  readonly isSubmitting: boolean;
  readonly hasChanges: boolean;

  save(): Promise<void>;
  cancel(): Promise<void>;
}

export class AppEditThrottleSettingsDialogViewModel implements EditThrottleSettingsDialogViewModel {
  @observable private _skippedSyncCycleCount = 0;
  @observable private _millisecondsBetweenSyncs = 0;
  @observable private _syncPauseTime: Time | undefined;
  @observable private _syncResumeTime: Time | undefined;
  @observable private _isSubmitting = false;
  private readonly _originalLocalSyncPauseTime: Time | undefined;
  private readonly _originalLocalSyncResumeTime: Time | undefined;

  constructor(
    private readonly _externalAccount: ExternalAccount,
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService
  ) {
    makeObservable(this);
    this._skippedSyncCycleCount = _externalAccount.skippedSyncCycleCount;
    this._millisecondsBetweenSyncs = _externalAccount.millisecondsBetweenSyncs;

    // Times must be converted back to local. We use today's timezone offset, even if those times are
    // used for the whole year.
    const today = new Date();

    if (_externalAccount.syncPauseTime != null) {
      today.setUTCHours(_externalAccount.syncPauseTime.hour);
      today.setUTCMinutes(_externalAccount.syncPauseTime.minute);
      this._syncPauseTime = this._originalLocalSyncPauseTime = Time.fromHoursMinutes(
        today.getHours(),
        today.getMinutes()
      );
    }

    if (_externalAccount.syncResumeTime != null) {
      today.setUTCHours(_externalAccount.syncResumeTime.hour);
      today.setUTCMinutes(_externalAccount.syncResumeTime.minute);
      this._syncResumeTime = this._originalLocalSyncResumeTime = Time.fromHoursMinutes(
        today.getHours(),
        today.getMinutes()
      );
    }
  }

  @computed
  get skippedSyncCycleCount(): number {
    return this._skippedSyncCycleCount;
  }

  set skippedSyncCycleCount(value: number) {
    this._skippedSyncCycleCount = value;
  }

  @computed
  get millisecondsBetweenSyncs(): number {
    return this._millisecondsBetweenSyncs;
  }

  set millisecondsBetweenSyncs(value: number) {
    this._millisecondsBetweenSyncs = value;
  }

  @computed
  get syncPauseTime(): Time | undefined {
    return this._syncPauseTime;
  }

  set syncPauseTime(value: Time | undefined) {
    this._syncPauseTime = value;
  }

  @computed
  get syncResumeTime(): Time | undefined {
    return this._syncResumeTime;
  }

  set syncResumeTime(value: Time | undefined) {
    this._syncResumeTime = value;
  }

  @computed
  get hasChanges(): boolean {
    return (
      this._skippedSyncCycleCount !== this._externalAccount.skippedSyncCycleCount ||
      this._millisecondsBetweenSyncs !== this._externalAccount.millisecondsBetweenSyncs ||
      (this._syncPauseTime == null) !== (this._originalLocalSyncPauseTime == null) ||
      (this._syncResumeTime == null) !== (this._originalLocalSyncResumeTime == null) ||
      (this._syncPauseTime != null && !this._syncPauseTime.isSame(this._originalLocalSyncPauseTime!)) ||
      (this._syncResumeTime != null && !this._syncResumeTime.isSame(this._originalLocalSyncResumeTime!))
    );
  }

  @computed
  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  @action
  async save() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if ((this._syncPauseTime == null) !== (this._syncResumeTime == null)) {
      await this._alertService.showMessage({
        title: strings.invalidSyncPauseResumeTimesTitle,
        message: strings.invalidSyncPauseResumeTimesMessage
      });
      return;
    }

    this._isSubmitting = true;

    try {
      if (this._syncPauseTime == null || this._syncResumeTime == null) {
        await this._connectorsStore.throttleAccountSync(
          this._externalAccount.configId,
          this._externalAccount.id,
          this._skippedSyncCycleCount,
          undefined,
          undefined,
          this._millisecondsBetweenSyncs
        );
      } else {
        // Times must be in UTC. We use today's timezone offset, even if those times are
        // used for the whole year.
        const today = new Date();

        today.setHours(this._syncPauseTime.hour);
        today.setMinutes(this._syncPauseTime.minute);
        const utcPauseTime = Time.fromHoursMinutes(today.getUTCHours(), today.getUTCMinutes());

        today.setHours(this._syncResumeTime.hour);
        today.setMinutes(this._syncResumeTime.minute);
        const utcResumeTime = Time.fromHoursMinutes(today.getUTCHours(), today.getUTCMinutes());

        await this._connectorsStore.throttleAccountSync(
          this._externalAccount.configId,
          this._externalAccount.id,
          this._skippedSyncCycleCount,
          utcPauseTime,
          utcResumeTime,
          this._millisecondsBetweenSyncs
        );
      }

      this._onSuccess();
    } catch (error) {
      // Not using base class error handling.
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError
      });
    } finally {
      runInAction(() => (this._isSubmitting = false));
    }
  }

  async cancel(): Promise<void> {
    if (this.isSubmitting) {
      return;
    }

    if (this.hasChanges) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.edit;
      const response = await this._alertService.showConfirmation({
        message: strings.pendingChangesMessage,
        okButtonCaption: strings.discardButtonCaption,
        cancelButtonCaption: strings.cancelButtonCaption
      });

      if (response === 'cancelled') {
        return;
      }
    }

    this._onCancel();
  }
}
