import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/blackbaud_sky_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { GrpcBlackbaudSkyAssignmentTypeMapping } from './GrpcBlackbaudSkyAssignmentTypeMapping';
import { GrpcBlackbaudSkyTokenInformation } from './GrpcBlackbaudSkyTokenInformation';
import { BlackbaudSkyExternalAccountDetails, BlackbaudSkyTokenInformation } from './interfaces';

export class GrpcBlackbaudSkyExternalAccountDetails implements BlackbaudSkyExternalAccountDetails {
  constructor(private readonly pb: BC.GetExternalAccountDetailsResponse) {}

  get tokens(): BlackbaudSkyTokenInformation | undefined {
    const pbTokens = this.pb.tokens;
    return pbTokens != null ? new GrpcBlackbaudSkyTokenInformation(pbTokens) : undefined;
  }

  get schoolYearLabel() {
    return this.pb.schoolYearLabel;
  }

  get schoolLevelId() {
    return this.pb.schoolLevelId;
  }

  get portalAddress() {
    return this.pb.portalAddress;
  }

  @LazyGetter()
  get mappings() {
    return this.pb.mappings.map((pb) => new GrpcBlackbaudSkyAssignmentTypeMapping(pb));
  }

  get ignoreUnmappedAssignmentTypes() {
    return this.pb.ignoreUnmappedAssignmentTypes;
  }
}
