import { ChangeIntegrationsViewModel } from '@insights/viewmodels';
import { Checkbox, FormControlLabel, SxProps, Typography } from '@mui/material';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Column } from '../layout';

export interface ChangeIntegrationsProps {
  sx?: SxProps;
  className?: string;
  viewModel: ChangeIntegrationsViewModel;
}

export const ChangeIntegrations = observer((props: ChangeIntegrationsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Column sx={sx} className={className}>
      {viewModel.availableIntegrations.map((ai) => (
        <FormControlLabel
          key={ai}
          label={strings.localizedIntegrationName(ai)}
          control={
            <Checkbox
              checked={viewModel.integrations.indexOf(ai) > -1}
              onChange={(event) => {
                if (event.target.checked) {
                  viewModel.integrations = _.union(viewModel.integrations, [ai]);
                } else {
                  viewModel.integrations = viewModel.integrations.filter((i) => i !== ai);
                }
              }}
            />
          }
        />
      ))}

      <Typography variant="caption" color="red">
        {viewModel.errorMessage}
      </Typography>
    </Column>
  );
});
