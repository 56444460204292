import { TeacherPlanningViewModel } from '@insights/viewmodels';
import PreviousWeekIcon from '@mui/icons-material/ArrowLeft';
import NextWeekIcon from '@mui/icons-material/ArrowRight';
import RestoreIcon from '@mui/icons-material/FirstPage';
import SkipIcon from '@mui/icons-material/KeyboardArrowRight';
import * as MUI from '@mui/material';
import { styled, SxProps, useTheme } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { ObserverAutoSizer } from '@shared/components/layout';
import {
  SpecialDaySymbolAndColor,
  SpecialDaySymbolGrid,
  SpecialDaySymbolImage
} from '@shared/components/special_day_symbols';
import { CourseOccurrence } from '@shared/models/calendar';
import { SectionColors } from '@shared/models/Colors';
import { Day } from '@shared/models/types';
import clsx from 'clsx';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { TeacherPlanningMoveLeftPopover } from './TeacherPlanningMoveLeftPopover';
import { TeacherPlanningMoveRightPopover } from './TeacherPlanningMoveRightPopover';

export interface TeacherPlanningProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: TeacherPlanningViewModel;
}

export const TeacherPlanning = observer((props: TeacherPlanningProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const dateFormats = localizationService.localizedStrings.models.dateFormats;
  const [actionAnchorElement, setActionAnchorElement] = useState<HTMLElement | undefined>();
  const previousDay = useRef<Day | undefined>();
  const nextDay = useRef<Day | undefined>();
  const theme = useTheme();

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const getOccurrenceTitle = (occurrence: CourseOccurrence): string => {
    return occurrence.displayTitle;
  };

  const getOccurrenceRoom = (occurrence: CourseOccurrence): string => {
    return occurrence.customRoomName.length > 0 ? occurrence.customRoomName : occurrence.roomName;
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (viewModel.movingLeftCell == null && viewModel.movingRightCell == null) {
      if (e.code === 'ArrowLeft' && previousDay.current != null) {
        viewModel.startingDay = previousDay.current;
      } else if (e.code === 'ArrowRight' && nextDay.current != null) {
        viewModel.startingDay = nextDay.current;
      }
    }
  };

  return (
    <ObserverAutoSizer>
      {({ width }) => {
        const maxDayCount = Math.floor((width - 60) / 150);

        // Keep those for keyboard handling.
        previousDay.current = maxDayCount >= 5 ? viewModel.previousStartOfWeek : viewModel.previousDay;
        nextDay.current = maxDayCount >= 5 ? viewModel.nextStartOfWeek : viewModel.nextDay;

        return (
          <Root sx={sx} className={className} style={style} position="relative" overflow="auto">
            <MUI.Box
              display="inline-flex"
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
              position="sticky"
              top={0}
              zIndex={1_000}
              sx={{ backgroundColor: theme.palette.background.paper }}
            >
              <MUI.IconButton
                size="small"
                disabled={previousDay.current == null}
                onClick={() => (viewModel.startingDay = previousDay.current!)}
              >
                <PreviousWeekIcon />
              </MUI.IconButton>
              {_.take(viewModel.visibleSchoolDays, maxDayCount).map((sd) => (
                <MUI.Tooltip
                  key={`visible-day-tooltip-${sd.day.asString}`}
                  className="defaultCursor"
                  classes={{
                    tooltip: 'tooltip',
                    popper: 'tooltipPopper'
                  }}
                  title={
                    sd.visibleSpecialDays.length > 0 ? (
                      <MUI.Box>
                        {sd.visibleSpecialDays.map((specialDay, index) => (
                          <MUI.Box
                            key={`special-day-${specialDay.symbol}-${index}`}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            <SpecialDaySymbolImage
                              symbol={specialDay.symbol}
                              color={SectionColors.get(specialDay.color) ?? SectionColors.get('medium-bmgray')}
                              squareSize={16}
                              alwaysDisplaySymbol={true}
                            />
                            <MUI.Box marginLeft={0.5}>
                              <MUI.Typography variant="body2">{specialDay.title}</MUI.Typography>
                            </MUI.Box>
                          </MUI.Box>
                        ))}
                      </MUI.Box>
                    ) : (
                      ''
                    )
                  }
                >
                  <MUI.Box
                    key={`visible-day-${sd.day.asString}`}
                    className={clsx('cellHeader', sd.day.isSame(viewModel.currentDay) && 'today')}
                    display="flex"
                    flexDirection="column"
                  >
                    <MUI.Typography variant="body2">{sd.day.formattedString(dateFormats.dayOfWeek)}</MUI.Typography>
                    <MUI.Typography variant="caption">{sd.day.formattedString(dateFormats.short)}</MUI.Typography>
                    <MUI.Typography variant="caption" className={'cycleDayLabel'}>
                      {sd.displayCycleDayTitle}
                    </MUI.Typography>
                    <SpecialDaySymbolGrid
                      sx={{ position: 'absolute', bottom: 4, right: 4 }}
                      displayKind="linear"
                      symbols={sd.visibleSpecialDays.map<SpecialDaySymbolAndColor>((specialDay) => ({
                        symbol: specialDay.symbol,
                        color: SectionColors.get(specialDay.color)
                      }))}
                      squareSize={12}
                      alwaysDisplaySymbol={true}
                      displayNoneSymbol={false}
                    />
                  </MUI.Box>
                </MUI.Tooltip>
              ))}
              <MUI.IconButton
                size="small"
                disabled={nextDay.current == null}
                onClick={() => (viewModel.startingDay = nextDay.current!)}
              >
                <NextWeekIcon />
              </MUI.IconButton>
            </MUI.Box>

            {_.flatten(
              viewModel.rows.map((row, rowIndex) => {
                const sectionColor = SectionColors.get(row.section.color);
                const sectionStyle: React.CSSProperties = {
                  backgroundColor: sectionColor,
                  color: 'white'
                };
                const skippedStyle: React.CSSProperties = {
                  backgroundColor: grey[500],
                  color: 'white'
                };

                return _.compact([
                  row.isFirst ? (
                    <MUI.Box
                      key={`section-header-${rowIndex}`}
                      className={'sectionRow'}
                      style={sectionStyle}
                      width={150 * Math.min(row.occurrences.length, maxDayCount)}
                      display="inline-flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <MUI.Typography variant="subtitle1">
                        {row.section.title} - {row.section.sectionNumber}
                      </MUI.Typography>
                      <MUI.Typography variant="caption">&nbsp;({row.section.importId})</MUI.Typography>
                    </MUI.Box>
                  ) : undefined,
                  <MUI.Box
                    key={`occurrence-row-${rowIndex}}`}
                    className={'occurrenceRow'}
                    display="inline-flex"
                    flexDirection="row"
                    flexWrap="nowrap"
                  >
                    {_.take(row.occurrences, maxDayCount).map((o, cellIndex) =>
                      o.period == null ? (
                        <MUI.Box
                          key={`occurrence-cell-${rowIndex}-${cellIndex}`}
                          className={o.schoolDay.day.isSame(viewModel.currentDay) ? 'emptyTodayCell' : 'emptyCell'}
                        >
                          &nbsp;
                        </MUI.Box>
                      ) : (
                        <MUI.Box
                          key={`occurrence-cell-${rowIndex}-${cellIndex}`}
                          className={'cell'}
                          display="flex"
                          flexDirection="column"
                          style={o.occurrence!.skipped ? skippedStyle : sectionStyle}
                        >
                          <MUI.Typography variant="caption">{getOccurrenceTitle(o.occurrence!)}</MUI.Typography>
                          <MUI.Typography variant="caption">{getOccurrenceRoom(o.occurrence!)}</MUI.Typography>
                          {o.occurrence?.skipped ? (
                            <MUI.IconButton
                              className={'periodAction'}
                              size="small"
                              onClick={(e) => {
                                runInAction(() => setActionAnchorElement(e.currentTarget));
                                viewModel.startMoveLeft(o);
                              }}
                            >
                              <RestoreIcon />
                            </MUI.IconButton>
                          ) : (
                            <MUI.IconButton
                              className={'periodAction'}
                              size="small"
                              onClick={(e) => {
                                runInAction(() => setActionAnchorElement(e.currentTarget));
                                viewModel.startMoveRight(o);
                              }}
                            >
                              <SkipIcon />
                            </MUI.IconButton>
                          )}
                          <MUI.Typography className={'periodTag'}>{o.period?.tag}</MUI.Typography>
                        </MUI.Box>
                      )
                    )}
                  </MUI.Box>
                ]);
              })
            )}

            <TeacherPlanningMoveRightPopover anchorElement={actionAnchorElement} viewModel={viewModel} />
            <TeacherPlanningMoveLeftPopover anchorElement={actionAnchorElement} viewModel={viewModel} />
          </Root>
        );
      }}
    </ObserverAutoSizer>
  );
});

const Root = styled(MUI.Box)(({ theme }) => ({
  '.cellHeader': {
    position: 'relative',
    width: 150,
    padding: theme.spacing(1),
    borderStyle: 'dotted',
    borderColor: grey[300],
    borderWidth: 0,
    borderLeftWidth: 1
  },
  '.today': {
    backgroundColor: red[50]
  },
  '.cycleDayLabel': {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    fontWeight: 500
  },
  '.defaultCursor': {
    cursor: 'default'
  },
  '.tooltip': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.getContrastText(theme.palette.common.white),
    boxShadow: theme.shadows[1],
    maxWidth: 'none'
  },
  '.tooltipPopper': {
    zIndex: theme.zIndex.modal + 999,
    opacity: 1
  },
  '.sectionRow': {
    marginLeft: 30,
    marginRight: 30,
    padding: theme.spacing(0.5),
    borderStyle: 'dotted',
    borderColor: grey[300],
    borderWidth: 0,
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },
  '.occurrenceRow': {
    marginLeft: 30,
    marginRight: 30
  },
  '.emptyCell': {
    position: 'relative',
    width: 150,
    height: 120,
    padding: theme.spacing(0.5),
    backgroundColor: grey[100],
    borderStyle: 'dotted',
    borderColor: grey[300],
    borderWidth: 0,
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },
  '.emptyTodayCell': {
    position: 'relative',
    width: 150,
    height: 120,
    padding: theme.spacing(0.5),
    backgroundColor: red[50],
    borderStyle: 'dotted',
    borderColor: grey[300],
    borderWidth: 0,
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },
  '.cell': {
    position: 'relative',
    width: 150,
    height: 120,
    padding: theme.spacing(0.5),
    borderStyle: 'dotted',
    borderColor: grey[300],
    borderWidth: 0,
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },
  '.periodAction': {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5)
  },
  '.periodTag': {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    right: theme.spacing(0.5),
    fontWeight: 500
  }
}));
