import { ObservablePresenter, PageHeaderBar } from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';
import { SchoolCalendarLayout } from './SchoolCalendarLayout';

export interface SchoolCalendarScreenProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const SchoolCalendarScreen = observer((props: SchoolCalendarScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.calendar;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createSchoolCalendar(configId), [configId]);

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, height: '100%', width: '100%', flex: 1, display: 'flex' }}
          data={viewModel.calendar}
          loadingMessage={strings.loadingMessage}
          errorMessage={strings.errorMessage}
          render={(data) => <SchoolCalendarLayout sx={{ flex: 1 }} viewModel={data} />}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
