import { ImportSessionFileManualLinkDialogViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface ImportSessionFileManualLinkProps {
  viewModel: ImportSessionFileManualLinkDialogViewModel;
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionFileManualLink = observer((props: ImportSessionFileManualLinkProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <MUI.TextField
        fullWidth
        label={strings.fileUrlLabel}
        value={viewModel.link}
        onChange={(e) => (viewModel.link = e.target.value)}
      />
    </MUI.Box>
  );
});
