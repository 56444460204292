import {
  ImportSessionFileDirectData,
  InsightsButton,
  ObservablePresenter,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { ImportSession } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportSessionFileDirectDataDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  session: ImportSession;
  fileLabel: string;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionFileDirectDataDialog = observer((props: ImportSessionFileDirectDataDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { style, className, sx, session, fileLabel, onSuccess, configId, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createImportSessionFileDirectDataLink(configId, session, fileLabel, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="md"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <MUI.DialogTitle>{strings.linkFileToConfigurationTitle}</MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <ObservablePresenter
              data={viewModel.data}
              render={(data) => <ImportSessionFileDirectData viewModel={data} />}
            />
          </MUI.DialogContent>

          <MUI.DialogActions>
            {viewModel.hasError && (
              <MUI.Typography flex={1} variant="caption" color="error" style={{ textAlign: 'end' }}>
                {strings.errorMessage}
              </MUI.Typography>
            )}
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => void viewModel.cancel()}>
              {strings.cancel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={!viewModel.canSave}
              isExecuting={viewModel.isExecuting}
              onClick={() => void viewModel.save()}
            >
              {strings.save}
            </InsightsButton>
          </MUI.DialogActions>
        </form>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
