// TODO MUI.Box

import {
  Column,
  Container,
  ErrorIndicator,
  Expanded,
  LoadingObservablePresenter,
  ManageBacAccountSettings,
  Row,
  Scroll,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Button, Dialog, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ManageBacAccountSettingsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  externalAccountId: string;
}

export const ManageBacAccountSettingsDialog = observer((props: ManageBacAccountSettingsDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, configId, externalAccountId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createManageBacAccountSettings(configId, externalAccountId, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <Dialog sx={sx} open={true} fullWidth maxWidth="md">
        <DialogTitle>{strings.manageBacSettings}</DialogTitle>
        <DialogContent>
          <LoadingObservablePresenter
            data={viewModel.settings}
            loadingMessage={strings.loadingManageBacSettingsMessage}
            render={(data) => (
              <Scroll direction="y">
                <Container sx={{ p: 0.5 }}>
                  <Column>
                    <Expanded>
                      <ManageBacAccountSettings viewModel={data} />
                    </Expanded>
                  </Column>
                </Container>
              </Scroll>
            )}
            renderError={() => (
              <Column>
                <ErrorIndicator size="normal" message={strings.loadingError} />
                <Row horizontalContentAlignment="right">
                  <Button variant="contained" onClick={() => onCancel!()}>
                    {strings.cancelButton}
                  </Button>
                </Row>
              </Column>
            )}
          />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
});
