import { InsightsButton, OnboardingProcessEdition, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { OnboardingProcess } from '@shared/models/onboarding/interfaces';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface OnboardingProcessEditionDialogProps extends DialogResult<OnboardingProcess> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  process: OnboardingProcess;
}

export const OnboardingProcessEditionDialog = observer((props: OnboardingProcessEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx, process, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createOnboardingProcessEditionDialog(process, onSuccess!, onCancel!)
  );

  const isCustomized = viewModel.editableProcess.isCustomized;

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        onClose={() => viewModel.cancel()}
      >
        <MUI.DialogTitle>{strings.editProcessTitle}</MUI.DialogTitle>
        <MUI.DialogContent>
          <OnboardingProcessEdition
            sx={{ mb: 1 }}
            editableProcess={viewModel.editableProcess}
            onFileUpload={(d, f) => viewModel.uploadFile(d, f)}
          />
          {isCustomized && (
            <MUI.Box display="flex" flexDirection="row-reverse" sx={{ mt: 1 }}>
              <MUI.Typography variant="subtitle2" color="error">
                {strings.customizedProcessNotice}
              </MUI.Typography>
            </MUI.Box>
          )}
        </MUI.DialogContent>
        <MUI.DialogActions>
          <InsightsButton onClick={() => viewModel.cancel()}>{strings.cancelLabel}</InsightsButton>
          <InsightsButton
            isDefault={isCustomized}
            isDisabled={!viewModel.canSave}
            onClick={() => void viewModel.save(false)}
          >
            {isCustomized ? strings.saveForThisSchoolButton : strings.saveOnlyForThisSchoolButton}
          </InsightsButton>
          <InsightsButton
            isDefault={!isCustomized}
            isDisabled={!viewModel.canSave || isCustomized}
            onClick={() => void viewModel.save(true)}
          >
            {strings.saveForAllButton}
          </InsightsButton>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
