import {
  AuthorizationRoleCondition,
  ErrorIndicator,
  InformationText,
  LoadingIndicator,
  ObservablePresenter,
  RequiresFeatureCondition,
  SchoolDayHeader,
  SchoolWeekHeader,
  SectionName,
  WeekPagingNavigation
} from '@insights/components';
import {
  WorkloadManagerByGradeInfo,
  WorkloadManagerByGradePageInfo,
  WorkloadManagerByGradeViewModel
} from '@insights/viewmodels';
import { mdiCalendarEdit } from '@mdi/js';
import Icon from '@mdi/react';
import InfoIcon from '@mui/icons-material/Info';
import * as MUI from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { TaskIcon } from '@shared/components/contents';
import { DateUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { SchoolDay } from '@shared/models/calendar';
import { AdminAuthorizationRoles, Color, ImportantContentIcons } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
  MinLayoutWidth,
  MinTinyChartHeight,
  WorkloadManagerStudentAtThresholdColor,
  WorkloadManagerStudentAtThresholdHoveredColor,
  WorkloadManagerStudentOverThresholdColor,
  WorkloadManagerStudentOverThresholdHoveredColor
} from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface WorkloadManagerByGradeProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: WorkloadManagerByGradeViewModel;
  displayWeekNavigation?: boolean;
}

export const WorkloadManagerByGrade = observer((props: WorkloadManagerByGradeProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, style, viewModel, displayWeekNavigation = true } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;
  const theme = useTheme();

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <RequiresFeatureCondition
        requiredFeature="workload-manager-widget"
        featureNotAvailableContent={{
          fill: true,
          padding: 2,
          title: strings.workload,
          titleVariant: 'h6'
        }}
      >
        <MUI.Box sx={{ minHeight: MinTinyChartHeight, minWidth: MinLayoutWidth }} display="flex" flexDirection="row">
          <ObservablePresenter
            sx={{ flex: 1 }}
            data={viewModel.data}
            indicatorsSize="normal"
            loadingMessage={strings.loadingDataMessage}
            errorMessage={strings.loadingDataErrorMessage}
            render={(data) => renderShell(data, viewModel, localizationService, theme, displayWeekNavigation)}
          />
        </MUI.Box>
      </RequiresFeatureCondition>
    </MUI.Box>
  );
});

function renderShell(
  workloadData: WorkloadManagerByGradeInfo,
  viewModel: WorkloadManagerByGradeViewModel,
  localizationService: LocalizationService,
  theme: Theme,
  displayWeekNavigation: boolean
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <MUI.Box display="flex" flexDirection="column">
      <MUI.Box marginLeft={3} marginBottom={2} display="flex" flexDirection="row" alignItems="center">
        <MUI.Typography variant="h6">{strings.workload}</MUI.Typography>

        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
          <MUI.Tooltip title={strings.editAssessmentPlanningDatesButtonTooltip}>
            <MUI.IconButton onClick={() => void viewModel.editAssessmentPlanningDates()}>
              <Icon path={mdiCalendarEdit} size={1} color={theme.palette.text.secondary} />
            </MUI.IconButton>
          </MUI.Tooltip>
        </AuthorizationRoleCondition>

        <MUI.Box flex={1} />

        <MUI.Typography variant="body1">{strings.showOnlyExams}</MUI.Typography>

        <MUI.Switch checked={viewModel.examOnly} onChange={(_, checked) => (viewModel.examOnly = checked)} />

        <MUI.Tooltip title={renderFilterTooltip(localizationService)}>
          <InfoIcon sx={{ color: blue[700] }} />
        </MUI.Tooltip>
      </MUI.Box>

      <MUI.Box flex={1}>
        {viewModel.pageData.case({
          pending: () => renderEmptyTable(workloadData, viewModel, localizationService, theme),
          rejected: () => <ErrorIndicator message={strings.loadingDataErrorMessage} />,
          fulfilled: (data) => renderTable(workloadData, data, viewModel, localizationService, theme)
        })}
      </MUI.Box>

      <MUI.Box minHeight={30} mt={2} ml={3} display="flex" flexDirection="row" alignItems="center">
        {/* Legend */}
        <MUI.Tooltip
          slotProps={{
            popper: { sx: { opacity: 1 } },
            tooltip: {
              sx: {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.getContrastText(theme.palette.common.white),
                boxShadow: theme.shadows[1],
                maxWidth: 'none'
              }
            }
          }}
          title={renderLegendTooltip(localizationService)}
        >
          <span>
            <InformationText text={strings.showLegend} />
          </span>
        </MUI.Tooltip>

        <MUI.Box flex={1} />

        {/* Show a loading indicator when changing the current page */}
        {viewModel.pageData.state === 'pending' && <LoadingIndicator size="tiny" />}

        {displayWeekNavigation && <WeekPagingNavigation pagination={viewModel.pagination} />}
      </MUI.Box>
    </MUI.Box>
  );
}

function renderEmptyTable(
  workloadData: WorkloadManagerByGradeInfo,
  viewModel: WorkloadManagerByGradeViewModel,
  localizationService: LocalizationService,
  theme: Theme
) {
  const pageRange = viewModel.pagination?.currentPage;
  if (pageRange != null) {
    return renderTable(
      workloadData,
      {
        schoolDays: workloadData.schoolDays.filter((sd) => sd.day.isWithin(pageRange.startDay, pageRange.endDay)),
        dayInfos: [],
        weekInfos: []
      },
      viewModel,
      localizationService,
      theme
    );
  }

  return null;
}

function renderTable(
  workloadData: WorkloadManagerByGradeInfo,
  pageData: WorkloadManagerByGradePageInfo,
  viewModel: WorkloadManagerByGradeViewModel,
  localizationService: LocalizationService,
  theme: Theme
) {
  return (
    <MUI.Table sx={{ tableLayout: 'fixed' }}>
      {renderTableHeader(workloadData, pageData, localizationService)}
      {renderTableBody(workloadData, pageData, viewModel, localizationService, theme)}
    </MUI.Table>
  );
}

function renderTableHeader(
  workloadData: WorkloadManagerByGradeInfo,
  pageData: WorkloadManagerByGradePageInfo,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <MUI.TableHead>
      <MUI.TableRow sx={{ verticalAlign: 'center', height: '50px' }}>
        <MUI.TableCell sx={{ width: '240px', cursor: 'default' }}>
          <MUI.Typography variant="subtitle2" color="textSecondary" fontWeight="500" lineHeight="normal">
            {strings.grade}
          </MUI.Typography>
        </MUI.TableCell>

        {pageData.schoolDays
          .filter((schoolDay) => !DateUtils.isWeekend(schoolDay.day))
          .map((schoolDay) => (
            <MUI.TableCell
              key={schoolDay.day.asDateString}
              sx={{ textAlign: 'center', pr: 2, width: 'calc(80%/6)', cursor: 'default' }}
            >
              <SchoolDayHeader schoolDay={schoolDay} displayType="column-header" />
            </MUI.TableCell>
          ))}

        <MUI.TableCell
          sx={{
            borderLeft: `1px solid ${grey[300]}`,
            textAlign: 'center',
            pr: 2,
            width: 'calc(80%/6)',
            cursor: 'default'
          }}
        >
          <SchoolWeekHeader schoolDay={pageData.schoolDays[0]} displayType="column-header" />
        </MUI.TableCell>
      </MUI.TableRow>
    </MUI.TableHead>
  );
}

function renderTableBody(
  workloadData: WorkloadManagerByGradeInfo,
  pageData: WorkloadManagerByGradePageInfo,
  viewModel: WorkloadManagerByGradeViewModel,
  localizationService: LocalizationService,
  theme: Theme
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;
  const materialTableStrings = localizationService.localizedStrings.insights.materialTable;

  return (
    <MUI.TableBody>
      {workloadData.gradeLevels.length === 0 && (
        <MUI.TableRow>
          <MUI.TableCell colSpan={99} align="center">
            <MUI.Typography variant="body2">{materialTableStrings.body?.emptyDataSourceMessage ?? ''}</MUI.Typography>
          </MUI.TableCell>
        </MUI.TableRow>
      )}
      {workloadData.gradeLevels.length > 0 &&
        workloadData.gradeLevels.map((gradeLevelInfo) => {
          const weekInfo = pageData.weekInfos.find(
            (i) => i.schoolDay == null && i.gradeLevel === gradeLevelInfo.gradeLevel
          );

          const studentCountAtThresholdForWeek = weekInfo?.studentIdsAtThreshold.length ?? 0;
          const studentCountOverThresholdForWeek = weekInfo?.studentIdsOverThreshold.length ?? 0;

          const weekType =
            studentCountOverThresholdForWeek > 0 ? 'error' : studentCountAtThresholdForWeek > 0 ? 'warning' : 'normal';

          const weekCellColors =
            weekType === 'error'
              ? {
                  hover: WorkloadManagerStudentOverThresholdHoveredColor,
                  normal: WorkloadManagerStudentOverThresholdColor
                }
              : weekType === 'warning'
                ? {
                    hover: WorkloadManagerStudentAtThresholdHoveredColor,
                    normal: WorkloadManagerStudentAtThresholdColor
                  }
                : undefined;

          return (
            <MUI.TableRow key={gradeLevelInfo.gradeLevel} className={'tableRow'}>
              <MUI.TableCell sx={{ width: '240px', cursor: 'default' }}>
                <SectionName
                  color="darker-bmgray"
                  title={gradeLevelInfo.gradeLevel}
                  subInformation1={strings.localizedNumberOfStudents(gradeLevelInfo.students.size)}
                  thresholds={{
                    dailyThreshold: gradeLevelInfo.dailyThreshold,
                    weeklyThreshold: gradeLevelInfo.weeklyThreshold
                  }}
                />
              </MUI.TableCell>

              {pageData.schoolDays
                .filter((schoolDay) => !DateUtils.isWeekend(schoolDay.day))
                .map((schoolDay) => {
                  const dayInfo = pageData.dayInfos.find(
                    (d) => d.schoolDay!.day.isSame(schoolDay.day) && d.gradeLevel === gradeLevelInfo.gradeLevel
                  );
                  const studentCountAtThreshold = dayInfo?.studentIdsAtThreshold.length ?? 0;
                  const studentCountOverThreshold = dayInfo?.studentIdsOverThreshold.length ?? 0;

                  const dayType =
                    studentCountOverThreshold > 0 ? 'error' : studentCountAtThreshold > 0 ? 'warning' : 'normal';

                  const dayCellColors =
                    dayType === 'error'
                      ? {
                          hover: WorkloadManagerStudentOverThresholdHoveredColor,
                          normal: WorkloadManagerStudentOverThresholdColor
                        }
                      : dayType === 'warning'
                        ? {
                            hover: WorkloadManagerStudentAtThresholdHoveredColor,
                            normal: WorkloadManagerStudentAtThresholdColor
                          }
                        : undefined;

                  return (
                    <MUI.Tooltip
                      key={`${gradeLevelInfo.gradeLevel} - ${schoolDay.day.asDateString}`}
                      classes={{
                        tooltip: 'tooltip',
                        popper: 'tooltipPopper'
                      }}
                      title={renderValueTooltip(
                        studentCountAtThreshold,
                        studentCountOverThreshold,
                        'darker-bmgray',
                        localizationService
                      )}
                    >
                      <MUI.TableCell
                        sx={{
                          textAlign: 'center',
                          pr: 2,
                          width: 'calc(80%/6)',
                          backgroundColor: dayCellColors?.normal,
                          color:
                            dayCellColors != null ? theme.palette.getContrastText(dayCellColors.normal) : undefined,
                          cursor: dayCellColors != null ? 'pointer' : 'default',
                          '&:hover': {
                            backgroundColor: dayCellColors?.hover,
                            color:
                              dayCellColors != null ? theme.palette.getContrastText(dayCellColors.hover) : undefined
                          }
                        }}
                        onClick={() =>
                          dayInfo &&
                          (studentCountAtThreshold > 0 || studentCountOverThreshold > 0) &&
                          void showDetail(
                            viewModel,
                            gradeLevelInfo.gradeLevel,
                            gradeLevelInfo.students.size,
                            dayInfo.schoolDay!,
                            dayInfo.schoolDay!,
                            dayInfo.studentIdsAtThreshold,
                            dayInfo.studentIdsOverThreshold
                          )
                        }
                      >
                        {(studentCountOverThreshold > 0 || studentCountAtThreshold > 0) && (
                          <MUI.Typography variant="body1">
                            {studentCountOverThreshold || studentCountAtThreshold || 0}
                          </MUI.Typography>
                        )}
                      </MUI.TableCell>
                    </MUI.Tooltip>
                  );
                })}

              <MUI.Tooltip
                classes={{
                  tooltip: 'tooltip',
                  popper: 'tooltipPopper'
                }}
                title={renderValueTooltip(
                  studentCountAtThresholdForWeek,
                  studentCountOverThresholdForWeek,
                  'darker-bmgray',
                  localizationService
                )}
              >
                <MUI.TableCell
                  sx={{
                    borderLeft: `1px solid ${grey[300]}`,
                    textAlign: 'center',
                    pr: 2,
                    width: 'calc(80%/6)',
                    fontWeight: 400,
                    backgroundColor: weekCellColors?.normal,
                    color: weekCellColors != null ? theme.palette.getContrastText(weekCellColors.normal) : undefined,
                    cursor: weekCellColors != null ? 'pointer' : 'default',
                    '&:hover': {
                      backgroundColor: weekCellColors?.hover,
                      color: weekCellColors != null ? theme.palette.getContrastText(weekCellColors.hover) : undefined
                    }
                  }}
                  onClick={() =>
                    weekInfo &&
                    (studentCountAtThresholdForWeek > 0 || studentCountOverThresholdForWeek > 0) &&
                    void showDetail(
                      viewModel,
                      gradeLevelInfo.gradeLevel,
                      gradeLevelInfo.students.size,
                      pageData.schoolDays[0],
                      pageData.schoolDays[pageData.schoolDays.length - 1],
                      weekInfo.studentIdsAtThreshold,
                      weekInfo.studentIdsOverThreshold
                    )
                  }
                >
                  {(studentCountOverThresholdForWeek > 0 || studentCountAtThresholdForWeek > 0) && (
                    <MUI.Typography variant="body1">
                      {studentCountOverThresholdForWeek || studentCountAtThresholdForWeek || 0}
                    </MUI.Typography>
                  )}
                </MUI.TableCell>
              </MUI.Tooltip>
            </MUI.TableRow>
          );
        })}
    </MUI.TableBody>
  );
}

function renderValueTooltip(
  studentCountAtThreshold: number,
  studentCountOverThreshold: number,
  sectionColor: Color,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  if (studentCountAtThreshold === 0 && studentCountOverThreshold === 0) {
    return '';
  }

  return (
    <MUI.Box display="flex" flexDirection="row">
      <MUI.Box mr="6px" borderLeft={`3px solid ${SectionColors.get(sectionColor)}`} />

      <MUI.Box display="flex" flexDirection="column">
        <MUI.Box display="flex" flexDirection="row">
          <MUI.Box marginRight={3}>
            <MUI.Typography>{strings.overloadedStudents}</MUI.Typography>
          </MUI.Box>
          <MUI.Box flex={1} />
          <MUI.Typography align="right" fontWeight="400">
            {studentCountOverThreshold}
          </MUI.Typography>
        </MUI.Box>

        <MUI.Box display="flex" flexDirection="row">
          <MUI.Box marginRight={3}>
            <MUI.Typography>{strings.studentsAtThreshold}</MUI.Typography>
          </MUI.Box>
          <MUI.Box flex={1} />
          <MUI.Typography align="right" fontWeight="400">
            {studentCountAtThreshold}
          </MUI.Typography>
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
}

function renderFilterTooltip(localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <MUI.Box display="flex" flexDirection="column">
      <MUI.Box marginBottom={1}>
        <span style={{ color: 'white' }}>{strings.filterTooltipContent}</span>
      </MUI.Box>

      <MUI.Box display="flex" flexDirection="row" flexWrap="wrap">
        {ImportantContentIcons.filter((icon) => icon !== 'exam').map((icon) => (
          <TaskIcon key={icon} icon={icon} forcedIconColor="white" sx={{ mx: 0.5 }} />
        ))}
      </MUI.Box>
    </MUI.Box>
  );
}

function renderLegendTooltip(localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <MUI.Box display="flex" flexDirection="column">
      <MUI.Box display="flex" flexDirection="row" margin={0.5} alignItems="center">
        <MUI.Box sx={{ backgroundColor: WorkloadManagerStudentOverThresholdColor, width: 60, height: 32 }} />

        <MUI.Box marginLeft={1}>
          <MUI.Typography noWrap={true} color="textSecondary">
            {strings.numberOfOverloadedStudents}
          </MUI.Typography>
        </MUI.Box>
      </MUI.Box>

      <MUI.Box display="flex" flexDirection="row" margin={0.5} alignItems="center">
        <MUI.Box sx={{ backgroundColor: WorkloadManagerStudentAtThresholdColor, width: 60, height: 32 }} />

        <MUI.Box marginLeft={1}>
          <MUI.Typography noWrap={true} color="textSecondary">
            {strings.numberOfStudentsAtThreshold}
          </MUI.Typography>
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>
  );
}

async function showDetail(
  viewModel: WorkloadManagerByGradeViewModel,
  gradeLevel: string,
  gradeLevelStudentCount: number,
  fromDay: SchoolDay,
  toDay: SchoolDay,
  studentIdsAtThreshold: string[],
  studentIdsOverThreshold: string[]
) {
  await viewModel.showDetail(
    gradeLevel,
    gradeLevelStudentCount,
    fromDay,
    toDay,
    studentIdsAtThreshold,
    studentIdsOverThreshold
  );
}
