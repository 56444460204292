import { TransformationSchemaViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface TransformationSchemaProps {
  sx?: SxProps;
  viewModel: TransformationSchemaViewModel;
  className?: string;
  style?: React.CSSProperties;
}

export const TransformationSchema = observer((props: TransformationSchemaProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <MUI.FormControl fullWidth>
        <MUI.InputLabel htmlFor="target-schema-select">{strings.targetSchemaLabel}</MUI.InputLabel>
        <MUI.Select
          value={viewModel.targetRootSchema}
          inputProps={{ id: 'target-schema-select' }}
          onChange={(e) => (viewModel.targetRootSchema = e.target.value)}
          renderValue={(v) => viewModel.availableSchemas.find((s) => s.name == v)?.description ?? strings.noSchema}
        >
          <MUI.MenuItem key={`target-schema-none`} value="">
            <MUI.Typography>{strings.noSchema}</MUI.Typography>
          </MUI.MenuItem>
          {viewModel.availableSchemas.map((schema) => (
            <MUI.MenuItem key={`target-schema-${schema.name}`} value={schema.name}>
              <MUI.Typography>{schema.description}</MUI.Typography>
            </MUI.MenuItem>
          ))}
        </MUI.Select>
      </MUI.FormControl>

      {viewModel.availableSuffixes.map((group, i) => (
        <MUI.FormControl key={`suffix-group-${i}`} sx={{ mt: 1 }} fullWidth>
          <MUI.FormLabel>{group.localizedDescription}</MUI.FormLabel>
          {group.suffixes.length > 1 ? (
            <MUI.RadioGroup row>
              {group.suffixes.map((suffix) => (
                <MUI.FormControlLabel
                  key={`suffix-item-${suffix.name}`}
                  label={suffix.localizedDescription}
                  control={
                    <MUI.Radio
                      checked={viewModel.targetSuffixes.includes(suffix.name)}
                      onChange={() => viewModel.addSuffix(suffix.name)}
                    />
                  }
                />
              ))}
            </MUI.RadioGroup>
          ) : (
            <>
              {group.suffixes.map((suffix) => (
                <MUI.FormControlLabel
                  key={`suffix-item-${suffix.name}`}
                  label={suffix.localizedDescription}
                  control={
                    <MUI.Checkbox
                      checked={viewModel.targetSuffixes.includes(suffix.name)}
                      onChange={() => viewModel.toggleSuffix(suffix.name)}
                    />
                  }
                />
              ))}
            </>
          )}
        </MUI.FormControl>
      ))}
      {viewModel.availableOptions.length > 0 && (
        <MUI.Box mt={1}>
          <MUI.FormControl fullWidth>
            <MUI.InputLabel htmlFor="options-select">{strings.suggestedImportOptions}</MUI.InputLabel>
            <MUI.Select
              multiple
              value={viewModel.selectedOptionNames}
              inputProps={{ id: 'options-select', placeholder: strings.noImportOption }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                }
              }}
              onChange={(e) => (viewModel.selectedOptionNames = e.target.value as unknown as string[])}
              renderValue={() => (
                <MUI.Typography variant="body2">{viewModel.selectedOptionNames.join(', ')}</MUI.Typography>
              )}
            >
              {viewModel.availableOptions.map((o) => (
                <MUI.MenuItem key={`option-${o.name}`} value={o.name}>
                  <MUI.Checkbox size="small" checked={viewModel.selectedOptionNames.includes(o.name)} />
                  <MUI.Typography variant="body2">
                    {o.name} : {o.localizedDescription}
                  </MUI.Typography>
                </MUI.MenuItem>
              ))}
            </MUI.Select>
          </MUI.FormControl>
        </MUI.Box>
      )}
    </MUI.Box>
  );
});
