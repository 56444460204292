import { css } from '@emotion/css';
import { WorkloadManagerInfo } from '@insights/viewmodels';
import InfoIcon from '@mui/icons-material/Info';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SectionUtils } from '@shared/components/utils';
import { format } from 'date-fns';
import _ from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { WorkloadManagerStudentAtThresholdColor, WorkloadManagerStudentOverThresholdColor } from '../Constants';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { SectionName } from './SectionName';
import { TaskInformation } from './TaskInformation';

export interface StudentTasksThresholdTableProps {
  sx?: SxProps;
  conflictsInfo: WorkloadManagerInfo;
  className?: string;
  header: string;
  style?: React.CSSProperties;
  studentsAreOverloaded: boolean;
}

export const StudentTasksThresholdTable = observer((props: StudentTasksThresholdTableProps) => {
  const { localizationService, navigationService } = useInsightsServices();
  const { sx, className, conflictsInfo, header, studentsAreOverloaded, style } = props;
  const { schoolYearConfiguration, sectionsById, conflicts } = conflictsInfo;
  const theme = useTheme();

  const headerClassName = css(
    studentsAreOverloaded
      ? {
          backgroundColor: WorkloadManagerStudentOverThresholdColor,
          color: theme.palette.getContrastText(WorkloadManagerStudentOverThresholdColor)
        }
      : {
          backgroundColor: WorkloadManagerStudentAtThresholdColor,
          color: theme.palette.getContrastText(WorkloadManagerStudentAtThresholdColor)
        }
  );

  const strings = localizationService.localizedStrings.insights.components.tasks;

  // Avoid clipping just one or two items. Take 25 if count >= 30.
  const count = conflicts.length >= 30 ? 25 : conflicts.length;
  const visibleConflicts = _.chain(conflicts)
    .orderBy([(c) => c.students.length], ['desc'])
    .take(count)
    .value();
  const clippedCount = conflicts.length - visibleConflicts.length;

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="column">
      {/* Header */}
      <MUI.Box className={headerClassName} padding={1} marginBottom={1}>
        {header}
      </MUI.Box>

      {/* Tasks */}
      <MUI.Box display="flex" flexDirection="column">
        {visibleConflicts.map((conflict, index) => (
          <MUI.Box
            key={conflict.conflictId}
            flex={1}
            display="flex"
            flexDirection="column"
            marginTop={index > 0 ? 3 : 0}
          >
            {/* Number of students */}
            <MUI.Box
              bgcolor={grey[100]}
              paddingLeft={1}
              paddingRight={1}
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={() => void navigationService.navigateToWorkloadManagerStudentListDetail(conflict.students)}
            >
              <MUI.Typography variant="subtitle1" noWrap unselectable="on">
                {strings.localizedNumberOfStudents(conflict.students.length)}
              </MUI.Typography>

              <MUI.Box flex={1} />

              <MUI.Tooltip title={strings.viewDetails}>
                <MUI.IconButton size="small" edge="end">
                  <InfoIcon />
                </MUI.IconButton>
              </MUI.Tooltip>
            </MUI.Box>

            <MUI.Box paddingLeft={1}>
              {conflict.tasks.map((taskInfo) => (
                <MUI.Box
                  key={taskInfo.task.id}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  marginTop={1}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: grey[100]
                    }
                  }}
                  onClick={() =>
                    void navigationService.navigateToWorkloadManagerTaskDetail(
                      taskInfo,
                      conflictsInfo.schoolYearConfiguration,
                      conflictsInfo.sectionsById
                    )
                  }
                >
                  {/* Section */}
                  <MUI.Box flex={1} minWidth="250px" paddingRight={2}>
                    <SectionName
                      title={taskInfo.section.title}
                      color={taskInfo.section.color}
                      subInformation1={taskInfo.section.sectionNumber}
                      subInformation2={SectionUtils.getSectionShortTeacherInfo(
                        taskInfo.section,
                        taskInfo.teachers,
                        strings.noTeacher
                      )}
                    />
                  </MUI.Box>

                  {/* Dates */}
                  <MUI.Box width="250px" paddingRight={2}>
                    <MUI.Box display="flex" flexDirection="column">
                      {/* Published at */}
                      <MUI.Typography variant="body2" noWrap>
                        <span>{strings.publishedOn}</span>

                        <span
                          style={{
                            marginLeft: theme.spacing(0.5),
                            marginRight: theme.spacing(0.5),
                            fontWeight: '400'
                          }}
                        >
                          {format(taskInfo.publishedAt, localizationService.localizedStrings.models.dateFormats.short)}
                        </span>

                        <span>{strings.at}</span>

                        <span
                          style={{
                            marginLeft: theme.spacing(0.5),
                            fontWeight: '400'
                          }}
                        >
                          {format(taskInfo.publishedAt, localizationService.localizedStrings.models.dateFormats.time)}
                        </span>
                      </MUI.Typography>

                      {/* Due day */}

                      <MUI.Typography variant="body2" color="textSecondary" noWrap fontStyle="italic">
                        <span>{strings.dueOn}</span>

                        <span
                          style={{
                            marginLeft: theme.spacing(0.5),
                            fontWeight: '400'
                          }}
                        >
                          {format(
                            taskInfo.task.dueDay.asDate,
                            localizationService.localizedStrings.models.dateFormats.short
                          )}
                        </span>

                        {taskInfo.task.duePeriodTag && (
                          <React.Fragment>
                            <span style={{ marginLeft: theme.spacing(0.5) }}>{strings.atPeriod}</span>
                            <span style={{ marginLeft: theme.spacing(0.5), fontWeight: '400' }}>
                              {taskInfo.task.duePeriodTag}
                            </span>
                          </React.Fragment>
                        )}
                      </MUI.Typography>
                    </MUI.Box>
                  </MUI.Box>

                  {/* Task */}
                  <MUI.Box flex={2}>
                    <TaskInformation
                      task={taskInfo.task}
                      type="two-lines"
                      size="normal"
                      disableTooltip
                      schoolYearConfig={schoolYearConfiguration}
                      sectionsById={sectionsById}
                    />
                  </MUI.Box>
                </MUI.Box>
              ))}
            </MUI.Box>
          </MUI.Box>
        ))}
      </MUI.Box>
      {clippedCount > 0 && (
        <MUI.Box marginTop={2}>
          <MUI.Typography variant="subtitle1">
            {strings.localizedNumberOfClippedConflictGroups(clippedCount)}
          </MUI.Typography>
        </MUI.Box>
      )}
    </MUI.Box>
  );
});
