import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { Incident } from '@shared/models/import';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { ImportDataIncidentIcon, IncidentColor, IncidentSize } from './ImportDataIncidentIcon';

export interface ImportDataIncidentProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  incident: Incident;
  color?: IncidentColor;
  colorMode?: 'no-incident-color' | 'icon-incident-color' | 'full-incident-color';
  size?: IncidentSize;
}

export const ImportDataIncident = observer((props: ImportDataIncidentProps) => {
  const { incident, className, style, sx, color = 'inherit', size = 'normal', colorMode = 'no-incident-color' } = props;

  const codeVariant: MUI.TypographyVariant = useMemo(() => {
    switch (size) {
      case 'normal':
        return 'subtitle2';
      case 'small':
        return 'caption';
      default:
        return 'subtitle2';
    }
  }, [size]);

  const messageVariant: MUI.TypographyVariant = useMemo(() => {
    switch (size) {
      case 'normal':
        return 'body1';
      case 'small':
        return 'caption';
      default:
        return 'body1';
    }
  }, [size]);

  const iconColor = colorMode !== 'no-incident-color' ? color : undefined;
  const textColor = colorMode === 'full-incident-color' ? color : undefined;

  return (
    <MUI.Stack sx={sx} className={className} style={style} direction="row" spacing={1} alignItems="center">
      <ImportDataIncidentIcon severity={incident.severity} color={iconColor} size={size} />
      <MUI.Typography variant={codeVariant} color={textColor}>
        {incident.code}
      </MUI.Typography>
      <MUI.Typography variant={messageVariant} color={textColor}>
        {incident.message}
      </MUI.Typography>
    </MUI.Stack>
  );
});
