import { AlertService } from '@insights/services';
import { EditableSchoolYearConfiguration, SchoolYearConfigurationModel } from '@shared/models/config';
import { PremiumFeature } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { AppEditViewModelBase, EditViewModelBase } from '../../EditViewModelBase';

export type EditFeaturesDialogViewModel = EditViewModelBase<PremiumFeature>;

export class AppEditFeaturesDialogViewModel
  extends AppEditViewModelBase<PremiumFeature>
  implements EditFeaturesDialogViewModel
{
  private readonly _editableSchoolYearConfiguration: EditableSchoolYearConfiguration;

  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _schoolYearConfiguration: SchoolYearConfigurationModel,
    alertService: AlertService,
    localizationService: LocalizationService,
    onSuccess: () => void,
    onCancel: () => void
  ) {
    super(alertService, localizationService, onSuccess, onCancel);
    makeObservable(this);
    this._editableSchoolYearConfiguration = new EditableSchoolYearConfiguration(_schoolYearConfiguration);
  }

  @computed
  get hasChanges(): boolean {
    return this._editableSchoolYearConfiguration.hasChanges;
  }

  getFieldValueCore(field: PremiumFeature): boolean {
    // The API returns the list of disabled features. The UI is exposing this as
    // the list of available features. Therefore, we need to reverse the logic here.
    return this._editableSchoolYearConfiguration.disabledFeatures.indexOf(field) === -1;
  }

  setFieldValueCore(field: PremiumFeature, value: boolean) {
    // The API returns the list of disabled features. The UI is exposing this as
    // the list of available features. Therefore, we need to reverse the logic here.
    if (value) {
      this._editableSchoolYearConfiguration.removeDisabledFeature(field);
    } else {
      this._editableSchoolYearConfiguration.addDisabledFeature(field);
    }
  }

  protected async saveCore(): Promise<void> {
    await this._schoolYearConfigurationStore.saveConfig(this._editableSchoolYearConfiguration);
  }

  protected validateFields() {
    // Nothing to validate
  }
}
