import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR';
import { PeriodDisplay } from '@insights/enums';
import {
  createChartSubTitle as utilsCreateChartSubTitle,
  createChartTitle as utilsCreateChartTitle
} from '@insights/utils';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { DataPoint } from '@shared/models/metrics';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { v4 } from 'uuid';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

type Chart = am4charts.XYChart;

interface ChartElements {
  chart: Chart;
  title: am4core.Label;
  subTitle: am4core.Label;
  anyUsersSeries: am4charts.LineSeries;
  interactedUsersSeries: am4charts.LineSeries;
  dateAxis: am4charts.DateAxis;
  valueAxis: am4charts.ValueAxis;
}

export interface ActiveUsersProps {
  sx?: SxProps;
  className?: string;
  caption: string;
  subcaption?: string;
  anyUsersData: DataPoint[];
  anyUsersTooltipSuffix: string;
  anyUsersColor: string;
  anyUsersLegendTitle: string;
  interactedUsersData: DataPoint[];
  interactedUsersTooltipSuffix: string;
  interactedUsersColor: string;
  interactedUsersLegendTitle: string;
  periodDisplay: PeriodDisplay;
  maximumValue: number;
}

export const ActiveUsers = observer((props: ActiveUsersProps) => {
  const { localizationService } = useInsightsServices();
  const {
    sx,
    className,
    anyUsersData,
    interactedUsersData,
    caption,
    subcaption,
    periodDisplay,
    maximumValue,
    anyUsersTooltipSuffix,
    interactedUsersTooltipSuffix,
    anyUsersColor,
    anyUsersLegendTitle,
    interactedUsersColor,
    interactedUsersLegendTitle
  } = props;
  const theme = useTheme();

  const chartElements = useRef<ChartElements | null>(null);

  const chartId = `active-users-chart-container-${v4()}`;

  function createChart() {
    const chart = am4core.create(chartId, am4charts.XYChart);
    chart.dateFormatter.firstDayOfWeek = 0; // Sunday
    chart.dateFormatter.utc = true;

    const { chartTitle, chartSubTitle } = createChartTitle(chart, theme);
    const { chartDateAxis, chartValueAxis } = createChartAxis(
      chart,
      theme,
      (dataItem, dateAxis: am4charts.DateAxis) => {
        if (
          periodDisplay === PeriodDisplay.Daily &&
          dateAxis.gridInterval.timeUnit === 'day' &&
          dateAxis.gridInterval.count === 1
        ) {
          dataItem.axisFill.visible = dataItem.date.getUTCDay() === 0 || dataItem.date.getUTCDay() === 6;
        } else {
          dataItem.axisFill.visible = false;
        }
      },
      maximumValue
    );

    const chartAnyUsersSeries = createAnyUsersSeries(chart, anyUsersColor, anyUsersLegendTitle);
    const chartInteractedUserSeries = createInteractedUsersSeries(
      chart,
      interactedUsersColor,
      interactedUsersLegendTitle
    );
    createChartCursor(chart, chartDateAxis, chartValueAxis);
    createLegend(chart, theme);

    chartElements.current = {
      chart,
      title: chartTitle,
      subTitle: chartSubTitle,
      dateAxis: chartDateAxis,
      valueAxis: chartValueAxis,
      interactedUsersSeries: chartInteractedUserSeries,
      anyUsersSeries: chartAnyUsersSeries
    };
  }

  function setChartData() {
    if (chartElements.current == null) {
      return;
    }

    chartElements.current.anyUsersSeries.data = anyUsersData;
    chartElements.current.interactedUsersSeries.data = interactedUsersData;
  }

  function setChartTitle() {
    if (chartElements.current == null) {
      return;
    }

    const { title, subTitle } = chartElements.current;
    const marginBottom = Number(theme.spacing(3).replace('px', ''));

    title.text = caption;
    title.marginBottom = marginBottom;

    if (subcaption != null) {
      title.marginBottom = 0;

      subTitle.text = subcaption;
      subTitle.marginBottom = marginBottom;
    }
  }

  function setChartTooltip() {
    if (chartElements.current == null) {
      return;
    }

    const { anyUsersSeries, interactedUsersSeries } = chartElements.current;

    anyUsersSeries.tooltip!.label.fontSize = theme.typography.body1.fontSize;
    anyUsersSeries.tooltip!.label.fontWeight = '300';
    anyUsersSeries.tooltipText = `[font-weight: 500]{valueY.value}[/] ${anyUsersTooltipSuffix}`;

    interactedUsersSeries.tooltip!.label.fontSize = theme.typography.body1.fontSize;
    interactedUsersSeries.tooltip!.label.fontWeight = '300';
    interactedUsersSeries.tooltipText = `[font-weight: 500]{valueY.value}[/] ${interactedUsersTooltipSuffix}`;
  }

  function setChartAxisTooltip() {
    if (chartElements.current == null) {
      return;
    }

    const { dateAxis } = chartElements.current;

    switch (periodDisplay) {
      case PeriodDisplay.Weekly:
        dateAxis.tooltipDateFormat = "'Week starting on: '[font-weight: 500]EEEE, MMM d[/]";
        return;

      case PeriodDisplay.Daily:
        dateAxis.tooltipDateFormat = 'EEEE, MMM d';
        return;

      case PeriodDisplay.Monthly:
      default:
        dateAxis.tooltipDateFormat = 'MMM yyyy';
        return;
    }
  }

  function setChartInterval() {
    if (chartElements.current == null) {
      return;
    }

    const { dateAxis } = chartElements.current;

    switch (periodDisplay) {
      case PeriodDisplay.Monthly:
        dateAxis.baseInterval = { timeUnit: 'month', count: 1 };
        break;

      case PeriodDisplay.Weekly:
        dateAxis.baseInterval = { timeUnit: 'week', count: 1 };
        break;

      default:
        dateAxis.baseInterval = { timeUnit: 'day', count: 1 };
    }
  }

  useLayoutEffect(() => {
    createChart();

    setChartTitle();
    setChartTooltip();
    setChartAxisTooltip();
    setChartInterval();
    setChartData();
  }, []);

  useEffect(() => {
    const currentLocaleChangeDisposer = autorun(() => {
      const chart = chartElements.current?.chart;
      if (chart != null) {
        chart.language.locale = localizationService.currentLocale === 'fr' ? am4lang_fr_FR : am4lang_en_US;
      }
    });

    return () => {
      currentLocaleChangeDisposer();
      chartElements.current?.chart.dispose();
    };
  }, []);

  useEffect(() => {
    setChartTitle();
    setChartTooltip();
    setChartAxisTooltip();
    setChartInterval();
    setChartData();
  }, [caption, subcaption, periodDisplay, anyUsersColor, interactedUsersData]);

  return <Box sx={{ ...sx, height: '100%', width: '100%' }} className={className} id={chartId} />;
});

function createChartTitle(chart: Chart, theme: Theme) {
  const chartTitle = utilsCreateChartTitle(theme);
  const chartSubTitle = utilsCreateChartSubTitle(theme);

  chart.titles.push(chartSubTitle);
  chart.titles.push(chartTitle);
  return { chartTitle, chartSubTitle };
}

function createChartAxis(
  chart: Chart,
  theme: Theme,
  weekendFillRule: (dataItem: am4charts.DateAxisDataItem, dateAxis: am4charts.DateAxis) => void,
  maximumValue: number
) {
  const chartDateAxis = chart.xAxes.push(new am4charts.DateAxis());

  // This value make the labels display properly on smaller charts when
  // displaying only the number.
  chartDateAxis.renderer.minGridDistance = 25;

  chartDateAxis.renderer.gridContainer.strokeWidth = 0; // Disable the vertical grid lines
  chartDateAxis.renderer.cellStartLocation = 0.1;
  chartDateAxis.renderer.cellEndLocation = 0.9;
  chartDateAxis.renderer.grid.template.location = 0.5;
  chartDateAxis.renderer.labels.template.location = 0.5;
  chartDateAxis.renderer.ticks.template.location = 0.5;

  chartDateAxis.fontSize = theme.typography.body1.fontSize;
  chartDateAxis.fontWeight = '300';
  chartDateAxis.tooltip!.label.fontSize = theme.typography.body1.fontSize;
  chartDateAxis.tooltip!.label.fontWeight = '300';

  // This is to disable the axis animation when changing the period display
  chartDateAxis.rangeChangeDuration = 0;

  // Configure how date formatting of the dates on the axis
  chartDateAxis.dateFormats.setKey('day', 'd');
  chartDateAxis.periodChangeDateFormats.setKey('day', '[font-weight: 500]MMM[/]');
  chartDateAxis.dateFormats.setKey('week', 'd');
  chartDateAxis.periodChangeDateFormats.setKey('week', '[font-weight: 500]MMM[/]');
  chartDateAxis.dateFormats.setKey('month', 'MMM yyyy');
  chartDateAxis.periodChangeDateFormats.setKey('month', 'MMM yyyy');

  // Configure the weekend background
  chartDateAxis.renderer.axisFills.template.disabled = false;
  chartDateAxis.renderer.axisFills.template.fill = am4core.color('grey');
  chartDateAxis.renderer.axisFills.template.fillOpacity = 0.1;
  chartDateAxis.fillRule = (dataItem) => weekendFillRule(dataItem, chartDateAxis);

  const chartValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  chartValueAxis.min = 0;
  chartValueAxis.max = maximumValue;
  chartValueAxis.maxPrecision = 0;
  chartValueAxis.strictMinMax = true;
  chartValueAxis.extraMax = 0.1;
  chartValueAxis.cursorTooltipEnabled = false;

  chartValueAxis.fontSize = theme.typography.body1.fontSize;
  chartValueAxis.fontWeight = '300';
  chartValueAxis.tooltip!.label.fontSize = theme.typography.body1.fontSize;
  chartValueAxis.tooltip!.label.fontWeight = '300';
  return { chartValueAxis, chartDateAxis };
}

function createAnyUsersSeries(chart: Chart, anyUsersColor: string, anyUsersLegendTitle: string) {
  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.dateX = 'startOfPeriod';
  series.dataFields.valueY = 'value';
  series.stroke = am4core.color(anyUsersColor);
  series.strokeOpacity = 1;
  series.fill = am4core.color(anyUsersColor);
  series.fillOpacity = 1;
  series.legendSettings.labelText = anyUsersLegendTitle;
  return series;
}

function createInteractedUsersSeries(chart: Chart, interactedUsersColor: string, interactedUsersLegendTitle: string) {
  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.dateX = 'startOfPeriod';
  series.dataFields.valueY = 'value';
  series.stroke = am4core.color(interactedUsersColor);
  series.strokeOpacity = 1;
  series.fill = am4core.color(interactedUsersColor);
  series.fillOpacity = 1;
  series.legendSettings.labelText = interactedUsersLegendTitle;
  return series;
}

function createChartCursor(chart: Chart, dateAxis: am4charts.DateAxis, valueAxis: am4charts.ValueAxis) {
  const cursor = new am4charts.XYCursor();
  cursor.behavior = 'none';
  cursor.xAxis = dateAxis;
  cursor.yAxis = valueAxis;
  cursor.lineY.disabled = true;
  chart.cursor = cursor;
  return cursor;
}

function createLegend(chart: Chart, theme: Theme) {
  const legend = new am4charts.Legend();
  legend.itemContainers.template.clickable = false;
  legend.itemContainers.template.focusable = false;
  legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
  legend.itemContainers.template.fontSize = theme.typography.body2.fontSize;
  legend.itemContainers.template.fontWeight = '300';
  chart.legend = legend;
  return legend;
}
