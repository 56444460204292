import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR';
import { BehaviourHistoryRangeDataPoint, BehaviourSummaryItemType } from '@insights/models';
import {
  createChartSubTitle as utilsCreateChartSubTitle,
  createChartTitle as utilsCreateChartTitle
} from '@insights/utils';
import * as MUI from '@mui/material';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { OQValue } from '@shared/models/types';
import { ImageService, LocalizationService } from '@shared/resources/services';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

type Chart = am4charts.XYChart;

interface ChartElements {
  chart: Chart;
  title: am4core.Label;
  subTitle: am4core.Label;
  categoryAxis: am4charts.CategoryAxis;
  dateAxis: am4charts.DateAxis;
  series: am4charts.ColumnSeries;
}

export interface BehaviourHistoryChartProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  caption: string;
  subcaption?: string;
  data: BehaviourHistoryRangeDataPoint[];
  onBehaviourClicked?: (type: BehaviourSummaryItemType) => void;
}

export const BehaviourHistoryChart = observer((props: BehaviourHistoryChartProps) => {
  const { imageService, localizationService } = useInsightsServices();
  const { className, sx, style, data, caption, subcaption: subCaption, onBehaviourClicked } = props;
  const theme = useTheme();

  const chartElements = useRef<ChartElements | null>(null);

  function handleCategoryClicked(behaviourType: BehaviourSummaryItemType) {
    onBehaviourClicked?.(behaviourType);
  }

  function getColorValue(value: OQValue): string {
    switch (value) {
      case 'green':
        return green[400];
      case 'yellow':
        return orange[400];
      case 'red':
        return red[400];
      case 'none':
      default:
        return grey[300];
    }
  }

  function createChart() {
    const chart = am4core.create('behavior-history-chart-container', am4charts.XYChart);
    chart.dateFormatter.firstDayOfWeek = 0; // Sunday
    chart.dateFormatter.utc = true;
    chart.paddingLeft = 60; // This is for the axis images

    const { title, subTitle } = createChartTitle(chart, theme);
    const { categoryAxis, dateAxis } = createChartAxis(
      chart,
      theme,
      (type) => handleCategoryClicked(type),
      imageService,
      localizationService
    );

    const series = createChartSeries(
      chart,
      (type) => handleCategoryClicked(type),
      (value) => getColorValue(value)
    );

    createChartCursor(chart, dateAxis, categoryAxis);
    chartElements.current = {
      chart,
      title,
      subTitle,
      categoryAxis,
      dateAxis,
      series
    };
  }

  function setChartData() {
    if (chartElements.current != null) {
      chartElements.current.chart.data = data;
    }
  }

  function setChartTitle() {
    if (chartElements.current == null) {
      return;
    }

    const title = chartElements.current.title;
    const subTitle = chartElements.current.subTitle;
    const marginBottom = Number(theme.spacing(3).replace('px', ''));

    title.text = caption;
    title.marginBottom = marginBottom;

    if (subCaption != null) {
      title.marginBottom = 0;

      subTitle.text = subCaption;
      subTitle.marginBottom = marginBottom;
    }
  }

  useLayoutEffect(() => {
    createChart();
    setChartTitle();
    setChartData();
  }, []);

  useEffect(() => {
    const currentLocaleChangeDisposer = autorun(() => {
      const chart = chartElements.current?.chart;
      if (chart != null) {
        chart.language.locale = localizationService.currentLocale === 'fr' ? am4lang_fr_FR : am4lang_en_US;
      }
    });

    return () => {
      currentLocaleChangeDisposer();
      chartElements.current?.chart.dispose();
    };
  }, []);

  useEffect(() => {
    setChartTitle();
    setChartData();
  }, [caption, subCaption, data]);

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <Box sx={{ width: '100%', height: '100%' }} id="behavior-history-chart-container" />
    </MUI.Box>
  );
});

function createChartTitle(chart: Chart, theme: Theme) {
  const title = utilsCreateChartTitle(theme);
  const subTitle = utilsCreateChartSubTitle(theme);

  chart.titles.push(subTitle);
  chart.titles.push(title);
  return { title, subTitle };
}

function createChartAxis(
  chart: Chart,
  theme: Theme,
  handleCategoryClicked: (behaviourType: BehaviourSummaryItemType) => void,
  imageService: ImageService,
  localizationService: LocalizationService
) {
  const imageSize = 34;

  const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = 'type';
  categoryAxis.renderer.minGridDistance = 20;
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.inversed = true;
  categoryAxis.renderer.gridContainer.strokeWidth = 0; // Disable the grid lines
  categoryAxis.renderer.labels.template.disabled = true;
  categoryAxis.cursorTooltipEnabled = false;
  categoryAxis.fontSize = theme.typography.body1.fontSize;
  categoryAxis.fontWeight = '300';
  categoryAxis.tooltip!.label.fontSize = theme.typography.body1.fontSize;
  categoryAxis.tooltip!.label.fontWeight = '300';

  const bullet = new am4charts.AxisBullet();
  bullet.dx = -imageSize;

  const image = bullet.createChild(am4core.Image);
  image.horizontalCenter = 'middle';
  image.verticalCenter = 'middle';
  image.width = imageSize;
  image.height = imageSize;
  image.adapter.add('href', (href, target) => {
    const categoryDataItem = target.dataItem as am4charts.CategoryAxisDataItem;

    if (categoryDataItem != null) {
      switch (categoryDataItem.category) {
        case 'app-open':
          return imageService.insightsImages.organizationalBehaviours.appOpen;
        case 'task-completion':
          return imageService.insightsImages.organizationalBehaviours.taskCompletion;
        default:
          return href;
      }
    }

    return href;
  });
  image.adapter.add('tooltipText', (tooltipText, target) => {
    const categoryDataItem = target.dataItem as am4charts.CategoryAxisDataItem;

    if (categoryDataItem != null) {
      switch (categoryDataItem.category) {
        case 'app-open':
          return localizationService.localizedStrings.insights.components.behaviourSummary.appOpenChartLabel;
        case 'task-completion':
          return localizationService.localizedStrings.insights.components.behaviourSummary.taskCompletionChartLabel;
        default:
          return tooltipText;
      }
    }

    return tooltipText;
  });
  image.tooltipText = ' '; // This is to make the adapter work
  image.events.on('hit', (e) => {
    const categoryDataItem = e.target.dataItem as am4charts.CategoryAxisDataItem;

    // For some reason, this event is called twice. The last one does not have a category.
    if (categoryDataItem.category == null) {
      return;
    }

    handleCategoryClicked(categoryDataItem.category as BehaviourSummaryItemType);
  });

  categoryAxis.dataItems.template.bullet = bullet;

  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.minGridDistance = 70;
  dateAxis.baseInterval = { count: 1, timeUnit: 'day' };
  dateAxis.renderer.gridContainer.strokeWidth = 0; // Disable the grid lines

  // Configure how date formatting of the dates on the axis
  dateAxis.dateFormats.setKey('day', 'd');
  dateAxis.periodChangeDateFormats.setKey('day', '[font-weight: 500]MMM[/]');
  dateAxis.dateFormats.setKey('week', 'd');
  dateAxis.periodChangeDateFormats.setKey('week', '[font-weight: 500]MMM[/]');
  dateAxis.dateFormats.setKey('month', 'MMM yyyy');
  dateAxis.periodChangeDateFormats.setKey('month', 'MMM yyyy');

  dateAxis.fontSize = theme.typography.body1.fontSize;
  dateAxis.fontWeight = '300';
  dateAxis.tooltip!.label.fontSize = theme.typography.body1.fontSize;
  dateAxis.tooltip!.label.fontWeight = '300';
  dateAxis.tooltipDateFormat = 'EEEE, MMM d';

  return { categoryAxis, dateAxis };
}

function createChartSeries(
  chart: Chart,
  handleCategoryClicked: (behaviourType: BehaviourSummaryItemType) => void,
  getColorValue: (value: OQValue) => string
) {
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.columns.template.height = 10;
  series.dataFields.openDateX = 'startDate';
  series.dataFields.dateX = 'endDate';
  series.dataFields.categoryY = 'type';
  series.columns.template.events.on('hit', (e) =>
    handleCategoryClicked((e.target.dataItem as am4charts.ColumnSeriesDataItem).categoryY as BehaviourSummaryItemType)
  );
  series.columns.template.adapter.add('fill', (fill, target) => {
    const dataItem = target.dataItem as am4charts.ColumnSeriesDataItem;
    if (dataItem) {
      const dataPoint = dataItem.dataContext as BehaviourHistoryRangeDataPoint;
      if (dataPoint) {
        return am4core.color(getColorValue(dataPoint.color));
      }
    }

    return fill;
  });
  series.columns.template.strokeOpacity = 0;
  series.columns.template.column.cornerRadiusTopLeft = 25;
  series.columns.template.column.cornerRadiusTopRight = 25;
  series.columns.template.column.cornerRadiusBottomLeft = 25;
  series.columns.template.column.cornerRadiusBottomRight = 25;

  return series;
}

function createChartCursor(chart: Chart, dateAxis: am4charts.DateAxis, categoryAxis: am4charts.CategoryAxis) {
  const cursor = new am4charts.XYCursor();
  cursor.behavior = 'none';
  cursor.xAxis = dateAxis;
  cursor.yAxis = categoryAxis;
  cursor.lineY.disabled = true;

  chart.cursor = cursor;
}
