import { css } from '@emotion/css';
import { OnboardingStepViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { MinLayoutWidth } from '../../Constants';
import { OnboardingCommentsCard } from './OnboardingCommentsCard';
import { OnboardingStepCard } from './OnboardingStepCard';

export interface OnboardingStepViewProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: OnboardingStepViewModel;
}

export const OnboardingStepView = observer((props: OnboardingStepViewProps) => {
  const { className, style, sx, viewModel } = props;
  const cardClassName = css([{ minWidth: MinLayoutWidth, display: 'block' }]);

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <OnboardingStepCard className={cardClassName} viewModel={viewModel} />
      <OnboardingCommentsCard className={cardClassName} viewModel={viewModel.commentsViewModel} />
    </MUI.Box>
  );
});
