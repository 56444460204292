import { AlertService, NavigationService, SubscriptionsService } from '@insights/services';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { FeatureModel } from '@shared/models/subscriptions';
import {
  AllSubscriptionEntitlements,
  PremiumFeature,
  PremiumFeatureList,
  SubscriptionEntitlement
} from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface FeatureInfo {
  readonly feature: PremiumFeature;
  readonly isSelected: boolean;
}

export interface FeaturesInfo {
  readonly schoolYearConfiguration: SchoolYearConfigurationModel;
  readonly features: FeatureInfo[];
  readonly featureEntitlements: FeatureModel[];
  readonly availableEntitlements: SubscriptionEntitlement[];
}

export interface FeaturesViewModel {
  readonly configId: string;
  readonly data: IPromiseBasedObservable<FeaturesInfo>;

  editFeatures: (schoolYearConfiguration: SchoolYearConfigurationModel) => Promise<void>;
  addEntitlement: (configId: string, availableEntitlements: SubscriptionEntitlement[]) => Promise<void>;
  removeEntitlement: (configId: string, entitlement: SubscriptionEntitlement) => Promise<void>;
}

export class AppFeaturesViewModel implements FeaturesViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _subscriptionsService: SubscriptionsService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    public readonly configId: string
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<FeaturesInfo> {
    return fromPromise(this.loadFeatures());
  }

  async editFeatures(schoolYearConfiguration: SchoolYearConfigurationModel): Promise<void> {
    await this._navigationService.navigateToEditFeatures(schoolYearConfiguration);
  }

  async addEntitlement(configId: string, availableEntitlements: SubscriptionEntitlement[]): Promise<void> {
    await this._navigationService.navigateToAddEntitlement(configId, availableEntitlements);
  }

  async removeEntitlement(configId: string, entitlement: SubscriptionEntitlement): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.features;
    const answer = await this._alertService.showConfirmation({
      title: strings.confirmRemoveEntitlementTitle,
      message: strings.confirmRemoveEntitlementMessage(entitlement)
    });

    if (answer !== 'cancelled') {
      try {
        await this._subscriptionsService.removeSchoolRequiredFeature(configId, entitlement);
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.features;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedErrorMessage + (error as Error).message
        });
      }
    }
  }

  private async loadFeatures(): Promise<FeaturesInfo> {
    const [config, subscription] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this.configId),
      this._subscriptionsService.getSchoolSubscription(this.configId)
    ]);

    const features: FeatureInfo[] = PremiumFeatureList.map((feature) => ({
      feature,
      isSelected: config.disabledFeatures.indexOf(feature) === -1
    }));

    const featureEntitlements = subscription?.requiredFeatures ?? [];
    const availableEntitlements = AllSubscriptionEntitlements.filter(
      (e) => featureEntitlements.find((f) => f.entitlement == e) == null
    );

    return {
      schoolYearConfiguration: config,
      features,
      featureEntitlements,
      availableEntitlements
    };
  }
}
