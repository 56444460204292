import {
  CurrentPageInfo,
  ObservablePresenter,
  PageFooter,
  PageHeaderBar,
  WeekPagingNavigation
} from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';
import { PublishedTasksByGrade } from './PublishedTasksByGrade';
import { WorkloadManagerByGrade } from './WorkloadManagerByGrade';

export interface WorkloadProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const Workload = observer((props: WorkloadProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createWorkload(configId), [configId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Workload Manager' });
  });

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="column">
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ width: '100%', height: '100%' }}
          data={viewModel.data}
          loadingMessage={strings.loadingDataMessage}
          errorMessage={strings.loadingDataErrorMessage}
          render={() => (
            <MUI.Box height="100%" display="flex" flexDirection="column">
              <MUI.Box flex={1} overflow="auto" padding={2}>
                <MUI.Grid container>
                  {/* Workload Manager */}
                  <MUI.Grid item xs={12}>
                    <MUI.Card>
                      <WorkloadManagerByGrade
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createWorkloadManagerByGrade(
                          viewModel.configId,
                          viewModel.pagination
                        )}
                        displayWeekNavigation={false}
                      />
                    </MUI.Card>
                  </MUI.Grid>

                  {/* Published Tasks */}
                  <MUI.Grid item xs={12}>
                    <MUI.Card>
                      <PublishedTasksByGrade
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createPublishedTasksByGrade(
                          viewModel.configId,
                          viewModel.pagination
                        )}
                        displayWeekNavigation={false}
                      />
                    </MUI.Card>
                  </MUI.Grid>
                </MUI.Grid>
              </MUI.Box>

              <PageFooter dense>
                <MUI.Box display="flex" flexDirection="row" alignItems="center">
                  <CurrentPageInfo pagination={viewModel.pagination} />

                  <MUI.Box flex={1} />

                  <WeekPagingNavigation pagination={viewModel.pagination} />
                </MUI.Box>
              </PageFooter>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
