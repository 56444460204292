import { observer } from 'mobx-react';
import { IPromiseBasedObservable } from 'mobx-utils';
import * as React from 'react';

export interface ObservableActionsPresenterProps<T> {
  className?: string;
  style?: React.CSSProperties;
  data: IPromiseBasedObservable<T>;
  render: (data: T) => React.ReactElement;
  renderError: () => React.ReactElement;
}

export const ObservableActionsPresenter = observer(<T,>(props: ObservableActionsPresenterProps<T>) => {
  const { data, render, renderError } = props;

  return data.case({
    pending: () => <></>,
    rejected: () => renderError(),
    fulfilled: (value) => render(value)
  });
});
