import { TeacherPlanningViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { styled } from '@mui/material';
import { Day } from '@shared/models/types';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsButton } from '../InsightsButton';

export interface TeacherPlanningMoveLeftPopoverProps {
  viewModel: TeacherPlanningViewModel;
  anchorElement?: Element;
}

export const TeacherPlanningMoveLeftPopover = observer((props: TeacherPlanningMoveLeftPopoverProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, anchorElement } = props;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  return (
    <Root
      open={viewModel.movingLeftCell != null}
      anchorEl={anchorElement}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
    >
      <MUI.Box minWidth={400} maxWidth={600} padding={2} display="flex" flexDirection="column">
        <MUI.Typography className={'control'} variant="h6">
          {strings.modifyPeriodTitle}
        </MUI.Typography>

        <MUI.TextField
          className={'control'}
          fullWidth
          disabled={viewModel.moveTitlesLeft}
          label={strings.moveCurrentTitleLabel}
          placeholder={strings.moveCurrentTitlePlaceholder(viewModel.defaultTitle)}
          value={viewModel.currentTitle}
          onChange={(event) => (viewModel.currentTitle = event.target.value)}
        />

        <MUI.TextField
          className={'control'}
          fullWidth
          label={strings.moveCurrentRoomNameLabel}
          value={viewModel.currentRoomName}
          onChange={(event) => (viewModel.currentRoomName = event.target.value)}
        />

        <MUI.FormControlLabel
          className={'control'}
          label={strings.moveCurrentUnskip}
          control={
            <MUI.Checkbox
              color="primary"
              checked={viewModel.unskipCurrent || viewModel.moveTitlesLeft}
              disabled={viewModel.moveTitlesLeft}
              onChange={(e) => (viewModel.unskipCurrent = e.target.checked)}
            />
          }
        />

        <MUI.FormControlLabel
          className={'controlWithNotice'}
          label={strings.moveTitlesLeft}
          control={
            <MUI.Checkbox
              color="primary"
              checked={viewModel.moveTitlesLeft}
              onChange={(e) => (viewModel.moveTitlesLeft = e.target.checked)}
            />
          }
        />
        <MUI.Typography className={'subWarning'} variant="body2" color="error">
          {strings.moveTitlesLeftWarning}
        </MUI.Typography>
        <MUI.Typography className={'subNotice'} variant="body2" color="primary">
          {strings.moveTitlesLeftNotice}
        </MUI.Typography>

        <MUI.TextField
          className={'subControl'}
          fullWidth
          type="date"
          disabled={!viewModel.moveTitlesLeft}
          label={strings.moveTitlesLeftUntil}
          InputLabelProps={{ style: { marginLeft: 32 } }}
          value={viewModel.moveUntil.asDateString}
          onChange={(event) => {
            const day = Day.fromDateString(event.target.value);
            if (day != null) {
              viewModel.moveUntil = day;
            }
          }}
        />

        <MUI.Box display="flex" flexDirection="row-reverse" paddingTop={2}>
          <InsightsButton className={'button'} onClick={() => viewModel.cancel()}>
            {strings.cancel}
          </InsightsButton>
          <InsightsButton className={'button'} isDefault onClick={() => void viewModel.confirmMoveLeft()}>
            {strings.modify}
          </InsightsButton>
        </MUI.Box>
      </MUI.Box>
    </Root>
  );
});

const Root = styled(MUI.Popover)(({ theme }) => ({
  '.control': {
    marginBottom: theme.spacing(1)
  },
  '.controlWithNotice': {},
  '.subControl': {
    marginBottom: theme.spacing(1),
    paddingLeft: 32
  },
  '.subWarning': {
    marginBottom: theme.spacing(0.5),
    paddingLeft: 32
  },
  '.subNotice': {
    marginBottom: theme.spacing(2),
    paddingLeft: 32
  },
  '.button': {
    marginLeft: theme.spacing(1)
  }
}));
