import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { EditableExternalFileSource, ExternalFileSource } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface ExternalFileSourceEditionDialogProps extends DialogResult<ExternalFileSource> {
  sx?: SxProps;
  editableSource: EditableExternalFileSource;
}

export const ExternalFileSourceEditionDialog = observer((props: ExternalFileSourceEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, editableSource, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.settings;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createExternalFileSourceEdition(editableSource, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog sx={sx} open maxWidth="sm" fullWidth onClose={() => void viewModel.cancel()}>
        <MUI.DialogTitle>
          {viewModel.isNew ? strings.addExternalFileSourceTitle : strings.editExternalFileSourceTitle}
        </MUI.DialogTitle>

        <MUI.DialogContent dividers>
          <MUI.Box
            display="flex"
            flexDirection="column"
            sx={{
              // This is to reset the bottom margin of the last field
              '& >:last-child': {
                marginBottom: 0
              }
            }}
          >
            <MUI.TextField
              value={viewModel.editableSource.baseUrl}
              label={strings.baseUrlLabel}
              sx={{ mb: 2 }}
              onChange={(event) => (viewModel.editableSource.baseUrl = event.target.value)}
            />
            <MUI.TextField
              value={viewModel.editableSource.port}
              type="number"
              label={strings.portLabel}
              sx={{ mb: 2 }}
              onChange={(event) => {
                const port = Number(event.target.value);
                if (!Number.isNaN(port)) {
                  viewModel.editableSource.port = port;
                }
              }}
            />
            <MUI.TextField
              value={viewModel.editableSource.username}
              label={strings.usernameLabel}
              autoComplete="new-password"
              sx={{ mb: 2 }}
              onChange={(event) => (viewModel.editableSource.username = event.target.value)}
            />
            <MUI.TextField
              type="password"
              value={viewModel.editableSource.password}
              label={strings.passwordLabel}
              helperText={viewModel.hasPasswordPlaceholder ? strings.updatePasswordNotice : ''}
              autoComplete="new-password"
              sx={{ mb: 2 }}
              onChange={(event) => (viewModel.editableSource.password = event.target.value)}
            />
          </MUI.Box>
        </MUI.DialogContent>

        <MUI.DialogActions>
          <InsightsButton isDisabled={viewModel.isApplying} onClick={() => void viewModel.cancel()}>
            {strings.cancelButton}
          </InsightsButton>
          <InsightsButton
            isDefault
            isDisabled={!viewModel.canApply || viewModel.isApplying}
            onClick={() => void viewModel.apply()}
          >
            {strings.applyButton}
          </InsightsButton>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
