import { CustomizableViewModel } from '@insights/viewmodels';
import { SxProps, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { DayConfigurationModel } from '@shared/models/config';
import { observer } from 'mobx-react';
import { CSSProperties, ReactElement } from 'react';
import { CustomizationsListDayConfiguration } from './CustomizationsListDayConfiguration.tsx';

export interface CustomizationsListTableProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: CustomizableViewModel;
  renderRightComponent?: ((dc: DayConfigurationModel) => ReactElement) | undefined;
  textColor: string;
}

export const CustomizationsListTable = observer(
  ({ sx, className, style, viewModel, renderRightComponent, textColor }: CustomizationsListTableProps) => {
    return (
      <Table sx={sx} className={className} style={style}>
        <TableBody>
          {viewModel.dayConfigurations.map((dc) => (
            <TableRow key={`day-configuration-${dc.id}`}>
              <TableCell style={{ padding: '2px 10px' }}>
                <CustomizationsListDayConfiguration
                  dayConfiguration={dc}
                  variant="table"
                  renderRightComponent={renderRightComponent}
                  viewModel={viewModel}
                  textColor={textColor}
                />
              </TableCell>
              {renderRightComponent != null && (
                <TableCell style={{ padding: '2px 10px' }}>{renderRightComponent(dc)}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
);
