import { StudentDailyTaskMetrics_StudentDailyTaskCounters as PBStudentDailyTaskCounters } from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { Day } from '../types';

export class StudentDailyTaskCounters {
  private _pb: PBStudentDailyTaskCounters;

  constructor(pb: PBStudentDailyTaskCounters) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  @LazyGetter()
  get day(): Day {
    const day = this._pb.day;
    if (day == null) {
      throw new Error('Unexpected data from backend: StudentDailyTaskCounters.Day is undefined.');
    }

    return new Day(day);
  }

  get late(): number {
    return this._pb.late;
  }

  get dueToday(): number {
    return this._pb.dueToday;
  }

  get dueFuture(): number {
    return this._pb.dueFuture;
  }

  get plannedPast(): number {
    return this._pb.plannedPast;
  }

  get plannedToday(): number {
    return this._pb.plannedToday;
  }

  get plannedFuture(): number {
    return this._pb.plannedFuture;
  }

  get activeWithSteps(): number {
    return this._pb.activeWithSteps;
  }

  get completedWithSteps(): number {
    return this._pb.completedWithSteps;
  }

  get completed(): number {
    return this._pb.completed;
  }

  get cancelled(): number {
    return this._pb.cancelled;
  }

  get active(): number {
    return this._pb.active;
  }
}
