import { css } from '@emotion/css';
import {
  MaximumAssociatedSectionNumbersLength,
  MaximumSectionGradeLevelLength,
  MaximumSectionGroupLength,
  MaximumSectionImportIdLength,
  MaximumSectionRoomNameLength,
  MaximumSectionShortTitleLength,
  MaximumSectionTitleLength,
  SectionEditionViewModel
} from '@insights/viewmodels';
import { Button, Checkbox, FormControlLabel, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { red } from '@mui/material/colors';
import { AdminAuthorizationRoles } from '@shared/models/types';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { EditableAccountSelection } from '../EditableAccountSelection';
import { EditableAutoEnrollRoles } from '../EditableAutoEnrollRoles';
import { EditableAutoEnrollTags } from '../EditableAutoEnrollTags';
import { EditableColor } from '../EditableColor';
import { Column } from '../layout';
import { MultipleValuesCheckbox, MultipleValuesControl, MultipleValuesTextField } from '../utils';

export interface SectionEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SectionEditionViewModel;
}

export const SectionEdition = observer((props: SectionEditionProps) => {
  const { accountService, localizationService } = useInsightsServices();
  const { viewModel, style } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;
  const canChangeImportId = accountService.isAllowed(AdminAuthorizationRoles) || viewModel.shouldBeCreated;
  const canChangeTeacher = accountService.isAllowed(AdminAuthorizationRoles);
  const theme = useTheme();

  const controlClassName = css([{ margin: theme.spacing(1) }]);
  const importIdError = viewModel.getLiveError('importId');
  const titleError = viewModel.getLiveError('title');

  return (
    <Column style={style}>
      <MultipleValuesCheckbox
        value={viewModel.isLocked}
        label={strings.lockedFormControlLabel}
        className={controlClassName}
        onChange={(value) => (viewModel.isLocked = value)}
      />
      <Stack direction="row" alignItems="baseline">
        <MultipleValuesTextField
          value={viewModel.importId}
          disabled={viewModel.importId === 'multiple-values' || !canChangeImportId}
          label={strings.importIdentifier}
          className={controlClassName}
          onChange={(value) => (viewModel.importId = value)}
          textFieldProps={{
            inputProps: { maxLength: MaximumSectionImportIdLength },
            error: importIdError != null,
            helperText: importIdError,
            fullWidth: true
          }}
        />
        {viewModel.importId !== 'multiple-values' && (
          <Button variant="contained" onClick={() => viewModel.generateRandomImportId()}>
            {strings.generateRandomImportIdButton}
          </Button>
        )}
      </Stack>
      {importIdError != null && viewModel.importId === 'multiple-values' && (
        <Typography ml={1} variant="caption" color="error">
          {strings.importIdentifierMultiError}
        </Typography>
      )}
      <MultipleValuesTextField
        value={viewModel.title}
        label={strings.title}
        className={controlClassName}
        onChange={(value) => (viewModel.title = value)}
        textFieldProps={{
          inputProps: { maxLength: MaximumSectionTitleLength },
          error: titleError != null,
          helperText: titleError
        }}
      />
      {titleError != null && viewModel.title === 'multiple-values' && (
        <Typography ml={1} variant="caption" color="error">
          {strings.titleMultiError}
        </Typography>
      )}
      <MultipleValuesTextField
        value={viewModel.shortTitle}
        label={strings.shortTitle}
        className={controlClassName}
        onChange={(value) => (viewModel.shortTitle = value)}
        textFieldProps={{
          inputProps: { maxLength: MaximumSectionShortTitleLength }
        }}
      />
      <MultipleValuesTextField
        value={viewModel.gradeLevel}
        label={strings.gradeLevel}
        className={controlClassName}
        onChange={(value) => (viewModel.gradeLevel = value)}
        textFieldProps={{
          inputProps: { maxLength: MaximumSectionGradeLevelLength }
        }}
      />
      <MultipleValuesTextField
        value={viewModel.sectionNumber}
        label={strings.sectionOrGroupNumber}
        className={controlClassName}
        onChange={(value) => (viewModel.sectionNumber = value)}
        textFieldProps={{
          inputProps: { maxLength: MaximumSectionGroupLength }
        }}
      />
      <MultipleValuesTextField
        value={viewModel.associatedSectionNumbers}
        label={strings.associatedSectionNumbers}
        className={controlClassName}
        onChange={(value) => (viewModel.associatedSectionNumbers = value)}
        textFieldProps={{
          helperText: strings.associatedSectionNumbersDescription,
          inputProps: { maxLength: MaximumAssociatedSectionNumbersLength }
        }}
      />
      <EditableColor
        className={controlClassName}
        value={viewModel.color}
        onChange={(value) => (viewModel.color = value)}
      />
      <EditableAccountSelection
        selectedId={viewModel.defaultTeacherId}
        accounts={viewModel.teachers}
        label={strings.defaultTeacher}
        canEdit={canChangeTeacher}
        className={controlClassName}
        onChange={(value) => (viewModel.defaultTeacherId = value)}
      />
      {viewModel.hasDefaultTeacherChanged && !viewModel.shouldBeCreated && (
        <FormControlLabel
          sx={{ m: 1, backgroundColor: red[100] }}
          control={
            <Checkbox
              checked={viewModel.shouldChangeScheduleTeachers}
              onChange={(event) => (viewModel.shouldChangeScheduleTeachers = event.target.checked)}
            />
          }
          label={strings.changeScheduleTeachers}
        />
      )}
      <MultipleValuesTextField
        value={viewModel.defaultRoomName}
        label={strings.defaultRoomName}
        className={controlClassName}
        onChange={(value) => (viewModel.defaultRoomName = value)}
        textFieldProps={{
          inputProps: { maxLength: MaximumSectionRoomNameLength }
        }}
      />

      <Typography variant="h6" m={1} mt={2}>
        {strings.autoEnrollSectionTitle}
      </Typography>
      {viewModel.hasAutoEnrollConflict && (
        <Typography m={1} variant="body2" color={theme?.palette.error.dark}>
          {strings.autoEnrollConflictWarning}
        </Typography>
      )}
      <EditableAutoEnrollRoles
        className={controlClassName}
        values={viewModel.autoEnrollRoles}
        onChange={(roles) => (viewModel.autoEnrollRoles = roles)}
      />
      <MultipleValuesControl
        value={viewModel.autoEnrollTags}
        defaultValue={[]}
        label={strings.autoEnrollTagsLabel}
        className={controlClassName}
        renderControl={(value) => (
          <EditableAutoEnrollTags
            sx={{ mt: 1, mb: 2 }}
            tags={value}
            availableTags={viewModel.availableAutoEnrollTags}
            error={viewModel.getLiveError('autoEnrollTags')}
            onChange={(tags) => (viewModel.autoEnrollTags = tags)}
          />
        )}
      />
    </Column>
  );
});
