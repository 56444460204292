import { CurrentPageInfo, ObservablePresenter, PageFooter, WeekPagingNavigation } from '@insights/components';
import FilterIcon from '@mui/icons-material/FilterList';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';
import {
  PublishedTasksBySection,
  SectionsCourseOccurrencesStatus,
  WorkloadManagerByGrade,
  WorkloadManagerBySection
} from '../metrics';

export interface WorkloadEmbeddedProps {
  sx?: SxProps;
  className?: string;
}

export const WorkloadEmbedded = observer((props: WorkloadEmbeddedProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.teachers;

  const params = useParams();
  const configId = params.configId ?? '';
  const teacherId = params.teacherId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createWorkloadEmbedded(configId, teacherId), [configId, teacherId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Workload Manager (iOS)' });
  });

  return (
    <MUI.Box sx={sx} className={className}>
      <ObservablePresenter
        sx={{ width: '100%', height: '100%' }}
        data={viewModel.data}
        render={(data) => {
          const hasSections = data.teacherTaughtSectionIds.length > 0;

          return (
            <MUI.Box display="flex" flexDirection="column" width="100%" height="100%">
              <MUI.Box flex={1} overflow="auto" padding={2}>
                <MUI.Grid container>
                  {/* Course Occurrences Status */}
                  {hasSections && (
                    <MUI.Grid item xs={12}>
                      <MUI.Card>
                        <SectionsCourseOccurrencesStatus
                          sx={{ width: '100%', height: '100%' }}
                          viewModel={viewModelFactory.createSectionsCourseOccurrencesStatus(
                            viewModel.configId,
                            data.displayedSectionIds,
                            viewModel.pagination
                          )}
                          displaySectionName={true}
                          displayWeekNavigation={false}
                        />
                      </MUI.Card>
                    </MUI.Grid>
                  )}

                  {/* Published Tasks */}
                  {hasSections && (
                    <MUI.Grid item xs={12}>
                      <MUI.Card>
                        <PublishedTasksBySection
                          sx={{ width: '100%', height: '100%' }}
                          viewModel={viewModelFactory.createPublishedTasksBySection(
                            viewModel.configId,
                            data.displayedSectionIds,
                            viewModel.pagination
                          )}
                          displaySectionName={true}
                          displayWeekNavigation={false}
                        />
                      </MUI.Card>
                    </MUI.Grid>
                  )}

                  {/* Workload Manager (by sections) */}
                  {hasSections && (
                    <MUI.Grid item xs={12}>
                      <MUI.Card>
                        <WorkloadManagerBySection
                          sx={{ width: '100%', height: '100%' }}
                          viewModel={viewModelFactory.createWorkloadManagerBySection(
                            viewModel.configId,
                            data.displayedSectionIds,
                            viewModel.pagination
                          )}
                          displaySectionName={true}
                          displayWeekNavigation={false}
                        />
                      </MUI.Card>
                    </MUI.Grid>
                  )}

                  {/* Workload Manager (by grade levels) */}
                  <MUI.Grid item xs={12}>
                    <MUI.Card>
                      <WorkloadManagerByGrade
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createWorkloadManagerByGrade(
                          viewModel.configId,
                          viewModel.pagination
                        )}
                        displayWeekNavigation={false}
                      />
                    </MUI.Card>
                  </MUI.Grid>
                </MUI.Grid>
              </MUI.Box>

              <PageFooter dense>
                <MUI.Box display="flex" flexDirection="row" alignItems="center">
                  <CurrentPageInfo pagination={viewModel.pagination} />

                  <MUI.Box flex={1} />

                  <WeekPagingNavigation pagination={viewModel.pagination} />

                  <MUI.Tooltip title={strings.filterButtonTooltip}>
                    <MUI.IconButton onClick={() => void viewModel.showFilters()}>
                      <MUI.Badge badgeContent={data.filterCount} color="primary" showZero={false} variant="dot">
                        <FilterIcon />
                      </MUI.Badge>
                    </MUI.IconButton>
                  </MUI.Tooltip>
                </MUI.Box>
              </PageFooter>
            </MUI.Box>
          );
        }}
      />
    </MUI.Box>
  );
});
