import AgentIcon from '@mui/icons-material/HeadsetMic';
import ClientIcon from '@mui/icons-material/School';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AccountModel } from '@shared/models/config';
import { OnboardingParticipantKind } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface OnboardingOwnershipProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  size?: 'small' | 'medium';
  variant?: 'filled' | 'outlined';
  agent?: AccountModel;
  client?: AccountModel;
  followers?: AccountModel[];
  participants?: OnboardingParticipantKind;
  disabled?: boolean;
  onClick?: () => Promise<void>;
}

export const OnboardingOwnership = observer((props: OnboardingOwnershipProps) => {
  const {
    className,
    style,
    size = 'medium',
    variant = 'filled',
    agent,
    client,
    followers = [],
    participants = 'studyo-and-client',
    disabled = false,
    onClick
  } = props;
  const { localizationService } = useInsightsServices();

  return (
    <MUI.Box className={className} style={style}>
      <MUI.Chip
        sx={{ mr: 1 }}
        label={renderLabel(localizationService, participants, agent, client, followers, variant === 'outlined')}
        disabled={disabled}
        onClick={onClick != null ? () => void onClick() : undefined}
        size={size}
        variant={variant}
      />
    </MUI.Box>
  );
});

function renderLabel(
  localization: LocalizationService,
  participants: OnboardingParticipantKind,
  agent: AccountModel | undefined,
  client: AccountModel | undefined,
  followers: AccountModel[],
  outlined: boolean
) {
  const strings = localization.localizedStrings.insights.views.onboarding;
  const agentLabel = AccountUtils.getDisplayFirstLastName(agent, strings.noStudyoAgentShortLabel);
  const clientLabel = AccountUtils.getDisplayFirstLastName(client, strings.noClientOwnerShortLabel);
  const variant = outlined ? 'caption' : 'body2';
  const color = outlined ? 'textSecondary' : 'textPrimary';

  return (
    <MUI.Box display="flex" flexDirection="row" alignItems="center">
      {participants !== 'studyo-only' && (
        <>
          {!outlined && <ClientIcon fontSize="small" />}
          <MUI.Typography variant={variant} color={color}>
            &nbsp;{clientLabel}&nbsp;
          </MUI.Typography>
          {outlined && participants !== 'client-only' && (
            <MUI.Typography variant="caption" color="textSecondary">
              &nbsp;/&nbsp;
            </MUI.Typography>
          )}
        </>
      )}
      {participants !== 'client-only' && (
        <>
          {!outlined && <AgentIcon fontSize="small" />}
          <MUI.Typography variant={variant} color={color}>
            &nbsp;{agentLabel}&nbsp;
          </MUI.Typography>
        </>
      )}
      {followers.length > 0 && (
        <MUI.Tooltip title={followers.map((f) => AccountUtils.getDisplayFirstLastName(f)).join(', ')} placement="top">
          <MUI.Typography variant={variant} color={color}>
            &nbsp;{`+${followers.length}`}
          </MUI.Typography>
        </MUI.Tooltip>
      )}
    </MUI.Box>
  );
}
