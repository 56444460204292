import { OnboardingProcessViewModel, OnboardingStepSummaryViewModel } from '@insights/viewmodels';
import { default as CustomizedIcon, default as RenameIcon } from '@mui/icons-material/CallSplit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/FileCopy';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { ReorderableList } from '@studyo/components';
import { observer } from 'mobx-react';
import * as React from 'react';
import { MaxLayoutWidth } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { MoreActionMenu } from '../MoreActionMenu';
import { OnboardingOwnership } from './OnboardingOwnership';
import { OnboardingStatusChip } from './OnboardingStatusChip';
import { OnboardingStepSummaryView } from './OnboardingStepSummary';
import { OnboardingTypographySelector } from './OnboardingTypographySelector';

export interface OnboardingProcessCardProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: OnboardingProcessViewModel;
}

interface SortableStepListProps {
  items: OnboardingStepSummaryViewModel[];
  onOrderChanged: (oldIndex: number, newIndex: number) => void;
}

const SortableStepList = ({ items, onOrderChanged }: SortableStepListProps) => (
  <MUI.Box>
    <ReorderableList
      items={items}
      renderItem={(value) => (
        <OnboardingStepSummaryView
          step={value}
          canEdit={true}
          canSort={true}
          style={{ marginBottom: 10 }}
          key={`onboarding-step-${value.id}`}
        />
      )}
      onOrderChanged={onOrderChanged}
    />
  </MUI.Box>
);

export const OnboardingProcessCard = observer((props: OnboardingProcessCardProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <MUI.Card sx={sx} className={className} style={style}>
      <MUI.CardHeader
        title={
          <OnboardingOwnership
            agent={viewModel.agent}
            client={viewModel.client}
            followers={viewModel.followers}
            disabled={!viewModel.canEdit}
            onClick={() => viewModel.selectOwners()}
          />
        }
        action={
          <MUI.Box display="flex" flexDirection="row" alignItems="center">
            <OnboardingStatusChip
              status={viewModel.status}
              nextDate={viewModel.nextTargetDate}
              targetDate={viewModel.finalTargetDate}
              disabled={!viewModel.canEdit}
              onChange={(status) => viewModel.updateStatus(status)}
            />

            {viewModel.isCustomized && viewModel.canEdit && (
              <MUI.Tooltip title={strings.customizedProcessNotice}>
                <CustomizedIcon sx={{ ml: 1 }} color="error" />
              </MUI.Tooltip>
            )}

            {viewModel.canEdit && (
              <MoreActionMenu>
                <MUI.MenuItem onClick={() => void viewModel.navigateToEditProcess()}>
                  <MUI.ListItemIcon>
                    <EditIcon />
                  </MUI.ListItemIcon>
                  <MUI.ListItemText>{strings.editProcessLabel}</MUI.ListItemText>
                </MUI.MenuItem>
                <MUI.MenuItem onClick={() => void viewModel.renameProcess()}>
                  <MUI.ListItemIcon>
                    <RenameIcon />
                  </MUI.ListItemIcon>
                  <MUI.ListItemText>{strings.getRenameTemplateLabel(viewModel.templateName)}</MUI.ListItemText>
                </MUI.MenuItem>
                <MUI.MenuItem onClick={() => void viewModel.deleteProcess()}>
                  <MUI.ListItemIcon>
                    <DeleteIcon />
                  </MUI.ListItemIcon>
                  <MUI.ListItemText>{strings.deleteProcessLabel}</MUI.ListItemText>
                </MUI.MenuItem>
                <MUI.MenuItem onClick={() => void viewModel.copyProcess()}>
                  <MUI.ListItemIcon>
                    <CopyIcon />
                  </MUI.ListItemIcon>
                  <MUI.ListItemText>{strings.copyProcessLabel}</MUI.ListItemText>
                </MUI.MenuItem>
              </MoreActionMenu>
            )}
          </MUI.Box>
        }
      />
      <MUI.Divider />
      <MUI.CardContent
        sx={{
          maxWidth: MaxLayoutWidth - 50 // Arbitrary, trial and error
        }}
      >
        <OnboardingTypographySelector
          sx={{ mb: 2 }}
          texts={viewModel.description}
          variableResolver={viewModel.variableResolver}
        />
        <MUI.Typography variant="h6">{strings.stepsTitle}</MUI.Typography>
        {viewModel.canSort ? (
          <>
            <SortableStepList
              items={viewModel.steps}
              onOrderChanged={(oldIndex, newIndex) => void viewModel.reorderStep(oldIndex, newIndex)}
            />
            {/* canSort implies canEdit */}
            <MUI.Button sx={{ mt: 2 }} variant="contained" onClick={() => void viewModel.navigateToAddStep()}>
              {strings.addStepLabel}
            </MUI.Button>
          </>
        ) : (
          <MUI.Box>
            {viewModel.steps.map((step, index) => (
              <OnboardingStepSummaryView
                key={`onboarding-step-${index}`}
                step={step}
                canEdit={viewModel.canEdit}
                canSort={false}
                style={{ marginBottom: 10 }}
              />
            ))}
          </MUI.Box>
        )}
      </MUI.CardContent>
    </MUI.Card>
  );
});
