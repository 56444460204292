import { GoogleAccountSettingsViewModel } from '@insights/viewmodels';
import { SxProps, Typography, styled } from '@mui/material';
import { AdminAuthorizationRoles } from '@shared/models/types';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { InsightsButton } from '../InsightsButton';
import { Column, Expanded, Row } from '../layout';
import { ClassroomCourseAliasesSelector } from './ClassroomCourseAliasesSelector';
import { ClassroomCourseWorkMappings } from './ClassroomCourseWorkMappings';
import { ClassroomMatchingExpressionSelector } from './ClassroomMatchingExpressionSelector';
import { ClassroomRemoveMatchedKeyword } from './ClassroomRemoveMatchedKeyword';
import { ClassroomUnmatchedCourseWork } from './ClassroomUnmatchedCourseWork';

export interface GoogleAccountSettingsProps {
  sx?: SxProps;
  className?: string;
  viewModel: GoogleAccountSettingsViewModel;
}

export const GoogleAccountSettings = observer((props: GoogleAccountSettingsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Root sx={sx} className={className}>
      <ClassroomCourseWorkMappings className="mappings" viewModel={viewModel} />

      <ClassroomUnmatchedCourseWork
        className="mappingsExtra"
        isIgnoringUnmatchedCourseWork={viewModel.isIgnoringUnmatchedCourseWork}
        onChange={(value) => (viewModel.isIgnoringUnmatchedCourseWork = value)}
      />

      <ClassroomRemoveMatchedKeyword
        className="mappingsExtra"
        isRemovingMatchedKeyword={viewModel.isRemovingMatchedKeyword}
        onChange={(value) => (viewModel.isRemovingMatchedKeyword = value)}
      />

      <AuthorizationRoleCondition allowedRoles={['super-admin']}>
        <Typography variant="subtitle1">{strings.advancedTitle}</Typography>
        <ClassroomMatchingExpressionSelector
          className="matching"
          matchingValue={viewModel.matchingExpression}
          onChange={(value) => (viewModel.matchingExpression = value ?? '')}
        />
        <ClassroomCourseAliasesSelector
          isFetchingCourseAliases={viewModel.isFetchingCourseAliases}
          onChange={(value) => (viewModel.isFetchingCourseAliases = value)}
        />
      </AuthorizationRoleCondition>

      <Row>
        <Typography className="errors" noWrap={false} color="error">
          {viewModel.error}
        </Typography>
        <Expanded />
        <InsightsButton className="button" isExecuting={viewModel.isApplying} onClick={() => viewModel.cancelChanges()}>
          {strings.cancel}
        </InsightsButton>
        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
          <InsightsButton
            className="button"
            isDisabled={viewModel.isApplying}
            onClick={() => void viewModel.applyChangesToAll()}
          >
            {strings.saveToAll}
          </InsightsButton>
        </AuthorizationRoleCondition>
        <InsightsButton
          className="button"
          isExecuting={viewModel.isApplying}
          showSpinnerOnExecuting
          isSubmit
          onClick={() => void viewModel.applyChanges()}
        >
          {strings.save}
        </InsightsButton>
      </Row>
    </Root>
  );
});

const Root = styled(Column)(({ theme }) => ({
  '.mappingsTitle': {
    margin: theme.spacing(0),
    marginTop: theme.spacing(1)
  },
  '.matching': {
    margin: theme.spacing(0),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 400
  },
  '.mappings': {
    margin: theme.spacing(0),
    marginBottom: theme.spacing(1)
  },
  '.mappingsExtra': {
    margin: theme.spacing(0),
    marginBottom: theme.spacing(2)
  },
  '.button': {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  '.errors': {
    margin: theme.spacing(1)
  }
}));
