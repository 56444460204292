import { ManageBacAccountSettingsViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import * as MUI from '@mui/material';
import { styled, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface ManageBacTermIdsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: ManageBacAccountSettingsViewModel;
}

export const ManageBacTermIds = observer((props: ManageBacTermIdsProps) => {
  const { sx, className, style, viewModel } = props;

  return (
    <Root sx={sx} className={className} style={style}>
      <MUI.TableBody>
        {viewModel.termIds.map((id) => (
          <MUI.TableRow key={id}>
            <MUI.TableCell className={classNames('cell', 'termIdCell')}>
              <MUI.Typography>{Number(id)}</MUI.Typography>
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'actionCell')}>
              <MUI.IconButton onClick={() => viewModel.removeTermId(id)}>
                <DeleteIcon fontSize="small" />
              </MUI.IconButton>
            </MUI.TableCell>
          </MUI.TableRow>
        ))}
      </MUI.TableBody>
      <MUI.TableFooter>
        <MUI.TableRow className={'nextRow'}>
          <MUI.TableCell className={classNames('cell', 'termIdCell')}>
            <MUI.Input
              fullWidth
              type="number"
              value={viewModel.addingTermId}
              onChange={(e) => {
                const value = BigInt(e.currentTarget.value);

                if (!Number.isNaN(value)) {
                  viewModel.addingTermId = value;
                }
              }}
              endAdornment={
                <MUI.InputAdornment position="end">
                  <MUI.IconButton onClick={() => viewModel.addTermId()} disabled={!viewModel.canAddTermId}>
                    <AddIcon fontSize="small" />
                  </MUI.IconButton>
                </MUI.InputAdornment>
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter' && viewModel.canAddTermId) {
                  viewModel.addTermId();
                }
              }}
            />
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'actionCell')} />
        </MUI.TableRow>
      </MUI.TableFooter>
    </Root>
  );
});

const Root = styled(MUI.Table)(() => ({
  '.cell': {
    padding: '2px 8px 2px 0px',
    border: 'none'
  },
  '.termIdCell': {
    width: '80%'
  },
  '.actionCell': {
    width: '20%'
  },
  '.nextRow': {
    backgroundColor: grey[200]
  }
}));
