import { RenameOrCopyOnboardingTemplateViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface RenameOrCopyOnboardingTemplateProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: RenameOrCopyOnboardingTemplateViewModel;
}

export const RenameOrCopyOnboardingTemplate = observer((props: RenameOrCopyOnboardingTemplateProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <MUI.Typography variant="caption">{strings.currentTemplateNameLabel}</MUI.Typography>
      <MUI.Typography>{viewModel.currentTemplateName}</MUI.Typography>

      <MUI.TextField
        sx={{ mt: 2 }}
        fullWidth
        label={strings.newTemplateNameLabel}
        value={viewModel.newTemplateName}
        onChange={(e) => (viewModel.newTemplateName = e.target.value)}
        helperText={viewModel.message}
      />
    </MUI.Box>
  );
});
