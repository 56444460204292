import { PeriodDisplay } from '@insights/enums';
import { AppStorageModel, AppStorageProperty, StorageProperty } from '@insights/utils';
import { EmptyStudentsFilters, StudentsFilters } from '@insights/viewmodels';
import { Storage } from '@shared/services';
import { computed, makeObservable, observable } from 'mobx';
import { AppCardPreferences, CardPreferences } from './CardPreferences';
import { AppConfigPreferences, ConfigPreferences } from './ConfigPreferences';
import { AppOnboardingPreferences, OnboardingPreferences } from './OnboardingPreferences';
import { SettingsStore } from './SettingsStore';
import { AppTablePreferences, TablePreferences } from './TablePreferences';
import { AppWorkloadPreferences, WorkloadPreferences } from './WorkloadPreferences';

const PeriodDisplayStorageKey = 'PeriodDisplay';
const StudentsFiltersStorageKey = 'StudentsFilters';

export class WebSettingsStore extends AppStorageModel implements SettingsStore {
  @observable private _schoolConfigurationsYear: number = this.getCurrentSchoolConfigurationsYear();
  @observable private _showDemoSchools = false;
  @observable private _demoMode = false;
  @observable private _useIOSAccessTokenProvider = false;
  @observable private _isCompletingLogin = false;
  @observable private _hasLoginError = false;
  @observable private _hasSidenav = false;
  @observable private _sidenavOpen = false;
  @observable private _sidenavCollapsed = false;
  @observable private _isEmbedded = false;

  private readonly _periodDisplay: StorageProperty<PeriodDisplay>;
  private readonly _studentsFilters: StorageProperty<StudentsFilters>;

  private readonly _tablePreferences: TablePreferences;
  private readonly _cardPreferences: CardPreferences;
  private readonly _workloadPreferences: WorkloadPreferences;
  private readonly _onboardingPreferences: OnboardingPreferences;

  constructor(storage: Storage) {
    super(storage);

    makeObservable(this);

    this._tablePreferences = new AppTablePreferences(storage);

    this.setStorageProperties([
      (this._periodDisplay = new AppStorageProperty(storage, PeriodDisplayStorageKey, PeriodDisplay.Daily)),
      (this._studentsFilters = new AppStorageProperty(storage, StudentsFiltersStorageKey, EmptyStudentsFilters)),
      (this._cardPreferences = new AppCardPreferences(storage)),
      (this._workloadPreferences = new AppWorkloadPreferences(storage)),
      (this._onboardingPreferences = new AppOnboardingPreferences(storage))
    ]);
  }

  get tablePreferences(): TablePreferences {
    return this._tablePreferences;
  }

  get cardPreferences(): CardPreferences {
    return this._cardPreferences;
  }

  get workloadPreferences(): WorkloadPreferences {
    return this._workloadPreferences;
  }

  @computed
  get periodDisplay() {
    return this._periodDisplay.value;
  }

  set periodDisplay(value: PeriodDisplay) {
    this._periodDisplay.value = value;
  }

  @computed
  get studentsFilters() {
    return this._studentsFilters.value;
  }

  set studentsFilters(value: StudentsFilters) {
    this._studentsFilters.value = value;
  }

  get onboardingPreferences(): OnboardingPreferences {
    return this._onboardingPreferences;
  }

  @computed
  get schoolConfigurationsYear() {
    return this._schoolConfigurationsYear;
  }

  set schoolConfigurationsYear(value: number) {
    this._schoolConfigurationsYear = value;
  }

  @computed
  get showDemoSchools() {
    return this._showDemoSchools;
  }

  set showDemoSchools(value: boolean) {
    this._showDemoSchools = value;
  }

  @computed
  get demoMode() {
    return this._demoMode;
  }

  set demoMode(value: boolean) {
    this._demoMode = value;
  }

  @computed
  get useIOSAccessTokenProvider() {
    return this._useIOSAccessTokenProvider;
  }

  set useIOSAccessTokenProvider(value: boolean) {
    this._useIOSAccessTokenProvider = value;
  }

  @computed
  get isCompletingLogin() {
    return this._isCompletingLogin;
  }

  set isCompletingLogin(value: boolean) {
    this._isCompletingLogin = value;
  }

  @computed
  get hasLoginError() {
    return this._hasLoginError;
  }

  set hasLoginError(value: boolean) {
    this._hasLoginError = value;
  }

  @computed
  get hasSidenav(): boolean {
    return this._hasSidenav;
  }

  set hasSidenav(hasSidenav: boolean) {
    this._hasSidenav = hasSidenav;
  }

  @computed
  get sidenavOpen(): boolean {
    return this._sidenavOpen;
  }

  set sidenavOpen(value: boolean) {
    this._sidenavOpen = value;
  }

  @computed
  get sidenavCollapsed(): boolean {
    return this._sidenavCollapsed;
  }

  set sidenavCollapsed(value: boolean) {
    this._sidenavCollapsed = value;
  }

  @computed
  get isEmbedded(): boolean {
    return this._isEmbedded;
  }

  set isEmbedded(value: boolean) {
    this._isEmbedded = value;
  }

  async initialize(): Promise<void> {
    await Promise.all([this.tablePreferences.initialize(), super.initialize()]);
  }

  async clear() {
    // It is not enough to remove the memoize from memory, we also need to clear the local storage.
    // IMPORTANT: This must be done before calling super because the memoized cache won't be available then.

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    (this.getConfigPreferences as any).values().forEach((preferences: ConfigPreferences) => preferences.clear());

    this.tablePreferences.clear();

    await super.clear();
  }

  getConfigPreferences = this.memoize(
    (configId: string): ConfigPreferences => new AppConfigPreferences(configId, this._storage)
  );

  private getCurrentSchoolConfigurationsYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // If before July, return previous year
    return currentDate.getMonth() < 6 ? currentYear - 1 : currentYear;
  }
}
