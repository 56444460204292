import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { SubscriptionEntitlement } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface AddEntitlementDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  availableEntitlements: SubscriptionEntitlement[];
}

export const AddEntitlementDialog = observer((props: AddEntitlementDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx, availableEntitlements, configId, onSuccess, onCancel, style } = props;
  const strings = localizationService.localizedStrings.insights.views.features;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createAddEntitlement(configId, availableEntitlements, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="xs"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <MUI.DialogTitle>{strings.addEntitlementDialogTitle}</MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <MUI.FormControl fullWidth>
              <MUI.InputLabel htmlFor="entitlement-select">{strings.entitlementLabel}</MUI.InputLabel>
              <MUI.Select
                value={viewModel.entitlement}
                inputProps={{ id: 'entitlement-select' }}
                onChange={(e) => (viewModel.entitlement = e.target.value as SubscriptionEntitlement)}
              >
                {availableEntitlements.map((e) => (
                  <MUI.MenuItem key={`entitlement-${e}`} value={e}>
                    <MUI.Typography>{strings.getEntitlementLabel(e)}</MUI.Typography>
                  </MUI.MenuItem>
                ))}
              </MUI.Select>
            </MUI.FormControl>
            <MUI.TextField
              fullWidth
              sx={{ mt: 1 }}
              label={strings.stripePriceIdLabel}
              value={viewModel.stripePriceId}
              onChange={(e) => (viewModel.stripePriceId = e.target.value)}
            />
          </MUI.DialogContent>

          <MUI.DialogActions>
            <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
              {strings.cancelButtonCaption}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={!viewModel.canSave}
              isExecuting={viewModel.isSubmitting}
              onClick={() => void viewModel.save()}
            >
              {strings.saveButtonCaption}
            </InsightsButton>
          </MUI.DialogActions>
        </form>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
