import { ExternalFileSources, ObservablePresenter, PageHeaderBar } from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface GlobalSettingsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const GlobalSettings = observer((props: GlobalSettingsProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.settings;

  const viewModel = useMemo(() => viewModelFactory.createGlobalSettings(), []);

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="column">
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%' }}
          data={viewModel.data}
          loadingMessage={strings.loadingOptionsMessage}
          errorMessage={strings.loadingOptionsErrorMessage}
          render={(data) => (
            <MUI.Box overflow="auto">
              <MUI.Grid container>
                <MUI.Grid item xs={12} md={6}>
                  <ExternalFileSources viewModel={data.externalSources} />
                </MUI.Grid>
              </MUI.Grid>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
