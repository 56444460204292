import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import { EditableTransformationColumnViewModel, EditableTransformationViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Cached';
import ResetIcon from '@mui/icons-material/CancelOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import DeleteIcon from '@mui/icons-material/Delete';
import ReorderIcon from '@mui/icons-material/Reorder';
import SaveIcon from '@mui/icons-material/SaveAlt';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { ReorderableList } from '@studyo/components';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties, useLayoutEffect } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsButton } from '../InsightsButton';
import { EditableIsSubstitution } from './EditableIsSubstitution';
import { EditableOperation } from './EditableOperation';
import { EditableParameters } from './EditableParameters';
import { EditableTargetSchemaField } from './EditableTargetSchemaField';

export interface ImportSessionTransformationColumnListProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  transformation: EditableTransformationViewModel;
}

const SortableTransformationColumnRow = ({ value }: { value: EditableTransformationColumnViewModel }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: value.id });

  const containerStyle = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dragHandleStyle: CSSProperties = {
    cursor: 'move',
    touchAction: 'none'
  };

  return (
    <MUI.Grid
      ref={setNodeRef}
      container
      spacing={1}
      alignItems="center"
      style={{
        ...containerStyle,
        borderBottom: '1px solid #bbb'
      }}
    >
      <MUI.Grid item xs={2}>
        <EditableTargetSchemaField column={value} />
      </MUI.Grid>
      <MUI.Grid item xs={2}>
        <EditableOperation column={value} />
      </MUI.Grid>
      <MUI.Grid item xs={6}>
        <EditableParameters column={value} />
      </MUI.Grid>
      <MUI.Grid item xs={2}>
        <MUI.Box display="flex" flexDirection="row" alignItems="center">
          <MUI.Box flex={1}>
            <EditableIsSubstitution column={value} />
          </MUI.Box>
          <MUI.Tooltip title={value.comment}>
            <MUI.IconButton onClick={() => value.editComment()}>
              <CommentIcon color={value.hasComment ? 'secondary' : 'disabled'} />
            </MUI.IconButton>
          </MUI.Tooltip>
          <MUI.IconButton onClick={() => value.delete()}>
            <DeleteIcon />
          </MUI.IconButton>

          <ReorderIcon sx={{ ml: 1 }} {...attributes} {...listeners} style={dragHandleStyle} />
        </MUI.Box>
      </MUI.Grid>
    </MUI.Grid>
  );
};

interface SortableTransformationColumnListProps {
  items: EditableTransformationColumnViewModel[];
  onOrderChange: (oldIndex: number, newIndex: number) => void;
}

const SortableTransformationColumnList = ({ items, onOrderChange }: SortableTransformationColumnListProps) => (
  <MUI.Box>
    <ReorderableList
      items={items}
      renderItem={(item) => <SortableTransformationColumnRow key={`transformation-column-${item.id}`} value={item} />}
      onOrderChanged={onOrderChange}
    />
  </MUI.Box>
);

export const ImportSessionTransformationColumnList = observer((props: ImportSessionTransformationColumnListProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, transformation } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.altKey && !e.shiftKey) {
      switch (e.code) {
        case 'KeyA':
          transformation.addColumn();
          break;
        case 'KeyU':
          void transformation.updateData();
          break;
        case 'KeyS':
          void transformation.saveChanges();
          break;
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const columnHeaderClassName = css([{ fontWeight: 500 }]);

  return (
    <MUI.Paper sx={sx} className={className} style={style} variant="outlined">
      <MUI.Box m={2}>
        <MUI.Box display="flex" flexDirection="row" alignItems="center">
          <MUI.Box flex={1}>
            <MUI.Typography variant="h6">{`${transformation.label} - ${transformation.name}`}</MUI.Typography>
          </MUI.Box>
          <MUI.Box mx={1} display="flex" flexDirection="row">
            <MUI.Tooltip title={strings.addColumnTooltip}>
              {/* Avoid disabled immediate child of tooltip */}
              <MUI.Box>
                <MUI.IconButton disabled={transformation.isExecuting} onClick={() => transformation.addColumn()}>
                  <AddIcon />
                </MUI.IconButton>
              </MUI.Box>
            </MUI.Tooltip>
            <MUI.Tooltip title={strings.updateDataTooltip}>
              {/* Avoid disabled immediate child of tooltip */}
              <MUI.Box>
                <MUI.IconButton
                  disabled={transformation.isExecuting || !transformation.hasChangesSinceLastUpdate}
                  onClick={() => void transformation.updateData()}
                >
                  <UpdateIcon />
                </MUI.IconButton>
              </MUI.Box>
            </MUI.Tooltip>
            <MUI.Tooltip title={strings.resetChangesTooltip}>
              {/* Avoid disabled immediate child of tooltip */}
              <MUI.Box>
                <MUI.IconButton
                  disabled={transformation.isExecuting || !transformation.hasChanges}
                  onClick={() => transformation.resetChanges()}
                >
                  <ResetIcon />
                </MUI.IconButton>
              </MUI.Box>
            </MUI.Tooltip>
            <MUI.Tooltip title={strings.saveChangesTooltip}>
              {/* Avoid disabled immediate child of tooltip */}
              <MUI.Box>
                <MUI.IconButton
                  disabled={transformation.isExecuting || !transformation.hasChanges}
                  onClick={() => void transformation.saveChanges()}
                >
                  <SaveIcon />
                </MUI.IconButton>
              </MUI.Box>
            </MUI.Tooltip>
          </MUI.Box>
        </MUI.Box>
        <MUI.Grid container spacing={1}>
          <MUI.Grid item xs={2}>
            <MUI.Typography variant="body2" className={columnHeaderClassName}>
              {strings.targetSchema}
            </MUI.Typography>
          </MUI.Grid>
          <MUI.Grid item xs={2}>
            <MUI.Typography variant="body2" className={columnHeaderClassName}>
              {strings.operation}
            </MUI.Typography>
          </MUI.Grid>
          <MUI.Grid item xs={6}>
            <MUI.Typography variant="body2" className={columnHeaderClassName}>
              {strings.parameters}
            </MUI.Typography>
          </MUI.Grid>
          <MUI.Grid item xs={2}>
            <MUI.Typography variant="body2" className={columnHeaderClassName}>
              {strings.isSubstitution}
            </MUI.Typography>
          </MUI.Grid>
        </MUI.Grid>
        <SortableTransformationColumnList
          items={transformation.editableColumns}
          onOrderChange={(oldIndex, newIndex) => transformation.moveColumn(oldIndex, newIndex)}
        />
      </MUI.Box>
      <MUI.Dialog
        open={transformation.editingCommentColumn != null}
        fullWidth
        maxWidth="sm"
        onAbort={() => transformation.cancelComment()}
        onClose={() => transformation.cancelComment()}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <MUI.DialogTitle>{strings.editCommentTitle}</MUI.DialogTitle>
          <MUI.DialogContent>
            <MUI.TextField
              fullWidth
              autoFocus
              onSubmit={() => transformation.applyComment()}
              value={transformation.editingComment}
              onChange={(e) => (transformation.editingComment = e.target.value)}
            />
          </MUI.DialogContent>
          <MUI.DialogActions>
            <InsightsButton onClick={() => transformation.cancelComment()}>{strings.cancel}</InsightsButton>
            <InsightsButton isSubmit onClick={() => transformation.applyComment()}>
              {strings.save}
            </InsightsButton>
          </MUI.DialogActions>
        </form>
      </MUI.Dialog>
    </MUI.Paper>
  );
});
