import {
  AccountList,
  CurrentPageInfo,
  LabeledValuePresenter,
  ObservablePresenter,
  PageFooter,
  PageHeaderBar,
  PageHeaderDetailBar,
  RouterLink,
  SectionName,
  StudentBehaviorAggregation,
  WeekPagingNavigation
} from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { sortBy } from 'lodash';
import { Observer, observer } from 'mobx-react';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { cleanDiacritics } from 'underscore.string';
import { RouteParamNames, RouteTemplates } from '../../../Routes';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';
import { PublishedTasksBySection } from './PublishedTasksBySection';
import { SectionsCourseOccurrencesStatus } from './SectionsCourseOccurrencesStatus';
import { WorkloadManagerBySection } from './WorkloadManagerBySection';

export interface SectionDetailsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const SectionDetails = observer((props: SectionDetailsProps) => {
  const { analyticsService, localizationService, reactRouterRouteService, viewModelFactory } = useInsightsServices();
  const { className, style, sx } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  const params = useParams();
  const configId = params.configId ?? '';
  const sectionId = params.sectionId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createSectionDetails(configId, sectionId), [configId, sectionId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Section Details' });
  });

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="column">
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingDetailsMessage}
          errorMessage={strings.loadingDetailsErrorMessage}
          render={(data) => {
            const studentsViewModel = data.studentsViewModel;

            return (
              <MUI.Box height="100%" display="flex" flexDirection="column">
                {/* Section Info */}
                <PageHeaderDetailBar>
                  <MUI.Box display="flex" flexDirection="row">
                    {/* Section title and teachers */}
                    <MUI.Box flex={1}>
                      {/* Section Title */}
                      <SectionName
                        sx={{ mb: 1 }}
                        title={data.sectionInfo.section?.title ?? ''}
                        size="big"
                        color={data.sectionInfo.section?.color}
                      />

                      {/* Teachers */}
                      {data.sectionInfo.teachers && data.sectionInfo.teachers.length > 0 && (
                        <MUI.Box display="flex" flexDirection="row" flexWrap="wrap">
                          {sortBy(
                            data.sectionInfo.teachers.map((teacher) => ({
                              teacher: teacher.account,
                              displayName: AccountUtils.getDisplayLastFirstName(teacher.account, strings.noName)
                            })),
                            (teacherInfo) => [cleanDiacritics(teacherInfo.displayName).toLowerCase()]
                          ).map((teacherInfo) => (
                            <MUI.Box key={teacherInfo.teacher.id} marginRight={1} marginBottom={1}>
                              <RouterLink
                                underline="none"
                                to={reactRouterRouteService.resolveLocation(RouteTemplates.teacherDetails, [
                                  {
                                    name: RouteParamNames.configId,
                                    value: viewModel.configId
                                  },
                                  {
                                    name: RouteParamNames.teacherId,
                                    value: teacherInfo.teacher.id
                                  }
                                ])}
                              >
                                <MUI.Chip
                                  key={teacherInfo.teacher.id}
                                  label={teacherInfo.displayName}
                                  clickable={true}
                                  sx={{ fontWeight: '300' }}
                                />
                              </RouterLink>
                            </MUI.Box>
                          ))}
                        </MUI.Box>
                      )}
                    </MUI.Box>

                    {/* Section information (group, grade, room and students) */}
                    <MUI.Box display="flex" flexDirection="row" marginBottom={1} marginRight={1}>
                      {data.sectionInfo.section != null && (
                        <>
                          <LabeledValuePresenter
                            sx={{ ml: 4 }}
                            label={strings.grade}
                            value={data.sectionInfo.section.gradeLevel}
                          />

                          <LabeledValuePresenter
                            sx={{ ml: 4 }}
                            label={strings.group}
                            value={data.sectionInfo.section.sectionNumber}
                          />

                          <LabeledValuePresenter
                            sx={{ ml: 4 }}
                            label={strings.room}
                            value={data.sectionInfo.section.defaultRoomName}
                          />
                        </>
                      )}

                      <LabeledValuePresenter
                        sx={{ ml: 4 }}
                        label={strings.studentsTitle}
                        value={(data.sectionInfo.students != null ? data.sectionInfo.students.length : 0).toString()}
                      />
                    </MUI.Box>
                  </MUI.Box>
                </PageHeaderDetailBar>

                {/* Content */}
                <MUI.Box flex={1} overflow="auto" padding={2}>
                  <MUI.Grid container alignItems="stretch">
                    {/* Course Occurrences Status */}
                    <MUI.Grid item xs={12}>
                      <MUI.Card>
                        <SectionsCourseOccurrencesStatus
                          sx={{ height: '100%', width: '100%' }}
                          viewModel={viewModelFactory.createSectionsCourseOccurrencesStatus(
                            viewModel.configId,
                            [viewModel.sectionId],
                            viewModel.pagination
                          )}
                          displaySectionName={false}
                          displayWeekNavigation={false}
                          allowCsvExport
                        />
                      </MUI.Card>
                    </MUI.Grid>

                    {/* Published Tasks */}
                    <MUI.Grid item xs={12}>
                      <MUI.Card>
                        <PublishedTasksBySection
                          sx={{ height: '100%', width: '100%' }}
                          viewModel={viewModelFactory.createPublishedTasksBySection(
                            viewModel.configId,
                            [viewModel.sectionId],
                            viewModel.pagination
                          )}
                          displaySectionName={false}
                          displayWeekNavigation={false}
                        />
                      </MUI.Card>
                    </MUI.Grid>

                    {/* Workload Manager */}
                    <MUI.Grid item xs={12}>
                      <MUI.Card>
                        <WorkloadManagerBySection
                          sx={{ height: '100%', width: '100%' }}
                          viewModel={viewModelFactory.createWorkloadManagerBySection(
                            viewModel.configId,
                            [viewModel.sectionId],
                            viewModel.pagination
                          )}
                          displaySectionName={false}
                          displayWeekNavigation={false}
                        />
                      </MUI.Card>
                    </MUI.Grid>

                    {/* Students */}
                    <MUI.Grid item xs={12}>
                      {studentsViewModel.hasStudents && (
                        <MUI.Card>
                          {/* Prevent full rerender without requiring separate component */}
                          <Observer>
                            {() => (
                              <MUI.Box height="100%" width="100%">
                                {studentsViewModel.opensAppBehavior != null &&
                                  studentsViewModel.completesTasksBehavior != null && (
                                    <StudentBehaviorAggregation
                                      opensApp={studentsViewModel.opensAppBehavior}
                                      completesTasks={studentsViewModel.completesTasksBehavior}
                                      invitesParent={studentsViewModel.invitesParentBehavior}
                                    />
                                  )}

                                <AccountList
                                  title={strings.studentsTitle}
                                  accounts={studentsViewModel.students}
                                  accountType="student"
                                  configId={viewModel.configId}
                                  exportableViewModel={studentsViewModel}
                                />
                              </MUI.Box>
                            )}
                          </Observer>
                        </MUI.Card>
                      )}
                    </MUI.Grid>
                  </MUI.Grid>
                </MUI.Box>

                <PageFooter dense>
                  <MUI.Box display="flex" flexDirection="row" alignItems="center">
                    <CurrentPageInfo pagination={viewModel.pagination} />

                    <MUI.Box flex={1} />

                    <WeekPagingNavigation pagination={viewModel.pagination} />
                  </MUI.Box>
                </PageFooter>
              </MUI.Box>
            );
          }}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
