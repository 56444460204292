import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface ClassroomCourseAliasesSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  isFetchingCourseAliases: boolean;
  onChange: (value: boolean) => void;
}

export const ClassroomCourseAliasesSelector = observer((props: ClassroomCourseAliasesSelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, isFetchingCourseAliases, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <MUI.FormControlLabel
      sx={sx}
      className={className}
      style={style}
      label={strings.classroomIncludeCourseAliasesLabel}
      control={
        <MUI.Checkbox
          color="primary"
          checked={isFetchingCourseAliases}
          onChange={(event) => onChange(event.target.checked)}
        />
      }
    />
  );
});
