import { LightInsightsMuiTheme } from '@insights/theme';
import { CustomizableViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import * as MUI from '@mui/material';
import { createTheme, styled } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsButton } from '../InsightsButton';
import { useViewModelRef } from '../utils';
import { CustomizationsList } from './CustomizationsList';
import { Schedules } from './Schedules';
import { SpecialDays } from './SpecialDays';

type Tabs = 'specialDays' | 'schedules';

export interface EditableDayCustomizationDialogProps extends DialogResult<void> {
  customizable: CustomizableViewModel;
}

export const EditableDayCustomizationDialog = observer(
  ({ customizable, onSuccess, onCancel }: EditableDayCustomizationDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createEditableCalendarDayCustomization(customizable, onSuccess!, onCancel!)
    );

    const [selectedTab, setSelectedTab] = useState<Tabs>('specialDays');
    const strings = localizationService.localizedStrings.insights.components.calendar;
    const viewStrings = localizationService.localizedStrings.insights.views.calendar;

    const save = () => viewModel.save();
    const cancel = () => viewModel.cancel();

    return (
      <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
        <Root open maxWidth="lg" fullWidth onClose={cancel}>
          <MUI.DialogTitle>{strings.editDayCustomizations}</MUI.DialogTitle>

          <MUI.DialogContent>
            <MUI.Grid container spacing={2}>
              <MUI.Grid item xs={4}>
                <MUI.Card className={'card'}>
                  <MUI.CardHeader title={viewModel.title} />
                  <MUI.CardContent className={'cardContent'}>
                    {viewModel.hasDayConfigurations ? (
                      <CustomizationsList
                        viewModel={viewModel.customizable}
                        color={'dark'}
                        renderRightComponent={(dc) => (
                          <MUI.Tooltip title={strings.removeDayCustomization}>
                            <MUI.Box display={'flex'} justifyContent={'flex-end'}>
                              <MUI.IconButton
                                className={'iconButton'}
                                onClick={() => viewModel.removeDayConfiguration(dc)}
                              >
                                <DeleteIcon />
                              </MUI.IconButton>
                            </MUI.Box>
                          </MUI.Tooltip>
                        )}
                        variant={'table'}
                      />
                    ) : (
                      <MUI.Typography variant={'subtitle2'} color={'textSecondary'}>
                        {strings.emptyDayCustomizations}
                      </MUI.Typography>
                    )}
                  </MUI.CardContent>
                </MUI.Card>
              </MUI.Grid>

              <MUI.Grid item xs={8}>
                <MUI.Paper sx={{ height: '100%', width: '100%', padding: 2 }}>
                  <MUI.Box height="100%" width="100%" display="flex" flexDirection="column">
                    <MUI.Tabs value={selectedTab} onChange={(_, value: Tabs) => setSelectedTab(value)}>
                      <MUI.Tab label={viewStrings.specialDays} value={'specialDays'} />
                      <MUI.Tab label={strings.bellTimes} value={'schedules'} />
                    </MUI.Tabs>
                    <MUI.Box height="100%" width="100%" overflow="auto" className={'cardContent'}>
                      {selectedTab === 'specialDays' && (
                        <SpecialDays
                          viewModel={viewModel.calendar}
                          specialDays={viewModel.specialDays}
                          renderRowActions={(specialDay, isDeleted) =>
                            !isDeleted && (
                              <MUI.IconButton
                                className={'iconButton'}
                                onClick={() => viewModel.addSpecialDay(specialDay)}
                              >
                                <AddIcon />
                              </MUI.IconButton>
                            )
                          }
                          columnsToExclude={['cycleDay', 'bellTimes']}
                        />
                      )}

                      {selectedTab === 'schedules' && (
                        <Schedules
                          viewModel={viewModel.calendar}
                          schedules={viewModel.schedules}
                          renderRowActions={(schedule, isDeleted) =>
                            !isDeleted && (
                              <MUI.IconButton className={'iconButton'} onClick={() => viewModel.addSchedule(schedule)}>
                                <AddIcon />
                              </MUI.IconButton>
                            )
                          }
                          columnsToExclude={['period', 'start', 'end']}
                        />
                      )}
                    </MUI.Box>
                  </MUI.Box>
                </MUI.Paper>
              </MUI.Grid>
            </MUI.Grid>
          </MUI.DialogContent>

          <MUI.DialogActions>
            <InsightsButton onClick={cancel}>{strings.cancel}</InsightsButton>
            <InsightsButton isDefault onClick={save}>
              {strings.done}
            </InsightsButton>
          </MUI.DialogActions>
        </Root>
      </MUI.ThemeProvider>
    );
  }
);

const Root = styled(MUI.Dialog)(() => ({
  '.iconButton': {
    padding: 4,
    marginRight: 4
  },
  '.card': {
    display: 'inherit',
    marginTop: 0,
    marginBottom: 0
  },
  '.cardContent': {
    overflow: 'auto',
    height: 300
  }
}));
