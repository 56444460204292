import { ImportDataDetails, ObservablePresenter, PageHeaderBar } from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportDataDetailsScreenProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportDataDetailsScreen = observer((props: ImportDataDetailsScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  const params = useParams();
  const configId = params.configId ?? '';
  const sessionId = params.sessionId ?? '';
  const label = params.label ?? '';
  const viewModel = useMemo(
    () => viewModelFactory.createImportDataDetails(configId, sessionId, label),
    [configId, sessionId, label]
  );

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.details}
          loadingMessage={strings.loadingImportDataMessage}
          errorMessage={strings.importDataErrorMessage}
          render={(data) => (
            <MUI.Box display="flex" flexDirection="column">
              <ImportDataDetails viewModel={data} />
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
