import {
  ObservablePresenter,
  SchoolDayHeader,
  SchoolWeekHeader,
  SectionName,
  StudentTasksThresholdTable,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { WorkloadManagerInfo } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { SchoolDay } from '@shared/models/calendar';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface WorkloadManagerByGradeDetailProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  gradeLevel: string;
  gradeLevelStudentCount: number;
  fromDay: SchoolDay;
  toDay: SchoolDay;
  includeExamOnly: boolean;
  studentIdsAtThreshold: string[];
  studentIdsOverThreshold: string[];
}

export const WorkloadManagerByGradeDetail = observer((props: WorkloadManagerByGradeDetailProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const {
    sx,
    className,
    style,
    fromDay,
    toDay,
    gradeLevel,
    gradeLevelStudentCount,
    studentIdsOverThreshold,
    studentIdsAtThreshold,
    onCancel,
    onSuccess,
    includeExamOnly,
    configId
  } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createWorkloadManagerByGradeDetail(
      configId,
      gradeLevel,
      gradeLevelStudentCount,
      fromDay,
      toDay,
      includeExamOnly,
      studentIdsAtThreshold,
      studentIdsOverThreshold,
      onSuccess!,
      onCancel!
    )
  );
  const isWeek = !fromDay.day.isSame(toDay.day);

  function renderTable(conflictsInfo: WorkloadManagerInfo, overloaded: boolean) {
    const header = overloaded ? strings.overloadedStudents : strings.studentsAtThreshold;

    return (
      <StudentTasksThresholdTable
        sx={{ height: '100%', width: '100%', mb: 4 }}
        header={header}
        conflictsInfo={conflictsInfo}
        studentsAreOverloaded={overloaded}
      />
    );
  }

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        onClose={() => viewModel.close()}
        scroll="paper"
        className={className}
        style={style}
      >
        <MUI.DialogTitle>
          {/* Ensure the overflow is visible so that the badge is displayed */}
          <MUI.Box display="flex" flexDirection="row" alignItems="center" overflow="visible">
            <SectionName
              color="darker-bmgray"
              title={viewModel.gradeLevel}
              subInformation1={strings.localizedNumberOfStudents(viewModel.gradeLevelStudentCount)}
              size="medium"
            />

            <MUI.Box flex={1} />

            {!isWeek && <SchoolDayHeader schoolDay={fromDay} displayType="page-header" />}
            {isWeek && <SchoolWeekHeader schoolDay={fromDay} displayType="page-header" />}
          </MUI.Box>
        </MUI.DialogTitle>

        <MUI.DialogContent dividers>
          <ObservablePresenter
            sx={{
              minHeight: 100,

              // This is to reset the bottom margin of the last card
              '& >:last-child': {
                marginBottom: 2
              }
            }}
            data={viewModel.data}
            render={(data) => (
              <MUI.Box
                sx={{
                  minHeight: 100,

                  // This is to reset the bottom margin of the last card
                  '& >:last-child': {
                    marginBottom: 2
                  }
                }}
              >
                {(data.overThresholdConflicts?.conflicts.length ?? 0) > 0 &&
                  renderTable(data.overThresholdConflicts!, true)}
                {(data.atThresholdConflicts?.conflicts.length ?? 0) > 0 &&
                  renderTable(data.atThresholdConflicts!, false)}
              </MUI.Box>
            )}
          />
        </MUI.DialogContent>

        <MUI.DialogActions>
          <MUI.Button variant="outlined" onClick={() => viewModel.close()}>
            {strings.close}
          </MUI.Button>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
