import { Storage } from '@shared/services';
import { AppBaseStore, BaseStore } from '@shared/services/stores';
import { IObservableArray, observable } from 'mobx';
import { StorageProperty } from './StorageProperty';

export interface StorageModel extends BaseStore {
  initialize: () => Promise<void>;
}

export abstract class AppStorageModel extends AppBaseStore implements StorageModel {
  private _storageProperties?: IObservableArray<StorageProperty<unknown> | StorageModel>;

  protected constructor(protected readonly _storage: Storage) {
    super('AppStorageModel');
  }

  private get ensuredStorageProperties(): IObservableArray<StorageProperty<unknown> | StorageModel> {
    if (this._storageProperties == null || this._storageProperties.length === 0) {
      throw new Error("Storage properties must be set in the derived class' constructor before the class can be used.");
    }
    return this._storageProperties;
  }

  async initialize(): Promise<void> {
    await Promise.all(this.ensuredStorageProperties.map((storageProperty) => storageProperty.initialize()));
  }

  async clear() {
    await Promise.all(this.ensuredStorageProperties.map((storageProperty) => storageProperty.clear()));
    super.clear();
  }

  protected setStorageProperties(storageProperties: (StorageProperty<unknown> | StorageModel)[]) {
    if (this._storageProperties != null && this._storageProperties.length > 0) {
      throw new Error("Invalid operation. Storage properties should be set once in the derived class' constructor.");
    }

    this._storageProperties = observable.array<StorageProperty<unknown> | StorageModel>(storageProperties);
  }
}
