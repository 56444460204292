import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { AutomatedTransformationImportInfo, AutomatedTransformationImportListViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/NoteAdd';
import ReorderIcon from '@mui/icons-material/Reorder';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { ImportComponentsStrings } from '@shared/resources/strings/insights/components/ImportComponentsStrings';
import { ReorderableList } from '@studyo/components';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { MoreActionMenu } from '../MoreActionMenu';

export interface AutomatedTransformationImportListProps {
  viewModel: AutomatedTransformationImportListViewModel;
  sx?: SxProps;
  className?: string;
}

interface SortableAutomatedTransformationProps {
  value: AutomatedTransformationImportInfo;
  itemIndex: number;
  viewModel: AutomatedTransformationImportListViewModel;
  strings: ImportComponentsStrings;
}

const SortableAutomatedTransformation = ({
  value,
  itemIndex,
  viewModel,
  strings
}: SortableAutomatedTransformationProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: value.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dragHandleStyle: CSSProperties = {
    cursor: 'move',
    touchAction: 'none'
  };

  return (
    <MUI.Box ref={setNodeRef} my={1} flexDirection="row" display="flex" alignItems="flex-start" style={style}>
      <MUI.Typography mr={2} variant="subtitle1" fontWeight={700}>
        {value.automatedTransformationImport.label || '?'}
      </MUI.Typography>

      <MUI.Box flex={1} flexDirection="column" px={1}>
        <MUI.Typography variant="subtitle1">{value.transformation?.name ?? strings.noTransformation}</MUI.Typography>
        <MUI.Typography variant="subtitle2" color="textSecondary">
          {value.automatedTransformationImport.isCompleteData
            ? strings.destructiveAutomatedImport
            : strings.additiveAutomatedImport}
        </MUI.Typography>
        {value.transformation != null && (
          <MUI.Typography variant="subtitle2" color="textSecondary">
            {strings.targetSchemaValue(value.transformation.targetSchema)}
          </MUI.Typography>
        )}
      </MUI.Box>

      <MoreActionMenu>
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <MUI.MenuItem onClick={() => void viewModel.editAutomatedTransformationImport(itemIndex)}>
            <MUI.ListItemIcon>
              <EditIcon />
            </MUI.ListItemIcon>
            <MUI.ListItemText>{strings.editAutomatedTransformation}</MUI.ListItemText>
          </MUI.MenuItem>
        </AuthorizationRoleCondition>
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <MUI.MenuItem onClick={() => void viewModel.deleteAutomatedTransformationImport(itemIndex)}>
            <MUI.ListItemIcon>
              <DeleteIcon />
            </MUI.ListItemIcon>
            <MUI.ListItemText>{strings.deleteAutomatedTransformationImport}</MUI.ListItemText>
          </MUI.MenuItem>
        </AuthorizationRoleCondition>
      </MoreActionMenu>

      <ReorderIcon sx={{ ml: 1, mt: 1.5 }} {...attributes} {...listeners} style={dragHandleStyle} />
    </MUI.Box>
  );
};

interface SortableAutomatedTransformationListProps {
  viewModel: AutomatedTransformationImportListViewModel;
  strings: ImportComponentsStrings;
  onSortEnd: (oldIndex: number, newIndex: number) => void;
}

const SortableAutomatedTransformationList = ({
  viewModel,
  strings,
  onSortEnd
}: SortableAutomatedTransformationListProps) => (
  <ReorderableList
    items={viewModel.automatedTransformationImports}
    renderItem={(value, index) => (
      <SortableAutomatedTransformation
        key={`auto-import-row-${value.automatedTransformationImport.label}-${index}`}
        itemIndex={index}
        value={value}
        viewModel={viewModel}
        strings={strings}
      />
    )}
    onOrderChanged={onSortEnd}
  />
);

export const AutomatedTransformationImportList = observer((props: AutomatedTransformationImportListProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  return (
    <MUI.Box sx={sx} className={className} flexDirection="column">
      <MUI.CardHeader
        title={strings.automatedTransformationImportsTitle}
        action={
          <AuthorizationRoleCondition allowedRoles={['super-admin']}>
            <MUI.Tooltip title={strings.addAutomatedTransformationImportTooltip}>
              <MUI.IconButton onClick={() => void viewModel.addAutomatedTransformationImport()}>
                <AddIcon />
              </MUI.IconButton>
            </MUI.Tooltip>
          </AuthorizationRoleCondition>
        }
      />

      <MUI.CardContent>
        <SortableAutomatedTransformationList
          viewModel={viewModel}
          strings={strings}
          onSortEnd={(oldIndex, newIndex) => void viewModel.reorderAutomatedTransformationImport(oldIndex, newIndex)}
        />
      </MUI.CardContent>
    </MUI.Box>
  );
});
