import { SectionStudentsEditionSectionSelectionViewModel } from '@insights/viewmodels';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Divider, IconButton, InputAdornment, SxProps, TextField } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { Observer, observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { SectionName } from '../SectionName';
import { Column } from '../layout';
import { VirtualizedList } from '../utils';

const RowHeight = 56;

export interface SectionStudentsEditionSectionSelectionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: SectionStudentsEditionSectionSelectionViewModel;
}

export const SectionStudentsEditionSectionSelection = observer((props: SectionStudentsEditionSectionSelectionProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  return (
    <Column sx={sx} className={className} style={style}>
      <TextField
        id="input-with-icon-adornment"
        placeholder={strings.searchSectionImport}
        value={viewModel.searchText}
        onChange={(event) => (viewModel.searchText = event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={viewModel.searchText.length == 0} onClick={() => (viewModel.searchText = '')}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <Box flex={1} sx={{ mt: 1 }}>
        {/* We draw rows in the section header and report 0 rows per section. */}
        <VirtualizedList
          sections={viewModel.results.map(() => ({ rowCount: 0 }))}
          getRowHeight={() => RowHeight}
          renderHeader={(s) => renderRow(viewModel, s)}
          renderItem={() => <></>}
        />
      </Box>
    </Column>
  );
});

function renderRow(viewModel: SectionStudentsEditionSectionSelectionViewModel, index: number) {
  return (
    <Observer>
      {() => {
        const info = viewModel.results[index];
        const isSelected = viewModel.selectedSection?.section.id === info.section.id;
        return (
          <Box
            sx={{
              height: RowHeight,
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer',
              backgroundColor: isSelected ? 'rgba(0,0,0,0.2)' : 'white'
            }}
            onClick={() => (viewModel.selectedSection = info)}
          >
            <Box flex={1} sx={{ pl: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <SectionName
                title={info.section.title}
                color={info.section.color}
                subInformation1={info.section.importId}
                subInformation2={AccountUtils.getDisplayLastFirstName(info.teacher, '')}
                sx={{ height: 40 }}
              />
            </Box>

            <Divider />
          </Box>
        );
      }}
    </Observer>
  );
}
