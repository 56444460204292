// TODO MUI.Box

import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { SxProps, Typography } from '@mui/material';
import { Column, Container } from './layout';

export interface ErrorIndicatorProps {
  sx?: SxProps;
  className?: string;
  message?: string;
  size?: 'small' | 'normal';
}

export function ErrorIndicator({ className, size, message, sx }: ErrorIndicatorProps) {
  const isNormal = size == null || size === 'normal';
  const messageVariant = isNormal ? 'h5' : 'h6';

  return (
    <Column sx={sx} horizontalContentAlignment="center" verticalContentAlignment="center" className={className}>
      <ErrorIcon color="primary" sx={{ fontSize: isNormal ? 100 : 70 }} />

      {message != null && (
        <Container sx={{ mt: 2 }}>
          <Typography variant={messageVariant} sx={{ textAlign: 'center', fontWeight: '300' }}>
            {message}
          </Typography>
        </Container>
      )}
    </Column>
  );
}
