import { AutomatedImportListViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/NoteAdd';
import * as MUI from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { AutomatedImportItem } from './AutomatedImportItem.tsx';

export interface AutomatedImportListProps {
  viewModel: AutomatedImportListViewModel;
  sx?: MUI.SxProps;
  className?: string;
}

export const AutomatedImportList = observer((props: AutomatedImportListProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  return (
    <MUI.Box sx={sx} className={className} flexDirection="column">
      <MUI.CardHeader
        title={strings.automatedImportsTitle}
        action={
          <AuthorizationRoleCondition allowedRoles={['super-admin']}>
            <MUI.Tooltip title={strings.addAutomatedImportTooltip}>
              <MUI.IconButton onClick={() => void viewModel.addAutomatedImport()}>
                <AddIcon />
              </MUI.IconButton>
            </MUI.Tooltip>
          </AuthorizationRoleCondition>
        }
      />

      <MUI.CardContent>
        <MUI.Box sx={{ width: '100%' }}>
          {viewModel.automatedImports.map((imp, index) => (
            <AutomatedImportItem
              key={`auto-import-row-${imp.id}`}
              viewModel={viewModel}
              automatedImport={imp}
              showActions
              hasDivider={index < viewModel.automatedImports.length - 1}
            />
          ))}
        </MUI.Box>
      </MUI.CardContent>
    </MUI.Box>
  );
});
