import { CreateSchool, ObservablePresenter, PageHeaderBar } from '@insights/components';
import { Box, Container, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface CreateSchoolScreenProps {
  sx?: SxProps;
  className?: string;
}

export const CreateSchoolScreen = observer(({ sx, className }: CreateSchoolScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.createSchool;

  const params = useParams();
  const viewModel = useMemo(() => viewModelFactory.createCreateSchool(params.sourceConfigId), []);

  return (
    <Box sx={sx} className={className} display="flex" flexDirection="column">
      <PageHeaderBar />

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingMessage}
          errorMessage={strings.loadingErrorMessage}
          render={(data) => (
            <Container maxWidth="md">
              <CreateSchool viewModel={data} />
            </Container>
          )}
        />
      </Box>
    </Box>
  );
});
