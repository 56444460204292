import { InsightsButton, ObservablePresenter, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface OnboardingHistoryDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  className?: string;
  style?: React.CSSProperties;
}

export const OnboardingHistoryDialog = observer((props: OnboardingHistoryDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx, configId, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const dateFormat = localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createOnboardingHistoryDialog(configId, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="md"
        fullWidth={true}
        onClose={() => viewModel.close()}
      >
        <MUI.DialogTitle>{strings.addStepTitle}</MUI.DialogTitle>
        <MUI.DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => (
              <MUI.Box>
                {data.entries.map((h, index) => (
                  <MUI.Box key={`history-entry-${index}`}>
                    <MUI.Typography variant="caption" color="textSecondary">
                      {format(h.createdAt, dateFormat)}
                    </MUI.Typography>
                    <MUI.Typography>{h.description}</MUI.Typography>
                  </MUI.Box>
                ))}
              </MUI.Box>
            )}
          />
        </MUI.DialogContent>
        <MUI.DialogActions>
          <InsightsButton isDefault onClick={() => viewModel.close()}>
            {strings.okLabel}
          </InsightsButton>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
