import { cleanDiacritics } from 'underscore.string';

export interface NamedFilter<T> {
  prefix: string;
  isMatch: (item: T, value: string) => boolean;
}

export class CustomFilterUtils {
  /**
   * Constructs a "customFilterAndSearch" handler for a MaterialTable's column, preferably
   * applied only to the first column. It supports named filters, like "grade:4", and performs
   * search by word.
   * @param filter The search string enterred by the user.
   * @param item The item displayed in the table's rows that must be filtered out or not.
   * @param searchableValues A function that returns all searchable strings for a given item.
   * Exposed as a function so it can be delayed after named filters still include the item, and
   * could be memoized by item.
   * @param namedFilters The list of named filter perfixes supported and their function that can
   * evaluate if an item is matched.
   */
  static customFilterAndSearch<T>(
    filter: string,
    item: T,
    searchableValues: (item: T) => string[],
    namedFilters?: NamedFilter<T>[]
  ): boolean {
    if (filter.length === 0) {
      return true;
    }

    const cleanedFilter = cleanDiacritics(filter).toLowerCase();
    let words = cleanedFilter.split(' ');

    if (namedFilters != null) {
      for (let i = 0; i < words.length; i++) {
        for (const namedFilter of namedFilters) {
          if (words[i].startsWith(namedFilter.prefix)) {
            if (!namedFilter.isMatch(item, words[i].substr(namedFilter.prefix.length))) {
              return false;
            }
            words[i] = '';
            break;
          }
        }
      }
    }

    words = words.filter((w) => w.length > 0);
    // Because this can be costly, we delay getting them after named filtering.
    const values = searchableValues(item).map((value) => cleanDiacritics(value).toLowerCase());

    return !words.some((word) => !values.some((value) => value.indexOf(word) !== -1));
  }

  static valueAsBoolean(value: string, defaultValue: boolean): boolean {
    switch (value) {
      case '0':
      case 'off':
      case 'false':
      case 'no':
        return false;

      case '1':
      case 'on':
      case 'true':
      case 'yes':
        return true;
    }

    return defaultValue;
  }
}
