// TODO MUI.Box

import { MultipleValues } from '@insights/viewmodels';
import { Box, FormControl, InputLabel, MenuItem, Select, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SectionColors } from '@shared/models/Colors';
import { AllColors, Color } from '@shared/models/types';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { Column } from './layout';
import { MultipleValuesControl } from './utils';

export interface EditableColorProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  value: Color | MultipleValues;
  onChange: (value: Color) => void;
}

export const EditableColor = observer((props: EditableColorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, value, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components;

  return (
    <Column sx={sx} className={className} style={style} horizontalContentAlignment="stretch">
      <MultipleValuesControl
        value={value}
        defaultValue={'orange'}
        label={strings.color}
        renderControl={(v) => (
          <FormControl>
            <InputLabel htmlFor="symbol-color-select">{strings.color}</InputLabel>
            <Select
              variant="filled"
              value={v}
              inputProps={{ id: 'symbol-color-select' }}
              onChange={(event) => onChange(event.target.value as Color)}
              MenuProps={{
                sx: {
                  '&& .MuiMenuItem-root': {
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'white'
                  },
                  '&& .Mui-focusVisible': {
                    borderWidth: 2,
                    borderStyle: 'dotted',
                    borderColor: grey[500]
                  },
                  '&& .Mui-selected': {
                    borderWidth: 2,
                    borderStyle: 'dotted',
                    borderColor: 'black'
                  }
                }
              }}
            >
              {AllColors.map((color) => ({
                value: color,
                color: SectionColors.get(color)
              })).map((info) => (
                <MenuItem sx={{ p: 0.25 }} key={`symbol-color-select-item-${info.value}`} value={info.value}>
                  <Box style={{ backgroundColor: info.color, width: '100%', height: 20 }} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Column>
  );
});
