import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { ImportSession } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportSessionAddOrEditConcatenationDialogProps extends DialogResult<ImportSession> {
  sx?: SxProps;
  configId: string;
  session: ImportSession;
  concatenationLabel?: string;
}

export const ImportSessionAddOrEditConcatenationDialog = observer(
  (props: ImportSessionAddOrEditConcatenationDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { sx, configId, concatenationLabel, session, onSuccess, onCancel } = props;
    const strings = localizationService.localizedStrings.insights.views.imports;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createImportSessionAddOrEditConcatenation(
        configId,
        session,
        concatenationLabel ?? '',
        onSuccess!,
        onCancel!
      )
    );

    return (
      <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
        <MUI.Dialog sx={sx} open={true} maxWidth="sm" fullWidth onClose={() => viewModel.cancel()}>
          <MUI.DialogTitle>{strings.addConcatenationTitle}</MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <MUI.TextField
              sx={{ mb: 1 }}
              label={strings.labelLabel}
              inputProps={{ maxLength: 1 }}
              autoFocus
              value={viewModel.label}
              helperText={viewModel.labelError}
              error={viewModel.labelError.length > 0}
              onChange={(e) => (viewModel.label = e.target.value)}
            />
            <MUI.TextField
              sx={{ mb: 1 }}
              fullWidth
              label={strings.concatenationNameLabel}
              inputProps={{ maxLength: 50 }}
              value={viewModel.name}
              onChange={(e) => (viewModel.name = e.target.value)}
            />
            <MUI.FormControl sx={{ mb: 1 }} fullWidth>
              <MUI.InputLabel htmlFor="source-labels-select">{strings.sourceLabelsLabel}</MUI.InputLabel>
              <MUI.Select
                multiple
                fullWidth
                value={viewModel.sourceLabels}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  }
                }}
                onChange={(e) => (viewModel.sourceLabels = e.target.value as string[])}
                renderValue={() => viewModel.sourceLabels.join(', ')}
                inputProps={{ id: 'source-labels-select' }}
              >
                {viewModel.availableSources.map((source) => (
                  <MUI.MenuItem key={`source-label-${source.label}`} value={source.label}>
                    <MUI.Checkbox size="small" checked={viewModel.sourceLabels.includes(source.label)} />
                    <MUI.Typography variant="body1">{`${source.label} - ${source.name} (${source.columnCount})`}</MUI.Typography>
                  </MUI.MenuItem>
                ))}
              </MUI.Select>
            </MUI.FormControl>
            <MUI.TextField
              sx={{ mb: 1 }}
              fullWidth
              label={strings.concatenatedColumnCountLabel}
              type="number"
              value={viewModel.columnCount}
              onChange={(e) => (viewModel.columnCount = Number(e.target.value))}
            />
            <MUI.FormControlLabel
              sx={{ mb: 1 }}
              label={strings.useCommonColumnNamesLabel}
              control={
                <MUI.Checkbox
                  checked={viewModel.useColumnNames}
                  onChange={(e) => (viewModel.useColumnNames = e.target.checked)}
                />
              }
            />
          </MUI.DialogContent>

          <MUI.DialogActions>
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => viewModel.cancel()}>
              {strings.cancel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isExecuting={viewModel.isExecuting}
              isDisabled={!viewModel.canSave}
              onClick={() => void viewModel.saveConcatenation()}
            >
              {viewModel.isNew ? strings.addButton : strings.saveButton}
            </InsightsButton>
          </MUI.DialogActions>
        </MUI.Dialog>
      </MUI.ThemeProvider>
    );
  }
);
