import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportSessionCreateScreenProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionCreateScreen = observer((props: ImportSessionCreateScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx, configId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createImportSessionCreate(configId, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="xs"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <MUI.DialogTitle>{strings.createSessionTitle}</MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <MUI.Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
              <MUI.TextField
                sx={{ mb: 2 }}
                label={strings.sessionNameLabel}
                value={viewModel.name}
                fullWidth
                required
                autoFocus
                onChange={(e) => (viewModel.name = e.target.value)}
              />

              <MUI.TextField
                sx={{ mb: 2 }}
                label={strings.sessionDescriptionLabel}
                value={viewModel.description}
                multiline
                fullWidth
                onChange={(e) => (viewModel.description = e.target.value)}
              />
            </MUI.Box>
          </MUI.DialogContent>

          <MUI.DialogActions>
            {viewModel.hasError && (
              <MUI.Typography flex={1} variant="caption" color="error" style={{ textAlign: 'end' }}>
                {strings.createSessionError}
              </MUI.Typography>
            )}
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => void viewModel.cancel()}>
              {strings.createSessionCancelButtonLabel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={viewModel.name.length === 0}
              isExecuting={viewModel.isExecuting}
              onClick={() => void viewModel.create()}
            >
              {strings.createSessionCreateButtonLabel}
            </InsightsButton>
          </MUI.DialogActions>
        </form>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
