import { OnboardingVariableResolver } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { OnboardingText } from '@shared/models/onboarding/interfaces';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { OnboardingTypography } from './OnboardingTypography';

export interface OnboardingTypographySelectorProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  texts: OnboardingText[];
  variableResolver?: OnboardingVariableResolver;
  variant?: Variant;
  disabled?: boolean;
}

export const OnboardingTypographySelector = observer((props: OnboardingTypographySelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, texts, variant, variableResolver, disabled } = props;
  const text = texts.find((t) => t.languageCode === localizationService?.currentLocale) ?? texts[0];
  const textValue = variableResolver?.parseText(text.value) ?? text.value;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <OnboardingTypography text={textValue} format={text.format} variant={variant} disabled={disabled} />
    </MUI.Box>
  );
});
