import { AccountList, ObservablePresenter, PageHeaderBar } from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface TeachersProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const Teachers = observer((props: TeachersProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.teachers;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createTeachers(configId), [configId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Teacher List' });
  });

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingTeachersMessage}
          errorMessage={strings.loadingTeachersErrorMessage}
          render={(data) => (
            <MUI.Box display="flex" flexDirection="column">
              <MUI.Card sx={{ minWidth: MinLayoutWidth }}>
                <AccountList
                  sx={{ width: '100%', height: '100%' }}
                  title={strings.teachers}
                  accounts={data}
                  accountType="teacher"
                  configId={viewModel.configId}
                />
              </MUI.Card>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
