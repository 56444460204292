import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { OnboardingStepSummaryViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import StudyoParticipantIcon from '@mui/icons-material/HeadsetMic';
import BothParticipantsIcon from '@mui/icons-material/People';
import ClientParticipantIcon from '@mui/icons-material/Person';
import ReorderIcon from '@mui/icons-material/Reorder';
import * as MUI from '@mui/material';
import { styled, SxProps } from '@mui/material';
import { grey, yellow } from '@mui/material/colors';
import { OnboardingParticipantKind } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { OptionalRouterLink } from '../RouterLink';
import { OnboardingOwnership } from './OnboardingOwnership';
import { OnboardingStatusChip } from './OnboardingStatusChip';
import { OnboardingTypographySelector } from './OnboardingTypographySelector';

export interface OnboardingStepSummaryViewProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  step: OnboardingStepSummaryViewModel;
  canEdit?: boolean;
  canSort?: boolean;
}

export const OnboardingStepSummaryView = observer((props: OnboardingStepSummaryViewProps) => {
  const { localizationService, reactRouterRouteService } = useInsightsServices();
  const { step, sx, className, style, canEdit = false, canSort = false } = props;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: step.id });

  const containerStyle = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const dragHandleStyle: CSSProperties = {
    cursor: 'move',
    touchAction: 'none'
  };

  const getDependenceLabel = (questionName: string, answer: string) => {
    return answer?.length > 0 ? `${questionName} = ${answer}` : questionName;
  };

  return (
    <Root ref={setNodeRef} sx={sx} className={className} style={{ ...style, ...containerStyle }} variant="outlined">
      {step.isLocked && canEdit && (
        <MUI.Box className="lockedHeader">
          <MUI.Typography variant="caption">
            {getDependenceLabel(step.dependantQuestionName, step.dependantQuestionAnswer)}
          </MUI.Typography>
        </MUI.Box>
      )}
      <MUI.Box
        className={clsx('box', step.isLocked && canEdit && 'locked')}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        {renderParticipantsIcon(step.participants, localizationService)}

        <MUI.Box flex={1}>
          <OptionalRouterLink
            underline="none"
            disabled={!step.canNavigate}
            to={reactRouterRouteService.resolveLocation(RouteTemplates.manageOnboardingStep, [
              { name: RouteParamNames.configId, value: step.configId },
              {
                name: RouteParamNames.processName,
                value: step.processName
              },
              {
                name: RouteParamNames.templateName,
                value: step.templateName
              }
            ])}
          >
            <OnboardingTypographySelector
              className="title"
              variant="h6"
              texts={step.title}
              variableResolver={step.variableResolver}
            />
          </OptionalRouterLink>
        </MUI.Box>

        <OnboardingOwnership
          size="small"
          variant="outlined"
          participants={step.participants}
          agent={step.agent}
          client={step.client}
          followers={step.followers}
        />

        <OnboardingStatusChip
          size="small"
          disabled
          status={step.status}
          isStepStatus
          isBlocked={step.isBlocked}
          targetDate={step.targetDate}
        />

        {canEdit && (
          <MUI.IconButton
            className="deleteButton"
            onClick={(e) => {
              e.stopPropagation();
              void step.deleteStep();
            }}
          >
            <DeleteIcon />
          </MUI.IconButton>
        )}
        {canSort && <ReorderIcon {...attributes} {...listeners} style={dragHandleStyle} />}
      </MUI.Box>
    </Root>
  );
});

function renderParticipantsIcon(participant: OnboardingParticipantKind, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  switch (participant) {
    case 'studyo-only':
      return (
        <MUI.Tooltip title={strings.participantStudyoTooltip}>
          <StudyoParticipantIcon className="participantIcon" fontSize="small" color="disabled" />
        </MUI.Tooltip>
      );
    case 'client-only':
      return (
        <MUI.Tooltip title={strings.participantClientTooltip}>
          <ClientParticipantIcon className="participantIcon" fontSize="small" color="secondary" />
        </MUI.Tooltip>
      );
    case 'studyo-and-client':
      return (
        <MUI.Tooltip title={strings.participantStudyoAndClientTooltip}>
          <BothParticipantsIcon className="participantIcon" fontSize="small" color="secondary" />
        </MUI.Tooltip>
      );
  }
}

const Root = styled(MUI.Paper)(({ theme }) => ({
  '.box': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  '.lockedHeader': {
    backgroundColor: yellow[100],
    marginLeft: 44,
    padding: theme.spacing(1),
    display: 'inline-flex'
  },
  '.locked': {
    backgroundColor: yellow[100]
  },
  '.statusIcon': {
    margin: theme.spacing(1)
  },
  '.title': {
    margin: theme.spacing(1)
  },
  '.participantIcon': {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0.5)
  },
  '.participantChip': {
    marginRight: theme.spacing(1),
    borderColor: grey[500],
    color: grey[500]
  },
  '.deleteButton': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));
