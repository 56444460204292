import { ImportSessionDataListViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import ConcatenationIcon from '@mui/icons-material/Layers';
import PlayIcon from '@mui/icons-material/PlayArrowRounded';
import ReorderHeadersIcon from '@mui/icons-material/Sort';
import TransformationIcon from '@mui/icons-material/Transform';
import ViewIcon from '@mui/icons-material/ViewList';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { MoreActionMenu } from '../MoreActionMenu';
import { Container } from '../layout';

export interface ImportSessionDataListProps {
  viewModel: ImportSessionDataListViewModel;
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionDataList = observer((props: ImportSessionDataListProps) => {
  const { localizationService } = useInsightsServices();
  const { style, className, viewModel, sx } = props;
  const strings = localizationService.localizedStrings.insights.components.import;
  const navigate = useNavigate();

  return (
    <Container sx={sx} className={className} style={style}>
      <MUI.Box flexDirection="column">
        <MUI.CardHeader
          title={strings.data}
          action={
            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
              <MUI.Tooltip title={strings.addConcatenationTooltip}>
                <MUI.IconButton onClick={() => void viewModel.addConcatenation()}>
                  <ConcatenationIcon />
                </MUI.IconButton>
              </MUI.Tooltip>
              <MUI.Tooltip title={strings.addTransformationTooltip}>
                <MUI.IconButton onClick={() => void viewModel.addTransformation()}>
                  <TransformationIcon />
                </MUI.IconButton>
              </MUI.Tooltip>
            </AuthorizationRoleCondition>
          }
        />

        <MUI.CardContent>
          {viewModel.data.map(({ data, file, concatenation, transformation }, index) => {
            const hasSchema = data.targetSchema.length > 0;
            const columnCount = data.rows[0]?.columns.length ?? data.columnNames.length;
            const globalErrors = data.incidents.map((i) => `${i.message} (${i.code})`);
            const hasGlobalErrors = globalErrors.length > 0;

            // Dim transformations without schemas.
            const iconColor = transformation?.targetSchema.length === 0 ? 'disabled' : 'primary';
            const titleColor = transformation?.targetSchema.length === 0 ? 'textSecondary' : 'textPrimary';

            return (
              <React.Fragment key={`data-row-${data.label}-${data.targetSchema}`}>
                <MUI.Box my={1} flexDirection="row" display="flex" alignItems="flex-start">
                  {(file && <FileIcon sx={{ mr: 1 }} color={iconColor} />) ??
                    (concatenation && <ConcatenationIcon sx={{ mr: 1 }} color={iconColor} />) ??
                    (transformation && <TransformationIcon sx={{ mr: 1 }} color={iconColor} />)}

                  <MUI.Typography sx={{ mr: 2 }} variant="subtitle1" color={titleColor} fontWeight="700">
                    {data.label}
                  </MUI.Typography>

                  <MUI.Box flex={1} px={1}>
                    <MUI.Box display="flex" flexDirection="row" alignItems="center">
                      <MUI.Typography variant="subtitle1" color={titleColor}>
                        {file?.name ?? concatenation?.name ?? transformation?.name}
                      </MUI.Typography>
                      {data.hashChangedSinceLastImport && (
                        <MUI.Typography variant="caption" color="secondary">
                          &nbsp;{strings.hashChanged}
                        </MUI.Typography>
                      )}
                      {hasGlobalErrors && (
                        <MUI.Tooltip title={globalErrors.join('\n')}>
                          <ErrorIcon sx={{ ml: 1 }} fontSize="small" />
                        </MUI.Tooltip>
                      )}
                    </MUI.Box>
                    <MUI.Typography variant="body1" color="textSecondary">
                      {file?.description ?? transformation?.description}
                    </MUI.Typography>
                    {transformation && (
                      <MUI.Typography variant="subtitle2" color="textSecondary">
                        {strings.transformationSourceAndIndexes(
                          transformation.sourceLabel,
                          transformation.indexedSources
                        )}
                      </MUI.Typography>
                    )}
                    <MUI.Typography variant="subtitle2" color="textSecondary">
                      {strings.targetSchemaValue(data.targetSchema)}
                      {data.lastImportTime != null && strings.lastImported(data.lastImportTime)}
                    </MUI.Typography>
                    <MUI.Typography variant="subtitle2" color="textSecondary" style={{ fontStyle: 'italic' }}>
                      {strings.columnAndRowCount(columnCount, data.rows.length)}&nbsp;-&nbsp;
                      {strings.renderingTime(Number(data.renderingTimeMilliseconds))}
                    </MUI.Typography>
                  </MUI.Box>

                  <MoreActionMenu>
                    <MUI.MenuItem onClick={() => void viewModel.viewData(data, navigate)}>
                      <MUI.ListItemIcon>
                        <ViewIcon />
                      </MUI.ListItemIcon>
                      <MUI.ListItemText>{strings.viewData}</MUI.ListItemText>
                    </MUI.MenuItem>

                    {file && (
                      <MUI.MenuItem onClick={() => void viewModel.editOrderedHeaders(file)}>
                        <MUI.ListItemIcon>
                          <ReorderHeadersIcon />
                        </MUI.ListItemIcon>
                        <MUI.ListItemText>{strings.editOrderedHeaders}</MUI.ListItemText>
                      </MUI.MenuItem>
                    )}

                    {hasSchema && (
                      <MUI.MenuItem onClick={() => void viewModel.testData(data, navigate)}>
                        <MUI.ListItemIcon>
                          <PlayIcon />
                        </MUI.ListItemIcon>
                        <MUI.ListItemText>{strings.testData}</MUI.ListItemText>
                      </MUI.MenuItem>
                    )}

                    <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                      {concatenation && (
                        <MUI.MenuItem onClick={() => void viewModel.editConcatenationOptions(concatenation)}>
                          <MUI.ListItemIcon>
                            <EditIcon />
                          </MUI.ListItemIcon>
                          <MUI.ListItemText>{strings.editConcatenationOptions}</MUI.ListItemText>
                        </MUI.MenuItem>
                      )}
                      {concatenation && (
                        <MUI.MenuItem onClick={() => void viewModel.removeConcatenation(concatenation)}>
                          <MUI.ListItemIcon>
                            <DeleteIcon />
                          </MUI.ListItemIcon>
                          <MUI.ListItemText>{strings.removeConcatenation}</MUI.ListItemText>
                        </MUI.MenuItem>
                      )}
                      {transformation && (
                        <MUI.MenuItem onClick={() => void viewModel.editTransformationOptions(transformation)}>
                          <MUI.ListItemIcon>
                            <EditIcon />
                          </MUI.ListItemIcon>
                          <MUI.ListItemText>{strings.editTransformationOptions}</MUI.ListItemText>
                        </MUI.MenuItem>
                      )}
                      {transformation && (
                        <MUI.MenuItem onClick={() => void viewModel.editTransformationRecipe(transformation, navigate)}>
                          <MUI.ListItemIcon>
                            <TransformationIcon />
                          </MUI.ListItemIcon>
                          <MUI.ListItemText>{strings.editTransformationRecipe}</MUI.ListItemText>
                        </MUI.MenuItem>
                      )}
                      {transformation && (
                        <MUI.MenuItem onClick={() => void viewModel.removeTransformation(transformation)}>
                          <MUI.ListItemIcon>
                            <DeleteIcon />
                          </MUI.ListItemIcon>
                          <MUI.ListItemText>{strings.removeTransformation}</MUI.ListItemText>
                        </MUI.MenuItem>
                      )}
                    </AuthorizationRoleCondition>
                  </MoreActionMenu>
                </MUI.Box>

                {index < viewModel.data.length - 1 && <MUI.Divider />}
              </React.Fragment>
            );
          })}
        </MUI.CardContent>
      </MUI.Box>
    </Container>
  );
});
