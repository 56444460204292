import { ObservablePresenter, SessionsPerDayChart, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { MinMediumChartHeight, StudentsColor, TeachersColor } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SessionsPerDayDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  accountId: string;
  accountType: 'student' | 'teacher';
}

export const SessionsPerDayDialog = observer((props: SessionsPerDayDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, accountType, style, accountId, configId, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sessionsPerDayDialog;
  const accountStrings =
    accountType === 'student'
      ? localizationService.localizedStrings.insights.views.metrics.students
      : localizationService.localizedStrings.insights.views.metrics.teachers;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createDailyAccountSessions(configId, accountId)
  );
  const color = accountType === 'student' ? StudentsColor : TeachersColor;

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        scroll="paper"
        onClose={() => onSuccess!()}
        className={className}
        style={style}
      >
        <MUI.DialogContent sx={{ height: MinMediumChartHeight }}>
          <ObservablePresenter
            sx={{ width: '100%', height: '100%' }}
            data={viewModel.data}
            loadingMessage={strings.loadingMessage}
            errorMessage={strings.loadingErrorMessage}
            render={(data) => {
              if (data.length === 0) {
                return (
                  <MUI.Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <MUI.Typography variant="h5">{accountStrings.sessionsPerDayEmptyMessage}</MUI.Typography>
                  </MUI.Box>
                );
              }

              return (
                <SessionsPerDayChart
                  caption={accountStrings.sessionsPerDay}
                  subcaption={accountStrings.sessionsPerDaySubcaption}
                  sessions={data}
                  color={color}
                />
              );
            }}
          />
        </MUI.DialogContent>

        <MUI.DialogActions>
          <MUI.Button variant="outlined" onClick={() => onSuccess!()}>
            {strings.closeButtonCaption}
          </MUI.Button>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
