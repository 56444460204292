import { LayoutType, ObservablePresenter, ValuePresenter } from '@insights/components';
import { ConfigGlobalStatsViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { MinLayoutWidth, MinTinyChartHeight, TeachersColor } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface TeacherAccountsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: ConfigGlobalStatsViewModel;
}

export const TeacherAccounts = observer((props: TeacherAccountsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.teachers;

  return (
    <MUI.Box sx={{ ...sx, height: MinTinyChartHeight, minWidth: MinLayoutWidth }} className={className} style={style}>
      <ObservablePresenter
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        data={viewModel.data}
        indicatorsSize="small"
        loadingMessage={strings.loadingTeacherAccountsMessage}
        errorMessage={strings.loadingTeacherAccountsErrorMessage}
        render={(data) => (
          <ValuePresenter
            caption={strings.teachers}
            subcaption={strings.numberOfConnectedTeachers}
            maximum={data.accounts.teacherTotal}
            value={data.accounts.teacherClaimed}
            valueColor={TeachersColor}
            layoutType={LayoutType.Horizontal}
            tooltipText={`
                <table>
                  <tr>
                    <td style='font-weight: 500; text-align: right;'>${data.accounts.teacherClaimed}</td>
                    <td>${strings.connectedTeachers}</td>
                  </tr>
                  <tr>
                    <td style='font-weight: 500; text-align: right;'>${data.accounts.teacherTotal}</td>
                    <td>${strings.teachersTotal}</td>
                  </tr>
                </table>
              `}
          />
        )}
      />
    </MUI.Box>
  );
});
