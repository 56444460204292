import { SchoolCalendarTermViewModel } from '@insights/viewmodels';
import { SxProps, TextField } from '@mui/material';
import { Day } from '@shared/models/types';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Row } from '../layout';

export interface EditableTermDatesProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarTermViewModel;
}

export const EditableTermDates = observer((props: EditableTermDatesProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, style, sx, className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Row sx={sx} className={className} style={style}>
      <TextField
        type="date"
        required
        label={strings.startDate}
        value={viewModel.startDay.asDateString}
        onChange={(event) => {
          const day = Day.fromDateString(event.target.value);
          if (day != null) {
            viewModel.startDay = day;
          }
        }}
        sx={{ mr: 1 }}
      />
      <TextField
        type="date"
        required
        label={strings.endDate}
        value={viewModel.endDay.asDateString}
        onChange={(event) => {
          const day = Day.fromDateString(event.target.value);
          if (day != null) {
            viewModel.endDay = day;
          }
        }}
        sx={{ mr: 1 }}
      />
    </Row>
  );
});
