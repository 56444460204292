import { SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface MicrosoftTeamsCustomClassesFilterProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  filter: string;
  onChange: (value: string) => void;
}

export const MicrosoftTeamsCustomClassesFilter = observer((props: MicrosoftTeamsCustomClassesFilterProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, filter, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <TextField
      sx={sx}
      className={className}
      label={strings.microsoftTeamsCustomClassesFilterLabel}
      value={filter}
      onChange={(event) => onChange(event.target.value)}
      fullWidth
    />
  );
});
