import { css } from '@emotion/css';
import { LightInsightsMuiTheme } from '@insights/theme';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import * as MUI from '@mui/material';
import { SxProps, createTheme, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AccountModel } from '@shared/models/config';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';
import { useViewModelRef } from '../../../components';

export interface WorkloadManagerStudentListDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  students: AccountModel[];
}

export const WorkloadManagerStudentListDialog = observer((props: WorkloadManagerStudentListDialogProps) => {
  const { localizationService, settingsStore, viewModelFactory } = useInsightsServices();
  const { sx, className, style, students, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createWorkloadManagerStudentListDialog(students, onSuccess!, onCancel!)
  );

  const theme = useTheme();
  const contentClassName = css({ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) });

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
        onClose={() => viewModel.close()}
        className={className}
        style={style}
      >
        <MUI.DialogTitle>{strings.localizedNumberOfStudents(viewModel.students.length)}</MUI.DialogTitle>

        <MUI.DialogContent dividers classes={{ root: contentClassName }}>
          <MUI.List disablePadding>
            {viewModel.students.map((s, index, items) => (
              <React.Fragment key={s.id}>
                <MUI.ListItem disableGutters>
                  <MUI.ListItemText
                    primary={AccountUtils.getDisplayLastFirstName(s)}
                    secondary={
                      <MUI.Link target="_top" href={`mailto:${s.email}`}>
                        {s.email}
                      </MUI.Link>
                    }
                  ></MUI.ListItemText>
                  {!settingsStore.isEmbedded && (
                    <MUI.ListItemSecondaryAction>
                      <MUI.Tooltip title={strings.viewPlanner}>
                        <MUI.IconButton edge="end" onClick={() => void viewModel.viewPlanner(s.id)}>
                          <OpenInNewIcon />
                        </MUI.IconButton>
                      </MUI.Tooltip>
                    </MUI.ListItemSecondaryAction>
                  )}
                </MUI.ListItem>
                {index < items.length - 1 && <MUI.Divider />}
              </React.Fragment>
            ))}
          </MUI.List>
        </MUI.DialogContent>

        <MUI.DialogActions>
          <MUI.Button variant="outlined" onClick={() => viewModel.close()}>
            {strings.close}
          </MUI.Button>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
