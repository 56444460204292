import {
  AuthorizationRoleCondition,
  CurrentPageInfo,
  ObservablePresenter,
  PageFooter,
  PageHeaderBar,
  PageHeaderDetailBar,
  SectionList,
  WeekPagingNavigation
} from '@insights/components';
import FilterIcon from '@mui/icons-material/FilterList';
import NotesIcon from '@mui/icons-material/NoteAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { AdminAuthorizationRoles } from '@shared/models/types';
import { observer } from 'mobx-react';
import { CSSProperties, Fragment, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';
import { PublishedTasksBySection } from './PublishedTasksBySection';
import { SectionsCourseOccurrencesStatus } from './SectionsCourseOccurrencesStatus';
import { TeacherSessionsPerDay } from './TeacherSessionsPerDay';
import { WorkloadManagerBySection } from './WorkloadManagerBySection';

export interface TeacherDetailsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const TeacherDetails = observer((props: TeacherDetailsProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.teachers;

  const params = useParams();
  const configId = params.configId ?? '';
  const teacherId = params.teacherId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createTeacherDetails(configId, teacherId), [configId, teacherId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Teacher Details' });
  });

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="column">
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
          <MUI.Button onClick={() => void viewModel.navigateToPlanner()}>
            <MUI.Box display="flex" flexDirection="row" alignItems="center">
              <MUI.Typography>{strings.viewPlanner}</MUI.Typography>

              <OpenInNewIcon sx={{ ml: 0.5 }} color="action" />
            </MUI.Box>
          </MUI.Button>
        </AuthorizationRoleCondition>
      </PageHeaderBar>

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ height: '100%', width: '100%' }}
          data={viewModel.data}
          loadingMessage={strings.loadingTeacherDetailsMessage}
          errorMessage={strings.loadingTeacherDetailsErrorMessage}
          render={(data) => (
            <MUI.Box display="flex" flexDirection="column" height="100%">
              {/* Teacher Info */}
              <PageHeaderDetailBar>
                <MUI.Box display="flex" flexDirection="row" alignItems="center">
                  {/* Teacher name and email */}
                  <MUI.Box>
                    <MUI.Typography sx={{ fontSize: '2em', lineHeight: 'normal', fontWeight: 300 }}>
                      {AccountUtils.getDisplayLastFirstName(data.accountInfo.account, strings.noName)}
                    </MUI.Typography>
                    <MUI.Box display="flex">
                      <MUI.Link variant="body1" target="_top" href={`mailto:${data.accountInfo.account.email}`}>
                        {data.accountInfo.account.email}
                      </MUI.Link>
                      {data.accountInfo.account.profile.publicEmail.length > 0 && (
                        <MUI.Link
                          sx={{ ml: 2 }}
                          variant="body1"
                          target="_top"
                          href={`mailto:${data.accountInfo.account.profile.publicEmail}`}
                        >
                          ✉️&nbsp;{data.accountInfo.account.profile.publicEmail}
                        </MUI.Link>
                      )}
                    </MUI.Box>
                  </MUI.Box>

                  <MUI.Box flex={1} />

                  {viewModel.exports.canExportNotes && (
                    <MUI.Tooltip title={strings.exportNotesTooltip}>
                      <MUI.IconButton sx={{ marginRight: 2 }} onClick={() => void viewModel.exports.exportNotes()}>
                        <NotesIcon />
                      </MUI.IconButton>
                    </MUI.Tooltip>
                  )}

                  <MUI.Tooltip title={strings.filterButtonTooltip}>
                    <MUI.IconButton onClick={() => void viewModel.showFilters()}>
                      <MUI.Badge badgeContent={data.filterCount} color="primary" showZero={false} variant="dot">
                        <FilterIcon />
                      </MUI.Badge>
                    </MUI.IconButton>
                  </MUI.Tooltip>
                </MUI.Box>
              </PageHeaderDetailBar>

              {/* Content */}
              <MUI.Box flex={1} overflow="auto" padding={2}>
                <MUI.Grid container alignItems="stretch">
                  {/* Daily Account Sessions */}
                  <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                    <MUI.Grid item xs={12}>
                      <MUI.Card>
                        <TeacherSessionsPerDay
                          sx={{ height: '100%', width: '100%' }}
                          viewModel={viewModelFactory.createDailyAccountSessions(
                            viewModel.configId,
                            viewModel.teacherId
                          )}
                        />
                      </MUI.Card>
                    </MUI.Grid>
                  </AuthorizationRoleCondition>

                  {data.accountInfo.sections && (
                    <Fragment>
                      {/* Course Occurrences Status */}
                      <MUI.Grid item xs={12}>
                        <MUI.Card>
                          <SectionsCourseOccurrencesStatus
                            sx={{ height: '100%', width: '100%' }}
                            viewModel={viewModelFactory.createSectionsCourseOccurrencesStatus(
                              viewModel.configId,
                              data.displayedSectionIds,
                              viewModel.pagination
                            )}
                            displaySectionName={true}
                            displayWeekNavigation={false}
                          />
                        </MUI.Card>
                      </MUI.Grid>

                      {/* Published Tasks */}
                      <MUI.Grid item xs={12}>
                        <MUI.Card>
                          <PublishedTasksBySection
                            sx={{ height: '100%', width: '100%' }}
                            viewModel={viewModelFactory.createPublishedTasksBySection(
                              viewModel.configId,
                              data.displayedSectionIds,
                              viewModel.pagination
                            )}
                            displaySectionName={true}
                            displayWeekNavigation={false}
                          />
                        </MUI.Card>
                      </MUI.Grid>

                      {/* Workload Manager */}
                      <MUI.Grid item xs={12}>
                        <MUI.Card>
                          <WorkloadManagerBySection
                            sx={{ height: '100%', width: '100%' }}
                            viewModel={viewModelFactory.createWorkloadManagerBySection(
                              viewModel.configId,
                              data.displayedSectionIds,
                              viewModel.pagination
                            )}
                            displaySectionName={true}
                            displayWeekNavigation={false}
                          />
                        </MUI.Card>
                      </MUI.Grid>

                      {/* Sections */}
                      {data.accountInfo.sections.sections.length > 0 && (
                        <MUI.Grid item xs={12}>
                          <MUI.Card>
                            <SectionList
                              sx={{ height: '100%', width: '100%' }}
                              title={strings.taughtSections}
                              sections={data.accountInfo.sections}
                              usePagination={false}
                              configId={viewModel.configId}
                            />
                          </MUI.Card>
                        </MUI.Grid>
                      )}
                    </Fragment>
                  )}
                </MUI.Grid>
              </MUI.Box>

              <PageFooter dense>
                <MUI.Box display="flex" flexDirection="row" alignItems="center">
                  <CurrentPageInfo pagination={viewModel.pagination} />

                  <MUI.Box flex={1} />

                  <WeekPagingNavigation pagination={viewModel.pagination} />
                </MUI.Box>
              </PageFooter>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
