import { AutomatedTransformationImportViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import * as MUI from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface AcceptedIncidentListProps {
  sx?: MUI.SxProps;
  className?: string;
  viewModel: AutomatedTransformationImportViewModel;
}

export const AcceptedIncidentList = observer(({ sx, className, viewModel }: AcceptedIncidentListProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <MUI.Table sx={sx} className={className}>
      <MUI.TableHead>
        <MUI.TableRow>
          <MUI.TableCell>{strings.incidentCodeColumn}</MUI.TableCell>
          <MUI.TableCell>{strings.incidentMinCountColumn}</MUI.TableCell>
          <MUI.TableCell>{strings.incidentMaxCountColumn}</MUI.TableCell>
          <MUI.TableCell>{strings.incidentTextColumn}</MUI.TableCell>
          <MUI.TableCell />
        </MUI.TableRow>
      </MUI.TableHead>
      <MUI.TableBody>
        {viewModel.transformationImport.incidentTolerances.map((t, index) => (
          <MUI.TableRow key={`incident-tolerance-${index}`}>
            <MUI.TableCell>
              <MUI.Autocomplete
                sx={{ minWidth: 200 }}
                fullWidth
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={viewModel.incidents}
                getOptionLabel={(v) => (typeof v === 'string' ? v : `${v.code}: ${v.message}`)}
                value={t.code}
                onChange={(_, v) => (t.code = typeof v === 'string' ? v : v?.code ?? '')}
                onInputChange={(_, v) => {
                  const match = viewModel.incidents.find((i) => i.code === v);

                  if (match != null) {
                    t.code = match.code;
                  }
                }}
                renderInput={(p) => <MUI.TextField {...p} />}
              />
            </MUI.TableCell>
            <MUI.TableCell>
              <MUI.TextField
                type="number"
                value={t.minimumCount}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (!Number.isNaN(value)) {
                    t.minimumCount = value;
                  }
                }}
              />
            </MUI.TableCell>
            <MUI.TableCell>
              <MUI.TextField
                type="number"
                value={t.maximumCount}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (!Number.isNaN(value)) {
                    t.maximumCount = value;
                  }
                }}
              />
            </MUI.TableCell>
            <MUI.TableCell>
              <MUI.TextField value={t.incidentText} onChange={(e) => (t.incidentText = e.target.value)} />
            </MUI.TableCell>
            <MUI.TableCell>
              <MUI.IconButton onClick={() => viewModel.deleteIncidentTolerance(t)}>
                <DeleteIcon />
              </MUI.IconButton>
            </MUI.TableCell>
          </MUI.TableRow>
        ))}
      </MUI.TableBody>
      <MUI.TableFooter>
        <MUI.TableRow>
          <MUI.TableCell colSpan={4} />
          <MUI.TableCell>
            <MUI.IconButton onClick={() => viewModel.addIncidentTolerance()}>
              <AddIcon />
            </MUI.IconButton>
          </MUI.TableCell>
        </MUI.TableRow>
      </MUI.TableFooter>
    </MUI.Table>
  );
});
