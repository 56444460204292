import { AccountInfo, accountInfoFromModel } from '@insights/models';
import { AlertService } from '@insights/services';
import {
  AccountModel,
  EditableSchoolYearConfiguration,
  EditableSection,
  EditableSectionSchedule,
  SchoolYearConfigurationModel,
  SectionModel
} from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { isDemoError } from '@shared/services/stores/implementations/DemoSchoolInterceptor';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface LoadingSectionTeachersEditionViewModel {
  readonly configId: string;
  readonly sectionId: string;

  readonly data: IPromiseBasedObservable<SectionTeachersEditionViewModel>;

  close(): void;
}

export interface SectionTeachersEditionViewModel {
  readonly section: SectionModel;
  readonly defaultTeacher: AccountModel | undefined;
  readonly selectedTeachers: AccountInfo[];
  readonly availableTeachers: AccountInfo[];
  // Teachers that teach all schedules
  readonly allSchedulesTeacherIds: Set<string>;

  readonly hasChanges: boolean;
  readonly isSaving: boolean;

  addTeacher(teacher: AccountModel): void;
  removeTeacher(teacher: AccountModel): void;
  setDefaultTeacher(teacherId: string): void;

  apply(): Promise<void>;
}

export class AppLoadingSectionTeachersEditionViewModel implements LoadingSectionTeachersEditionViewModel {
  constructor(
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    public readonly configId: string,
    public readonly sectionId: string,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<SectionTeachersEditionViewModel> {
    return fromPromise(this.loadData());
  }

  close() {
    this._onCancel();
  }

  private async loadData(): Promise<SectionTeachersEditionViewModel> {
    const [section, teachers, config] = await Promise.all([
      this._schoolYearConfigurationStore.getSection(this.configId, this.sectionId),
      this._schoolYearConfigurationStore.getTeachers(this.configId, false /* exclude deleted teachers */),
      this._schoolYearConfigurationStore.getConfig(this.configId)
    ]);

    return new AppSectionTeachersEditionViewModel(
      this._alertService,
      this._localizationService,
      this._schoolYearConfigurationStore,
      teachers,
      this._onSuccess,
      config,
      section
    );
  }
}

export class AppSectionTeachersEditionViewModel implements SectionTeachersEditionViewModel {
  private readonly _editableConfig: EditableSchoolYearConfiguration;
  private readonly _editableSection: EditableSection;
  @observable private _isSaving = false;

  constructor(
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _teachers: AccountModel[],
    private readonly _onSuccess: () => void,
    config: SchoolYearConfigurationModel,
    section: SectionModel
  ) {
    makeObservable(this);
    this._editableConfig = new EditableSchoolYearConfiguration(config);
    this._editableSection = this._editableConfig.getEditableSection(section)!;
  }

  @computed
  get section(): SectionModel {
    return this._editableSection;
  }

  @computed
  get defaultTeacher(): AccountModel | undefined {
    return this.teachersById[this.section.defaultTeacherId];
  }

  @computed
  get selectedTeacherModels(): AccountModel[] {
    return _.compact(this.selectedTeacherIds.map((id) => this.teachersById[id]));
  }

  @computed
  get selectedTeachers(): AccountInfo[] {
    return this.selectedTeacherModels.map(accountInfoFromModel);
  }

  @computed
  get availableTeacherModels(): AccountModel[] {
    return this._teachers.filter((t) => !this.selectedTeacherIdsSet.has(t.id));
  }

  @computed
  get availableTeachers(): AccountInfo[] {
    return this.availableTeacherModels.map(accountInfoFromModel);
  }

  @computed
  get allSchedulesTeacherIds(): Set<string> {
    return new Set(
      this.selectedTeacherModels
        .filter((teacher) => this.section.schedules.every((schedule) => schedule.teacherIds.includes(teacher.id)))
        .map((teacher) => teacher.id)
    );
  }

  @computed
  get hasChanges(): boolean {
    return this._editableConfig.hasChanges;
  }

  @computed
  get isSaving(): boolean {
    return this._isSaving;
  }

  @action
  async addTeacher(teacher: AccountModel) {
    if (this.section.defaultTeacherId.length === 0) {
      this._editableSection.defaultTeacherId = teacher.id;
    }

    if (this.section.schedules.length === 0) {
      // Only create this fake schedule if the added teacher is not the default teacher.
      if (this._editableSection.defaultTeacherId !== teacher.id) {
        await this._alertService.showMessage({
          message: this._localizationService.localizedStrings.insights.views.metrics.sections.noScheduleMessage
        });

        // Creating empty schedule if none exists. Using 🚫 as period tag to make sure it doesn't show in the teachers'
        // schedule. Also adding the default teacher to prevent a warning that the teacher is not associated
        // to any schedule.
        const schedule = EditableSectionSchedule.createNew(this._editableSection.defaultTeacherId, undefined, '🚫');
        schedule.teacherIds = schedule.teacherIds.concat(teacher.id);
        this._editableSection.addSchedule(schedule);
      }
    } else {
      this._editableSection.editableSchedules.forEach(
        (schedule) => (schedule.teacherIds = _.uniq(schedule.teacherIds.concat(teacher.id)))
      );
    }
  }

  @action
  removeTeacher(teacher: AccountModel) {
    if (this.section.defaultTeacherId === teacher.id) {
      this._editableSection.defaultTeacherId = '';
    }

    if (this.scheduleTeacherIdsSet.has(teacher.id)) {
      this._editableSection.editableSchedules.forEach(
        (schedule) => (schedule.teacherIds = schedule.teacherIds.filter((id) => id !== teacher.id))
      );
    }
  }

  @action
  setDefaultTeacher(teacherId: string): void {
    this._editableSection.defaultTeacherId = teacherId;
  }

  @action
  async apply() {
    if (!this.hasChanges) {
      console.error('Invalid operation: It should not be possible to call apply without changes.');
      return;
    }

    this._isSaving = true;

    try {
      await this._schoolYearConfigurationStore.saveConfig(this._editableConfig);
      this._onSuccess();
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.edit;
      await this._alertService.showMessage({
        message: isDemoError(error as Error) ? strings.demoErrorMessage(error as Error) : strings.saveErrorMessage
      });
    } finally {
      runInAction(() => (this._isSaving = false));
    }
  }

  @computed
  private get teachersById(): Record<string, AccountModel> {
    return _.keyBy(this._teachers, (t) => t.id);
  }

  @computed
  private get scheduleTeacherIdsSet(): Set<string> {
    return new Set(
      /**
       * Calculate the value instead of using this._editableSection.teacherIds
       * because since it's lazy, the values don't get updated
       */
      _.uniq(_.flatMap(this.section.schedules.map((schedule) => schedule.teacherIds)))
    );
  }

  @computed
  private get selectedTeacherIdsSet(): Set<string> {
    return new Set(
      /**
       * Calculate the value instead of using this._editableSection.teacherIds
       * because since it's lazy, the values don't get updated
       */
      _.uniq(
        _.compact(
          _.flatMap(this.section.schedules.map((schedule) => schedule.teacherIds)).concat(this.section.defaultTeacherId)
        )
      )
    );
  }

  @computed
  private get selectedTeacherIds(): string[] {
    // We can rely on scheduleTeacherIdsSet's order but not selectedTeacherIdsSet.
    // If schedules already contain the default id, we want to keep that order.
    // Otherwise, it must appear first.
    const ids = this.scheduleTeacherIdsSet;

    return ids.has(this.section.defaultTeacherId)
      ? Array.from(ids)
      : [this.section.defaultTeacherId, ...Array.from(ids)];
  }
}
