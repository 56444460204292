import { AuthorizationRoleCondition, ObservablePresenter, PageHeaderBar } from '@insights/components';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as MUI from '@mui/material';
import { SxProps, styled } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface FeaturesProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const Features = observer((props: FeaturesProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx, style } = props;
  const strings = localizationService.localizedStrings.insights.views.features;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createFeatures(configId), [configId]);

  return (
    <Root sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 1, height: '100%', width: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingMessage}
          errorMessage={strings.loadingErrorMessage}
          render={(data) => (
            <MUI.Grid container alignItems="stretch">
              <MUI.Grid item xs={12} sm={6}>
                <MUI.Box height="100%" width="100%" display="flex">
                  <MUI.Card sx={{ flex: 1 }} className="card">
                    <MUI.CardHeader
                      title={strings.featureListCardTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                          <MUI.Tooltip title={strings.editFeaturesButtonTooltip}>
                            <MUI.IconButton onClick={() => void viewModel.editFeatures(data.schoolYearConfiguration)}>
                              <EditIcon />
                            </MUI.IconButton>
                          </MUI.Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <MUI.Divider />
                    <MUI.CardContent>
                      <MUI.Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
                        {data.features.map((f) => (
                          <MUI.FormControlLabel
                            key={`feature-${f.feature}`}
                            classes={{
                              root: 'checkboxLabel',
                              label: 'checkboxLabel'
                            }}
                            control={
                              <MUI.Checkbox
                                classes={{ root: 'checkbox' }}
                                readOnly
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                              />
                            }
                            checked={f.isSelected}
                            label={strings.getFeatureLabel(f.feature)}
                            labelPlacement="start"
                          />
                        ))}
                      </MUI.Box>
                    </MUI.CardContent>
                  </MUI.Card>
                </MUI.Box>
              </MUI.Grid>
              <MUI.Grid item xs={12} sm={6}>
                <MUI.Box height="100%" width="100%" display="flex">
                  <MUI.Card sx={{ flex: 1 }} className="card">
                    <MUI.CardHeader
                      title={strings.requiredEntitlementsTitle}
                      action={
                        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                          <MUI.Tooltip title={strings.addEntitlementButtonTooltip}>
                            <MUI.IconButton
                              onClick={() =>
                                void viewModel.addEntitlement(
                                  data.schoolYearConfiguration.id,
                                  data.availableEntitlements
                                )
                              }
                              disabled={data.availableEntitlements.length === 0}
                            >
                              <AddIcon />
                            </MUI.IconButton>
                          </MUI.Tooltip>
                        </AuthorizationRoleCondition>
                      }
                    />
                    <MUI.Divider />
                    <MUI.CardContent>
                      <MUI.Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
                        {data.featureEntitlements.map((e) => (
                          <MUI.Box display="flex" flexDirection="row" alignItems="center" key={e.stripePriceId}>
                            <MUI.Box flex={1}>
                              <MUI.Typography>{strings.getEntitlementLabel(e.entitlement)}</MUI.Typography>
                            </MUI.Box>
                            <MUI.Typography>{e.stripePriceId}</MUI.Typography>
                            <MUI.IconButton
                              className="removeEntitlementButton"
                              onClick={() =>
                                void viewModel.removeEntitlement(data.schoolYearConfiguration.id, e.entitlement)
                              }
                            >
                              <DeleteIcon />
                            </MUI.IconButton>
                          </MUI.Box>
                        ))}
                        {data.featureEntitlements.length == 0 && (
                          <MUI.Typography>{strings.noEntitlements}</MUI.Typography>
                        )}
                      </MUI.Box>
                    </MUI.CardContent>
                  </MUI.Card>
                </MUI.Box>
              </MUI.Grid>
            </MUI.Grid>
          )}
        />
      </MUI.Box>
    </Root>
  );
});

const Root = styled(MUI.Box)(({ theme }) => ({
  '.card': {
    display: 'block'
  },
  '.checkboxLabel': {
    marginLeft: 0,
    flex: 1,
    cursor: 'default !important',
    '&:hover': {
      cursor: 'default !important'
    }
  },
  '.checkbox': {
    backgroundColor: 'transparent !important',
    cursor: 'default !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
      cursor: 'default !important'
    }
  },
  '.removeEntitlementButton': {
    marginLeft: theme.spacing(2)
  }
}));
