import { GoogleAccountViewModel } from '@insights//viewmodels';
import DoneIcon from '@mui/icons-material/DoneOutline';
import { Button, styled, SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsButton } from '../InsightsButton.tsx';
import { Column, Expanded, Row } from '../layout';

export interface GoogleAccountNotConnectedProps {
  sx?: SxProps;
  className?: string;
  viewModel: GoogleAccountViewModel;
}

export const GoogleAccountNotConnected = observer(({ sx, className, viewModel }: GoogleAccountNotConnectedProps) => {
  const { environmentService, imageService, localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.components.connectors;
  const isAuthorized = viewModel.googleAuthCode.length > 0;

  const authorize = () => {
    const client = google.accounts.oauth2.initCodeClient({
      client_id: environmentService.googleClientId,
      scope:
        'profile email https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.coursework.students.readonly',
      ux_mode: 'popup',
      select_account: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      callback: (response: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { error_description, code } = response;

        if (error_description) {
          console.error(error_description);
        } else {
          viewModel.updateGoogleInfo(code as string);
        }
      }
    });

    client.requestCode();
  };

  return (
    <Root sx={sx} className={className}>
      {viewModel.accountId.length === 0 && (
        <Typography color="error" variant="body1" style={{ marginBottom: 8 }}>
          {strings.googleNoAccount}
        </Typography>
      )}

      {viewModel.existingAccount != null && (
        <Typography color="error" variant="body1" style={{ marginBottom: 8 }}>
          {strings.googleExistingAccount(viewModel.existingAccount.email)}
        </Typography>
      )}

      <Column className={'stepSection'} horizontalContentAlignment="stretch">
        <Typography variant="h6">{strings.googleTitle}</Typography>
        <Typography variant="body2" style={{ marginBottom: 8 }}>
          {strings.googleInstructions}
        </Typography>
        <Row>
          <Button className="button" variant="outlined" onClick={authorize}>
            <img style={{ height: 24, width: 24, marginRight: 4 }} src={imageService.studyoImages.generic.googleLogo} />
            <Typography className={'authorizeLabel'}>{strings.googleAuthorizeButton}</Typography>
          </Button>
          {isAuthorized && <DoneIcon fontSize="large" color="secondary" />}
        </Row>
      </Column>
      <Row horizontalContentAlignment="stretch" verticalContentAlignment="center">
        <Typography className={'errors'} noWrap={false} color="error">
          {viewModel.error}
        </Typography>
        <Expanded />
        <InsightsButton className={'button'} onClick={() => viewModel.cancelChanges()}>
          {strings.cancel}
        </InsightsButton>
        <InsightsButton
          className={'button'}
          isDefault
          isDisabled={!isAuthorized}
          onClick={() => void viewModel.applyChanges()}
        >
          {strings.continue}
        </InsightsButton>
      </Row>
    </Root>
  );
});

const Root = styled(Column)(({ theme }) => ({
  '.stepSection': {
    margin: theme.spacing(1)
  },
  '.button': {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  '.authorizeLabel': {
    textTransform: 'uppercase'
  },
  '.errors': {
    margin: theme.spacing(1)
  }
}));
