import { NavigationService, SettingsStore } from '@insights/services';
import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { isEqual } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppPaginatedViewModel, AppPaginationViewModel, PaginatedViewModel } from '../metrics';

export interface WorkloadEmbeddedInfo {
  config: SchoolYearConfigurationSummary;
  teacherTaughtSectionIds: string[];
  displayedSectionIds: string[];
  filterCount: number;
}

export interface WorkloadEmbeddedViewModel extends PaginatedViewModel {
  readonly configId: string;
  readonly teacherId: string;
  readonly data: IPromiseBasedObservable<WorkloadEmbeddedInfo>;

  showFilters: () => Promise<void>;
}

export class AppWorkloadEmbeddedViewModel extends AppPaginatedViewModel implements WorkloadEmbeddedViewModel {
  constructor(
    public readonly configId: string,
    public readonly teacherId: string,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _settingsStore: SettingsStore,
    private readonly _navigationService: NavigationService
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<WorkloadEmbeddedInfo> {
    return fromPromise(this.loadData());
  }

  async showFilters(): Promise<void> {
    const configPreferences = this._settingsStore.getConfigPreferences(this.configId);
    const teacherFilters = await configPreferences.getWorkloadEmbeddedFilters(this.teacherId);

    const result = await this._navigationService.navigateToWorkloadEmbeddedFilters(
      this.configId,
      this.teacherId,
      teacherFilters
    );

    if (result !== 'cancelled' && !isEqual(result, teacherFilters)) {
      await configPreferences.saveWorkloadEmbeddedFilters(this.teacherId, result);
    }
  }

  private async loadData(): Promise<WorkloadEmbeddedInfo> {
    // IMPORTANT: In order to invalidate the data and have MOBX reevaluate the loadData,
    //            we must call this async method before the other ones. This is until we change
    //            approach and use sync stores.
    const configPreferences = this._settingsStore.getConfigPreferences(this.configId);
    const workloadEmbeddedFilters = await configPreferences.getWorkloadEmbeddedFilters(this.teacherId);

    const [config, taughtSections] = await Promise.all([
      this._schoolYearConfigurationStore.getConfigSummary(this.configId),
      this._schoolYearConfigurationStore.getTaughtSectionsForTeacherId(this.configId, this.teacherId)
    ]);

    this.setPagination(new AppPaginationViewModel(config.startDay, config.endDay));

    return {
      config,
      teacherTaughtSectionIds: taughtSections.map((section) => section.id),
      displayedSectionIds: taughtSections
        .filter((section) => workloadEmbeddedFilters.excludedSectionIds.indexOf(section.id) === -1)
        .map((section) => section.id),
      filterCount: workloadEmbeddedFilters.excludedSectionIds.length
    };
  }
}
