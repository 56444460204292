import { AutomatedImportHistoryViewModel } from '@insights/viewmodels';
import SuccessIcon from '@mui/icons-material/Check';
import FailureIcon from '@mui/icons-material/Close';
import PlayIcon from '@mui/icons-material/PlayArrow';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { differenceInMilliseconds, format } from 'date-fns';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';

export interface AutomatedImportHistoryProps {
  viewModel: AutomatedImportHistoryViewModel;
  sx?: SxProps;
  className?: string;
}

export const AutomatedImportHistory = observer((props: AutomatedImportHistoryProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;
  const dateStrings = localizationService.localizedStrings.models.dateFormats;

  return (
    <MUI.Box sx={sx} className={className} flexDirection="column">
      <MUI.CardHeader
        title={strings.automatedImportHistoryTitle}
        action={
          <AuthorizationRoleCondition allowedRoles={['super-admin']}>
            {viewModel.isExecuting ? (
              <MUI.CircularProgress />
            ) : (
              <MUI.Tooltip title={strings.executeAutomatedImportManuallyTooltip}>
                <MUI.IconButton onClick={() => void viewModel.executeManually()}>
                  <PlayIcon />
                </MUI.IconButton>
              </MUI.Tooltip>
            )}
          </AuthorizationRoleCondition>
        }
      />

      <MUI.CardContent>
        {viewModel.history.map((result, index) => (
          <React.Fragment key={`auto-import-history-${result.startTime.toISOString()}`}>
            <MUI.Box my={1} flexDirection="row" display="flex" alignItems="flex-start">
              {result.isFullySuccessful ? (
                <SuccessIcon color="success" sx={{ mr: 2, mt: 1 }} />
              ) : (
                <FailureIcon color="error" sx={{ mr: 2, mt: 1 }} />
              )}

              <MUI.Box flex={1} flexDirection="column" px={1}>
                <MUI.Button
                  sx={{ textTransform: 'none' }}
                  variant="text"
                  onClick={() => void viewModel.viewResultDetails(result)}
                >
                  <MUI.Typography variant="subtitle1">
                    {format(result.startTime, dateStrings.mediumUnabridgedWithLongTime)}&nbsp;
                  </MUI.Typography>
                  <MUI.Typography variant="body2">
                    ({strings.importDuration(differenceInMilliseconds(result.endTime, result.startTime))})
                  </MUI.Typography>
                </MUI.Button>
                {result.transformationResults.length === 0 ? (
                  <MUI.Typography pl={1}>{strings.noAutomatedTransformationImportResults}</MUI.Typography>
                ) : (
                  <MUI.Box pl={1}>
                    {result.transformationResults.map((tr, ti) => (
                      <MUI.Box
                        key={`automated-import-result-${ti}`}
                        flexDirection="row"
                        display="flex"
                        alignItems="flex-start"
                      >
                        {tr.isSuccessful ? (
                          <SuccessIcon fontSize="small" sx={{ mr: 2 }} />
                        ) : (
                          <FailureIcon fontSize="small" sx={{ mr: 2 }} />
                        )}
                        <MUI.Typography variant="caption" fontWeight={700} mr={2}>
                          {tr.label}
                        </MUI.Typography>
                        {tr.isSkipped ? (
                          <MUI.Typography variant="caption">{strings.skippedAutomatedTransformation}</MUI.Typography>
                        ) : (
                          <MUI.Typography variant="caption">
                            {strings.importDuration(differenceInMilliseconds(tr.endTime, tr.startTime))}
                          </MUI.Typography>
                        )}
                      </MUI.Box>
                    ))}
                  </MUI.Box>
                )}
              </MUI.Box>
            </MUI.Box>

            {index < viewModel.history.length - 1 && <MUI.Divider />}
          </React.Fragment>
        ))}
        {viewModel.history.length === 0 && (
          <MUI.Box flexDirection="row" display="flex">
            <MUI.Typography flex={1}>{strings.noAutomatedImports}</MUI.Typography>
          </MUI.Box>
        )}
      </MUI.CardContent>
    </MUI.Box>
  );
});
