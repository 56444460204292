import { css } from '@emotion/css';
import { ManageBacAccountViewModel } from '@insights/viewmodels';
import { SxProps, TextField, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface ManageBacAccountProps {
  sx?: SxProps;
  className?: string;
  viewModel: ManageBacAccountViewModel;
}

export const ManageBacAccount = observer((props: ManageBacAccountProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx, className } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;
  const theme = useTheme();

  const textFieldClassName = css([{ margin: theme.spacing(1) }]);

  return (
    <Column sx={sx} className={className}>
      <TextField
        className={textFieldClassName}
        label={strings.accountName}
        value={viewModel.accountName}
        onChange={(event) => (viewModel.accountName = event.target.value)}
      />
      <TextField
        className={textFieldClassName}
        label={strings.serverUrl}
        value={viewModel.serverUrl}
        onChange={(event) => (viewModel.serverUrl = event.target.value)}
      />
      <TextField
        className={textFieldClassName}
        label={strings.authenticationToken}
        value={viewModel.authToken}
        onChange={(event) => (viewModel.authToken = event.target.value)}
      />
      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
