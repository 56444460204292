import { Chip, SxProps, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import classNames from 'classnames';
import * as React from 'react';
import { WorkloadManagerStudentAtThresholdColor, WorkloadManagerStudentOverThresholdColor } from '../Constants';

export interface CourseOccurrenceInformationChipProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  hasCourseOccurrence: boolean;
  hasPublishedTasks: boolean;
  value: number;
  type?: 'normal' | 'warning' | 'error';
}

export const CourseOccurrenceInformationChip = (props: CourseOccurrenceInformationChipProps) => {
  const { sx, className, style, hasCourseOccurrence, hasPublishedTasks, value, type } = props;

  const courseOccurrenceWithPublishedTasksClassName =
    type === 'error'
      ? 'courseOccurrenceWithPublishedTasksError'
      : type === 'warning'
      ? 'courseOccurrenceWithPublishedTasksWarning'
      : 'courseOccurrenceWithPublishedTasks';

  const noCourseOccurrenceWithPublishedTasksClassName =
    type === 'error'
      ? 'noCourseOccurrenceWithPublishedTasksError'
      : type === 'warning'
      ? 'noCourseOccurrenceWithPublishedTasksWarning'
      : 'noCourseOccurrenceWithPublishedTasks';

  const courseOccurrenceWithoutPublishedTasksClassName =
    type === 'error'
      ? 'courseOccurrenceWithoutPublishedTasksError'
      : type === 'warning'
      ? 'courseOccurrenceWithoutPublishedTasksWarning'
      : 'courseOccurrenceWithoutPublishedTasks';

  const noCourseOccurrenceWithoutPublishedTasksClassName =
    type === 'error'
      ? 'noCourseOccurrenceNoPublishedTasksError'
      : type === 'warning'
      ? 'noCourseOccurrenceNoPublishedTasksWarning'
      : 'noCourseOccurrenceNoPublishedTasks';

  const effectiveClass =
    hasCourseOccurrence && hasPublishedTasks
      ? courseOccurrenceWithPublishedTasksClassName
      : hasPublishedTasks
      ? noCourseOccurrenceWithPublishedTasksClassName
      : hasCourseOccurrence
      ? courseOccurrenceWithoutPublishedTasksClassName
      : noCourseOccurrenceWithoutPublishedTasksClassName;

  const effectiveValue = value !== 0 ? value.toString() : '';

  return (
    <Root
      sx={sx}
      variant="outlined"
      className={classNames(effectiveClass, className)}
      style={style}
      label={effectiveValue}
    />
  );
};

const Root = styled(Chip)(({ theme }) => ({
  minWidth: 60,
  borderWidth: 2,
  borderStyle: 'none',
  // We set !important to override the hover, active and focus style set by the component
  backgroundColor: 'transparent !important',
  // Disable the shadow on click
  boxShadow: 'none !important',
  // This is to disable the transition animation of the background color
  transition: 'none !important',
  // We need to override the cursor so that the host can set
  cursor: 'unset !important',

  '&.chipLabel': {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  '&.courseOccurrenceWithPublishedTasks': {
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: `${grey[300]} !important`,
    borderColor: grey[300],
    borderStyle: 'solid',
    color: theme.palette.getContrastText(grey[300])
  },
  '&.courseOccurrenceWithPublishedTasksWarning': {
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: `white !important`,
    color: theme.palette.text.primary,
    borderColor: 'white',
    borderStyle: 'solid'
  },
  '&.courseOccurrenceWithPublishedTasksError': {
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: `white !important`,
    color: theme.palette.text.primary,
    borderColor: 'white',
    borderStyle: 'solid'
  },
  '&.courseOccurrenceWithoutPublishedTasks': {
    borderStyle: 'solid',
    borderColor: grey[300],
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: 'transparent !important'
  },
  '&.courseOccurrenceWithoutPublishedTasksWarning': {
    borderStyle: 'solid',
    borderColor: 'white',
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: `${WorkloadManagerStudentAtThresholdColor} !important`,
    color: theme.palette.getContrastText(WorkloadManagerStudentAtThresholdColor)
  },
  '&.courseOccurrenceWithoutPublishedTasksError': {
    borderStyle: 'solid',
    borderColor: 'white',
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: `${WorkloadManagerStudentOverThresholdColor} !important`,
    color: theme.palette.getContrastText(WorkloadManagerStudentOverThresholdColor)
  },
  '&.noCourseOccurrenceWithPublishedTasks': {
    borderStyle: 'dashed',
    borderColor: grey[300],
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: 'transparent !important'
  },
  '&.noCourseOccurrenceWithPublishedTasksWarning': {
    borderStyle: 'dashed',
    borderColor: 'white',
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: `${WorkloadManagerStudentAtThresholdColor} !important`,
    color: theme.palette.getContrastText(WorkloadManagerStudentAtThresholdColor)
  },
  '&.noCourseOccurrenceWithPublishedTasksError': {
    borderStyle: 'dashed',
    borderColor: 'white',
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: `${WorkloadManagerStudentOverThresholdColor} !important`,
    color: theme.palette.getContrastText(WorkloadManagerStudentOverThresholdColor)
  },
  '&.noCourseOccurrenceNoPublishedTasks': {
    borderStyle: 'none',
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: 'white',
    color: theme.palette.text.primary
  },
  '&.noCourseOccurrenceNoPublishedTasksWarning': {
    borderStyle: 'none',
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: `${WorkloadManagerStudentAtThresholdColor} !important`,
    color: theme.palette.getContrastText(WorkloadManagerStudentAtThresholdColor)
  },
  '&.noCourseOccurrenceNoPublishedTasksError': {
    borderStyle: 'none',
    // We set !important to override the hover, active and focus style set by the component
    backgroundColor: `${WorkloadManagerStudentOverThresholdColor} !important`,
    color: theme.palette.getContrastText(WorkloadManagerStudentOverThresholdColor)
  }
}));
