// TODO MUI.Box

import { SxProps, Typography } from '@mui/material';
import * as React from 'react';
import { Column } from './layout';

export interface LabeledValuePresenterProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  label: string;
  value: string;
}

export function LabeledValuePresenter({ sx, className, style, label, value }: LabeledValuePresenterProps) {
  return (
    <Column sx={sx} className={className} style={style}>
      <Typography variant="body2" lineHeight="normal" fontWeight="300" color="textSecondary">
        {label}
      </Typography>
      <Typography fontSize="1.5em" lineHeight="normal" fontWeight="300">
        {value}
      </Typography>
    </Column>
  );
}
