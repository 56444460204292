import { PaginationViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

export interface CurrentPageInfoProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  pagination: PaginationViewModel | undefined;
}

export const CurrentPageInfo = observer((props: CurrentPageInfoProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, pagination: currentPageProvider } = props;

  const dateFormats = localizationService.localizedStrings.models.dateFormats;
  const currentPage = currentPageProvider?.currentPage;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <MUI.Typography noWrap component="div">
        {/* Start Day */}
        <MUI.Typography color="textSecondary" variant="body2" display="inline" noWrap>
          {`${currentPage?.startDay.formattedString(dateFormats.dayOfWeek)} `}
        </MUI.Typography>
        <MUI.Typography variant="body1" display="inline" noWrap>
          {`${currentPage?.startDay.formattedString(dateFormats.shortUnabridged)}`}
        </MUI.Typography>

        <MUI.Typography color="textSecondary" display="inline" variant="body1">{` – `}</MUI.Typography>

        {/* End Day */}
        <MUI.Typography color="textSecondary" variant="body2" display="inline" noWrap>
          {`${currentPage?.endDay.formattedString(dateFormats.dayOfWeek)} `}
        </MUI.Typography>
        <MUI.Typography variant="body1" display="inline" noWrap>
          {`${currentPage?.endDay.formattedString(dateFormats.shortUnabridged)}`}
        </MUI.Typography>
      </MUI.Typography>
    </MUI.Box>
  );
});
