import { AccountService, AlertService, NavigationService, SettingsStore } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { OnboardingProcess, OnboardingStepSummary, OnboardingText } from '@shared/models/onboarding/interfaces';
import { OnboardingStatus } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { arrayMoveImmutable } from 'array-move';
import { compact, uniq } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { OnboardingCommentsViewModel } from './OnboardingCommentsViewModel';
import { AppOnboardingStepSummaryViewModel, OnboardingStepSummaryViewModel } from './OnboardingStepSummaryViewModel';
import { DefaultOnboardingVariableResolver, OnboardingVariableResolver } from './OnboardingVariableResolver';

export interface OnboardingProcessViewModel {
  readonly configId: string;

  readonly id: string;
  readonly templateName: string;
  readonly status: OnboardingStatus;
  readonly nextTargetDate: Date | undefined;
  readonly finalTargetDate: Date | undefined;
  readonly description: OnboardingText[];
  readonly steps: OnboardingStepSummaryViewModel[];
  readonly isCustomized: boolean;

  readonly agent: AccountModel | undefined;
  readonly client: AccountModel | undefined;
  readonly followers: AccountModel[];

  readonly commentsViewModel: OnboardingCommentsViewModel;

  readonly canEdit: boolean;
  readonly canSort: boolean;

  readonly variableResolver: OnboardingVariableResolver;

  selectOwners: () => Promise<void>;

  updateStatus(status: OnboardingStatus): Promise<void>;
  deleteProcess(): Promise<void>;
  deleteStep(step: OnboardingStepSummaryViewModel): Promise<void>;
  reorderStep(oldIndex: number, newIndex: number): Promise<void>;
  navigateToAddStep(): Promise<void>;
  navigateToEditProcess(): Promise<void>;
  renameProcess(): Promise<void>;
  copyProcess(): Promise<void>;
}

export class AppOnboardingProcessViewModel implements OnboardingProcessViewModel {
  @observable private _process: OnboardingProcess;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _settings: SettingsStore,
    readonly configId: string,
    private readonly _accountsById: Record<string, AccountModel>,
    process: OnboardingProcess,
    readonly commentsViewModel: OnboardingCommentsViewModel
  ) {
    makeObservable(this);
    this._process = process;
  }

  @computed
  get id() {
    return this._process.id;
  }

  @computed
  get templateName() {
    return this._process.templateName;
  }

  @computed
  get status() {
    return this._process.status;
  }

  @computed
  get nextTargetDate() {
    return this._process.nextTargetDate;
  }

  @computed
  get finalTargetDate() {
    return this._process.finalTargetDate;
  }

  @computed
  get description() {
    return this._process.description;
  }

  @computed
  get steps(): OnboardingStepSummaryViewModel[] {
    const steps = this.isHidingLockedContent
      ? this._process.steps.filter((step) => !step.isDependantLocked)
      : this._process.steps;

    return steps.map(
      (step) =>
        new AppOnboardingStepSummaryViewModel(
          this._accountService,
          this._navigationService,
          this.configId,
          this,
          step,
          this._accountsById
        )
    );
  }

  @computed
  get isCustomized() {
    return this._process.isCustomized;
  }

  @computed
  get agent() {
    return this._accountsById[this._process.agentId];
  }

  @computed
  get client() {
    return this._accountsById[this._process.clientId];
  }

  @computed
  get followers(): AccountModel[] {
    return this._process.followerIds.map((id) => this._accountsById[id]);
  }

  @computed
  get canEdit() {
    return (
      this._accountService.isAllowed(['super-admin']) ||
      this._accountService.isAccount(compact([this._process.agentId]))
    );
  }

  @computed
  get canSort() {
    return this.canEdit && !this.isHidingLockedContent;
  }

  @computed
  get variableResolver() {
    return new DefaultOnboardingVariableResolver(this._process.configId);
  }

  @computed
  private get isHidingLockedContent() {
    return this._settings.onboardingPreferences.hideLockedContent;
  }

  async selectOwners(): Promise<void> {
    const result = await this._navigationService.navigateToSelectOwnership(
      this.configId,
      this._process.templateName,
      this._process.clientId,
      this._process.agentId,
      this._process.followerIds,
      'studyo-and-client',
      true
    );

    if (result !== 'cancelled') {
      try {
        let process = result.isProcessSelected
          ? await this._onboardingStore.updateProcessAssignees(
              this._process.templateName,
              this.configId,
              result.clientId,
              result.agentId,
              result.followerIds
            )
          : this._process;

        if (result.selectedStepNames.length > 0) {
          await Promise.all(
            process.steps
              .filter((step) => result.selectedStepNames.indexOf(step.templateName) != -1)
              .map((step) => this.updateStepOwners(step, result.clientId, result.agentId, result.followerIds))
          );
          // We need to reload the process.
          process = await this._onboardingStore.getProcess(this._process.templateName, this.configId);
        }

        runInAction(() => (this._process = process));
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      }
    }
  }

  @action
  async updateStatus(status: OnboardingStatus): Promise<void> {
    try {
      const process = await this._onboardingStore.updateProcessStatus(
        this._process.templateName,
        this.configId,
        status
      );
      runInAction(() => (this._process = process));
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    }
  }

  @action
  async deleteProcess(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
    const result = await this._alertService.showConfirmation({
      title: strings.confirmDeleteProcessTitle,
      message: strings.confirmDeleteProcessMessage,
      okButtonCaption: strings.deleteButtonLabel
    });

    if (result !== 'cancelled') {
      try {
        await this._onboardingStore.deleteProcess(this._process.templateName, this.configId);
        // This should have invalidated the processes list.
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      }
    }
  }

  @action
  async deleteStep(step: OnboardingStepSummaryViewModel): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
    const result = await this._alertService.showConfirmation({
      title: strings.confirmDeleteStepTitle,
      message: this._process.isCustomized ? strings.confirmDeleteStepMessage : strings.confirmDeleteStepForAllMessage,
      okButtonCaption: strings.deleteButtonLabel
    });

    if (result !== 'cancelled') {
      try {
        // First remove the step from the process.
        const process = await this._onboardingStore.updateProcess(
          this._process.templateName,
          this.configId,
          this._process.description,
          this._process.steps.map((s) => s.templateName).filter((n) => n != step.templateName),
          !this._process.isCustomized
        );

        // Then delete the step.
        await this._onboardingStore.deleteStep(step.templateName, this.configId);

        runInAction(() => (this._process = process));
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      }
    }
  }

  @action
  async reorderStep(oldIndex: number, newIndex: number): Promise<void> {
    try {
      let stepNames = this._process.steps.map((s) => s.templateName);
      stepNames = arrayMoveImmutable(stepNames, oldIndex, newIndex);

      const process = await this._onboardingStore.updateProcess(
        this._process.templateName,
        this.configId,
        this._process.description,
        stepNames,
        !this._process.isCustomized
      );
      runInAction(() => (this._process = process));
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    }
  }

  async navigateToAddStep(): Promise<void> {
    const stepName = await this._navigationService.navigateToAddOnboardingStep(this.configId);

    if (stepName !== 'cancelled') {
      try {
        const stepNames = uniq(this._process.steps.map((s) => s.templateName).concat(stepName));
        const process = await this._onboardingStore.updateProcess(
          this._process.templateName,
          this.configId,
          this._process.description,
          stepNames,
          !this._process.isCustomized
        );

        runInAction(() => (this._process = process));
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      }
    }
  }

  async navigateToEditProcess(): Promise<void> {
    const result = await this._navigationService.navigateToOnboardingProcessEdition(this._process);

    if (result !== 'cancelled') {
      runInAction(() => (this._process = result));
    }
  }

  async renameProcess(): Promise<void> {
    const result = await this._navigationService.navigateToRenameOrCopyOnboardingProcess(
      this._process.templateName,
      this.configId,
      false
    );

    if (result !== 'cancelled') {
      try {
        const process = await this._onboardingStore.renameProcess(this._process.templateName, this.configId, result);

        runInAction(() => (this._process = process));
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      }
    }
  }

  async copyProcess(): Promise<void> {
    const result = await this._navigationService.navigateToRenameOrCopyOnboardingProcess(
      this._process.templateName,
      this.configId,
      true
    );

    if (result !== 'cancelled') {
      try {
        await this._onboardingStore.createOrUpdateProcessTemplate(
          result,
          this._process.description,
          this._process.steps.map((s) => s.templateName)
        );
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      }
    }
  }

  private async updateStepOwners(
    step: OnboardingStepSummary,
    clientId: string | undefined,
    agentId: string | undefined,
    followerIds: string[]
  ) {
    await this._onboardingStore.updateStepAssignees(
      step.templateName,
      this.configId,
      this._process.templateName,
      step.participants !== 'studyo-only' ? clientId : undefined,
      step.participants !== 'client-only' ? agentId : undefined,
      followerIds
    );
  }
}
