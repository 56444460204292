import * as TC from '@buf/studyo_studyo.bufbuild_es/studyo/type_teams_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel } from '../Model';
import { GrpcTeamsContentRepresentation } from './GrpcTeamsContentRepresentation';
import { TeamsAssignmentCategoryMappingModel } from './interfaces';

export class GrpcTeamsAssignmentCategoryMapping
  extends BaseModel<TC.AssignmentCategoryMapping>
  implements TeamsAssignmentCategoryMappingModel
{
  constructor(pb: TC.AssignmentCategoryMapping) {
    super(pb);
  }

  get categoryId() {
    return this._pb.categoryId;
  }

  get categoryName() {
    return this._pb.categoryName;
  }

  @LazyGetter()
  get representation() {
    const pb = this._pb.contentRepresentation;
    return pb && new GrpcTeamsContentRepresentation(pb);
  }
}
