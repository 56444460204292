import { FormControl, InputLabel, MenuItem, Select, SxProps } from '@mui/material';
import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { caseInsensitiveAccentInsensitiveCompare } from '../../utils';
import { Column } from '../layout';

export interface ImportFromConfigurationConfigListProps {
  sx?: SxProps;
  className?: string;
  data: SchoolYearConfigurationSummary[];
  selectedConfigId: string;
  onSelect: (configId: string) => void;
}

export const ImportFromConfigurationConfigList = observer(
  ({ sx, className, data, onSelect, selectedConfigId }: ImportFromConfigurationConfigListProps) => {
    const { localizationService } = useInsightsServices();
    const strings = localizationService.localizedStrings.insights.components.calendar;

    return (
      <Column sx={sx} className={className} horizontalContentAlignment="stretch">
        <FormControl sx={{ pb: 2 }}>
          <InputLabel htmlFor="source-select">{strings.sourceSchoolLabel}</InputLabel>
          <Select
            value={selectedConfigId}
            inputProps={{ id: 'source-select' }}
            onChange={(event) => onSelect(String(event.target.value))}
          >
            {data
              .slice()
              .sort((a, b) => caseInsensitiveAccentInsensitiveCompare(a.schoolName, b.schoolName))
              .map((config) => (
                <MenuItem key={`source-select-${config.id}`} value={config.id}>
                  {config.schoolName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Column>
    );
  }
);
