import CollapseIcon from '@mui/icons-material/CloseFullscreen';
import ExpandIcon from '@mui/icons-material/OpenInFull';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { EditableOnboardingAnswer, OnboardingAnswer } from '@shared/models/onboarding';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LastAnswerTooltip } from './LastAnswerTooltip';

export interface MultiLineTextFieldQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  answer: EditableOnboardingAnswer;
  lastYearAnswer?: OnboardingAnswer;
  disabled?: boolean;
  showLargerEdit?: boolean;
}

export const MultiLineTextFieldQuestion = observer((props: MultiLineTextFieldQuestionProps) => {
  const { className, style, answer, lastYearAnswer, disabled = false, showLargerEdit: showPopupEdit = false } = props;

  const [isLarger, setIsLarger] = React.useState(false);

  // We work with styles because we want the input to be read-only
  // but not disabled, to allow text selection.
  const inputStyle: React.CSSProperties = disabled
    ? {
        fontFamily: isLarger ? 'monospace' : 'inherit',
        fontWeight: 400,
        color: grey[500]
      }
    : {
        fontFamily: isLarger ? 'monospace' : 'inherit',
        fontWeight: 400
      };

  return (
    <MUI.TextField
      className={className}
      style={style}
      sx={isLarger ? { maxWidth: 'unset !important', pr: 2 } : undefined}
      InputProps={{
        readOnly: disabled,
        style: inputStyle,
        endAdornment: (
          <MUI.InputAdornment position="end">
            {showPopupEdit && (
              <MUI.IconButton onClick={() => setIsLarger(!isLarger)} onMouseDown={(e) => e.preventDefault()} edge="end">
                {isLarger ? <CollapseIcon /> : <ExpandIcon />}
              </MUI.IconButton>
            )}
            {lastYearAnswer != null && lastYearAnswer.largeTextValue.length > 0 && (
              <LastAnswerTooltip
                sx={{ ml: showPopupEdit ? 2 : 0 }}
                answer={lastYearAnswer.largeTextValue}
                onCopy={() => (answer.largeTextValue = lastYearAnswer.largeTextValue)}
              />
            )}
          </MUI.InputAdornment>
        )
      }}
      fullWidth
      value={answer.largeTextValue}
      multiline
      rows={isLarger ? 20 : 5}
      onChange={(e) => (answer.largeTextValue = e.target.value)}
    />
  );
});
