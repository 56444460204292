import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import * as React from 'react';
import { CSSProperties } from 'react';
import { RouterLink } from './RouterLink';
import { Thresholds, ThresholdsInformation } from './ThresholdsInformation.tsx';

export interface SectionNameTwoLineProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  title: string;
  titleStyle?: CSSProperties;
  subInformation1?: string;
  subInformation2?: string;
  subInformationStyle?: CSSProperties;
  thresholds?: Thresholds;
  sectionColor?: string;
  linkTo?: string;
}

export function SectionNameTwoLine({
  sx,
  className,
  style,
  title,
  titleStyle,
  subInformation1,
  subInformation2,
  subInformationStyle,
  thresholds,
  sectionColor,
  linkTo
}: SectionNameTwoLineProps) {
  return (
    <MUI.Box
      sx={sx}
      className={className}
      style={style}
      display="flex"
      flexDirection="row"
      alignItems="center"
      flex={1}
      paddingLeft={1}
      borderLeft={`4px solid ${sectionColor}`}
    >
      <MUI.Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center">
        {/* Display the title as a link if `linkTo` was specified */}
        {linkTo != null && (
          <RouterLink style={titleStyle} to={linkTo}>
            <MUI.Typography color="primary" style={titleStyle} noWrap>
              {title}
            </MUI.Typography>
          </RouterLink>
        )}

        {/* Display the title as a text if `linkTo` was not specified */}
        {linkTo == null && (
          <MUI.Typography style={titleStyle} noWrap>
            {title}
          </MUI.Typography>
        )}

        {(subInformation1 != null || subInformation2 != null || thresholds != null) && (
          <MUI.Box display="flex" flexDirection="row" alignItems="center">
            {(subInformation1 != null || subInformation2 != null) && (
              <MUI.Box whiteSpace="nowrap" textOverflow="ellipsis">
                {subInformation1 != null && (
                  <MUI.Typography display="inline" color="textSecondary" style={subInformationStyle} noWrap>
                    {subInformation1}
                  </MUI.Typography>
                )}

                {subInformation2 != null && (
                  <React.Fragment>
                    {subInformation1 != null && (
                      <MUI.Typography display="inline" color="textSecondary" style={subInformationStyle} noWrap>
                        {' – '}
                      </MUI.Typography>
                    )}
                    <MUI.Typography display="inline" color="textSecondary" style={subInformationStyle} noWrap>
                      {subInformation2}
                    </MUI.Typography>
                  </React.Fragment>
                )}
              </MUI.Box>
            )}

            {thresholds != null && (
              <React.Fragment>
                <MUI.Box flex={1} />
                <MUI.Box
                  marginLeft={subInformation1 != null || subInformation2 != null ? 1 : undefined}
                  minWidth="min-content"
                >
                  <ThresholdsInformation thresholds={thresholds} />
                </MUI.Box>
              </React.Fragment>
            )}
          </MUI.Box>
        )}
      </MUI.Box>
    </MUI.Box>
  );
}
