import * as TC from '@buf/studyo_studyo.bufbuild_es/studyo/type_teams_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { GrpcTeamsAssignmentCategoryMapping } from './GrpcTeamsAssignmentCategoryMapping';
import { TeamsAccountDetailsModel } from './interfaces';

export class GrpcTeamsExternalAccountDetails implements TeamsAccountDetailsModel {
  constructor(private readonly _pb: TC.AccountDetails) {}

  get tenantId() {
    return this._pb.tenantId;
  }

  @LazyGetter()
  get mappings() {
    return this._pb.mappings.map((pb) => new GrpcTeamsAssignmentCategoryMapping(pb));
  }

  get ignoreUnmappedCategories() {
    return this._pb.ignoreUnmappedCategories;
  }

  get customClassesFilter(): string {
    return this._pb.customClassesFilter;
  }
}
