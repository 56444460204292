import { css } from '@emotion/css';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { observer } from 'mobx-react';

export interface BehaviorAggregationTooltipInfo {
  title: string;
  grey?: string;
  green?: string;
  yellow?: string;
  red?: string;
}

export interface BehaviorAggregationTooltipProps {
  sx?: SxProps;
  className?: string;
  tooltip: BehaviorAggregationTooltipInfo | undefined;
}

export const BehaviorAggregationTooltip = observer(({ tooltip, sx, className }: BehaviorAggregationTooltipProps) => {
  const theme = useTheme();
  if (tooltip == null) {
    return null;
  }

  const tooltipBoxClassName = css([
    {
      marginRight: theme.spacing(1),
      width: 12,
      height: 12
    }
  ]);

  return (
    <MUI.Box sx={sx} className={className}>
      <MUI.Typography fontWeight="500" variant="body2">
        {tooltip.title}
      </MUI.Typography>
      {tooltip.grey != null && (
        <MUI.Box display="flex" alignItems="center">
          <MUI.Box className={tooltipBoxClassName} bgcolor={grey[300]} borderRadius={50}>
            &nbsp;
          </MUI.Box>
          <MUI.Typography variant="body2">{tooltip.grey}</MUI.Typography>
        </MUI.Box>
      )}
      {tooltip.green != null && (
        <MUI.Box display="flex" alignItems="center">
          <MUI.Box className={tooltipBoxClassName} bgcolor={green[400]} borderRadius={50}>
            &nbsp;
          </MUI.Box>
          <MUI.Typography variant="body2">{tooltip.green}</MUI.Typography>
        </MUI.Box>
      )}
      {tooltip.yellow != null && (
        <MUI.Box display="flex" alignItems="center">
          <MUI.Box className={tooltipBoxClassName} bgcolor={orange[400]} borderRadius={50}>
            &nbsp;
          </MUI.Box>
          <MUI.Typography variant="body2">{tooltip.yellow}</MUI.Typography>
        </MUI.Box>
      )}
      {tooltip.red != null && (
        <MUI.Box display="flex" alignItems="center">
          <MUI.Box className={tooltipBoxClassName} bgcolor={red[400]} borderRadius={50}>
            &nbsp;
          </MUI.Box>
          <MUI.Typography variant="body2">{tooltip.red}</MUI.Typography>
        </MUI.Box>
      )}
    </MUI.Box>
  );
});
