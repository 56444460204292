import { CanvasAccountSettingsViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowIcon from '@mui/icons-material/ForwardOutlined';
import * as MUI from '@mui/material';
import { SxProps, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { EditableCanvasAssignmentGroupMapping } from '@shared/models/connectors/EditableCanvasAssignmentGroupMapping';
import { EditableCanvasContentRepresentation } from '@shared/models/connectors/EditableCanvasContentRepresentation';
import { AllContentWorkloadLevels, CanvasGradingCondition, ContentIcon } from '@shared/models/types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { CanvasAssignmentGroupSelector } from './CanvasAssignmentGroupSelector';
import { CanvasGradingConditionSelector } from './CanvasGradingConditionSelector';
import { ContentWorkloadLevelSelector } from './ContentWorkloadLevelSelector';
import { OptionalContentIconSelector } from './OptionalContentIconSelector';

export interface CanvasAssignmentGroupMappingsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: CanvasAccountSettingsViewModel;
}

export const CanvasAssignmentGroupMappings = observer((props: CanvasAssignmentGroupMappingsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  function addGroupName(viewModel: CanvasAccountSettingsViewModel, name: string | undefined): void {
    // We actually add this new group name mapping
    if (name != null && name.length > 0) {
      viewModel.addAssignmentGroupMapping(name);
    }
  }

  function updateGroupName(mapping: EditableCanvasAssignmentGroupMapping, name: string | undefined): void {
    if (name != null && name.length > 0) {
      mapping.name = name!;
    }
  }

  function updateCondition(mapping: EditableCanvasAssignmentGroupMapping, condition: CanvasGradingCondition): void {
    mapping.gradingCondition = condition;
  }

  function updateIcon(mapping: EditableCanvasAssignmentGroupMapping, icon: ContentIcon | undefined): void {
    if (icon == null) {
      mapping.representation = undefined;
    } else {
      if (mapping.representation == null) {
        mapping.representation = EditableCanvasContentRepresentation.createNew();
      }

      mapping.editableRepresentation.icon = icon!;
    }
  }

  return (
    <Root sx={sx} className={className} style={style}>
      <MUI.TableHead>
        <MUI.TableRow>
          <MUI.TableCell className={classNames('cell', 'groupCell')}>
            <MUI.InputLabel shrink>{strings.canvasAssignmentGroupTitle}</MUI.InputLabel>
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'gradingCell')}>
            <MUI.InputLabel shrink>{strings.canvasGradingConditionTitle}</MUI.InputLabel>
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'iconCell')}>
            <MUI.InputLabel shrink>{strings.iconTitle}</MUI.InputLabel>
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'workloadCell')}>
            <MUI.InputLabel shrink>{strings.workloadLevelTitle}</MUI.InputLabel>
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'actionCell')} />
        </MUI.TableRow>
      </MUI.TableHead>
      <MUI.TableBody>
        {viewModel.assignmentGroupMappings.map((m, index) => (
          <MUI.TableRow key={`group-${index}`}>
            <MUI.TableCell className={classNames('cell', 'groupCell')}>
              <CanvasAssignmentGroupSelector
                availableGroupNames={viewModel.availableGroupNames}
                selectedGroupName={m.name}
                onChange={(name) => updateGroupName(m, name)}
              />
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'gradingCell')}>
              <MUI.Box display="flex" flexDirection="row" alignItems="center">
                <CanvasGradingConditionSelector
                  selectedGradingCondition={m.gradingCondition}
                  onChange={(condition) => updateCondition(m, condition)}
                />
                <ArrowIcon className={'arrow'} />
              </MUI.Box>
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'iconCell')}>
              <OptionalContentIconSelector
                selectedIcon={m.representation?.icon}
                workloadLevel={m.representation?.workloadLevel}
                onChange={(icon) => updateIcon(m, icon)}
              />
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'workloadCell')}>
              {m.representation?.icon != null && (
                <ContentWorkloadLevelSelector
                  selectedLevel={m.representation.workloadLevel}
                  availableLevels={AllContentWorkloadLevels}
                  onChange={(level) => (m.representation!.workloadLevel = level)}
                />
              )}
            </MUI.TableCell>
            <MUI.TableCell className={classNames('cell', 'actionCell')}>
              <MUI.IconButton onClick={() => m.markAsDeleted()}>
                <DeleteIcon fontSize="small" />
              </MUI.IconButton>
            </MUI.TableCell>
          </MUI.TableRow>
        ))}
      </MUI.TableBody>
      <MUI.TableFooter>
        <MUI.TableRow className={'nextRow'}>
          <MUI.TableCell className={classNames('cell', 'groupCell')}>
            <CanvasAssignmentGroupSelector
              availableGroupNames={viewModel.availableGroupNames}
              selectedGroupName={''}
              onChange={(groupName) => addGroupName(viewModel, groupName)}
            />
          </MUI.TableCell>
          <MUI.TableCell className={classNames('cell', 'gradingCell')} />
          <MUI.TableCell className={classNames('cell', 'iconCell')} />
          <MUI.TableCell className={classNames('cell', 'workloadCell')} />
          <MUI.TableCell className={classNames('cell', 'actionCell')} />
        </MUI.TableRow>
      </MUI.TableFooter>
    </Root>
  );
});

const Root = styled(MUI.Table)(({ theme }) => ({
  '.cell': {
    padding: '2px 8px 2px 0px',
    border: 'none',
    verticalAlign: 'bottom'
  },
  '.groupCell': {
    width: '30%'
  },
  '.gradingCell': {
    width: '30%'
  },
  '.iconCell': {
    width: '15%'
  },
  '.workloadCell': {
    width: '15%'
  },
  '.actionCell': {
    width: '10%'
  },
  '.nextRow': {
    backgroundColor: grey[200]
  },
  '.arrow': {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));
