import { Storage } from '@shared/services';
import { AppBaseStore, BaseStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface StorageProperty<T> extends BaseStore {
  value: T;
  initialize: () => Promise<void>;
}

export class AppStorageProperty<T> extends AppBaseStore implements StorageProperty<T> {
  @observable protected _value: T | undefined;

  constructor(
    protected readonly _storage: Storage,
    protected readonly _storageKey: string,
    protected readonly _defaultValue: T
  ) {
    super(`AppStorageProperty`);
    makeObservable(this);
  }

  @computed
  get value(): T {
    return this._value ?? this._defaultValue;
  }

  set value(value: T) {
    this._value = value;
    void this.saveToStorage();
  }

  async initialize(): Promise<void> {
    await this.loadFromStorage();
  }

  @action
  async clear() {
    this._value = undefined;
    await this._storage.delete(this._storageKey);
    super.clear();
  }

  protected async loadFromStorage(): Promise<void> {
    const value = await this._storage.get<T>(this._storageKey);
    runInAction(() => (this._value = value));
  }

  protected async saveToStorage(): Promise<void> {
    await this._storage.set(this._storageKey, this.value);
    this.invalidate();
  }
}
