import { MenuItem, Select, SxProps, Typography } from '@mui/material';
import { AllCanvasGradingConditions, CanvasGradingCondition } from '@shared/models/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface CanvasGradingConditionSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  selectedGradingCondition: CanvasGradingCondition;
  onChange: (gradingCondition: CanvasGradingCondition) => void;
}

export const CanvasGradingConditionSelector = observer((props: CanvasGradingConditionSelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, selectedGradingCondition, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Select
      sx={sx}
      className={className}
      style={style}
      fullWidth
      value={selectedGradingCondition}
      onChange={(event) => {
        onChange(event.target.value as CanvasGradingCondition);
      }}
    >
      {AllCanvasGradingConditions.map((condition) => (
        <MenuItem key={`${condition}-grading-condition`} value={condition}>
          <Typography>{strings.localizedCanvasGradingCondition(condition)}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
});
