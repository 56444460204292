import { MicrosoftTeamsAccountViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsButton } from '../InsightsButton';
import { Column, Expanded, Row } from '../layout';

export interface MicrosoftTeamsAccountProps {
  sx?: SxProps;
  className?: string;
  viewModel: MicrosoftTeamsAccountViewModel;
}

export const MicrosoftTeamsAccount = observer((props: MicrosoftTeamsAccountProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx, className } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Column sx={sx} className={className}>
      <MUI.Typography sx={{ mb: 1 }}>{strings.microsoftTeamsAuthorizationMessage}</MUI.Typography>

      {viewModel.isExistingAccount && (
        <MUI.Typography sx={{ mb: 1 }} variant="body2" color="primary">
          {strings.microsoftTeamsAuthorizationExistingAccount}
        </MUI.Typography>
      )}

      <MUI.Link variant="button" href={viewModel.authorizationLink}>
        {strings.microsoftTeamsAuthorizationButton}
      </MUI.Link>

      <Row sx={{ m: 2 }}>
        <Expanded />

        <InsightsButton sx={{ m: 1 }} onClick={() => viewModel.cancelChanges()}>
          {strings.cancel}
        </InsightsButton>
      </Row>
    </Column>
  );
});
