import { Box, SxProps } from '@mui/material';
import * as React from 'react';
import { ScrollDirection } from './Types';

export interface ScrollProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  direction?: ScrollDirection;
  children?: React.ReactNode;
}

export function Scroll(props: ScrollProps) {
  const overflow = getOverflow(props.direction);
  const style = {
    ...props.style,
    overflowX: overflow.x,
    overflowY: overflow.y
  };

  return (
    <Box sx={props.sx} className={props.className} style={style}>
      {props.children}
    </Box>
  );
}

function getOverflow(direction: ScrollDirection | undefined): { x: 'auto' | 'hidden'; y: 'auto' | 'hidden' } {
  switch (direction) {
    case 'x':
      return { x: 'auto', y: 'hidden' };

    case 'y':
      return { x: 'hidden', y: 'auto' };

    case 'both':
      return { x: 'auto', y: 'auto' };

    case 'none':
    default:
      return { x: 'hidden', y: 'hidden' };
  }
}
