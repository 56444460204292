import { css } from '@emotion/css';
import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { LoginKind } from '@shared/models/types';
import { UserProfile } from '@shared/models/user';
import AppleLogo from '@shared/web/images/assets/studyo/ui_apple.svg?react';
import GoogleLogo from '@shared/web/images/assets/studyo/ui_google.svg?react';
import MicrosoftLogo from '@shared/web/images/assets/studyo/ui_microsoft.svg?react';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface UserInfoProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  profile: UserProfile;
  accountEmail?: string;
}

export const UserInfo = observer(({ sx, className, style, profile, accountEmail = '' }: UserInfoProps) => {
  const theme = useTheme();
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.components.users;

  const doEmailsDiffer =
    profile.email.length > 0 && accountEmail.length > 0 && profile.email.toLowerCase() != accountEmail.toLowerCase();

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="row">
      <MUI.Box marginRight={0.5}>
        {profile.userRole === 'root-admin-user' && <VpnKeyIcon color={'secondary'} />}
      </MUI.Box>
      {profile.loginKinds.length > 0 && (
        <MUI.Box alignItems="center" alignContent="center" marginRight={0.5}>
          {profile.loginKinds.map((kind) => logoForLoginKind(kind))}
        </MUI.Box>
      )}
      <MUI.Box display="flex" flexDirection="column">
        <MUI.Typography variant="body1">{profile.username}</MUI.Typography>
        <code style={{ color: theme.palette.grey[400] }}>{profile.userId}</code>
        {doEmailsDiffer && (
          <MUI.Typography variant="caption" color="error">
            {strings.emailsDontMatchWarning}
          </MUI.Typography>
        )}
      </MUI.Box>
    </MUI.Box>
  );
});

function logoForLoginKind(kind: LoginKind) {
  const logoClassName = css([{ height: 24, width: 24 }]);

  switch (kind) {
    case 'apple':
      return <AppleLogo className={logoClassName} />;
    case 'google':
      return <GoogleLogo className={logoClassName} />;
    case 'microsoft':
      return <MicrosoftLogo className={logoClassName} />;
    default:
      return undefined;
  }
}
