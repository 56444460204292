import { red } from '@mui/material/colors';
import { TopIndicator } from '@shared/components/utils';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

export const UpdateIndicator = observer(() => {
  const { localizationService, killSwitchService } = useInsightsServices();

  return (
    <TopIndicator
      message={localizationService.localizedStrings.insights.updateAvailable}
      backgroundColor={red[500]}
      display={killSwitchService.updateAvailable}
      onPress={() => window.location.reload()}
    />
  );
});
