import { Label } from '@insights/components';
import { SchoolYearConfigurationScreenInfo } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SchoolYearConfigurationAssessmentPlanningProps {
  sx?: SxProps;
  className?: string;
  data: SchoolYearConfigurationScreenInfo;
  style?: React.CSSProperties;
}

export const SchoolYearConfigurationAssessmentPlanning = observer(
  (props: SchoolYearConfigurationAssessmentPlanningProps) => {
    const { localizationService } = useInsightsServices();
    const { className, sx, data, style } = props;
    const strings = localizationService.localizedStrings.insights.views.managedGeneral;
    const configStrings = localizationService.localizedStrings.models.schoolYearConfig;

    return (
      <MUI.Box sx={sx} className={className} style={style}>
        <MUI.Grid container spacing={2}>
          {/* Grade level source label */}
          <MUI.Grid item xs={12}>
            <Label>{`${strings.gradeLevelSourceAssessmentPlanningTitle}: ${configStrings.gradeLevelSourceTitle(
              'section'
            )}`}</Label>
          </MUI.Grid>

          <MUI.Grid container item>
            {/* Grade Level Column Title */}
            <MUI.Grid item xs={4} xl={3}>
              <Label>{strings.gradeLevelColumnTitle}</Label>
            </MUI.Grid>
            {/* Assessment Date Column Title */}
            <MUI.Grid item xs={4} xl={3}>
              <Label>{strings.assessmentPlanningVisibilityDateColumnTitle}</Label>
            </MUI.Grid>
            <MUI.Grid item xs={4} xl={6} />

            {data.visibilityDates.map((planning) => (
              <React.Fragment key={planning.gradeLevel}>
                {/* Grade Level */}
                <MUI.Grid item xs={4} xl={3}>
                  <MUI.Typography>{planning.gradeLevel}</MUI.Typography>
                </MUI.Grid>
                {/* Visibility Day */}
                <MUI.Grid item xs={4} xl={3}>
                  <MUI.Typography>
                    {planning.visibilityDate.formattedString(
                      localizationService.localizedStrings.models.dateFormats.mediumUnabridged
                    )}
                  </MUI.Typography>
                </MUI.Grid>
                {/* Filler */}
                <MUI.Grid item xs={4} xl={6} />
              </React.Fragment>
            ))}
          </MUI.Grid>
        </MUI.Grid>
      </MUI.Box>
    );
  }
);
