import { SxProps, Typography } from '@mui/material';
import { ScheduleModel } from '@shared/models/config';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface ScheduleFlagsSubtitleProps {
  sx?: SxProps;
  className?: string;
  schedule: ScheduleModel;
}

export const ScheduleFlagsSubtitle = observer((props: ScheduleFlagsSubtitleProps) => {
  const { localizationService } = useInsightsServices();
  const { schedule, sx, className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;
  const flagsSubtitle = schedule.isHidden
    ? schedule.isNoSchool
      ? strings.hiddenNoSchool
      : strings.hidden
    : schedule.isNoSchool
      ? strings.noSchool
      : '';

  return (
    <Typography sx={sx} className={className} variant="caption">
      {flagsSubtitle}
    </Typography>
  );
});
