import { ImportDataDetailsResultsViewModel, ImportDataDetailsViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { ObservablePresenter } from '../ObservablePresenter';
import { ImportDataDetailsResults } from './ImportDataDetailsResults';

export interface ImportDataDetailsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: ImportDataDetailsViewModel;
}

const globalOptionsHeight = 40;

export const ImportDataDetails = observer((props: ImportDataDetailsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;
  const theme = useTheme();

  const renderData = (data: ImportDataDetailsResultsViewModel) => {
    return (
      <MUI.Card sx={{ maxHeight: `calc(100% - ${globalOptionsHeight}px - ${theme.spacing(1)})`, overflow: 'auto' }}>
        <ImportDataDetailsResults viewModel={data} sx={{ height: 'fit-content', width: '100%' }} />
      </MUI.Card>
    );
  };

  return (
    <MUI.Box sx={sx} className={className} style={style} padding={2} height="100%" width="100%">
      <MUI.Box display="flex" flexDirection="row" ml={3} sx={{ maxWidth: 800 }} alignItems="center">
        <MUI.Box flex={1}>
          <MUI.FormControlLabel
            label={viewModel.isDestructive ? strings.destructiveOnLabel : strings.destructiveOffLabel}
            control={
              <MUI.Switch
                checked={viewModel.isDestructive}
                onChange={(e) => (viewModel.isDestructive = e.target.checked)}
              />
            }
          />
        </MUI.Box>
        {viewModel.availableOptions.length > 0 && (
          <MUI.Box flex={1}>
            <MUI.FormControl fullWidth>
              <MUI.InputLabel shrink htmlFor="options-select">
                {strings.schemaImportOptions}
              </MUI.InputLabel>
              <MUI.Select
                multiple
                value={viewModel.selectedOptionNames}
                inputProps={{ id: 'options-select', placeholder: strings.noImportOption }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  }
                }}
                onChange={(e) => (viewModel.selectedOptionNames = e.target.value as unknown as string[])}
                renderValue={() => (
                  <MUI.Typography variant="body2">{viewModel.selectedOptionNames.join(', ')}</MUI.Typography>
                )}
              >
                {viewModel.availableOptions.map((o) => (
                  <MUI.MenuItem key={`option-${o.name}`} value={o.name}>
                    <MUI.Checkbox size="small" checked={viewModel.selectedOptionNames.includes(o.name)} />
                    <MUI.Typography variant="body2">
                      {o.name} : {o.localizedDescription}
                    </MUI.Typography>
                  </MUI.MenuItem>
                ))}
              </MUI.Select>
            </MUI.FormControl>
          </MUI.Box>
        )}
      </MUI.Box>

      <ObservablePresenter
        data={viewModel.importData}
        loadingMessage={strings.loadingImportDataMessage}
        errorMessage={strings.importDataErrorMessage}
        render={renderData}
      />
    </MUI.Box>
  );
});
