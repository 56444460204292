// TODO MUI.Box

import * as MUI from '@mui/material';
import { useTheme } from '@mui/material';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { ApplicationName } from './ApplicationName';
import { Column, Container } from './layout';

export const ErrorBoundary = ({ children }: PropsWithChildren) => {
  return (
    <SentryErrorBoundary
      fallback={() => <ErrorBoundaryFallback />}
      onError={(error, componentStack) => {
        console.error(error);
        console.log(componentStack);
      }}
    >
      {children}
    </SentryErrorBoundary>
  );
};

export const ErrorBoundaryFallback = observer(() => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.errorBoundary;
  const theme = useTheme();

  return (
    <Container sx={{ flex: 1, p: 3 }}>
      <Column horizontalContentAlignment="left">
        <Container sx={{ mb: 3 }}>
          <ApplicationName size="extra-large" color="light" />
        </Container>

        <Container sx={{ mb: 2 }}>
          <MUI.Typography variant="body1">{strings.content}</MUI.Typography>
        </Container>

        <MUI.Link variant="body1" color={theme.palette.secondary.dark} onClick={() => window.location.reload()}>
          {strings.reloadApp}
        </MUI.Link>
      </Column>
    </Container>
  );
});
