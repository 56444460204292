import {
  AuthorizationRoleCondition,
  ObservablePresenter,
  OnboardingStepView,
  PageHeaderBar
} from '@insights/components';
import ShowIcon from '@mui/icons-material/Visibility';
import HideIcon from '@mui/icons-material/VisibilityOff';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MaxLayoutWidth, MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface OnboardingStepScreenProps {
  sx?: SxProps;

  className?: string;
  style?: React.CSSProperties;
}

export const OnboardingStepScreen = observer((props: OnboardingStepScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const params = useParams();
  const configId = params.configId ?? '';
  const stepName = params.templateName ?? '';
  const processName = params.processName ?? '';
  const viewModel = useMemo(
    () => viewModelFactory.createOnboardingStepScreen(configId, processName, stepName),
    [configId, processName, stepName]
  );

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={['super-admin', 'studyo-staff']}>
          <MUI.Tooltip title={strings.hideLockedContentTooltip}>
            <MUI.IconButton sx={{ mr: 1 }} onClick={() => viewModel.toggleLockedContentVisibility()}>
              {viewModel.isLockedContentVisible ? <HideIcon fontSize="small" /> : <ShowIcon fontSize="small" />}
            </MUI.IconButton>
          </MUI.Tooltip>
        </AuthorizationRoleCondition>
      </PageHeaderBar>

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.step}
          loadingMessage={strings.loadingStepMessage}
          errorMessage={strings.loadingStepErrorMessage}
          render={(step) => (
            <MUI.Box display="flex" flexDirection="column">
              <OnboardingStepView
                viewModel={step}
                sx={{
                  minWidth: MinLayoutWidth,
                  maxWidth: MaxLayoutWidth,
                  width: '100%',
                  height: '100%',
                  display: 'block'
                }}
              />
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
