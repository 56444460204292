import * as BC from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/blackbaud_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { GrpcBlackbaudKindMapping } from './GrpcBlackbaudKindMapping';
import { BlackbaudExternalAccountDetails } from './interfaces/BlackbaudExternalAccountDetails';

export class GrpcBlackbaudExternalAccountDetails implements BlackbaudExternalAccountDetails {
  constructor(private readonly pb: BC.GetExternalAccountDetailsResponse) {}

  get serverUrl() {
    return this.pb.serverUrl;
  }

  get username() {
    return this.pb.username;
  }

  get token() {
    return this.pb.token;
  }

  get yearLabel() {
    return this.pb.yearLabel;
  }

  get levelId() {
    return this.pb.levelId;
  }

  get levelName() {
    return this.pb.levelName;
  }

  @LazyGetter()
  get kindMappings() {
    return this.pb.kindMappings.map((pb) => new GrpcBlackbaudKindMapping(pb));
  }

  get includeNonLeadSections() {
    return this.pb.includeNonLeadSections;
  }
}
