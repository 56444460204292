import AddIcon from '@mui/icons-material/Add';
import { IconButton, Input, InputAdornment, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';

export interface BlackbaudSkyAssignmentTypeSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  typeName: string;
  onAdd: (name: string) => void;
}

export const BlackbaudSkyAssignmentTypeSelector = observer((props: BlackbaudSkyAssignmentTypeSelectorProps) => {
  const { onAdd, className, sx, style, typeName } = props;
  const [name, setName] = useState(typeName);

  function addMapping() {
    onAdd(name);
    setName('');
  }

  return (
    <Input
      sx={sx}
      className={className}
      style={style}
      fullWidth
      value={name}
      onChange={(e) => setName(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton disabled={name.length === 0} onClick={() => addMapping()}>
            <AddIcon />
          </IconButton>
        </InputAdornment>
      }
      onKeyDown={(e) => {
        if (e.key === 'Enter' && name.length > 0) {
          addMapping();
        }
      }}
    />
  );
});
