import { AccountService, AlertService, NavigationService, SettingsStore } from '@insights/services';
import { Locale, LocalizationService } from '@shared/resources/services';
import { EnvironmentService, Storage } from '@shared/services';
import { getYear } from 'date-fns';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { Location, NavigateFunction } from 'react-router-dom';
import { StorageKeys } from '../Constants';

export interface LoginViewModel {
  readonly isSigningUp: boolean;
  readonly isLoggingIn: boolean;
  readonly currentLocale: Locale;
  readonly version: string;
  readonly copyright: string;
  hasError: boolean;

  onInit: (location: Location, navigate: NavigateFunction) => Promise<void>;
  signUp: (location: Location, navigate: NavigateFunction) => Promise<void>;
  login: (location: Location, navigate: NavigateFunction) => Promise<void>;
  switchLanguage: () => Promise<void>;
}

export class AppLoginViewModel implements LoginViewModel {
  @observable private _isSigningUp = false;
  @observable private _isLoggingIn = false;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _localStorage: Storage,
    private readonly _settingsStore: SettingsStore,
    private readonly _environment: EnvironmentService,
    private readonly _alertService: AlertService
  ) {
    makeObservable(this);
  }

  async onInit(location: Location, navigate: NavigateFunction): Promise<void> {
    try {
      if (this._settingsStore.isCompletingLogin) {
        runInAction(() => (this._isLoggingIn = true));

        // Complete the login process
        let result = await this._accountService.completeLogin();

        if (result.success) {
          // If successful, this will navigate the user to the proper pager.
          await this._navigationService.completeLogin(navigate, result.referrer);
        } else {
          // An error can occur when the user closes the login popup after he has
          // successfully logged in but still in the process (e.g. waiting for email
          // confirmation). In that case, `login()` reports an error (the popup was closed
          // but the callback url was not invoked, so we did not receive auth tokens),
          // but the user is actually logged in. Initiating a silent signin flow will
          // allow us to proceed with the login without having to display "an error has occurred"
          // to the user.
          await this._accountService.startSilentLogin();
          result = { ...result, success: this._accountService.isLoggedIn };

          if (result.success) {
            await this._navigationService.completeLogin(navigate, result.referrer);
          } else {
            // An error occurred. Set proper state and redirect to the login
            // page (without any authentication information)
            runInAction(() => {
              this.hasError = true;
              this._isLoggingIn = false;
            });

            await this._navigationService.redirectToLogin(navigate);
          }
        }
      } else {
        // "Normal" login flow. Redirect the user if he is already logged in.
        await this.redirectIfAlreadyLoggedIn(location, navigate);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this._settingsStore.isCompletingLogin = false;
    }
  }

  @computed
  get currentLocale(): Locale {
    return this._localizationService.currentLocale;
  }

  @computed
  get isSigningUp(): boolean {
    return this._isSigningUp;
  }

  @computed
  get isLoggingIn(): boolean {
    return this._isLoggingIn;
  }

  @computed
  get version(): string {
    return this._localizationService.localizedStrings.insights.version(this._environment.formattedVersionNumber);
  }

  @computed
  get copyright(): string {
    return this._localizationService.localizedStrings.insights.copyright(getYear(new Date()));
  }

  @computed
  get hasError(): boolean {
    return this._settingsStore.hasLoginError;
  }

  set hasError(value: boolean) {
    this._settingsStore.hasLoginError = value;
  }

  async signUp(location: Location, navigate: NavigateFunction) {
    try {
      runInAction(() => {
        this._isSigningUp = true;
        this.hasError = false;
      });

      const referrer = this._navigationService.extractReferrer(location);
      const result = await this._accountService.login(referrer);

      if (result) {
        await this._navigationService.redirectToReferrerOrLanding(location, navigate);
      }
    } catch (e) {
      runInAction(() => {
        this._isSigningUp = false;
        this.hasError = true;
      });
    }
  }

  async login(location: Location, navigate: NavigateFunction): Promise<void> {
    try {
      runInAction(() => {
        this._isLoggingIn = true;
        this.hasError = false;
      });

      const referrer = this._navigationService.extractReferrer(location);
      const result = await this._accountService.login(referrer);

      if (result) {
        await this._navigationService.redirectToReferrerOrLanding(location, navigate);
      }
    } catch (e) {
      runInAction(() => {
        this._isLoggingIn = false;
        this.hasError = true;
      });
    }
  }

  async switchLanguage(): Promise<void> {
    const newLocale = this._localizationService.currentLocale === 'en' ? 'fr' : 'en';
    this._localizationService.setCurrentLocale(newLocale);

    await this._localStorage.set(StorageKeys.locale, newLocale);
  }

  private async redirectIfAlreadyLoggedIn(location: Location, navigate: NavigateFunction): Promise<void> {
    if (this._accountService.isLoggedIn) {
      await this._navigationService.redirectToReferrerOrLanding(location, navigate);
    }
  }
}
