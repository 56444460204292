import { css } from '@emotion/css';
import { SchoolCalendarViewModel } from '@insights/viewmodels';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { EditableDialogActions } from './EditableDialogActions';
import { EditableSpecialDayCycleDayAndEffect } from './EditableSpecialDayCycleDayAndEffect';
import { EditableSpecialDaySchedules } from './EditableSpecialDaySchedules';
import { EditableSpecialDaySymbolAndColor } from './EditableSpecialDaySymbolAndColor';
import { EditableSpecialDayTitle } from './EditableSpecialDayTitle';
import { OccurrencesSummary } from './OccurrencesSummary.tsx';

export interface SpecialDayDialogProps {
  viewModel: SchoolCalendarViewModel;
}

export const SpecialDayDialog = observer((props: SpecialDayDialogProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel } = props;
  const edition = viewModel.specialDayEditor;
  const strings = localizationService.localizedStrings.insights.components.calendar;
  const theme = useTheme();

  if (edition.active == null) {
    return <div />;
  }

  const editableClassName = css([{ padding: theme.spacing(2), paddingTop: 0 }]);

  return (
    <Dialog open={true} onClose={() => void edition.cancel()} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{strings.editSpecialDay}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <EditableSpecialDayTitle viewModel={edition.active} className={editableClassName} />
          <EditableSpecialDayCycleDayAndEffect
            viewModel={edition.active}
            daysPerCycle={viewModel.daysPerCycle}
            className={editableClassName}
          />
          <EditableSpecialDaySymbolAndColor viewModel={edition.active} className={editableClassName} />
          <EditableSpecialDaySchedules
            viewModel={edition.active}
            schedules={viewModel.availableSchedules}
            className={editableClassName}
          />
          <OccurrencesSummary className={editableClassName} occurrences={edition.active.occurrences} />
        </Box>
      </DialogContent>
      <DialogActions>
        <EditableDialogActions edition={edition} hasChanges={edition.active.hasChanges} />
      </DialogActions>
    </Dialog>
  );
});
