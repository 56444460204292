import { PageHeaderBar } from '@insights/components';
import { StudentDashboardViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { CSSProperties, useEffect } from 'react';
import { useInsightsServices } from '../../../../UseInsightsServicesHook.ts';

export interface StudentDashboardProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: StudentDashboardViewModel;
}

export const StudentDashboard = observer((props: StudentDashboardProps) => {
  const { analyticsService, localizationService } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.dashboards;
  const studyoUrl = window.STUDYO_ENV?.studyoUrl ?? '';

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Student Dashboard' });
  });

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <MUI.Box padding={2} flex={1} overflow="auto">
        <MUI.Stack spacing={2} alignItems="start">
          <MUI.Typography variant="h4">{strings.connectedStudentTitle}</MUI.Typography>
          <MUI.Typography>
            {strings.connectedStudentMessage}
            <MUI.Link href={studyoUrl}>studyo.app</MUI.Link>.
          </MUI.Typography>
          <MUI.Button variant="contained" href={studyoUrl}>
            {strings.openStudyoButton}
          </MUI.Button>
        </MUI.Stack>
      </MUI.Box>
    </MUI.Box>
  );
});
