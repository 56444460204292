import { ExternalAccountEditionViewModel } from '@insights/viewmodels';
import { SxProps, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsButton } from '../InsightsButton';
import { Expanded, Row } from '../layout';

export interface ExternalAccountEditionActionsProps {
  sx?: SxProps;
  className?: string;
  viewModel: ExternalAccountEditionViewModel;
}

export const ExternalAccountEditionActions = observer((props: ExternalAccountEditionActionsProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Row>
      {viewModel.errors.map((e, index) => (
        <Row key={`error-${index}`}>
          <Typography sx={{ m: 1 }} color="error">
            {e}
          </Typography>
        </Row>
      ))}

      <Expanded />

      <InsightsButton
        sx={{ ml: 1, mt: 2 }}
        onClick={() => viewModel.cancelChanges()}
        isExecuting={viewModel.isApplying}
      >
        {strings.cancel}
      </InsightsButton>

      <InsightsButton
        sx={{ ml: 1, mt: 2 }}
        onClick={() => void viewModel.applyChanges()}
        isDisabled={!viewModel.hasChanges && viewModel.hasNext !== true}
        isExecuting={viewModel.isApplying}
        showSpinnerOnExecuting
        isDefault
      >
        {viewModel.hasNext && !viewModel.hasChanges ? strings.skip : strings.save}
      </InsightsButton>
    </Row>
  );
});
