import { EditableTransformationColumnViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface EditableIsSubstitutionProps {
  sx?: SxProps;
  className?: string;
  column: EditableTransformationColumnViewModel;
}

export const EditableIsSubstitution = observer((props: EditableIsSubstitutionProps) => {
  const { localizationService } = useInsightsServices();
  const { column, sx, className } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <MUI.FormControlLabel
      sx={sx}
      className={className}
      label={strings.isSubstitutionLabel}
      control={
        <MUI.Checkbox
          autoFocus={column.focusedField === 'substitution'}
          checked={column.isSubstitution}
          disabled={column.schemaFields.length > 0}
          onChange={(e) => (column.isSubstitution = e.target.checked)}
          onFocus={() => column.setFocusedField('substitution')}
        />
      }
    />
  );
});
