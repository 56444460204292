import { BlackbaudSkyAccountMappingsViewModel } from '@insights/viewmodels';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Column } from '../layout';
import { BlackbaudSkyAssignmentTypeMappings } from './BlackbaudSkyAssignmentTypeMappings';
import { BlackbaudSkyUnmappedAssignmentTypes } from './BlackbaudSkyUnmappedAssignmentTypes';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface BlackbaudSkyAccountMappingsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: BlackbaudSkyAccountMappingsViewModel;
}

export const BlackbaudSkyAccountMappings = observer((props: BlackbaudSkyAccountMappingsProps) => {
  const { sx, className, style, viewModel } = props;

  return (
    <Column sx={sx} className={className} style={style}>
      <BlackbaudSkyAssignmentTypeMappings sx={{ mb: 2 }} viewModel={viewModel} />

      <BlackbaudSkyUnmappedAssignmentTypes
        sx={{ mb: 2 }}
        isIgnoringUnmappedTypes={viewModel.isIgnoringUnmappedTypes}
        onChange={(value) => (viewModel.isIgnoringUnmappedTypes = value)}
      />

      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
