import { TransformationIndexedSourcesViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface TransformationIndexedSourcesProps {
  sx?: SxProps;
  viewModel: TransformationIndexedSourcesViewModel;
  className?: string;
  style?: React.CSSProperties;
}

export const TransformationIndexedSources = observer((props: TransformationIndexedSourcesProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <MUI.Box my={1}>
        <MUI.Typography variant="caption" color="primary">
          {strings.indexedSourcesLabel}
        </MUI.Typography>
        {viewModel.indexedSources.length > 0 ? (
          viewModel.indexedSources.map((source) => (
            <MUI.Box key={`index-source-${source.label}`} display="flex" flexDirection="row" alignItems="center">
              <MUI.Box flex={1}>
                <MUI.Typography>{strings.indexedSourceDescription(source.label, source.columnIndex)}</MUI.Typography>
              </MUI.Box>
              <MUI.IconButton onClick={() => viewModel.removeIndexedSource(source)}>
                <DeleteIcon fontSize="small" />
              </MUI.IconButton>
            </MUI.Box>
          ))
        ) : (
          <MUI.Typography>{strings.noIndexedSource}</MUI.Typography>
        )}
      </MUI.Box>
      <MUI.Box ml={2} mb={1} display="flex" flexDirection="row" alignItems="center">
        <MUI.Grid container flex={1} spacing={1}>
          <MUI.Grid item xs={6}>
            <MUI.TextField
              select
              fullWidth
              label={strings.sourceLabelLabel}
              InputLabelProps={{ shrink: true }}
              value={viewModel.nextIndexedSourceLabel}
              onChange={(e) => (viewModel.nextIndexedSourceLabel = e.target.value)}
            >
              {viewModel.availableSources.map((source) => (
                <MUI.MenuItem key={`next-indexed-source-label${source.label}`} value={source.label}>
                  {`${source.label} - ${source.name} (${source.columnCount})`}
                </MUI.MenuItem>
              ))}
            </MUI.TextField>
          </MUI.Grid>
          <MUI.Grid item xs={6}>
            <MUI.TextField
              fullWidth
              type="number"
              label={strings.indexedColumnLabel}
              InputLabelProps={{ shrink: true }}
              value={viewModel.nextIndexedSourceColumnIndex === -1 ? '' : viewModel.nextIndexedSourceColumnIndex}
              onChange={(e) => (viewModel.nextIndexedSourceColumnIndex = Number(e.target.value))}
            />
          </MUI.Grid>
        </MUI.Grid>

        <MUI.IconButton disabled={!viewModel.canAddNextIndexedSource} onClick={() => viewModel.addNextIndexedSource()}>
          <AddIcon fontSize="small" />
        </MUI.IconButton>
      </MUI.Box>
    </MUI.Box>
  );
});
