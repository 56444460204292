import { SchoolCalendarSpecialDayViewModel } from '@insights/viewmodels';
import { FormControl, InputLabel, MenuItem, Select, SxProps, Typography } from '@mui/material';

import { CycleDayEffect } from '@shared/models/types';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Row } from '../layout';

export interface EditableSpecialDayCycleDayAndEffectProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarSpecialDayViewModel;
  daysPerCycle: number;
}

export const EditableSpecialDayCycleDayAndEffect = observer((props: EditableSpecialDayCycleDayAndEffectProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, daysPerCycle, style, sx, className } = props;
  const cycleDayValue = viewModel == null || viewModel.cycleDayEffect === 'preserve' ? -1 : viewModel.cycleDay;
  const isCycleDayEffectVisible = cycleDayValue !== -1;

  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Row sx={sx} className={className} style={style}>
      <FormControl sx={{ mr: 2, minWidth: 150, flex: 2 }}>
        <InputLabel htmlFor="cycle-day-select">{strings.cycleDay}</InputLabel>
        <Select
          value={cycleDayValue}
          inputProps={{ id: 'cycle-day-select' }}
          onChange={(event) => (viewModel.cycleDay = Number(event.target.value))}
        >
          <MenuItem key="cycle-day-select-item-keep" value={-1}>
            <Typography>{strings.keepCurrent}</Typography>
          </MenuItem>
          <MenuItem key="cycle-day-select-item-remove" value={0}>
            <Typography>{strings.remove}</Typography>
          </MenuItem>

          {_.range(1, daysPerCycle + 1).map((i) => {
            let title = strings.cycleDay + ` ${i}`;
            const customTitle = viewModel.cycleDayTitles.at(i - 1)?.trim() ?? '';
            if (customTitle.length > 0) {
              title += ` - ${customTitle}`;
            }

            return (
              <MenuItem key={`cycle-day-select-item-${i}`} value={i}>
                <Typography>{title}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {isCycleDayEffectVisible && (
        <FormControl sx={{ mr: 2, minWidth: 150, flex: 3 }}>
          <InputLabel htmlFor="cycle-day-effect-select">{strings.cycleDayEffect}</InputLabel>
          <Select
            value={viewModel.cycleDayEffect}
            inputProps={{ id: 'cycle-day-effect-select' }}
            onChange={(event) => (viewModel.cycleDayEffect = event.target.value as CycleDayEffect)}
          >
            <MenuItem value={'sequence' as CycleDayEffect}>
              <Typography>{strings.doNotAffectSequence}</Typography>
            </MenuItem>
            <MenuItem value={'insert' as CycleDayEffect}>
              <Typography>{strings.pushCycleDays}</Typography>
            </MenuItem>
            <MenuItem value={'reset' as CycleDayEffect}>
              <Typography>{strings.restartSequence}</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      )}
    </Row>
  );
});
