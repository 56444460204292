import { SchoolYearConfigurationScreenViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SchoolYearConfigurationPowerToolsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: SchoolYearConfigurationScreenViewModel;
  schoolYearConfiguration: SchoolYearConfigurationModel;
}

export const SchoolYearConfigurationPowerTools = observer((props: SchoolYearConfigurationPowerToolsProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, style, viewModel, schoolYearConfiguration } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;
  const navigate = useNavigate();

  return (
    <MUI.Grid sx={sx} className={className} style={style} container spacing={2}>
      {/* Clear the cache */}
      <MUI.Grid item xs={12}>
        <MUI.Button
          variant="outlined"
          onClick={() => {
            void viewModel.clearCache();
          }}
        >
          <MUI.Typography>{strings.clearCacheLabel}</MUI.Typography>
        </MUI.Button>
      </MUI.Grid>

      {/* Create a demo copy */}
      <MUI.Grid item xs={12}>
        <MUI.Button
          variant="outlined"
          onClick={() => {
            void viewModel.createDemoCopy(schoolYearConfiguration);
          }}
        >
          <MUI.Typography>{strings.createDemoCopyLabel}</MUI.Typography>
        </MUI.Button>
      </MUI.Grid>

      {/* Create a real copy */}
      <MUI.Grid item xs={12}>
        <MUI.Button
          variant="outlined"
          onClick={() => {
            void viewModel.createNextYearCopy(schoolYearConfiguration.id, navigate);
          }}
        >
          <MUI.Typography>{strings.createNextYearLabel}</MUI.Typography>
        </MUI.Button>
      </MUI.Grid>

      {/* Purge deleted accounts */}
      <MUI.Grid item xs={12}>
        <MUI.Button
          variant="outlined"
          onClick={() => {
            void viewModel.purgeDeletedAccounts(schoolYearConfiguration);
          }}
        >
          <MUI.Typography>{strings.purgeDeletedAccountsLabel}</MUI.Typography>
        </MUI.Button>
      </MUI.Grid>
    </MUI.Grid>
  );
});
