import FatalErrorIcon from '@mui/icons-material/Cancel';
import InformativeIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import { SxProps, useTheme } from '@mui/material';
import { IncidentSeverity } from '@shared/models/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';

export type IncidentColor = 'inherit' | 'primary' | 'secondary' | 'error';

export type IncidentSize = 'small' | 'normal';

export type IconFontSize = 'inherit' | 'medium' | 'small' | 'large';

export interface ImportDataIncidentIconProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  severity: IncidentSeverity;
  color?: IncidentColor;
  size?: IncidentSize;
}

export const ImportDataIncidentIcon = observer((props: ImportDataIncidentIconProps) => {
  const { sx, color = 'inherit', size = 'normal', severity, className } = props;
  const theme = useTheme();

  const iconSize: IconFontSize = useMemo(() => {
    switch (size) {
      case 'normal':
        return 'medium';
      case 'small':
        return 'small';
      default:
        return 'medium';
    }
  }, [size]);

  switch (severity) {
    case 'informative':
      return (
        <InformativeIcon
          sx={{ ...sx, color: theme.palette.text.primary }}
          fontSize={iconSize}
          color={color}
          className={className}
        />
      );
    case 'warning':
      return (
        <WarningIcon
          sx={{ ...sx, color: theme.palette.warning.main }}
          fontSize={iconSize}
          color={color}
          className={className}
        />
      );

    case 'error':
      return (
        <ErrorIcon
          sx={{ ...sx, color: theme.palette.error.main }}
          fontSize={iconSize}
          color={color}
          className={className}
        />
      );

    case 'fatal-error':
      return (
        <FatalErrorIcon
          sx={{ ...sx, color: theme.palette.error.dark }}
          fontSize={iconSize}
          color={color}
          className={className}
        />
      );
  }
});
