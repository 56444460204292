import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { SectionScheduleUtils } from '@shared/components/utils';
import { SectionScheduleModel } from '@shared/models/config';
import _ from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface SectionSchedulesSummaryProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  schedules: SectionScheduleModel[];
}

export const SectionSchedulesSummary = observer((props: SectionSchedulesSummaryProps) => {
  const { localizationService } = useInsightsServices();
  const { schedules, sx, className, style } = props;
  const schedulesByTerm = _.groupBy(schedules, (s) => s.termTag);
  const strings = localizationService.localizedStrings.insights.components.sections;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <MUI.Tooltip
        title={
          <MUI.Box display="flex" flexDirection="column">
            {schedules.map((schedule) => (
              <MUI.Typography key={`schedule-summary-${schedule.id}`} variant="caption">
                {SectionScheduleUtils.getScheduleDigest(schedule, localizationService)}
              </MUI.Typography>
            ))}
          </MUI.Box>
        }
      >
        <MUI.Box display="flex" flexDirection="column">
          {Object.keys(schedulesByTerm).map((termTag) => (
            <MUI.Typography key={`schedule-summary-${termTag}`} variant="caption">
              {strings.getScheduleCountPerTerm(termTag, schedulesByTerm[termTag].length)}
            </MUI.Typography>
          ))}
        </MUI.Box>
      </MUI.Tooltip>
    </MUI.Box>
  );
});
