import { AccountInfo } from '@insights/models';
import { CustomFilterUtils } from '@insights/utils';
import { SectionStudentsEditionViewModel } from '@insights/viewmodels';
import { MTableToolbar, Column as TableColumn } from '@material-table/core';
import WarningIcon from '@mui/icons-material/Warning';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { AccountUtils } from '@shared/components/utils/models';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react';
import moize from 'moize';
import * as React from 'react';
import { useRef } from 'react';
import { DefaultTablePageSize, DefaultTablePageSizes } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsMaterialTable } from '../InsightsMaterialTable';

const SelectedTableStateKey = 'SectionStudentsEditionSelected';
const AvailableTableStateKey = 'SectionStudentsEditionAvailable';

export interface SectionStudentsEditionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: SectionStudentsEditionViewModel;
}

export const SectionStudentsEdition = observer((props: SectionStudentsEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;

  const getSearchableFields = useRef(
    moize(({ account }: AccountInfo) => [
      AccountUtils.getDisplayLastFirstName(account),
      account.email,
      account.managedIdentifier
    ])
  );

  return (
    <MUI.Grid sx={sx} className={className} style={style} container spacing={1}>
      <MUI.Grid item xs={12} sm={6}>
        <InsightsMaterialTable
          stateKey={SelectedTableStateKey}
          // This is to disable the card contour
          components={{
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>,
            Toolbar: (p) => (
              <MUI.Box display="flex" flexDirection="row" alignItems="center">
                {viewModel.isViewingTeachers && (
                  <MUI.Tooltip title={strings.selectedTeachersNotice}>
                    <WarningIcon fontSize="small" />
                  </MUI.Tooltip>
                )}
                <MUI.Box flex={1}>
                  <MTableToolbar {...p} />
                </MUI.Box>
              </MUI.Box>
            )
          }}
          title={viewModel.isViewingTeachers ? strings.selectedTeachers : strings.selectedStudents}
          actions={[
            {
              icon: viewModel.isViewingTeachers ? 'visibility' : 'visibility_off',
              isFreeAction: true,
              onClick: () => (viewModel.isViewingTeachers = !viewModel.isViewingTeachers),
              tooltip: viewModel.isViewingTeachers
                ? strings.toggleSelectStudentsTooltip
                : strings.toggleSelectTeachersTooltip
            },
            {
              icon: 'delete',
              disabled: viewModel.isSaving,
              onClick: (_, rowData) => viewModel.removeAccount((rowData as AccountInfo).account)
            }
          ]}
          columns={renderSelectedAccountsColumns(localizationService)}
          data={viewModel.selectedAccounts}
          options={{
            header: false,
            paging: false,
            search: false,
            padding: 'dense',
            draggable: false,
            actionsColumnIndex: -1
          }}
          localization={localizationService.localizedStrings.insights.materialTable}
        />
      </MUI.Grid>
      <MUI.Grid item xs={12} sm={6}>
        <InsightsMaterialTable
          stateKey={AvailableTableStateKey}
          // This is to disable the card contour
          components={{
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>
          }}
          title={viewModel.isViewingTeachers ? strings.availableTeachers : strings.availableStudents}
          actions={[
            {
              icon: 'add',
              disabled: viewModel.isSaving,
              onClick: (_, rowData) => viewModel.addAccount((rowData as AccountInfo).account)
            }
          ]}
          columns={renderAvailableAccountsColumns(localizationService, getSearchableFields.current)}
          data={viewModel.availableAccounts}
          options={{
            pageSize: DefaultTablePageSize,
            pageSizeOptions: DefaultTablePageSizes,
            header: false,
            emptyRowsWhenPaging: false,
            padding: 'dense',
            draggable: false,
            actionsColumnIndex: -1
          }}
          localization={localizationService.localizedStrings.insights.materialTable}
        />
      </MUI.Grid>
    </MUI.Grid>
  );
});

function renderSelectedAccountsColumns(localizationService: LocalizationService): TableColumn<AccountInfo>[] {
  const insightsStrings = localizationService.localizedStrings.insights;

  return [
    {
      title: '',
      sorting: false,
      render: ({ account }: AccountInfo) => {
        return (
          <MUI.Typography variant="body1">
            {AccountUtils.getDisplayLastFirstName(account, insightsStrings.noName)}
          </MUI.Typography>
        );
      }
    }
  ];
}

function renderAvailableAccountsColumns(
  localizationService: LocalizationService,
  getSearchableFields: (item: AccountInfo) => string[]
): TableColumn<AccountInfo>[] {
  const insightsStrings = localizationService.localizedStrings.insights;

  return [
    {
      title: '',
      sorting: false,
      customFilterAndSearch: (filter: string, account: AccountInfo) =>
        CustomFilterUtils.customFilterAndSearch(filter, account, getSearchableFields),
      render: ({ account }: AccountInfo) => {
        return (
          <MUI.Typography variant="body1">
            {AccountUtils.getDisplayLastFirstName(account, insightsStrings.noName)}
          </MUI.Typography>
        );
      }
    }
  ];
}
