import { css } from '@emotion/css';
import { InsightsButton, ObservablePresenter, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { AllConnectionStatuses, ConnectionStatus, ExternalAssociationListFilters } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps, createTheme, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { DialogResult } from '@shared/services';
import { find } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ExternalAssociationListFiltersDialogProps extends DialogResult<ExternalAssociationListFilters> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  initialFilters?: ExternalAssociationListFilters;
}

export const ExternalAssociationListFiltersDialog = observer((props: ExternalAssociationListFiltersDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx, style, initialFilters, configId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;
  const dateFormats = localizationService.localizedStrings.models.dateFormats;
  const theme = useTheme();

  const contentClassName = css({ minHeight: 100, paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) });

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createExternalAssociationsFilters(configId, onSuccess!, onCancel!, initialFilters)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        maxWidth="xs"
        fullWidth={true}
        scroll="paper"
        onClose={() => viewModel.close()}
        className={className}
        style={style}
      >
        <MUI.DialogTitle>{strings.externalAssociationListFiltersDialogTitle}</MUI.DialogTitle>

        <MUI.DialogContent dividers classes={{ root: contentClassName }}>
          <ObservablePresenter
            className={contentClassName}
            data={viewModel.data}
            render={(data) => (
              <MUI.Box>
                <MUI.FormControl fullWidth sx={{ pb: 1 }}>
                  <MUI.InputLabel htmlFor="teacher-select" shrink>
                    {strings.selectTeacherLabel}
                  </MUI.InputLabel>
                  <MUI.Select
                    value={viewModel.filters.teacherId ?? ''}
                    inputProps={{ id: 'teacher-select' }}
                    displayEmpty
                    onChange={(e) => viewModel.setTeacherId(e.target.value)}
                  >
                    <MUI.MenuItem value="" key="teacher-any">
                      {strings.anyTeacher}
                    </MUI.MenuItem>
                    {data.availableTeachers.map((teacher) => (
                      <MUI.MenuItem key={`teacher-${teacher.id}`} value={teacher.id}>
                        {AccountUtils.getDisplayLastFirstName(teacher)}
                      </MUI.MenuItem>
                    ))}
                  </MUI.Select>
                </MUI.FormControl>
                <MUI.FormControl fullWidth sx={{ pb: 1 }}>
                  <MUI.InputLabel htmlFor="term-select" shrink>
                    {strings.selectTermLabel}
                  </MUI.InputLabel>
                  <MUI.Select
                    value={viewModel.filters.term?.tag ?? ''}
                    inputProps={{ id: 'term-select' }}
                    displayEmpty
                    onChange={(e) => viewModel.setTerm(find(data.availableTerms, { tag: e.target.value }))}
                  >
                    <MUI.MenuItem value="" key="term-any">
                      {strings.anyTerm}
                    </MUI.MenuItem>
                    {data.availableTerms.map((term) => (
                      <MUI.MenuItem key={`term-${term.tag}`} value={term.tag}>
                        {`${term.tag} : ${term.startDay.formattedString(
                          dateFormats.short
                        )} - ${term.endDay.formattedString(dateFormats.short)}`}
                      </MUI.MenuItem>
                    ))}
                  </MUI.Select>
                </MUI.FormControl>
                <MUI.FormControl fullWidth sx={{ pb: 1 }}>
                  <MUI.InputLabel htmlFor="connection-status-select" shrink>
                    {strings.selectConnectionStatusLabel}
                  </MUI.InputLabel>
                  <MUI.Select
                    value={viewModel.filters.connectionStatus}
                    inputProps={{ id: 'connection-status-select' }}
                    onChange={(e) => viewModel.setConnectionStatus(e.target.value as ConnectionStatus)}
                  >
                    {AllConnectionStatuses.map((status) => (
                      <MUI.MenuItem value={status} key={`connection-status-${status}`}>
                        {strings.getConnectionStatusLabel(status)}
                      </MUI.MenuItem>
                    ))}
                  </MUI.Select>
                </MUI.FormControl>
              </MUI.Box>
            )}
          />
        </MUI.DialogContent>

        <MUI.DialogActions>
          <InsightsButton onClick={() => viewModel.clear()}>{strings.resetFilters}</InsightsButton>

          <MUI.Box flex={1} />

          <InsightsButton isDefault onClick={() => viewModel.close()}>
            {strings.closeFilters}
          </InsightsButton>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
