import {
  AccountSectionsEditionCopy,
  Column,
  Expanded,
  InsightsButton,
  ObservableActionsPresenter,
  ObservablePresenter,
  Scroll,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Dialog, DialogActions, DialogContent, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface AccountSectionsEditionCopyDialogProps extends DialogResult<string[]> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  accountId: string;
}

export const AccountSectionsEditionCopyDialog = observer((props: AccountSectionsEditionCopyDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style, accountId, configId, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.account;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createAccountSectionEditionCopy(configId, accountId, onSuccess!, onCancel!)
  );

  const renderActions = () => {
    return (
      <InsightsButton isDefault onClick={() => onCancel?.()}>
        {strings.close}
      </InsightsButton>
    );
  };

  return (
    <ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        PaperProps={{ sx: { height: '75h' } }}
        onClose={() => onCancel?.()}
      >
        <DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => (
              <Column>
                <Expanded>
                  <Scroll direction="y">
                    <AccountSectionsEditionCopy viewModel={data} />
                  </Scroll>
                </Expanded>
              </Column>
            )}
          />
        </DialogContent>
        <DialogActions>
          <ObservableActionsPresenter data={viewModel.data} render={renderActions} renderError={renderActions} />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
