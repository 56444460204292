import { css } from '@emotion/css';
import { DayConfigurationImportMethod, ImportMethod, SchoolCalendarImportFromViewModel } from '@insights/viewmodels';
import { FormControl, InputLabel, MenuItem, Select, SxProps, TextField, Typography, useTheme } from '@mui/material';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Column } from '../layout';

export interface ImportFromConfigurationConfigDetailsProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarImportFromViewModel;
  data: SchoolYearConfigurationModel | undefined;
}

export const ImportFromConfigurationConfigDetails = observer(
  ({ sx, className, data, viewModel }: ImportFromConfigurationConfigDetailsProps) => {
    const { localizationService } = useInsightsServices();
    const theme = useTheme();
    const strings = localizationService.localizedStrings.insights.components.calendar;

    if (data == null) {
      return null;
    }

    const showBellTimesWarning =
      viewModel.bellTimesImportMethod === 'replace' && viewModel.specialDaysImportMethod !== 'replace';
    const showSpecialDaysWarning =
      viewModel.bellTimesImportMethod === 'none' && viewModel.specialDaysImportMethod !== 'none';

    const editableClassName = css([{ paddingBottom: theme.spacing(2) }]);

    return (
      <Column sx={sx} className={className} horizontalContentAlignment="stretch">
        <FormControl className={editableClassName}>
          <InputLabel htmlFor="bells-select">{strings.importBellTimesLabel}</InputLabel>
          <Select
            value={viewModel.bellTimesImportMethod}
            inputProps={{ id: 'bells-select' }}
            onChange={(event) => (viewModel.bellTimesImportMethod = event.target.value as ImportMethod)}
          >
            <MenuItem value={'none' as ImportMethod}>{strings.importBellTimesNone}</MenuItem>
            <MenuItem value={'add' as ImportMethod}>{strings.importBellTimesAdd}</MenuItem>
            <MenuItem value={'replace' as ImportMethod}>{strings.importBellTimesReplace}</MenuItem>
          </Select>
        </FormControl>
        {showBellTimesWarning && (
          <Typography variant="caption" color="red" mt={-1} pb={-1}>
            {strings.importBellTimesWarning}
          </Typography>
        )}
        <FormControl className={editableClassName}>
          <InputLabel htmlFor="special-day-select">{strings.importSpecialDaysLabel}</InputLabel>
          <Select
            value={viewModel.specialDaysImportMethod}
            inputProps={{ id: 'special-day-select' }}
            onChange={(event) => (viewModel.specialDaysImportMethod = event.target.value as ImportMethod)}
          >
            <MenuItem value={'none' as ImportMethod}>{strings.importSpecialDaysNone}</MenuItem>
            <MenuItem value={'add' as ImportMethod}>{strings.importSpecialDaysAdd}</MenuItem>
            <MenuItem value={'replace' as ImportMethod}>{strings.importSpecialDaysReplace}</MenuItem>
          </Select>
        </FormControl>
        {showSpecialDaysWarning && (
          <Typography variant="caption" color="red" mt={-1} pb={-1}>
            {strings.importSpecialDaysWarning}
          </Typography>
        )}
        <FormControl className={editableClassName} disabled={!viewModel.canEditDayConfigurationsImportMethod}>
          <InputLabel htmlFor="day-configurations-select">{strings.importDayConfigurationsLabel}</InputLabel>
          <Select
            value={viewModel.dayConfigurationsImportMethod}
            inputProps={{ id: 'day-configurations-select' }}
            onChange={(event) =>
              (viewModel.dayConfigurationsImportMethod = event.target.value as DayConfigurationImportMethod)
            }
          >
            <MenuItem value={'none' as DayConfigurationImportMethod}>{strings.importDayConfigurationsNone}</MenuItem>
            <MenuItem
              disabled={viewModel.specialDaysImportMethod === 'none'}
              value={'special-days-only' as DayConfigurationImportMethod}
            >
              {strings.importDayConfigurationsSpecialDays}
            </MenuItem>
            <MenuItem
              disabled={viewModel.bellTimesImportMethod === 'none'}
              value={'schedules-only' as DayConfigurationImportMethod}
            >
              {strings.importDayConfigurationsSchedules}
            </MenuItem>
            <MenuItem
              disabled={viewModel.specialDaysImportMethod === 'none' || viewModel.bellTimesImportMethod === 'none'}
              value={'all' as DayConfigurationImportMethod}
            >
              {strings.importDayConfigurationsAll}
            </MenuItem>
          </Select>
        </FormControl>

        <TextField
          disabled={viewModel.dayConfigurationsImportMethod === 'none'}
          className={editableClassName}
          fullWidth
          label={strings.weeksOffsetLabel}
          type="number"
          value={viewModel.weeksOffset}
          onChange={(e) => (viewModel.weeksOffset = Number(e.target.value))}
        />

        <FormControl className={editableClassName}>
          <InputLabel htmlFor="terms-select">{strings.importTermsLabel}</InputLabel>
          <Select
            value={viewModel.termsImportMethod}
            inputProps={{ id: 'terms-select' }}
            onChange={(event) => (viewModel.termsImportMethod = event.target.value as ImportMethod)}
          >
            <MenuItem value={'none' as ImportMethod}>{strings.importTermsNone}</MenuItem>
            <MenuItem value={'add' as ImportMethod}>{strings.importTermsAdd}</MenuItem>
            <MenuItem value={'replace' as ImportMethod}>{strings.importTermsReplace}</MenuItem>
          </Select>
        </FormControl>
      </Column>
    );
  }
);
