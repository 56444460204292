import { ApplicationName, LoadingIndicator } from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

export interface LogoutProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const Logout = observer((props: LogoutProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx } = props;
  const strings = localizationService.localizedStrings.insights;
  const viewModel = useMemo(() => viewModelFactory.createLogout(), []);

  const navigate = useNavigate();

  useEffect(() => {
    void viewModel.onInit(navigate);
  }, []);

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="column" alignItems="center">
      <ApplicationName sx={{ marginTop: '20vh' }} size="extra-large" color="light" />
      <LoadingIndicator sx={{ marginTop: '10vh' }} message={strings.loggingOutMessage} />
    </MUI.Box>
  );
});
