import {
  ObservablePresenter,
  OnboardingCommentView,
  OnboardingStatusChip,
  OnboardingStepSummaryView,
  PageHeaderBar,
  RouterLink
} from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface OnboardingCommentsDashboardProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const OnboardingCommentsDashboard = observer((props: OnboardingCommentsDashboardProps) => {
  const { localizationService, reactRouterRouteService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views;

  const viewModel = useMemo(() => viewModelFactory.createOnboardingCommentsDashboard(), []);

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingSchoolConfigMessage}
          errorMessage={strings.loadingSchoolConfigErrorMessage}
          render={(data) => (
            <MUI.Box>
              <MUI.Grid container>
                {data.map((p) => (
                  <MUI.Grid key={`school-${p.process.configId}-process-${p.process.templateName}`} item xs={12} lg={6}>
                    <MUI.Card>
                      <MUI.CardContent sx={{ width: '100%' }}>
                        <MUI.Stack>
                          <RouterLink
                            underline="none"
                            to={reactRouterRouteService.resolveLocation(RouteTemplates.manageOnboardingProcess, [
                              {
                                name: RouteParamNames.configId,
                                value: p.process.configId
                              },
                              {
                                name: RouteParamNames.processName,
                                value: p.process.templateName
                              }
                            ])}
                          >
                            <MUI.CardHeader
                              sx={{ cursor: 'pointer' }}
                              title={p.schoolName}
                              action={
                                <OnboardingStatusChip
                                  size="small"
                                  disabled
                                  status={p.process.status}
                                  nextDate={p.process.nextTargetDate}
                                  targetDate={p.process.finalTargetDate}
                                />
                              }
                            />
                          </RouterLink>
                          {p.process.commentsViewModel.comments.map((c, index) => (
                            <OnboardingCommentView key={`comment-${index}`} viewModel={c} />
                          ))}
                          {p.steps.map((s) => (
                            <MUI.Box key={`step-${s.stepSummary.templateName}`}>
                              <OnboardingStepSummaryView step={s.stepSummary} />
                              {s.comments.comments.map((c, index) => (
                                <OnboardingCommentView key={`step-comment-${index}`} viewModel={c} />
                              ))}
                            </MUI.Box>
                          ))}
                        </MUI.Stack>
                      </MUI.CardContent>
                    </MUI.Card>
                  </MUI.Grid>
                ))}
              </MUI.Grid>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
