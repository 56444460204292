import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { ImportSession } from '@shared/models/import';
import { AllFileEncodings, ExposedFileKinds, FileEncoding, FileKind } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportSessionAddOrEditExpectedFileDialogProps extends DialogResult<ImportSession> {
  sx?: SxProps;
  configId: string;
  session: ImportSession;
  fileLabel?: string;
}

export const ImportSessionAddOrEditExpectedFileDialog = observer(
  (props: ImportSessionAddOrEditExpectedFileDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { session, configId, fileLabel, sx, onSuccess, onCancel } = props;
    const strings = localizationService.localizedStrings.insights.views.imports;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createImportSessionAddOrEditExpectedFile(configId, session, fileLabel ?? '', onSuccess!, onCancel!)
    );

    return (
      <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
        <MUI.Dialog sx={sx} open={true} maxWidth="sm" fullWidth onClose={() => viewModel.cancel()}>
          <MUI.DialogTitle>{strings.addExpectedFileTitle}</MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <MUI.TextField
              sx={{ mb: 1 }}
              label={strings.labelLabel}
              inputProps={{ maxLength: 1 }}
              autoFocus
              value={viewModel.label}
              helperText={viewModel.labelError}
              error={viewModel.labelError.length > 0}
              onChange={(e) => (viewModel.label = e.target.value)}
            />
            <MUI.TextField
              sx={{ mb: 1 }}
              fullWidth
              label={strings.fileNameLabel}
              inputProps={{ maxLength: 50 }}
              value={viewModel.name}
              onChange={(e) => (viewModel.name = e.target.value)}
            />
            <MUI.TextField
              sx={{ mb: 1 }}
              fullWidth
              multiline
              label={strings.fileDescriptionLabel}
              inputProps={{ maxLength: 250 }}
              value={viewModel.description}
              onChange={(e) => (viewModel.description = e.target.value)}
            />
            <MUI.FormControl sx={{ mb: 1 }} fullWidth>
              <MUI.InputLabel htmlFor="file-kind-select">{strings.fileKindLabel}</MUI.InputLabel>
              <MUI.Select
                value={viewModel.kind}
                inputProps={{ id: 'file-kind-select' }}
                onChange={(e) => (viewModel.kind = e.target.value as FileKind)}
              >
                {ExposedFileKinds.map((kind) => (
                  <MUI.MenuItem key={`file-kind-${kind}`} value={kind}>
                    <MUI.Typography>{strings.fileKind(kind)}</MUI.Typography>
                  </MUI.MenuItem>
                ))}
              </MUI.Select>
            </MUI.FormControl>
            {viewModel.showEncoding && (
              <MUI.FormControl sx={{ mb: 1 }} fullWidth>
                <MUI.InputLabel htmlFor="file-encoding-select">{strings.fileEncodingLabel}</MUI.InputLabel>
                <MUI.Select
                  value={viewModel.encoding}
                  inputProps={{ id: 'file-encoding-select' }}
                  onChange={(e) => (viewModel.encoding = e.target.value as FileEncoding)}
                >
                  {AllFileEncodings.map((encoding) => (
                    <MUI.MenuItem key={`file-encoding-${encoding}`} value={encoding}>
                      <MUI.Typography>{strings.fileEncoding(encoding)}</MUI.Typography>
                    </MUI.MenuItem>
                  ))}
                </MUI.Select>
              </MUI.FormControl>
            )}
            {viewModel.showHasHeader && (
              <MUI.FormControlLabel
                sx={{ mb: 1 }}
                label={strings.fileHasHeaderLabel}
                control={
                  <MUI.Checkbox
                    checked={viewModel.hasHeader}
                    onChange={(e) => (viewModel.hasHeader = e.target.checked)}
                  />
                }
              />
            )}
            {viewModel.showTopRowsSkipped && (
              <MUI.TextField
                sx={{ mb: 1 }}
                fullWidth
                label={strings.topRowsSkippedLabel}
                type="number"
                value={viewModel.topRowsSkipped}
                onChange={(e) => (viewModel.topRowsSkipped = Number(e.target.value))}
              />
            )}
            {viewModel.showSheetName && (
              <MUI.TextField
                sx={{ mb: 1 }}
                fullWidth
                label={strings.sheetNameLabel}
                inputProps={{ maxLength: 50 }}
                value={viewModel.sheetName}
                onChange={(e) => (viewModel.sheetName = e.target.value)}
              />
            )}
          </MUI.DialogContent>

          <MUI.DialogActions>
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => viewModel.cancel()}>
              {strings.cancel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isExecuting={viewModel.isExecuting}
              isDisabled={!viewModel.canAdd}
              onClick={() => void viewModel.saveFile()}
            >
              {viewModel.isNew ? strings.addButton : strings.saveButton}
            </InsightsButton>
          </MUI.DialogActions>
        </MUI.Dialog>
      </MUI.ThemeProvider>
    );
  }
);
