import {
  AuthorizationRoleCondition,
  ErrorIndicator,
  LoadingIndicator,
  ObservablePresenter,
  RequiresFeatureCondition,
  SchoolDayHeader,
  SchoolWeekHeader,
  SectionName,
  WeekPagingNavigation
} from '@insights/components';
import {
  PublishedTasksByGradeInfo,
  PublishedTasksByGradePageInfo,
  PublishedTasksByGradeViewModel
} from '@insights/viewmodels';
import { mdiCalendarEdit } from '@mdi/js';
import Icon from '@mdi/react';
import ExportIcon from '@mui/icons-material/ArrowDownward';
import * as MUI from '@mui/material';
import { Box, SxProps, styled, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DateUtils } from '@shared/components/utils';
import { SchoolDay } from '@shared/models/calendar';
import { ContentDefinitionModel } from '@shared/models/content';
import { AdminAuthorizationRoles } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { MinLayoutWidth, MinTinyChartHeight } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface PublishedTasksByGradeProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: PublishedTasksByGradeViewModel;
  displayWeekNavigation?: boolean;
}

export const PublishedTasksByGrade = observer((props: PublishedTasksByGradeProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, style, viewModel, displayWeekNavigation = true } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;
  const theme = useTheme();

  return (
    <Root sx={sx} className={className} style={style}>
      <RequiresFeatureCondition
        requiredFeature="all-published-tasks-widget"
        featureNotAvailableContent={{
          fill: true,
          padding: 2,
          title: strings.publishedTasksTitle,
          titleVariant: 'h6'
        }}
      >
        <ObservablePresenter
          className="container"
          data={viewModel.data}
          indicatorsSize="normal"
          loadingMessage={strings.loadingDataMessage}
          errorMessage={strings.loadingDataErrorMessage}
          render={(configData) => (
            <MUI.Box display="flex" flexDirection="column">
              <MUI.Box marginLeft={3} marginBottom={2} display="flex" flexDirection="row" alignItems="center">
                <MUI.Typography variant="h6">{strings.publishedTasksTitle}</MUI.Typography>

                <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
                  <MUI.Tooltip title={strings.editAssessmentPlanningDatesButtonTooltip}>
                    <MUI.IconButton onClick={() => void viewModel.editAssessmentPlanningDates()}>
                      <Icon path={mdiCalendarEdit} size={1} color={theme.palette.text.secondary} />
                    </MUI.IconButton>
                  </MUI.Tooltip>
                </AuthorizationRoleCondition>

                <MUI.Box flex={1} />

                <MUI.Typography variant="body1">{strings.showOnlyImportantTasks}</MUI.Typography>
                <MUI.Switch
                  checked={viewModel.importantTasksOnly}
                  onChange={(_, checked) => (viewModel.importantTasksOnly = checked)}
                />

                <MUI.Tooltip title={strings.csvExportTooltip}>
                  <MUI.IconButton disabled={viewModel.isExporting} onClick={() => void viewModel.exportToCsv()}>
                    {viewModel.isExporting ? <LoadingIndicator size="tiny" /> : <ExportIcon />}
                  </MUI.IconButton>
                </MUI.Tooltip>
              </MUI.Box>

              <MUI.Box flex={1}>
                {viewModel.pageData.case({
                  pending: () => renderEmptyTable(viewModel, configData, localizationService),
                  rejected: () => <ErrorIndicator message={strings.loadingDataErrorMessage} />,
                  fulfilled: (data) => renderTable(viewModel, configData, data, localizationService)
                })}
              </MUI.Box>

              <MUI.Box
                className={'footer'}
                marginTop={2}
                marginLeft={3}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <MUI.Box flex={1} />

                {/* Show a loading indicator when changing the current page */}
                {viewModel.pageData.state === 'pending' && <LoadingIndicator size="tiny" />}

                {displayWeekNavigation && <WeekPagingNavigation pagination={viewModel.pagination} />}
              </MUI.Box>
            </MUI.Box>
          )}
        />
      </RequiresFeatureCondition>
    </Root>
  );
});

function renderEmptyTable(
  viewModel: PublishedTasksByGradeViewModel,
  configData: PublishedTasksByGradeInfo,
  localizationService: LocalizationService
) {
  const pageRange = viewModel.pagination?.currentPage;
  if (pageRange != null) {
    return renderTable(
      viewModel,
      configData,
      {
        schoolDays: configData.schoolDays.filter((schoolDay) =>
          schoolDay.day.isWithin(pageRange.startDay, pageRange.endDay)
        ),
        dayInfos: [],
        weekInfos: []
      },
      localizationService
    );
  }

  return null;
}

function renderTable(
  viewModel: PublishedTasksByGradeViewModel,
  configData: PublishedTasksByGradeInfo,
  pageData: PublishedTasksByGradePageInfo,
  localizationService: LocalizationService
) {
  return (
    <MUI.Table>
      {renderTableHeader(configData, pageData, localizationService)}
      {renderTableBody(viewModel, configData, pageData, localizationService)}
    </MUI.Table>
  );
}

function renderTableHeader(
  _configData: PublishedTasksByGradeInfo,
  pageData: PublishedTasksByGradePageInfo,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  return (
    <MUI.TableHead>
      <MUI.TableRow className={'tableRow'}>
        <MUI.TableCell className={'gradeLevelCell'}>
          <MUI.Typography variant="subtitle2" className={'headerText'}>
            {strings.grade}
          </MUI.Typography>
        </MUI.TableCell>

        {pageData.schoolDays
          .filter((schoolDay) => !DateUtils.isWeekend(schoolDay.day))
          .map((schoolDay) => (
            <MUI.TableCell key={schoolDay.day.asDateString} className={'tableDayCell'}>
              <SchoolDayHeader schoolDay={schoolDay} displayType="column-header" />
            </MUI.TableCell>
          ))}

        <MUI.TableCell className={classNames('tableDayCell', 'weekCell')}>
          <SchoolWeekHeader schoolDay={pageData.schoolDays[0]} displayType="column-header" />
        </MUI.TableCell>
      </MUI.TableRow>
    </MUI.TableHead>
  );
}

function renderTableBody(
  viewModel: PublishedTasksByGradeViewModel,
  configData: PublishedTasksByGradeInfo,
  pageData: PublishedTasksByGradePageInfo,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;
  const materialTableStrings = localizationService.localizedStrings.insights.materialTable;

  return (
    <MUI.TableBody>
      {configData.gradeLevels.length === 0 && (
        <MUI.TableRow>
          <MUI.TableCell colSpan={99} align="center">
            <MUI.Typography variant="body2">{materialTableStrings.body?.emptyDataSourceMessage ?? ''}</MUI.Typography>
          </MUI.TableCell>
        </MUI.TableRow>
      )}
      {configData.gradeLevels.length > 0 &&
        configData.gradeLevels.map((gradeLevelInfo) => {
          const weekInfo = pageData.weekInfos.find(
            (i) => i.schoolDay == null && i.gradeLevel === gradeLevelInfo.gradeLevel
          );

          const publishedTasksForWeekCount = weekInfo?.publishedTasks.length ?? 0;
          const weekValueCellStyle = publishedTasksForWeekCount > 0 ? 'tableDayWithValueCell' : '';

          return (
            <MUI.TableRow key={gradeLevelInfo.gradeLevel} className={'tableRow'}>
              <MUI.TableCell className={'gradeLevelCell'}>
                <SectionName
                  color="darker-bmgray"
                  title={gradeLevelInfo.gradeLevel}
                  subInformation1={strings.localizedNumberOfSections(gradeLevelInfo.sections.size)}
                />
              </MUI.TableCell>

              {pageData.schoolDays
                .filter((schoolDay) => !DateUtils.isWeekend(schoolDay.day))
                .map((schoolDay) => {
                  const dayInfo = pageData.dayInfos.find(
                    (d) => d.schoolDay!.day.isSame(schoolDay.day) && d.gradeLevel === gradeLevelInfo.gradeLevel
                  );
                  const publishedTasksCount = dayInfo?.publishedTasks.length ?? 0;

                  const valueCellStyle = publishedTasksCount > 0 ? 'tableDayWithValueCell' : '';

                  return (
                    <MUI.TableCell
                      key={`${gradeLevelInfo.gradeLevel} - ${schoolDay.day.asDateString}`}
                      className={classNames('tableDayCell', valueCellStyle)}
                      onClick={() =>
                        dayInfo &&
                        publishedTasksCount > 0 &&
                        void showDetail(
                          viewModel,
                          gradeLevelInfo.gradeLevel,
                          gradeLevelInfo.sections.size,
                          dayInfo.schoolDay!,
                          dayInfo.schoolDay!,
                          dayInfo.publishedTasks
                        )
                      }
                    >
                      {publishedTasksCount > 0 && (
                        <MUI.Typography variant="body1">{publishedTasksCount}</MUI.Typography>
                      )}
                    </MUI.TableCell>
                  );
                })}

              <MUI.TableCell
                className={classNames('tableDayCell', 'weekCell', weekValueCellStyle)}
                onClick={() =>
                  weekInfo &&
                  publishedTasksForWeekCount > 0 &&
                  void showDetail(
                    viewModel,
                    gradeLevelInfo.gradeLevel,
                    gradeLevelInfo.sections.size,
                    pageData.schoolDays[0],
                    pageData.schoolDays[pageData.schoolDays.length - 1],
                    weekInfo.publishedTasks
                  )
                }
              >
                {publishedTasksForWeekCount > 0 && (
                  <MUI.Typography variant="body1">{publishedTasksForWeekCount}</MUI.Typography>
                )}
              </MUI.TableCell>
            </MUI.TableRow>
          );
        })}
    </MUI.TableBody>
  );
}

async function showDetail(
  viewModel: PublishedTasksByGradeViewModel,
  gradeLevel: string,
  gradeLevelSectionCount: number,
  fromDay: SchoolDay,
  toDay: SchoolDay,
  tasks: ContentDefinitionModel[]
): Promise<void> {
  await viewModel.showDetail(viewModel.configId, gradeLevel, gradeLevelSectionCount, fromDay, toDay, tasks);
}

const Root = styled(Box)(({ theme }) => ({
  '.container': {
    minHeight: MinTinyChartHeight,
    minWidth: MinLayoutWidth
  },

  '.table': {
    tableLayout: 'fixed'
  },
  '.tableRow': {
    verticalAlign: 'center',
    height: '50px'
  },
  '.gradeLevelCell': {
    width: '20%',
    cursor: 'default'
  },
  '.tableDayCell': {
    textAlign: 'center',
    paddingRight: theme.spacing(2),
    width: 'calc(80%/6)',
    cursor: 'default'
  },
  '.tableDayWithValueCell': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[50]
    }
  },
  '.weekCell': {
    borderLeft: `1px solid ${grey[300]}`
  },
  '.headerText': {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    lineHeight: 'normal'
  },
  '.footer': {
    minHeight: 30
  }
}));
