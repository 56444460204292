import { MaximumTermTagLength, SchoolCalendarTermViewModel } from '@insights/viewmodels';
import { SxProps, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Column } from '../layout';

export interface EditableTermTagProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarTermViewModel;
}

export const EditableTermTag = observer((props: EditableTermTagProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, style, sx, className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Column sx={sx} className={className} style={style}>
      <TextField
        label={strings.tag}
        value={viewModel.tag}
        onChange={(event) => (viewModel.tag = event.target.value)}
        inputProps={{ maxLength: MaximumTermTagLength }}
      />
    </Column>
  );
});
