import { Checkbox, FormControl, FormControlLabel, FormHelperText, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface BlackbaudSkyUnmappedAssignmentTypesProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  isIgnoringUnmappedTypes: boolean;
  onChange: (isIgnoringUnmappedTypes: boolean) => void;
}

export const BlackbaudSkyUnmappedAssignmentTypes = observer((props: BlackbaudSkyUnmappedAssignmentTypesProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, isIgnoringUnmappedTypes, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <FormControl sx={sx} className={className} style={style}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isIgnoringUnmappedTypes}
            onChange={(event) => onChange(event.target.checked)}
          />
        }
        label={strings.blackbaudSkyIgnoringUnmappedTypesLabel}
      />
      <FormHelperText>
        {isIgnoringUnmappedTypes
          ? strings.blackbaudSkyIgnoringUnmappedTypesNotice
          : strings.blackbaudSkyNotIgnoringUnmappedTypesNotice}
      </FormHelperText>
    </FormControl>
  );
});
