import { css } from '@emotion/css';
import { MaximumSectionRoomNameLength, SectionEditionViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  Input,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { AccountUtils } from '@shared/components/utils/models';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Column } from '../layout';
import { EditableSectionScheduleDay } from './EditableSectionScheduleDay';
import { EditableSectionScheduleTime } from './EditableSectionScheduleTime';

export interface SectionScheduleEditionProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SectionEditionViewModel;
}

export const SectionScheduleEdition = observer((props: SectionScheduleEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, viewModel, style } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;
  const theme = useTheme();

  const teacherChipClassName = css({
    '& .MuiChip-label': { fontSize: theme.typography.caption.fontSize }
  });

  return (
    <Column sx={sx} className={className} style={style}>
      <Table>
        <TableHead>
          <Row>
            <Cell>{strings.term}</Cell>
            <Cell>{strings.day}</Cell>
            <Cell>{strings.time}</Cell>
            <Cell>{strings.room}</Cell>
            <Cell>{strings.teachers}</Cell>
            <Cell />
          </Row>
        </TableHead>
        <TableBody>
          {viewModel.allEditableSchedules.map((schedule) => {
            const rowBackColor = schedule.shouldBeDeleted ? red[100] : schedule.hasChanges ? green[100] : undefined;

            const hasTerms = viewModel.terms.length > 0;
            const isTermTagValid =
              (!hasTerms && schedule.termTag.length === 0) ||
              viewModel.terms.find((t) => t.tag == schedule.termTag) != null;

            return (
              <Row sx={{ backgroundColor: rowBackColor }} key={`section-schedule-${schedule.id}`}>
                <Cell sx={{ maxWidth: 100 }}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    {hasTerms && (
                      <Autocomplete
                        fullWidth
                        disableClearable
                        value={schedule.termTag}
                        options={viewModel.terms.map((t) => t.tag)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{ ...params.InputProps, sx: { ...theme.typography.body2 } }}
                          />
                        )}
                        onChange={(_, value) => (schedule.termTag = value ?? '')}
                      />
                    )}
                    {!hasTerms && isTermTagValid && <Typography variant="body2">{strings.none}</Typography>}
                    {!hasTerms && !isTermTagValid && (
                      <>
                        <Typography variant="body2" color="error">
                          {schedule.termTag}
                        </Typography>
                        <IconButton size="small" onClick={() => (schedule.termTag = '')}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Cell>
                <Cell sx={{ minWidth: 200 }}>
                  <EditableSectionScheduleDay viewModel={viewModel} schedule={schedule} />
                </Cell>
                <Cell sx={{ minWidth: 300 }}>
                  {/* The bottom margin is for aligning items vertically. This control is the only
                        one using shrunk labels in its controls. */}
                  <EditableSectionScheduleTime sx={{ mb: 2 }} viewModel={viewModel} schedule={schedule} />
                </Cell>
                <Cell sx={{ maxWidth: 150 }}>
                  <Input
                    sx={{ m: 1, ...theme.typography.body2 }}
                    value={schedule.roomName}
                    onChange={(event) => (schedule.roomName = event.target.value)}
                    inputProps={{ maxLength: MaximumSectionRoomNameLength }}
                  />
                </Cell>
                <Cell sx={{ maxWidth: 350 }}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    value={schedule.teacherIds.map((id) => ({
                      id,
                      label: AccountUtils.getDisplayLastFirstName(
                        viewModel.teachersById[id],
                        `${strings.invalidTeacher} (${id})`
                      )
                    }))}
                    options={viewModel.teachers.map((teacher) => ({
                      id: teacher.id,
                      label: AccountUtils.getDisplayLastFirstName(teacher, teacher.email)
                    }))}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(e, values) => (schedule.teacherIds = values.map((v) => v.id))}
                    size="small"
                    renderTags={(values, getTagProps) => (
                      <Stack direction="row" flexWrap="wrap">
                        {values.map((v, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={v.id}
                            label={v.label}
                            size="small"
                            className={teacherChipClassName}
                          />
                        ))}
                      </Stack>
                    )}
                  />
                </Cell>
                <Cell>
                  {!schedule.shouldBeDeleted && (
                    <IconButton onClick={() => schedule.markAsDeleted()}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Cell>
              </Row>
            );
          })}
        </TableBody>
        <TableFooter>
          <Row>
            <Cell />
            <Cell />
            <Cell />
            <Cell />
            <Cell />
            <Cell>
              <IconButton onClick={() => viewModel.addSchedule()}>
                <AddIcon />
              </IconButton>
            </Cell>
          </Row>
        </TableFooter>
      </Table>
    </Column>
  );
});

const Cell = styled(TableCell)(({ theme }) => ({
  p: theme.spacing(0.25)
}));

const Row = styled(TableRow)(() => ({
  height: 'auto'
}));
