import { AccountList, ObservablePresenter, PageHeaderBar, StudentBehaviorAggregation } from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface StudentsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const Students = observer((props: StudentsProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.students;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createStudents(configId), [configId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Student List' });
  });

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingStudentsMessage}
          errorMessage={strings.loadingStudentsErrorMessage}
          render={(data) => (
            <MUI.Box display="flex" flexDirection="column">
              <MUI.Card sx={{ minWidth: MinLayoutWidth }}>
                <MUI.Box height="100%" width="100%">
                  {data.opensAppBehavior != null && data.completesTasksBehavior != null && (
                    <StudentBehaviorAggregation
                      opensApp={data.opensAppBehavior}
                      completesTasks={data.completesTasksBehavior}
                      invitesParent={data.invitesParentBehavior}
                    />
                  )}

                  <AccountList
                    title={strings.students}
                    accounts={data.students}
                    accountType="student"
                    configId={viewModel.configId}
                    exportableViewModel={data}
                  />
                </MUI.Box>
              </MUI.Card>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
