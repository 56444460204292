import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { OnboardingQuestionViewModel } from '@insights/viewmodels';
import { Link, Stack, SxProps, Typography } from '@mui/material';
import { OnboardingAnswer } from '@shared/models/onboarding/interfaces';
import { LocalizationService } from '@shared/resources/services';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { OnboardingTypographySelector } from './OnboardingTypographySelector';

export interface PreviousAnswersProps {
  sx?: SxProps;
  className?: string;
  viewModel: OnboardingQuestionViewModel;
}

export const PreviousAnswers = observer((props: PreviousAnswersProps) => {
  const { sx, className, viewModel } = props;
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <Stack sx={sx} className={className} alignItems="flex-start" p={1} spacing={1}>
      <Typography variant="subtitle2">{strings.previousAnswersHeader}</Typography>
      {viewModel.previousAnswers.map((answer, i) => (
        <Stack key={`previous-answer-${i}`} sx={{ borderTopWidth: 1, borderTopStyle: 'solid', width: '100%' }} pt={1}>
          {renderAnswer(viewModel, answer, localizationService)}
        </Stack>
      ))}
    </Stack>
  );
});

function renderAnswer(
  viewModel: OnboardingQuestionViewModel,
  answer: OnboardingAnswer,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const dateStrings = localizationService.localizedStrings.models.dateFormats;

  switch (answer.kind) {
    case 'simple-text':
      return <Typography variant="body2">{answer.simpleTextValue}</Typography>;
    case 'large-text':
      return (
        <Stack>
          <Typography variant="body2" whiteSpace="pre-line">
            {answer.largeTextValue}
          </Typography>
        </Stack>
      );
    case 'single-choice': {
      const label = viewModel.choices.find((c) => c.value == answer.singleChoiceValue)?.label;
      return label != null ? (
        <OnboardingTypographySelector texts={label} variant="body2" />
      ) : (
        <Typography variant="body2">
          {answer.singleChoiceValue.length > 0 ? `(${answer.singleChoiceValue})` : strings.noAnswers}
        </Typography>
      );
    }
    case 'multiple-choice': {
      const choices = viewModel.choices.filter((c) => answer.multipleChoicesValue.includes(c.value));
      return choices.length > 0 ? (
        <Stack>
          {choices.map((choice, i) => (
            <OnboardingTypographySelector key={`answer-label-${i}`} texts={choice.label} variant="body2" />
          ))}
        </Stack>
      ) : answer.multipleChoicesValue.length > 0 ? (
        <Stack>
          {answer.multipleChoicesValue.map((value, i) => (
            <Typography key={`answer-value-${i}`} variant="body2">
              {`("${value}")`}
            </Typography>
          ))}
        </Stack>
      ) : (
        <Typography variant="body2">{strings.noAnswers}</Typography>
      );
    }
    case 'date':
      return (
        <Typography variant="body2">
          {answer.dateValue != null ? format(answer.dateValue, dateStrings.mediumUnabridged) : strings.noAnswers}
        </Typography>
      );
    case 'date-time':
      return (
        <Typography variant="body2">
          {answer.dateTimeValue != null
            ? format(answer.dateTimeValue, dateStrings.mediumUnabridgedWithTime)
            : strings.noAnswers}
        </Typography>
      );
    case 'time':
      return <Typography variant="body2">{answer.timeValue ?? strings.noAnswers}</Typography>;
    case 'file':
      return (
        <Typography variant="body2">
          {answer.fileValue != null ? (
            <Link target="_blank" href={answer.fileValue}>
              {answer.fileValue}
            </Link>
          ) : (
            strings.noAnswers
          )}
        </Typography>
      );
  }
}
