import { CanvasAccountSettingsViewModel } from '@insights/viewmodels';
import { SxProps } from '@mui/material';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react';
import { Column } from '../layout';
import { CanvasAssignmentGroupMappings } from './CanvasAssignmentGroupMappings';
import { CanvasUnmappedAssignmentGroups } from './CanvasUnmappedAssignmentGroups';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';

export interface CanvasAccountSettingsProps {
  sx?: SxProps;
  className?: string;
  viewModel: CanvasAccountSettingsViewModel;
  localizationService?: LocalizationService;
}

export const CanvasAccountSettings = observer((props: CanvasAccountSettingsProps) => {
  const { viewModel, sx, className } = props;

  return (
    <Column sx={sx} className={className}>
      <CanvasAssignmentGroupMappings sx={{ m: 0, mb: 1 }} viewModel={viewModel} />

      <CanvasUnmappedAssignmentGroups
        sx={{ m: 0, mb: 1 }}
        isIgnoringUnmappedGroups={viewModel.isIgnoringUnmappedGroups}
        onChange={(value) => (viewModel.isIgnoringUnmappedGroups = value)}
      />

      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
