import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { InsightsButton } from './InsightsButton';

export interface MessageDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  message: string;
  okButtonCaption?: string;
}

export const MessageDialog = observer((props: MessageDialogProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, style, onCancel, onSuccess, title, message, okButtonCaption } = props;
  const strings = localizationService.localizedStrings.insights.components.messageDialog;

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        onClose={() => onCancel!()}
        maxWidth="sm"
        fullWidth={true}
        className={className}
        style={style}
      >
        <MUI.DialogTitle>{title ?? strings.defaultTitle}</MUI.DialogTitle>

        <MUI.DialogContent>
          <MUI.Box>
            <MUI.Typography whiteSpace="pre-line">{message}</MUI.Typography>
          </MUI.Box>
        </MUI.DialogContent>

        <MUI.DialogActions>
          <InsightsButton isDefault onClick={() => onSuccess!()}>
            {okButtonCaption ?? strings.defaultOkButtonCaption}
          </InsightsButton>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
