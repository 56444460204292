// TODO MUI.Box

import { Box, SxProps } from '@mui/material';
import * as React from 'react';
import { Row } from './layout';

export interface IndicatorDotProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  color: string;
  isFilled: boolean;
}

export const IndicatorDot = ({ className, sx, style, color, isFilled }: IndicatorDotProps) => {
  const dotStyle: React.CSSProperties = {
    backgroundColor: isFilled ? color : 'transparent',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: color
  };

  return (
    <Row sx={sx} className={className} style={style}>
      <Box flex={1} sx={{ width: '100%', height: '100%', borderRadius: '50%' }} style={dotStyle} />
    </Row>
  );
};
