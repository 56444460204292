import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { EditableOnboardingAnswer } from '@shared/models/onboarding/implementations';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../../AuthorizationRoleCondition';
import { InsightsButton } from '../../InsightsButton';

export interface SensitiveTextFieldQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  answer: EditableOnboardingAnswer;
  onViewAnswer: () => Promise<void>;
  disabled?: boolean;
  clientId?: string;
}

export const SensitiveTextFieldQuestion = observer((props: SensitiveTextFieldQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, answer, onViewAnswer, disabled, clientId } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const label = answer.hasAnswer ? strings.answered : strings.notAnswered;
  const buttonLabel = answer.hasAnswer ? strings.changeSensitiveAnswerButton : strings.setSensitiveAnswerButton;

  const [localAnswer, setLocalAnswer] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const saveAnswer = () => {
    // Close before assigning.
    const answerCopy = localAnswer;

    setIsOpen(false);
    setLocalAnswer('');

    answer.sensitiveSimpleTextValue = answerCopy;
  };

  const cancel = () => {
    setIsOpen(false);
  };

  const allowedAccountIds = clientId != null ? [clientId] : [];

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="row" alignItems="center">
      <MUI.Typography mr={2} color="primary">
        {label}
      </MUI.Typography>
      {/* Any assigned client can modify the answer, as long as they're not school staff. */}
      <AuthorizationRoleCondition
        allowedRoles={['super-admin']}
        allowedAccountIds={allowedAccountIds}
        blockedRoles={['school-staff']}
      >
        <MUI.Button
          sx={{ mr: 2 }}
          variant="contained"
          disabled={disabled}
          onClick={() => runInAction(() => setIsOpen(true))}
        >
          {buttonLabel}
        </MUI.Button>
      </AuthorizationRoleCondition>
      {answer.hasAnswer && (
        <AuthorizationRoleCondition allowedRoles={['super-admin', 'admin']} allowedAccountIds={allowedAccountIds}>
          <MUI.Button title={strings.viewSensitiveAnswer} variant="contained" onClick={() => void onViewAnswer()}>
            {strings.viewSensitiveAnswer}
          </MUI.Button>
        </AuthorizationRoleCondition>
      )}

      <MUI.Dialog maxWidth="sm" fullWidth open={isOpen} onAbort={cancel} onClose={cancel}>
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <MUI.DialogTitle>{strings.sensitiveAnswerTitle}</MUI.DialogTitle>
          <MUI.DialogContent>
            <MUI.TextField
              type="password"
              label={strings.sensitiveAnswerLabel}
              autoFocus
              fullWidth
              value={localAnswer}
              onChange={(e) => runInAction(() => setLocalAnswer(e.target.value))}
              onSubmit={saveAnswer}
            />
          </MUI.DialogContent>
          <MUI.DialogActions>
            <InsightsButton onClick={cancel}>{strings.cancelLabel}</InsightsButton>
            <InsightsButton isSubmit onClick={saveAnswer}>
              {strings.saveLabel}
            </InsightsButton>
          </MUI.DialogActions>
        </form>
      </MUI.Dialog>
    </MUI.Box>
  );
});
