import { InsightsButton, ObservablePresenter, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { Autocomplete, SxProps, createTheme } from '@mui/material';
import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { ImportSession } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportSessionImportFromSchoolDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionImportFromSchoolDialog = observer((props: ImportSessionImportFromSchoolDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx, configId, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createImportSessionImportFromSchool(configId, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="xs"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <MUI.DialogTitle>{strings.importSessionTitle}</MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <MUI.Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
              <MUI.FormControl sx={{ mb: 2 }} fullWidth>
                <MUI.InputLabel htmlFor="school-year-select">{strings.schoolYearLabel}</MUI.InputLabel>
                <MUI.Select
                  inputProps={{ id: 'school-year-select' }}
                  value={viewModel.selectedYear}
                  onChange={(e) => (viewModel.selectedYear = e.target.value as number)}
                >
                  {viewModel.years.map((y) => (
                    <MUI.MenuItem key={`year-${y}`} value={y}>
                      {y}
                    </MUI.MenuItem>
                  ))}
                </MUI.Select>
              </MUI.FormControl>

              <ObservablePresenter
                indicatorsSize="small"
                data={viewModel.configurations}
                errorMessage={strings.loadingConfigurationsError}
                render={(configurations) => (
                  <Autocomplete
                    sx={{ mb: 2 }}
                    options={configurations}
                    value={viewModel.selectedConfiguration}
                    onChange={(_, v: SchoolYearConfigurationSummary | null) =>
                      (viewModel.selectedConfiguration = v ?? undefined)
                    }
                    renderInput={(params) => (
                      <MUI.TextField
                        {...params}
                        fullWidth
                        label={strings.schoolLabel}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    getOptionLabel={(c) => c.schoolName}
                    noOptionsText={strings.noSchoolConfiguration}
                  />
                )}
              />

              <ObservablePresenter
                indicatorsSize="small"
                data={viewModel.sessions}
                errorMessage={strings.sessionsErrorMessage}
                render={(sessions) => (
                  <Autocomplete
                    sx={{ mb: 2 }}
                    options={sessions}
                    value={viewModel.selectedSession}
                    onChange={(_, v: ImportSession | null) => (viewModel.selectedSession = v ?? undefined)}
                    renderInput={(params) => (
                      <MUI.TextField
                        {...params}
                        fullWidth
                        label={strings.importSessionLabel}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    getOptionLabel={(s) => s.name}
                    noOptionsText={
                      viewModel.selectedConfiguration == null ? strings.noSchoolSelected : strings.noSession
                    }
                  />
                )}
              />
            </MUI.Box>

            {viewModel.hasError && (
              <MUI.Typography variant="caption" color="error">
                {strings.importSessionFromSchoolError}
              </MUI.Typography>
            )}
          </MUI.DialogContent>

          <MUI.DialogActions>
            <InsightsButton isExecuting={viewModel.isImporting} onClick={() => void viewModel.cancel()}>
              {strings.cancel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={!viewModel.canImport}
              isExecuting={viewModel.isImporting}
              onClick={() => void viewModel.import()}
            >
              {strings.importSessionButton}
            </InsightsButton>
          </MUI.DialogActions>
        </form>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
