import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { EditableOnboardingText } from '@shared/models/onboarding/implementations';
import { observer } from 'mobx-react';
import * as React from 'react';
import { OnboardingTextEdition } from './OnboardingTextEdition';

export interface OnboardingTextsEditionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  texts: EditableOnboardingText[];
  rows?: number;
  variant?: Variant;
  enforcePlainText?: boolean;
  onFileUpload?: (dataUrl: string, fileName?: string) => Promise<void>;
}

export const OnboardingTextsEdition = observer((props: OnboardingTextsEditionProps) => {
  const { className, style, sx, title, texts, rows, variant, onFileUpload, enforcePlainText } = props;
  const enText = texts.find((t) => t.languageCode === 'en');
  const frText = texts.find((t) => t.languageCode === 'fr');
  const enLabel = (title && `${title} - English`) ?? 'English';
  const frLabel = (title && `${title} - Français`) ?? 'Français';

  return (
    <MUI.Grid sx={sx} container className={className} style={style}>
      {enText && (
        <MUI.Grid item sm={12} md={6}>
          <OnboardingTextEdition
            sx={{ mb: 1 }}
            text={enText}
            variant={variant}
            rows={rows}
            label={enLabel}
            enforcePlainText={enforcePlainText}
            onFileUpload={onFileUpload}
          />
        </MUI.Grid>
      )}
      {frText && (
        <MUI.Grid item sm={12} md={6}>
          <OnboardingTextEdition
            sx={{ mb: 1 }}
            text={frText}
            variant={variant}
            rows={rows}
            label={frLabel}
            enforcePlainText={enforcePlainText}
            onFileUpload={onFileUpload}
          />
        </MUI.Grid>
      )}
    </MUI.Grid>
  );
});
