import { DateTimePickerLocalizationProvider, InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface EditSchoolYearConfigurationDatesDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  schoolYearConfiguration: SchoolYearConfigurationModel;
}

export const EditSchoolYearConfigurationDatesDialog = observer((props: EditSchoolYearConfigurationDatesDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style, schoolYearConfiguration, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createEditSchoolYearConfigurationDates(schoolYearConfiguration, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <DateTimePickerLocalizationProvider>
        <MUI.Dialog
          sx={sx}
          open={true}
          onClose={() => void viewModel.cancel()}
          maxWidth="sm"
          fullWidth={true}
          className={className}
          style={style}
        >
          <form
            noValidate
            onSubmit={(e) => {
              // This is to prevent the page from reloading on submit
              e.preventDefault();
              return false;
            }}
          >
            <MUI.DialogTitle>{strings.editDatesDialogTitle}</MUI.DialogTitle>
            <MUI.DialogContent dividers>
              <MUI.Grid container spacing={2}>
                {/* Start Day */}
                <MUI.Grid item xs={12} sm={6}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                        error: viewModel.getFieldError('startDay') != null,
                        helperText: viewModel.getFieldError('startDay')
                      },
                      actionBar: {
                        actions: ['cancel', 'accept']
                      }
                    }}
                    localeText={{
                      okButtonLabel: strings.okButtonCaption,
                      cancelButtonLabel: strings.cancelButtonCaption
                    }}
                    format={localizationService.localizedStrings.models.dateFormats.mediumUnabridged}
                    label={strings.startDateFieldLabel}
                    value={(viewModel.getFieldValue('startDay') as Date | undefined) ?? null}
                    onChange={(e) => viewModel.setFieldValue('startDay', e ?? undefined)}
                  />
                </MUI.Grid>

                {/* End Day */}
                <MUI.Grid item xs={12} sm={6}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                        error: viewModel.getFieldError('endDay') != null,
                        helperText: viewModel.getFieldError('endDay')
                      },
                      actionBar: {
                        actions: ['cancel', 'accept']
                      }
                    }}
                    localeText={{
                      okButtonLabel: strings.okButtonCaption,
                      cancelButtonLabel: strings.cancelButtonCaption
                    }}
                    format={localizationService.localizedStrings.models.dateFormats.mediumUnabridged}
                    label={strings.endDateFieldLabel}
                    value={(viewModel.getFieldValue('endDay') as Date | undefined) ?? null}
                    onChange={(e) => viewModel.setFieldValue('endDay', e ?? undefined)}
                  />
                </MUI.Grid>

                {/* Teacher Access Date */}
                <MUI.Grid item xs={12}>
                  <DateTimePicker
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                        error: viewModel.getFieldError('teacherAccessDate') != null,
                        helperText: viewModel.getFieldError('teacherAccessDate')
                      },
                      actionBar: {
                        actions: ['clear', 'cancel', 'accept']
                      }
                    }}
                    localeText={{
                      okButtonLabel: strings.okButtonCaption,
                      cancelButtonLabel: strings.cancelButtonCaption,
                      clearButtonLabel: strings.clearButtonCaption
                    }}
                    ampm={localizationService.currentLocale !== 'fr'}
                    format={localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime}
                    label={strings.teachersAccessDate}
                    value={(viewModel.getFieldValue('teacherAccessDate') as Date | undefined) ?? null}
                    onChange={(e) => viewModel.setFieldValue('teacherAccessDate', e ?? undefined)}
                  />
                </MUI.Grid>

                {/* Student Access Date */}
                <MUI.Grid item xs={12}>
                  <DateTimePicker
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                        error: viewModel.getFieldError('studentAccessDate') != null,
                        helperText: viewModel.getFieldError('studentAccessDate')
                      },
                      actionBar: {
                        actions: ['clear', 'cancel', 'accept']
                      }
                    }}
                    localeText={{
                      okButtonLabel: strings.okButtonCaption,
                      cancelButtonLabel: strings.cancelButtonCaption,
                      clearButtonLabel: strings.clearButtonCaption
                    }}
                    ampm={localizationService.currentLocale !== 'fr'}
                    format={localizationService.localizedStrings.models.dateFormats.mediumUnabridgedWithTime}
                    label={strings.studentsAccessDate}
                    value={(viewModel.getFieldValue('studentAccessDate') as Date | undefined) ?? null}
                    onChange={(e) => viewModel.setFieldValue('studentAccessDate', e ?? undefined)}
                  />
                </MUI.Grid>
              </MUI.Grid>
            </MUI.DialogContent>
            <MUI.DialogActions>
              <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
                {strings.cancelButtonCaption}
              </InsightsButton>
              <InsightsButton
                isSubmit
                showSpinnerOnExecuting
                isExecuting={viewModel.isSubmitting}
                onClick={() => void viewModel.save()}
              >
                {strings.saveButtonCaption}
              </InsightsButton>
            </MUI.DialogActions>
          </form>
        </MUI.Dialog>
      </DateTimePickerLocalizationProvider>
    </MUI.ThemeProvider>
  );
});
