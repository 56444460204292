import { ObservablePresenter } from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface MicrosoftTeamsAuthorizationRedirectScreenProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const MicrosoftTeamsAuthorizationRedirectScreen = observer(
  (props: MicrosoftTeamsAuthorizationRedirectScreenProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { sx, className, style } = props;
    const strings = localizationService.localizedStrings.insights.views.settings;
    const theme = useTheme();
    const navigate = useNavigate();

    const location = useLocation();
    const search = location.search;
    const viewModel = useMemo(() => viewModelFactory.createMicrosoftTeamsRedirect(search), [search]);

    return (
      <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="column">
        <MUI.Box flex={1}>
          <ObservablePresenter
            sx={{ height: '100%', width: '100%', p: 2 }}
            data={viewModel.data}
            loadingMessage={strings.completingTeamsAuthorizationMessage}
            errorMessage={strings.completingTeamsAuthorizationErrorMessage}
            render={(data) => (
              <MUI.Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <MUI.Typography variant="h4">{strings.completedTeamsAuthorizationTitle}</MUI.Typography>
                <MUI.Typography
                  sx={{
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                    maxWidth: theme.breakpoints.values.sm
                  }}
                  variant="subtitle1"
                  align="center"
                >
                  {strings.completedTeamsAuthorizationMessage}
                </MUI.Typography>
                <MUI.Button
                  variant="contained"
                  color="primary"
                  onClick={() => void data.navigateToExternalAccounts(navigate)}
                >
                  {strings.returnToExternalAccountsButton}
                </MUI.Button>
              </MUI.Box>
            )}
          />
        </MUI.Box>
      </MUI.Box>
    );
  }
);
