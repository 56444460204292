import { SourceData as PBSourceData } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel } from '../../Model';
import { Incident, SourceData, SourceRow } from '../interfaces';
import { GrpcIncident } from './GrpcIncident';
import { GrpcSourceRow } from './GrpcSourceRow';

export class GrpcSourceData extends BaseModel<PBSourceData> implements SourceData {
  constructor(pb: PBSourceData) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get label(): string {
    return this._pb.label;
  }

  get targetSchema(): string {
    return this._pb.targetSchema;
  }

  @LazyGetter()
  get rows(): SourceRow[] {
    return this._pb.rows.map((r) => new GrpcSourceRow(r));
  }

  get columnNames(): string[] {
    return this._pb.columnNames;
  }

  @LazyGetter()
  get incidents(): Incident[] {
    return this._pb.incidents.map((i) => new GrpcIncident(i));
  }

  get isFulfilled(): boolean {
    return this._pb.isFulfilled;
  }

  get lastImportTime(): Date | undefined {
    return this._pb.lastImportTime?.toDate();
  }

  get hashChangedSinceLastImport(): boolean {
    return this._pb.hashChangedSinceLastImport;
  }

  get renderingTimeMilliseconds(): bigint {
    return this._pb.renderingTimeMilliseconds;
  }
}
