import { SectionMetrics_CourseOccurrenceTaskCounters as PBCourseOccurrenceTaskCounters } from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { Day } from '../types';

export class CourseOccurrenceTaskCounters {
  private _pb: PBCourseOccurrenceTaskCounters;

  constructor(pb: PBCourseOccurrenceTaskCounters) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  @LazyGetter()
  get day(): Day {
    const day = this._pb.day;
    if (day == null) {
      throw new Error('Unexpected data from backend: CourseOccurrenceTaskCounters.Day is undefined.');
    }

    return new Day(day);
  }

  get periodTag(): string {
    return this._pb.periodTag;
  }

  get activePersonal(): number {
    return this._pb.activePersonal;
  }

  get completedPersonal(): number {
    return this._pb.completedPersonal;
  }
}
