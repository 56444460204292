import {
  SchoolYearConfiguration_SpecialDay_CycleDayEffect as PBCycleDayEffect,
  SchoolYearConfiguration_SpecialDay as PBSpecialDay
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { computed, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {
  EditableModelEx,
  EditableStringArrayProperty,
  EditableStringProperty,
  EditableValuePropertyEx
} from '../editables';
import { Color, CycleDayEffect, SpecialDaySymbol } from '../types';
import { protobufFromColor, protobufFromCycleDayEffect, protobufFromSpecialDaySymbol } from '../types/EnumConversion';
import { SpecialDay, SpecialDayModel } from './SpecialDay';

export class EditableSpecialDay extends EditableModelEx<PBSpecialDay> implements SpecialDayModel {
  private _title: EditableStringProperty<PBSpecialDay>;
  private _cycleDay: EditableValuePropertyEx<number, PBSpecialDay>;
  private _cycleDayEffect: EditableValuePropertyEx<CycleDayEffect, PBSpecialDay>;
  private _symbol: EditableValuePropertyEx<SpecialDaySymbol, PBSpecialDay>;
  private _symbolColor: EditableValuePropertyEx<Color, PBSpecialDay>;
  private _scheduleIds: EditableStringArrayProperty<PBSpecialDay>;
  private _isTitleVisible: EditableValuePropertyEx<boolean, PBSpecialDay>;
  private _isSymbolVisible: EditableValuePropertyEx<boolean, PBSpecialDay>;
  private _isEndOfTerm: EditableValuePropertyEx<boolean, PBSpecialDay>;

  static createNew(): EditableSpecialDay {
    const pb = new PBSpecialDay();
    pb.id = uuidv4();
    pb.cycleDay = 0;
    pb.cycleDayEffect = PBCycleDayEffect.PRESERVE;
    pb.isTitleVisible = true;
    pb.isSymbolVisible = true;

    return new EditableSpecialDay(new SpecialDay(pb), true);
  }

  static cloneAsNew(original: SpecialDayModel, shouldChangeTitle = false): EditableSpecialDay {
    const pb = original.toProtobuf();
    pb.id = uuidv4();

    if (shouldChangeTitle) {
      pb.title = pb.title + ' COPY';
    }

    return new EditableSpecialDay(new SpecialDay(pb), true);
  }

  constructor(
    private readonly _originalSpecialDay: SpecialDayModel,
    isNew = false
  ) {
    super(_originalSpecialDay.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._title = new EditableStringProperty(_originalSpecialDay.title, (pb, value) => (pb.title = value), {
        trim: true
      })),
      (this._cycleDay = new EditableValuePropertyEx(
        _originalSpecialDay.cycleDay,
        (pb, value) => (pb.cycleDay = value)
      )),
      (this._cycleDayEffect = new EditableValuePropertyEx(
        _originalSpecialDay.cycleDayEffect,
        (pb, value) => (pb.cycleDayEffect = protobufFromCycleDayEffect(value))
      )),
      (this._symbol = new EditableValuePropertyEx(
        _originalSpecialDay.symbol,
        (pb, value) => (pb.symbol = protobufFromSpecialDaySymbol(value))
      )),
      (this._symbolColor = new EditableValuePropertyEx(
        _originalSpecialDay.symbolColor,
        (pb, value) => (pb.symbolColor = protobufFromColor(value))
      )),
      (this._scheduleIds = new EditableStringArrayProperty(
        _originalSpecialDay.scheduleIds,
        (pb, values) => (pb.scheduleIds = values),
        {
          trim: true
        }
      )),
      (this._isTitleVisible = new EditableValuePropertyEx(
        _originalSpecialDay.isTitleVisible,
        (pb, value) => (pb.isTitleVisible = value)
      )),
      (this._isSymbolVisible = new EditableValuePropertyEx(
        _originalSpecialDay.isSymbolVisible,
        (pb, value) => (pb.isSymbolVisible = value)
      )),
      (this._isEndOfTerm = new EditableValuePropertyEx(
        _originalSpecialDay.isEndOfTerm,
        (pb, value) => (pb.isEndOfTerm = value)
      ))
    ]);
  }

  //
  // Read-only properties
  //

  get id(): string {
    return this._originalSpecialDay.id;
  }

  //
  // Editable properties
  //

  @computed
  get title(): string {
    return this._title.value;
  }

  set title(value: string) {
    this._title.value = value;
  }

  @computed
  get cycleDay(): number {
    return this._cycleDay.value;
  }

  set cycleDay(value: number) {
    this._cycleDay.value = value;
  }

  @computed
  get cycleDayEffect(): CycleDayEffect {
    return this._cycleDayEffect.value;
  }

  set cycleDayEffect(value: CycleDayEffect) {
    this._cycleDayEffect.value = value;
  }

  @computed
  get symbol(): SpecialDaySymbol {
    return this._symbol.value;
  }

  set symbol(value: SpecialDaySymbol) {
    this._symbol.value = value;
  }

  @computed
  get symbolColor(): Color {
    return this._symbolColor.value;
  }

  set symbolColor(value: Color) {
    this._symbolColor.value = value;
  }

  @computed
  get scheduleIds(): string[] {
    return this._scheduleIds.value;
  }

  set scheduleIds(values: string[]) {
    this._scheduleIds.value = values;
  }

  @computed
  get isTitleVisible(): boolean {
    return this._isTitleVisible.value;
  }

  set isTitleVisible(value: boolean) {
    this._isTitleVisible.value = value;
  }

  @computed
  get isSymbolVisible() {
    return this._isSymbolVisible.value;
  }

  set isSymbolVisible(value: boolean) {
    this._isSymbolVisible.value = value;
  }

  @computed
  get isEndOfTerm(): boolean {
    return this._isEndOfTerm.value;
  }

  set isEndOfTerm(value: boolean) {
    this._isEndOfTerm.value = value;
  }

  clone() {
    const pb = this.toProtobuf();
    return new EditableSpecialDay(new SpecialDay(pb));
  }
}
