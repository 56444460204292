// TODO MUI.Box

import {
  AuthorizationRoleCondition,
  Column,
  Container,
  Expanded,
  InsightsButton,
  ObservableActionsPresenter,
  ObservablePresenter,
  Row,
  Scroll,
  SectionEdition,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SectionEditionDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  sectionIds: string[];
}

export const SectionEditionDialog = observer((props: SectionEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, sectionIds, className, style, configId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createSectionEdition(configId, sectionIds, false, onSuccess!, onCancel!)
  );

  const close = () => {
    void viewModel.close();
  };

  return (
    <ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="sm"
        fullWidth={true}
        PaperProps={{ sx: { height: '100vh' } }}
        onClose={() => close()}
      >
        <DialogTitle sx={{ pb: 0 }}>{strings.createOrEditSection(sectionIds.length)}</DialogTitle>

        <DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => (
              <Container>
                <Column>
                  <Expanded>
                    <Scroll
                      direction="y"
                      sx={{
                        // This is to reset the bottom margin of the last card
                        '& >:last-child': {
                          marginBottom: 2
                        }
                      }}
                    >
                      <SectionEdition viewModel={data} />
                    </Scroll>
                  </Expanded>
                </Column>
              </Container>
            )}
          />
        </DialogContent>

        <DialogActions>
          <ObservableActionsPresenter
            data={viewModel.data}
            render={(data) => (
              <Column horizontalContentAlignment="stretch">
                {data.messages.map((message, index) => (
                  <Typography key={`message-${index}`} color="error" variant="caption">
                    {message}
                  </Typography>
                ))}
                <Row verticalContentAlignment="center">
                  <AuthorizationRoleCondition allowedRoles={['super-admin', 'admin']}>
                    {data.canTryDelete && (
                      <InsightsButton sx={{ m: 1 }} onClick={() => void data.tryDelete()}>
                        {strings.delete}
                      </InsightsButton>
                    )}
                  </AuthorizationRoleCondition>
                  <Expanded />
                  <InsightsButton sx={{ m: 1 }} onClick={() => close()}>
                    {strings.cancel}
                  </InsightsButton>
                  <InsightsButton
                    sx={{ m: 1 }}
                    onClick={() => void data.apply()}
                    isDisabled={!data.hasChanges || data.hasLiveErrors}
                    isDefault
                  >
                    {strings.save}
                  </InsightsButton>
                </Row>
              </Column>
            )}
            renderError={() => <InsightsButton onClick={() => close()}>{strings.close}</InsightsButton>}
          />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
