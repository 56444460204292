import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { ImportSession } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportSessionFileEditDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  session: ImportSession;
  fileLabel: string;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionFileEditDialog = observer((props: ImportSessionFileEditDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { style, className, sx, session, configId, fileLabel, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createImportSessionFileEdit(configId, session, fileLabel, onSuccess!, onCancel!)
  );
  const hasFile = viewModel.file != null;

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileReader = useRef(new FileReader());
  const localFilename = useRef<string | null>(null);

  useEffect(() => {
    fileReader.current.onload = () => {
      viewModel.file = {
        data: fileReader.current.result,
        name: localFilename.current ?? ''
      };
    };
  }, []);

  const openFileInput = () => {
    requestAnimationFrame(() => {
      if (fileInputRef.current != null) {
        fileInputRef.current.click();
      }
    });
  };

  const onImportFiles = (files: FileList | null) => {
    if (files != null) {
      const file = files[0];

      if (file != null) {
        readFile(file);
      }
    }
  };

  const readFile = (file: File) => {
    localFilename.current = file.name;
    fileReader.current.readAsDataURL(file);
  };

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        onClose={() => void viewModel.cancel()}
        maxWidth="sm"
        fullWidth={true}
        className={className}
        style={style}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <MUI.DialogTitle>{strings.editFileTitle(viewModel.expectedSourceFilename)}</MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <MUI.Box
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              overflow="visible"
              alignItems="center"
              py={4}
            >
              <input
                type="file"
                id="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(e) => onImportFiles(e.target.files)}
              />

              <MUI.Typography
                align="center"
                color={hasFile ? 'textPrimary' : 'textSecondary'}
                style={{
                  fontStyle: hasFile ? '' : 'italic',
                  fontWeight: hasFile ? 'normal' : 'lighter'
                }}
              >
                {viewModel.file?.name ?? strings.noFileSelected}
              </MUI.Typography>

              <MUI.Button sx={{ mt: 2 }} variant="outlined" color="primary" onClick={openFileInput}>
                {strings.selectFile}
              </MUI.Button>
            </MUI.Box>
          </MUI.DialogContent>

          <MUI.DialogActions>
            {viewModel.hasError && (
              <MUI.Typography flex={1} variant="caption" color="error" style={{ textAlign: 'end' }}>
                {strings.errorMessage}
              </MUI.Typography>
            )}
            <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => void viewModel.cancel()}>
              {strings.cancel}
            </InsightsButton>
            <InsightsButton
              isSubmit
              showSpinnerOnExecuting
              isDisabled={!viewModel.canSave}
              isExecuting={viewModel.isExecuting}
              onClick={() => void viewModel.save()}
            >
              {strings.upload}
            </InsightsButton>
          </MUI.DialogActions>
        </form>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
