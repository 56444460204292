import { css } from '@emotion/css';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { Browser, BrowserInfo, detect } from 'detect-browser';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { ApplicationName } from './ApplicationName';

const SupportedBrowser: Browser[] = [
  'chrome',
  'crios',
  'edge',
  'edge-chromium',
  'firefox',
  'ios',
  'safari',
  'ios-webview'
];

export interface BrowserDetectorProps extends PropsWithChildren {
  sx?: SxProps;
  className?: string;
}

export const BrowserDetector = observer((props: BrowserDetectorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, children } = props;
  const strings = localizationService.localizedStrings.insights.components.browserDetector;
  const theme = useTheme();

  const browserInfo = detect();
  const browser = browserInfo instanceof BrowserInfo ? browserInfo : undefined;
  if (browser != null && SupportedBrowser.includes(browser.name)) {
    return <>{children}</>;
  }

  const linkClassName = css({ color: theme.palette.secondary.main });

  const openIntercom = () => {
    if (window.Intercom != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Intercom('showNewMessage');
    }
  };

  return (
    <MUI.Box sx={sx} className={className} display="flex" flexDirection="column" padding={3}>
      <MUI.Box marginBottom={3}>
        <ApplicationName size="extra-large" color="light" />
      </MUI.Box>

      <MUI.Box marginBottom={3}>
        <MUI.Typography variant="h4">{strings.title}</MUI.Typography>
      </MUI.Box>

      <MUI.Box marginBottom={3}>
        <MUI.Typography variant="body1">
          {strings.errorOnOurPartText}
          <MUI.Link variant="body1" className={linkClassName} onClick={openIntercom}>
            {strings.errorOnOurPartLetUsKnowLink}
          </MUI.Link>
          .
        </MUI.Typography>
      </MUI.Box>

      <MUI.Typography variant="body1">{strings.useOneOfTheFollowingBrowser}</MUI.Typography>

      <MUI.Link variant="body1" href="https://www.google.com/chrome/" className={linkClassName}>
        Google Chrome
      </MUI.Link>

      <MUI.Link variant="body1" href="http://www.mozilla.org/firefox/" className={linkClassName}>
        Firefox
      </MUI.Link>

      {browser != null && (browser.os === 'Mac OS' || browser.os === 'iOS') && (
        <MUI.Link variant="body1" href="https://support.apple.com/downloads/safari/" className={linkClassName}>
          Safari
        </MUI.Link>
      )}

      {browser != null && browser.os === 'Windows 10' && (
        <MUI.Link
          variant="body1"
          href="https://www.microsoft.com/en-us/windows/microsoft-edge/"
          className={linkClassName}
        >
          Microsoft Edge
        </MUI.Link>
      )}
    </MUI.Box>
  );
});
