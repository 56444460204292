import { ImportSessionFileProcessLinkViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface ImportSessionFileProcessLinkProps {
  viewModel: ImportSessionFileProcessLinkViewModel;
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionFileProcessLink = observer((props: ImportSessionFileProcessLinkProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      {viewModel.hasUploadedFile && (
        <MUI.Typography mb={2} color="error">
          {strings.fileAlreadyHasUrlWarning}
        </MUI.Typography>
      )}
      <MUI.TextField
        fullWidth
        label={strings.onboardingQuestionLabel}
        select
        value={viewModel.question?.templateName}
        onChange={(e) => viewModel.setQuestionByName(e.target.value)}
      >
        {viewModel.availableQuestions.map((info) => (
          <MUI.MenuItem
            key={`onboarding-question-${info.question.templateName}`}
            sx={{
              fontStyle: info.question.answer == null ? 'italic' : undefined,
              backgroundColor: info.question.isDependantLocked ? yellow[100] : undefined
            }}
            value={info.question.templateName}
          >
            {info.question.templateName}
          </MUI.MenuItem>
        ))}
      </MUI.TextField>
      {viewModel.question != null && (
        <MUI.Box mt={1} display="flex">
          {viewModel.question.kind === 'file' &&
            (viewModel.question.answer?.fileValue != null ? (
              <MUI.Link
                href={viewModel.question.answer.fileValue}
                target="_blank"
                noWrap
                sx={{ width: '100%', direction: 'rtl', textAlign: 'left' }}
              >
                {viewModel.question.answer.fileValue}
              </MUI.Link>
            ) : (
              <MUI.Typography variant="body2">{strings.noAnswer}</MUI.Typography>
            ))}
          {viewModel.question.kind === 'large-text' && (
            <MUI.Typography variant="body2">{strings.textContent}</MUI.Typography>
          )}
        </MUI.Box>
      )}
    </MUI.Box>
  );
});
