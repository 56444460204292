import { DayConfigurationModel, EditableSpecialDay, ScheduleModel } from '@shared/models/config';
import { Color, CycleDayEffect, SpecialDaySymbol } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import _ from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import * as C from './Constants';
import { ValidatableViewModel } from './Editor';
import { SchoolCalendarViewModel } from './SchoolCalendarViewModel';

export interface SchoolCalendarSpecialDayViewModel extends ValidatableViewModel {
  readonly editableSpecialDay: EditableSpecialDay;

  readonly id: string;
  title: string;
  cycleDay: number;
  cycleDayEffect: CycleDayEffect;
  symbol: SpecialDaySymbol;
  symbolColor: Color;
  isEndOfTerm: boolean;
  isTitleVisible: boolean;
  isSymbolVisible: boolean;
  readonly cycleDayTitles: string[];

  readonly schedules: ScheduleModel[];
  setScheduleIds(ids: string[]): void;

  readonly isNew: boolean;
  readonly isDeleted: boolean;

  readonly occurrences: DayConfigurationModel[];

  delete(): void;
  resetChanges(): void;
  clone(): SchoolCalendarSpecialDayViewModel;
}

export class AppSchoolCalendarSpecialDayViewModel implements SchoolCalendarSpecialDayViewModel {
  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _configViewModel: SchoolCalendarViewModel,
    public readonly editableSpecialDay: EditableSpecialDay,
    public readonly occurrences: DayConfigurationModel[] = []
  ) {
    makeObservable(this);
  }

  get id() {
    return this.editableSpecialDay.id;
  }

  @computed
  get title() {
    return this.editableSpecialDay.title;
  }

  set title(value: string) {
    this.editableSpecialDay.title = value;
  }

  @computed
  get cycleDay() {
    return this.editableSpecialDay.cycleDay;
  }

  set cycleDay(value: number) {
    if (value === -1) {
      // Means "preserve" effect
      this.cycleDayEffect = 'preserve';
      this.editableSpecialDay.cycleDay = 0;
    } else {
      this.editableSpecialDay.cycleDay = value;
      if (this.cycleDayEffect === 'preserve') {
        this.cycleDayEffect = 'insert';
      }
    }
  }

  @computed
  get cycleDayEffect() {
    return this.editableSpecialDay.cycleDayEffect;
  }

  set cycleDayEffect(value: CycleDayEffect) {
    this.editableSpecialDay.cycleDayEffect = value;
  }

  @computed
  get symbol() {
    return this.editableSpecialDay.symbol;
  }

  set symbol(value: SpecialDaySymbol) {
    this.editableSpecialDay.symbol = value;
  }

  @computed
  get symbolColor() {
    return this.editableSpecialDay.symbolColor;
  }

  set symbolColor(value: Color) {
    this.editableSpecialDay.symbolColor = value;
  }

  @computed
  get isTitleVisible() {
    return this.editableSpecialDay.isTitleVisible;
  }

  set isTitleVisible(value: boolean) {
    this.editableSpecialDay.isTitleVisible = value;
  }

  @computed
  get isSymbolVisible() {
    return this.editableSpecialDay.isSymbolVisible;
  }

  set isSymbolVisible(value: boolean) {
    this.editableSpecialDay.isSymbolVisible = value;
  }

  @computed
  get schedules() {
    return _.compact(this.editableSpecialDay.scheduleIds.map((id) => this._configViewModel.schedulesById[id]));
  }

  @action
  setScheduleIds(ids: string[]): void {
    this.editableSpecialDay.scheduleIds = ids;
  }

  @computed
  get isEndOfTerm() {
    return this.editableSpecialDay.isEndOfTerm;
  }

  set isEndOfTerm(value: boolean) {
    this.editableSpecialDay.isEndOfTerm = value;
  }

  @computed
  get cycleDayTitles(): string[] {
    return this._configViewModel.editableConfig.cycleDayTitles;
  }

  @computed
  get hasChanges() {
    return this.editableSpecialDay.hasChanges;
  }

  get isNew() {
    return this.editableSpecialDay.shouldBeCreated;
  }

  @computed
  get isDeleted() {
    return this.editableSpecialDay.shouldBeDeleted;
  }

  @action
  delete() {
    this.editableSpecialDay.markAsDeleted();
  }

  @action
  resetChanges() {
    this.editableSpecialDay.resetChanges();
  }

  validate(): string[] {
    const messages: string[] = [];

    const strings = this._localizationService.localizedStrings.insights.viewModels.calendar;

    if (this.title.length === 0) {
      messages.push(strings.emptyTitleError);
    } else if (this.title.length > C.MaximumSpecialDayTitleLength) {
      messages.push(strings.titleTooLongError + C.MaximumSpecialDayTitleLength + strings.characters + '.');
    }

    // Other properties are set by pickers, which we assume can't set invalid values.

    return messages;
  }

  clone(): SchoolCalendarSpecialDayViewModel {
    return new AppSchoolCalendarSpecialDayViewModel(
      this._localizationService,
      this._configViewModel,
      this.editableSpecialDay.clone()
    );
  }
}
