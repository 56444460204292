import { SectionName, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { PublishedTasksDetailSectionInfo, PublishedTasksDetailTaskInfo } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps, createTheme, useTheme } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { ContentDefinitionUtils, DataStructureUtils, SectionUtils } from '@shared/components/utils';
import { SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { DialogResult } from '@shared/services';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface PublishedTaskDetailDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  sectionInfo: PublishedTasksDetailSectionInfo;
  taskInfo: PublishedTasksDetailTaskInfo;
  sectionsById: Record<string, SectionModel>;
  schoolYearConfig: SchoolYearConfigurationModel;
}

export const PublishedTaskDetailDialog = observer((props: PublishedTaskDetailDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, schoolYearConfig, sectionsById, style, sectionInfo, taskInfo, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.components.tasks;
  const theme = useTheme();

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createPublishedTaskDetail(sectionInfo, taskInfo, onSuccess!, onCancel!)
  );

  const task = viewModel.taskInfo.task;
  const notes = task.isSlave ? task.masterContent!.notes : task.notes;
  const sectionsByIdMap = DataStructureUtils.convertDictionaryToMap<SectionModel>(sectionsById);
  const taskIconPublishKind = ContentDefinitionUtils.getTaskIconPublishKind(task, sectionsByIdMap, schoolYearConfig);

  const close = () => {
    void viewModel.close();
  };

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
        onClose={() => close()}
        className={className}
        style={style}
      >
        <MUI.DialogTitle>
          <MUI.Box display="flex" flexDirection="row" alignItems="center">
            <SectionName
              color={viewModel.sectionInfo.section.color}
              title={viewModel.sectionInfo.section.title}
              subInformation1={viewModel.sectionInfo.section.sectionNumber}
              subInformation2={SectionUtils.getSectionShortTeacherInfo(
                viewModel.sectionInfo.section,
                viewModel.sectionInfo.teachers,
                strings.noTeacher
              )}
            />

            <MUI.Box flex={1} />

            <TaskIcon
              icon={task.icon}
              workloadLevel={task.workloadLevel}
              squareSize={32}
              publishedKind={taskIconPublishKind}
              externalSource={task.externalContent != null ? task.externalContent.sourceIntegration : undefined}
            />
          </MUI.Box>
        </MUI.DialogTitle>

        <MUI.DialogContent dividers>
          <MUI.Grid container spacing={2}>
            {/* Title */}
            <MUI.Grid item xs={12}>
              <MUI.InputLabel shrink>{strings.titleLabel}</MUI.InputLabel>
              <MUI.Typography variant="body1">
                <span style={{ fontWeight: '400' }}>
                  {ContentDefinitionUtils.getDisplayTitleForContent(task, localizationService.localizedStrings)}
                </span>
              </MUI.Typography>
            </MUI.Grid>

            {/* Published on */}
            <MUI.Grid item xs={6}>
              <MUI.InputLabel shrink>{strings.publishedOnLabel}</MUI.InputLabel>
              <MUI.Typography>
                <span style={{ marginRight: theme.spacing(0.5), fontWeight: '400' }}>
                  {format(
                    viewModel.taskInfo.publishedAt,
                    localizationService.localizedStrings.models.dateFormats.shortUnabridged
                  )}
                </span>

                <span>{strings.at}</span>

                <span style={{ marginRight: theme.spacing(0.5), fontWeight: '400' }}>
                  {format(viewModel.taskInfo.publishedAt, localizationService.localizedStrings.models.dateFormats.time)}
                </span>
              </MUI.Typography>
            </MUI.Grid>

            {/* Due on */}
            <MUI.Grid item xs={6}>
              <MUI.InputLabel shrink>{strings.dueOnLabel}</MUI.InputLabel>
              <MUI.Typography>
                <span style={{ fontWeight: '400' }}>
                  {format(
                    viewModel.taskInfo.task.dueDay.asDate,
                    localizationService.localizedStrings.models.dateFormats.shortUnabridged
                  )}
                </span>

                {viewModel.taskInfo.task.duePeriodTag && (
                  <React.Fragment>
                    <span style={{ marginLeft: theme.spacing(0.5) }}>{strings.atPeriod}</span>

                    <span style={{ marginLeft: theme.spacing(0.5), fontWeight: '400' }}>
                      {viewModel.taskInfo.task.duePeriodTag}
                    </span>
                  </React.Fragment>
                )}
              </MUI.Typography>
            </MUI.Grid>

            {notes && (
              <MUI.Grid item xs={12}>
                <MUI.InputLabel shrink>{strings.notesLabel}</MUI.InputLabel>
                <MUI.Typography>{notes}</MUI.Typography>
              </MUI.Grid>
            )}
          </MUI.Grid>
        </MUI.DialogContent>

        <MUI.DialogActions>
          <MUI.Button variant="outlined" onClick={() => close()}>
            {strings.close}
          </MUI.Button>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
