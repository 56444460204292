import UploadIcon from '@mui/icons-material/Attachment';
import * as MUI from '@mui/material';
import { styled, SxProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { EditableOnboardingText } from '@shared/models/onboarding/implementations';
import { OnboardingText } from '@shared/models/onboarding/interfaces';
import { OnboardingTextFormat } from '@shared/models/types';
import { Interweave } from 'interweave';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface OnboardingTextEditionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  text: EditableOnboardingText;
  label: string;
  rows?: number;
  variant?: Variant;
  enforcePlainText?: boolean;
  onFileUpload?: (dataUrl: string, fileName?: string) => Promise<void>;
}

export const OnboardingTextEdition = observer((props: OnboardingTextEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, text, label, variant, rows = 10, enforcePlainText = false, onFileUpload } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileReader = useRef(new FileReader());
  const localFilename = useRef<string | undefined>();
  const [showToast, setShowToast] = useState(false);

  const openFileInput = () => {
    requestAnimationFrame(() => {
      if (fileInputRef.current != null) {
        fileInputRef.current.click();
      }
    });
  };

  const onUploadFile = (files: FileList | null) => {
    if (files != null) {
      const file = files[0];

      if (file != null) {
        readFile(file);
      }
    }
  };

  const readFile = (file: File) => {
    localFilename.current = file.name;
    fileReader.current.readAsDataURL(file);
  };

  useEffect(() => {
    fileReader.current.onload = () => {
      void onFileUpload?.(fileReader.current.result as string, localFilename.current).then(() => setShowToast(true));
    };
  }, []);
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  // We force plain text if it's enforced and already plain text.
  const canChangeFormat = !enforcePlainText || text.format !== 'plain-text';

  return (
    <Root sx={sx} className={className} style={style}>
      <input
        type="file"
        id="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => onUploadFile(e.target.files)}
      />

      <MUI.Box display="flex" flexDirection="row" alignItems="center">
        <MUI.Typography variant="h6">{label}</MUI.Typography>
        {canChangeFormat && (
          <MUI.Select
            className="formatSelector"
            value={text.format}
            onChange={(e) => (text.format = e.target.value as OnboardingTextFormat)}
          >
            <MUI.MenuItem value={'plain-text'}>{strings.textFormatPlainText}</MUI.MenuItem>
            <MUI.MenuItem value={'markdown'}>{strings.textFormatMarkdown}</MUI.MenuItem>
            <MUI.MenuItem value={'html'}>{strings.textFormatHtml}</MUI.MenuItem>
          </MUI.Select>
        )}
        {onFileUpload != null && (
          <MUI.IconButton className="actions" size="small" onClick={openFileInput}>
            <UploadIcon fontSize="small" />
          </MUI.IconButton>
        )}
      </MUI.Box>
      <MUI.Paper className="editablePaper">
        <MUI.TextField
          fullWidth
          multiline
          rows={rows}
          value={text.value}
          onChange={(e) => (text.value = e.target.value)}
        />
      </MUI.Paper>
      {canChangeFormat && renderPreview(text, variant)}
      <MUI.Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => runInAction(() => setShowToast(false))}
        message={strings.fileUrlInClipboardToast}
      />
    </Root>
  );
});

function renderPreview(text: EditableOnboardingText, variant: Variant | undefined) {
  switch (text.format) {
    case 'plain-text':
      return renderPlainText(text, variant);
    case 'markdown':
      return renderMarkdown(text);
    case 'html':
      return renderHtml(text);
  }
}

function renderPlainText(text: OnboardingText, variant: Variant | undefined) {
  return (
    <MUI.Typography className="preview" variant={variant}>
      {text.value}
    </MUI.Typography>
  );
}

function renderMarkdown(text: OnboardingText) {
  return (
    <MUI.Box className="preview" overflow="auto" maxHeight={200}>
      <ReactMarkdown>{text.value}</ReactMarkdown>
    </MUI.Box>
  );
}

function renderHtml(text: OnboardingText) {
  return (
    <MUI.Box className="preview" overflow="auto" maxHeight={200}>
      <Interweave content={text.value} />
    </MUI.Box>
  );
}

const Root = styled(MUI.Box)(({ theme }) => ({
  '.formatSelector': {
    marginLeft: theme.spacing(2)
  },
  '.actions': {
    marginLeft: theme.spacing(2)
  },
  '.editablePaper': {
    backgroundColor: '#eef',
    marginRight: theme.spacing(1)
  },
  '.preview': {
    marginTop: theme.spacing(1)
  }
}));
