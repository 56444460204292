import { AccountEditionViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Typography
} from '@mui/material';
import { AdminAuthorizationRoles, AllAdminSupportedRoles, Role } from '@shared/models/types';
import { AllLocales } from '@shared/resources/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { LiveUserInfo } from '../users';

export interface AccountEditionProps {
  sx?: SxProps;
  className?: string;
  viewModel: AccountEditionViewModel;
}

export const AccountEdition = observer((props: AccountEditionProps) => {
  const { accountService, localizationService } = useInsightsServices();
  const { sx, className, viewModel } = props;

  const baseStrings = localizationService.localizedStrings.insights.components;
  const strings = localizationService.localizedStrings.insights.components.accounts;
  const canRemoveUser = accountService.isAllowed(AdminAuthorizationRoles);
  const canSetUser = accountService.isAllowed(['super-admin']);
  const canBeAdmin =
    AllAdminSupportedRoles.includes(viewModel.editableAccount.role) || viewModel.editableAccount.isAdmin;

  function addUserId() {
    const strings = localizationService.localizedStrings.insights.components.accounts;
    const userId = prompt(strings.userIdPrompt);

    if (userId != null && userId.length > 0) {
      viewModel.editableAccount.userId = userId;
    }
  }

  return (
    <Box sx={sx} className={className} display="flex" flexDirection="column">
      <FormControlLabel
        style={Styles.control}
        control={
          <Checkbox
            checked={viewModel.editableAccount.isLocked}
            onChange={(event) => (viewModel.editableAccount.isLocked = event.target.checked)}
          />
        }
        label={strings.isLockedLabel}
      />

      <FormControl style={Styles.control} disabled={viewModel.acceptedRoles.length < 2}>
        <InputLabel htmlFor="role-select">{strings.role}</InputLabel>
        <Select
          value={viewModel.editableAccount.role}
          inputProps={{ id: 'role-select' }}
          onChange={(event) => (viewModel.editableAccount.role = event.target.value as Role)}
        >
          {viewModel.acceptedRoles.map((role) => (
            <MenuItem key={role} value={role}>
              <Typography>{baseStrings.getDisplayableRole(role)}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        style={Styles.control}
        value={viewModel.editableAccount.managedIdentifier}
        label={strings.importIdentifier}
        onChange={(event) => (viewModel.editableAccount.managedIdentifier = event.target.value)}
      />

      <Box display="flex" flexDirection="row">
        <TextField
          style={Styles.control}
          value={viewModel.editableAccount.firstName}
          label={strings.firstName}
          onChange={(event) => (viewModel.editableAccount.firstName = event.target.value)}
        />
        <TextField
          style={Styles.control}
          value={viewModel.editableAccount.lastName}
          label={strings.lastName}
          onChange={(event) => (viewModel.editableAccount.lastName = event.target.value)}
        />
      </Box>

      {viewModel.hasPrivateNames && (
        <>
          <Box display="flex" flexDirection="row">
            <TextField
              style={Styles.control}
              value={viewModel.editableProfile.privateFirstName}
              label={strings.privateFirstName}
              onChange={(event) => (viewModel.editableProfile.privateFirstName = event.target.value)}
            />
            <TextField
              style={Styles.control}
              value={viewModel.editableProfile.privateLastName}
              label={strings.privateLastName}
              onChange={(event) => (viewModel.editableProfile.privateLastName = event.target.value)}
            />
          </Box>
          <Typography style={Styles.notice} variant="caption" color="textSecondary">
            {strings.privateNotice}
          </Typography>
        </>
      )}

      <TextField
        style={Styles.control}
        value={viewModel.editableAccount.email}
        label={strings.email}
        onChange={(event) => (viewModel.editableAccount.email = event.target.value)}
      />

      {viewModel.editableAccount.role === 'teacher' && (
        <>
          <TextField
            style={Styles.control}
            value={viewModel.editableProfile.publicEmail}
            label={strings.publicEmail}
            placeholder={
              viewModel.editableAccount.email.length > 0
                ? viewModel.editableAccount.email
                : strings.publicEmailPlaceholder
            }
            onChange={(event) => (viewModel.editableProfile.publicEmail = event.target.value)}
          />

          {viewModel.allowedScheduleTags.length > 0 && (
            <FormControl style={Styles.control}>
              <InputLabel htmlFor="preferred-schedule-tag-select">{strings.preferredScheduleTag}</InputLabel>
              <Select
                value={viewModel.editableAccount.preferredScheduleTag}
                inputProps={{ id: 'preferred-schedule-tag-select' }}
                onChange={(event) => (viewModel.editableAccount.preferredScheduleTag = event.target.value)}
              >
                <MenuItem value="">{strings.noneF}</MenuItem>
                {viewModel.allowedScheduleTags.map((tag) => (
                  <MenuItem key={`schedule-tag-${tag}`} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}

      {viewModel.editableAccount.role === 'student' && (
        <TextField
          style={Styles.control}
          value={viewModel.editableAccount.gradeLevel}
          label={strings.gradeTitle}
          onChange={(event) => (viewModel.editableAccount.gradeLevel = event.target.value)}
        />
      )}

      {viewModel.editableAccount.role === 'parent' && (
        <FormControlLabel
          style={Styles.control}
          control={
            <Checkbox
              checked={viewModel.editableAccount.optedOutOfParentReports}
              onChange={(event) => (viewModel.editableAccount.optedOutOfParentReports = event.target.checked)}
            />
          }
          label={strings.optedOutOfParentReports}
        />
      )}

      <FormControl style={Styles.control}>
        <InputLabel htmlFor="role-select">{strings.language}</InputLabel>
        <Select
          value={viewModel.editableAccount.language || 'none'}
          inputProps={{ id: 'role-select' }}
          onChange={(e) => (viewModel.editableAccount.language = e.target.value === 'none' ? '' : e.target.value)}
        >
          <MenuItem key={'none'} value={'none'}>
            <Typography>{strings.noneF}</Typography>
          </MenuItem>
          {AllLocales.map((locale) => (
            <MenuItem key={locale} value={locale}>
              <Typography>{locale}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
        {canBeAdmin && (
          <FormControlLabel
            style={Styles.control}
            control={
              <Checkbox
                checked={viewModel.editableAccount.isAdmin}
                onChange={(event) => (viewModel.editableAccount.isAdmin = event.target.checked)}
              />
            }
            label={strings.canManageSchool}
          />
        )}
      </AuthorizationRoleCondition>
      <FormControl style={Styles.bottomControl} disabled={!canRemoveUser && !canSetUser}>
        <Typography variant="caption">{strings.connectedUserLabel}</Typography>
        {viewModel.editableAccount.userId.length > 0 ? (
          <>
            <Box display="flex" flexDirection="row" alignItems="center">
              <LiveUserInfo
                userId={viewModel.editableAccount.userId}
                userProfileData={viewModel.userProfileData}
                accountEmail={viewModel.editableAccount.email}
              />
              <Box flex={1} />
              <IconButton onClick={() => (viewModel.editableAccount.userId = '')} disabled={!canRemoveUser}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </>
        ) : (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body1">{strings.none}</Typography>
            <Box flex={1} />
            <IconButton onClick={() => addUserId()} disabled={!canSetUser}>
              <AddIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </FormControl>

      {!viewModel.editableAccount.isDeleted && viewModel.editableAccount.id.length !== 0 && (
        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
          <Button color="primary" onClick={() => void viewModel.deleteAccount()}>
            {strings.deleteAccountButtonTitle}
          </Button>
        </AuthorizationRoleCondition>
      )}
      {viewModel.editableAccount.isDeleted && (
        <AuthorizationRoleCondition allowedRoles={AdminAuthorizationRoles}>
          <Button color="primary" onClick={() => void viewModel.undeleteAccount()}>
            {strings.undeleteAccountButtonTitle}
          </Button>
        </AuthorizationRoleCondition>
      )}
    </Box>
  );
});

const Styles = {
  control: {
    margin: 8
  },
  notice: {
    marginLeft: 8,
    marginRight: 8
  },
  bottomControl: {
    margin: 8,
    marginTop: 20
  }
};
