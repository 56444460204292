import {
  AuthorizationRoleCondition,
  DateTimePickerLocalizationProvider,
  InsightsButton,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import {
  MaximumCommentsLength,
  MaximumOnboardingCodeLength,
  MaximumSchoolNameLength,
  MaximumSchoolTitleLength
} from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { AllConfigStates } from '@shared/models/types';
import { AllLocales } from '@shared/resources/services';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

const filter = MUI.createFilterOptions<string>();

export interface EditSchoolYearConfigurationInformationDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  schoolYearConfiguration: SchoolYearConfigurationModel;
}

export const EditSchoolYearConfigurationInformationDialog = observer(
  (props: EditSchoolYearConfigurationInformationDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const { className, sx, style, schoolYearConfiguration, onCancel, onSuccess } = props;
    const strings = localizationService.localizedStrings.insights.views.managedGeneral;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createEditSchoolYearConfigurationInformation(schoolYearConfiguration, onSuccess!, onCancel!)
    );

    return (
      <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
        <DateTimePickerLocalizationProvider>
          <MUI.Dialog
            sx={sx}
            open={true}
            onClose={() => void viewModel.cancel()}
            maxWidth="sm"
            fullWidth={true}
            className={className}
            style={style}
          >
            <form
              noValidate
              onSubmit={(e) => {
                // This is to prevent the page from reloading on submit
                e.preventDefault();
                return false;
              }}
            >
              <MUI.DialogTitle>{strings.editInformationDialogTitle}</MUI.DialogTitle>
              <MUI.DialogContent dividers>
                <MUI.Grid container spacing={2}>
                  {/* School Name */}
                  <MUI.Grid item xs={12}>
                    <MUI.TextField
                      label={strings.schoolNameFieldLabel}
                      value={viewModel.getFieldValue('schoolName')}
                      fullWidth
                      required
                      autoFocus
                      inputProps={{ maxLength: MaximumSchoolNameLength }}
                      error={Boolean(viewModel.getFieldError('schoolName'))}
                      helperText={viewModel.getFieldError('schoolName')}
                      onChange={(e) => viewModel.setFieldValue('schoolName', e.target.value)}
                    />
                  </MUI.Grid>

                  {/* School Title */}
                  <MUI.Grid item xs={12}>
                    <MUI.TextField
                      label={strings.schoolTitleFieldLabel}
                      value={viewModel.getFieldValue('schoolTitle')}
                      placeholder={viewModel.schoolDisplayTitle}
                      fullWidth
                      inputProps={{ maxLength: MaximumSchoolTitleLength }}
                      error={Boolean(viewModel.getFieldError('schoolTitle'))}
                      helperText={viewModel.getFieldError('schoolTitle')}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => viewModel.setFieldValue('schoolTitle', e.target.value)}
                    />
                  </MUI.Grid>

                  {/* State */}
                  <MUI.Grid item xs={12}>
                    <MUI.FormControl fullWidth error={Boolean(viewModel.getFieldError('state'))}>
                      <MUI.InputLabel htmlFor="state-select" shrink>
                        {strings.stateFieldLabel}
                      </MUI.InputLabel>
                      <MUI.Select
                        value={viewModel.getFieldValue('state')}
                        inputProps={{ id: 'state-select' }}
                        displayEmpty
                        onChange={(e) => viewModel.setFieldValue('state', e.target.value)}
                      >
                        {AllConfigStates.map((state) => (
                          <MUI.MenuItem key={`state-${state}`} value={state}>
                            {strings.getConfigStateLabel(state)}
                          </MUI.MenuItem>
                        ))}
                      </MUI.Select>
                    </MUI.FormControl>
                  </MUI.Grid>

                  {/* Comments */}
                  <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                    <MUI.Grid item xs={12}>
                      <MUI.TextField
                        label={strings.commentsFieldLabel}
                        value={viewModel.getFieldValue('comments')}
                        fullWidth
                        multiline
                        inputProps={{ maxLength: MaximumCommentsLength }}
                        InputProps={{ maxRows: 5 }}
                        error={Boolean(viewModel.getFieldError('comments'))}
                        helperText={viewModel.getFieldError('comments')}
                        onChange={(e) => viewModel.setFieldValue('comments', e.target.value)}
                      />
                    </MUI.Grid>
                  </AuthorizationRoleCondition>

                  {/* Expected student count */}
                  <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                    <MUI.Grid item xs={12}>
                      <MUI.TextField
                        fullWidth
                        type="number"
                        inputProps={{ min: 0 }}
                        InputLabelProps={{ shrink: true }}
                        label={strings.expectedStudentCountFieldLabel}
                        value={viewModel.getFieldValue('expectedStudentCount')}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          const adjustedValue = Math.max(value, 0);

                          viewModel.setFieldValue('expectedStudentCount', adjustedValue);
                        }}
                      />
                    </MUI.Grid>
                  </AuthorizationRoleCondition>

                  {/* Onboarding Code */}
                  <MUI.Grid item xs={12}>
                    <MUI.TextField
                      label={strings.onboardingCodeFieldLabel}
                      value={viewModel.getFieldValue('onboardingCode')}
                      fullWidth
                      inputProps={{ maxLength: MaximumOnboardingCodeLength }}
                      error={Boolean(viewModel.getFieldError('onboardingCode'))}
                      helperText={viewModel.getFieldError('onboardingCode')}
                      onChange={(e) => viewModel.setFieldValue('onboardingCode', e.target.value)}
                    />
                  </MUI.Grid>

                  {/* Timezone */}
                  <MUI.Grid item xs={12}>
                    <MUI.FormControl fullWidth error={Boolean(viewModel.getFieldError('timezone'))}>
                      <MUI.InputLabel htmlFor="timezone-select" shrink>
                        {strings.timezoneLabel}
                      </MUI.InputLabel>
                      <MUI.Select
                        value={viewModel.getFieldValue('timezone')}
                        inputProps={{ id: 'timezone-select' }}
                        displayEmpty
                        onChange={(e) => viewModel.setFieldValue('timezone', e.target.value)}
                      >
                        <MUI.MenuItem value="">{strings.noTimezone}</MUI.MenuItem>
                        {moment.tz.names().map((name) => (
                          <MUI.MenuItem key={`timezone-${name}`} value={name}>
                            {name}
                          </MUI.MenuItem>
                        ))}
                      </MUI.Select>
                      <MUI.FormHelperText>{viewModel.getFieldError('timezone')}</MUI.FormHelperText>
                    </MUI.FormControl>
                  </MUI.Grid>

                  {/* Language */}
                  <MUI.Grid item xs={12}>
                    <MUI.FormControl fullWidth error={Boolean(viewModel.getFieldError('language'))}>
                      <MUI.InputLabel htmlFor="language-select" shrink>
                        {strings.languageLabel}
                      </MUI.InputLabel>
                      <MUI.Select
                        value={viewModel.getFieldValue('language')}
                        inputProps={{ id: 'language-select' }}
                        onChange={(e) => viewModel.setFieldValue('language', e.target.value)}
                      >
                        {AllLocales.map((locale) => (
                          <MUI.MenuItem value={locale} key={locale}>
                            <MUI.Typography>{strings.getLocaleDisplayName(locale)}</MUI.Typography>
                          </MUI.MenuItem>
                        ))}
                      </MUI.Select>
                    </MUI.FormControl>
                  </MUI.Grid>

                  {/* Tags */}
                  <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                    <MUI.Grid item xs={12}>
                      <MUI.Autocomplete
                        multiple
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={viewModel.suggestedTags}
                        value={viewModel.getFieldValue('tags') as string[]}
                        onChange={(_, v) => viewModel.setFieldValue('tags', v as string[])}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option);
                          if (inputValue !== '' && !isExisting) {
                            filtered.push(strings.newTagLabel(inputValue));
                          }

                          return filtered;
                        }}
                        renderInput={(p) => <MUI.TextField {...p} label={strings.tagsLabel} />}
                      />
                    </MUI.Grid>
                  </AuthorizationRoleCondition>

                  {/* Demo School */}
                  <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                    <MUI.Grid item xs={6}>
                      <MUI.FormControlLabel
                        control={
                          <MUI.Checkbox
                            checked={Boolean(viewModel.getFieldValue('demoSchool'))}
                            color="primary"
                            onChange={(_, isChecked) => viewModel.setFieldValue('demoSchool', isChecked)}
                          />
                        }
                        label={strings.demoSchoolCheckboxCaption}
                      />
                    </MUI.Grid>
                    <MUI.Grid item xs={6}>
                      <DatePicker
                        disabled={!viewModel.getFieldValue('demoSchool')}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: Boolean(viewModel.getFieldError('demoDay')),
                            helperText: viewModel.getFieldError('demoDay')
                          },
                          actionBar: {
                            actions: ['clear', 'cancel', 'accept']
                          }
                        }}
                        localeText={{
                          clearButtonLabel: strings.clearButtonCaption,
                          okButtonLabel: strings.okButtonCaption,
                          cancelButtonLabel: strings.cancelButtonCaption
                        }}
                        minDate={viewModel.minDemoDay.asDate}
                        maxDate={viewModel.maxDemoDay.asDate}
                        format={localizationService.localizedStrings.models.dateFormats.mediumUnabridged}
                        label={strings.demoDateFieldLabel}
                        value={(viewModel.getFieldValue('demoDay') as Date | undefined) ?? null}
                        onChange={(e) => viewModel.setFieldValue('demoDay', e ?? undefined)}
                      />
                    </MUI.Grid>
                  </AuthorizationRoleCondition>
                </MUI.Grid>
              </MUI.DialogContent>
              <MUI.DialogActions>
                <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
                  {strings.cancelButtonCaption}
                </InsightsButton>
                <InsightsButton
                  isSubmit
                  showSpinnerOnExecuting
                  isExecuting={viewModel.isSubmitting}
                  onClick={() => void viewModel.save()}
                >
                  {strings.saveButtonCaption}
                </InsightsButton>
              </MUI.DialogActions>
            </form>
          </MUI.Dialog>
        </DateTimePickerLocalizationProvider>
      </MUI.ThemeProvider>
    );
  }
);
