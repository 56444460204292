import { BehaviorAggregationViewModel } from '@insights/viewmodels';
import TaskCompletionIcon from '@mui/icons-material/Check';
import ParentsIcon from '@mui/icons-material/Share';
import AppOpenIcon from '@mui/icons-material/Visibility';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { BehaviorAggregation } from './BehaviourAggregation';

export interface StudentBehaviorAggregationProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  opensApp: BehaviorAggregationViewModel;
  completesTasks: BehaviorAggregationViewModel;
  invitesParent?: BehaviorAggregationViewModel;
}

export const StudentBehaviorAggregation = observer((props: StudentBehaviorAggregationProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, style, opensApp, completesTasks, invitesParent } = props;
  const behaviorStrings = localizationService.localizedStrings.insights.components.behaviourSummary;

  return (
    <MUI.Box sx={sx} className={className} style={style} display="flex" flexDirection="row-reverse">
      {invitesParent != null && (
        <BehaviorAggregation
          sx={{ backgroundColor: grey[100] }}
          icon={<ParentsIcon />}
          tooltip={{
            title: behaviorStrings.invitesParentTooltip,
            grey: behaviorStrings.invitesParentGreyTooltip,
            green: behaviorStrings.invitesParentGreenTooltip,
            yellow: behaviorStrings.invitesParentYellowTooltip,
            red: behaviorStrings.invitesParentRedTooltip
          }}
          viewModel={invitesParent}
        />
      )}
      <BehaviorAggregation
        sx={{ mr: 1 }}
        icon={<TaskCompletionIcon />}
        tooltip={{
          title: behaviorStrings.taskCompletionTooltip,
          green: behaviorStrings.taskCompletionGreenTooltip,
          yellow: behaviorStrings.taskCompletionYellowTooltip,
          red: behaviorStrings.taskCompletionRedTooltip
        }}
        viewModel={completesTasks}
      />
      <BehaviorAggregation
        sx={{ mr: 1 }}
        icon={<AppOpenIcon />}
        tooltip={{
          title: behaviorStrings.appOpenTooltip,
          grey: behaviorStrings.appOpenGreyTooltip,
          green: behaviorStrings.appOpenGreenTooltip,
          yellow: behaviorStrings.appOpenYellowTooltip,
          red: behaviorStrings.appOpenRedTooltip
        }}
        viewModel={opensApp}
      />
    </MUI.Box>
  );
});
