import { EditableTransformationColumnViewModel } from '@insights/viewmodels';
import InfoIcon from '@mui/icons-material/HelpOutline';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';

export interface EditableParametersProps {
  sx?: SxProps;
  className?: string;
  column: EditableTransformationColumnViewModel;
}

export const EditableParameters = observer((props: EditableParametersProps) => {
  const { column, sx, className } = props;

  return (
    <MUI.Box sx={sx} className={className} display="flex" flexDirection="row" alignItems="center">
      {column.parameters.map((p, i) => {
        const hasDescription = p.description.length > 0;

        return (
          <MUI.Stack key={`parameter-${i}`} direction="row">
            <MUI.TextField
              autoFocus={column.focusedField === i}
              fullWidth
              required={p.isRequired}
              sx={hasDescription ? { mr: 0.5, maxWidth: 180 } : { mr: 1, maxWidth: 200 }}
              label={p.name}
              helperText={p.formatHelper}
              InputLabelProps={{ shrink: true }}
              value={p.value}
              onChange={(e) => (p.value = e.target.value)}
              onFocus={() => column.setFocusedField(i)}
            />

            {hasDescription && (
              <MUI.Tooltip title={p.description}>
                <InfoIcon sx={{ mr: 1 }} fontSize="small" color="secondary" />
              </MUI.Tooltip>
            )}
          </MUI.Stack>
        );
      })}
    </MUI.Box>
  );
});
