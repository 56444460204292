import { css } from '@emotion/css';
import { OnboardingProcessViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { MinLayoutWidth } from '../../Constants';
import { OnboardingCommentsCard } from './OnboardingCommentsCard';
import { OnboardingProcessCard } from './OnboardingProcessCard';

export interface OnboardingProcessViewProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: OnboardingProcessViewModel;
}

export const OnboardingProcessView = observer((props: OnboardingProcessViewProps) => {
  const { className, style, sx, viewModel } = props;
  const cardClassName = css([{ minWidth: MinLayoutWidth, display: 'block' }]);

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <OnboardingProcessCard className={cardClassName} viewModel={viewModel} />
      <OnboardingCommentsCard className={cardClassName} viewModel={viewModel.commentsViewModel} showHistoryButton />
    </MUI.Box>
  );
});
