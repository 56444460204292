import { AlertService } from '@insights/services';
import { ExternalAccount } from '@shared/models/connectors';
import { LocalizationService } from '@shared/resources/services';
import { ConnectorsStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface EditErrorNotificationSettingsDialogViewModel {
  snoozeErrorsUntil: Date | undefined;
  pauseSyncWhenSnoozed: boolean;
  readonly minSnoozeErrorsUntilDate: Date;
  readonly isSubmitting: boolean;
  readonly hasChanges: boolean;

  save(): Promise<void>;
  cancel(): Promise<void>;
}

export class AppEditErrorNotificationSettingsDialogViewModel implements EditErrorNotificationSettingsDialogViewModel {
  @observable private _snoozeErrorsUntil: Date | undefined;
  @observable private _pauseSyncWhenSnoozed = false;
  @observable private _isSubmitting = false;

  constructor(
    private readonly _externalAccount: ExternalAccount,
    private _connectorsStore: ConnectorsStore,
    private _onSuccess: () => void,
    private _onCancel: () => void,
    private _alertService: AlertService,
    private _localizationService: LocalizationService
  ) {
    makeObservable(this);
    this._snoozeErrorsUntil = _externalAccount.snoozeErrorsUntil;
    this._pauseSyncWhenSnoozed = _externalAccount.pauseSyncWhenSnoozed;
  }

  @computed
  get snoozeErrorsUntil(): Date | undefined {
    return this._snoozeErrorsUntil;
  }

  set snoozeErrorsUntil(value: Date | undefined) {
    this._snoozeErrorsUntil = value;
  }

  @computed
  get pauseSyncWhenSnoozed(): boolean {
    return this._pauseSyncWhenSnoozed;
  }

  set pauseSyncWhenSnoozed(value: boolean) {
    this._pauseSyncWhenSnoozed = value;
  }

  @computed
  get hasChanges(): boolean {
    return (
      this._snoozeErrorsUntil?.getTime() != this._externalAccount.snoozeErrorsUntil?.getTime() ||
      this._pauseSyncWhenSnoozed != this._externalAccount.pauseSyncWhenSnoozed
    );
  }

  @computed
  get minSnoozeErrorsUntilDate(): Date {
    return new Date();
  }

  @computed
  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  @action
  async save() {
    try {
      this._isSubmitting = true;

      await this._connectorsStore.updateSnoozeAccountErrors(
        this._externalAccount.configId,
        this._externalAccount.id,
        this._snoozeErrorsUntil,
        this._pauseSyncWhenSnoozed
      );

      this._onSuccess();
    } catch (error) {
      await this._alertService.showMessage({
        message: this._localizationService.localizedStrings.insights.viewModels.edit.saveErrorMessage
      });
    } finally {
      runInAction(() => (this._isSubmitting = false));
    }
  }

  @action
  async cancel() {
    if (this.isSubmitting) {
      return;
    }

    if (this.hasChanges) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.edit;
      const result = await this._alertService.showConfirmation({
        message: strings.pendingChangesMessage,
        okButtonCaption: strings.discardButtonCaption,
        cancelButtonCaption: strings.cancelButtonCaption
      });

      if (result === 'cancelled') {
        return;
      }
    }

    this._onCancel();
  }
}
