import {
  AuthorizationRoleCondition,
  ImportSessionDataList,
  ImportSessionFileList,
  ObservablePresenter,
  PageHeaderBar
} from '@insights/components';
import { AutomatedImportList } from '@insights/components/import/AutomatedImportList';
import DeleteIcon from '@mui/icons-material/Delete';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportSessionDetailsScreenProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionDetailsScreen = observer((props: ImportSessionDetailsScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx, style } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;
  const navigate = useNavigate();

  const params = useParams();
  const configId = params.configId ?? '';
  const sessionId = params.sessionId ?? '';

  const viewModel = useMemo(
    () => viewModelFactory.createImportSessionDetails(configId, sessionId),
    [configId, sessionId]
  );

  async function deleteSession() {
    await viewModel.deleteSession();
    navigate(-1);
  }

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <MUI.Tooltip title={strings.deleteSessionTooltip}>
            <MUI.IconButton aria-label="Add" sx={{ m: 1 }} onClick={() => void deleteSession()}>
              <DeleteIcon fontSize="small" />
            </MUI.IconButton>
          </MUI.Tooltip>
        </AuthorizationRoleCondition>
      </PageHeaderBar>

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.session}
          loadingMessage={strings.loadingSessionMessage}
          errorMessage={strings.sessionErrorMessage}
          render={(data) => (
            <MUI.Box>
              <MUI.Typography variant="h5" ml={1}>
                {data.title}
              </MUI.Typography>
              <MUI.Grid container>
                <MUI.Grid item xs={12} md={6}>
                  <MUI.Card sx={{ minWidth: MinLayoutWidth }}>
                    <ImportSessionFileList sx={{ width: '100%', height: '100%' }} viewModel={data.fileList} />
                  </MUI.Card>
                  <MUI.Card sx={{ minWidth: MinLayoutWidth }}>
                    <AutomatedImportList sx={{ width: '100%', height: '100%' }} viewModel={data.automatedImportList} />
                  </MUI.Card>
                </MUI.Grid>

                <MUI.Grid item xs={12} md={6}>
                  <MUI.Card sx={{ minWidth: MinLayoutWidth }}>
                    <ImportSessionDataList sx={{ width: '100%', height: '100%' }} viewModel={data.dataList} />
                  </MUI.Card>
                </MUI.Grid>
              </MUI.Grid>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
