import { ImportDataIncident, InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { ImportData, SchemaImportOption, SourceData } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportDataIncidentsSelectionScreenProps extends DialogResult<ImportData> {
  sx?: SxProps;
  configId: string;
  sessionId: string;
  importData: ImportData;
  data: SourceData;
  isCompleteData: boolean;
  options?: SchemaImportOption[];
}

export const ImportDataIncidentsSelectionScreen = observer((props: ImportDataIncidentsSelectionScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, configId, sessionId, data, importData, isCompleteData, options, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;
  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createImportDataIncidentsSelection(
      configId,
      sessionId,
      importData,
      data,
      isCompleteData,
      options ?? [],
      onSuccess!,
      onCancel!
    )
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog sx={sx} open={true} maxWidth="md" fullWidth onClose={() => viewModel.cancel()}>
        <MUI.DialogTitle>{strings.incidentsSelection}</MUI.DialogTitle>

        <MUI.DialogContent dividers>
          {viewModel.errors.length === 0 ? (
            <MUI.Typography variant="subtitle1" color="textSecondary">
              {viewModel.warnings.length === 0 ? strings.noIncident : strings.noIncidentToAllow}
            </MUI.Typography>
          ) : (
            <MUI.Box>
              <MUI.Typography variant="subtitle1">{strings.incidentsToAllowSelectionMessage}</MUI.Typography>
              {viewModel.errors.map((incident, i) => (
                <MUI.Box key={`incident-checkbox-${i}`}>
                  <MUI.FormControlLabel
                    control={
                      <MUI.Checkbox
                        disabled={viewModel.isExecuting}
                        checked={viewModel.allowedErrors.includes(incident)}
                        onChange={(_, checked) =>
                          checked ? viewModel.addAllowedIncident(incident) : viewModel.removeAllowedIncident(incident)
                        }
                      />
                    }
                    label={<ImportDataIncident incident={incident} />}
                  />
                </MUI.Box>
              ))}
            </MUI.Box>
          )}
          {viewModel.warnings.length > 0 && (
            <MUI.Box sx={{ mt: 1 }}>
              <MUI.Typography variant="subtitle1">{strings.otherIncidents}</MUI.Typography>
              {viewModel.warnings.map((incident, i) => (
                <ImportDataIncident
                  key={`incident-${i}`}
                  sx={{ my: 1 }}
                  incident={incident}
                  colorMode="no-incident-color"
                />
              ))}
            </MUI.Box>
          )}
          {viewModel.options.length > 0 && (
            <MUI.Box sx={{ mt: 2 }}>
              <MUI.Typography variant="subtitle1">{strings.selectedImportOptions}</MUI.Typography>
              {viewModel.options.map((o) => (
                <MUI.Box key={`option-${o.name}`} display="flex" flexDirection="row" sx={{ mt: 1 }}>
                  <MUI.Typography variant="body2" sx={{ fontWeight: '700', minWidth: 200 }}>
                    {o.name}
                  </MUI.Typography>
                  <MUI.Typography variant="body2">{o.localizedDescription}</MUI.Typography>
                </MUI.Box>
              ))}
            </MUI.Box>
          )}
          {viewModel.isCompleteData ? (
            <MUI.Typography color="error" variant="subtitle1" mt={2}>
              {strings.destructiveOnConfirmation}
            </MUI.Typography>
          ) : (
            <MUI.Typography variant="subtitle1" mt={2}>
              {strings.destructiveOffConfirmation}
            </MUI.Typography>
          )}
        </MUI.DialogContent>

        <MUI.DialogActions>
          <InsightsButton isExecuting={viewModel.isExecuting} onClick={() => viewModel.cancel()}>
            {strings.cancel}
          </InsightsButton>
          <InsightsButton
            isSubmit
            showSpinnerOnExecuting
            isExecuting={viewModel.isExecuting}
            onClick={() => void viewModel.import()}
          >
            {strings.importDataButton}
          </InsightsButton>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
