// TODO MUI.Box

import {
  Column,
  Container,
  Expanded,
  InsightsButton,
  ObservableActionsPresenter,
  ObservablePresenter,
  ParentChildrenEdition,
  Scroll,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Dialog, DialogActions, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { DialogResult } from '@shared/services';
import { Observer, observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ParentChildrenEditionDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  parentId: string;
}

export const ParentChildrenEditionDialog = observer((props: ParentChildrenEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style, parentId, configId, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.account;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createParentChildrenEdition(configId, parentId, onSuccess!, onCancel!)
  );
  const data = viewModel.data;

  const close = () => {
    viewModel.close();
  };

  return (
    <ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        PaperProps={{ sx: { height: '100vh' } }}
        onClose={() => close()}
      >
        <DialogTitle sx={{ pb: 0 }}>
          {data.case({
            pending: () => strings.editParentChildrenTitle,
            fulfilled: (d) =>
              strings.editParentChildrenTitle + ' – ' + AccountUtils.getDisplayFirstLastName(d.parent, d.parent.email),
            rejected: () => strings.editParentChildrenTitle
          })}
        </DialogTitle>

        <DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => (
              <Observer>
                {() => (
                  <Container sx={{ p: 3 }}>
                    <Column>
                      <Expanded>
                        <Scroll
                          direction="y"
                          sx={{
                            // This is to reset the bottom margin of the last card
                            '& >:last-child': {
                              marginBottom: 2
                            }
                          }}
                        >
                          <ParentChildrenEdition viewModel={data} />
                        </Scroll>
                      </Expanded>
                    </Column>
                  </Container>
                )}
              </Observer>
            )}
          />
        </DialogContent>

        <DialogActions>
          <ObservableActionsPresenter
            data={viewModel.data}
            render={(data) => (
              <>
                <InsightsButton sx={{ ml: 1 }} onClick={() => close()} isExecuting={data.isSaving}>
                  {strings.cancel}
                </InsightsButton>
                <InsightsButton
                  sx={{ ml: 1 }}
                  onClick={() => void data.apply()}
                  isSubmit
                  isDisabled={!data.hasChanges}
                  isExecuting={data.isSaving}
                  showSpinnerOnExecuting
                >
                  {strings.save}
                </InsightsButton>
              </>
            )}
            renderError={() => <InsightsButton onClick={() => close()}>{strings.close}</InsightsButton>}
          />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
