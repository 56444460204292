import { OQDataPoint as PBOQDataPoint } from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { OQValue } from '../types';
import { oqValueFromProtobuf } from '../types/EnumConversion';

export class OQDataPoint {
  private _pb: PBOQDataPoint;

  constructor(pb: PBOQDataPoint) {
    this._pb = pb;
  }

  @LazyGetter()
  get startOfPeriod(): Date {
    const startOfPeriod = this._pb.startOfPeriod;
    if (startOfPeriod == null) {
      throw new Error('Invalid data from backend: OQDataPoint has an undefined startOfPeriod.');
    }

    return startOfPeriod.toDate();
  }

  get value(): OQValue {
    return oqValueFromProtobuf(this._pb.value);
  }
}
