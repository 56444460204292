import { SchoolCalendarViewModel } from '@insights/viewmodels';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Column } from '../layout';
import { EditableDialogActions } from './EditableDialogActions';
import { EditableTermDates } from './EditableTermDates';
import { EditableTermTag } from './EditableTermTag';

export interface TermDialogProps {
  viewModel: SchoolCalendarViewModel;
}

export const TermDialog = observer((props: TermDialogProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel } = props;
  const edition = viewModel.termEditor;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  if (edition.active == null) {
    return <div />;
  }

  return (
    <Dialog open={true} onClose={() => void edition.cancel()} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{strings.editTerm}</DialogTitle>
      <DialogContent>
        <Column horizontalContentAlignment="left">
          <EditableTermTag viewModel={edition.active} sx={{ p: 2, pt: 0 }} />
          <EditableTermDates viewModel={edition.active} sx={{ p: 2, pt: 0 }} />
        </Column>
      </DialogContent>
      <DialogActions>
        <EditableDialogActions edition={edition} hasChanges={edition.active.hasChanges} />
      </DialogActions>
    </Dialog>
  );
});
