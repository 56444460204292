import { ExternalAssociationViewModel } from '@insights/viewmodels';
import { Autocomplete, Box, SxProps, TextField } from '@mui/material';
import { AccountUtils } from '@shared/components/utils/models';
import { AccountModel } from '@shared/models/config';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface EditableOwnerProps {
  sx?: SxProps;
  className?: string;
  viewModel: ExternalAssociationViewModel;
}

export const EditableOwner = observer((props: EditableOwnerProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Box sx={sx} className={className}>
      <Autocomplete
        selectOnFocus
        options={viewModel.teachers}
        value={viewModel.owner}
        onChange={(_, v: AccountModel | null) => viewModel.setOwnerId(v?.id ?? '')}
        renderInput={(params) => (
          <TextField {...params} fullWidth InputLabelProps={{ shrink: true }} error={viewModel.owner == null} />
        )}
        getOptionLabel={(account) => AccountUtils.getDisplayLastFirstName(account)}
        noOptionsText={strings.none}
      />
    </Box>
  );
});
