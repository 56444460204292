import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { ContentDefinitionUtils, DataStructureUtils } from '@shared/components/utils';
import { SchoolYearConfigurationModel, SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { Column, Container, Row } from './layout';

export interface TaskInformationTwoLineProps {
  sx?: SxProps;
  className?: string;
  displayTitle: string;
  notes: string;
  task: ContentDefinitionModel;
  size: 'normal' | 'small';
  sectionsById: Record<string, SectionModel>;
  schoolYearConfig: SchoolYearConfigurationModel;
}

export function TaskInformationTwoLine({
  sx,
  className,
  displayTitle,
  notes,
  size,
  schoolYearConfig,
  sectionsById,
  task
}: TaskInformationTwoLineProps) {
  const sectionsByIdMap = DataStructureUtils.convertDictionaryToMap<SectionModel>(sectionsById);
  const taskIconPublishKind = ContentDefinitionUtils.getTaskIconPublishKind(task, sectionsByIdMap, schoolYearConfig);

  return (
    <Row sx={sx} className={className} hideOverflow verticalContentAlignment="center">
      <TaskIcon
        sx={{ flexShrink: 0 }}
        icon={task.icon}
        workloadLevel={task.workloadLevel}
        squareSize={size === 'normal' ? 30 : 28}
        publishedKind={taskIconPublishKind}
        externalSource={task.externalContent != null ? task.externalContent.sourceIntegration : undefined}
      />

      <Container sx={{ ml: 0.5 }} hideOverflow>
        <Column hideOverflow>
          <MUI.Typography variant={size === 'normal' ? 'body1' : 'body2'} sx={{ lineHeight: 'normal' }} noWrap>
            {displayTitle}
          </MUI.Typography>
          <MUI.Typography
            variant="body2"
            sx={{
              lineHeight: 'normal',
              // Any second line would peek
              maxHeight: 28
            }}
            color="textSecondary"
          >
            {notes}
          </MUI.Typography>
        </Column>
      </Container>
    </Row>
  );
}
