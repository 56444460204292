import { OnboardingVariableResolver } from '@insights/viewmodels';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import * as MUI from '@mui/material';
import { SxProps, styled } from '@mui/material';
import { OnboardingQuestion } from '@shared/models/onboarding/interfaces';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { OnboardingTypographySelector } from './OnboardingTypographySelector';
import { ReadOnlyMultipleChoiceQuestion, ReadOnlySingleChoiceQuestion } from './questions';

export interface OnboardingQuestionReadOnlyViewProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  question: OnboardingQuestion;
  displayAnswer?: boolean;
  variableResolver?: OnboardingVariableResolver;
}

export const OnboardingQuestionReadOnlyView = observer((props: OnboardingQuestionReadOnlyViewProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, question, variableResolver, displayAnswer = false } = props;

  const renderInput = () => {
    const models = localizationService.localizedStrings.models;
    const strings = localizationService.localizedStrings.insights.views.onboarding;

    switch (question.kind) {
      case 'simple-text':
        return (
          <MUI.TextField
            className="question"
            fullWidth
            disabled
            value={displayAnswer ? question.answer?.simpleTextValue : ''}
          />
        );

      case 'large-text':
        return (
          <MUI.TextField
            className="question"
            fullWidth
            multiline
            rows={5}
            disabled
            value={displayAnswer ? question.answer?.largeTextValue : ''}
          />
        );

      case 'single-choice':
        return (
          <ReadOnlySingleChoiceQuestion
            className="question"
            choices={question.choices}
            answer={displayAnswer ? question.answer?.singleChoiceValue : undefined}
          />
        );

      case 'multiple-choice':
        return (
          <ReadOnlyMultipleChoiceQuestion
            className="question"
            choices={question.choices}
            answers={displayAnswer ? question.answer?.multipleChoicesValue : []}
          />
        );

      case 'date':
        return (
          <MUI.TextField
            className="question"
            type="date"
            disabled
            value={
              displayAnswer && question.answer?.dateValue != null
                ? format(question.answer.dateValue, models?.dateFormats.mediumUnabridged)
                : ''
            }
          />
        );

      case 'date-time':
        return (
          <MUI.TextField
            className="question"
            type="datetime-local"
            disabled
            value={
              displayAnswer && question.answer?.dateTimeValue != null
                ? format(question.answer.dateTimeValue, models?.dateFormats.mediumUnabridgedWithTime)
                : ''
            }
          />
        );

      case 'time':
        return (
          <MUI.TextField
            className="question"
            type="time"
            disabled
            value={
              displayAnswer && question.answer?.timeValue != null
                ? format(new Date(question.answer.timeValue), models?.timeFormats.doubleDigits)
                : ''
            }
          />
        );

      case 'file': {
        const hasAnswer = question.answer?.fileValue != null;
        return (
          <MUI.Box display="flex" flexDirection="row" alignItems="center">
            <MUI.Typography variant="caption" noWrap>
              {displayAnswer && hasAnswer ? (
                <MUI.Link href={question.answer.fileValue} target="_blank" rel="noreferrer">
                  {question.answer.fileValue}
                </MUI.Link>
              ) : hasAnswer ? (
                strings.fileProvided
              ) : (
                strings.fileNotProvided
              )}
            </MUI.Typography>
            {displayAnswer && hasAnswer && <OpenInNewIcon fontSize="small" className="openInNewIcon" />}
          </MUI.Box>
        );
      }

      case 'sensitive-simple-text':
        return (
          <MUI.TextField
            className="question"
            type="password"
            fullWidth
            disabled
            value={displayAnswer ? question.answer?.sensitiveSimpleTextValue : ''}
          />
        );
    }
  };

  return (
    <Root sx={sx} className={className} style={style}>
      <MUI.Divider className="divider" />
      <OnboardingTypographySelector texts={question.description} variableResolver={variableResolver} />
      {renderInput()}
    </Root>
  );
});

const Root = styled(MUI.Box)(({ theme }) => ({
  '.description': {
    marginBottom: theme.spacing(2)
  },
  '.divider': {
    marginBottom: theme.spacing(2)
  },
  '.question': {
    maxWidth: 600,
    marginRight: theme.spacing(2)
  },
  '.openInNewIcon': {
    marginLeft: theme.spacing(1)
  }
}));
