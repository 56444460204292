// TODO MUI.Box

import {
  Column,
  Container,
  Expanded,
  InsightsButton,
  ObservableActionsPresenter,
  ObservablePresenter,
  Row,
  Scroll,
  SectionName,
  SectionScheduleEdition,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { SectionUtils } from '@insights/utils';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SectionSchedulesEditionDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  sectionId: string;
}

export const SectionSchedulesEditionDialog = observer((props: SectionSchedulesEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style, sectionId, configId, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createSectionEdition(configId, [sectionId], true, onSuccess!, onCancel!)
  );

  const close = () => {
    void viewModel.close();
  };

  return (
    <ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="xl"
        fullWidth={true}
        PaperProps={{ sx: { height: '100vh' } }}
        onClose={() => close()}
      >
        <DialogTitle>{strings.editSectionSchedules}</DialogTitle>

        <DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => {
              const defaultTeacher = data.teachersById[data.defaultTeacherId];

              return (
                <Container>
                  <Column>
                    <Row horizontalContentAlignment="stretch" verticalContentAlignment="center">
                      <SectionName
                        title={SectionUtils.formatTitle(data.section)}
                        subInformation1={data.importId}
                        subInformation2={data.sectionNumber}
                        color={data.section.color}
                        sx={{ mr: '48px' }}
                      />
                      <Typography variant="subtitle1" sx={{ mr: '48px' }}>
                        {defaultTeacher != null ? AccountUtils.getDisplayLastFirstName(defaultTeacher) : ''}
                      </Typography>
                    </Row>
                    <Expanded>
                      <Scroll
                        direction="y"
                        sx={{
                          // This is to reset the bottom margin of the last card
                          '& >:last-child': {
                            marginBottom: 2
                          }
                        }}
                      >
                        <SectionScheduleEdition viewModel={data} />
                      </Scroll>
                    </Expanded>
                  </Column>
                </Container>
              );
            }}
          />
        </DialogContent>

        <DialogActions>
          <ObservableActionsPresenter
            data={viewModel.data}
            render={(data) => (
              <Column>
                <Row verticalContentAlignment="center">
                  <Column horizontalContentAlignment="stretch">
                    {data.messages.map((message, index) => (
                      <Typography key={`message-${index}`} color="error" variant="caption">
                        {message}
                      </Typography>
                    ))}
                  </Column>
                  <InsightsButton sx={{ m: 1 }} onClick={() => close()}>
                    {strings.cancel}
                  </InsightsButton>
                  <InsightsButton
                    sx={{ m: 1 }}
                    isDefault
                    onClick={() => void data.apply()}
                    isDisabled={!data.hasChanges}
                  >
                    {strings.save}
                  </InsightsButton>
                </Row>
              </Column>
            )}
            renderError={() => <InsightsButton onClick={() => close()}>{strings.close}</InsightsButton>}
          />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
