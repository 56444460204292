// TODO: Change to MUI.Box

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR';
import {
  createChartSubTitle as utilsCreateChartSubTitle,
  createChartTitle as utilsCreateChartTitle
} from '@insights/utils';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { DataPoint } from '@shared/models/metrics';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

type Chart = am4charts.XYChart;

interface ChartElements {
  chart: Chart;
  title: am4core.Label;
  subTitle: am4core.Label;
  series: am4charts.LineSeries;
  dateAxis: am4charts.DateAxis;
  valueAxis: am4charts.ValueAxis;
}

export interface SessionsPerDayChartProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  caption: string;
  subcaption?: string;
  color: string;
  sessions: DataPoint[];
}

export const SessionsPerDayChart = observer((props: SessionsPerDayChartProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, sessions, subcaption, color, caption } = props;
  const theme = useTheme();

  const chartElements = useRef<ChartElements | null>(null);

  function createChart() {
    const chart = am4core.create('sessions-per-day-chart-chart-container', am4charts.XYChart);
    chart.dateFormatter.firstDayOfWeek = 0; // Sunday

    const { title, subTitle } = createTitle(chart, theme);
    const { dateAxis, valueAxis } = createAxis(chart, theme, weekendFillRule);
    createCursor(chart, dateAxis, valueAxis);
    const series = createSeries(chart, color);
    createLegend(chart, theme);

    chartElements.current = {
      chart,
      title,
      subTitle,
      dateAxis,
      valueAxis,
      series
    };
  }

  function weekendFillRule(dataItem: am4charts.DateAxisDataItem, dateAxis: am4charts.DateAxis) {
    if (dateAxis.gridInterval.timeUnit === 'day' && dateAxis.gridInterval.count === 1) {
      dataItem.axisFill.visible = dataItem.date.getDay() === 0 || dataItem.date.getDay() === 6;
    } else {
      dataItem.axisFill.visible = false;
    }
  }

  function setChartData() {
    if (chartElements.current != null) {
      chartElements.current.series.data = sessions;
    }
  }

  function setChartTitle() {
    if (chartElements.current == null) {
      return;
    }

    const { title, subTitle } = chartElements.current;
    const marginBottom = Number(theme.spacing(3).replace('px', ''));

    title.text = caption;
    title.marginBottom = marginBottom;

    if (subcaption != null) {
      title.marginBottom = 0;

      subTitle.text = subcaption;
      subTitle.marginBottom = marginBottom;
    }
  }

  function setChartTooltip() {
    if (chartElements.current == null) {
      return;
    }

    const series = chartElements.current.series;
    series.tooltip!.label.fontSize = theme.typography.body1.fontSize;
    series.tooltip!.label.fontWeight = '300';
    series.tooltip!.zIndex = 9999;

    series.tooltipHTML = `
      <table>
        <tr>
          <td style="font-weight: 500;">{dateX.formatDate('EEEE, MMMM d')}</td>
        </tr>
        <tr>
          <td><span style="font-weight: 500;">{valueY.value}</span> sessions</td>
        </tr>
      </table>`;
  }

  useLayoutEffect(() => {
    createChart();

    setChartTitle();
    setChartTooltip();
    setChartData();
  }, []);

  useEffect(() => {
    const currentLocaleChangeDisposer = autorun(() => {
      const chart = chartElements.current?.chart;
      if (chart != null) {
        chart.language.locale = localizationService.currentLocale === 'fr' ? am4lang_fr_FR : am4lang_en_US;
      }
    });

    return () => {
      currentLocaleChangeDisposer();
      chartElements.current?.chart.dispose();
    };
  }, []);

  useEffect(() => {
    setChartTitle();
    setChartTooltip();
    setChartData();
  }, [caption, subcaption, sessions]);

  return (
    <Box
      id="sessions-per-day-chart-chart-container"
      sx={{ ...sx, width: '100%', height: '100%' }}
      className={className}
      style={style}
    />
  );
});

function createTitle(chart: Chart, theme: Theme) {
  const title = utilsCreateChartTitle(theme);
  const subTitle = utilsCreateChartSubTitle(theme);

  chart.titles.push(subTitle);
  chart.titles.push(title);
  return { title, subTitle };
}

function createAxis(
  chart: Chart,
  theme: Theme,
  weekendFillRule: (dataItem: am4charts.DateAxisDataItem, dateAxis: am4charts.DateAxis) => void
) {
  // DATE AXIS
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());

  // This value make the labels display properly on smaller charts when
  // displaying only the number.
  dateAxis.renderer.minGridDistance = 25;

  dateAxis.renderer.gridContainer.strokeWidth = 0; // Disable the vertical grid lines
  dateAxis.renderer.cellStartLocation = 0.1;
  dateAxis.renderer.cellEndLocation = 0.9;
  dateAxis.renderer.grid.template.location = 0.5;
  dateAxis.renderer.labels.template.location = 0.5;
  dateAxis.renderer.ticks.template.location = 0.5;

  dateAxis.fontSize = theme.typography.body1.fontSize;
  dateAxis.fontWeight = '300';
  dateAxis.tooltip!.label.fontSize = theme.typography.body1.fontSize;
  dateAxis.tooltip!.label.fontWeight = '300';
  dateAxis.tooltipDateFormat = 'EEEE, MMM d';

  dateAxis.baseInterval = { timeUnit: 'day', count: 1 };

  // This is to disable the axis animation when changing the period display
  dateAxis.rangeChangeDuration = 0;

  // Configure how date formatting of the dates on the axis
  dateAxis.dateFormats.setKey('day', 'd');
  dateAxis.periodChangeDateFormats.setKey('day', '[font-weight: 500]MMM[/]');
  dateAxis.dateFormats.setKey('week', 'd');
  dateAxis.periodChangeDateFormats.setKey('week', '[font-weight: 500]MMM[/]');
  dateAxis.dateFormats.setKey('month', 'd');
  dateAxis.periodChangeDateFormats.setKey('month', '[font-weight: 500]MMM[/]');

  // Configure the weekend background
  dateAxis.renderer.axisFills.template.disabled = false;
  dateAxis.renderer.axisFills.template.fill = am4core.color('grey');
  dateAxis.renderer.axisFills.template.fillOpacity = 0.1;
  dateAxis.fillRule = (dataItem) => weekendFillRule(dataItem, dateAxis);

  // VALUE AXIS
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

  valueAxis.min = 0;
  valueAxis.maxPrecision = 0;
  valueAxis.cursorTooltipEnabled = false;

  valueAxis.fontSize = theme.typography.body1.fontSize;
  valueAxis.fontWeight = '300';
  valueAxis.tooltip!.label.fontSize = theme.typography.body1.fontSize;
  valueAxis.tooltip!.label.fontWeight = '300';

  return { dateAxis, valueAxis };
}

function createCursor(chart: Chart, dateAxis: am4charts.DateAxis, valueAxis: am4charts.ValueAxis) {
  const cursor = new am4charts.XYCursor();
  cursor.behavior = 'none';
  cursor.xAxis = dateAxis;
  cursor.yAxis = valueAxis;
  cursor.lineY.disabled = true;

  chart.cursor = cursor;
}

function createSeries(chart: Chart, color: string) {
  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.dateX = 'startOfPeriod';
  series.dataFields.valueY = 'value';
  series.fill = am4core.color(color);
  series.fillOpacity = 1;
  series.stroke = am4core.color(color);
  series.strokeOpacity = 1;
  series.legendSettings.labelText = 'Sessions per day';

  return series;
}

function createLegend(chart: Chart, theme: Theme) {
  const legend = new am4charts.Legend();
  legend.itemContainers.template.clickable = false;
  legend.itemContainers.template.focusable = false;
  legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
  legend.itemContainers.template.fontSize = theme.typography.body2.fontSize;
  legend.itemContainers.template.fontWeight = '300';
  chart.legend = legend;
}
