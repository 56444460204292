import DeleteIcon from '@mui/icons-material/Delete';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { EditableQuestionChoice } from '@shared/models/onboarding/implementations';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { OnboardingTextEdition } from './OnboardingTextEdition';

export interface OnboardingChoiceEditionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  choice: EditableQuestionChoice;
}

export const OnboardingChoiceEdition = observer((props: OnboardingChoiceEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, choice } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const enText = choice.editableLabel.find((t) => t.languageCode === 'en');
  const frText = choice.editableLabel.find((t) => t.languageCode === 'fr');

  return (
    <MUI.Grid sx={sx} container className={className} style={style} alignItems="flex-end">
      {enText && (
        <MUI.Grid item xs={6} sm={4}>
          <OnboardingTextEdition label="" text={enText} enforcePlainText rows={1} />
        </MUI.Grid>
      )}
      {frText && (
        <MUI.Grid item xs={6} sm={4}>
          <OnboardingTextEdition label="" text={frText} enforcePlainText rows={1} />
        </MUI.Grid>
      )}
      <MUI.Grid item xs={5} sm={3}>
        <MUI.TextField
          fullWidth
          label={strings.questionChoiceValueLabel}
          value={choice.value}
          onChange={(e) => (choice.value = e.target.value)}
        />
      </MUI.Grid>
      <MUI.Grid item xs={1}>
        <MUI.IconButton onClick={() => choice.markAsDeleted()}>
          <DeleteIcon fontSize="small" />
        </MUI.IconButton>
      </MUI.Grid>
    </MUI.Grid>
  );
});
