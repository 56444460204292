// TODO MUI.Box

import { css } from '@emotion/css';
import { Drawer, List, ListItemButton, SxProps, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  AdminAuthorizationRoles,
  AdminOrStaffAuthorizationRoles,
  AdminOrTeacherAuthorizationRoles,
  AuthorizationRole,
  EmployeeAuthorizationRoles
} from '@shared/models/types';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { RouteParamNames, RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from './AuthorizationRoleCondition';
import { DemoIndicator } from './DemoIndicator.tsx';
import { Column, Container, Expanded } from './layout';

const ManageSideNavigationWidth = 200;

export interface ManageSideNavigationProps {
  sx?: SxProps;
  className?: string;
  configId: string;
  demoMode?: boolean;
}

const SideNavigationLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  return <NavLink className={({ isActive }) => clsx(isActive && 'active')} {...props} ref={ref} />;
});
SideNavigationLink.displayName = 'SideNavigationLink';

interface RouteInfo {
  readonly template: string;
  readonly roles: AuthorizationRole[];
}

const routeInfos: RouteInfo[] = [
  {
    template: RouteTemplates.manageOnboarding,
    roles: EmployeeAuthorizationRoles
  },
  {
    template: RouteTemplates.manageGeneral,
    roles: EmployeeAuthorizationRoles
  },
  { template: RouteTemplates.manageCalendar, roles: AdminAuthorizationRoles },
  {
    template: RouteTemplates.manageSections,
    roles: EmployeeAuthorizationRoles
  },
  {
    template: RouteTemplates.manageTeachers,
    roles: EmployeeAuthorizationRoles
  },
  {
    template: RouteTemplates.manageStudents,
    roles: EmployeeAuthorizationRoles
  },
  {
    template: RouteTemplates.manageParents,
    roles: EmployeeAuthorizationRoles
  },
  {
    template: RouteTemplates.manageStaff,
    roles: AdminOrStaffAuthorizationRoles
  },
  {
    template: RouteTemplates.manageExternalAccounts,
    roles: AdminOrTeacherAuthorizationRoles
  },
  {
    template: RouteTemplates.manageImportSessions,
    roles: AdminAuthorizationRoles
  },
  { template: RouteTemplates.manageFeatures, roles: ['super-admin'] }
];

export const ManageSideNavigation = observer((props: ManageSideNavigationProps) => {
  const { environmentService, localizationService, reactRouterRouteService, settingsStore } = useInsightsServices();
  const { sx, className, configId, demoMode = false } = props;
  const params = [{ name: RouteParamNames.configId, value: configId }];
  const routeStrings = localizationService.localizedStrings.insights.routes;
  const theme = useTheme();

  const routes = routeInfos.map((info) => ({
    name: reactRouterRouteService.getRouteName(info.template, {
      ...routeStrings
    }),
    template: info.template,
    to: reactRouterRouteService.resolveLocation(info.template, params),
    roles: info.roles
  }));

  const linkClassName = css({
    borderLeft: '4px solid transparent',
    '&:hover': {
      borderLeft: '4px solid ' + grey[400]
    },
    '&.active': {
      borderLeft: '4px solid ' + theme.palette.secondary.main,
      '& >:first-of-type': {
        fontWeight: 400
      }
    },
    textTransform: 'uppercase',
    height: '50px !important'
  });

  const handleClose = () => (settingsStore.sidenavOpen = false);

  return (
    <Drawer
      sx={{ width: ManageSideNavigationWidth, ...sx }}
      variant={settingsStore.sidenavCollapsed ? 'temporary' : 'permanent'}
      open={settingsStore.sidenavOpen}
      onClose={handleClose}
    >
      <Column sx={{ flex: 1, width: ManageSideNavigationWidth, backgroundColor: '#fff' }} className={className}>
        <Expanded>
          <List sx={{ flex: 1, padding: 0, paddingTop: 9 }}>
            {routes.map((route) => (
              <AuthorizationRoleCondition key={`manage-menu-${route.name}`} allowedRoles={route.roles}>
                <ListItemButton
                  component={SideNavigationLink}
                  {...{ to: route.to }}
                  className={linkClassName}
                  onClick={handleClose}
                >
                  <Typography variant="body2" fontWeight="300">
                    {route.name}
                  </Typography>
                </ListItemButton>
              </AuthorizationRoleCondition>
            ))}
          </List>
        </Expanded>

        <Container sx={{ m: 2 }}>
          <Column horizontalContentAlignment="center">
            <Typography variant="body2" fontWeight="300" fontSize={10} sx={{ opacity: 0.5 }}>
              {environmentService.formattedVersionNumber}
            </Typography>
          </Column>
        </Container>

        {demoMode && <DemoIndicator />}
      </Column>
    </Drawer>
  );
});
