import NextDateIcon from '@mui/icons-material/ChevronRight';
import TargetDateIcon from '@mui/icons-material/KeyboardArrowDown';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { AllOnboardingStatuses, AllStepOnboardingStatuses, OnboardingStatus } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { differenceInCalendarDays, format, startOfDay } from 'date-fns';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface OnboardingStatusChipProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  status: OnboardingStatus;
  nextDate?: Date;
  targetDate?: Date;
  isStepStatus?: boolean;
  isBlocked?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  onChange?: (status: OnboardingStatus) => Promise<void>;
}

interface ChipProps {
  sx?: SxProps;
  status: OnboardingStatus;
  disabled?: boolean;
  isBlocked?: boolean;
  size?: 'small' | 'medium';
  label?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Chip = observer((props: ChipProps) => {
  const { status, isBlocked = false, disabled = false, sx, size, label, onClick } = props;

  if (disabled) {
    // Use outline look.
    switch (status) {
      case 'not-started':
        return (
          <MUI.Chip
            sx={{ borderWidth: 2, borderColor: orange[300], color: orange[300], ...sx }}
            variant="outlined"
            size={size}
            label={label}
            onClick={onClick}
          />
        );
      case 'in-progress':
        return (
          <MUI.Chip
            sx={{ borderWidth: 2, borderColor: green[300], color: isBlocked ? red[500] : green[300], ...sx }}
            variant="outlined"
            size={size}
            label={label}
            onClick={onClick}
          />
        );
      case 'completed':
        return (
          <MUI.Chip
            sx={{ borderWidth: 2, borderColor: grey[700], color: grey[700], ...sx }}
            variant="outlined"
            size={size}
            label={label}
            onClick={onClick}
          />
        );
      case 'archived':
        return (
          <MUI.Chip
            sx={{ borderWidth: 2, borderColor: grey[400], color: grey[400], ...sx }}
            variant="outlined"
            size={size}
            label={label}
            onClick={onClick}
          />
        );
    }
  }

  switch (status) {
    case 'not-started':
      return (
        <MUI.Chip
          sx={{ backgroundColor: orange[200], color: 'white', ...sx }}
          size={size}
          label={label}
          onClick={onClick}
        />
      );
    case 'in-progress':
      return (
        <MUI.Chip
          sx={{ backgroundColor: green[300], color: isBlocked ? red[500] : 'white', ...sx }}
          size={size}
          label={label}
          onClick={onClick}
        />
      );
    case 'completed':
      return (
        <MUI.Chip
          sx={{ backgroundColor: grey[700], color: 'white', ...sx }}
          size={size}
          label={label}
          onClick={onClick}
        />
      );
    case 'archived':
      return (
        <MUI.Chip
          sx={{ backgroundColor: grey[400], color: 'white', ...sx }}
          size={size}
          label={label}
          onClick={onClick}
        />
      );
  }
});

export const OnboardingStatusChip = observer((props: OnboardingStatusChipProps) => {
  const { localizationService } = useInsightsServices();
  const {
    sx,
    status,
    nextDate,
    targetDate,
    isStepStatus = false,
    size,
    isBlocked = false,
    disabled = false,
    onChange
  } = props;
  const statuses = isStepStatus ? AllStepOnboardingStatuses : AllOnboardingStatuses;
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | undefined>(undefined);

  const closeOnChange = (status: OnboardingStatus) => {
    void onChange?.(status);
    setMenuAnchor(undefined);
  };

  const onClick: React.MouseEventHandler<HTMLDivElement> | undefined = disabled
    ? undefined
    : (e) => setMenuAnchor(menuAnchor == null ? e.currentTarget : undefined);

  return (
    <>
      <Chip
        sx={sx}
        size={size}
        status={status}
        disabled={disabled}
        isBlocked={isBlocked}
        label={renderLabel(status, isStepStatus ?? false, size, isBlocked, localizationService, nextDate, targetDate)}
        onClick={onClick}
      />
      {!disabled && (
        <MUI.Menu
          open={menuAnchor != null}
          onClose={() => setMenuAnchor(undefined)}
          anchorEl={menuAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {statuses.map((s) => (
            <MUI.MenuItem key={`status-menu-${s}`} value={s} onClick={() => closeOnChange(s)}>
              <Chip status={s} label={renderLabel(s, isStepStatus, size, false, localizationService)} />
            </MUI.MenuItem>
          ))}
        </MUI.Menu>
      )}
    </>
  );
});

function renderLabel(
  status: OnboardingStatus,
  isStepStatus: boolean,
  size: 'small' | 'medium' | undefined,
  isBlocked: boolean,
  localizationService: LocalizationService,
  nextDate?: Date,
  targetDate?: Date
) {
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const labelSelector = isStepStatus ? strings.getStepStatusLabel : strings.getProcessStatusLabel;

  const variant = size === 'small' ? 'caption' : 'body1';
  const isPending = status === 'not-started' || status === 'in-progress';

  return (
    <MUI.Box display="flex" flexDirection="row" alignItems="center">
      <MUI.Typography variant={variant}>{labelSelector(status, isBlocked)}</MUI.Typography>
      {nextDate && renderDate(nextDate, variant, isPending, false, localizationService)}
      {targetDate && renderDate(targetDate, variant, isPending, nextDate != null, localizationService)}
    </MUI.Box>
  );
}

function renderDate(
  date: Date,
  variant: 'caption' | 'body1',
  isPending: boolean,
  isTarget: boolean,
  localizationService: LocalizationService
) {
  const day = startOfDay(date);
  const dateLabel = format(day, localizationService.localizedStrings.models.dateFormats.short);
  const color = isPending && differenceInCalendarDays(day, startOfDay(new Date())) < 0 ? 'error' : 'inherit';

  return (
    <>
      {isTarget || !isPending ? (
        <TargetDateIcon fontSize="small" color={color} />
      ) : (
        <NextDateIcon fontSize="small" color={color} />
      )}
      <MUI.Typography variant={variant} color={color}>
        {dateLabel}
      </MUI.Typography>
    </>
  );
}
