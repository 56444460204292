import { ImportSessionFileListViewModel } from '@insights/viewmodels';
import LinkSelfIcon from '@mui/icons-material/AllInclusive';
import ManualLinkIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/NoteAdd';
import UploadIcon from '@mui/icons-material/Publish';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { MoreActionMenu } from '../MoreActionMenu';

export interface ImportSessionFileListProps {
  viewModel: ImportSessionFileListViewModel;
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionFileList = observer((props: ImportSessionFileListProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.import;

  return (
    <MUI.Box sx={sx} className={className} style={style} flexDirection="column">
      <MUI.CardHeader
        title={strings.expectedFiles}
        action={
          <AuthorizationRoleCondition allowedRoles={['super-admin']}>
            <MUI.Tooltip title={strings.addExpectedFileTooltip}>
              <MUI.IconButton onClick={() => void viewModel.addExpectedFile()}>
                <AddIcon />
              </MUI.IconButton>
            </MUI.Tooltip>
          </AuthorizationRoleCondition>
        }
      />

      <MUI.CardContent>
        {viewModel.expectedFiles.map((file, index) => (
          <React.Fragment key={`file-row-${file.name}`}>
            <MUI.Box my={1} flexDirection="row" display="flex" alignItems="flex-start">
              <MUI.Typography fontWeight="700" mr={2} variant="subtitle1">
                {file.label}
              </MUI.Typography>

              <MUI.Box flex={1} flexDirection="column" px={1}>
                <MUI.Typography variant="subtitle1">{file.name}</MUI.Typography>
                <MUI.Typography variant="body1" color="textSecondary">
                  {file.description}
                </MUI.Typography>

                {file.url ? (
                  <MUI.Typography
                    variant="subtitle2"
                    noWrap
                    color="primary"
                    style={{ direction: 'rtl', textAlign: 'left' }}
                  >
                    <MUI.Link href={file.url}>{file.url}</MUI.Link>
                  </MUI.Typography>
                ) : (
                  <MUI.Typography variant="subtitle2" noWrap color="primary">
                    {strings.noUrlPlaceholder}
                  </MUI.Typography>
                )}
              </MUI.Box>

              <MoreActionMenu>
                <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                  <MUI.MenuItem onClick={() => void viewModel.uploadFile(file)}>
                    <MUI.ListItemIcon>
                      <UploadIcon />
                    </MUI.ListItemIcon>
                    <MUI.ListItemText>{strings.editFileUrl}</MUI.ListItemText>
                  </MUI.MenuItem>
                </AuthorizationRoleCondition>

                <MUI.MenuItem onClick={() => void viewModel.linkFileToProcess(file)}>
                  <MUI.ListItemIcon>
                    <LinkIcon />
                  </MUI.ListItemIcon>
                  <MUI.ListItemText>{strings.linkFileToQuestion}</MUI.ListItemText>
                </MUI.MenuItem>
                <MUI.MenuItem onClick={() => void viewModel.linkFileToConfiguration(file)}>
                  <MUI.ListItemIcon>
                    <LinkSelfIcon />
                  </MUI.ListItemIcon>
                  <MUI.ListItemText>{strings.linkFileToConfiguration}</MUI.ListItemText>
                </MUI.MenuItem>
                <MUI.MenuItem onClick={() => void viewModel.linkFileManually(file)}>
                  <MUI.ListItemIcon>
                    <ManualLinkIcon />
                  </MUI.ListItemIcon>
                  <MUI.ListItemText>{strings.linkFileManually}</MUI.ListItemText>
                </MUI.MenuItem>

                <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                  <MUI.MenuItem onClick={() => void viewModel.editFileOptions(file)}>
                    <MUI.ListItemIcon>
                      <EditIcon />
                    </MUI.ListItemIcon>
                    <MUI.ListItemText>{strings.editFileOptions}</MUI.ListItemText>
                  </MUI.MenuItem>
                  <MUI.MenuItem onClick={() => void viewModel.removeFile(file)}>
                    <MUI.ListItemIcon>
                      <DeleteIcon />
                    </MUI.ListItemIcon>
                    <MUI.ListItemText>{strings.removeFile}</MUI.ListItemText>
                  </MUI.MenuItem>
                </AuthorizationRoleCondition>
              </MoreActionMenu>
            </MUI.Box>

            {index < viewModel.expectedFiles.length - 1 && <MUI.Divider />}
          </React.Fragment>
        ))}
      </MUI.CardContent>
    </MUI.Box>
  );
});
