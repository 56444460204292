import {
  ObservablePresenter,
  OnboardingStatusChip,
  OnboardingStatusSelector,
  OnboardingTypographySelector,
  OptionalRouterLink,
  PageHeaderBar,
  RouterLink
} from '@insights/components';
import { OnboardingDashboardStepViewModel } from '@insights/viewmodels';
import ArchivedIcon from '@mui/icons-material/Archive';
import ClearIcon from '@mui/icons-material/Clear';
import StudyoParticipantIcon from '@mui/icons-material/HeadsetMic';
import PreparingIcon from '@mui/icons-material/HourglassEmpty';
import BothParticipantsIcon from '@mui/icons-material/People';
import ClientParticipantIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import ActiveIcon from '@mui/icons-material/ThumbUp';
import * as MUI from '@mui/material';
import { SxProps, styled } from '@mui/material';
import { ConfigState, OnboardingParticipantKind } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { differenceInCalendarDays } from 'date-fns';
import { last } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface OnboardingDashboardProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const OnboardingDashboard = observer((props: OnboardingDashboardProps) => {
  const { localizationService, reactRouterRouteService, viewModelFactory } = useInsightsServices();
  const { className, sx, style } = props;
  const strings = localizationService.localizedStrings.insights.views;

  const viewModel = useMemo(() => viewModelFactory.createOnboardingDashboard(), []);

  return (
    <Root sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar>
        <MUI.Box display="flex" flexDirection="row" alignItems="center">
          <OnboardingStatusSelector
            className={'statusSelector'}
            selectedProcessStatuses={viewModel.processStatuses}
            selectedStepStatuses={viewModel.stepStatuses}
            minimumDate={viewModel.minimumDate}
            setStatuses={viewModel.setStatuses.bind(viewModel)}
          />
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              viewModel.search();
            }}
          >
            <MUI.TextField
              className={'search'}
              placeholder={strings.search}
              value={viewModel.searchTerm}
              onChange={(e) => (viewModel.searchTerm = e.target.value)}
            />
            <MUI.IconButton size="small" onClick={() => viewModel.search()}>
              <SearchIcon />
            </MUI.IconButton>
            {viewModel.activeSearchTerm.length > 0 && (
              <MUI.IconButton size="small" onClick={() => viewModel.resetSearch()}>
                <ClearIcon />
              </MUI.IconButton>
            )}
          </form>
        </MUI.Box>
        <RouterLink
          underline="none"
          to={reactRouterRouteService.resolveLocation(RouteTemplates.onboardingDashboardComments)}
        >
          {strings.viewComments}
        </RouterLink>
      </PageHeaderBar>

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingSchoolConfigMessage}
          errorMessage={strings.loadingSchoolConfigErrorMessage}
          render={(data) => (
            <MUI.Box>
              {viewModel.activeSearchTerm.length > 0 && (
                <MUI.Typography className={'searchResultsLabel'} variant="subtitle1">
                  {strings.searchResults + viewModel.activeSearchTerm}
                </MUI.Typography>
              )}
              <MUI.TableContainer component={MUI.Paper} className={'table'}>
                <MUI.Table>
                  <MUI.TableHead>
                    <MUI.TableRow>
                      <MUI.TableCell style={{ width: '20%' }}>{strings.school}</MUI.TableCell>
                      <MUI.TableCell style={{ width: '50%' }}>{strings.steps}</MUI.TableCell>
                      <MUI.TableCell style={{ width: '10%' }}>{strings.startDate}</MUI.TableCell>
                      <MUI.TableCell style={{ width: '20%' }}>{strings.status}</MUI.TableCell>
                    </MUI.TableRow>
                  </MUI.TableHead>
                  <MUI.TableBody>
                    {data.map((item) => (
                      <MUI.TableRow key={item.process.id} className={'tableRow'}>
                        <MUI.TableCell>
                          <RouterLink
                            underline="none"
                            to={reactRouterRouteService.resolveLocation(RouteTemplates.manageOnboardingProcess, [
                              {
                                name: RouteParamNames.configId,
                                value: item.process.configId
                              },
                              {
                                name: RouteParamNames.processName,
                                value: item.process.templateName
                              }
                            ])}
                          >
                            <MUI.Box display="flex" flexDirection="column" alignItems="left">
                              {item.schoolName}
                              <MUI.Typography variant="caption" className={'comment'}>
                                {last(item.comments)?.message}
                              </MUI.Typography>
                            </MUI.Box>
                          </RouterLink>
                        </MUI.TableCell>
                        <MUI.TableCell>
                          {item.steps
                            .filter((s) => s.stepSummary.status === 'in-progress')
                            .map((step) => (
                              <OptionalRouterLink
                                key={`step-list-${step.stepSummary.id}`}
                                underline="none"
                                disabled={!step.stepSummary.canNavigate}
                                to={reactRouterRouteService.resolveLocation(RouteTemplates.manageOnboardingStep, [
                                  {
                                    name: RouteParamNames.configId,
                                    value: step.stepSummary.configId
                                  },
                                  {
                                    name: RouteParamNames.processName,
                                    value: step.stepSummary.processName
                                  },
                                  {
                                    name: RouteParamNames.templateName,
                                    value: step.stepSummary.templateName
                                  }
                                ])}
                              >
                                <MUI.Box display="flex" flexDirection="column" alignItems="left">
                                  <MUI.Box display="flex" flexDirection="row" alignItems="center">
                                    {renderParticipantsIcon(step.stepSummary.participants, localizationService)}
                                    <OnboardingTypographySelector
                                      variant="body2"
                                      texts={step.stepSummary.title}
                                      variableResolver={item.variableResolver}
                                      sx={{ mr: 1 }}
                                    />
                                    {step.stepSummary.status !== 'completed' &&
                                      step.stepSummary.targetDate != null &&
                                      differenceInCalendarDays(step.stepSummary.targetDate, new Date()) < 0 && (
                                        <MUI.Tooltip title={strings.lateTooltip} placement="top">
                                          <MUI.Typography variant="body2">🕦</MUI.Typography>
                                        </MUI.Tooltip>
                                      )}
                                    {step.stepSummary.isBlocked && (
                                      <MUI.Tooltip title={strings.blockedTooltip} placement="top">
                                        <MUI.Typography variant="body2">🛑</MUI.Typography>
                                      </MUI.Tooltip>
                                    )}
                                    {step.stepSummary.isLocked && (
                                      <MUI.Tooltip title={strings.lockedTooltip} placement="top">
                                        <MUI.Typography variant="body2">🔒</MUI.Typography>
                                      </MUI.Tooltip>
                                    )}
                                    {step.stepSummary.status === 'completed' && (
                                      <MUI.Tooltip title={strings.completedTooltip} placement="top">
                                        <MUI.Typography variant="body2">✅</MUI.Typography>
                                      </MUI.Tooltip>
                                    )}
                                    {!step.stepSummary.isLocked && step.stepSummary.status === 'not-started' && (
                                      <MUI.Tooltip title={strings.notStartedTooltip} placement="top">
                                        <MUI.Typography variant="body2">🟡</MUI.Typography>
                                      </MUI.Tooltip>
                                    )}
                                  </MUI.Box>
                                  <MUI.Typography variant="caption" className="comment">
                                    {last(step.comments)?.message}
                                  </MUI.Typography>
                                </MUI.Box>
                              </OptionalRouterLink>
                            ))}
                        </MUI.TableCell>
                        <MUI.TableCell>{item.schoolStartDate}</MUI.TableCell>
                        <MUI.TableCell>
                          <MUI.Box display="flex" flexDirection="column">
                            <MUI.Box
                              className="statusChip"
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <OnboardingStatusChip
                                size="small"
                                disabled
                                status={item.process.status}
                                nextDate={item.process.nextTargetDate}
                                targetDate={item.process.finalTargetDate}
                              />
                              {renderConfigStateIcon(item.configState)}
                            </MUI.Box>
                            <MUI.LinearProgress
                              className="progress"
                              variant="determinate"
                              color="secondary"
                              value={computeProgressValue(item.steps)}
                            />
                          </MUI.Box>
                        </MUI.TableCell>
                      </MUI.TableRow>
                    ))}
                  </MUI.TableBody>
                </MUI.Table>
              </MUI.TableContainer>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </Root>
  );
});

function computeProgressValue(steps: OnboardingDashboardStepViewModel[]) {
  const totalSteps = steps.filter((s) => !s.stepSummary.isLocked).length;
  const completedSteps = steps.filter((s) => !s.stepSummary.isLocked && s.stepSummary.status === 'completed').length;

  return totalSteps === 0 ? 0 : (completedSteps / totalSteps) * 100;
}

function renderParticipantsIcon(participant: OnboardingParticipantKind, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  switch (participant) {
    case 'studyo-only':
      return (
        <MUI.Tooltip title={strings.participantStudyoTooltip}>
          <StudyoParticipantIcon className="participantIcon" fontSize="small" color="disabled" />
        </MUI.Tooltip>
      );
    case 'client-only':
      return (
        <MUI.Tooltip title={strings.participantClientTooltip}>
          <ClientParticipantIcon className="participantIcon" fontSize="small" color="secondary" />
        </MUI.Tooltip>
      );
    case 'studyo-and-client':
      return (
        <MUI.Tooltip title={strings.participantStudyoAndClientTooltip}>
          <BothParticipantsIcon className="participantIcon" fontSize="small" color="secondary" />
        </MUI.Tooltip>
      );
  }
}

function renderConfigStateIcon(configState: ConfigState) {
  switch (configState) {
    case 'preparing':
      return <PreparingIcon fontSize="small" color="secondary" />;

    case 'active':
      return <ActiveIcon fontSize="small" color="secondary" />;

    case 'archived':
      return <ArchivedIcon fontSize="small" color="secondary" />;
  }
}

const Root = styled(MUI.Box)(({ theme }) => ({
  '.statusSelector': {
    marginRight: theme.spacing(1)
  },
  '.search': {
    marginLeft: theme.spacing(2)
  },
  '.searchResultsLabel': {
    marginLeft: theme.spacing(1)
  },
  '.table': {
    marginBottom: theme.spacing(6)
  },
  '.tableRow': {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    verticalAlign: 'top'
  },
  '.participantIcon': {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0.5)
  },
  '.comment': {
    color: theme.palette.primary.light,
    maxHeight: '1.6em'
  },
  '.statusChip': {
    marginTop: theme.spacing(1)
  },
  '.progress': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));
