import { Schema as PBSchema } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel } from '../../Model';
import { Schema, SchemaField, SchemaImportOption, SchemaSuffixGroup } from '../interfaces';
import { GrpcSchemaField } from './GrpcSchemaField';
import { GrpcSchemaImportOption } from './GrpcSchemaImportOption';
import { GrpcSchemaSuffixGroup } from './GrpcSchemaSuffixGroup';

export class GrpcSchema extends BaseModel<PBSchema> implements Schema {
  constructor(pb: PBSchema) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get name(): string {
    return this._pb.name;
  }

  get description(): string {
    return this._pb.description;
  }

  @LazyGetter()
  get fields(): SchemaField[] {
    return this._pb.fields.map((f) => new GrpcSchemaField(f));
  }

  get alias(): string {
    return this._pb.alias;
  }

  @LazyGetter()
  get importOptions(): SchemaImportOption[] {
    return this._pb.importOptions.map((o) => new GrpcSchemaImportOption(o));
  }

  @LazyGetter()
  get suffixGroups(): SchemaSuffixGroup[] {
    return this._pb.suffixGroups.map((g) => new GrpcSchemaSuffixGroup(g));
  }
}
