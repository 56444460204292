import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { OnboardingOptionsViewModel } from '@insights/viewmodels';
import { Autocomplete, Card, CardContent, Stack, SxProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';

export interface CreateSchoolOnboardingProps {
  sx?: SxProps;
  className?: string;
  viewModel: OnboardingOptionsViewModel;
}

export const CreateSchoolOnboarding = observer(({ sx, className, viewModel }: CreateSchoolOnboardingProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.createSchool;

  return (
    <Card sx={sx} className={className}>
      <CardContent sx={{ width: '100%' }}>
        <Stack spacing={2}>
          <Typography variant="h5">{strings.onboardingSection}</Typography>
          <Autocomplete
            selectOnFocus
            options={viewModel.processTemplateNames}
            value={viewModel.selectedProcessTemplateName}
            onChange={(_, v) => (viewModel.selectedProcessTemplateName = v ?? '')}
            renderInput={(p) => <TextField {...p} label={strings.onboardingProcessNameLabel} />}
            noOptionsText={strings.noOnboardingProcessesLabel}
          />
        </Stack>
      </CardContent>
    </Card>
  );
});
