import { CustomizableViewModel } from '@insights/viewmodels';
import ScheduleIcon from '@mui/icons-material/Notifications';
import { SxProps, Typography } from '@mui/material';
import { SpecialDaySymbolImage } from '@shared/components/special_day_symbols';
import { SectionColors } from '@shared/models/Colors.ts';
import { DayConfigurationModel } from '@shared/models/config';
import { observer } from 'mobx-react';
import { CSSProperties, ReactElement } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { HorizontalAlignment, Row } from '../layout';

export interface CustomizationsListDayConfigurationProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: CustomizableViewModel;
  dayConfiguration: DayConfigurationModel;
  textColor: string;
  variant: 'table' | 'list';
  renderRightComponent?: (dayConfiguration: DayConfigurationModel) => ReactElement;
}

export const CustomizationsListDayConfiguration = observer(
  ({
    sx,
    className,
    style,
    dayConfiguration,
    renderRightComponent,
    variant,
    textColor,
    viewModel
  }: CustomizationsListDayConfigurationProps) => {
    const { localizationService } = useInsightsServices();
    const strings = localizationService.localizedStrings.insights.components.calendar;

    const rowHorizontalAlignment: HorizontalAlignment = variant === 'list' ? 'center' : 'left';
    const rightComponent = () => (variant === 'list' ? renderRightComponent?.(dayConfiguration) : null);

    if (dayConfiguration.specialDayId.length > 0) {
      const specialDay = viewModel.specialDaysById[dayConfiguration.specialDayId];
      return (
        <Row
          sx={sx}
          className={className}
          style={style}
          key={`day-configuration-${dayConfiguration.id}`}
          horizontalContentAlignment={rowHorizontalAlignment}
          verticalContentAlignment="center"
        >
          <SpecialDaySymbolImage
            symbol={specialDay.symbol}
            color={SectionColors.get(specialDay.symbolColor)}
            squareSize={16}
            alwaysDisplaySymbol={true}
            sx={{ m: '2px' }}
          />
          <Typography variant="caption" color={textColor} ml={1} flexShrink={1}>
            {specialDay.title}
          </Typography>
          {rightComponent()}
        </Row>
      );
    } else if (dayConfiguration.scheduleId.length > 0) {
      const schedule = viewModel.schedulesById[dayConfiguration.scheduleId];
      return (
        <Row
          sx={sx}
          className={className}
          style={style}
          key={`day-configuration-${dayConfiguration.id}`}
          horizontalContentAlignment={rowHorizontalAlignment}
          verticalContentAlignment="center"
        >
          <ScheduleIcon fontSize="small" />
          <Typography variant="caption" color={textColor} ml={1} flexShrink={1}>
            {schedule.title}
          </Typography>
          {rightComponent()}
        </Row>
      );
    } else {
      return (
        <Typography
          sx={sx}
          className={className}
          style={style}
          key={`day-configuration-${dayConfiguration.id}`}
          variant="caption"
          color={textColor}
        >
          {strings.unknownDayConfig}
        </Typography>
      );
    }
  }
);
