import { ExternalAssociationList, ObservablePresenter, PageHeaderBar } from '@insights/components';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ExternalAssociationsScreenProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const ExternalAssociationsScreen = observer((props: ExternalAssociationsScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const params = useParams();
  const configId = params.configId ?? '';
  const externalAccountId = params.externalAccountId ?? '';
  const viewModel = useMemo(
    () => viewModelFactory.createExternalAssociations(configId, externalAccountId),
    [configId, externalAccountId]
  );

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ width: '100%', height: '100%', p: 2, overflow: 'auto' }}
          data={viewModel.associationList}
          loadingMessage={strings.loadingExternalAssociationsMessage}
          errorMessage={strings.loadingExternalAssociationsErrorMessage}
          render={(data) => (
            <MUI.Box display="flex" flexDirection="column">
              <MUI.Card sx={{ minWidth: MinLayoutWidth }}>
                <ExternalAssociationList sx={{ width: '100%', height: '100%' }} viewModel={data} />
              </MUI.Card>
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
