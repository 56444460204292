import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import * as React from 'react';
import { CSSProperties } from 'react';
import { RouterLink } from './RouterLink.tsx';
import { Thresholds, ThresholdsInformation } from './ThresholdsInformation.tsx';

export interface SectionNameOneLineProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  title: string;
  titleStyle?: CSSProperties;
  subInformation1?: string;
  subInformation2?: string;
  subInformationStyle?: CSSProperties;
  thresholds?: Thresholds;
  sectionColor?: string;
  fontWeight?: string;
  linkTo?: string;
}

export function SectionNameOneLine({
  sx,
  className,
  style,
  title,
  titleStyle,
  subInformation1,
  subInformation2,
  subInformationStyle,
  thresholds,
  sectionColor,
  fontWeight,
  linkTo
}: SectionNameOneLineProps) {
  return (
    <MUI.Box
      sx={{ ...sx, minHeight: 30 }}
      className={className}
      style={style}
      display="flex"
      flexDirection="row"
      flex={1}
      paddingLeft={1}
      borderLeft={`4px solid ${sectionColor}`}
    >
      <MUI.Box display="flex" flexDirection="row" alignItems="center">
        {/* Display the title as a link if `linkTo` was specified */}
        {linkTo != null && (
          <RouterLink style={titleStyle} to={linkTo}>
            <MUI.Typography color="primary" style={titleStyle} noWrap fontWeight={`${fontWeight} !important`}>
              {title}
            </MUI.Typography>
          </RouterLink>
        )}

        {/* Display the title as a text if `linkTo` was not specified */}
        {linkTo == null && (
          <MUI.Typography style={titleStyle} noWrap fontWeight={`${fontWeight} !important`}>
            {title}
          </MUI.Typography>
        )}

        {(subInformation1 != null || subInformation2 != null) && (
          <MUI.Box marginLeft={3}>
            {subInformation1 != null && (
              <MUI.Typography
                display="inline"
                color="textSecondary"
                style={subInformationStyle}
                noWrap
                fontWeight={`${fontWeight} !important`}
              >
                {subInformation1}
              </MUI.Typography>
            )}

            {subInformation2 != null && (
              <React.Fragment>
                {subInformation1 != null && (
                  <MUI.Typography
                    display="inline"
                    color="textSecondary"
                    style={subInformationStyle}
                    noWrap
                    fontWeight={`${fontWeight} !important`}
                  >
                    {' – '}
                  </MUI.Typography>
                )}

                <MUI.Typography
                  display="inline"
                  color="textSecondary"
                  style={subInformationStyle}
                  noWrap
                  fontWeight={`${fontWeight} !important`}
                >
                  {subInformation2}
                </MUI.Typography>
              </React.Fragment>
            )}
          </MUI.Box>
        )}

        {thresholds != null && (
          <MUI.Box
            marginLeft={subInformation1 != null || subInformation2 != null ? 1 : undefined}
            minWidth="min-content"
          >
            <ThresholdsInformation thresholds={thresholds} />
          </MUI.Box>
        )}
      </MUI.Box>
    </MUI.Box>
  );
}
