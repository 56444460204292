// TODO MUI.Box

import * as MUI from '@mui/material';
import { SxProps, Tooltip, useTheme } from '@mui/material';
import {
  SpecialDaySymbolAndColor,
  SpecialDaySymbolGrid,
  SpecialDaySymbolImage
} from '@shared/components/special_day_symbols';
import { DateUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { SchoolDay } from '@shared/models/calendar';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { Column, Container, Row } from './layout';

export interface SchoolDayHeaderProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  schoolDay: SchoolDay;
  displayType: 'column-header' | 'page-header';
}

export const SchoolDayHeader = observer((props: SchoolDayHeaderProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, displayType, schoolDay } = props;
  const theme = useTheme();

  const specialDaySymbols = schoolDay.visibleSpecialDays.map<SpecialDaySymbolAndColor>((specialDay) => ({
    symbol: specialDay.symbol,
    color: SectionColors.get(specialDay.color)
  }));

  return (
    <Tooltip
      sx={{ ...sx, cursor: 'default' }}
      className={className}
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.getContrastText(theme.palette.common.white),
            boxShadow: theme.shadows[1],
            maxWidth: 'none'
          }
        },
        popper: { sx: { opacity: 1 } }
      }}
      title={
        schoolDay.visibleSpecialDays.length > 0 ? (
          <Column>
            {schoolDay.visibleSpecialDays.map((specialDay, index) => (
              <Row key={`${specialDay.symbol}-${index}`} verticalContentAlignment="center">
                <SpecialDaySymbolImage
                  symbol={specialDay.symbol}
                  color={SectionColors.get(specialDay.color) ?? SectionColors.get('medium-bmgray')}
                  squareSize={16}
                  alwaysDisplaySymbol={true}
                />
                <Container sx={{ ml: 1 }}>
                  <MUI.Typography variant="body2">{specialDay.title}</MUI.Typography>
                </Container>
              </Row>
            ))}
          </Column>
        ) : (
          ''
        )
      }
    >
      <MUI.Badge
        sx={{ mt: 0.5 }}
        badgeContent={schoolDay.displayCycleDayTitle}
        slotProps={{ badge: { style: { right: '-10px', height: 16, minWidth: 16 } } }}
        color={DateUtils.isToday(schoolDay.day) ? 'primary' : 'default'}
        max={9999}
        showZero={true}
      >
        {displayType === 'column-header' && (
          <ColumnHeader
            specialDaySymbols={specialDaySymbols}
            schoolDay={schoolDay}
            localizationService={localizationService}
          />
        )}
        {displayType === 'page-header' && renderAsPageHeader(specialDaySymbols, schoolDay, localizationService)}
      </MUI.Badge>
    </Tooltip>
  );
});

function ColumnHeader({
  specialDaySymbols,
  schoolDay,
  localizationService
}: {
  specialDaySymbols: SpecialDaySymbolAndColor[];
  schoolDay: SchoolDay;
  localizationService: LocalizationService;
}) {
  const theme = useTheme();

  return (
    <Column horizontalContentAlignment="center">
      <MUI.Typography
        variant="subtitle2"
        sx={{
          lineHeight: 'normal',
          color: schoolDay.isPadding ? theme.palette.grey[300] : theme.palette.text.secondary
        }}
      >
        {schoolDay.day.formattedString(localizationService.localizedStrings.models.dateFormats.dayOfWeek)}
      </MUI.Typography>
      <MUI.Typography
        variant="subtitle2"
        sx={{
          fontWeight: 500,
          lineHeight: 'normal',
          color: schoolDay.isPadding ? theme.palette.grey[300] : theme.palette.text.secondary
        }}
      >
        {schoolDay.day.formattedString(localizationService.localizedStrings.models.dateFormats.short)}
      </MUI.Typography>

      <SpecialDaySymbolGrid
        displayKind="linear"
        symbols={specialDaySymbols}
        squareSize={12}
        alwaysDisplaySymbol={true}
        displayNoneSymbol={false}
        sx={{ minHeight: 14, marginTop: '2px' }}
      />
    </Column>
  );
}

function renderAsPageHeader(
  specialDaySymbols: SpecialDaySymbolAndColor[],
  schoolDay: SchoolDay,
  localizationService: LocalizationService
): React.ReactNode {
  return (
    <Column horizontalContentAlignment="right">
      <Row>
        <SpecialDaySymbolGrid
          displayKind="linear"
          symbols={specialDaySymbols}
          squareSize={20}
          alwaysDisplaySymbol={true}
          displayNoneSymbol={false}
        />

        <Container sx={{ ml: 1 }}>
          <MUI.Typography fontSize="1em" lineHeight="normal" color="textSecondary">
            {schoolDay.day.formattedString(localizationService.localizedStrings.models.dateFormats.dayOfWeek)}
          </MUI.Typography>
        </Container>
      </Row>
      <MUI.Typography fontSize="1.25em" lineHeight="normal">
        {schoolDay.day.formattedString(localizationService.localizedStrings.models.dateFormats.mediumUnabridged)}
      </MUI.Typography>
    </Column>
  );
}
