import { AddOnboardingQuestionViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { Autocomplete, createFilterOptions, SxProps } from '@mui/material';
import { AllOnboardingQuestionKinds, OnboardingQuestionKind } from '@shared/models/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { OnboardingQuestionReadOnlyView } from './OnboardingQuestionReadOnlyView';

interface QuestionOption {
  readonly name: string;
  readonly displayName?: string;
}

function isQuestionOption(value: string | QuestionOption | null): value is QuestionOption {
  return value != null && (value as QuestionOption).name != null;
}

const filter = createFilterOptions<QuestionOption>();

export interface AddOnboardingQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: AddOnboardingQuestionViewModel;
}

export const AddOnboardingQuestion = observer((props: AddOnboardingQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <Autocomplete
        freeSolo
        selectOnFocus
        handleHomeEndKeys
        value={viewModel.selectedQuestionName}
        onChange={(_, v) => {
          viewModel.selectedQuestionName = isQuestionOption(v) ? v.name : v ?? '';
        }}
        filterOptions={(o, p) => {
          const filtered = filter(o, p);
          const { inputValue } = p;

          if (inputValue.length > 0 && !viewModel.availableQuestions.some((q) => q.templateName == inputValue)) {
            filtered.push({ name: inputValue, displayName: strings.getCreateQuestionLabel(inputValue) });
          }

          return filtered;
        }}
        options={viewModel.availableQuestions.map<QuestionOption>((q) => ({ name: q.templateName }))}
        getOptionLabel={(o) => (isQuestionOption(o) ? o.displayName ?? o.name : o)}
        renderInput={(params) => <MUI.TextField {...params} fullWidth label={strings.selectQuestionTemplateLabel} />}
      />
      {viewModel.isNewNameSelected && (
        <MUI.FormControl sx={{ mt: 2 }} fullWidth>
          <MUI.InputLabel htmlFor="answer-kind-select" shrink>
            {strings.answerKindLabel}
          </MUI.InputLabel>
          <MUI.Select
            labelId="answer-kind-select"
            value={viewModel.selectedAnswerKind}
            onChange={(e) => (viewModel.selectedAnswerKind = e.target.value as OnboardingQuestionKind)}
          >
            {AllOnboardingQuestionKinds.map((kind) => (
              <MUI.MenuItem value={kind} key={`answer-kind-${kind}`}>
                {strings.getAnswerKindLabel(kind)}
              </MUI.MenuItem>
            ))}
          </MUI.Select>
        </MUI.FormControl>
      )}
      {viewModel.selectedQuestion != null && (
        <OnboardingQuestionReadOnlyView
          sx={{ mt: 2 }}
          question={viewModel.selectedQuestion}
          variableResolver={viewModel.variableResolver}
        />
      )}
    </MUI.Box>
  );
});
