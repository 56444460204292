import { Label } from '@insights/components';
import { SchoolYearConfigurationScreenInfo } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SchoolYearConfigurationLinksProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  data: SchoolYearConfigurationScreenInfo;
}

export const SchoolYearConfigurationLinks = observer((props: SchoolYearConfigurationLinksProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, style, data } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <MUI.Grid container>
        {/* Position Column Title */}
        <MUI.Grid item xs={2} xl={1}>
          <Label>{strings.linkPositionColumnTitle}</Label>
        </MUI.Grid>
        {/* Title Column Title */}
        <MUI.Grid item xs={4} xl={3}>
          <Label>{strings.linkTitleColumnTitle}</Label>
        </MUI.Grid>
        {/* Link Column Title */}
        <MUI.Grid item xs={6} xl={8}>
          <Label>{strings.linkUrlColumnTitle}</Label>
        </MUI.Grid>

        {orderBy(data.schoolYearConfiguration.links, (l) => l.sortPosition).map((link) => (
          <React.Fragment key={link.sortPosition}>
            {/* Position */}
            <MUI.Grid item xs={2} xl={1}>
              <MUI.Typography>{link.sortPosition}</MUI.Typography>
            </MUI.Grid>
            {/* Title */}
            <MUI.Grid item xs={4} xl={3}>
              <MUI.Typography>{link.text}</MUI.Typography>
            </MUI.Grid>
            {/* Link */}
            <MUI.Grid item xs={6} xl={8}>
              <MUI.Link href={link.url} target="_blank" variant="body1">
                {link.url}
              </MUI.Link>
            </MUI.Grid>
          </React.Fragment>
        ))}
      </MUI.Grid>
    </MUI.Box>
  );
});
