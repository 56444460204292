import { SchoolCalendarViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { Column, Row } from '../layout';
import { SchoolCalendarCycleDays } from './SchoolCalendarCycleDays';
import { SchoolCalendarHeader } from './SchoolCalendarHeader';
import { SchoolCalendarImportFromDialog } from './SchoolCalendarImportFromDialog';
import { SchoolCalendarMonths } from './SchoolCalendarMonths';

export interface SchoolCalendarProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarViewModel;
}

export const SchoolCalendar = observer((props: SchoolCalendarProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx, className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Column sx={sx} className={className}>
      <SchoolCalendarHeader viewModel={viewModel} sx={{ mb: '10px' }} />
      <SchoolCalendarMonths viewModel={viewModel} sx={{ height: 400 }} />
      <SchoolCalendarCycleDays viewModel={viewModel} sx={{ mt: '10px' }} />
      <AuthorizationRoleCondition allowedRoles={['super-admin']}>
        <Row sx={{ mt: '10px' }}>
          <MUI.Button onClick={() => viewModel.importFrom()}>{strings.importFromButton}</MUI.Button>
        </Row>
        <SchoolCalendarImportFromDialog viewModel={viewModel.importFromEditor} />
      </AuthorizationRoleCondition>
    </Column>
  );
});
