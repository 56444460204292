import { ExternalFileSourcesViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

export interface ExternalFileSourcesProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: ExternalFileSourcesViewModel;
}

export const ExternalFileSources = observer((props: ExternalFileSourcesProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.settings;

  return (
    <MUI.Card sx={{ ...sx, display: 'block' }} className={className} style={style}>
      <MUI.CardHeader title={strings.externalSourcesTitle} />
      <MUI.Divider />
      <MUI.CardContent>
        <MUI.Box>
          {viewModel.externalSources.map((s) => (
            <MUI.Box key={s.id} display="flex" flexDirection="row" alignItems="center">
              <MUI.Box flex={1}>
                <MUI.Typography>{s.baseUrl}</MUI.Typography>
              </MUI.Box>
              <MUI.IconButton onClick={() => void viewModel.editExternalFileSource(s)}>
                <EditIcon />
              </MUI.IconButton>
              <MUI.IconButton onClick={() => void viewModel.deleteExternalFileSource(s.id)}>
                <DeleteIcon />
              </MUI.IconButton>
            </MUI.Box>
          ))}

          <MUI.Divider sx={{ mt: 1, mb: 2 }} />

          <MUI.Button variant="contained" onClick={() => void viewModel.addExternalFileSource()}>
            {strings.addExternalFileSourceButton}
          </MUI.Button>
        </MUI.Box>
      </MUI.CardContent>
    </MUI.Card>
  );
});
