import { Editor } from '@insights/viewmodels';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsButton } from '../InsightsButton';
import { Column, Expanded, Row } from '../layout';

export interface EditableDialogActionsProps {
  edition: Editor;
  hasChanges: boolean;
  doneLabel?: string;
}

export const EditableDialogActions = observer(({ edition, hasChanges, doneLabel }: EditableDialogActionsProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.components.calendar;
  const navigate = useNavigate();

  return (
    <Row verticalContentAlignment="center">
      <Column horizontalContentAlignment="left" sx={{ m: 2 }}>
        {edition.validationMessages.map((m, index) => (
          <Typography key={`validation-message-${index}`} variant="caption" color="red">
            {m}
          </Typography>
        ))}
      </Column>

      <Expanded />

      <InsightsButton sx={{ m: 1 }} isDisabled={edition.isApplying} onClick={() => void edition.cancel()}>
        {strings.cancel}
      </InsightsButton>
      <InsightsButton
        sx={{ m: 1 }}
        isDefault
        isDisabled={!hasChanges || edition.isApplying}
        isExecuting={edition.isApplying}
        showSpinnerOnExecuting
        onClick={() => void edition.apply(navigate)}
      >
        {doneLabel ?? strings.done}
      </InsightsButton>
    </Row>
  );
});
