import { ManageBacMappingsViewModel } from '@insights/viewmodels';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { Column } from '../layout';
import { ExternalAccountEditionActions } from './ExternalAccountEditionActions';
import { ManageBacAssignmentKindMappings } from './ManageBacAssignmentKindMappings';
import { ManageBacUnmappedAssignmentKinds } from './ManageBacUnmappedAssignmentKinds';

export interface ManageBacMappingsProps {
  sx?: SxProps;
  className?: string;
  viewModel: ManageBacMappingsViewModel;
}

export const ManageBacMappings = observer((props: ManageBacMappingsProps) => {
  const { viewModel, sx, className } = props;

  return (
    <Column sx={sx} className={className}>
      <ManageBacAssignmentKindMappings sx={{ m: 0, mb: 1 }} viewModel={viewModel} />

      <ManageBacUnmappedAssignmentKinds
        sx={{ m: 0, mb: 1 }}
        isIgnoringUnmappedKinds={viewModel.isIgnoringUnmappedKinds}
        onChange={(value) => (viewModel.isIgnoringUnmappedKinds = value)}
      />

      <ExternalAccountEditionActions viewModel={viewModel} />
    </Column>
  );
});
