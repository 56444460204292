import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { QuestionChoice } from '@shared/models/onboarding/interfaces';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ReadOnlySingleChoiceQuestionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  choices: QuestionChoice[];
  answer?: string;
}

export const ReadOnlySingleChoiceQuestion = observer((props: ReadOnlySingleChoiceQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, choices, answer } = props;
  const languageCode = localizationService.currentLocale;

  return (
    <MUI.FormControl sx={sx} className={className} style={style}>
      <MUI.RadioGroup>
        {choices.map((choice) => (
          <MUI.FormControlLabel
            key={`q-ro-c-${choice.value}`}
            value={choice.value}
            control={<MUI.Radio sx={{ paddingY: '6px' }} disabled checked={choice.value === answer} />}
            label={
              <MUI.Typography fontWeight="400">
                {choice.label.find((l) => l.languageCode === languageCode)?.value ?? ''}
              </MUI.Typography>
            }
          />
        ))}
      </MUI.RadioGroup>
    </MUI.FormControl>
  );
});
