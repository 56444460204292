// TODO MUI.Box

import { css } from '@emotion/css';
import ArrowDown from '@mui/icons-material/TrendingDown';
import ArrowUp from '@mui/icons-material/TrendingUp';
import { SxProps, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { Column, Container, Row } from './layout';

export interface ValueVariationPresenterProps {
  sx?: SxProps;
  className?: string;
  caption?: string;
  value: number;
  variationPercentage?: number;
}

export const ValueVariationPresenter = (props: ValueVariationPresenterProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, caption, value, variationPercentage } = props;
  const strings = localizationService.localizedStrings.insights.components;

  const variationClassName = css({ fontWeight: 300, lineHeight: 'normal' });

  return (
    <Column sx={sx} className={className} horizontalContentAlignment="center" verticalContentAlignment="space-evenly">
      {caption != null && (
        <Typography fontWeight="400" lineHeight="normal" variant="h6" color="textSecondary">
          {caption}
        </Typography>
      )}
      <Typography sx={{ fontSize: '2em', lineHeight: 'normal', fontWeight: 300 }}>{value}</Typography>

      {/* Positive variation */}
      {variationPercentage != null && variationPercentage > 0 && (
        <Row verticalContentAlignment="center">
          <Container sx={{ m: 0.5 }}>
            <ArrowUp sx={{ fontSize: 18, lineHeight: 'normal', color: green[500] }} />
          </Container>

          <Typography className={variationClassName} variant="subtitle2" gutterBottom={false} color="textSecondary">
            {strings.getIncreaseVariationMessage(Math.abs(variationPercentage))}
          </Typography>
        </Row>
      )}

      {/* Negative variation */}
      {variationPercentage != null && variationPercentage < 0 && (
        <Row verticalContentAlignment="center">
          <Container sx={{ m: 0.5 }}>
            <ArrowDown sx={{ fontSize: 18, lineHeight: 'normal', color: red[500] }} />
          </Container>

          <Typography className={variationClassName} variant="subtitle2" gutterBottom={false} color="textSecondary">
            {strings.getDecreaseVariationMessage(Math.abs(variationPercentage))}
          </Typography>
        </Row>
      )}

      {/* Neutral variation */}
      {variationPercentage != null && variationPercentage === 0 && (
        <Container sx={{ m: 0.5 }}>
          <Typography className={variationClassName} variant="subtitle2" gutterBottom={false} color="textSecondary">
            -- {strings.noVariation}
          </Typography>
        </Container>
      )}
    </Column>
  );
};
