import {
  AuthorizationRoleCondition,
  ImportSessionList,
  ObservablePresenter,
  PageHeaderBar
} from '@insights/components';
import AddIcon from '@mui/icons-material/Add';
import ImportIcon from '@mui/icons-material/GetApp';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ImportSessionsScreenProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionsScreen = observer((props: ImportSessionsScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createImportSessions(configId), [configId]);

  return (
    <MUI.Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <MUI.Tooltip title={strings.importSessionFromOtherSchool}>
            <MUI.IconButton
              aria-label="Add"
              sx={{ mr: 1 }}
              onClick={() => void viewModel.importSessionFromOtherSchool()}
            >
              <ImportIcon fontSize="small" />
            </MUI.IconButton>
          </MUI.Tooltip>
          <MUI.Tooltip title={strings.addNewSession}>
            <MUI.IconButton aria-label="Add" sx={{ mr: 1 }} onClick={() => void viewModel.addSession()}>
              <AddIcon fontSize="small" />
            </MUI.IconButton>
          </MUI.Tooltip>
        </AuthorizationRoleCondition>
      </PageHeaderBar>

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.sessionList}
          loadingMessage={strings.loadingSessionsMessage}
          errorMessage={strings.sessionsErrorMessage}
          render={(data) => (
            <MUI.Box display="flex" flexDirection="column">
              <ImportSessionList
                sx={{ width: '100%', height: '100%' }}
                viewModel={data}
                title={strings.importSessions}
              />
            </MUI.Box>
          )}
        />
      </MUI.Box>
    </MUI.Box>
  );
});
