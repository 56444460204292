import { Label } from '@insights/components';
import { SchoolYearConfigurationScreenInfo } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface SchoolYearConfigurationWorkloadManagerThresholdsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  data: SchoolYearConfigurationScreenInfo;
}

export const SchoolYearConfigurationWorkloadManagerThresholds = observer(
  (props: SchoolYearConfigurationWorkloadManagerThresholdsProps) => {
    const { localizationService } = useInsightsServices();
    const { className, sx, style, data } = props;
    const strings = localizationService.localizedStrings.insights.views.managedGeneral;
    const configStrings = localizationService.localizedStrings.models.schoolYearConfig;

    return (
      <MUI.Box sx={sx} className={className} style={style}>
        <MUI.Grid container spacing={2}>
          {/* Grade level source */}
          <MUI.Grid item xs={12}>
            <Label>{strings.gradeLevelSourceTitle}</Label>
            <MUI.Typography>
              {configStrings.gradeLevelSourceTitle(data.schoolYearConfiguration.gradeLevelSource)}
            </MUI.Typography>
          </MUI.Grid>

          <MUI.Grid container item>
            {/* Grade Level Column Title */}
            <MUI.Grid item xs={4} xl={3}>
              <Label>{strings.gradeLevelColumnTitle}</Label>
            </MUI.Grid>
            {/* Daily Threshold Column Title */}
            <MUI.Grid item xs={4} xl={3}>
              <Label>{strings.dailyThresholdColumnTitle}</Label>
            </MUI.Grid>
            {/* Weekly Threshold Column Title */}
            <MUI.Grid item xs={4} xl={3}>
              <Label>{strings.weeklyThresholdColumnTitle}</Label>
            </MUI.Grid>
            {/* Filler */}
            <MUI.Grid item xs={false} xl={3} />

            {data.thresholds.map((thresholdInfo) => (
              <React.Fragment key={thresholdInfo.gradeLevel}>
                {/* Grade Level */}
                <MUI.Grid item xs={4} xl={3}>
                  <MUI.Typography>{thresholdInfo.gradeLevel}</MUI.Typography>
                </MUI.Grid>
                {/* Daily Threshold */}
                <MUI.Grid item xs={4} xl={3}>
                  <MUI.Typography>{thresholdInfo.dailyThreshold}</MUI.Typography>
                </MUI.Grid>
                {/* Weekly Threshold */}
                <MUI.Grid item xs={4} xl={3}>
                  <MUI.Typography>{thresholdInfo.weeklyThreshold}</MUI.Typography>
                </MUI.Grid>
                {/* Filler */}
                <MUI.Grid item xs={false} xl={3} />
              </React.Fragment>
            ))}
          </MUI.Grid>
        </MUI.Grid>
      </MUI.Box>
    );
  }
);
