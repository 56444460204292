import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { EditableOnboardingQuestion } from '@shared/models/onboarding/implementations';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { OnboardingChoiceEdition } from './OnboardingChoiceEdition';

export interface OnboardingChoicesEditionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  editableQuestion: EditableOnboardingQuestion;
}

export const OnboardingChoicesEdition = observer((props: OnboardingChoicesEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx, editableQuestion } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      {editableQuestion.editableChoices.map((choice, index) => (
        <OnboardingChoiceEdition key={`onboarding-choice-${index}`} choice={choice} sx={{ mb: 2 }} />
      ))}
      <MUI.Button variant="contained" onClick={() => editableQuestion.addChoice()}>
        {strings.addChoiceLabel}
      </MUI.Button>
    </MUI.Box>
  );
});
