import { ImportDataDetailsResultsViewModel } from '@insights/viewmodels';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { Incident } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsButton } from '../InsightsButton';
import { ImportDataIncident } from './ImportDataIncident';
import { ImportDataIncidentIcon } from './ImportDataIncidentIcon';
import { ImportSessionSourceRowList } from './ImportSessionSourceRowList';

export interface ImportDataDetailsResultsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: ImportDataDetailsResultsViewModel;
}

export const ImportDataDetailsResults = observer((props: ImportDataDetailsResultsProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.components.import;
  const title = viewModel.isVerificationOnly
    ? strings.testImportData(viewModel.targetSchema)
    : strings.importData(viewModel.targetSchema);

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <MUI.CardHeader
        title={title}
        action={
          <MUI.Box display="flex" flexDirection="row">
            <InsightsButton isDefault isDisabled={!viewModel.canImport} onClick={() => void viewModel.import()}>
              {strings.importDataButton(viewModel.isDestructive)}
            </InsightsButton>
          </MUI.Box>
        }
      />

      {!viewModel.isVerificationOnly && renderAlert(viewModel.isSuccessful, localizationService)}

      {/* Global Incidents */}
      {viewModel.highestGlobalIncidentSeverity && (
        <MUI.Accordion elevation={0}>
          <MUI.AccordionSummary expandIcon={<ExpandIcon />}>
            <ImportDataIncidentIcon sx={{ mr: 1 }} severity={viewModel.highestGlobalIncidentSeverity} />
            <MUI.Typography variant="subtitle1">{strings.globalIncidents}</MUI.Typography>
          </MUI.AccordionSummary>
          <MUI.AccordionDetails sx={{ px: 0, py: 2 }}>
            <MUI.Stack direction="column" ml={2}>
              {renderGlobalIncidents(viewModel.globalIncidents)}
            </MUI.Stack>
          </MUI.AccordionDetails>
        </MUI.Accordion>
      )}

      {/* Source incidents and rows (in same expander) */}
      <MUI.Accordion elevation={0} disabled={viewModel.sourceRows.length === 0}>
        <MUI.AccordionSummary expandIcon={<ExpandIcon />}>
          <MUI.Typography variant="subtitle1">
            {`${strings.sourceRows} (${viewModel.sourceRows.length})`}
          </MUI.Typography>
        </MUI.AccordionSummary>
        <MUI.AccordionDetails sx={{ px: 0, py: 2 }}>
          <MUI.Stack direction="column" ml={2}>
            {renderSourceIncidents(viewModel.sourceIncidents)}
          </MUI.Stack>

          <ImportSessionSourceRowList
            rows={viewModel.sourceRows}
            columnNames={viewModel.sourceColumnNames}
            showIncidents
            title=""
            tableStateKey="ImportDataDetailsSourceRows"
          />
        </MUI.AccordionDetails>
      </MUI.Accordion>

      {/* Added rows */}
      <MUI.Accordion elevation={0} disabled={viewModel.addedRows.length === 0}>
        <MUI.AccordionSummary expandIcon={<ExpandIcon />}>
          <MUI.Typography variant="subtitle1">{`${strings.addedRows} (${viewModel.addedRows.length})`}</MUI.Typography>
        </MUI.AccordionSummary>
        <MUI.AccordionDetails sx={{ px: 0, py: 2 }}>
          <ImportSessionSourceRowList
            rows={viewModel.addedRows}
            columnNames={viewModel.columnNames}
            showIncidents
            title=""
            tableStateKey="ImportDataDetailsAddedRows"
          />
        </MUI.AccordionDetails>
      </MUI.Accordion>

      {/* Updated rows */}
      <MUI.Accordion elevation={0} disabled={viewModel.updatedRows.length === 0}>
        <MUI.AccordionSummary expandIcon={<ExpandIcon />}>
          <MUI.Typography variant="subtitle1">
            {`${strings.updatedRows} (${viewModel.updatedRows.length})`}
          </MUI.Typography>
        </MUI.AccordionSummary>
        <MUI.AccordionDetails sx={{ px: 0, py: 2 }}>
          <ImportSessionSourceRowList
            rows={viewModel.updatedRows}
            columnNames={viewModel.columnNames}
            showIncidents
            title=""
            tableStateKey="ImportDataDetailsUpdatedRows"
          />
        </MUI.AccordionDetails>
      </MUI.Accordion>

      {/* Removed rows */}
      <MUI.Accordion elevation={0} disabled={viewModel.removedRows.length === 0}>
        <MUI.AccordionSummary expandIcon={<ExpandIcon />}>
          <MUI.Typography variant="subtitle1">
            {`${strings.removedRows} (${viewModel.removedRows.length})`}
          </MUI.Typography>
        </MUI.AccordionSummary>
        <MUI.AccordionDetails sx={{ px: 0, py: 2 }}>
          <ImportSessionSourceRowList
            rows={viewModel.removedRows}
            columnNames={viewModel.columnNames}
            showIncidents
            title=""
            tableStateKey="ImportDataDetailsRemovedRows"
          />
        </MUI.AccordionDetails>
      </MUI.Accordion>

      {/* Skipped rows */}
      <MUI.Accordion elevation={0} disabled={viewModel.skippedRows.length === 0}>
        <MUI.AccordionSummary expandIcon={<ExpandIcon />}>
          <MUI.Typography variant="subtitle1">
            {`${strings.skippedRows} (${viewModel.skippedRows.length})`}
          </MUI.Typography>
        </MUI.AccordionSummary>
        <MUI.AccordionDetails sx={{ px: 0, py: 2 }}>
          <ImportSessionSourceRowList
            rows={viewModel.skippedRows}
            columnNames={viewModel.columnNames}
            showIncidents
            title=""
            tableStateKey="ImportDataDetailsSkippedRows"
          />
        </MUI.AccordionDetails>
      </MUI.Accordion>
    </MUI.Box>
  );
});

function renderGlobalIncidents(globalIncidents: Incident[]) {
  return globalIncidents.map((incident, i) => (
    <ImportDataIncident key={`incident-${i}`} incident={incident} colorMode="icon-incident-color" />
  ));
}

function renderSourceIncidents(sourceIncidents: Incident[]) {
  return sourceIncidents.map((incident, i) => (
    <ImportDataIncident key={`incident-${i}`} incident={incident} colorMode="icon-incident-color" />
  ));
}

function renderAlert(isSuccessful: boolean, localizationService: LocalizationService) {
  const strings = localizationService.localizedStrings.insights.components.import;

  return isSuccessful ? (
    <MUI.Alert severity="success">{strings.importDataSuccess}</MUI.Alert>
  ) : (
    <MUI.Alert severity="error">{strings.importDataError}</MUI.Alert>
  );
}
