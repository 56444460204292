import { CustomizationData, SchoolCalendarViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloneIcon from '@mui/icons-material/FileCopy';
import UndeleteIcon from '@mui/icons-material/RestoreFromTrash';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import { ScheduleModel, SchedulePeriodModel } from '@shared/models/config';
import { Draggable } from '@shared/rxp/drag-drop';
import clsx from 'clsx';
import _ from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Column, Row } from '../layout';
import { DayConfigurationsList } from './DayConfigurationsList';
import { ScheduleDialog } from './ScheduleDialog';
import { ScheduleFlagsSubtitle } from './ScheduleFlagsSubtitle';

export type SchedulesColumn = 'title' | 'period' | 'start' | 'end' | 'actions';

export interface SchedulesProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarViewModel;
  allowEdit?: boolean;
  columnsToExclude?: SchedulesColumn[];
  schedules?: ScheduleModel[];
  renderRowActions?: (schedule: ScheduleModel, isDeleted: boolean) => React.ReactNode;
}

export const Schedules = observer((props: SchedulesProps) => {
  const [search, setSearch] = React.useState('');
  const { sx, className, viewModel, allowEdit = false, columnsToExclude = [], renderRowActions } = props;
  const { localizationService } = useInsightsServices();
  const editableConfig = viewModel.editableConfig;
  const strings = localizationService.localizedStrings.insights.components.calendar;
  const browserLocale = localizationService.browserLocale;
  const schedules = _.orderBy(props.schedules ?? editableConfig.allSchedules, (s) => s.title.toLocaleLowerCase());
  const lowerSearch = search.toLocaleLowerCase();
  const filteredSchedules =
    search.length === 0
      ? schedules
      : schedules.filter(
          (s) =>
            s.title.toLocaleLowerCase().includes(lowerSearch) ||
            s.tags.find((t) => t.toLocaleLowerCase().includes(lowerSearch)) != null ||
            s.tag.toLocaleLowerCase().includes(lowerSearch)
        );

  function shouldHideColumn(column: SchedulesColumn): boolean {
    return columnsToExclude.includes(column);
  }

  function renderDefaultRowActions(schedule: ScheduleModel, isDeleted: boolean) {
    if (allowEdit) {
      return isDeleted ? (
        <IconButton className="iconButton" onClick={() => void viewModel.undeleteSchedule(schedule)}>
          <UndeleteIcon />
        </IconButton>
      ) : (
        <>
          <IconButton className="iconButton" onClick={() => void viewModel.deleteSchedule(schedule)}>
            <DeleteIcon />
          </IconButton>
          <IconButton className="iconButton" onClick={() => viewModel.copySchedule(schedule)}>
            <CloneIcon />
          </IconButton>
          <IconButton className="iconButton" onClick={() => viewModel.editSchedule(schedule)}>
            <EditIcon />
          </IconButton>
        </>
      );
    }
    return null;
  }

  return (
    <React.Fragment>
      <Stack direction="row-reverse">
        <FormControl sx={{ mr: 1 }}>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={strings.search}
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setSearch('')} edge="end">
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Stack>
      <Root sx={sx} className={className}>
        <TableHead>
          <TableRow className="normalRow">
            {/* No top line on header */}
            <TableCell className={getCellClassName('title', false, shouldHideColumn, 'cell')}>
              {strings.title}
            </TableCell>
            <TableCell className={getCellClassName('period', false, shouldHideColumn, 'cell')}>
              {strings.period}
            </TableCell>
            <TableCell className={getCellClassName('start', false, shouldHideColumn, 'cell')}>
              {strings.start}
            </TableCell>
            <TableCell className={getCellClassName('end', false, shouldHideColumn, 'cell')}>{strings.end}</TableCell>
            <TableCell className={getCellClassName('actions', false, shouldHideColumn, 'cell')}>
              {allowEdit && (
                <IconButton aria-label="Add" onClick={() => viewModel.addSchedule()}>
                  <AddIcon />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allowEdit && (
            <TableRow>
              <TableCell colSpan={5} className="cell">
                <Row verticalContentAlignment="center">
                  <Draggable data={{ clearSchedules: true } as CustomizationData} type="customization">
                    <div style={{ cursor: 'grab' }}>
                      <DeleteIcon />
                    </div>
                  </Draggable>
                  <Typography variant="subtitle2">{strings.dragOnCalendarToClear}</Typography>
                </Row>
              </TableCell>
            </TableRow>
          )}
          {filteredSchedules.length === 0 && schedules.length > 0 && (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography>{strings.noSchedulesFound}</Typography>
              </TableCell>
            </TableRow>
          )}
          {filteredSchedules.map((schedule) => {
            const isDeleted = editableConfig.getIsScheduleDeleted(schedule);
            const isChanged = editableConfig.getIsScheduleChanged(schedule);

            const rowClassName = isDeleted ? 'deletedRow' : isChanged ? 'changedRow' : 'normalRow';

            const dayConfigurations = viewModel.dayConfigurationsByScheduleId[schedule.id] ?? [];
            const tooltipTitle =
              dayConfigurations.length === 0 ? (
                // An empty string tooltip prevents the tooltip from appearing.
                ''
              ) : (
                <DayConfigurationsList viewModel={viewModel} dayConfigurations={dayConfigurations} />
              );

            // When no period, we act as if there's one so we have a row and title cell.
            const periods: (SchedulePeriodModel | undefined)[] =
              schedule.periods.length === 0 ? [undefined] : schedule.periods;

            return periods.map((period, i) => (
              <TableRow key={`schedule-${schedule.id}-period-${period?.tag ?? 'empty'}-${i}`} className={rowClassName}>
                {i == 0 && (
                  <Tooltip key={`schedule-${schedule.id}`} title={tooltipTitle} placement={'left'}>
                    <TableCell rowSpan={periods.length} className="topCell">
                      <Draggable data={{ schedule } as CustomizationData} type="customization" isDraggable={allowEdit}>
                        <div style={{ cursor: allowEdit ? 'grab' : 'default' }}>
                          <Column>
                            <Typography variant="body2" sx={{ userSelect: 'none' }}>
                              {schedule.title}
                            </Typography>
                            <Row>
                              {schedule.tags.length > 0 && (
                                <Typography mr={1} variant="caption" color="gray" sx={{ userSelect: 'none' }}>
                                  {strings.tags}:&nbsp;
                                  {schedule.tags.join(', ')}
                                </Typography>
                              )}
                              {schedule.tag && (
                                <Typography variant="caption" color="gray" sx={{ userSelect: 'none' }}>
                                  {`(${schedule.tag})`}
                                </Typography>
                              )}
                            </Row>
                            <ScheduleFlagsSubtitle schedule={schedule} />
                          </Column>
                        </div>
                      </Draggable>
                    </TableCell>
                  </Tooltip>
                )}
                <TableCell className={getCellClassName('period', i == 0, shouldHideColumn, 'cell')}>
                  {period && <Typography variant="body2">{period.tag}</Typography>}
                </TableCell>
                <TableCell className={getCellClassName('start', i == 0, shouldHideColumn, 'cell')}>
                  {period && (
                    <Typography variant="body2">
                      {period.startTime.formattedString(
                        localizationService.localizedStrings.models.dateFormats.time,
                        browserLocale
                      )}
                    </Typography>
                  )}
                </TableCell>
                <TableCell className={getCellClassName('end', i == 0, shouldHideColumn, 'cell')}>
                  {period && (
                    <Typography variant="body2">
                      {period.endTime.formattedString(
                        localizationService.localizedStrings.models.dateFormats.time,
                        browserLocale
                      )}
                    </Typography>
                  )}
                </TableCell>
                {i == 0 && (
                  <TableCell
                    rowSpan={periods.length}
                    className={getCellClassName('actions', true, shouldHideColumn, 'cell')}
                  >
                    {renderRowActions != null
                      ? renderRowActions(schedule, isDeleted)
                      : renderDefaultRowActions(schedule, isDeleted)}
                  </TableCell>
                )}
              </TableRow>
            ));
          })}
        </TableBody>
      </Root>
      <ScheduleDialog viewModel={viewModel} />
    </React.Fragment>
  );
});

// No choice to use this function since the TableCell hidden property does not hide the cell
function getCellClassName(
  column: SchedulesColumn,
  isTopCell: boolean,
  shouldHideColumn: (column: SchedulesColumn) => boolean,
  ...classNames: string[]
): string {
  const resolvedClassNames: string[] = classNames;

  if (isTopCell) {
    resolvedClassNames.push('topCell');
  }

  if (shouldHideColumn(column)) {
    resolvedClassNames.push('hiddenCell');
  }

  return clsx(resolvedClassNames);
}

const Root = styled(Table)(({ theme }) => ({
  '.normalRow': {
    height: 'auto'
  },
  '.deletedRow': {
    height: 'auto',
    backgroundColor: red[100]
  },
  '.changedRow': {
    height: 'auto',
    backgroundColor: green[100]
  },
  '.cell': {
    padding: '2px 10px'
  },
  '.topCell': {
    borderTop: '2px solid #AAA'
  },
  '.hiddenCell': {
    display: 'none'
  },
  '.iconButton': {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },
  '.scheduleColor': {
    height: 10,
    borderWidth: 1,
    borderColor: grey[200]
    // The background color is added dynamically
  }
}));
