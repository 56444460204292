import { BehaviourSummaryItemType } from '@insights/models';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { OQValue } from '@shared/models/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { BehaviourSummaryItem } from './BehaviourSummaryItem';

export interface BehaviourSummaryFilterItemProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  type: BehaviourSummaryItemType;
  filter?: OQValue;
  onFilterChanged?: (filter?: OQValue) => void;
}

export const BehaviourSummaryFilterItem = observer((props: BehaviourSummaryFilterItemProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, style, type, filter, onFilterChanged } = props;
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | undefined>(undefined);

  const title = useMemo(() => {
    const strings = localizationService.localizedStrings.insights.components.behaviourSummary;

    switch (type) {
      case 'app-open':
        return strings.appOpenFilterTitle;

      case 'task-completion':
        return strings.taskCompletionFilterTitle;

      default:
        throw new Error('Unknown type');
    }
  }, [type, localizationService.currentLocale]);

  const onChooseValueClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const onValueClick = (value?: OQValue) => {
    if (onFilterChanged != null && filter !== value) {
      onFilterChanged(value);
    }

    setMenuAnchorElement(undefined);
  };

  return (
    <MUI.Box sx={sx} className={className} style={style}>
      <MUI.Box display="flex" flexDirection="row" alignItems="center">
        <MUI.Typography variant="body1">{title}</MUI.Typography>

        <MUI.Box flex={1} />

        <BehaviourSummaryItem
          sx={{ cursor: 'pointer' }}
          type={type}
          value={filter}
          showTooltip={false}
          onClick={onChooseValueClick}
        />

        {/* The negative origin is to make the menu render on top of the icon */}
        <MUI.Menu
          anchorEl={menuAnchorElement}
          anchorOrigin={{ horizontal: -16, vertical: 'center' }}
          keepMounted
          open={Boolean(menuAnchorElement)}
          onClose={() => setMenuAnchorElement(undefined)}
        >
          <MUI.MenuItem onClick={() => onValueClick(undefined)} selected={!filter}>
            <BehaviourSummaryItem type={type} showTooltip={false} />
          </MUI.MenuItem>
          <MUI.MenuItem onClick={() => onValueClick('green')} selected={filter === 'green'}>
            <BehaviourSummaryItem type={type} value="green" showTooltip={false} />
          </MUI.MenuItem>
          <MUI.MenuItem onClick={() => onValueClick('yellow')} selected={filter === 'yellow'}>
            <BehaviourSummaryItem type={type} value="yellow" showTooltip={false} />
          </MUI.MenuItem>
          <MUI.MenuItem onClick={() => onValueClick('red')} selected={filter === 'red'}>
            <BehaviourSummaryItem type={type} value="red" showTooltip={false} />
          </MUI.MenuItem>
        </MUI.Menu>
      </MUI.Box>
    </MUI.Box>
  );
});
