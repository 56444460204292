import { EditableTransformationColumnViewModel } from '@insights/viewmodels';
import InfoIcon from '@mui/icons-material/HelpOutline';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface EditableOperationProps {
  sx?: SxProps;
  className?: string;
  column: EditableTransformationColumnViewModel;
}

export const EditableOperation = observer((props: EditableOperationProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, column } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  return (
    <MUI.Box display="flex" flexDirection="row" alignItems="center" sx={sx} className={className} mb="10px">
      <MUI.FormControl fullWidth>
        <MUI.Select
          autoFocus={column.focusedField === 'operation'}
          fullWidth
          displayEmpty
          value={column.operation.name}
          onChange={(e) => column.setOperationByName(e.target.value)}
          onFocus={() => column.setFocusedField('operation')}
          renderValue={(v) => <MUI.Typography>{v || strings.noOperation}</MUI.Typography>}
        >
          {column.availableOperations.map((o) => (
            <MUI.MenuItem key={`operation-${o.name}`} value={o.name}>
              <MUI.Tooltip title={o.description}>
                <MUI.Typography>{o.name || strings.noOperation}</MUI.Typography>
              </MUI.Tooltip>
            </MUI.MenuItem>
          ))}
        </MUI.Select>
      </MUI.FormControl>
      <MUI.Tooltip title={column.operation.description}>
        <InfoIcon sx={{ ml: 5, mr: 1 }} fontSize="small" color="secondary" />
      </MUI.Tooltip>
    </MUI.Box>
  );
});
