import * as am4core from '@amcharts/amcharts4/core';
import * as MUI from '@mui/material';
import { createTheme, CssBaseline } from '@mui/material';
import { configure } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Fragment, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import './App.css';
import {
  ApplicationName,
  BrowserDetector,
  BrowserHistoryListener,
  DateTimePickerLocalizationProvider,
  ErrorBoundary,
  LoadingIndicator,
  UpdateIndicator
} from './components';
import { amChartTheme, LightInsightsMuiTheme } from './theme';
import { useInsightsServices } from './UseInsightsServicesHook.ts';
import { UrlUtils } from './utils';

configure({
  enforceActions: 'observed'
});

am4core.options.commercialLicense = true;
am4core.useTheme(amChartTheme);

const App = observer(() => {
  const { modalService, localizationService, settingsStore, startUpService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights;
  const { isReady } = startUpService;
  const location = useLocation();

  useEffect(() => {
    settingsStore.useIOSAccessTokenProvider = UrlUtils.getUseAccessTokenProvider(location);

    // Set this before rendering any login page, to ensure the LoginViewModel knows early.
    settingsStore.isCompletingLogin = UrlUtils.getLoginCompletion(location);
    void startUpService.start();
  }, []);

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <React.Fragment>
        <DateTimePickerLocalizationProvider>
          <CssBaseline />

          <ErrorBoundary>
            <BrowserDetector>
              {!isReady ? (
                <MUI.Box flex={1} display="flex" flexDirection="column" alignItems="center">
                  <ApplicationName sx={{ marginTop: '20vh' }} size="extra-large" color="light" />
                  <LoadingIndicator sx={{ marginTop: '10vh' }} message={strings.initializingMessage} />
                </MUI.Box>
              ) : (
                <>
                  <BrowserHistoryListener />
                  <MUI.Box height="100%" width="100%" display="flex" flexDirection="column">
                    <UpdateIndicator />

                    <MUI.Box flex={1}>
                      <Outlet />

                      {modalService.modals.map((m, index) => (
                        <Fragment key={index}>{m.element}</Fragment>
                      ))}
                    </MUI.Box>
                  </MUI.Box>
                </>
              )}
            </BrowserDetector>
          </ErrorBoundary>
        </DateTimePickerLocalizationProvider>
      </React.Fragment>
    </MUI.ThemeProvider>
  );
});

export default App;
