import * as G from '@buf/studyo_studyo.bufbuild_es/studyo/connectors/classroom_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { GrpcClassroomExternalAccountSettings } from './GrpcClassroomExternalAccountSettings';
import { ClassroomExternalAccountDetails } from './interfaces';

export class GrpcClassroomExternalAccountDetails implements ClassroomExternalAccountDetails {
  constructor(private readonly _pb: G.GetExternalAccountDetailsResponse) {}

  get isConnectedSuccessfully() {
    return this._pb.isConnectedSuccessfully;
  }

  @LazyGetter()
  get settings() {
    return new GrpcClassroomExternalAccountSettings(this._pb.accountSettings);
  }
}
