import { MaximumSchedulePeriodTagLength, SchoolCalendarScheduleViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UndeleteIcon from '@mui/icons-material/RestoreFromTrash';
import SortIcon from '@mui/icons-material/Sort';
import {
  Checkbox,
  IconButton,
  Input,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { Time } from '@shared/models/types';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface EditableSchedulePeriodsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarScheduleViewModel;
}

export const EditableSchedulePeriods = observer((props: EditableSchedulePeriodsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const editableSchedule = viewModel.editableSchedule;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Root sx={sx} className={className} style={style}>
      <TableHead>
        <TableRow>
          <TableCell className="cell">{strings.periodTag}</TableCell>
          <TableCell className="cell">{strings.startTime}</TableCell>
          <TableCell className="cell">{strings.endTime}</TableCell>
          <TableCell className="cell">{strings.duration}</TableCell>
          <TableCell className="cell">
            <Tooltip title={strings.incrementOccurrenceCountTooltip}>
              <span>{strings.incrementOccurrenceCount}</span>
            </Tooltip>
          </TableCell>
          <TableCell className="cell">
            <IconButton onClick={() => viewModel.sortPeriods()}>
              <SortIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {editableSchedule.allEditablePeriods.map((period, i) => {
          const rowClassName = period.shouldBeDeleted ? 'deletedRow' : period.hasChanges ? 'changedRow' : 'normalRow';
          const duration = period.startTime.duration(period.endTime);
          const formattedDuration = period.startTime.isAfter(period.endTime)
            ? ''
            : `${duration.hours ?? 0}:${String(duration.minutes ?? 0).padStart(2, '0')}`;

          return (
            <TableRow key={`schedule-period-${i}`} className={rowClassName}>
              <TableCell className="cell">
                <Input
                  value={period.tag}
                  onChange={(event) => (period.tag = event.target.value)}
                  inputProps={{ maxLength: MaximumSchedulePeriodTagLength }}
                />
              </TableCell>
              <TableCell className="cell">
                <TextField
                  type="time"
                  required
                  label=""
                  value={period.startTime.asString}
                  onChange={(event) => {
                    const time = Time.fromString(event.target.value);
                    if (time != null) {
                      period.startTime = time;
                    }
                  }}
                  inputProps={{
                    step: 300
                  }}
                />
              </TableCell>
              <TableCell className="cell">
                <TextField
                  type="time"
                  required
                  label=""
                  value={period.endTime.asString}
                  onChange={(event) => {
                    const time = Time.fromString(event.target.value);
                    if (time != null) {
                      period.endTime = time;
                    }
                  }}
                  inputProps={{
                    step: 300
                  }}
                />
              </TableCell>
              <TableCell className="cell">
                <Typography>{formattedDuration}</Typography>
              </TableCell>
              <TableCell className="cell">
                <Checkbox
                  checked={!period.skipOccurrenceCount}
                  onChange={(e) => (period.skipOccurrenceCount = !e.target.checked)}
                />
              </TableCell>
              <TableCell className="cell">
                {period.shouldBeDeleted ? (
                  <IconButton onClick={() => period.markAsNotDeleted()}>
                    <UndeleteIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => period.markAsDeleted()}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell className="cell" colSpan={4} />
          <TableCell className="cell">
            <IconButton onClick={() => viewModel.addPeriod()}>
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Root>
  );
});

const Root = styled(Table)(() => ({
  '.normalRow': {
    height: 'auto'
  },
  '.deletedRow': {
    height: 'auto',
    backgroundColor: red[100]
  },
  '.changedRow': {
    height: 'auto',
    backgroundColor: green[100]
  },
  '.cell': {
    padding: '2px 10px'
  }
}));
