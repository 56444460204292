import { css } from '@emotion/css';
import { CustomizationData, SchoolCalendarCycleDayViewModel } from '@insights/viewmodels';
import { Box, SxProps, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import yellow from '@mui/material/colors/yellow';
import { Droppable } from '@shared/rxp/drag-drop';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Row } from '../layout';
import { CustomizationsList } from './CustomizationsList';

export interface SchoolCalendarCycleDayProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarCycleDayViewModel;
}

export const SchoolCalendarCycleDay = observer((props: SchoolCalendarCycleDayProps) => {
  const { viewModel, sx, className } = props;
  const [isDraggingOver, setIsDraggingOver] = React.useState(false);

  const onDropCustomization = (data: unknown) => {
    setIsDraggingOver(false);
    void props.viewModel.applyCustomizations(data as CustomizationData);
  };

  const editCustomizations = () => props.viewModel.editCustomizations();

  const rootClassname = viewModel.isEmptyPlaceholder
    ? css({})
    : css({
        backgroundColor: viewModel.hasCustomization ? yellow[100] : 'white',
        borderColor: isDraggingOver ? grey[600] : grey[100],
        borderWidth: 1,
        borderStyle: 'solid'
      });

  const containerStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    cursor: viewModel.isEmptyPlaceholder ? 'default' : 'pointer'
  };

  return (
    <Box sx={sx} className={classNames(className, rootClassname)} display="flex" flexDirection="column">
      <Droppable
        acceptedType="customization"
        handleDrop={onDropCustomization}
        handleDragOver={() => setIsDraggingOver(true)}
        handleDragLeave={() => setIsDraggingOver(false)}
        sx={{ flex: 1 }}
      >
        <Tooltip title={viewModel.hasCustomization ? <CustomizationsList viewModel={viewModel} /> : ''}>
          <Box onClick={() => void editCustomizations()} style={containerStyle}>
            <Row horizontalContentAlignment="stretch">
              <Typography flex={1}>{viewModel.label}</Typography>
            </Row>
          </Box>
        </Tooltip>
      </Droppable>
    </Box>
  );
});
