import * as PB from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel } from '../../Model';
import { OnboardingQuestionKind } from '../../types';
import { onboardingQuestionKindFromProtobuf } from '../../types/EnumConversion';
import { OnboardingQuestion, OnboardingText, QuestionChoice } from '../interfaces';
import { OnboardingAnswer } from '../interfaces/OnboardingAnswer';
import { GrpcOnboardingAnswer } from './GrpcOnboardingAnswer';
import { GrpcOnboardingText } from './GrpcOnboardingText';
import { GrpcQuestionChoice } from './GrpcQuestionChoice';

export class GrpcOnboardingQuestion extends BaseModel<PB.OnboardingQuestion> implements OnboardingQuestion {
  constructor(pb: PB.OnboardingQuestion) {
    super(pb);
  }

  get id(): string {
    return this._pb.id;
  }

  get configId(): string {
    return this._pb.configId;
  }

  get templateName(): string {
    return this._pb.templateName;
  }

  get isCustomized(): boolean {
    return this._pb.isCustomized;
  }

  @LazyGetter()
  get description(): OnboardingText[] {
    return this._pb.localizedDescriptions.map((t) => new GrpcOnboardingText(t));
  }

  get kind(): OnboardingQuestionKind {
    return onboardingQuestionKindFromProtobuf(this._pb.answerKind);
  }

  @LazyGetter()
  get choices(): QuestionChoice[] {
    return this._pb.choices.map((c) => new GrpcQuestionChoice(c));
  }

  get isRequired(): boolean {
    return this._pb.isRequired;
  }

  get dependantQuestionName(): string {
    return this._pb.dependantQuestionName;
  }

  get dependantQuestionAnswer(): string {
    return this._pb.dependantQuestionAnswer;
  }

  get isHiddenWhenDependant(): boolean {
    return this._pb.isHiddenWhenDependant;
  }

  get isDependantLocked() {
    return this._pb.isDependantLocked;
  }

  @LazyGetter()
  get answer(): OnboardingAnswer | undefined {
    const pbAnswer = this._pb.answer;
    return pbAnswer && new GrpcOnboardingAnswer(pbAnswer);
  }

  @LazyGetter()
  get previousAnswers(): OnboardingAnswer[] {
    const pbAnswers = this._pb.previousAnswers;
    return pbAnswers.map((pb) => new GrpcOnboardingAnswer(pb));
  }

  @LazyGetter()
  get lastYearAnswer(): OnboardingAnswer | undefined {
    const pbAnswer = this._pb.lastYearAnswer;
    return pbAnswer && new GrpcOnboardingAnswer(pbAnswer);
  }
}
