import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';
import { InsightsButton } from './InsightsButton';

export interface ConfirmationDialogProps extends DialogResult<void> {
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  message: string;
  isMessageHtml?: boolean;
  displayCheckbox?: boolean;
  okButtonCaption?: string;
  cancelButtonCaption?: string;
}

export const ConfirmationDialog = observer((props: ConfirmationDialogProps) => {
  const { localizationService } = useInsightsServices();
  const {
    className,
    style,
    onCancel,
    onSuccess,
    title,
    message,
    isMessageHtml = false,
    okButtonCaption,
    cancelButtonCaption,
    displayCheckbox = false
  } = props;
  const strings = localizationService.localizedStrings.insights.components.confirmationDialog;
  const [isChecked, setIsChecked] = useState(false);

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        open={true}
        onClose={() => onCancel!()}
        maxWidth="sm"
        fullWidth={true}
        className={className}
        style={style}
      >
        <MUI.DialogTitle>{title ?? strings.defaultTitle}</MUI.DialogTitle>

        <MUI.DialogContent>
          <MUI.Stack direction="row" alignItems="center" spacing={1}>
            {displayCheckbox && <MUI.Checkbox value={isChecked} onChange={() => setIsChecked(true)} />}

            {isMessageHtml ? (
              <MUI.Typography flex={1} component="span">
                <div dangerouslySetInnerHTML={{ __html: message }} />
              </MUI.Typography>
            ) : (
              <MUI.Typography flex={1}>{message}</MUI.Typography>
            )}
          </MUI.Stack>
        </MUI.DialogContent>

        <MUI.DialogActions>
          <InsightsButton onClick={() => onCancel!()}>
            {cancelButtonCaption ?? strings.defaultCancelButtonCaption}
          </InsightsButton>
          <InsightsButton isDefault onClick={() => onSuccess!()} isDisabled={displayCheckbox && !isChecked}>
            {okButtonCaption ?? strings.defaultOkButtonCaption}
          </InsightsButton>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
