// TODO MUI.Box

import { MicrosoftTeamsAccount, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Dialog, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface MicrosoftTeamsAccountDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  externalAccountId: string;
}

export const MicrosoftTeamsAccountDialog = observer((props: MicrosoftTeamsAccountDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, configId, onSuccess, onCancel, externalAccountId } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createMicrosoftTeamsAccount(configId, externalAccountId, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <Dialog sx={sx} open={true} fullWidth maxWidth="sm">
        <DialogTitle>{strings.microsoftTeamsAccount}</DialogTitle>
        <DialogContent>
          <MicrosoftTeamsAccount viewModel={viewModel} />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
});
