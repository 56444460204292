import { AccountEditionViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { red } from '@mui/material/colors';
import { AccountUtils } from '@shared/components/utils';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Column, Container, Expanded, Row } from '../layout';
import { AccountSectionList } from './AccountSectionList';
import { AccountSectionPicker } from './AccountSectionPicker';

export interface AccountSectionsEditionProps {
  sx?: MUI.SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: AccountEditionViewModel;
}

export const AccountSectionsEdition = observer((props: AccountSectionsEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, viewModel, style } = props;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  return (
    <Root sx={sx} className={className} horizontalContentAlignment="stretch">
      <Row>
        <MUI.Typography variant="h6">{strings.sectionsEditionTitle}</MUI.Typography>
        &nbsp;&nbsp;
        <MUI.Typography variant="h6" style={{ fontWeight: 'bold' }}>
          {AccountUtils.getDisplayFirstLastName(viewModel.editableAccount)}
        </MUI.Typography>
      </Row>
      {viewModel.editableAccount.role === 'teacher' && (
        <MUI.Box className="teacherNotification">
          <MUI.Typography variant="h5">{strings.teacherSelectedSectionsWarningTitle}</MUI.Typography>
          <MUI.Typography variant="subtitle1">{strings.teacherSelectedSectionsWarning}</MUI.Typography>
          <MUI.Typography variant="subtitle1">{strings.teacherSelectedSectionsWarning2}</MUI.Typography>
        </MUI.Box>
      )}
      {viewModel.hasMissingSection && (
        <MUI.Typography variant="body2" color={'error'}>
          {strings.missingSectionWarning}
        </MUI.Typography>
      )}
      <Row style={style}>
        <Expanded flex={1}>
          <AccountSectionList account={viewModel} />
        </Expanded>
        <Expanded flex={2.5}>
          <Container sx={{ ml: 1 }}>
            <AccountSectionPicker account={viewModel} />
          </Container>
        </Expanded>
      </Row>
    </Root>
  );
});

const Root = MUI.styled(Column)(({ theme }) => ({
  '.teacherNotification': {
    padding: theme.spacing(2),
    backgroundColor: red[500],
    color: theme.palette.common.white
  }
}));
