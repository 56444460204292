import { SchoolCalendarViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import { SchoolCalendarDay } from './SchoolCalendarDay';
import { SchoolCalendarDayOfWeek } from './SchoolCalendarDayOfWeek';

export interface SchoolCalendarMonthsProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarViewModel;
}

export const SchoolCalendarMonths = observer((props: SchoolCalendarMonthsProps) => {
  const { viewModel, sx, className } = props;

  return (
    <MUI.Box sx={sx} className={className} display="flex" flexDirection="column">
      <MUI.Box flex={0.5} display="flex" flexDirection="row">
        {viewModel.daysOfWeek.map((dayOfWeek, weekDayIndex) => (
          <SchoolCalendarDayOfWeek
            sx={{ padding: '5px', flex: 1, flexBasis: 0 }}
            key={`calendar-week-day-header-${weekDayIndex}`}
            viewModel={dayOfWeek}
          />
        ))}
      </MUI.Box>
      {viewModel.weeks.map((week, weekIndex) => (
        <MUI.Box key={`calendar-week-${weekIndex}`} flex={1} display="flex" flexDirection="row">
          {week.map((day, dayIndex) => (
            <SchoolCalendarDay
              key={`calendar-week-${weekIndex}-day-${dayIndex}`}
              viewModel={day}
              sx={{ padding: '5px', flex: 1, flexBasis: 0 }}
            />
          ))}
        </MUI.Box>
      ))}
    </MUI.Box>
  );
});
