import { AccountOQMetrics as PBAccountOQMetrics } from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { OQHistory } from './OQHistory';
import { OQProfile } from './OQProfile';

export class AccountOQMetrics {
  private _pb: PBAccountOQMetrics;

  constructor(pb: PBAccountOQMetrics) {
    this._pb = pb;
  }

  //
  // Properties coming directly from Protobuf
  //

  get configId(): string {
    return this._pb.configId;
  }

  get accountId(): string {
    return this._pb.accountId;
  }

  @LazyGetter()
  get oqProfile(): OQProfile | undefined {
    const profile = this._pb.oqProfile;

    return profile != null ? new OQProfile(profile) : undefined;
  }

  @LazyGetter()
  get oqHistory(): OQHistory | undefined {
    const history = this._pb.oqHistory;

    return history != null ? new OQHistory(history) : undefined;
  }
}
