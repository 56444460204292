import { MenuItem, Select, SxProps, Typography } from '@mui/material';
import { TaskIcon } from '@shared/components/contents';
import { AllContentIcons, ContentIcon, ContentWorkloadLevel } from '@shared/models/types';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Row } from '../layout';

export interface OptionalContentIconSelectorProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  selectedIcon: ContentIcon | undefined;
  workloadLevel?: ContentWorkloadLevel;
  onChange: (icon: ContentIcon | undefined) => void;
}

export const OptionalContentIconSelector = observer((props: OptionalContentIconSelectorProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, selectedIcon, workloadLevel, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;
  const contentsStrings = localizationService.localizedStrings.models.contents;

  return (
    <Row sx={sx} className={className} style={style}>
      <Select
        fullWidth
        displayEmpty
        value={selectedIcon}
        onChange={(event) => onChange(event.target.value as ContentIcon)}
      >
        <MenuItem key={'no-content-icon'} value={undefined}>
          <Typography>{strings.none}</Typography>
        </MenuItem>
        {AllContentIcons.map((icon) => (
          <MenuItem key={icon} value={icon}>
            <Row verticalContentAlignment="center">
              <TaskIcon icon={icon} workloadLevel={workloadLevel} />
              <Typography ml={1}>{contentsStrings.defaultTitle(icon)}</Typography>
            </Row>
          </MenuItem>
        ))}
      </Select>
    </Row>
  );
});
