import { MaximumScheduleTagLength, SchoolCalendarScheduleViewModel } from '@insights/viewmodels';
import { Autocomplete, SxProps, TextField, createFilterOptions } from '@mui/material';
import { observer } from 'mobx-react';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Row } from '../layout';

const filter = createFilterOptions<string>();

export interface EditableScheduleTagProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolCalendarScheduleViewModel;
}

export const EditableScheduleTag = observer((props: EditableScheduleTagProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel, style, sx, className } = props;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <Row sx={sx} className={className} style={style}>
      <Autocomplete
        multiple
        fullWidth
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={viewModel.allowedTags}
        value={viewModel.tags}
        onChange={(e, v) => (viewModel.tags = v as string[])}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option);
          if (inputValue !== '' && !isExisting) {
            filtered.push(strings.newScheduleTag(inputValue));
          }

          return filtered;
        }}
        renderInput={(p) => <TextField {...p} label={strings.tags} />}
      />
      <TextField
        sx={{ ml: 2, width: 300 }}
        label={strings.legacyTag}
        value={viewModel.tag}
        onChange={(event) => (viewModel.tag = event.target.value)}
        inputProps={{ maxLength: MaximumScheduleTagLength }}
      />
    </Row>
  );
});
