import { AccountInfo } from '@insights/models';
import { CustomFilterUtils } from '@insights/utils';
import { SectionTeachersEditionViewModel } from '@insights/viewmodels';
import { Column, MTableToolbar } from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import DefaultTeacherIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react';
import moize from 'moize';
import * as React from 'react';
import { PropsWithChildren, useRef } from 'react';
import { SmallTablePageSize, SmallTablePageSizes } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsMaterialTable } from '../InsightsMaterialTable';

const SelectedTableStateKey = 'SectionTeachersEditionSelected';
const AvailableTableStateKey = 'SectionTeachersEditionAvailable';

export interface SectionTeachersEditionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: SectionTeachersEditionViewModel;
}

export const SectionTeachersEdition = observer((props: SectionTeachersEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;
  const materialTableStrings = localizationService.localizedStrings.insights.materialTable;

  const searchableFields = useRef(
    moize((teacher: AccountInfo) => [
      AccountUtils.getDisplayLastFirstName(teacher.account),
      teacher.account.email,
      teacher.account.managedIdentifier
    ])
  );

  const selectedTableColumns: Column<AccountInfo>[] = [
    {
      render: (rowData) => (
        <TeacherIndicatorsColumn viewModel={viewModel} localizationService={localizationService} rowData={rowData} />
      )
    },
    {
      render: (rowData) => <TeacherNameColumn rowData={rowData} localizationService={localizationService} />
    },
    // Delete teacher
    {
      render: (rowData) => (
        <MUI.IconButton onClick={() => viewModel.removeTeacher(rowData.account)}>
          <DeleteIcon fontSize="small" />
        </MUI.IconButton>
      )
    }
  ];

  const availableTableColumns: Column<AccountInfo>[] = [
    {
      render: (rowData) => <TeacherNameColumn rowData={rowData} localizationService={localizationService} />,
      customFilterAndSearch: (filter: string, account: AccountInfo) =>
        CustomFilterUtils.customFilterAndSearch(filter, account, searchableFields.current)
    },
    // Add teacher
    {
      render: (rowData) => (
        <MUI.IconButton size="small" onClick={() => viewModel.addTeacher(rowData.account)}>
          <AddIcon fontSize="small" />
        </MUI.IconButton>
      )
    }
  ];

  return (
    <MUI.Grid sx={sx} className={className} style={style} container spacing={1}>
      {/* Selected teachers */}
      <MUI.Grid item xs={12} sm={6}>
        <InsightsMaterialTable
          stateKey={SelectedTableStateKey}
          // This is to disable the card contour
          components={{
            Container: TableContainer,
            Toolbar: TableToolbar
          }}
          title={strings.selectedTeachers}
          columns={selectedTableColumns}
          data={viewModel.selectedTeachers}
          options={{
            header: false,
            paging: false,
            search: false,
            padding: 'dense',
            draggable: false
          }}
          localization={materialTableStrings}
        />
      </MUI.Grid>
      {/* Available teachers */}
      <MUI.Grid item xs={12} sm={6}>
        <InsightsMaterialTable
          stateKey={AvailableTableStateKey}
          // This is to disable the card contour
          components={{
            Container: TableContainer
          }}
          title={strings.availableTeachers}
          columns={availableTableColumns}
          data={viewModel.availableTeachers}
          options={{
            pageSize: SmallTablePageSize,
            pageSizeOptions: SmallTablePageSizes,
            header: false,
            emptyRowsWhenPaging: false,
            padding: 'dense',
            draggable: false
          }}
          localization={materialTableStrings}
        />
      </MUI.Grid>
    </MUI.Grid>
  );
});

function TeacherIndicatorsColumn({
  viewModel,
  localizationService,
  rowData
}: {
  viewModel: SectionTeachersEditionViewModel;
  localizationService: LocalizationService;
  rowData: AccountInfo;
}) {
  const strings = localizationService.localizedStrings.insights.components.sections;
  const isDefaultTeacher = viewModel.defaultTeacher?.id === rowData.id;
  const isTeachingAllSchedules = viewModel.allSchedulesTeacherIds.has(rowData.id);

  return (
    <>
      {isDefaultTeacher ? (
        <MUI.Tooltip title={strings.defaultTeacherTooltip}>
          <DefaultTeacherIcon sx={{ color: MUI.colors.green['500'], m: 1.5 }} />
        </MUI.Tooltip>
      ) : (
        <MUI.IconButton onClick={() => viewModel.setDefaultTeacher(rowData.id)}>
          <DefaultTeacherIcon sx={{ color: MUI.colors.grey['500'] }} />
        </MUI.IconButton>
      )}
      {!isTeachingAllSchedules && (
        <MUI.Tooltip title={strings.notTeachingAllSchedulesInfo}>
          <InfoIcon />
        </MUI.Tooltip>
      )}
    </>
  );
}

function TeacherNameColumn({
  rowData,
  localizationService
}: {
  rowData: AccountInfo;
  localizationService: LocalizationService;
}) {
  return (
    <MUI.Typography variant="body1">
      {AccountUtils.getDisplayLastFirstName(rowData.account, localizationService.localizedStrings.insights.noName)}
    </MUI.Typography>
  );
}

function TableContainer(props: object & PropsWithChildren) {
  const theme = useTheme();
  return <MUI.Box sx={{ backgroundColor: theme.palette.background.paper }}>{props.children}</MUI.Box>;
}

function TableToolbar(props: object) {
  return (
    <MUI.Box display="flex" flexDirection="row" alignItems="center">
      <MUI.Box flex={1}>
        <MTableToolbar {...props} />
      </MUI.Box>
    </MUI.Box>
  );
}
