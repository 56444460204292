import { DailyPlannerStatusChart, ObservablePresenter, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { MinMediumChartHeight, StudentsColor, StudentsDarkColor, StudentsLightColor } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface StudentDailyPlannerStatusDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  studentId: string;
}

export const StudentDailyPlannerStatusDialog = observer((props: StudentDailyPlannerStatusDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx, style, studentId, configId, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.studentDailyPlannerStatusDialog;
  const studentStrings = localizationService.localizedStrings.insights.views.metrics.students;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createStudentDailyTaskCounters(configId, studentId)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        scroll="paper"
        onClose={() => onSuccess!()}
        className={className}
        style={style}
      >
        <MUI.DialogContent sx={{ height: MinMediumChartHeight }}>
          <ObservablePresenter
            sx={{ height: '100%', width: '100%' }}
            data={viewModel.data}
            loadingMessage={strings.loadingMessage}
            errorMessage={strings.loadingErrorMessage}
            render={(data) => {
              if (data.length === 0) {
                return (
                  <MUI.Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <MUI.Typography variant="h5">{studentStrings.dailyPlannerStatusEmptyMessage}</MUI.Typography>
                  </MUI.Box>
                );
              }

              return (
                <DailyPlannerStatusChart
                  caption={studentStrings.dailyPlannerStatus}
                  subcaption={studentStrings.dailyPlannerStatusSubcaption}
                  data={data}
                  completedTasksColor={StudentsColor}
                  pastDueTasksColor={StudentsLightColor}
                  cumulativeColor={StudentsDarkColor}
                />
              );
            }}
          />
        </MUI.DialogContent>

        <MUI.DialogActions>
          <MUI.Button variant="outlined" onClick={() => onSuccess!()}>
            {strings.closeButtonCaption}
          </MUI.Button>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
