import { AddOnboardingProcess, InsightsButton, ObservablePresenter, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface AddOnboardingProcessDialogProps extends DialogResult<string> {
  sx?: SxProps;
  configId: string;
  className?: string;
  style?: React.CSSProperties;
}

export const AddOnboardingProcessDialog = observer((props: AddOnboardingProcessDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx, configId, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createAddOnboardingProcessDialog(configId, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => viewModel.cancel()}
      >
        <MUI.DialogTitle>{strings.addProcessTitle}</MUI.DialogTitle>
        <MUI.DialogContent>
          <ObservablePresenter data={viewModel.data} render={(data) => <AddOnboardingProcess viewModel={data} />} />
        </MUI.DialogContent>
        <MUI.DialogActions>
          <InsightsButton onClick={() => viewModel.cancel()}>{strings.cancelLabel}</InsightsButton>
          <InsightsButton isDefault isDisabled={!viewModel.canAdd} onClick={() => void viewModel.add()}>
            {strings.saveLabel}
          </InsightsButton>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
