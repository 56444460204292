import {
  ObservablePresenter,
  PublishedTasksDetailTaskItem,
  SchoolDayHeader,
  SchoolWeekHeader,
  SectionName,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { SectionUtils } from '@insights/utils';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ContentDefinitionUtils, SectionUtils as SharedSectionUtils } from '@shared/components/utils';
import { SectionColors } from '@shared/models/Colors';
import { SchoolDay } from '@shared/models/calendar';
import { SectionModel } from '@shared/models/config';
import { ContentDefinitionModel } from '@shared/models/content';
import { AllContentWorkloadLevels } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { chain } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { cleanDiacritics } from 'underscore.string';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface PublishedTasksBySectionDetailProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  configId: string;
  section: SectionModel;
  sectionStudentCount: number;
  fromDay: SchoolDay;
  toDay: SchoolDay;
  tasks: ContentDefinitionModel[];
}

export const PublishedTasksBySectionDetail = observer((props: PublishedTasksBySectionDetailProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, style, fromDay, toDay, tasks, section, sectionStudentCount, configId, onCancel, onSuccess } =
    props;
  const workloadStrings = localizationService.localizedStrings.insights.views.metrics.workload;
  const tasksStrings = localizationService.localizedStrings.insights.components.tasks;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createPublishedTasksBySectionDetail(configId, section, sectionStudentCount, tasks, onSuccess!, onCancel!)
  );

  const isWeek = !fromDay.day.isSame(toDay.day);

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        maxWidth="md"
        fullWidth={true}
        onClose={() => void viewModel.close()}
        scroll="paper"
        className={className}
        style={style}
      >
        <MUI.DialogTitle>
          {/* Ensure the overflow is visible so that the badge is displayed */}
          <MUI.Box display="flex" flexDirection="row" alignItems="center" overflow="visible">
            <SectionName
              color={viewModel.section.color}
              title={SectionUtils.formatTitle(viewModel.section)}
              subInformation1={viewModel.section.sectionNumber}
              subInformation2={workloadStrings.localizedNumberOfStudents(viewModel.sectionStudentCount)}
              size="medium"
            />

            <MUI.Box flex={1} />

            {!isWeek && <SchoolDayHeader schoolDay={fromDay} displayType="page-header" />}
            {isWeek && <SchoolWeekHeader schoolDay={fromDay} displayType="page-header" />}
          </MUI.Box>
        </MUI.DialogTitle>

        <MUI.DialogContent dividers>
          <ObservablePresenter
            sx={{ minHeight: 100 }}
            data={viewModel.data}
            render={(data) => (
              <MUI.Box height="100%" width="100%">
                {chain(data.sections)
                  .orderBy((sectionInfo) => [
                    cleanDiacritics(SectionUtils.formatTitle(sectionInfo.section)).toLowerCase(),
                    cleanDiacritics(sectionInfo.section.sectionNumber).toLowerCase()
                  ])
                  .map((sectionInfo, index) => (
                    <MUI.Box key={sectionInfo.section.id} marginTop={index > 0 ? 4 : 0}>
                      <MUI.Box bgcolor={grey[100]}>
                        <SectionName
                          title={SectionUtils.formatTitle(sectionInfo.section)}
                          subInformation1={sectionInfo.section.sectionNumber}
                          subInformation2={SharedSectionUtils.getSectionShortTeacherInfo(
                            sectionInfo.section,
                            sectionInfo.teachers,
                            tasksStrings.noTeacher
                          )}
                          color={sectionInfo.section.color}
                          mode="one-line"
                          fontWeight="400"
                        />
                      </MUI.Box>

                      <MUI.Box borderLeft={`4px solid ${SectionColors.get(sectionInfo.section.color)}`} paddingLeft={1}>
                        {chain(sectionInfo.tasks)
                          .orderBy(
                            (t) => [
                              AllContentWorkloadLevels.indexOf(t.task.workloadLevel),
                              cleanDiacritics(
                                ContentDefinitionUtils.getDisplayTitleForContent(
                                  t.task,
                                  localizationService.localizedStrings
                                )
                              ).toLowerCase()
                            ],
                            ['desc', 'asc']
                          )
                          .map((taskInfo) => (
                            <PublishedTasksDetailTaskItem
                              key={taskInfo.task.id}
                              sx={{ my: 0.5 }}
                              taskInfo={taskInfo}
                              onClick={() =>
                                void viewModel.showDetail(
                                  sectionInfo,
                                  taskInfo,
                                  data.schoolYearConfiguration,
                                  data.sectionsById
                                )
                              }
                              schoolYearConfig={data.schoolYearConfiguration}
                              sectionsById={data.sectionsById}
                            />
                          ))
                          .value()}
                      </MUI.Box>
                    </MUI.Box>
                  ))
                  .value()}
              </MUI.Box>
            )}
          />
        </MUI.DialogContent>

        <MUI.DialogActions>
          <MUI.Button variant="outlined" onClick={() => void viewModel.close()}>
            {workloadStrings.close}
          </MUI.Button>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
