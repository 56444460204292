import { OnboardingCommentsViewModel } from '@insights/viewmodels';
import HistoryIcon from '@mui/icons-material/History';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { OnboardingCommentView } from './OnboardingCommentView';

export interface OnboardingCommentsCardProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: OnboardingCommentsViewModel;
  showHistoryButton?: boolean;
}

export const OnboardingCommentsCard = observer((props: OnboardingCommentsCardProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, viewModel, showHistoryButton } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;

  return (
    <MUI.Card className={className} style={style} id="comments">
      <MUI.CardHeader
        title={strings.commentsTitle}
        action={
          showHistoryButton && (
            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
              <MUI.Tooltip title={strings.viewHistoryTooltip}>
                <MUI.IconButton onClick={() => void viewModel.viewHistory()}>
                  <HistoryIcon />
                </MUI.IconButton>
              </MUI.Tooltip>
            </AuthorizationRoleCondition>
          )
        }
      ></MUI.CardHeader>
      <MUI.CardContent>
        {viewModel.comments.map((c, index) => (
          <OnboardingCommentView key={`comment-${index}`} viewModel={c} />
        ))}
        {viewModel.nextComment && <OnboardingCommentView viewModel={viewModel.nextComment} />}
      </MUI.CardContent>
    </MUI.Card>
  );
});
