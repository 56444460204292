import { InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import ExpandIcon from '@mui/icons-material/ArrowForwardIosSharp';
import SuccessIcon from '@mui/icons-material/Check';
import FailureIcon from '@mui/icons-material/Close';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { AutomatedImport, AutomatedImportResult, ImportSession } from '@shared/models/import';
import { IncidentSeverity } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface AutomatedImportResultDetailsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  importSession: ImportSession;
  automatedImport: AutomatedImport;
  result: AutomatedImportResult;
}

export const AutomatedImportResultDetailsDialog = observer(
  ({ sx, className, importSession, automatedImport, result, onCancel }: AutomatedImportResultDetailsDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const strings = localizationService.localizedStrings.insights.views.imports;
    const compStrings = localizationService.localizedStrings.insights.components.import;
    const dateStrings = localizationService.localizedStrings.models.dateFormats;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createAutomatedImportResultDetails(onCancel!, importSession, automatedImport, result)
    );

    return (
      <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
        <MUI.Dialog
          sx={sx}
          open={true}
          onClose={() => viewModel.cancel()}
          maxWidth="md"
          fullWidth={true}
          className={className}
        >
          <form
            noValidate
            onSubmit={(e) => {
              // This is to prevent the page from reloading on submit
              e.preventDefault();
              return false;
            }}
          >
            <MUI.DialogTitle>
              <MUI.Stack direction="row">
                {viewModel.result.isFullySuccessful ? (
                  <SuccessIcon color="success" sx={{ mr: 2 }} />
                ) : (
                  <FailureIcon color="error" sx={{ mr: 2 }} />
                )}
                <MUI.Typography>
                  {format(viewModel.result.startTime, dateStrings.mediumUnabridgedWithLongTime)} -{' '}
                  {format(viewModel.result.endTime, dateStrings.longTime)}
                </MUI.Typography>
              </MUI.Stack>
            </MUI.DialogTitle>

            <MUI.DialogContent dividers>
              {viewModel.transformationResults.length === 0 ? (
                <MUI.Typography>{compStrings.noAutomatedTransformationImportResults}</MUI.Typography>
              ) : (
                <MUI.Stack>
                  {viewModel.transformationResults.map((info, index) => (
                    <MUI.Box
                      key={`transformation-result-${index}`}
                      flexDirection="row"
                      display="flex"
                      alignItems="flex-start"
                      mb={1}
                    >
                      {info.result.isSuccessful ? (
                        <SuccessIcon color="success" sx={{ mr: 2 }} />
                      ) : (
                        <FailureIcon color="error" sx={{ mr: 2 }} />
                      )}
                      <MUI.Stack>
                        <MUI.Typography fontWeight={700} mr={2}>
                          {info.result.label} - {info.transformation?.name ?? strings.unknownTransformation}
                        </MUI.Typography>
                        {info.result.isSkipped ? (
                          <MUI.Typography>{compStrings.skippedAutomatedTransformation}</MUI.Typography>
                        ) : (
                          <MUI.Typography>
                            {format(info.result.startTime, dateStrings.mediumUnabridgedWithLongTime)} -{' '}
                            {format(info.result.endTime, dateStrings.longTime)}
                          </MUI.Typography>
                        )}
                        {info.result.globalIncidents.map((incident, incidentIndex) => (
                          <MUI.Stack key={`incident-${incidentIndex}`} direction="row">
                            <MUI.Typography
                              variant="caption"
                              color={colorFromIncidentSeverity(incident.severity)}
                              width={50}
                            >
                              {incident.code}
                            </MUI.Typography>
                            <MUI.Typography variant="caption">{incident.message}</MUI.Typography>
                          </MUI.Stack>
                        ))}
                        {info.result.entityListResults.map((er) => (
                          <MUI.Accordion disableGutters elevation={0} sx={{ mt: 1 }} key={`entity-list-${er.listKind}`}>
                            <AccordionSummary>
                              <MUI.Typography variant="body2">
                                {strings.entityListKind(er.listKind)}:{' '}
                                {strings.entityCounts(er.entityCount, er.incidents.length)}
                              </MUI.Typography>
                            </AccordionSummary>
                            {er.incidents.length > 0 && (
                              <MUI.AccordionDetails sx={{ py: 0 }}>
                                <MUI.Stack>
                                  {er.incidents.map((incident, incidentIndex) => (
                                    <MUI.Stack key={`entity-incident-${er.listKind}-${incidentIndex}`} direction="row">
                                      <MUI.Typography
                                        variant="caption"
                                        color={colorFromIncidentSeverity(incident.severity)}
                                        width={50}
                                      >
                                        {incident.code}
                                      </MUI.Typography>
                                      <MUI.Typography variant="caption">{incident.message}</MUI.Typography>
                                    </MUI.Stack>
                                  ))}
                                </MUI.Stack>
                              </MUI.AccordionDetails>
                            )}
                          </MUI.Accordion>
                        ))}
                      </MUI.Stack>
                    </MUI.Box>
                  ))}
                </MUI.Stack>
              )}
            </MUI.DialogContent>
            <MUI.DialogActions>
              <InsightsButton onClick={() => viewModel.cancel()}>{strings.close}</InsightsButton>
            </MUI.DialogActions>
          </form>
        </MUI.Dialog>
      </MUI.ThemeProvider>
    );
  }
);

const AccordionSummary = MUI.styled((props: MUI.AccordionSummaryProps) => (
  <MUI.AccordionSummary expandIcon={<ExpandIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

function colorFromIncidentSeverity(severity: IncidentSeverity): 'error' | 'warning' | 'disabled' {
  switch (severity) {
    case 'fatal-error':
    case 'error':
      return 'error';
    case 'warning':
      return 'warning';
    default:
      return 'disabled';
  }
}
