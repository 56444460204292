// TODO MUI.Box

import {
  CalendarAccount,
  Column,
  Container,
  ErrorIndicator,
  Expanded,
  InsightsButton,
  LoadingObservablePresenter,
  Scroll,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Box, Dialog, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface CalendarAccountDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
  externalAccountId: string;
}

export const CalendarAccountDialog = observer((props: CalendarAccountDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, configId, externalAccountId, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createCalendarAccount(configId, externalAccountId, onSuccess!, onCancel!)
  );

  return (
    <ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <Dialog sx={sx} open={true} fullWidth maxWidth="sm">
        <DialogTitle>{strings.calendarAccount}</DialogTitle>
        <DialogContent>
          <LoadingObservablePresenter
            data={viewModel.account}
            loadingMessage={strings.loadingCalendarAccountMessage}
            render={(data) => (
              <Scroll direction="y">
                <Container sx={{ p: 0.5 }}>
                  <Column>
                    <Expanded>
                      <CalendarAccount viewModel={data} />
                    </Expanded>
                  </Column>
                </Container>
              </Scroll>
            )}
            renderError={() => (
              <Column>
                <ErrorIndicator size="normal" message={strings.loadingError} />
                <Box display="flex" flexDirection="column" justifyContent="flex-end" m={1}>
                  <InsightsButton isDefault onClick={() => onCancel!()}>
                    {strings.cancelButton}
                  </InsightsButton>
                </Box>
              </Column>
            )}
          />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
});
