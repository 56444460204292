import { Checkbox, FormControl, FormControlLabel, FormHelperText, SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface MicrosoftTeamsUnmappedAssignmentCategoriesProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  isIgnoringUnmappedCategories: boolean;
  onChange: (isIgnoringUnmappedCategories: boolean) => void;
}

export const MicrosoftTeamsUnmappedAssignmentCategories = observer(
  (props: MicrosoftTeamsUnmappedAssignmentCategoriesProps) => {
    const { localizationService } = useInsightsServices();
    const { sx, className, style, isIgnoringUnmappedCategories, onChange } = props;
    const strings = localizationService.localizedStrings.insights.components.connectors;

    return (
      <FormControl sx={sx} className={className} style={style}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={isIgnoringUnmappedCategories}
              onChange={(event) => onChange(event.target.checked)}
            />
          }
          label={strings.microsoftTeamsIgnoringUnmappedCategoriesLabel}
        />
        <FormHelperText>
          {isIgnoringUnmappedCategories
            ? strings.microsoftTeamsIgnoringUnmappedCategoriesNotice
            : strings.microsoftTeamsNotIgnoringUnmappedCategoriesNotice}
        </FormHelperText>
      </FormControl>
    );
  }
);
