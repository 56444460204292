import { InsightsButton, ObservablePresenter, OnboardingStepEdition, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { OnboardingStep } from '@shared/models/onboarding/interfaces';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface OnboardingStepEditionDialogProps extends DialogResult<OnboardingStep> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  step: OnboardingStep;
  processName: string;
}

export const OnboardingStepEditionDialog = observer((props: OnboardingStepEditionDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx, step, processName, onCancel, onSuccess } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const isCustomized = step.isCustomized;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createOnboardingStepEditionDialog(step, processName, onSuccess!, onCancel!)
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        className={className}
        style={style}
        open={true}
        maxWidth="lg"
        fullWidth={true}
        onClose={() => viewModel.cancel()}
      >
        <MUI.DialogTitle>{strings.editStepTitle}</MUI.DialogTitle>
        <MUI.DialogContent>
          <ObservablePresenter
            data={viewModel.data}
            render={(data) => <OnboardingStepEdition sx={{ mb: 1 }} viewModel={data} />}
          />

          {isCustomized && (
            <MUI.Box display="flex" flexDirection="row-reverse" sx={{ mt: 1 }}>
              <MUI.Typography variant="subtitle2" color="error">
                {strings.customizedStepNotice}
              </MUI.Typography>
            </MUI.Box>
          )}
        </MUI.DialogContent>
        <MUI.DialogActions>
          <InsightsButton onClick={() => viewModel.cancel()}>{strings.cancelLabel}</InsightsButton>
          <InsightsButton
            isDefault={isCustomized}
            isDisabled={!viewModel.canSave}
            onClick={() => void viewModel.save(false)}
          >
            {isCustomized ? strings.saveForThisSchoolButton : strings.saveOnlyForThisSchoolButton}
          </InsightsButton>
          <InsightsButton
            isDefault={!isCustomized}
            isDisabled={!viewModel.canSave || isCustomized}
            onClick={() => void viewModel.save(true)}
          >
            {strings.saveForAllButton}
          </InsightsButton>
        </MUI.DialogActions>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
