import { ManageBacExternalAccountDetails } from '@shared/models/connectors/interfaces';
import { LocalizationService } from '@shared/resources/services';
import { ManageBacConnectorStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  BaseExternalAccountEditionViewModel,
  ExternalAccountEditionViewModel
} from './ExternalAccountEditionViewModel';

export interface ManageBacAccountSettingsViewModel extends ExternalAccountEditionViewModel {
  readonly termIds: bigint[];
  addingTermId: bigint;
  readonly canAddTermId: boolean;
  addTermId(): void;
  removeTermId(termId: bigint): void;
}

export class AppManageBacAccountSettingsViewModel
  extends BaseExternalAccountEditionViewModel
  implements ManageBacAccountSettingsViewModel
{
  @observable private _termIds?: bigint[];
  @observable private _addingTermId = 0n;

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _manageBacStore: ManageBacConnectorStore,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void,
    private readonly _configId: string,
    private readonly _externalAccountId: string,
    private readonly _originalDetails: ManageBacExternalAccountDetails
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get termIds() {
    return this._termIds ?? this._originalDetails.termIds;
  }

  @computed
  get addingTermId() {
    return this._addingTermId;
  }

  set addingTermId(value: bigint) {
    this._addingTermId = value;
  }

  @computed
  get canAddTermId() {
    return this._addingTermId !== 0n && this.termIds.indexOf(this._addingTermId) === -1;
  }

  @action
  addTermId(): void {
    this._termIds = this.termIds.concat(this._addingTermId);
    this._addingTermId = 0n;
    this.onChange();
  }

  @action
  removeTermId(termId: bigint): void {
    if (this.termIds.indexOf(termId) !== -1) {
      this._termIds = this.termIds.filter((id) => id != termId);
      this.onChange();
    }
  }

  @action
  async applyChanges(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (!this.hasChanges) {
      console.error('Applying without changes. Ignoring...');
      this._onSuccess();
      return;
    }

    this.beginApplying();

    try {
      await this._manageBacStore.updateManageBacAccountSettings(
        this._configId,
        this._externalAccountId,
        this.termIds,
        this._originalDetails.kindMappings,
        this._originalDetails.isIgnoringUnmappedKinds
      );

      this._onSuccess();
    } catch (error) {
      this.addError(`${strings.serverError} ${(error as Error).message}`);
    } finally {
      this.endApplying();
    }
  }

  @action
  resetChanges() {
    this._termIds = undefined;
    this.onReset();
  }

  cancelChanges() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;

    if (this.hasChanges) {
      if (!confirm(strings.unsavedChangesWarning)) {
        return;
      }
    }

    this._onCancel();
  }
}
