import { css } from '@emotion/css';
import { BehaviorAggregationViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps, useTheme } from '@mui/material';
import { blue, green, grey, orange, red } from '@mui/material/colors';
import { observer } from 'mobx-react';
import * as React from 'react';
import { BehaviorAggregationTooltip, BehaviorAggregationTooltipInfo } from './BehaviorAggregationTooltip.tsx';

export interface BehaviorAggregationProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  icon: React.ReactElement;
  tooltip?: BehaviorAggregationTooltipInfo;
  viewModel: BehaviorAggregationViewModel;
}

export const BehaviorAggregation = observer((props: BehaviorAggregationProps) => {
  const { sx, className, tooltip, style, icon, viewModel } = props;
  const { counts } = viewModel;
  const theme = useTheme();

  const countButtonClassName = css({ marginRight: theme.spacing(1) });
  const countBoxClassName = css({
    borderWidth: 3,
    borderStyle: 'solid',
    fontWeight: 700,
    width: 30,
    height: 30,
    verticalAlign: 'middle'
  });

  return (
    <MUI.Box
      sx={{ ...sx, border: `1px solid ${grey[300]}`, backgroundColor: 'white', minWidth: 'min-content' }}
      className={className}
      style={style}
      borderRadius={50}
      padding={0.5}
    >
      <MUI.Box display="flex" alignItems="center">
        <MUI.Tooltip sx={{ mx: 1 }} title={<BehaviorAggregationTooltip tooltip={tooltip} />}>
          {icon}
        </MUI.Tooltip>

        {counts.greyCount != null && (
          <MUI.IconButton className={countButtonClassName} size="small" onClick={() => viewModel.toggleFilter('none')}>
            <MUI.Box
              className={countBoxClassName}
              bgcolor={grey[300]}
              borderRadius={50}
              borderColor={viewModel.activeFilters.includes('none') ? blue[500] : grey[300]}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <MUI.Typography variant="caption">{counts.greyCount}</MUI.Typography>
            </MUI.Box>
          </MUI.IconButton>
        )}
        {counts.greenCount != null && (
          <MUI.IconButton className={countButtonClassName} size="small" onClick={() => viewModel.toggleFilter('green')}>
            <MUI.Box
              className={countBoxClassName}
              color="white"
              bgcolor={green[400]}
              borderRadius={50}
              borderColor={viewModel.activeFilters.includes('green') ? blue[500] : green[400]}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <MUI.Typography variant="caption">{counts.greenCount}</MUI.Typography>
            </MUI.Box>
          </MUI.IconButton>
        )}
        {counts.yellowCount != null && (
          <MUI.IconButton
            className={countButtonClassName}
            size="small"
            onClick={() => viewModel.toggleFilter('yellow')}
          >
            <MUI.Box
              className={countBoxClassName}
              color="white"
              bgcolor={orange[400]}
              borderRadius={50}
              borderColor={viewModel.activeFilters.includes('yellow') ? blue[500] : orange[400]}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <MUI.Typography variant="caption">{counts.yellowCount}</MUI.Typography>
            </MUI.Box>
          </MUI.IconButton>
        )}
        {counts.redCount != null && (
          <MUI.IconButton className={countButtonClassName} size="small" onClick={() => viewModel.toggleFilter('red')}>
            <MUI.Box
              className={countBoxClassName}
              color="white"
              bgcolor={red[400]}
              borderRadius={50}
              borderColor={viewModel.activeFilters.includes('red') ? blue[500] : red[400]}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <MUI.Typography variant="caption">{counts.redCount}</MUI.Typography>
            </MUI.Box>
          </MUI.IconButton>
        )}
      </MUI.Box>
    </MUI.Box>
  );
});
