import { SchoolCalendarViewModel } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UndeleteIcon from '@mui/icons-material/RestoreFromTrash';
import { Box, IconButton, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { TermDialog } from './TermDialog';

export interface TermsProps {
  sx?: SxProps;
  className?: string;
  viewModel: SchoolCalendarViewModel;
}

export const Terms = observer((props: TermsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, viewModel } = props;
  const editableConfig = viewModel.editableConfig;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  return (
    <>
      <Table sx={sx} className={className}>
        <TableHead>
          <TableRow style={Styles.normalRow}>
            <TableCell style={Styles.cell}>{strings.tag}</TableCell>
            <TableCell style={Styles.cell}>{strings.start}</TableCell>
            <TableCell style={Styles.cell}>{strings.end}</TableCell>
            <TableCell style={Styles.cell}>
              <IconButton aria-label="Add" onClick={() => viewModel.addTerm()}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {editableConfig.allTerms.map((term) => {
            const isDeleted = editableConfig.getIsTermDeleted(term);
            const isChanged = editableConfig.getIsTermChanged(term);
            const rowStyle = isDeleted ? Styles.deletedRow : isChanged ? Styles.changedRow : Styles.normalRow;

            return (
              <TableRow key={`term-${term.tag}`} style={rowStyle}>
                <TableCell style={Styles.cell}>{term.tag}</TableCell>
                <TableCell style={Styles.cell}>{term.startDay.asString}</TableCell>
                <TableCell style={Styles.cell}>{term.endDay.asString}</TableCell>
                {isDeleted && (
                  <TableCell style={Styles.cell}>
                    <IconButton style={Styles.iconButton} onClick={() => viewModel.undeleteTerm(term)}>
                      <UndeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
                {!isDeleted && (
                  <TableCell style={Styles.cell}>
                    <IconButton style={Styles.iconButton} onClick={() => viewModel.deleteTerm(term)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton style={Styles.iconButton} onClick={() => viewModel.editTerm(term)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                )}
                <TableCell />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box margin={1}>
        <Typography variant="caption" color="textSecondary">
          {strings.termsNotice}
        </Typography>
      </Box>
      <TermDialog viewModel={viewModel} />
    </>
  );
});

const Styles = {
  normalRow: {
    height: 'auto'
  },
  deletedRow: {
    height: 'auto',
    backgroundColor: red[100]
  },
  changedRow: {
    height: 'auto',
    backgroundColor: green[100]
  },
  cell: {
    padding: '2px 10px'
  },
  iconButton: {
    padding: 4,
    marginRight: 4
  }
};
