import { AccountInfo } from '@insights/models';
import { CustomFilterUtils } from '@insights/utils';
import { ChildAccountInfo, ParentChildrenEditionViewModel } from '@insights/viewmodels';
import { Column as TableColumn } from '@material-table/core';
import AddIcon from '@mui/icons-material/Add';
import ConfirmIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import * as MUI from '@mui/material';
import { SxProps, styled } from '@mui/material';
import { AccountUtils } from '@shared/components/utils/models';
import { observer } from 'mobx-react';
import moize from 'moize';
import * as React from 'react';
import { DefaultTablePageSize, DefaultTablePageSizes } from '../../Constants';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsMaterialTable } from '../InsightsMaterialTable';

const SelectedTableStateKey = 'ParentChildrenEditionSelected';
const AvailableTableStateKey = 'ParentChildrenEditionAvailable';

export interface ParentChildrenEditionProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  viewModel: ParentChildrenEditionViewModel;
}

export const ParentChildrenEdition = observer((props: ParentChildrenEditionProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  function renderChildrenColumns(): TableColumn<ChildAccountInfo>[] {
    const insightsStrings = localizationService.localizedStrings.insights;

    const columns: TableColumn<ChildAccountInfo>[] = [
      {
        title: '',
        sorting: false,
        render: (rowData: ChildAccountInfo) => {
          return (
            <MUI.Typography variant="body1" style={{ fontStyle: rowData.isPending ? 'italic' : 'normal' }}>
              {AccountUtils.getDisplayLastFirstName(rowData.account, insightsStrings.noName)}
            </MUI.Typography>
          );
        }
      },
      {
        title: '',
        sorting: false,
        render: (rowData: ChildAccountInfo) => {
          return (
            <MUI.Box>
              <MUI.IconButton size="small" onClick={() => viewModel.removeAccount(rowData.account.id)}>
                <DeleteIcon fontSize="small" />
              </MUI.IconButton>
              {rowData.isPending && (
                <MUI.IconButton
                  className="confirmButton"
                  size="small"
                  onClick={() => viewModel.confirmAccount(rowData.account.id)}
                >
                  <ConfirmIcon fontSize="small" />
                </MUI.IconButton>
              )}
            </MUI.Box>
          );
        }
      }
    ];

    return columns;
  }

  function renderAvailableStudentsColumns(): TableColumn<AccountInfo>[] {
    const insightsStrings = localizationService.localizedStrings.insights;

    const columns: TableColumn<AccountInfo>[] = [
      {
        title: '',
        sorting: false,
        customFilterAndSearch: (filter: string, account: AccountInfo) =>
          CustomFilterUtils.customFilterAndSearch(filter, account, getSearchableFields),
        render: (rowData) => {
          return (
            <MUI.Typography variant="body1">
              {AccountUtils.getDisplayLastFirstName(rowData.account, insightsStrings.noName)}
            </MUI.Typography>
          );
        }
      },
      {
        title: '',
        sorting: false,
        render: (rowData) => {
          return (
            <MUI.IconButton size="small" onClick={() => viewModel.addAccount(rowData.id, false)}>
              <AddIcon fontSize="small" />
            </MUI.IconButton>
          );
        }
      }
    ];

    return columns;
  }

  const getSearchableFields = moize(({ account }: AccountInfo) => [
    AccountUtils.getDisplayLastFirstName(account),
    account.email,
    account.managedIdentifier
  ]);

  return (
    <Root sx={sx} className={className} style={style} container spacing={1}>
      <MUI.Grid item xs={12} sm={6}>
        <InsightsMaterialTable
          stateKey={SelectedTableStateKey}
          // This is to disable the card contour
          components={{
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>
          }}
          title={strings.selectedChildren}
          columns={renderChildrenColumns()}
          data={viewModel.selectedChildren}
          options={{
            header: false,
            paging: false,
            search: false,
            padding: 'dense',
            draggable: false
          }}
          localization={localizationService.localizedStrings.insights.materialTable}
        />
      </MUI.Grid>
      <MUI.Grid item xs={12} sm={6}>
        <InsightsMaterialTable
          stateKey={AvailableTableStateKey}
          // This is to disable the card contour
          components={{
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>
          }}
          title={strings.availableStudents}
          columns={renderAvailableStudentsColumns()}
          data={viewModel.availableStudents}
          options={{
            pageSize: DefaultTablePageSize,
            pageSizeOptions: DefaultTablePageSizes,
            header: false,
            emptyRowsWhenPaging: false,
            padding: 'dense',
            draggable: false
          }}
          localization={localizationService.localizedStrings.insights.materialTable}
        />
      </MUI.Grid>
    </Root>
  );
});

const Root = styled(MUI.Grid)(({ theme }) => ({
  '.confirmButton': {
    marginLeft: theme.spacing(1)
  }
}));
