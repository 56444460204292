import { MaximumSectionAutoEnrollTagLength } from '@insights/viewmodels';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import * as MUI from '@mui/material';
import { styled, SxProps } from '@mui/material';
import { LocalizationService } from '@shared/resources/services';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook.ts';

export interface EditableAutoEnrollTagsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  tags: string[];
  availableTags: string[];
  error?: string;
  onChange: (tags: string[]) => void;
}

export const EditableAutoEnrollTags = observer((props: EditableAutoEnrollTagsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, style, availableTags, tags, error, onChange } = props;
  const strings = localizationService.localizedStrings.insights.components.sections;

  function addTag() {
    onChange(tags.slice().concat('gradeLevel='));
  }

  return (
    <Root sx={sx} size="small" className={className} style={style}>
      <MUI.TableHead>
        <MUI.TableRow>
          <MUI.TableCell className="cell" colSpan={2}>
            {strings.autoEnrollTagsLabel}
          </MUI.TableCell>
          <MUI.TableCell className="cell">
            <MUI.IconButton onClick={() => addTag()}>
              <AddIcon />
            </MUI.IconButton>
          </MUI.TableCell>
        </MUI.TableRow>
      </MUI.TableHead>
      <MUI.TableBody>
        {tags.map((tag, index) => renderTag(tag, index, tags, availableTags, onChange, localizationService))}
      </MUI.TableBody>
      {(error?.length ?? 0) > 0 && (
        <MUI.TableFooter>
          <MUI.TableRow>
            <MUI.TableCell colSpan={3}>
              <MUI.Typography variant="caption" color="error">
                {error}
              </MUI.Typography>
            </MUI.TableCell>
          </MUI.TableRow>
        </MUI.TableFooter>
      )}
    </Root>
  );
});

function renderTag(
  tag: string,
  index: number,
  allTags: string[],
  availableTags: string[],
  onChange: (tags: string[]) => void,
  localizationService: LocalizationService
) {
  const strings = localizationService.localizedStrings.insights.components.sections;

  const parts = tag.split('=');
  const canEdit = parts.length == 2 && availableTags.includes(parts[0]);

  function deleteTag(index: number) {
    // Do not modify the original array (and splice works "in place" and returns the deleted items, because, you know...)
    const tags = allTags.slice();
    tags.splice(index, 1);
    onChange(tags);
  }

  function updateTag(tag: string, value: string, index: number) {
    // Do not modify the original array
    const tags = allTags.slice();
    tags[index] = `${tag}=${value}`;
    onChange(tags);
  }

  return (
    <MUI.TableRow key={`tag-row-${index}`}>
      <MUI.TableCell className="cell">
        {canEdit ? (
          <MUI.Select value={parts[0]} onChange={(event) => updateTag(event.target.value, parts[1], index)}>
            {availableTags.map((at) => (
              <MUI.MenuItem key={`tag-key-${at}`} value={at}>
                {strings.getReadableAutoEnrollTag(at)}
              </MUI.MenuItem>
            ))}
          </MUI.Select>
        ) : (
          <MUI.Typography>{parts[0]}</MUI.Typography>
        )}
      </MUI.TableCell>
      <MUI.TableCell className="cell">
        {canEdit ? (
          <MUI.TextField
            value={parts[1]}
            inputProps={{ maxLength: MaximumSectionAutoEnrollTagLength }}
            onChange={(event) => updateTag(parts[0], event.target.value, index)}
          />
        ) : (
          <MUI.Typography>{parts[1] ?? ''}</MUI.Typography>
        )}
      </MUI.TableCell>
      <MUI.TableCell className="cell">
        <MUI.IconButton onClick={() => deleteTag(index)}>
          <DeleteIcon />
        </MUI.IconButton>
      </MUI.TableCell>
    </MUI.TableRow>
  );
}

const Root = styled(MUI.Table)(({ theme }) => ({
  '.cell': {
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0
  }
}));
