import { SxProps, styled } from '@mui/material';
import classNames from 'classnames';
import { Property } from 'csstype';
import * as React from 'react';

export interface ExpandedProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  flex?: Property.Flex<string | 0>;
  children?: React.ReactNode;
  hideOverflow?: boolean;
}

export function Expanded(props: ExpandedProps) {
  const style = {
    ...props.style,
    flex: props.flex ?? 1
  };

  const overflow = props.hideOverflow ? 'overflowHidden' : '';

  return (
    <Root className={classNames(overflow, props.className)} style={style}>
      {props.children}
    </Root>
  );
}

/**
 * Expands the child on the main axis of the parent flex container. The `flex` property
 * allows controlling the flex factor. The default value of the `flex` is 1. This component
 * only takes 1 child and it will be forced to take all of the available space.
 */
const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: 0,
  '& >:first-of-type': {
    flex: 1
  },

  '&.overflowHidden': {
    overflow: 'hidden'
  }
}));
