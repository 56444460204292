import { css } from '@emotion/css';
import { SchoolCalendarViewModel } from '@insights/viewmodels';
import { Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { Column } from '../layout';
import { EditableDialogActions } from './EditableDialogActions';
import { EditableSchedulePeriods } from './EditableSchedulePeriods';
import { EditableScheduleTag } from './EditableScheduleTag';
import { EditableScheduleTitle } from './EditableScheduleTitle';

export interface ScheduleDialogProps {
  viewModel: SchoolCalendarViewModel;
}

export const ScheduleDialog = observer((props: ScheduleDialogProps) => {
  const { localizationService } = useInsightsServices();
  const { viewModel } = props;
  const theme = useTheme();
  const edition = viewModel.scheduleEditor;
  const strings = localizationService.localizedStrings.insights.components.calendar;

  if (edition.active == null) {
    return <div />;
  }

  const editableClassName = css([{ padding: theme.spacing(2), paddingTop: 0 }]);

  return (
    <Dialog open={true} onClose={() => void edition.cancel()} maxWidth="md" fullWidth={true}>
      <DialogTitle>{strings.editSchedule}</DialogTitle>
      <DialogContent>
        <Column horizontalContentAlignment="stretch">
          <EditableScheduleTitle viewModel={edition.active} className={editableClassName} />
          <EditableScheduleTag viewModel={edition.active} className={editableClassName} />
          <EditableSchedulePeriods viewModel={edition.active} className={editableClassName} />
          {/* Note: Color and kind are not editable, only used by ConfigUI */}
        </Column>
      </DialogContent>
      <DialogActions>
        <EditableDialogActions edition={edition} hasChanges={edition.active.hasChanges} />
      </DialogActions>
    </Dialog>
  );
});
