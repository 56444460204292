import { css } from '@emotion/css';
import { InformationText, InsightsButton, useViewModelRef } from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { AllGradeLevelSources } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Thresholds } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface EditSchoolYearConfigurationThresholdsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  schoolYearConfiguration: SchoolYearConfigurationModel;
  sectionsGradeLevels: string[];
  studentsGradeLevels: string[];
}

export const EditSchoolYearConfigurationThresholdsDialog = observer(
  (props: EditSchoolYearConfigurationThresholdsDialogProps) => {
    const { localizationService, viewModelFactory } = useInsightsServices();
    const {
      className,
      sx,
      style,
      schoolYearConfiguration,
      sectionsGradeLevels,
      studentsGradeLevels,
      onCancel,
      onSuccess
    } = props;
    const strings = localizationService.localizedStrings.insights.views.managedGeneral;
    const configStrings = localizationService.localizedStrings.models.schoolYearConfig;

    const viewModel = useViewModelRef(viewModelFactory, (factory) =>
      factory.createEditSchoolYearConfigurationThresholds(
        schoolYearConfiguration,
        sectionsGradeLevels,
        studentsGradeLevels,
        onSuccess!,
        onCancel!
      )
    );

    const cellClassName = css({ padding: '2px 8px 2px 0px', border: 'none' });
    const gradeLevelCellClassName = css({ width: '33%' });
    const thresholdCellClassName = css({ width: '33%' });

    return (
      <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
        <MUI.Dialog
          sx={sx}
          open={true}
          onClose={() => void viewModel.cancel()}
          maxWidth="sm"
          fullWidth={true}
          className={className}
          style={style}
        >
          <form
            noValidate
            onSubmit={(e) => {
              // This is to prevent the page from reloading on submit
              e.preventDefault();
              return false;
            }}
          >
            <MUI.DialogTitle>{strings.editThresholdsDialogTitle}</MUI.DialogTitle>
            <MUI.DialogContent dividers>
              <MUI.Grid container spacing={3}>
                {/* Grade Level Source */}
                <MUI.Grid item xs={12}>
                  <MUI.FormControl fullWidth error={Boolean(viewModel.getFieldError('gradeLevelSource'))}>
                    <MUI.InputLabel htmlFor="gradelevelsource-select" shrink>
                      {strings.gradeLevelSourceTitle}
                    </MUI.InputLabel>
                    <MUI.Select
                      value={viewModel.getFieldValue('gradeLevelSource')}
                      inputProps={{ id: 'gradelevelsource-select' }}
                      onChange={(e) => viewModel.setFieldValue('gradeLevelSource', e.target.value)}
                    >
                      {AllGradeLevelSources.map((source) => (
                        <MUI.MenuItem value={source} key={source}>
                          <MUI.Typography>{configStrings.gradeLevelSourceTitle(source)}</MUI.Typography>
                        </MUI.MenuItem>
                      ))}
                    </MUI.Select>
                  </MUI.FormControl>
                </MUI.Grid>

                <MUI.Grid container item spacing={2}>
                  {/* Default Daily Thresholds */}
                  <MUI.Grid item xs={12} sm={6}>
                    <MUI.TextField
                      fullWidth
                      type="number"
                      inputProps={{ min: 0, max: Thresholds.maxThreshold }}
                      InputLabelProps={{ shrink: true }}
                      placeholder={`${Thresholds.defaultDailyThreshold}`}
                      label={strings.defaultDailyThresholdFieldLabel}
                      value={viewModel.getFieldValue('defaultDailyThreshold') || undefined}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        const adjustedValue = Math.max(Math.min(value, Thresholds.maxThreshold), 0);

                        viewModel.setFieldValue('defaultDailyThreshold', adjustedValue);

                        e.target.value = adjustedValue > 0 ? `${adjustedValue}` : '';
                      }}
                    />
                  </MUI.Grid>

                  {/* Default Daily Thresholds */}
                  <MUI.Grid item xs={12} sm={6}>
                    <MUI.TextField
                      fullWidth
                      type="number"
                      inputProps={{ min: 0, max: Thresholds.maxThreshold }}
                      InputLabelProps={{ shrink: true }}
                      placeholder={`${Thresholds.defaultWeeklyThreshold}`}
                      label={strings.defaultWeeklyThresholdFieldLabel}
                      value={viewModel.getFieldValue('defaultWeeklyThreshold') || undefined}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        const adjustedValue = Math.max(Math.min(value, Thresholds.maxThreshold), 0);

                        viewModel.setFieldValue('defaultWeeklyThreshold', adjustedValue);

                        e.target.value = adjustedValue > 0 ? `${adjustedValue}` : '';
                      }}
                    />
                  </MUI.Grid>

                  {/* Thresholds by grade level */}
                  <MUI.Grid item xs={12}>
                    <MUI.Table>
                      <MUI.TableHead>
                        <MUI.TableRow>
                          <MUI.TableCell className={classNames(cellClassName, gradeLevelCellClassName)}>
                            <MUI.InputLabel shrink>
                              {strings.workloadManagerThresholdsGradeLevelColumnTitle}
                            </MUI.InputLabel>
                          </MUI.TableCell>
                          <MUI.TableCell className={classNames(cellClassName, thresholdCellClassName)}>
                            <MUI.InputLabel shrink>
                              {strings.workloadManagerThresholdsDailyThresholdColumnTitle}
                            </MUI.InputLabel>
                          </MUI.TableCell>
                          <MUI.TableCell className={classNames(cellClassName, thresholdCellClassName)}>
                            <MUI.InputLabel shrink>
                              {strings.workloadManagerThresholdsWeeklyThresholdColumnTitle}
                            </MUI.InputLabel>
                          </MUI.TableCell>
                        </MUI.TableRow>
                      </MUI.TableHead>

                      <MUI.TableBody>
                        {viewModel.gradeLevels.map((gradeLevel) => (
                          <MUI.TableRow key={gradeLevel}>
                            <MUI.TableCell className={classNames(cellClassName, gradeLevelCellClassName)}>
                              <MUI.Typography variant="body1">{gradeLevel}</MUI.Typography>
                            </MUI.TableCell>
                            <MUI.TableCell className={classNames(cellClassName, thresholdCellClassName)}>
                              <MUI.Input
                                type="number"
                                inputProps={{
                                  min: 0,
                                  max: Thresholds.maxThreshold
                                }}
                                fullWidth
                                placeholder={`${
                                  (viewModel.getFieldValue('defaultDailyThreshold') as number) ||
                                  Thresholds.defaultDailyThreshold
                                }`}
                                value={viewModel.getDailyWorkloadThreshold(gradeLevel)}
                                onChange={(e) => {
                                  const value = Number(e.target.value);
                                  const adjustedValue = Math.max(Math.min(value, Thresholds.maxThreshold), 0);

                                  viewModel.setDailyWorkloadThreshold(gradeLevel, adjustedValue);

                                  e.target.value = adjustedValue > 0 ? `${adjustedValue}` : '';
                                }}
                              />
                            </MUI.TableCell>
                            <MUI.TableCell className={classNames(cellClassName, thresholdCellClassName)}>
                              <MUI.Input
                                type="number"
                                fullWidth
                                inputProps={{
                                  min: 0,
                                  max: Thresholds.maxThreshold
                                }}
                                placeholder={`${
                                  (viewModel.getFieldValue('defaultWeeklyThreshold') as number) ||
                                  Thresholds.defaultWeeklyThreshold
                                }`}
                                value={viewModel.getWeeklyWorkloadThreshold(gradeLevel)}
                                onChange={(e) => {
                                  const value = Number(e.target.value);
                                  const adjustedValue = Math.max(Math.min(value, Thresholds.maxThreshold), 0);

                                  viewModel.setWeeklyWorkloadThreshold(gradeLevel, adjustedValue);

                                  e.target.value = adjustedValue > 0 ? `${adjustedValue}` : '';
                                }}
                              />
                            </MUI.TableCell>
                          </MUI.TableRow>
                        ))}
                      </MUI.TableBody>
                    </MUI.Table>
                  </MUI.Grid>
                </MUI.Grid>
              </MUI.Grid>
            </MUI.DialogContent>
            <MUI.DialogActions>
              <InformationText text={strings.getThresholdHelperText(Thresholds.maxThreshold)} />

              <MUI.Box flex={1} />

              <InsightsButton isExecuting={viewModel.isSubmitting} onClick={() => void viewModel.cancel()}>
                {strings.cancelButtonCaption}
              </InsightsButton>
              <InsightsButton
                isSubmit
                showSpinnerOnExecuting
                isExecuting={viewModel.isSubmitting}
                onClick={() => void viewModel.save()}
              >
                {strings.saveButtonCaption}
              </InsightsButton>
            </MUI.DialogActions>
          </form>
        </MUI.Dialog>
      </MUI.ThemeProvider>
    );
  }
);
