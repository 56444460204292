import { css } from '@emotion/css';
import {
  AuthorizationRoleCondition,
  InsightsMaterialTable,
  MoreActionMenu,
  ObservablePresenter,
  PageHeaderBar,
  RouterLink,
  Row
} from '@insights/components';
import { CustomFilterUtils, caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import { SchoolConfigurationInfo } from '@insights/viewmodels';
import { MTableAction } from '@material-table/core';
import ArchivedIcon from '@mui/icons-material/Archive';
import PreparingIcon from '@mui/icons-material/HourglassEmpty';
import ArrowDown from '@mui/icons-material/TrendingDown';
import ArrowUp from '@mui/icons-material/TrendingUp';
import * as MUI from '@mui/material';
import { SxProps, styled, useTheme } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { TintedImage } from '@shared/rxp/tinted-image';
import _, { map, orderBy, range } from 'lodash';
import { observer } from 'mobx-react';
import moize from 'moize';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DefaultTablePageSizes, MinLayoutWidth } from '../../Constants';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

const TableStateKey = 'SchoolConfigurations';

export interface SchoolConfigurationsProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const SchoolConfigurations = observer((props: SchoolConfigurationsProps) => {
  const { imageService, localizationService, reactRouterRouteService, viewModelFactory } = useInsightsServices();
  const { sx, className, style } = props;
  const strings = localizationService.localizedStrings.insights.views;
  const connectorStrings = localizationService.localizedStrings.insights.components.connectors;
  const externalImages = imageService.studyoImages.tasks.external;
  const theme = useTheme();

  const navigate = useNavigate();
  const viewModel = useMemo(() => viewModelFactory.createSchoolConfigurations(), []);

  const [showToast, setShowToast] = useState(false);

  function getYears() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // range: "... up to, but not including, end".
    return orderBy(range(2018, currentYear + 2), (year) => [year], ['desc']);
  }

  const getSearchableFields = useRef(
    moize((s: SchoolConfigurationInfo) => [
      s.schoolConfiguration.schoolName,
      SchoolYearConfigurationUtils.displayTitle(s.schoolConfiguration),
      s.schoolConfiguration.comments,
      ...s.schoolConfiguration.tags
    ])
  );

  async function copyIdToClipboard(config: SchoolConfigurationInfo): Promise<void> {
    await navigator.clipboard.writeText(config.schoolConfiguration.id);
    setShowToast(true);
  }

  const tagClassname = css({
    '& .MuiChip-label': {
      fontSize: theme.typography.caption.fontSize
    }
  });

  return (
    <Root sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar>
        <MUI.Box display="flex" flexDirection="row" alignItems="center">
          <MUI.Typography>{strings.year}</MUI.Typography>

          <MUI.Box marginLeft={1}>
            <MUI.Select
              className={'yearSelection'}
              value={viewModel.schoolConfigurationsYear}
              onChange={(e) => (viewModel.schoolConfigurationsYear = Number(e.target.value))}
            >
              {map(getYears(), (year) => (
                <MUI.MenuItem key={year} value={year}>
                  {year}
                </MUI.MenuItem>
              ))}
            </MUI.Select>
          </MUI.Box>

          <AuthorizationRoleCondition allowedRoles={['super-admin']}>
            <MUI.Box marginLeft={1}>
              <MUI.FormControlLabel
                control={
                  <MUI.Checkbox
                    checked={viewModel.showDemoSchools}
                    onChange={(event) => (viewModel.showDemoSchools = event.target.checked)}
                  />
                }
                label={strings.showDemoSchools}
              />
            </MUI.Box>
          </AuthorizationRoleCondition>
        </MUI.Box>

        <MUI.Box display="flex" flexDirection="row" alignItems="center" className={'actionsRow'}>
          <MUI.Button onClick={() => void viewModel.createNewSchool(navigate)}>
            <MUI.Typography>{strings.newSchool}</MUI.Typography>
          </MUI.Button>
        </MUI.Box>
      </PageHeaderBar>

      <MUI.Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingSchoolConfigMessage}
          errorMessage={strings.loadingSchoolConfigErrorMessage}
          render={(data) => (
            <MUI.Box display="flex" flexDirection="column">
              <MUI.Card className={'card'}>
                <MUI.Box height="100%" width="100%">
                  <InsightsMaterialTable
                    stateKey={TableStateKey}
                    // This is to disable the card contour
                    components={{
                      Action: (p) => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        if (!p.action.isFreeAction) {
                          // TODO: configId parameter was removed from isAllowed. Fine elsewhere, but here it was required!
                          //       Otherwise a local admin somewhere could navigate to manage any school. For now, since
                          //       this page is only seen by root admins, the manage and copy id menus are only for them,
                          //       in case this is ever used elsewhere.

                          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                          const data = p.data as SchoolConfigurationInfo;
                          return (
                            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                              <MoreActionMenu>
                                <MUI.MenuItem
                                  onClick={() => void viewModel.editSchool(data.schoolConfiguration.id, navigate)}
                                >
                                  <MUI.ListItemText primary={strings.manageThisSchool} />
                                </MUI.MenuItem>
                                <MUI.MenuItem onClick={() => void copyIdToClipboard(data)}>
                                  <MUI.ListItemText primary={strings.copyId} />
                                </MUI.MenuItem>
                              </MoreActionMenu>
                            </AuthorizationRoleCondition>
                          );
                        }

                        return <MTableAction {...p} />;
                      }
                    }}
                    title={strings.configurationsTitle}
                    columns={[
                      {
                        title: strings.school,
                        defaultSort: 'asc',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) => {
                          return caseInsensitiveAccentInsensitiveCompare(
                            a.schoolConfiguration.schoolName,
                            b.schoolConfiguration.schoolName
                          );
                        },
                        customFilterAndSearch: (filter: string, config: SchoolConfigurationInfo) =>
                          CustomFilterUtils.customFilterAndSearch(filter, config, getSearchableFields.current),
                        render: (rowData: SchoolConfigurationInfo) => {
                          return (
                            <MUI.Stack direction="row" alignItems="center" spacing={1}>
                              {rowData.schoolConfiguration.state !== 'active' && (
                                <MUI.Tooltip
                                  title={strings.managedGeneral.getConfigStateLabel(rowData.schoolConfiguration.state)}
                                >
                                  {rowData.schoolConfiguration.state === 'archived' ? (
                                    <ArchivedIcon fontSize="small" />
                                  ) : (
                                    <PreparingIcon fontSize="small" />
                                  )}
                                </MUI.Tooltip>
                              )}
                              <RouterLink
                                variant="body1"
                                to={reactRouterRouteService.resolveLocation(RouteTemplates.dashboard, [
                                  {
                                    name: RouteParamNames.configId,
                                    value: rowData.schoolConfiguration.id
                                  }
                                ])}
                              >
                                {rowData.schoolConfiguration.schoolName}
                              </RouterLink>
                              {rowData.schoolConfiguration.title.length > 0 && (
                                <MUI.Typography variant="body2">
                                  {`[${rowData.schoolConfiguration.title}]`}
                                </MUI.Typography>
                              )}
                              {rowData.schoolConfiguration.tags.map((tag) => (
                                <MUI.Chip
                                  key={`tag-${tag}`}
                                  className={tagClassname}
                                  variant="outlined"
                                  color="info"
                                  size="small"
                                  label={tag}
                                />
                              ))}
                            </MUI.Stack>
                          );
                        }
                      },
                      {
                        title: strings.activeStudents,
                        width: '12%',
                        defaultSort: 'asc',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) =>
                          a.schoolGlobalStats.activeStudents.lastWeekVariation -
                          b.schoolGlobalStats.activeStudents.lastWeekVariation,
                        render: (rowData: SchoolConfigurationInfo) => {
                          const variation = rowData.schoolGlobalStats.activeStudents.lastWeekVariation;
                          const ratioLabel =
                            rowData.schoolConfiguration.expectedStudentCount === 0
                              ? `${rowData.schoolGlobalStats.activeStudents.lastWeek} / ${rowData.schoolGlobalStats.accounts.studentTotal}`
                              : `${rowData.schoolGlobalStats.activeStudents.lastWeek} / ${rowData.schoolConfiguration.expectedStudentCount} (${rowData.schoolGlobalStats.accounts.studentTotal})`;

                          const icon =
                            variation > 0 ? (
                              <ArrowUp className={'arrowUp'} />
                            ) : variation < 0 ? (
                              <ArrowDown className={'arrowDown'} />
                            ) : undefined;

                          return (
                            <Row verticalContentAlignment="center">
                              <MUI.Typography variant="body1">{ratioLabel}</MUI.Typography>
                              {icon && (
                                <>
                                  {icon}
                                  <MUI.Typography variant="body2">{`${Math.abs(variation)}%`}</MUI.Typography>
                                </>
                              )}
                            </Row>
                          );
                        }
                      },
                      {
                        title: strings.activeTeachers,
                        width: '12%',
                        defaultSort: 'asc',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) =>
                          a.schoolGlobalStats.activeTeachers.lastWeekVariation -
                          b.schoolGlobalStats.activeTeachers.lastWeekVariation,
                        render: (rowData: SchoolConfigurationInfo) => {
                          const variation = rowData.schoolGlobalStats.activeTeachers.lastWeekVariation;
                          const ratioLabel = `${rowData.schoolGlobalStats.activeTeachers.lastWeek} / ${rowData.schoolGlobalStats.accounts.teacherTotal}`;

                          const icon =
                            variation > 0 ? (
                              <ArrowUp className={'arrowUp'} />
                            ) : variation < 0 ? (
                              <ArrowDown className={'arrowDown'} />
                            ) : undefined;

                          return (
                            <Row verticalContentAlignment="center">
                              <MUI.Typography variant="body1">{ratioLabel}</MUI.Typography>
                              {icon && (
                                <>
                                  {icon}
                                  <MUI.Typography variant="body2">{`${Math.abs(variation)}%`}</MUI.Typography>
                                </>
                              )}
                            </Row>
                          );
                        }
                      },
                      {
                        title: strings.activeParents,
                        width: '12%',
                        defaultSort: 'asc',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) =>
                          a.schoolGlobalStats.accounts.parentClaimed - b.schoolGlobalStats.accounts.parentClaimed,
                        render: (rowData: SchoolConfigurationInfo) => (
                          <Row verticalContentAlignment="center">
                            <MUI.Typography variant="body1">{`${rowData.schoolGlobalStats.accounts.parentClaimed} / ${rowData.schoolGlobalStats.accounts.parentTotal}`}</MUI.Typography>
                          </Row>
                        )
                      },
                      {
                        title: strings.startDate,
                        width: '12%',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) =>
                          a.schoolConfiguration.startDay.compare(b.schoolConfiguration.startDay),
                        render: (rowData: SchoolConfigurationInfo) => {
                          return (
                            <MUI.Typography variant="body1">
                              {rowData.schoolConfiguration.startDay.formattedString(
                                localizationService.localizedStrings.models.dateFormats.shortUnabridged
                              )}
                            </MUI.Typography>
                          );
                        }
                      },
                      {
                        title: strings.endDate,
                        width: '12%',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) =>
                          a.schoolConfiguration.endDay.compare(b.schoolConfiguration.endDay),
                        render: (rowData: SchoolConfigurationInfo) => {
                          return (
                            <MUI.Typography variant="body1">
                              {rowData.schoolConfiguration.endDay.formattedString(
                                localizationService.localizedStrings.models.dateFormats.shortUnabridged
                              )}
                            </MUI.Typography>
                          );
                        }
                      },
                      {
                        title: strings.integrations,
                        width: '12%',
                        render: (rowData: SchoolConfigurationInfo) => {
                          const moreCount =
                            rowData.schoolConfiguration.enabledIntegrations.length > 4
                              ? rowData.schoolConfiguration.enabledIntegrations.length - 3
                              : 0;
                          const visibleIntegrations =
                            moreCount === 0
                              ? rowData.schoolConfiguration.enabledIntegrations
                              : _.take(rowData.schoolConfiguration.enabledIntegrations, 3);
                          const hiddenIntegrations =
                            moreCount === 0 ? [] : _.drop(rowData.schoolConfiguration.enabledIntegrations, 3);

                          return (
                            <MUI.Box display="flex" flexDirection="row">
                              {visibleIntegrations.map((integration) => (
                                <MUI.Tooltip
                                  key={integration}
                                  title={connectorStrings.localizedIntegrationName(integration)}
                                >
                                  <MUI.Box>
                                    <TintedImage
                                      source={externalImages.getIntegrationImage(integration)}
                                      sx={{
                                        width: 24,
                                        height: 24,
                                        marginRight: '6px'
                                      }}
                                    />
                                  </MUI.Box>
                                </MUI.Tooltip>
                              ))}
                              {moreCount > 0 && (
                                <MUI.Tooltip
                                  title={
                                    <MUI.Stack direction="row" p={1}>
                                      {hiddenIntegrations.map((integration) => (
                                        <TintedImage
                                          key={`more-integration-${integration}`}
                                          source={externalImages.getIntegrationImage(integration)}
                                          sx={{
                                            width: 24,
                                            height: 24,
                                            marginRight: '6px'
                                          }}
                                        />
                                      ))}
                                    </MUI.Stack>
                                  }
                                >
                                  <MUI.Typography>{`+${moreCount}`}</MUI.Typography>
                                </MUI.Tooltip>
                              )}
                            </MUI.Box>
                          );
                        }
                      },
                      {
                        title: strings.valid,
                        width: '1%',
                        render: (rowData: SchoolConfigurationInfo) => {
                          return (
                            <MUI.Typography variant="body1">
                              {rowData.validationMessages == null
                                ? ''
                                : rowData.validationMessages.length === 0
                                  ? '✅'
                                  : '❗'}
                            </MUI.Typography>
                          );
                        }
                      }
                    ]}
                    data={data}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    actions={[{ icon: 'more_vert', onClick: () => {} }]}
                    options={{
                      pageSize: 50,
                      pageSizeOptions: DefaultTablePageSizes,
                      rowStyle: { backgroundColor: '#fff' },
                      headerStyle: {
                        fontSize: theme.typography.body2.fontSize
                      },
                      emptyRowsWhenPaging: false,
                      actionsColumnIndex: -1,
                      draggable: false
                    }}
                    localization={{
                      ...localizationService.localizedStrings.insights.materialTable,
                      header: { actions: '' }
                    }}
                  />
                </MUI.Box>
              </MUI.Card>
            </MUI.Box>
          )}
        />
      </MUI.Box>

      <MUI.Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        message={strings.copyIdNotification}
      />
    </Root>
  );
});

const Root = styled(MUI.Box)(() => ({
  '.card': {
    minWidth: MinLayoutWidth
  },
  '.yearSelection': {
    minWidth: 100
  },
  '.actionsRow': {
    marginLeft: 50
  },
  '.arrowUp': {
    fontSize: 18,
    lineHeight: 'normal',
    marginLeft: 8,
    color: green[500]
  },
  '.arrowDown': {
    fontSize: 18,
    lineHeight: 'normal',
    marginLeft: 8,
    color: red[500]
  }
}));
