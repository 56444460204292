import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { EditableImportSession, EditableSourceFile, ImportSession } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface ConfigSuggestion {
  readonly label: string;
  readonly id: string;
}

export interface TableSuggestion {
  readonly label: string;
  readonly name: string;
}

export interface ImportSessionFileDirectDataViewModel {
  readonly availableConfigurations: ConfigSuggestion[];
  readonly availableTables: TableSuggestion[];

  selectedConfigurationId: string;
  selectedTableName: string;

  readonly hasChanges: boolean;
  readonly fullUrl: string;
}

export interface ImportSessionFileDirectDataDialogViewModel {
  readonly data: IPromiseBasedObservable<ImportSessionFileDirectDataViewModel>;

  readonly canSave: boolean;
  readonly isExecuting: boolean;
  readonly hasError: boolean;
  save: () => Promise<void>;
  cancel: () => void;
}

export class AppImportSessionFileDirectDataViewModel implements ImportSessionFileDirectDataViewModel {
  @observable _selectedConfigurationId = '';
  @observable _selectedTableName = '';

  constructor(
    private readonly _localizationService: LocalizationService,
    private readonly _config: SchoolYearConfigurationSummary,
    private readonly _editableSession: EditableImportSession,
    private readonly _editableFile: EditableSourceFile,
    private readonly _originalUrl: string
  ) {
    makeObservable(this);

    if (_originalUrl.startsWith('studyo://')) {
      const parts = _originalUrl.substring(9).split('/');

      if (parts.length === 2) {
        this._selectedConfigurationId = parts[0];
        this._selectedTableName = parts[1];
      }
    }
  }

  @computed
  get availableConfigurations() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;
    strings.concatenationName;
    const self: ConfigSuggestion = {
      label: strings.directDataSelfConfigLabel,
      id: this._config.id
    };

    if (this._config.previousConfigurationId.length > 0) {
      return [{ label: strings.directDataPreviousConfigLabel, id: this._config.previousConfigurationId }, self];
    } else {
      return [self];
    }
  }

  @computed
  get availableTables() {
    const strings = this._localizationService.localizedStrings.insights.viewModels.import;
    return [
      {
        label: strings.directDataTableTeachersLabel,
        name: 'teachers'
      },
      {
        label: strings.directDataTableSectionsLabel,
        name: 'sections'
      },
      {
        label: strings.directDataTableSchedulesLabel,
        name: 'schedules'
      },
      {
        label: strings.directDataTableStudentsLabel,
        name: 'students'
      },
      {
        label: strings.directDataTableParentsLabel,
        name: 'parents'
      }
    ];
  }

  @computed
  get selectedConfigurationId() {
    return this._selectedConfigurationId;
  }

  set selectedConfigurationId(value: string) {
    this._selectedConfigurationId = value;
  }

  @computed
  get selectedTableName() {
    return this._selectedTableName;
  }

  set selectedTableName(value: string) {
    this._selectedTableName = value;
  }

  @computed
  get fullUrl() {
    if (this._selectedConfigurationId.length > 0 && this._selectedTableName.length > 0) {
      return `studyo://${this._selectedConfigurationId}/${this._selectedTableName}`;
    }

    return '';
  }

  @computed
  get hasChanges() {
    return this._originalUrl != this.fullUrl;
  }
}

export class AppImportSessionFileDirectDataDialogViewModel implements ImportSessionFileDirectDataDialogViewModel {
  private readonly _editableSession: EditableImportSession;
  private readonly _editableFile: EditableSourceFile;
  private readonly _originalUrl: string;
  @observable private _isExecuting = false;
  @observable private _error: Error | undefined;

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _configStore: SchoolYearConfigurationStore,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    session: ImportSession,
    sourceFileLabel: string,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this._editableSession = new EditableImportSession(session);
    const editableFile = this._editableSession.expectedFiles.find((f) => f.label === sourceFileLabel);

    if (editableFile == null) {
      throw new Error('Cannot find a file with that label.');
    }

    this._editableFile = editableFile;
    this._originalUrl = editableFile.url;
  }

  @computed
  get data() {
    return fromPromise(this.loadData());
  }

  @computed
  get canSave() {
    return this.data.state === 'fulfilled' && this.data.value.hasChanges;
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @computed
  get hasError() {
    return this._error != null;
  }

  @action
  async save() {
    if (!this.canSave || this.data.state !== 'fulfilled') {
      return;
    }

    const data = this.data.value;
    this._isExecuting = true;
    this._error = undefined;

    try {
      this._editableFile.url = data.fullUrl;
      this._editableFile.kind = 'direct-data';

      // We need to keep the original url in previous urls.
      if (this._originalUrl.length > 0) {
        this._editableFile.addPreviousUrl(this._originalUrl);
      }

      // We simply need to save the session.
      await this._importSessionStore.createOrUpdateImportSession(this._editableSession, false);

      runInAction(() => {
        this._isExecuting = false;
        this._error = undefined;
      });

      this._onSuccess();
    } catch (error) {
      runInAction(() => {
        this._isExecuting = false;
        this._error = error as Error;
      });
    }
  }

  cancel() {
    this._onCancel();
  }

  private async loadData(): Promise<ImportSessionFileDirectDataViewModel> {
    const config = await this._configStore.getConfigSummary(this._configId);

    return new AppImportSessionFileDirectDataViewModel(
      this._localizationService,
      config,
      this._editableSession,
      this._editableFile,
      this._editableFile.url
    );
  }
}
