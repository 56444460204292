import { ImportSession as PBImportSession } from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import { LazyGetter } from 'lazy-get-decorator';
import { BaseModel } from '../../Model';
import { ImportSourceKind } from '../../types';
import { importSourceKindFromProtobuf } from '../../types/EnumConversion';
import { Concatenation, ImportSession, SourceData, SourceFile, Transformation } from '../interfaces';
import { GrpcConcatenation } from './GrpcConcatenation';
import { GrpcSourceData } from './GrpcSourceData';
import { GrpcSourceFile } from './GrpcSourceFile';
import { GrpcTransformation } from './GrpcTransformation';

export class GrpcImportSession extends BaseModel<PBImportSession> implements ImportSession {
  constructor(pb: PBImportSession) {
    super(pb);
  }

  //
  // Properties coming directly from Protobuf
  //

  get id(): string {
    return this._pb.id;
  }

  get configId(): string {
    return this._pb.configId;
  }

  get name(): string {
    return this._pb.name;
  }

  get description(): string {
    return this._pb.description;
  }

  get sourceKind(): ImportSourceKind {
    return importSourceKindFromProtobuf(this._pb.sourceKind);
  }

  get managerEmail(): string {
    return this._pb.managerEmail;
  }

  @LazyGetter()
  get expectedFiles(): SourceFile[] {
    return this._pb.expectedFiles.map((f) => new GrpcSourceFile(f));
  }

  @LazyGetter()
  get concatenations(): Concatenation[] {
    return this._pb.concatenations.map((c) => new GrpcConcatenation(c));
  }

  @LazyGetter()
  get transformations(): Transformation[] {
    return this._pb.transformations.map((t) => new GrpcTransformation(t));
  }

  @LazyGetter()
  get data(): SourceData[] {
    return this._pb.data.map((d) => new GrpcSourceData(d));
  }

  copyForSchool(configId: string): ImportSession {
    const pb = this.toProtobuf();

    // Adjust the session
    pb.id = '';
    pb.configId = configId;

    let newDescription = `(copied from school ${this.configId})`;

    if (this.description.length > 0) {
      newDescription = this.description + ' ' + newDescription;
    }

    pb.description = newDescription;

    // Make sure each source file doesn't point to a file.
    pb.expectedFiles.forEach((file) => {
      file.url = '';
      file.previousUrls = [];
    });

    // Note: ids in child objects can be left as is.

    return new GrpcImportSession(pb);
  }
}
