import {
  AcceptedIncidentList,
  ExpectedEntityList,
  InsightsButton,
  ObservableActionsPresenter,
  ObservablePresenter,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import * as MUI from '@mui/material';
import { SxProps, createTheme } from '@mui/material';
import { EditableAutomatedTransformationImport, ImportSession } from '@shared/models/import';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface AutomatedTransformationImportDialogProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  importSession: ImportSession;
  automatedTransformationImport: EditableAutomatedTransformationImport;
}

export const AutomatedTransformationImportDialog = observer((props: AutomatedTransformationImportDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, className, importSession, automatedTransformationImport, onSuccess, onCancel } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createAutomatedTransformationImportViewModel(
      onSuccess!,
      onCancel!,
      importSession,
      automatedTransformationImport
    )
  );

  return (
    <MUI.ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <MUI.Dialog
        sx={sx}
        open={true}
        onClose={() => viewModel.cancel()}
        maxWidth="md"
        fullWidth={true}
        className={className}
      >
        <form
          noValidate
          onSubmit={(e) => {
            // This is to prevent the page from reloading on submit
            e.preventDefault();
            return false;
          }}
        >
          <MUI.DialogTitle>
            {viewModel.isCreating ? strings.createAutomatedTransformationImportTitle : strings.editAutomatedImportTitle}
          </MUI.DialogTitle>

          <MUI.DialogContent dividers>
            <ObservablePresenter
              data={viewModel.data}
              render={(data) => (
                <MUI.Box height="100%" width="100%" display="flex" flexDirection="column" overflow="visible">
                  <MUI.FormControl>
                    <MUI.InputLabel id="transformation-label" required>
                      {strings.automatedTransformationImportLabelLabel}
                    </MUI.InputLabel>
                    <MUI.Select
                      labelId="transformation-label"
                      autoFocus
                      value={data.transformationImport.label}
                      onChange={(e) => (data.transformationImport.label = e.target.value)}
                    >
                      {data.availableLabels.map((transformation) => (
                        <MUI.MenuItem key={`available-label-${transformation.label}`} value={transformation.label}>
                          {transformation.label} - {transformation.name}
                        </MUI.MenuItem>
                      ))}
                    </MUI.Select>
                  </MUI.FormControl>

                  <MUI.FormControlLabel
                    sx={{ mt: 2 }}
                    control={
                      <MUI.Checkbox
                        checked={data.transformationImport.isCompleteData}
                        onChange={(event) => (data.transformationImport.isCompleteData = event.target.checked)}
                      />
                    }
                    label={strings.isCompleteDataLabel}
                  />

                  <MUI.Typography mt={2} variant="subtitle1">
                    {strings.acceptedIncidentsHeader}
                  </MUI.Typography>
                  <AcceptedIncidentList viewModel={data} />

                  <MUI.Typography mt={2} variant="subtitle1">
                    {strings.expectedEntitiesHeader}
                  </MUI.Typography>
                  <ExpectedEntityList viewModel={data} />
                </MUI.Box>
              )}
            />
          </MUI.DialogContent>

          <MUI.DialogActions>
            <ObservableActionsPresenter
              data={viewModel.data}
              render={(data) => (
                <>
                  <InsightsButton onClick={() => data.cancel()}>
                    {strings.cancelAutomatedImportButtonLabel}
                  </InsightsButton>
                  <InsightsButton
                    isSubmit
                    showSpinnerOnExecuting
                    isDisabled={!data.canApply}
                    onClick={() => data.apply()}
                  >
                    {viewModel.isCreating
                      ? strings.createAutomatedImportButtonLabel
                      : strings.editAutomatedImportButtonLabel}
                  </InsightsButton>
                </>
              )}
              renderError={() => (
                <InsightsButton isDefault onClick={() => viewModel.cancel()}>
                  {strings.close}
                </InsightsButton>
              )}
            />
          </MUI.DialogActions>
        </form>
      </MUI.Dialog>
    </MUI.ThemeProvider>
  );
});
