import { SectionInfo } from '@insights/models';
import { SectionUtils } from '@insights/utils';
import { AccountEditionViewModel } from '@insights/viewmodels';
import { Column as TableColumn } from '@material-table/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, SxProps, Typography, styled, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsMaterialTable } from '../InsightsMaterialTable';
import { SectionName } from '../SectionName';
import { Container, Row } from '../layout';

const TableStateKey = 'AccountSectionList';

export interface AccountSectionListProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  account: AccountEditionViewModel;
}

export const AccountSectionList = observer((props: AccountSectionListProps) => {
  const { localizationService } = useInsightsServices();
  const theme = useTheme();
  const { sx, className, style, account } = props;
  const tableStateKey = `${TableStateKey}-${props.account.editableAccount.role}`;
  const strings = localizationService.localizedStrings.insights.components.accounts;

  function renderColumns(): TableColumn<SectionInfo>[] {
    const columns: TableColumn<SectionInfo>[] = [
      {
        title: strings.section,
        defaultSort: 'asc',
        customSort: (a: SectionInfo, b: SectionInfo) =>
          SectionUtils.compareTitles(a, b, localizationService.currentLocale),
        render: (rowData: SectionInfo) => {
          const section = rowData.section;
          const teacher = section != null ? account.teachersById[section.defaultTeacherId] : undefined;

          return (
            <Container sx={{ my: 0.5 }} className={'item'}>
              <Row verticalContentAlignment="center">
                <SectionName
                  title={section != null ? SectionUtils.formatTitle(section) : strings.unknownSection}
                  color={section?.color}
                  subInformation1={section?.importId}
                  subInformation2={teacher != null ? AccountUtils.getDisplayLastFirstName(teacher) : undefined}
                />
              </Row>
            </Container>
          );
        }
      },
      {
        title: '',
        render: (rowData: SectionInfo) => {
          return (
            <Row verticalContentAlignment="center">
              {!rowData.isAutoEnrolled ? (
                <IconButton onClick={() => account.unselectSection(rowData.id)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              ) : (
                <Typography variant="body1">{strings.autoEnrolled}</Typography>
              )}
            </Row>
          );
        }
      }
    ];

    return columns;
  }

  return (
    <Root sx={sx} className={className} style={style}>
      <InsightsMaterialTable
        stateKey={tableStateKey}
        // This is to disable the card contour
        components={{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>
        }}
        title={strings.selectedSections}
        columns={renderColumns()}
        data={account.selectedSections}
        options={{
          rowStyle: { backgroundColor: '#fff', verticalAlign: 'top' },
          headerStyle: { fontSize: theme.typography.body2.fontSize },
          paging: false,
          search: false,
          draggable: false
        }}
        localization={localizationService.localizedStrings.insights.materialTable}
      />
    </Root>
  );
});

const Root = styled(Container)(() => ({
  '.item': {
    minHeight: 30
  }
}));
