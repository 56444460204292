import { ImportSessionFileDirectDataViewModel } from '@insights/viewmodels';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface ImportSessionFileDirectDataProps {
  viewModel: ImportSessionFileDirectDataViewModel;
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

export const ImportSessionFileDirectData = observer(
  ({ className, style, sx, viewModel }: ImportSessionFileDirectDataProps) => {
    const { localizationService } = useInsightsServices();
    const strings = localizationService.localizedStrings.insights.views.imports;

    return (
      <MUI.Stack direction="row" sx={sx} className={className} style={style} spacing={1} alignItems="end">
        <MUI.Typography pb={0.5}>studyo://</MUI.Typography>
        <MUI.Autocomplete
          sx={{ flex: 2 }}
          freeSolo
          autoSelect
          options={viewModel.availableConfigurations}
          value={viewModel.selectedConfigurationId}
          onChange={(_, v) => (viewModel.selectedConfigurationId = typeof v === 'string' ? v : v?.id ?? '')}
          getOptionLabel={(o) => (typeof o === 'string' ? o : o.label)}
          renderInput={(params) => <MUI.TextField {...params} label={strings.directDataConfigurationLabel} />}
        />
        <MUI.Typography pb={0.5}>/</MUI.Typography>
        <MUI.Autocomplete
          sx={{ flex: 1 }}
          freeSolo
          autoSelect
          options={viewModel.availableTables}
          value={viewModel.selectedTableName}
          onChange={(_, v) => (viewModel.selectedTableName = typeof v === 'string' ? v : v?.name ?? '')}
          getOptionLabel={(o) => (typeof o === 'string' ? o : o.label)}
          renderInput={(params) => <MUI.TextField {...params} label={strings.directDataTableNameLabel} />}
        />
      </MUI.Stack>
    );
  }
);
