import DateIcon from '@mui/icons-material/CalendarToday';
import * as MUI from '@mui/material';
import { SxProps } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { startOfDay } from 'date-fns';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';

export interface OnboardingTargetDateProps {
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  targetDate: Date;
  onChange: (targetDate: Date) => Promise<void>;
}

export const OnboardingTargetDate = observer((props: OnboardingTargetDateProps) => {
  const { localizationService } = useInsightsServices();
  const { sx } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>(undefined);
  const [targetDate, setTargetDate] = useState(props.targetDate);

  function changeDate(date: Date) {
    setTargetDate(date);
  }

  function togglePopover(anchor: HTMLElement) {
    setAnchorElement(anchorElement == null ? anchor : undefined);
  }

  function saveDate() {
    void props.onChange(targetDate);
    closePopover();
  }

  function closePopover() {
    setAnchorElement(undefined);
    setTargetDate(props.targetDate);
  }

  return (
    <>
      <MUI.Tooltip title={strings.setTargetDateTooltip}>
        <MUI.IconButton sx={sx} onClick={(e) => togglePopover(e.currentTarget)}>
          <DateIcon />
        </MUI.IconButton>
      </MUI.Tooltip>

      <MUI.Popover
        open={anchorElement != null}
        onClose={() => closePopover()}
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <StaticDatePicker
          value={targetDate}
          onChange={(e) => changeDate(startOfDay(e!))}
          slotProps={{ actionBar: { actions: [] } }}
        />
        <MUI.Box display="flex" flexDirection="row-reverse" p={1}>
          <MUI.Button onClick={() => closePopover()}>{strings.cancelLabel}</MUI.Button>
          <MUI.Button disabled={props.targetDate === targetDate} onClick={() => saveDate()}>
            {strings.saveLabel}
          </MUI.Button>
        </MUI.Box>
      </MUI.Popover>
    </>
  );
});
