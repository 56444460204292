import { css } from '@emotion/css';
import { Stack, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { TimePicker } from '@mui/x-date-pickers';
import { EditableOnboardingAnswer, OnboardingAnswer } from '@shared/models/onboarding/';
import { Time } from '@shared/models/types';
import { formatDate, startOfSecond } from 'date-fns';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';
import { LastAnswerTooltip } from './LastAnswerTooltip.tsx';

export interface TimeQuestionProps {
  sx?: SxProps;
  className?: string;
  answer: EditableOnboardingAnswer;
  lastYearAnswer?: OnboardingAnswer;
  disabled?: boolean;
}

export const TimeQuestion = observer((props: TimeQuestionProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx, answer, lastYearAnswer, disabled } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const format = localizationService.localizedStrings.models.timeFormats.doubleDigits;

  const normalClassName = css({ fontWeight: '400' });
  const disabledClassName = css({ fontWeight: '400', color: grey[500] });

  return (
    <Stack sx={sx} className={className} direction="row" spacing={2}>
      <TimePicker
        sx={{ flex: 1 }}
        disabled={disabled}
        slotProps={{
          textField: {
            InputProps: { classes: { disabled: disabledClassName, root: normalClassName } },
            placeholder: strings.noneLabel
          },
          actionBar: {
            actions: ['clear', 'cancel', 'accept']
          }
        }}
        localeText={{
          okButtonLabel: strings.okLabel,
          cancelButtonLabel: strings.cancelLabel,
          clearButtonLabel: strings.clearLabel
        }}
        format={format}
        value={answer.timeValueAsDate ?? null}
        onChange={(e) => (answer.timeValueAsDate = e != null ? startOfSecond(e) : undefined)}
      />
      {lastYearAnswer?.timeValue != null && (
        <LastAnswerTooltip
          answer={formatDate(Time.fromString(lastYearAnswer.timeValue)!.asDate, format)}
          onCopy={() => (answer.timeValue = lastYearAnswer.timeValue)}
        />
      )}
    </Stack>
  );
});
